// @ts-nocheck
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { Form, Input, InputNumber, Table } from 'antd';
import { ObstacleDataType } from 'pages/AeroReport/AeroReport';

type InputRef = typeof Input;
type FormInstance<T> = typeof Form<T>;

const EditableContext = createContext<FormInstance<any> | null>(null);

interface ItemType {
  key: string;
  obstacle: string;
  source: string;
  vertical: string;
  horizontal: string;
  remarks: string;
}

interface EditableRowProps {
  index: number;
}

const EditableRow = ({ index, ...props }: EditableRowProps) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr key={index} {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof ItemType;
  record: ItemType;
  handleSave: (record: ItemType) => void;
}

const EditableCell = ({
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}: EditableCellProps) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      // eslint-disable-next-line
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item style={{ margin: 0, width: 'inherit' }} name={dataIndex}>
        <InputNumber placeholder="Type here" ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return (
    <td {...restProps}>{record?.key === 1 && dataIndex === 'vertical' ? children : childNode}</td>
  );
};

type EditableTableProps = Parameters<typeof Table>[0];

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

interface TerrainSurveyObsTableProps {
  obstacleData: ObstacleDataType[] | [];
  handleSaveData: (data: ObstacleDataType[] | []) => void;
}

const TerrainSurveyObsTable = ({ obstacleData, handleSaveData }: TerrainSurveyObsTableProps) => {
  const [dataSource, setDataSource] = useState<ObstacleDataType[]>([...obstacleData]);

  useEffect(() => {
    if (obstacleData) {
      setDataSource([...obstacleData]);
    }
  }, [obstacleData]);
  // useEffect(() => {
  //   if (dataSource) {
  //     handleSaveData([...dataSource]);
  //   }
  // }, [dataSource]);

  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: 'Obstacle',
      dataIndex: 'obstacle',
      key: 'obstacle',
      className: 'table-col-data'
    },
    {
      title: 'Source',
      dataIndex: 'source',
      key: 'source',
      className: 'table-col-data'
    },
    {
      title: 'Tolerance / Accuracy',
      align: 'center',
      key: 'tolerance',
      children: [
        {
          title: 'Vertical',
          dataIndex: 'vertical',
          key: 'vertical',
          align: 'center',
          className: 'table-col-data',
          width: 150,
          render: (vt: string | number | null) => {
            return vt ? `${vt} M (${(Number(vt) * 3.28084).toFixed(0)} ft)` : '';
          },
          editable: (_, index) => index !== 0
        },
        {
          title: 'Horizontal',
          dataIndex: 'horizontal',
          key: 'horizontal',
          align: 'center',
          className: 'table-col-data',
          width: 150,
          render: (ht: string | number | null) => {
            return ht ? `${ht} M (${(Number(ht) * 3.28084).toFixed(0)} ft)` : '';
          },
          editable: true
        }
      ],
      className: 'table-col-data'
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      key: 'remarks',
      className: 'table-col-data'
    }
  ];

  const handleSave = (row: ObstacleDataType) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row
    });
    setDataSource(newData);
    handleSaveData(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell
    }
  };

  const mapColumns = (col) => {
    // if (!col.editable) {
    //   return col;
    // }
    const newCol = {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave
      })
    };
    if (col.children) {
      newCol.children = col.children.map(mapColumns);
    }
    return newCol;
  };

  const columns = defaultColumns.map(mapColumns);

  return (
    <Table
      components={components}
      rowClassName={() => 'editable-row table-row-data'}
      dataSource={dataSource}
      pagination={false}
      columns={columns as ColumnTypes}
    />
  );
};

export default TerrainSurveyObsTable;

import { useEffect, useMemo, useRef, useState } from 'react';
import { FeatureGroup, MapContainer } from 'react-leaflet';
import { useNavigate } from 'react-router-dom';
import { filter, includes, isEmpty, keys, map, orderBy, remove, uniq } from 'lodash';
import moment, { Moment } from 'moment';
import { Button, Card, Divider, Space, Typography } from 'antd';
import ArrowleftIcon from 'assets/svg/ArrowleftIcon';
import ExpandIcon from 'assets/svg/ExpandIcon';
import { fixADdataKey } from 'constants/designData.constants';
import { YearFormat, defaultCenter } from 'constants/common.constant';
import { routes } from 'constants/pageRoutes.constants';
import { LightTriangleIcon } from 'constants/map.constants';
import {
  airportDataCategories,
  airspaceDataCategories,
  airspaceDataLabel
} from 'constants/collectData.constant';
import { blue } from 'constants/color.constant';
import CheckboxCard from 'components/core/CheckboxCard';
import CollectADDataTable from 'components/CollectADDataTable/CollectADDataTable';
import AixmObstaclesAdData from 'components/Map/AixmObstaclesAdData';
import ControlMapCenter from 'components/Map/ControlMapCenter';
import MapActionControlV1 from 'components/Map/MapActionControlV1';
import MapLayers from 'components/Map/MapLayers';
import LineStringComponent from 'components/Map/LineStringComponent';
import LiveCoordinates from 'components/Map/LiveCoordinates';
import RenderControlledAirspace from 'components/Map/RenderControlledAirspace';
import RenderNavaidsAirspace from 'components/Map/RenderNavaidsAirspace';
import RenderWayPointsAirspace from 'components/Map/RenderWayPointsAirspace';
import RenderFIRAirspace from 'components/Map/RenderFIRAirspace';
import RenderRestrictiveAirspace from 'components/Map/RenderRestrictiveAirspace';
import RenderRNAVRouteAirspace from 'components/Map/RenderRNAVRouteAirspace';
import RenderMilitryAirspace from 'components/Map/RenderMilitryAirspace';
import RenderCNAVRouteAirspace from 'components/Map/RenderCNAVRouteAirspace';
import RenderADIZAirspace from 'components/Map/RenderADIZAirspace';
import RenderARPStartEndPoint from 'components/Map/RenderARPStartEndPoint';
import ARPPolyline from 'components/Map/ARPPolyline';
import RenderSurveyObstaclePoint from 'components/Map/RenderSurveyObstaclePoint';
import TerrainWMTSTileLayer from 'components/Map/TerrainWMTSTileLayer';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import {
  useLazyGetDesignTemperaturedataQuery,
  useLazyGetDesignMetadataLoadQuery,
  useLazyGetMagneticDeclinationQuery
} from 'services/apis/designsApi';
import {
  setADData,
  setAirspaceData,
  setAvailableADData,
  setAvailableAirspaceData,
  setTemperatureData
} from 'store/design';
import {
  DesignState,
  ObstacleType,
  PreviewSurveyObstacleType,
  SurveyObstacleType,
  VersionType
} from 'store/design/types';
import { CollectDataType, Maptype } from 'types/common.types';
import { GET_GEOSERVER_LEGEND_URL } from 'utils/apiUrls';
import { isFloat } from 'utils/utils';
import CollectObstactleDataTable from './CollectObstactleDataTable';
import MapFullScreenView from './MapFullScreenView';
import TableFullScreenView from './TableFullScreenView';
import SurveyObstacleDatataTable from './SurveyObstacleDatataTable';
import CollectObstacleDataTab from './CollectObstacleDataTab';
import CollectTemperatureDataTab from './CollectTemperatureDataTab';
import CollectMagneticVariationDataTab from './CollectMagneticVariationDataTab';
import MagneticDeclinationDataStock from './MagneticDeclinationDataStock';

const { Text } = Typography;

interface CollectProps {
  isDesigner: boolean;
  designData: any;
  collectDesignMetadata: any;
  collectDesignAllMetadata: any;
  versionsData: any;
  initInfoCollectData: any;
  surveyObstacleData: SurveyObstacleType[] | [];
  currentMetaId: number | null;
  airportLat: number | null;
  airportLng: number | null;
  tmin: number | string | null;
  tmax: number | string | null;
  // airportId: number | null;
  handleCollapseActiveKey: (data: string[] | []) => void;
  handleCurrentVersion: (versionId: number | null) => void;
  handleChangeTmin: (min: number | string | null) => void;
  handleChangeTmax: (max: number | string | null) => void;
  handleLoading: (load: boolean) => void;
  adDatacheckedList: string[];
  handleSetAdDataCheckedList: (data: string[]) => void;
  airspaceDatacheckedList: string[];
  handleSetAirspaceDataCheckedList: (data: string[]) => void;
  surveyFile: File | undefined;
  handleSelectSurveyFile: (file: File | undefined) => void;
  handleChangeMagneticVariation: (value: any) => void;
  magneticVariation: any;
  handleAddtoStockMagneticVar: (isAdd: boolean) => void;
  handleAddSurveyFileObsDataToStock: Function;
  handleChangeMagneticUnit: (magneticUnit: string) => void;
  handleSetMagneticVariationModal: (magneticModal: string) => void;
  handleSetMagneticModalDate: (modalDate: Moment | null) => void;
  magneticVariationModal: string;
  magneticModalDate: Moment | null;
  magneticDeclinationUnit: string;
  nominalCircles: any;
}

const Collect = ({
  isDesigner,
  // eslint-disable-next-line
  currentMetaId,
  designData,
  tmin,
  tmax,
  airportLat,
  airportLng,
  collectDesignMetadata,
  surveyObstacleData,
  // eslint-disable-next-line
  collectDesignAllMetadata,
  versionsData,
  initInfoCollectData,
  adDatacheckedList,
  airspaceDatacheckedList,
  // airportId,
  surveyFile,
  handleCollapseActiveKey,
  handleCurrentVersion,
  handleChangeTmin,
  handleChangeTmax,
  handleLoading,
  handleSetAdDataCheckedList,
  handleSetAirspaceDataCheckedList,
  handleSelectSurveyFile,
  handleChangeMagneticVariation,
  magneticVariation,
  handleAddtoStockMagneticVar,
  handleAddSurveyFileObsDataToStock,
  handleChangeMagneticUnit,
  magneticDeclinationUnit,
  nominalCircles,
  handleSetMagneticVariationModal,
  magneticVariationModal,
  handleSetMagneticModalDate,
  magneticModalDate
}: CollectProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const boundFeatureGroupRef: any = useRef();
  const { availableADData, availableAirspaceData, designTemperature, selectedDesign }: DesignState =
    useAppSelector((state) => state.design);
  const [activeTab, setActiveTab] = useState<string>('addata');
  const [activeObsSurveyBtn, setActiveObsSurveyBtn] = useState<string>('all');
  const [obstacleActiveBtn, setObstacleActiveBtn] = useState<string>('terrain');
  const [obstacleActiveTabData, setObstacleActiveTabData] = useState<string>('');
  const [versions, setVersions] = useState<VersionType[] | []>([]);
  const [metadataLoadObstacleData, setMetadataLoadObstacleData] = useState<ObstacleType[] | []>([]);
  const [activeVersionId, setActiveVersionId] = useState<number | undefined>();
  const [mapType, setMapType] = useState<string>(Maptype.satellite);
  const [availableDataActiveCodeId, setAvailableDataActiveCodeId] = useState<string | null>(null);
  const [mapExpandView, setMapExpandView] = useState<boolean>(false);
  const [tableExpandView, setTableExpandView] = useState<boolean>(false);
  const [mapCenter, setMapCenter] = useState<any>(defaultCenter);
  const [zoomLevel, setZoomLevel] = useState<number>(11);
  const [isTerrainDataViewonmap, setTerrainDataViewonmap] = useState<boolean>(false);
  const [surveyFileObsData, setSurveyFileObsData] = useState<PreviewSurveyObstacleType[] | []>([]);
  const [isVariationCalculate, setVariationCalculate] = useState<boolean>(false);
  const [terrainLayerName, setTerrainLayerName] = useState<string | null>(null);
  const [fliterObsSurveyData, setFliterObsSurveyData] = useState<SurveyObstacleType[] | []>([
    ...surveyObstacleData
  ]);

  const [
    getDesignMetadataLoad,
    { data: loadMetadataData, isSuccess: isSuccessLoadData, isFetching: isFetchingLoadMetadata }
  ] = useLazyGetDesignMetadataLoadQuery();
  const [
    getDesignTemperaturedata,
    { data: temperatureData, isFetching: isFetchingTemperaturedata }
  ] = useLazyGetDesignTemperaturedataQuery();

  const [
    getMagneticDeclination,
    { data: magneticDeclinationdata, isFetching: isFetchingMagneticDeclination }
  ] = useLazyGetMagneticDeclinationQuery();

  useEffect(() => {
    if (isEmpty(surveyFile)) {
      setObstacleActiveTabData('');
      setTerrainDataViewonmap(false);
      setSurveyFileObsData([]);
    }
  }, [surveyFile]);

  useEffect(() => {
    handleLoading(isFetchingTemperaturedata || isFetchingLoadMetadata);
  }, [isFetchingLoadMetadata, isFetchingTemperaturedata]);

  useEffect(() => {
    if (airportLat && airportLng) {
      setTimeout(() => {
        setMapCenter([airportLat, airportLng] || defaultCenter);
        setZoomLevel(14);
      }, 500);
    }
  }, [airportLat, airportLng, selectedDesign]);

  useEffect(() => {
    if (temperatureData) {
      const temperatureDataRes: any = temperatureData;
      if (temperatureDataRes.status) {
        handleChangeTmin(!isEmpty(temperatureDataRes.data) ? temperatureDataRes.data.tmin : null);
        handleChangeTmax(!isEmpty(temperatureDataRes.data) ? temperatureDataRes.data.tmax : null);
      }
    }
  }, [temperatureData]);

  useEffect(() => {
    if (magneticDeclinationdata) {
      const magneticDeclinationDataRes: any = magneticDeclinationdata;
      if (magneticDeclinationDataRes.status) {
        handleChangeMagneticVariation(magneticDeclinationDataRes.data);
      }
    }
  }, [magneticDeclinationdata]);

  useEffect(() => {
    if (loadMetadataData) {
      const loadMetadataDataRes: any = loadMetadataData;
      if (loadMetadataDataRes.status && isSuccessLoadData) {
        const data: any = {};
        data[`${availableDataActiveCodeId}`] = loadMetadataDataRes.data;

        if (activeTab === 'addata') {
          if (availableDataActiveCodeId === 'aixmobstacles') {
            setMetadataLoadObstacleData(loadMetadataDataRes.data || []);
          } else if (availableDataActiveCodeId !== 'aixmobstacles') {
            dispatch(
              setAvailableADData({
                ...availableADData,
                [`${availableDataActiveCodeId}`]: loadMetadataDataRes.data
              })
            );
          }
        } else if (activeTab === 'airspace') {
          dispatch(
            setAvailableAirspaceData({
              ...availableAirspaceData,
              [`${availableDataActiveCodeId}`]: loadMetadataDataRes.data
            })
          );
        }
      }
    }
  }, [isSuccessLoadData, loadMetadataData]);

  useEffect(() => {
    if (versionsData) {
      const versionRes: any = versionsData;
      if (versionRes.status) {
        const getVersions = orderBy(versionRes.data, ['id'], ['desc']).slice(0, 3);
        if (!isEmpty(getVersions)) {
          setVersions(getVersions);
          setActiveVersionId(getVersions[0].id);
          handleCurrentVersion(getVersions[0].id);
        }
      }
    }
  }, [versionsData]);

  useEffect(() => {
    if (collectDesignMetadata) {
      const designMetadataRes: any = collectDesignMetadata;
      if (designMetadataRes.status) {
        if (isEmpty(designMetadataRes.data)) return;
        if (designMetadataRes.data && designMetadataRes.data.eaip_version) {
          setActiveVersionId(Number(designMetadataRes.data.eaip_version));
        }
        const adDatakeys = keys(designMetadataRes.data?.ad_json);
        const airspaceDatakeys = keys(designMetadataRes.data?.airspace_geometry);
        const tempData = designMetadataRes.data?.tempreature;

        handleChangeTmin(!isEmpty(tempData) ? tempData.tmin : null);
        handleChangeTmax(!isEmpty(tempData) ? tempData.tmax : null);
        dispatch(setTemperatureData(!isEmpty(tempData) ? tempData : null));
        handleSetAdDataCheckedList([...adDatakeys]);
        dispatch(setADData(uniq([...adDatakeys])));
        dispatch(setAvailableADData(designMetadataRes.data?.ad_json || null));
        handleSetAirspaceDataCheckedList([...airspaceDatakeys]);
        dispatch(setAirspaceData(uniq([...airspaceDatakeys])));
        dispatch(setAvailableAirspaceData(designMetadataRes.data?.airspace_geometry || null));
        handleChangeMagneticVariation(designMetadataRes.data.magnetic_declination || null);
        handleAddtoStockMagneticVar(Boolean(designMetadataRes.data.magnetic_declination));
        setTerrainLayerName(designMetadataRes.data?.terrain_layer_name || null);
        handleSetMagneticVariationModal(designMetadataRes.data?.magnetic_model || null);
        handleSetMagneticModalDate(designMetadataRes.data?.magnetic_varation_date || null);
      }
    }
  }, [collectDesignMetadata]);

  const handleBackNavigate = () => {
    dispatch(setADData([]));
    dispatch(setAvailableADData(null));
    dispatch(setAirspaceData([]));
    dispatch(setAvailableAirspaceData(null));
    dispatch(setTemperatureData(null));
    navigate(routes.DesignList);
  };

  const handleChangeBtnTab = async (value: string) => {
    if (value === 'temperature') {
      if (isEmpty(designTemperature)) {
        if (tmin && tmax) return;
        await getDesignTemperaturedata({
          airportLat: isFloat(mapCenter[0])
            ? mapCenter[0]
            : (Math.round(mapCenter[0] * 100) / 100).toFixed(2),
          airportLng: isFloat(mapCenter[1])
            ? mapCenter[1]
            : (Math.round(mapCenter[1] * 100) / 100).toFixed(2)
        });
      }
    }
  };

  const handleChangeADData = (item: CollectDataType) => {
    if (includes(adDatacheckedList, item.value)) {
      remove(adDatacheckedList, (adDataVal: string) => adDataVal === item.value);
    } else {
      const advalue = item.value;
      adDatacheckedList.push(advalue);
    }
    handleSetAdDataCheckedList([...adDatacheckedList]);
  };

  const handleChangeAirspaceData = (item: CollectDataType) => {
    if (includes(airspaceDatacheckedList, item.codeID)) {
      remove(airspaceDatacheckedList, (airspaceData: string) => airspaceData === item.codeID);
    } else {
      const value = item.codeID;
      // @ts-ignore
      airspaceDatacheckedList.push(value);
    }
    handleSetAirspaceDataCheckedList([...airspaceDatacheckedList]);
  };

  const handleSelectAllAvailableData = () => {
    const arr: string[] = [];
    if (activeTab === 'addata') {
      map(airportDataCategories, (data: CollectDataType) => arr.push(data.value));
      handleSetAdDataCheckedList(uniq([...arr]));
      handleCollapseActiveKey(['addata']);
    } else if (activeTab === 'airspace') {
      map(airspaceDataCategories, (data: CollectDataType) => arr.push(data.codeID));
      handleSetAirspaceDataCheckedList([...arr]);
      handleCollapseActiveKey(['airspacedata']);
    } else if (activeTab === 'temperature') {
      handleCollapseActiveKey(['temperaturedata']);
    }
  };

  const handleactiveCodeIdFnc = (value: string) => {
    if (availableDataActiveCodeId === value) {
      setAvailableDataActiveCodeId(null);
    } else {
      setAvailableDataActiveCodeId(value);
    }
  };

  const handleClickMetadataLoadBtn = (value: string) => {
    handleactiveCodeIdFnc(value);
    if (availableDataActiveCodeId === value) return;

    const existavailableADDataKeys = availableADData ? Object.keys(availableADData) : [];
    const existavailableAirspaceDataKeys = availableAirspaceData
      ? Object.keys(availableAirspaceData)
      : [];
    if (activeTab === 'addata') {
      if (existavailableADDataKeys.includes(value)) return;
    } else if (activeTab === 'airspace') {
      if (existavailableAirspaceDataKeys.includes(value)) return;
    }

    const versionId: number | null =
      !isEmpty(versionsData) && !isEmpty(versionsData.data)
        ? orderBy(versionsData.data, ['id'], ['desc']).slice(0, 1)[0].id
        : null;
    const airportId: number | null =
      !isEmpty(designData) && !isEmpty(designData.data) ? designData.data.airport_id : null;
    const airportLat: number | null =
      !isEmpty(designData) && !isEmpty(designData.data) ? designData.data.latitude : null;
    const airportLng: number | null =
      !isEmpty(designData) && !isEmpty(designData.data) ? designData.data.longitude : null;

    if (versionId && airportId && airportLat && airportLng) {
      let urlstring = `inetra/versions/${versionId}/airports/${airportId}/type/${value}/load`;

      if (activeTab === 'airspace') {
        const params: any = new URLSearchParams({
          buffer: '150',
          lat: airportLat.toString(),
          lng: airportLng.toString()
        });
        urlstring = `inetra/versions/${versionId}/airports/${airportId}/type/${value}/load?${params.toString()}`;
      }

      getDesignMetadataLoad(urlstring);
    }
  };

  const handleClearAllAvailableData = () => {
    setAvailableDataActiveCodeId(null);
    if (activeTab === 'addata') {
      handleSetAdDataCheckedList([
        'geoadministrative',
        'runwayphysicals',
        'declareddistaces',
        'aixmobstacles'
      ]);
      dispatch(
        setADData(['geoadministrative', 'runwayphysicals', 'declareddistaces', 'aixmobstacles'])
      );
      handleCollapseActiveKey(['addata']);
    } else if (activeTab === 'airspace') {
      handleCollapseActiveKey(['airspacedata']);
      handleSetAirspaceDataCheckedList([]);
      dispatch(setAirspaceData([]));
    }
  };

  const handleMapType = (type: string) => {
    setMapType(type);
  };

  const handleOnMapClose = () => {
    setMapExpandView(false);
  };

  const handleOnTableClose = () => {
    setTableExpandView(false);
  };

  const handleADDATAAddtoStock = () => {
    handleCollapseActiveKey(['addata']);
    dispatch(setADData([...adDatacheckedList]));
  };

  const handleAirspaceDATAAddtoStock = () => {
    handleCollapseActiveKey(['airspacedata']);
    dispatch(setAirspaceData([...airspaceDatacheckedList]));
  };

  const handleTemperatureDATAAddtoStock = () => {
    handleCollapseActiveKey(['temperaturedata']);
  };

  const handleObstaclesDATAAddtoStock = () => {
    handleCollapseActiveKey(['obstacle']);
    handleAddSurveyFileObsDataToStock(true);
  };

  const handleSelectActiveObsSurveyBtn = (activeBtn: string) => {
    setActiveObsSurveyBtn(activeBtn);
    if (activeBtn === 'eaip') {
      const showEaip = filter([...surveyObstacleData], ['is_eaip', true]);
      setFliterObsSurveyData(showEaip);
    } else if (activeBtn === 'survey') {
      const showSurvey = filter([...surveyObstacleData], ['is_eaip', false]);
      setFliterObsSurveyData(showSurvey);
    } else if (activeBtn === 'all') {
      setFliterObsSurveyData([...surveyObstacleData]);
    }
  };

  const handleChangeObstacleActiveButtonData = (activeBtn: string) => {
    setObstacleActiveBtn(activeBtn);
    setObstacleActiveTabData('');
    setTerrainDataViewonmap(false);
    handleSelectSurveyFile(undefined);
    setSurveyFileObsData([]);
    handleSelectActiveObsSurveyBtn('all');
  };

  const handleObstacleDataViewOnMap = () => {
    if (obstacleActiveTabData === 'srtm') {
      setTerrainDataViewonmap(true);
    }
  };

  const handleChangeMagneticModal = (modalValue: string) => {
    handleSetMagneticVariationModal(modalValue);
    handleSetMagneticModalDate(null);
    setVariationCalculate(false);
    handleChangeMagneticVariation(null);
  };

  const handleChangeModalDate = (date: Moment | null) => {
    handleSetMagneticModalDate(date);
  };

  const handleSelectMagneticCalculate = () => {
    setVariationCalculate(true);
    if (!isEmpty(initInfoCollectData)) {
      getMagneticDeclination({
        lat: initInfoCollectData.arp[0],
        lng: initInfoCollectData.arp[1],
        year: moment(magneticModalDate).format(YearFormat),
        month: moment(magneticModalDate).format('MM'),
        day: moment(magneticModalDate).format('DD'),
        elevation: initInfoCollectData.der_elev,
        model: magneticVariationModal
      });
    }
  };

  const mapContainer = useMemo(() => {
    const selectedAirspaceData =
      availableAirspaceData &&
      availableDataActiveCodeId &&
      !isEmpty(availableAirspaceData[availableDataActiveCodeId])
        ? availableAirspaceData[availableDataActiveCodeId]
        : [];

    return (
      <MapContainer
        center={mapCenter}
        zoom={zoomLevel}
        maxZoom={15}
        minZoom={9}
        zoomControl={false}
        className="map-container collect-map-container"
      >
        <FeatureGroup ref={boundFeatureGroupRef}>
          <MapActionControlV1 mapType={mapType} handleMapType={handleMapType} />
          {!isEmpty(initInfoCollectData) && (
            <>
              <RenderARPStartEndPoint
                initInfoCollectData={initInfoCollectData}
                ringColor="#27A4FF"
                mapType={mapType}
              />
              <ARPPolyline
                arpCoordinates={
                  !isEmpty(initInfoCollectData)
                    ? [
                        [
                          initInfoCollectData.startpoint.coordinates[0],
                          initInfoCollectData.startpoint.coordinates[1]
                        ],
                        [
                          initInfoCollectData.endpoint.coordinates[0],
                          initInfoCollectData.endpoint.coordinates[1]
                        ]
                      ]
                    : null
                }
                decoratorIcon={LightTriangleIcon}
                weight={3}
              />
            </>
          )}
          <AixmObstaclesAdData
            obstacleData={
              !isEmpty(metadataLoadObstacleData) && availableDataActiveCodeId === 'aixmobstacles'
                ? metadataLoadObstacleData
                : availableADData &&
                  availableDataActiveCodeId === 'aixmobstacles' &&
                  !isEmpty(availableADData[availableDataActiveCodeId])
                ? availableADData[availableDataActiveCodeId]
                : []
            }
          />
          {availableDataActiveCodeId === airspaceDataLabel.ctrl && (
            <RenderControlledAirspace ctrlAirspaceData={selectedAirspaceData} />
          )}
          {availableDataActiveCodeId === airspaceDataLabel.geoNavaid && (
            <RenderNavaidsAirspace navaidAirspaceData={selectedAirspaceData} />
          )}
          {availableDataActiveCodeId === airspaceDataLabel.waypoint && (
            <RenderWayPointsAirspace waypointAirspaceData={selectedAirspaceData} />
          )}
          {availableDataActiveCodeId === airspaceDataLabel.fir && (
            <RenderFIRAirspace firAirspaceData={selectedAirspaceData} />
          )}
          {availableDataActiveCodeId === airspaceDataLabel.restrictive && (
            <RenderRestrictiveAirspace restrictiveAirspaceData={selectedAirspaceData} />
          )}
          {availableDataActiveCodeId === airspaceDataLabel.routeRNAV && (
            <RenderRNAVRouteAirspace routeRNAVAirspaceData={selectedAirspaceData} />
          )}
          {availableDataActiveCodeId === airspaceDataLabel.routeCNAV && (
            <RenderCNAVRouteAirspace routeCNAVAirspaceData={selectedAirspaceData} />
          )}
          {availableDataActiveCodeId === airspaceDataLabel.military && (
            <RenderMilitryAirspace militryAirspaceData={selectedAirspaceData} />
          )}
          {availableDataActiveCodeId === airspaceDataLabel.adiz && (
            <RenderADIZAirspace adizAirspaceData={selectedAirspaceData} />
          )}
          {activeTab === 'obstacle' &&
            obstacleActiveBtn === 'survey' &&
            isEmpty(surveyFileObsData) &&
            !isEmpty(surveyObstacleData) && (
              <RenderSurveyObstaclePoint obstacleData={surveyObstacleData} />
            )}
          {activeTab === 'obstacle' &&
            obstacleActiveBtn === 'survey' &&
            !isEmpty(surveyFileObsData) && (
              <RenderSurveyObstaclePoint obstacleData={surveyFileObsData} />
            )}
          {isTerrainDataViewonmap &&
            obstacleActiveBtn === 'terrain' &&
            obstacleActiveTabData === 'srtm' && (
              <TerrainWMTSTileLayer
                WMTSStyle={
                  !isEmpty(terrainLayerName) ? terrainLayerName : `cfnpd:${selectedDesign?.icao}`
                }
                isTerrainDataViewonmap={isTerrainDataViewonmap}
                WMTSPane="shadowPane"
              />
            )}
          {!isEmpty(nominalCircles) && !isEmpty(nominalCircles?.circle_15_nm) && (
            <LineStringComponent
              coordinates={nominalCircles.circle_15_nm}
              color={blue}
              dashArray={[5, 5]}
              weight={2}
            />
          )}
          {!isEmpty(nominalCircles) && !isEmpty(nominalCircles?.circle_30_nm) && (
            <LineStringComponent
              coordinates={nominalCircles.circle_30_nm}
              color={blue}
              dashArray={[5, 5]}
              weight={2}
            />
          )}
          <MapLayers maptype={mapType} />
          <LiveCoordinates />
          <ControlMapCenter zoomLevel={zoomLevel} center={mapCenter} />
        </FeatureGroup>
      </MapContainer>
    );
  }, [
    mapCenter,
    zoomLevel,
    mapType,
    mapExpandView,
    availableDataActiveCodeId,
    boundFeatureGroupRef,
    availableADData,
    initInfoCollectData,
    metadataLoadObstacleData,
    availableAirspaceData,
    surveyObstacleData,
    activeTab,
    surveyFileObsData,
    isTerrainDataViewonmap,
    obstacleActiveTabData,
    obstacleActiveBtn,
    nominalCircles
  ]);

  const adDataCheckboxcard = useMemo(() => {
    return airportDataCategories.map((item: CollectDataType) => {
      return (
        <CheckboxCard
          key={item.id}
          isChecked={includes(adDatacheckedList, item.value)}
          data={item}
          handleCheckboxClick={includes(fixADdataKey, item.value) ? () => {} : handleChangeADData}
          handleClickMetadataLoadBtn={handleClickMetadataLoadBtn}
          activeCodeId={availableDataActiveCodeId}
        />
      );
    });
  }, [adDatacheckedList, availableDataActiveCodeId, activeTab]);

  const airSpaceDataCheckboxcard = useMemo(() => {
    return airspaceDataCategories.map((item: CollectDataType) => (
      <CheckboxCard
        key={item.id}
        isChecked={includes(airspaceDatacheckedList, item.codeID)}
        data={item}
        handleCheckboxClick={handleChangeAirspaceData}
        handleClickMetadataLoadBtn={handleClickMetadataLoadBtn}
        activeCodeId={availableDataActiveCodeId}
      />
    ));
  }, [airspaceDatacheckedList, availableDataActiveCodeId, activeTab]);

  const temperatureDataContent = useMemo(() => {
    return (
      <CollectTemperatureDataTab
        designTemperature={designTemperature}
        tmin={tmin}
        tmax={tmax}
        handleChangeTmin={handleChangeTmin}
        handleChangeTmax={handleChangeTmax}
      />
    );
  }, [availableDataActiveCodeId, tmin, tmax, designTemperature, activeTab]);

  const obstacleDataContent = useMemo(
    () => (
      <CollectObstacleDataTab
        handleChangeObstacleActiveButtonData={handleChangeObstacleActiveButtonData}
        obstacleActiveBtn={obstacleActiveBtn}
        obstacleActiveTabData={obstacleActiveTabData}
        surveyFile={surveyFile}
        selectedDesign={selectedDesign}
        handleObstacleDataViewOnMap={handleObstacleDataViewOnMap}
        setObstacleActiveTabData={setObstacleActiveTabData}
        handleSelectSurveyFile={handleSelectSurveyFile}
        setSurveyFileObsData={setSurveyFileObsData}
      />
    ),
    [
      availableDataActiveCodeId,
      activeTab,
      obstacleActiveBtn,
      obstacleActiveTabData,
      surveyFile,
      isTerrainDataViewonmap
    ]
  );

  const magneticDataContent = useMemo(
    () => (
      <CollectMagneticVariationDataTab
        handleChangeMagneticModal={handleChangeMagneticModal}
        magneticVariationModal={magneticVariationModal}
        handleChangeModalDate={handleChangeModalDate}
        magneticModalDate={magneticModalDate}
        handleSelectMagneticCalculate={handleSelectMagneticCalculate}
        initInfoCollectData={initInfoCollectData}
        isFetchingMagneticDeclination={isFetchingMagneticDeclination}
      />
    ),
    [
      availableDataActiveCodeId,
      activeTab,
      magneticVariationModal,
      magneticVariation,
      magneticModalDate,
      isVariationCalculate,
      isFetchingMagneticDeclination
    ]
  );

  return (
    <div className="collect-main">
      <div className="collect-upper">
        <div className="collect-page-header">
          <Button
            type="text"
            shape="circle"
            icon={<ArrowleftIcon />}
            onClick={handleBackNavigate}
            className="back-icon-btn"
          />
          <Text>Available Data</Text>
          <Button type="primary" ghost disabled>
            User Defined Data
          </Button>
        </div>
        <div className="collect-upper-content">
          <div>
            <Button
              type="text"
              onClick={() => {
                setActiveTab('addata');
                handleCollapseActiveKey(['addata']);
                setAvailableDataActiveCodeId(null);
                setObstacleActiveTabData('');
                setObstacleActiveBtn('terrain');
              }}
              className={`${
                activeTab === 'addata' ? 'collect-btn collect-active-btn' : 'collect-btn'
              }`}
            >
              Aerodrome
            </Button>
            <Button
              type="text"
              onClick={() => {
                setActiveTab('airspace');
                handleCollapseActiveKey(['airspacedata']);
                setAvailableDataActiveCodeId(null);
                setObstacleActiveTabData('');
                setObstacleActiveBtn('terrain');
              }}
              className={`${
                activeTab === 'airspace' ? 'collect-btn collect-active-btn' : 'collect-btn'
              }`}
            >
              Airspace
            </Button>
            <Button
              type="text"
              onClick={() => {
                setActiveTab('obstacle');
                handleCollapseActiveKey(['obstacle']);
                setAvailableDataActiveCodeId(null);
                setObstacleActiveTabData('');
                setObstacleActiveBtn('terrain');
              }}
              className={`${
                activeTab === 'obstacle' ? 'collect-btn collect-active-btn' : 'collect-btn'
              }`}
            >
              Obstacle Data
            </Button>
            <Button
              type="text"
              onClick={() => {
                setActiveTab('magnetic');
                handleCollapseActiveKey(['magnetic']);
                setAvailableDataActiveCodeId(null);
                setObstacleActiveTabData('');
                setObstacleActiveBtn('terrain');
              }}
              className={`${
                activeTab === 'magnetic' ? 'collect-btn collect-active-btn' : 'collect-btn'
              }`}
            >
              Magnetic Variation
            </Button>
            <Button
              type="text"
              onClick={() => {
                setActiveTab('temperature');
                handleChangeBtnTab('temperature');
                handleCollapseActiveKey(['temperaturedata']);
                setAvailableDataActiveCodeId(null);
                setObstacleActiveTabData('');
                setObstacleActiveBtn('terrain');
              }}
              className={`${
                activeTab === 'temperature' ? 'collect-btn collect-active-btn' : 'collect-btn'
              }`}
            >
              Temperature
            </Button>
            <Button
              type="text"
              onClick={() => {
                setActiveTab('aicraft');
                handleCollapseActiveKey(['aircraft']);
                setAvailableDataActiveCodeId(null);
                setObstacleActiveTabData('');
                setObstacleActiveBtn('terrain');
              }}
              className={`${
                activeTab === 'aicraft' ? 'collect-btn collect-active-btn' : 'collect-btn'
              }`}
            >
              Aircraft Performance
            </Button>
            <Button
              type="text"
              onClick={() => {
                setActiveTab('uploaded');
                handleChangeBtnTab('uploaded');
                handleCollapseActiveKey(['uploadeddata']);
                setAvailableDataActiveCodeId(null);
                setObstacleActiveTabData('');
                setObstacleActiveBtn('terrain');
              }}
              className={`${
                activeTab === 'uploaded' ? 'collect-btn collect-active-btn' : 'collect-btn'
              }`}
            >
              User Defined Data
            </Button>
          </div>
          <div className="main-tab-content">
            {(activeTab === 'addata' || activeTab === 'airspace') && (
              <Card title="" className="version-lists" size="small" style={{ width: 159 }}>
                {versions &&
                  versions.map((version: VersionType, i: number) => {
                    return (
                      <Text
                        disabled={Boolean(i !== 0)}
                        className={`${
                          Number(activeVersionId) === version.id ? 'active-cycle' : 'airac-cycle'
                        }`}
                        key={version.id}
                      >
                        {i === 0 ? `${version.effective_date} (New)` : version.effective_date}
                      </Text>
                    );
                  })}
              </Card>
            )}
            <div
              className={
                activeTab === 'addata' || activeTab === 'airspace'
                  ? 'list-content-btn'
                  : 'list-content-btn data-list-btn'
              }
            >
              <Space size={[16, 16]} wrap style={{ width: '100%' }}>
                {activeTab === 'addata' && adDataCheckboxcard}
                {activeTab === 'airspace' && airSpaceDataCheckboxcard}
                {activeTab === 'temperature' && temperatureDataContent}
                {activeTab === 'obstacle' && obstacleDataContent}
                {activeTab === 'magnetic' && magneticDataContent}
                {/* {activeTab === 'aicraft' && <Space>No data</Space>}
                {activeTab === 'uploaded' && <Space>No data</Space>} */}
              </Space>
              {activeTab !== 'magnetic' && (
                <div className="all-btn">
                  {(activeTab === 'addata' || activeTab === 'airspace') && (
                    <>
                      <Button
                        type="text"
                        className="clear-all-btn"
                        disabled={!isDesigner}
                        onClick={handleClearAllAvailableData}
                      >
                        Clear All
                      </Button>
                      <Button
                        className="select-all-btn"
                        type="primary"
                        disabled={!isDesigner}
                        ghost
                        onClick={handleSelectAllAvailableData}
                      >
                        Select All
                      </Button>
                    </>
                  )}
                  {activeTab === 'addata' && !isEmpty(adDatacheckedList) ? (
                    <Button
                      className="add-datastock-btn"
                      type="primary"
                      disabled={!isEmpty(designTemperature) || !isDesigner}
                      onClick={isDesigner ? handleADDATAAddtoStock : () => {}}
                    >
                      Add to Data Stock
                    </Button>
                  ) : activeTab === 'airspace' && !isEmpty(airspaceDatacheckedList) ? (
                    <Button
                      className="add-datastock-btn"
                      type="primary"
                      disabled={!isEmpty(designTemperature) || !isDesigner}
                      onClick={isDesigner ? handleAirspaceDATAAddtoStock : () => {}}
                    >
                      Add to Data Stock
                    </Button>
                  ) : activeTab === 'temperature' ? (
                    <Button
                      className="add-datastock-btn"
                      type="primary"
                      disabled={!isEmpty(designTemperature) || !isDesigner}
                      onClick={isDesigner ? handleTemperatureDATAAddtoStock : () => {}}
                    >
                      Add to Data Stock
                    </Button>
                  ) : activeTab === 'obstacle' ? (
                    <Button
                      className="add-datastock-btn"
                      type="primary"
                      disabled={isEmpty(surveyFileObsData) || !isDesigner}
                      onClick={isDesigner ? handleObstaclesDATAAddtoStock : () => {}}
                    >
                      Add to Data Stock
                    </Button>
                  ) : (
                    <Button
                      className="add-datastock-btn"
                      type="primary"
                      style={{ color: '#696B72' }}
                      disabled
                    >
                      Add to Data Stock
                    </Button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Divider className="collect-data-divider" />
      {activeTab === 'magnetic' ? (
        <MagneticDeclinationDataStock
          magneticVariation={magneticVariation}
          magneticVariationModal={magneticVariationModal}
          designTemperature={designTemperature}
          isDesigner={isDesigner}
          handleChangeMagneticVariation={handleChangeMagneticVariation}
          isFetchingMagneticDeclination={isFetchingMagneticDeclination}
          handleAddtoStockMagneticVar={handleAddtoStockMagneticVar}
          handleChangeMagneticUnit={handleChangeMagneticUnit}
          magneticDeclinationUnit={magneticDeclinationUnit}
        />
      ) : (
        <div className="collect-lower">
          <div>
            <div className="map-view-content">
              <Text>Map View</Text>
              <div>
                <Button
                  type="text"
                  icon={<ExpandIcon />}
                  onClick={() => setMapExpandView(true)}
                  className="back-icon-btn"
                />
              </div>
              {mapExpandView && (
                <MapFullScreenView
                  mapExpandView={mapExpandView}
                  handleOnMapClose={handleOnMapClose}
                  mapcenter={mapCenter}
                  zoom={zoomLevel}
                  availableDataActiveCodeId={availableDataActiveCodeId}
                  terrainLayerName={terrainLayerName}
                  initInfoCollectData={initInfoCollectData}
                  obstacles={
                    !isEmpty(metadataLoadObstacleData)
                      ? metadataLoadObstacleData
                      : availableADData &&
                        availableDataActiveCodeId === 'aixmobstacles' &&
                        !isEmpty(availableADData[availableDataActiveCodeId])
                      ? availableADData[availableDataActiveCodeId]
                      : []
                  }
                  airspaceData={
                    availableAirspaceData &&
                    availableDataActiveCodeId &&
                    !isEmpty(availableAirspaceData[availableDataActiveCodeId])
                      ? availableAirspaceData[availableDataActiveCodeId]
                      : []
                  }
                  isTerrainDataViewonmap={isTerrainDataViewonmap}
                  obstacleActiveBtn={obstacleActiveBtn}
                  obstacleActiveTabData={obstacleActiveTabData}
                  selectedDesign={selectedDesign}
                  activeTab={activeTab}
                  surveyFileObsData={surveyFileObsData}
                  surveyObstacleData={surveyObstacleData}
                  nominalCircles={nominalCircles}
                />
              )}
            </div>
            {!mapExpandView && (
              <div style={{ position: 'relative' }}>
                {mapContainer}
                {isTerrainDataViewonmap &&
                  obstacleActiveBtn === 'terrain' &&
                  obstacleActiveTabData === 'srtm' && (
                    <div className="small-layer-legend">
                      <img
                        height="250px"
                        src={`${GET_GEOSERVER_LEGEND_URL}/${selectedDesign?.icao}`}
                        alt="layer-legend"
                      />
                    </div>
                  )}
              </div>
            )}
          </div>
          <div>
            <div className="flexJustifyBetween">
              <div className="table-view-content">
                <Text>Table View</Text>
                <div>
                  <Button
                    type="text"
                    icon={<ExpandIcon />}
                    onClick={() => setTableExpandView(true)}
                    className="back-icon-btn"
                  />
                </div>
                {tableExpandView && (
                  <TableFullScreenView
                    handleOnTableClose={handleOnTableClose}
                    tableExpandView={tableExpandView}
                    availableDataActiveCodeId={availableDataActiveCodeId}
                    metadataLoadObstacleData={metadataLoadObstacleData}
                    activeTab={activeTab}
                    surveyFile={surveyFile}
                    surveyFileObsData={surveyFileObsData}
                    obstacleActiveBtn={obstacleActiveBtn}
                    surveyObstacleData={surveyObstacleData}
                  />
                )}
              </div>
              {activeTab === 'obstacle' && obstacleActiveBtn === 'survey' && (
                <Button.Group className="table-grp-btn">
                  <Button
                    disabled={Boolean(surveyFile)}
                    className={`${
                      activeObsSurveyBtn === 'all'
                        ? 'table-data-btn all-active-btn'
                        : 'table-data-btn'
                    }`}
                    onClick={() => handleSelectActiveObsSurveyBtn('all')}
                  >
                    All
                  </Button>
                  <Button
                    disabled={Boolean(surveyFile)}
                    className={`${
                      activeObsSurveyBtn === 'eaip'
                        ? 'table-data-btn eaip-active-btn'
                        : 'table-data-btn'
                    }`}
                    onClick={() => handleSelectActiveObsSurveyBtn('eaip')}
                  >
                    eAIP
                  </Button>
                  <Button
                    disabled={Boolean(surveyFile)}
                    className={`${
                      activeObsSurveyBtn === 'survey'
                        ? 'table-data-btn survey-active-btn'
                        : 'table-data-btn'
                    }`}
                    onClick={() => handleSelectActiveObsSurveyBtn('survey')}
                  >
                    Survey
                  </Button>
                </Button.Group>
              )}
            </div>
            <div>
              {!isEmpty(availableDataActiveCodeId) ? (
                availableDataActiveCodeId === 'aixmobstacles' ? (
                  <CollectObstactleDataTable
                    tableExpandView={tableExpandView}
                    obstacleData={
                      !isEmpty(metadataLoadObstacleData)
                        ? metadataLoadObstacleData
                        : availableADData &&
                          availableDataActiveCodeId === 'aixmobstacles' &&
                          !isEmpty(availableADData[availableDataActiveCodeId])
                        ? availableADData[availableDataActiveCodeId]
                        : []
                    }
                  />
                ) : !isEmpty(availableADData) &&
                  availableDataActiveCodeId &&
                  availableDataActiveCodeId !== 'aixmobstacles' &&
                  activeTab === 'addata' ? (
                  <CollectADDataTable
                    availableDataActiveCodeId={availableDataActiveCodeId}
                    activeTab={activeTab}
                  />
                ) : (
                  <Text className="flexJustifyAlignCenter">No Data </Text>
                )
              ) : activeTab === 'obstacle' ? (
                obstacleActiveBtn === 'survey' &&
                isEmpty(surveyFileObsData) &&
                !isEmpty(fliterObsSurveyData) ? (
                  <SurveyObstacleDatataTable
                    tableExpandView={tableExpandView}
                    obstacleData={fliterObsSurveyData}
                  />
                ) : !isEmpty(surveyFileObsData) && obstacleActiveBtn === 'survey' ? (
                  <SurveyObstacleDatataTable
                    tableExpandView={tableExpandView}
                    obstacleData={surveyFileObsData}
                  />
                ) : (
                  <Text className="flexJustifyAlignCenter">No Data Selected</Text>
                )
              ) : (
                <Text className="flexJustifyAlignCenter">No Data Selected</Text>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Collect;

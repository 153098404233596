import React, { Fragment, useRef } from 'react';
import { isEmpty, keys } from 'lodash';
import { FeatureGroup, MapContainer, Marker } from 'react-leaflet';
import MapLayers from 'components/Map/MapLayers';
import RenderLegData from 'components/Map/RenderLegData';
import RFArcLine from 'components/Map/RFArcLine';
import LegBoundBox from 'components/Map/LegBoundBox';
import { ObstacleRedIcon } from 'constants/map.constants';
import { Maptype } from 'types/common.types';

const Sec11MapContainer = ({ ct, selectedDesign, sec11 }: any) => {
  const featureGroupRef: any = useRef();

  const line: any = {
    type: 'FeatureCollection',
    features: []
  };
  keys(sec11[`${ct}`]?.legs).map((legkey) => {
    const featureElm = {
      type: 'Feature',
      properties: {},
      geometry: {}
    };

    if (sec11[`${ct}`]?.legs[`${legkey}`].secondary) {
      featureElm.geometry = sec11[`${ct}`]?.legs[`${legkey}`].secondary;
    } else {
      featureElm.geometry = sec11[`${ct}`]?.legs[`${legkey}`].primary;
    }
    line.features.push(featureElm);
    return true;
  });

  return (
    <MapContainer
      className="obs-map"
      zoomControl={false}
      center={[selectedDesign?.latitude, selectedDesign?.longitude]}
      zoom={11}
    >
      <FeatureGroup ref={featureGroupRef}>
        {sec11[`${ct}`]?.legs &&
          keys(sec11[`${ct}`]?.legs).map((legkey, i) => {
            return (
              <Fragment key={`Leg-${i + 1}-${legkey}-${ct}`}>
                <RenderLegData
                  key={`${ct} ( DER - ${sec11[`${ct}`].end_point} )-${legkey}-${i + 1}`}
                  legsData={sec11[`${ct}`]?.legs}
                  activeLegKey={legkey}
                  procedure={
                    sec11[`${ct}`]?.legs[`${legkey}`].parameters.type === 'OMNI'
                      ? sec11[`${ct}`]?.legs[`${legkey}`].parameters.type
                      : 'DEPARTURE'
                  }
                  isLine
                  selectedPathTerminator={
                    sec11[`${ct}`]?.legs[`${legkey}`].parameters.type === 'RF' ? 'RF' : null
                  }
                  magneticDeclination={null}
                />
                {sec11[`${ct}`]?.legs[`${legkey}`].parameters.type === 'RF' && (
                  <RFArcLine
                    finalArcCords={
                      sec11[`${ct}`]?.legs[`${legkey}`]?.geo_parameters?.nominal_track || []
                    }
                  />
                )}
                {!isEmpty(sec11[`${ct}`]?.obstacles) &&
                  sec11[`${ct}`]?.obstacles.map((obs: any) => {
                    if (isEmpty(obs?.coordinates)) return null;
                    const coords: any = [obs?.coordinates[1], obs?.coordinates[0]];
                    return <Marker icon={ObstacleRedIcon} position={coords} />;
                  })}
              </Fragment>
            );
          })}
      </FeatureGroup>
      <MapLayers maptype={Maptype.Roadmap} />
      <LegBoundBox
        center={[selectedDesign?.latitude, selectedDesign?.longitude]}
        line={line || []}
      />
    </MapContainer>
  );
};

export default Sec11MapContainer;

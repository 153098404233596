import { Alert } from 'antd';
import { useAppDispatch } from 'hooks/useReduxHook';
import { clearAlerts, dequeueError } from 'store/alert';
import './styles.less';

interface AlertTypeProps {
  type: 'error' | 'success' | 'info' | 'warning' | undefined;
  description: string | null;
  message: string;
}

const AlertType = ({ type, description, message }: AlertTypeProps) => {
  const dispatch = useAppDispatch();

  const onClose = () => {
    dispatch(dequeueError());
    dispatch(clearAlerts());
  };

  return (
    <div className="alert-content">
      <Alert
        message={message}
        description={description}
        type={type}
        closable
        onClose={onClose}
        showIcon
        afterClose={onClose}
      />
    </div>
  );
};

export default AlertType;

import { userApiService } from 'services/apiService';
import { AddMembersPayload, UpdateUserPayload, UserRequest } from 'store/users/types';

export const userapi = userApiService.injectEndpoints({
  endpoints: (builder: any) => ({
    login: builder.mutation({
      query: (credentials: UserRequest) => ({
        url: '/login',
        method: 'POST',
        body: { ...credentials, encryptionDetails: 'PKCS1_OAEP' }
      })
    }),
    logout: builder.mutation({
      query: (refreshToken: string) => ({
        url: '/logout',
        method: 'DELETE',
        headers: { Authorization: refreshToken }
      })
    }),
    getOrganisation: builder.query({
      query: (orgId: string) => ({
        url: `/organisations/${orgId}`,
        method: 'GET'
      })
    }),
    updateUser: builder.mutation({
      query: (payload: { data: UpdateUserPayload; userId: string }) => {
        return {
          url: `/user/${payload.userId}`,
          method: 'PUT',
          body: payload.data
        };
      },
      invalidatesTags: [{ type: 'User' }]
    }),
    getOrganisationsMembers: builder.query({
      query: (orgId: number) => ({ url: `/organisations/${orgId}/members`, method: 'GET' }),
      providesTags: ['User']
    }),
    addOrganisationMembers: builder.mutation({
      query: (payload: { data: AddMembersPayload; orgId: number }) => {
        return {
          url: `/organisations/${payload.orgId}/members`,
          method: 'POST',
          body: payload.data
        };
      },
      invalidatesTags: [{ type: 'User' }]
    }),
    enableDisableOrgMember: builder.mutation({
      query: (payload: { status: boolean; orgId: number; memberId: string }) => {
        return {
          url: `/organisations/${payload.orgId}/members/${payload.memberId}/status`,
          method: 'POST',
          body: { status: payload.status }
        };
      },
      invalidatesTags: [{ type: 'User' }]
    })
  }),
  overrideExisting: true
});

export const {
  useLoginMutation,
  useGetOrganisationQuery,
  useLogoutMutation,
  useUpdateUserMutation,
  useAddOrganisationMembersMutation,
  useEnableDisableOrgMemberMutation,
  useGetOrganisationsMembersQuery
} = userapi;

// import { red2 } from 'constants/color.constant';
import { useMemo } from 'react';
import TextPath from 'react-leaflet-textpath';

interface LineTextPathProps {
  coordinates: any;
  distance: string | null;
  bearing: string | null;
  opacity?: number;
}

const LineTextPath = ({ coordinates, distance, bearing, opacity }: LineTextPathProps) => {
  const textPath = useMemo(() => {
    return (
      <>
        <TextPath
          positions={coordinates}
          pathOptions={{
            opacity
          }}
          text={distance || ''}
          offset={-10}
          attributes={{ x: '10', fill: '#696B72' }}
        />
        <TextPath
          positions={coordinates}
          pathOptions={{
            opacity
          }}
          text={bearing || ''}
          offset={15}
          attributes={{ x: '10', fill: '#696B72', fontWeight: '700' }}
        />
      </>
    );
  }, [coordinates]);

  // eslint-disable-next-line
  return <>{textPath}</>;
};

LineTextPath.defaultProps = {
  opacity: 0
};

export default LineTextPath;

/* eslint-disable */
// @ts-nocheck
import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';

const MeasurementControl = () => {
  const map = useMap();

  useEffect(() => {
    L.Control.LinearMeasurement = L.Control.extend({
      options: {
        unitSystem: 'imperial', // imperial | metric
        color: '#4D90FE',
        contrastingColor: '#fff',
        show_last_node: true,
        show_azimut: false
      },

      clickSpeed: 200,

      onAdd: function (map) {
        const container = L.DomUtil.create('div', 'leaflet-control leaflet-bar leafler-ruler-bar');
        const link = L.DomUtil.create('a', 'icon-ruler', container);
        const map_container = map.getContainer();
        const thisRef = this;

        link.href = '#';
        link.title = 'Toggle measurement tool';

        L.DomEvent.on(link, 'click', L.DomEvent.stop).on(link, 'click', function () {
          if (L.DomUtil.hasClass(link, 'icon-active')) {
            thisRef.resetRuler(!!thisRef.mainLayer);
            L.DomUtil.removeClass(link, 'icon-active');
            L.DomUtil.removeClass(map_container, 'ruler-map');
          } else {
            thisRef.initRuler();
            L.DomUtil.addClass(link, 'icon-active');
            L.DomUtil.addClass(map_container, 'ruler-map');
          }
        });

        function contrastingColor(color) {
          return luma(color) >= 165 ? '000' : 'fff';
        }

        function luma(color) {
          const rgb = typeof color === 'string' ? hexToRGBArray(color) : color;
          return 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2]; // SMPTE C, Rec. 709 weightings
        }

        function hexToRGBArray(color) {
          if (color.length === 3) {
            color =
              color.charAt(0) +
              color.charAt(0) +
              color.charAt(1) +
              color.charAt(1) +
              color.charAt(2) +
              color.charAt(2);
          }

          const rgb = [];
          for (let i = 0; i <= 2; i++) rgb[i] = parseInt(color.substr(i * 2, 2), 16);
          return rgb;
        }

        if (this.options.color && this.options.color.indexOf('#') === -1) {
          this.options.color = '#' + this.options.color;
        } else if (!this.options.color) {
          this.options.color = '#4D90FE';
        }

        const originalColor = this.options.color.replace('#', '');

        this.options.contrastingColor = '#' + contrastingColor(originalColor);

        return container;
      },

      onRemove: function (map) {
        this.resetRuler(!!this.mainLayer);
      },

      initRuler: function () {
        const thisRef = this;
        const map = this._map;

        this.mainLayer = L.featureGroup();
        this.mainLayer.addTo(this._map);

        map.touchZoom.disable();
        map.doubleClickZoom.disable();
        map.boxZoom.disable();
        map.keyboard.disable();

        if (map.tap) {
          map.tap.disable();
        }

        this.dblClickEventFn = function (e) {
          L.DomEvent.stop(e);
        };

        this.clickEventFn = function (e) {
          if (thisRef.clickHandle) {
            clearTimeout(thisRef.clickHandle);
            thisRef.clickHandle = 0;

            if (thisRef.options.show_last_node) {
              thisRef.preClick(e);
              thisRef.getMouseClickHandler(e);
            }

            thisRef.getDblClickHandler(e);
          } else {
            thisRef.preClick(e);

            thisRef.clickHandle = setTimeout(function () {
              thisRef.getMouseClickHandler(e);
              thisRef.clickHandle = 0;
            }, thisRef.clickSpeed);
          }
        };

        this.moveEventFn = function (e) {
          if (!thisRef.clickHandle) {
            thisRef.getMouseMoveHandler(e);
          }
        };

        map.on('click', this.clickEventFn, this);
        map.on('mousemove', this.moveEventFn, this);

        this.resetRuler();
      },

      initLayer: function () {
        this.layer = L.featureGroup();
        this.layer.addTo(this.mainLayer);
        this.layer.on('selected', this.layerSelected);
        this.layer.on('click', this.clickEventFn, this);
      },

      resetRuler: function (resetLayer) {
        const map = this._map;

        if (resetLayer) {
          map.off('click', this.clickEventFn, this);
          map.off('mousemove', this.moveEventFn, this);

          if (this.mainLayer) {
            this._map.removeLayer(this.mainLayer);
          }

          this.mainLayer = null;

          this._map.touchZoom.enable();
          this._map.boxZoom.enable();
          this._map.keyboard.enable();

          if (this._map.tap) {
            this._map.tap.enable();
          }
        }

        this.layer = null;
        this.prevLatlng = null;
        this.poly = null;
        this.multi = null;
        this.latlngs = null;
        this.latlngsList = [];
        this.sum = 0;
        this.distance = 0;
        this.separation = 1;
        this.last = 0;
        this.fixedLast = 0;
        this.totalIcon = null;
        this.total = null;
        this.lastCircle = null;
        this.centerPoint = null;
        this.distnceCircleIcon = null;
        this.distnceCircle = null;

        /* Leaflet return distances in meters */
        this.UNIT_CONV = 1000;
        this.SUB_UNIT_CONV = 1000;
        this.UNIT = 'km';
        this.SUB_UNIT = 'm';

        if (this.options.unitSystem === 'imperial') {
          this.UNIT_CONV = 1609.344;
          this.SUB_UNIT_CONV = 5280;
          this.UNIT = 'mi';
          this.SUB_UNIT = 'ft';
        }

        this.measure = {
          scalar: 0,
          unit: this.SUB_UNIT
        };
      },

      cleanUpMarkers: function (fixed) {
        const layer = this.layer;

        if (layer) {
          layer.eachLayer(function (l) {
            if (l.options && l.options.type === 'tmp') {
              if (fixed) {
                l.options.type = 'fixed';
              } else {
                layer.removeLayer(l);
              }
            }
          });
        }
      },

      cleanUpFixed: function () {
        const layer = this.layer;

        if (layer) {
          layer.eachLayer(function (l) {
            if (l.options && l.options.type === 'fixed') {
              layer.removeLayer(l);
            }
          });
        }
      },

      convertDots: function () {
        const thisRef = this;
        const layer = this.layer;

        if (layer) {
          layer.eachLayer(function (l) {
            if (l.options && l.options.type === 'dot') {
              const m = l.options.marker;
              const i = m ? m.options.icon.options : null;
              const il = i ? i.html : '';

              if (il && il.indexOf(thisRef.measure.unit) === -1) {
                const str = l.options.label;
                const s = str.split(' ');
                const e = parseFloat(s[0]);
                const u = s[1];

                let label = '';

                if (l.options.label.indexOf(thisRef.measure.unit) !== -1) {
                  label = l.options.label;
                } else if (u === thisRef.UNIT) {
                  label = (e * thisRef.SUB_UNIT_CONV).toFixed(2) + ' ' + thisRef.SUB_UNIT;
                } else if (u === thisRef.SUB_UNIT) {
                  label = (e / thisRef.SUB_UNIT_CONV).toFixed(2) + ' ' + thisRef.UNIT;
                }

                const cicon = L.divIcon({
                  className: 'total-popup-label',
                  html: label
                });

                m.setIcon(cicon);
              }
            }
          });
        }
      },

      displayMarkers: function (latlngs, multi, sum) {
        let x;
        let y;
        let label;
        let ratio;
        let p;
        let latlng = latlngs[latlngs.length - 1];

        const prevLatlng = latlngs[0];
        const original = prevLatlng.distanceTo(latlng) / this.UNIT_CONV;
        let dis = original;

        var p2 = this._map.latLngToContainerPoint(latlng),
          p1 = this._map.latLngToContainerPoint(prevLatlng),
          unit = 1;

        if (this.measure.unit === this.SUB_UNIT) {
          unit = this.SUB_UNIT_CONV;
          dis = dis * unit;
        }

        const t = sum * unit + dis;
        const qu = sum * unit;

        for (let q = Math.floor(qu); q < t; q++) {
          ratio = (t - q) / dis;

          if (q % this.separation || q < qu) {
            continue;
          }

          x = p2.x - ratio * (p2.x - p1.x);
          y = p2.y - ratio * (p2.y - p1.y);

          p = L.point(x, y);

          /* render a circle spaced by separation */

          latlng = this._map.containerPointToLatLng(p);

          label = q + ' ' + this.measure.unit;

          this.renderCircle(latlng, 0, this.layer, multi ? 'fixed' : 'tmp', label);

          this.last = t;
        }

        return original;
      },

      renderCircle: function (latLng, radius, layer, type, label) {
        const color = this.options.color;
        const lineColor = this.options.color;
        let azimut = '';
        let nodeCls = '';

        type = type || 'circle';

        const options = {
          color: lineColor,
          fillOpacity: 1,
          opacity: 1,
          fill: true,
          type: type
        };

        const a = this.prevLatlng ? this._map.latLngToContainerPoint(this.prevLatlng) : null;
        const b = this._map.latLngToContainerPoint(latLng);

        if (type === 'dot') {
          nodeCls = 'node-label';

          if (a && this.options.show_azimut) {
            azimut = ' <span class="azimut"> ' + this.lastAzimut + '&deg;</span>';
          }
        }

        const p_latLng = this._map.containerPointToLatLng(b);

        if (label) {
          const cicon = L.divIcon({
            className: 'total-popup-label ' + nodeCls,
            html:
              '<span class="dot-label not-show" style="color: ' +
              color +
              ';">' +
              label +
              azimut +
              '</span>'
          });

          options.icon = cicon;
          options.marker = L.marker(p_latLng, {
            icon: cicon,
            type: type
          }).addTo(layer);
          options.label = label;
        }

        const circle = L.circleMarker(latLng, options);

        circle.setRadius(5);
        // circle.bindTooltip(`<span class="latlng-label">${latLng.lat}, <br/> ${latLng.lng}<span>`, {
        //   permanent: false,
        //   direction: 'top'
        // });
        circle.addTo(layer);

        return circle;
      },

      getAzimut: function (a, b) {
        let deg = 0;

        if (a && b) {
          deg = parseInt((Math.atan2(b.y - a.y, b.x - a.x) * 180) / Math.PI);

          if (deg > 0) {
            deg += 90;
          } else if (deg < 0) {
            deg = Math.abs(deg);
            if (deg <= 90) {
              deg = 90 - deg;
            } else {
              deg = 360 - (deg - 90);
            }
          }
        }

        this.lastAzimut = deg;

        return deg;
      },

      renderPolyline: function (latLngs, dashArray, layer) {
        const poly = L.polyline(latLngs, {
          color: this.options.color,
          weight: 2,
          opacity: 1,
          dashArray: dashArray
        });

        poly.addTo(layer);

        return poly;
      },

      renderMultiPolyline: function (latLngs, dashArray, layer) {
        /* Leaflet version 1+ delegated the concept of multi-poly-line to the polyline */
        let multi;

        if (L.version.startsWith('0')) {
          multi = L.multiPolyline(latLngs, {
            color: this.options.color,
            weight: 2,
            opacity: 1,
            dashArray: dashArray
          });
        } else {
          multi = L.polyline(latLngs, {
            color: this.options.color,
            weight: 2,
            opacity: 1,
            dashArray: dashArray
          });
        }

        multi.addTo(layer);

        return multi;
      },

      formatDistance: function (distance, precision) {
        const s = L.Util.formatNum(
          distance < 1 ? distance * parseFloat(this.SUB_UNIT_CONV) : distance,
          precision
        );
        const u = distance < 1 ? this.SUB_UNIT : this.UNIT;

        return { scalar: s, unit: u };
      },

      hasClass: function (target, classes) {
        const fn = L.DomUtil.hasClass;

        for (var i in classes) {
          if (fn(target, classes[i])) {
            return true;
          }
        }

        return false;
      },

      preClick: function (e) {
        const thisRef = this;
        const target = e.originalEvent.target;

        if (this.hasClass(target, ['leaflet-popup', 'total-popup-content'])) {
          return;
        }

        if (!thisRef.layer) {
          thisRef.initLayer();
        }

        thisRef.cleanUpMarkers(true);

        thisRef.fixedLast = thisRef.last;
        thisRef.prevLatlng = e.latlng;
        thisRef.sum = 0;
      },

      getMouseClickHandler: function (e) {
        const thisRef = this;
        thisRef.fixedLast = thisRef.last;
        thisRef.sum = 0;

        if (thisRef.poly) {
          thisRef.latlngsList.push(thisRef.latlngs);

          if (!thisRef.multi) {
            thisRef.multi = thisRef.renderMultiPolyline(
              thisRef.latlngsList,
              '5 5',
              thisRef.layer,
              'dot'
            );
          } else {
            thisRef.multi.setLatLngs(thisRef.latlngsList);
          }
        }

        let o;
        let dis;
        for (const l in thisRef.latlngsList) {
          o = thisRef.latlngsList[l];
          thisRef.sum += o[0].distanceTo(o[1]) / thisRef.UNIT_CONV;
        }

        if (thisRef.measure.unit === this.SUB_UNIT) {
          dis = thisRef.sum * thisRef.SUB_UNIT_CONV;
        } else {
          dis = thisRef.sum;
        }

        const s = dis.toFixed(2);

        thisRef.renderCircle(
          e.latlng,
          0,
          thisRef.layer,
          'dot',
          parseInt(s) ? s + ' ' + thisRef.measure.unit : ''
        );

        if (thisRef.centerPoint) {
          L.marker(thisRef.centerPoint, {
            icon: thisRef.distnceCircleIcon
          }).addTo(thisRef.layer);
        }

        thisRef.prevLatlng = e.latlng;
      },

      getMouseMoveHandler: function (e) {
        let azimut = '';

        if (this.prevLatlng) {
          const latLng = e.latlng;

          this.latlngs = [this.prevLatlng, e.latlng];

          if (!this.poly) {
            this.poly = this.renderPolyline(this.latlngs, '5 5', this.layer);
          } else {
            this.poly.setLatLngs(this.latlngs);
          }

          /* Distance in miles/meters */
          this.distance = parseFloat(this.prevLatlng.distanceTo(e.latlng)) / this.UNIT_CONV;

          /* scalar and unit */
          this.measure = this.formatDistance(this.distance + this.sum, 2);
          const last2PointDistance = this.formatDistance(this.distance, 2);

          const a = this.prevLatlng ? this._map.latLngToContainerPoint(this.prevLatlng) : null;
          const b = this._map.latLngToContainerPoint(latLng);

          if (a && this.options.show_azimut) {
            const style = 'color: ' + this.options.contrastingColor + ';';
            azimut =
              ' <span class="azimut azimut-final" style="' +
              style +
              '"> &nbsp; ' +
              this.getAzimut(a, b) +
              '&deg;</span>';
          }

          /* tooltip with total distance */
          let labelmeasureNM =
            this.measure.unit === 'km'
              ? ((this.measure.scalar * 1000) / 1852).toFixed(3)
              : (this.measure.scalar / 1852).toFixed(3);
          const label = labelmeasureNM + ' ' + 'NM';

          let distanceLabelNM =
            last2PointDistance.unit === 'km'
              ? ((last2PointDistance.scalar * 1000) / 1852).toFixed(3)
              : (last2PointDistance.scalar / 1852).toFixed(3);

          const distanceLabel = distanceLabelNM + ' ' + 'NM';
          const html =
            '<span class="total-popup-content" style="background-color:' +
            this.options.color +
            '; color: ' +
            this.options.contrastingColor +
            '">' +
            label +
            azimut +
            '</span>';

          if (!this.total) {
            this.totalIcon = L.divIcon({
              className: 'total-popup',
              html: html
            });

            this.total = L.marker(e.latlng, {
              icon: this.totalIcon,
              clickable: true
            }).addTo(this.layer);
          } else {
            this.totalIcon = L.divIcon({
              className: 'total-popup',
              html: html
            });
            this.total.setLatLng(e.latlng);
            this.total.setIcon(this.totalIcon);
          }

          /* point distance marker */
          this.distnceCircleIcon = L.divIcon({
            className: 'total-popup-label',
            html: '<span class="dot-label";>' + distanceLabel + '</span>'
          });
          const p1 = map.project(this.prevLatlng);
          const p2 = map.project(e.latlng);
          this.centerPoint = map.unproject(p1._add(p2)._divideBy(2));
          if (!this.distnceCircle) {
            this.distnceCircle = L.marker(this.centerPoint, {
              icon: this.distnceCircleIcon
            }).addTo(this.layer);
          } else {
            this.distnceCircleIcon = L.divIcon({
              className: 'total-popup-label',
              html: '<span class="dot-label";>' + distanceLabel + '</span>'
            });
            this.distnceCircle.setLatLng(this.centerPoint);
            this.distnceCircle.setIcon(this.distnceCircleIcon);
          }

          /* Rules for separation using only distance criteria */
          const ds = this.measure.scalar;
          // const old_separation = this.separation;
          const digits = parseInt(ds).toString().length;
          const num = Math.pow(10, digits);
          const real = ds > num / 2 ? num / 10 : num / 20;
          // let dimension = 0;

          this.separation = real;

          /* If there is a change in the segment length we want to re-space
             the dots on the multi line */
          // if (old_separation !== this.separation && this.fixedLast) {
          //   this.cleanUpMarkers();
          //   this.cleanUpFixed();

          //   const multi_latlngs = this.multi.getLatLngs();

          //   for (const s in multi_latlngs) {
          //     dimension += this.displayMarkers.apply(this, [
          //       multi_latlngs[s],
          //       true,
          //       dimension,
          //     ]);
          //   }

          //   this.displayMarkers.apply(this, [
          //     this.poly.getLatLngs(),
          //     false,
          //     this.sum,
          //   ]);

          //   /* Review that the dots are in correct units */
          //   this.convertDots();
          // } else {
          //   this.cleanUpMarkers();
          //   this.displayMarkers.apply(this, [
          //     this.poly.getLatLngs(),
          //     false,
          //     this.sum,
          //   ]);
          // }
        }
      },

      getDblClickHandler: function (e) {
        let azimut = '';
        const thisRef = this;

        if (!this.total) {
          return;
        }

        this.layer.off('click');

        L.DomEvent.stop(e);

        if (this.options.show_azimut) {
          const style = 'color: ' + this.options.contrastingColor + ';';
          azimut =
            ' <span class="azimut azimut-final" style="' +
            style +
            '"> &nbsp; ' +
            this.lastAzimut +
            '&deg;</span>';
        }

        const workspace = this.layer;

        let distanceLabelNM =
          this.measure.unit === 'km'
            ? ((this.measure.scalar * 1000) / 1852).toFixed(3)
            : (this.measure.scalar / 1852).toFixed(3);

        const label = distanceLabelNM + ' ' + 'NM' + ' ';
        // const total_scalar = this.measure.unit === this.SUB_UNIT ? this.measure.scalar / this.UNIT_CONV : this.measure.scalar;
        // const total_latlng = this.total.getLatLng();
        const total_label = this.total;

        const html = [
          '<div class="total-popup-content" style="background-color:' +
            this.options.color +
            '; color: ' +
            this.options.contrastingColor +
            '">' +
            label +
            azimut,
          '  <svg class="close" viewbox="0 0 45 35">',
          '   <path style="stroke:#fff" class="close" d="M 10,10 L 30,30 M 30,10 L 10,30" />',
          '  </svg>',
          '</div>'
        ].join('');

        this.totalIcon = L.divIcon({ className: 'total-popup', html: html });
        this.total.setIcon(this.totalIcon);

        const data = {
          total: this.measure,
          total_label: total_label,
          unit: this.UNIT_CONV,
          sub_unit: this.SUB_UNIT_CONV
        };

        const fireSelected = function (e) {
          if (L.DomUtil.hasClass(e.originalEvent.target, 'close')) {
            thisRef.mainLayer.removeLayer(workspace);
          } else {
            workspace.fireEvent('selected', data);
          }
        };

        workspace.on('click', fireSelected);
        workspace.fireEvent('selected', data);

        this.resetRuler(false);
      },

      purgeLayers: function (layers) {
        for (const i in layers) {
          if (layers[i]) {
            this.layer.removeLayer(layers[i]);
          }
        }
      },

      numberWithCommas: function (x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      },

      layerSelected: function (e) {}
    });

    map.addControl(
      new L.Control.LinearMeasurement({
        unitSystem: 'metric',
        color: '#1ee0cd'
      })
    );
  }, []); // eslint-disable-line

  return null;
};
export default MeasurementControl;

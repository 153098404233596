import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import 'scripts/wmts';
import { GET_GEOSERVER_WMTS_URL } from 'utils/apiUrls';

interface WMTSTileLayerProps {
  isTerrainDataViewonmap: boolean;
  icaocode: string | undefined;
  WMTSPane?: string;
}

const WMTSTileLayer = ({ icaocode, isTerrainDataViewonmap, WMTSPane }: WMTSTileLayerProps) => {
  const map = useMap();
  const wmsLayerGroup = L.featureGroup();

  useEffect(() => {
    if (!isTerrainDataViewonmap && !icaocode) return;

    const wmtsParams = {
      layer: icaocode,
      format: 'image/png8',
      transparent: true,
      maxZoom: 15,
      pane: WMTSPane,
      opacity: 1,
      tilematrixset: 'EPSG:900913',
      height: 256,
      width: 256
    };

    const wmtslayer = L.tileLayer
      // @ts-ignore
      .wmts(GET_GEOSERVER_WMTS_URL, wmtsParams)
      .addTo(map);
    wmsLayerGroup.addLayer(wmtslayer);
    wmsLayerGroup.addTo(map);

    // eslint-disable-next-line
    return () => {
      wmsLayerGroup.removeFrom(map);
    };
  }, [icaocode, isTerrainDataViewonmap]);

  return null;
};

WMTSTileLayer.defaultProps = {
  WMTSPane: 'tilePane'
};

export default WMTSTileLayer;

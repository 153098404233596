export enum UserRole {
  SuperAdmin = 'super_admin',
  Admin = 'admin',
  Manager = 'manager',
  Designer = 'designer',
  Reviewer = 'reviewer'
}

export const UserRoleText: any = {
  super_admin: 'Super Admin',
  admin: 'Admin',
  manager: 'Manager',
  designer: 'Designer',
  reviewer: 'Reviewer'
};

export interface UserResponse {
  accessToken: string | null;
  email: string;
  firstname: string | null;
  id: string;
  lastname: string | null;
  organisationid: number | null;
  refreshToken: string | null;
  role: string | null;
}

export interface UpdateUserPayload {
  firstname: string;
  lastname: string;
  phone: string;
  empId: number;
  designation: string;
  city: string;
  country: string;
  yearsofexp: number;
  gender: string;
  age: number;
}

export interface UserRequest {
  email: string;
  password: string;
}
export interface AddMembersPayload {
  email: string;
  role: string;
}
export interface ErrorResponse {
  message: string;
  status: boolean;
  statusCode: number | undefined;
  data?: unknown;
}

export type Organisation = {
  active: boolean;
  created_at: string | null;
  id: number;
  modified_at: string | null;
  name: string;
};

export interface OrganisationMember {
  age: number | null;
  city: string | null;
  country: string | null;
  designation: string | null;
  email: string;
  empId: number | null;
  enable: boolean;
  firstname: string | null;
  gender: string | null;
  id: string;
  lastname: string | null;
  modified_on: string | null;
  phone: string | null;
  registered_on: string;
  role: string;
  yearsofexp: number | null;
}

export type UserState = {
  accessToken: string | null;
  refreshToken: string | null;
  userSession: UserResponse | null;
  rsaKey: string | null;
  organisation: Organisation | null;
  organisationMembers: OrganisationMember[] | [];
};

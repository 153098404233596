import { capitalize, isEmpty, keys } from 'lodash';
import { Typography } from 'antd';
import './styles.less';

const { Text } = Typography;

interface CollectADDataItemProps {
  selectedADData: any;
}

const CollectADDataItem = ({ selectedADData }: CollectADDataItemProps) => {
  return (
    <div className="ad-data-table-main">
      {!isEmpty(selectedADData) ? (
        typeof selectedADData === 'object' &&
        !Array.isArray(selectedADData) &&
        !isEmpty(selectedADData) ? (
          <table className="ad-data-table">
            <tbody>
              {keys(selectedADData).map((key, i) => {
                return (
                  <tr key={i}>
                    <td>{capitalize(key)}</td>
                    <td>{!isEmpty(selectedADData[key]) ? selectedADData[key] : '-'}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <table className="ad-data-table2">
            <thead>
              <tr>
                {selectedADData[0] &&
                  keys(selectedADData[0]).map((label: any, i) => {
                    return <th key={i}>{capitalize(label)}</th>;
                  })}
              </tr>
            </thead>
            <tbody>
              {selectedADData &&
                selectedADData.map((data: any, i: any) => {
                  return (
                    <tr key={i}>
                      {keys(data).map((key, index) => {
                        return <td key={index}>{data[key] || '-'}</td>;
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        )
      ) : (
        <Text style={{ height: '100%' }} className="flexJustifyAlignCenter ad-no-data">
          No Data
        </Text>
      )}
    </div>
  );
};

export default CollectADDataItem;

import { primary } from 'constants/color.constant';
import { IconProps } from 'types/common.types';

const EditIcon = ({ color, height, width, disabled, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      style={{ cursor: disabled ? 'no-drop' : 'pointer' }}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.10251 15.8737L9.07951 15.6027L14.6795 9.9977L11.9835 7.3007L6.36651 12.9177L6.10251 15.8737ZM13.3235 5.9617L16.0185 8.6567L17.9655 6.7067L15.2715 4.0127L13.3235 5.9617ZM4.29251 17.6857C4.08251 17.4757 3.97751 17.1837 4.00351 16.8877L4.38251 12.7177C4.42451 12.2607 4.62651 11.8297 4.95251 11.5037L13.9485 2.5077C14.6505 1.8027 15.9235 1.8377 16.6645 2.5767L19.4025 5.3147L19.4035 5.3157C20.1685 6.0817 20.1995 7.2997 19.4715 8.0297L10.4745 17.0267C10.1495 17.3517 9.71851 17.5537 9.26051 17.5957L5.09051 17.9747C5.06051 17.9767 5.03051 17.9777 4.99951 17.9777C4.73651 17.9777 4.48151 17.8737 4.29251 17.6857ZM19.9995 20.9777C19.9995 21.5277 19.5495 21.9777 18.9995 21.9777H4.99951C4.45051 21.9777 3.99951 21.5277 3.99951 20.9777C3.99951 20.4287 4.45051 19.9777 4.99951 19.9777H18.9995C19.5495 19.9777 19.9995 20.4287 19.9995 20.9777Z"
        fill={disabled ? '#95969d' : color}
      />
    </svg>
  );
};
EditIcon.defaultProps = {
  color: primary,
  width: 24,
  height: 24,
  disabled: false,
  onClick: () => {}
};

export default EditIcon;

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { concat, find, isEmpty, uniq } from 'lodash';
import moment, { Moment } from 'moment';
import { Button, Collapse, Typography } from 'antd';
import type { CollapseProps } from 'antd';
import DownArrow from 'assets/svg/DownArrow';
import DeleteIcon from 'assets/svg/DeleteIcon';
import RightArrow from 'assets/svg/RightArrow';
import { ACCESS_TOKEN, DateFormat3 } from 'constants/common.constant';
import { airportDataCategories, airspaceCatData } from 'constants/collectData.constant';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { useStartDesignMutation } from 'services/apis/designsApi';
import { enqueueError, setSuccessAlert } from 'store/alert';
import { setDesignProcedurelist, setTemperatureData } from 'store/design';
import { addSurveyObstacles } from 'store/design/actions';
import { getAsyncStorageValue } from 'services/axiosClient';
import './styles.less';

const { Text } = Typography;

interface CollectSidebarProps {
  collapseActiveKey: string[] | [];
  versionId: number | null;
  airportId: number | null;
  airportLat: number | null;
  airportLng: number | null;
  designid: any;
  isDisabled: boolean;
  isDesigner: boolean;
  tmin: number | string | null;
  tmax: number | string | null;
  currentMetaId: number | null;
  surveyFile: File | undefined;
  handleLoading: (load: boolean) => void;
  handleRemoveADDataStock: (key: string) => void;
  handleRemoveAirspaceDataStock: (key: string) => void;
  handleGetUpdatedSurveyObsData: () => void;
  magneticVariation: any;
  isAddStockMagneticVariation: boolean;
  isAddSurveyFileObsDataToStock: boolean;
  handleAddSurveyFileObsDataToStock: Function;
  magneticDeclinationUnit: string;
  magneticVariationModal: string;
  magneticModalDate: Moment | null;
}

const CollectSidebar = ({
  tmin,
  tmax,
  collapseActiveKey,
  airportId,
  versionId,
  airportLat,
  airportLng,
  designid,
  isDisabled,
  isDesigner,
  surveyFile,
  currentMetaId,
  handleLoading,
  handleRemoveADDataStock,
  handleRemoveAirspaceDataStock,
  handleGetUpdatedSurveyObsData,
  magneticVariation,
  isAddStockMagneticVariation,
  isAddSurveyFileObsDataToStock,
  handleAddSurveyFileObsDataToStock,
  magneticDeclinationUnit,
  magneticVariationModal,
  magneticModalDate
}: CollectSidebarProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { userSession } = useAppSelector((state) => state.user);
  const {
    availableADData,
    availableAirspaceData,
    designTemperature,
    adDataKeys,
    airspaceDataKeys
  } = useAppSelector((state) => state.design);
  const [startDesign, { isLoading: isStartDesignLoading, isSuccess: isStartDesignSuccess }] =
    useStartDesignMutation();

  const [activeCollapseKey, setActiveCollapseKey] = useState<string | string[] | number[] | []>(
    collapseActiveKey
  );

  useEffect(() => {
    setActiveCollapseKey(collapseActiveKey);
  }, [collapseActiveKey]);

  const handleStartDesign = async () => {
    if (
      airportId &&
      versionId &&
      !isEmpty(concat(uniq(adDataKeys), uniq(airspaceDataKeys))) &&
      designid &&
      tmin &&
      tmax &&
      magneticVariation &&
      isAddStockMagneticVariation &&
      magneticVariationModal
    ) {
      const magneticDeclination =
        magneticVariation && magneticDeclinationUnit === 'w'
          ? -Math.abs(magneticVariation)
          : magneticVariation;
      const magneticVariationDate =
        !isEmpty(magneticModalDate) && moment(magneticModalDate).format(DateFormat3);

      let payloadData = {
        airportId: airportId?.toString(),
        version: versionId?.toString(),
        types: concat(uniq(adDataKeys), uniq(airspaceDataKeys)),
        buffer: 150,
        lat: airportLat!,
        lng: airportLng!,
        tmin,
        tmax,
        magnetic_declination: magneticDeclination,
        magnetic_model: magneticVariationModal
      };
      if (magneticVariationModal !== 'CONSTANT' && !isEmpty(magneticModalDate)) {
        // @ts-ignore
        payloadData = { ...payloadData, magnetic_varation_date: magneticVariationDate };
      }
      const payload = {
        data: payloadData,
        orgId: Number(userSession?.organisationid),
        designId: Number(designid)
      };
      handleLoading(true);
      await startDesign(payload);
      if (surveyFile && isAddSurveyFileObsDataToStock) {
        if (currentMetaId) {
          await getAsyncStorageValue(ACCESS_TOKEN).then(async (token: string) => {
            await dispatch(
              addSurveyObstacles(
                currentMetaId,
                token,
                () => {
                  dispatch(setSuccessAlert('Upload survey data successfully.'));
                  handleGetUpdatedSurveyObsData();
                },
                surveyFile
              )
            );
          });
        }
      } else {
        handleLoading(false);
      }
      dispatch(setDesignProcedurelist([]));
      handleLoading(false);
      if (isStartDesignSuccess) {
        setTemperatureData(null);
        navigate(`/design/${designid}`);
      }
    } else {
      handleLoading(false);
      dispatch(enqueueError('First add ADdata, Airspacedata and Temerature to stock...'));
    }
  };

  const handleContinueDesign = async () => {
    if (surveyFile && isAddSurveyFileObsDataToStock) {
      if (currentMetaId) {
        await getAsyncStorageValue(ACCESS_TOKEN).then(async (token: string) => {
          await dispatch(
            addSurveyObstacles(
              currentMetaId,
              token,
              () => {
                dispatch(setSuccessAlert('Upload survey data successfully.'));
                handleGetUpdatedSurveyObsData();
              },
              surveyFile
            )
          );
        });
      }
    } else {
      await dispatch(setDesignProcedurelist([]));
      navigate(`/design/${designid}`);
    }
  };

  const handleNotDesigner = () => {
    if (
      !isEmpty(availableADData) ||
      !isEmpty(availableAirspaceData) ||
      !isEmpty(designTemperature)
    ) {
      dispatch(setDesignProcedurelist([]));
      navigate(`/design/${designid}`);
    } else {
      dispatch(enqueueError('Design not started...'));
    }
  };

  const handleRemoveADData = (addatakey: string) => {
    if (isEmpty(availableADData)) {
      handleRemoveADDataStock(addatakey);
    }
  };

  const items: CollapseProps['items'] = [
    {
      key: 'addata',
      label: 'Aerodrome',
      children: (
        <div className={!isEmpty(adDataKeys) ? 'ad-data-list' : 'no-data ad-data-list'}>
          {!isEmpty(adDataKeys) ? (
            adDataKeys.map((data: any) => {
              const adItem = find(airportDataCategories, ['value', data]);
              const isDefault = [
                'geoadministrative',
                'runwayphysicals',
                'declareddistaces',
                'aixmobstacles'
              ].includes(adItem?.value!);
              return (
                <div className="ad-data-content" key={adItem?.id}>
                  <div style={{ display: 'flex' }}>
                    <p>{adItem?.codeID}</p>
                    <p>{adItem?.codeLabel}</p>
                  </div>
                  <div
                    style={{
                      cursor: isDefault || !isEmpty(availableADData) ? 'default' : 'pointer',
                      paddingLeft: 5
                    }}
                    onClick={isDefault ? () => {} : () => handleRemoveADData(adItem?.value!)}
                  >
                    {!isDefault && <DeleteIcon width={13} height={13} />}
                  </div>
                </div>
              );
            })
          ) : (
            <Text className="no-data">No Data Added</Text>
          )}
        </div>
      )
    },
    {
      key: 'airspacedata',
      label: 'Airspace',
      children: (
        <div className={!isEmpty(airspaceDataKeys) ? 'ad-data-list' : 'no-data ad-data-list'}>
          {!isEmpty(airspaceDataKeys) ? (
            airspaceDataKeys.map((item: any) => {
              return (
                <div className="ad-data-content" key={item}>
                  <div style={{ display: 'flex' }}>
                    <p>{airspaceCatData[item]}</p>
                  </div>
                  <div
                    style={{
                      cursor: !isEmpty(availableADData) ? 'not-allowed' : 'pointer',
                      paddingLeft: 5
                    }}
                    onClick={
                      !isEmpty(availableADData)
                        ? () => {}
                        : () => handleRemoveAirspaceDataStock(item)
                    }
                  >
                    <DeleteIcon width={13} height={13} />
                  </div>
                </div>
              );
            })
          ) : (
            <Text className="no-data">No Data Added</Text>
          )}
        </div>
      )
    },
    {
      key: 'obstacle',
      label: 'Obstacle Data',
      children: (
        <div className="ad-data-list">
          <Text className="no-data" style={{ paddingLeft: 8 }}>
            Terrain - SRTM
          </Text>
          {isAddSurveyFileObsDataToStock && (
            <div className="ad-data-content">
              <div style={{ display: 'flex' }}>
                <p>Obstacles - survey</p>
              </div>
              <div
                style={{
                  cursor: !isAddSurveyFileObsDataToStock ? 'not-allowed' : 'pointer'
                }}
                onClick={() => handleAddSurveyFileObsDataToStock(false)}
              >
                <DeleteIcon width={13} height={13} />
              </div>
            </div>
          )}
        </div>
      )
    },
    {
      key: 'magnetic',
      label: 'Magnetic Variation',
      children: (
        <div className={isAddStockMagneticVariation ? 'ad-data-list' : 'ad-data-list no-data'}>
          {isAddStockMagneticVariation && magneticVariation ? (
            <Text className="no-data" style={{ paddingLeft: 8 }}>
              Magnetic Variation:{' '}
              {Number(magneticVariation) >= 0 && magneticDeclinationUnit === 'e'
                ? `${magneticVariation} °E`
                : `${Math.abs(magneticVariation)} °W`}
            </Text>
          ) : (
            <Text className="no-data">No Data Added</Text>
          )}
        </div>
      )
    },

    {
      key: 'temperaturedata',
      label: 'Temperature',
      children: (
        <div className={tmin && tmax ? 'ad-data-list temp-data' : 'ad-data-list no-data'}>
          {tmin && tmax ? (
            <>
              <Text className="no-data">Temperature Minimum: {tmin || '-'}</Text>
              <Text className="no-data">Temperature Maximum : {tmax || '-'}</Text>
            </>
          ) : (
            <Text className="no-data">No Data Added</Text>
          )}
        </div>
      )
    },
    {
      key: 'aircraft',
      label: 'Aircraft Performance',
      children: (
        <div className="ad-data-list no-data">
          <Text className="no-data">No Data Added</Text>
        </div>
      )
    },
    {
      key: 'uploadeddata',
      label: 'Uploaded',
      children: (
        <div className="ad-data-list no-data">
          <Text className="no-data">No Data Added</Text>
        </div>
      )
    }
  ];

  return (
    <div className="inr-main-sidebar">
      <div className="data-stock">Data Stock</div>
      <div className="data-collapse-div">
        <Collapse
          accordion
          activeKey={activeCollapseKey}
          size="small"
          items={items}
          onChange={(key) => setActiveCollapseKey(key)}
          // eslint-disable-next-line
          expandIcon={() => <DownArrow />}
          collapsible="icon"
          className="collapse-main-div"
        />
      </div>
      <div className="start-design">
        {isStartDesignLoading ? (
          <div>
            <Text>Loading...</Text>
          </div>
        ) : (
          <div
            onClick={
              isDesigner
                ? isEmpty(designTemperature)
                  ? handleStartDesign
                  : handleContinueDesign
                : handleNotDesigner
            }
          >
            <Button
              disabled={isDisabled}
              type="link"
              className="start-design-btn"
              style={{ flexDirection: 'row-reverse' }}
              icon={<RightArrow />}
            >
              {isEmpty(designTemperature) ? 'Start Design' : 'Continue'}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CollectSidebar;

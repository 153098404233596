import { isEmpty } from 'lodash';
import CodingTable from 'components/DataLayersTable/CodingTable';
import LegObstacleAnalysisTable from 'components/DataLayersTable/LegObstacleAnalysisTable';
import ObstacleAnalysisTableDeparture from 'components/DataLayersTable/ObstacleAnalysisTableDeparture';
import ObstacleAnalysisTableOmni from 'components/DataLayersTable/ObstacleAnalysisTableOmni';
import { OAlegdata, ProcedureDataType } from 'store/design/types';

interface RenderObstacleTableDataProps {
  isOATableDepartureShow: boolean;
  isOATableOmniShow: boolean;
  isShowHideOATable: boolean;
  isactiveCodingTable: boolean;
  isLoadingCodingTableData: boolean;
  isShowClosingObstacle: boolean;
  isLoadingOAData: boolean;
  activeLeg: string;
  activeObscleTerrainDataKey: string;
  activeOALegDesignDataDeparture: OAlegdata[] | [];
  activeOALegDesignDataOMNI: OAlegdata[] | [];
  activeTerrainLegDesignDataDeparture: OAlegdata[] | [];
  activeTerrainLegDesignDataOMNI: OAlegdata[] | [];
  activePrevLegDesignDataDeparture: any;
  activeLegDesignDataDeparture: any;
  oaLegCheckedKey: string | null;
  legObstacleAnalysis: OAlegdata[] | [];
  selectedProcedureData: ProcedureDataType | null;
  codingTableData: any;
  isObsTerrainFullScreenTable: boolean;
  handleShowHideObstacleTableDeparture: (isShow: boolean) => void;
  handleShowHideObstacleTableOmni: (isShow: boolean) => void;
  handleChangeShowClosingObstacle: (isShowHide: boolean) => void;
  handleShowHideOATable: (isShow: boolean) => void;
  handleCodingTable: (activeTable: boolean) => void;
  handleShowObsTerrainObstacles: (key: string) => void;
  handleShowTerrainLayerDeparture: Function;
  handleShowFullScreenTable: (showFullTable: boolean) => void;
}

const RenderObstacleTableData = ({
  isOATableDepartureShow,
  isOATableOmniShow,
  isShowHideOATable,
  isactiveCodingTable,
  isLoadingCodingTableData,
  isLoadingOAData,
  activeLeg,
  isShowClosingObstacle,
  activeOALegDesignDataDeparture,
  activeOALegDesignDataOMNI,
  activeTerrainLegDesignDataDeparture,
  activeTerrainLegDesignDataOMNI,
  activePrevLegDesignDataDeparture,
  activeLegDesignDataDeparture,
  oaLegCheckedKey,
  legObstacleAnalysis,
  selectedProcedureData,
  codingTableData,
  activeObscleTerrainDataKey,
  isObsTerrainFullScreenTable,
  handleShowHideObstacleTableDeparture,
  handleShowHideObstacleTableOmni,
  handleChangeShowClosingObstacle,
  handleShowHideOATable,
  handleCodingTable,
  handleShowObsTerrainObstacles,
  handleShowTerrainLayerDeparture,
  handleShowFullScreenTable
}: RenderObstacleTableDataProps) => {
  return (
    <>
      {isOATableDepartureShow && (
        <ObstacleAnalysisTableDeparture
          activeLeg={activeLeg}
          isLoadingOAData={isLoadingOAData}
          isShowClosingObstacle={isShowClosingObstacle}
          obstacleData={activeOALegDesignDataDeparture}
          terrainData={activeTerrainLegDesignDataDeparture}
          activePrevLegDesignDataDeparture={activePrevLegDesignDataDeparture}
          activeLegDesignDataDeparture={activeLegDesignDataDeparture}
          activeObscleTerrainDataKey={activeObscleTerrainDataKey}
          handleShowObstacleTableDeparture={handleShowHideObstacleTableDeparture}
          handleChangeShowClosingObstacle={handleChangeShowClosingObstacle}
          handleShowTerrainLayerDeparture={handleShowTerrainLayerDeparture}
          handleShowFullScreenTable={handleShowFullScreenTable}
        />
      )}
      {isOATableOmniShow && (
        <ObstacleAnalysisTableOmni
          obstacleData={activeOALegDesignDataOMNI}
          terrainData={activeTerrainLegDesignDataOMNI}
          isLoadingOAData={isLoadingOAData}
          isShowClosingObstacle={isShowClosingObstacle}
          activeObscleTerrainDataKey={activeObscleTerrainDataKey}
          handleShowHideObstacleTableOmni={handleShowHideObstacleTableOmni}
          handleChangeShowClosingObstacle={handleChangeShowClosingObstacle}
          handleShowFullScreenTable={handleShowFullScreenTable}
        />
      )}
      {!isEmpty(oaLegCheckedKey) && isShowHideOATable && (
        <LegObstacleAnalysisTable
          obstacleData={legObstacleAnalysis}
          oaLegCheckedKey={oaLegCheckedKey || ''}
          isShowClosingObstacle={isShowClosingObstacle}
          selectedProcedureData={selectedProcedureData}
          isLoadingOAData={isLoadingOAData}
          activeObscleTerrainDataKey={activeObscleTerrainDataKey}
          handleChangeShowClosingObstacle={handleChangeShowClosingObstacle}
          handleShowHideOATable={handleShowHideOATable}
          handleShowObsTerrainObstacles={handleShowObsTerrainObstacles}
          handleShowFullScreenTable={handleShowFullScreenTable}
        />
      )}
      {isactiveCodingTable && (
        <CodingTable
          handleCodingTable={handleCodingTable}
          isLoading={isLoadingCodingTableData}
          codingData={!isEmpty(codingTableData) ? codingTableData : []}
          handleShowFullScreenTable={handleShowFullScreenTable}
          isObsTerrainFullScreenTable={isObsTerrainFullScreenTable}
        />
      )}
    </>
  );
};

export default RenderObstacleTableData;

import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { Divider, Typography } from 'antd';
import ARPPointIcon from 'assets/svg/ARPPointIcon';
import AirspaceIcon from 'assets/svg/AirspaceIcon';
import RunwayIcon from 'assets/svg/RunwayIcon';
import { airspaceClass } from 'constants/aeroReport.constant';
import { lightGrey1, primary } from 'constants/color.constant';
import ListDataCard from 'components/core/ListDataCard';
import ReportContentCard from 'components/core/ReportContentCard';
import { latToDms } from 'utils/utils';
import ReportSecTitleWithIcon from './ReportSecTitleWithIcon';

const { Text } = Typography;

interface PreviewSection3Props {
  sec3: any;
  magneticDeclination: any;
}

const PreviewSection3 = ({ sec3, magneticDeclination }: PreviewSection3Props) => {
  const [airspaceRemark, setAirspaceRemark] = useState<string | null>(null);
  const [aerodromeRemark, setAerodromeRemark] = useState<string | null>(null);
  const [runwayRemark, setRunwayRemark] = useState<string | null>(null);
  const [tma, setTMA] = useState<string | null>(null);
  const [cta, setCTA] = useState<string | null>(null);
  const [ctr, setCTR] = useState<string | null>(null);

  useEffect(() => {
    if (!isEmpty(sec3)) {
      setTMA(sec3.airspace_class?.tma || null);
      setCTA(sec3.airspace_class?.cta || null);
      setCTR(sec3.airspace_class?.ctr || null);
      setAerodromeRemark(sec3.aero_remark || null);
      setAirspaceRemark(sec3.airspace_remark || null);
      setRunwayRemark(sec3.rwy_remark || null);
    }
  }, [sec3]);

  return (
    <div className="report-code-content page-break">
      <ReportSecTitleWithIcon title="3. GENERAL DATA" icon={null} />
      <div className="report-content-data">
        <div className="general-data-content">
          <ReportContentCard icon={<ARPPointIcon />} title="AERODROME DATA" />
          <div className="type-data-list clearfix">
            <ListDataCard title="Aerodrome Name" listData={[sec3?.aero_name || '']} />
            <ListDataCard title="Aerodrome Elevation" listData={[sec3?.aero_elv || '']} />
            <ListDataCard title="Magnetic Variation" listData={[sec3?.aero_mag_var || '']} />
          </div>
          <div className="type-data-list clearfix">
            <ListDataCard title="Transition Altitude" listData={[sec3?.aero_trans_alt || '']} />
            <ListDataCard title="ISA Deviation (°C)" listData={[sec3?.isa_dev || 'ISA + 15°']} />
            <ListDataCard
              title="ARP Coordinates (WGS 84)"
              listData={[`LAT : ${sec3?.arp?.lat || '-'}`, `LNG : ${sec3?.arp?.lng || '-'}`]}
            />
          </div>
          <div className="type-data-list clearfix">
            <ListDataCard
              title="Temperature (°C)"
              listData={[
                `AD REF TEMP : ${sec3?.ad_ref_temp || '-'}`,
                `HIGH : ${
                  sec3?.ad_high_temp
                    ? // eslint-disable-next-line
                      `${(sec3?.ad_high_temp).toFixed(2)} °`
                    : ' - '
                }`,
                `LOW : ${
                  sec3?.ad_low_temp
                    ? // eslint-disable-next-line
                      `${(sec3?.ad_low_temp).toFixed(2)} °`
                    : ' - '
                }`
              ]}
            />
          </div>
          <div className="type-data-list remark-list clearfix">
            <div className="list-data-card" style={{ width: '552px' }}>
              <Text>Remark</Text>
              <Text style={{ color: primary }}>{aerodromeRemark || undefined}</Text>
            </div>
          </div>
        </div>
        <Divider dashed style={{ borderColor: lightGrey1, margin: '16px 0' }} />
        <div className="general-data-content">
          <ReportContentCard icon={<RunwayIcon />} title="RUNWAY DETAILS" />
          <div className="type-data-list clearfix">
            <ListDataCard title="Runway" listData={[sec3?.rwy || '-']} />
            <ListDataCard title="Runway Direction" listData={[sec3?.rwy_dir || '-']} />
            <ListDataCard
              title="RWY Bearing"
              listData={[
                `TRUE : ${sec3?.rwy_bearing}°`,
                `MAG :  ${
                  magneticDeclination
                    ? (Number(sec3?.rwy_bearing!) - magneticDeclination).toFixed(2)
                    : 0
                }°`
              ]}
            />
          </div>
          <div className="type-data-list clearfix">
            <ListDataCard
              title="Declared Distance (TORA)"
              listData={[sec3?.tora ? `${sec3?.tora} m` : '-']}
            />
            <ListDataCard
              title="Declared Distance (TODA)"
              listData={[sec3?.toda ? `${sec3?.toda} m` : '-']}
            />
          </div>
          <div className="type-data-list clearfix">
            <ListDataCard title="DER Designation" listData={[sec3?.rwy || '-']} />
            <ListDataCard
              title="DER Elevation"
              titleInfo="MAX(DER,AD ELEV)"
              listData={[sec3?.der_elv ? `${sec3?.der_elv.toFixed(2)} ft` : '-']}
            />
            <ListDataCard
              title="DER Coordinates (WGS 84)"
              listData={[
                `LAT : ${sec3?.der_coords?.lat ? latToDms(sec3?.der_coords?.lat) : '-'}`,
                `LNG : ${sec3?.der_coords?.lng ? latToDms(sec3?.der_coords?.lng) : '-'}`
              ]}
            />
          </div>
          <div className="type-data-list clearfix">
            <ListDataCard title="Other Lights" listData={['As Published in AIP']} />
            <ListDataCard title="Runway Centreline Lights" listData={['As Published in AIP']} />
          </div>
          <div className="type-data-list remark-list clearfix">
            <div className="list-data-card" style={{ width: '552px' }}>
              <Text>Remark</Text>
              <Text style={{ color: primary }}>{runwayRemark || undefined}</Text>
            </div>
          </div>
        </div>
        <Divider dashed style={{ borderColor: lightGrey1 }} />
        <div className="general-data-content">
          <ReportContentCard icon={<AirspaceIcon />} title="AIRSPACE DETAILS" />
          <div className="type-data-list clearfix">
            <div className="list-data-card">
              <Text>Airspace Classification</Text>
              <div>
                <div>
                  <Text className="select-title">TMA : </Text>
                  <Text style={{ color: primary, paddingLeft: 8 }}>
                    {tma ? airspaceClass[tma] : '-'}
                  </Text>
                </div>
                <div>
                  <Text className="select-title">CTA : </Text>
                  <Text style={{ color: primary, paddingLeft: 8 }}>
                    {cta ? airspaceClass[cta] : '-'}{' '}
                  </Text>
                </div>
                <div>
                  <Text className="select-title">CTR : </Text>
                  <Text style={{ color: primary, paddingLeft: 8 }}>
                    {ctr ? airspaceClass[ctr] : '-'}
                  </Text>
                </div>
              </div>
            </div>
            <ListDataCard
              title="Special Use Area close to Airport"
              listData={[sec3?.spl_use_area || '-']}
            />
          </div>
          <div className="type-data-list remark-list clearfix">
            <div className="list-data-card" style={{ width: '552px' }}>
              <Text>Remark</Text>
              <Text style={{ color: primary }}>{airspaceRemark || undefined}</Text>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewSection3;

import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { capitalize, filter, isEmpty, orderBy } from 'lodash';
import moment from 'moment';
import { Button, Dropdown, Table, Tooltip, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import CloseIcon from 'assets/svg/CloseIcon';
import ViewDesignIcon from 'assets/svg/ViewDesignIcon';
import InfoCircleIcon from 'assets/svg/InfoCircleIcon';
import ViewTimelineIcon from 'assets/svg/ViewTimelineIcon';
import { TimezoneContext } from 'context/createusecontext';
import AvatarUserComponent from 'components/core/AvatarUserComponent';
import DesignStatusComponents from 'components/core/DesignStatusComponents';
import Emptytext from 'components/core/Emptytext';
import { DateFormat2, TimeFormat } from 'constants/common.constant';
import { lightGrey2, secondary } from 'constants/color.constant';
import { useAppSelector } from 'hooks/useReduxHook';
import { DesignResponse, ProcedureDataType } from 'store/design/types';
import { UserRole } from 'store/users/types';
import { DesignStatus as Status } from 'types/common.types';
import './styles.less';

interface MarkerDetailsComponentProps {
  handleCloseMarkerDetail: Function;
  selectedDesignData: string;
}

const { Text } = Typography;

const MarkerDetailsComponent = ({
  handleCloseMarkerDetail,
  selectedDesignData
}: MarkerDetailsComponentProps) => {
  const navigate = useNavigate();
  const currentUser = useAppSelector((state) => state.user.userSession);
  const isOrgAdmin = currentUser?.role === UserRole.Admin;
  const designData = useAppSelector((state) => state.design.designs);
  const selectdDesignData = filter(designData, ['icao', selectedDesignData]);
  const [collapsedId, setCollapsedId] = useState<number | null>(null);

  const locale = {
    emptyText: <Emptytext />
  };

  const handleNavigate = (id: number) => {
    if (!isOrgAdmin) {
      navigate(`/assign/${id}`);
    }
  };

  // eslint-disable-next-line
  const handleClickSID = (id: number, data: ProcedureDataType, metaid: number) => {
    if (!isOrgAdmin) {
      navigate(`/design/${id}`, { state: { id, selectedProcedureData: data, metaId: metaid } });
    }
  };

  const handleOpenChange = (id: number) => {
    setCollapsedId(id);
  };

  const timeZone = useContext(TimezoneContext);

  const columns: ColumnsType<DesignResponse> = [
    {
      title: 'CFN CODE',
      dataIndex: 'cfn',
      key: 'cfn_code-mark',
      width: 250,
      render(value) {
        return <Text className="cfn-code">{value}</Text>;
      }
    },
    {
      title: 'TYPE OF PROCEDURE',
      dataIndex: 'procedure_type',
      align: 'center',
      key: 'type_procedure-mark',
      render(value) {
        return <Text className="type-procedure">{value}</Text>;
      }
    },
    {
      title: 'NAME OF PROCEDURE',
      dataIndex: 'name',
      key: 'name_procedure-mark',
      render(value) {
        return <Text className="name-procedure">{value}</Text>;
      }
    },
    {
      title: 'NO. OF PROCEDURES',
      dataIndex: 'procedures',
      align: 'center',
      key: 'procedures',
      render(_, record) {
        const totalProcedure = record.procedures.length;

        return (
          <Text className="all-procedures">
            {String(totalProcedure).length === 1 ? `0${totalProcedure}` : totalProcedure}
            {totalProcedure > 0 && (
              <Dropdown
                className="procedure-info"
                overlayClassName="procedure-dropdown"
                placement="bottomLeft"
                open={record.id === collapsedId}
                onOpenChange={() => handleOpenChange(record.id)}
                // eslint-disable-next-line
                dropdownRender={() => {
                  return (
                    <div className="procedure-dropdown-div">
                      <div className="dropdown-title">
                        <Text>No. of Procedures ({totalProcedure})</Text>
                        <CloseIcon height={16} width={16} onClick={() => setCollapsedId(null)} />
                      </div>
                      <ul className="procedure-lists">
                        {orderBy(record.procedures, ['id'], ['asc']).map(
                          (data: ProcedureDataType) => (
                            <li key={data.id}>
                              <a onClick={() => handleClickSID(record.id, data, record.metaId)}>
                                {data.name} - {data.procedure}
                              </a>
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  );
                }}
                trigger={['click']}
              >
                <Button
                  type="text"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  icon={<InfoCircleIcon color="#696B72" />}
                />
              </Dropdown>
            )}
          </Text>
        );
      }
    },
    {
      title: 'MANAGER',
      dataIndex: 'manager',
      align: 'center',
      key: 'manager',
      render: (value) => {
        return <AvatarUserComponent data={value} userRole="manager" />;
      }
    },
    {
      title: 'DESIGNER',
      dataIndex: 'designer',
      align: 'center',
      key: 'designer',
      render: (value) => {
        return <AvatarUserComponent data={value} userRole="designer" />;
      }
    },
    {
      title: 'REVIEWER',
      dataIndex: 'reviwer',
      align: 'center',
      key: 'reviwer',
      render: (value) => {
        return <AvatarUserComponent data={value} userRole="reviewer" />;
      }
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      align: 'center',
      key: 'status-mark',
      render: (_, record) => {
        const data = capitalize(record.status);
        return <DesignStatusComponents data={data} />;
      }
    },
    {
      title: 'LAST UPDATED',
      dataIndex: 'modified_at',
      align: 'center',
      key: 'last_updated-mark',
      render(value) {
        const date: any = value ? moment(value).format(DateFormat2) : '-';
        const time: any = value ? `${moment(value).format(TimeFormat)} ${timeZone?.timezone}` : '';
        return (
          <div className="flexDirectionColumn">
            <Text style={{ color: `${lightGrey2}`, fontSize: 14, fontWeight: 500 }}>{date}</Text>
            <Text style={{ color: `${lightGrey2}`, fontSize: 14, fontWeight: 500 }}>{time}</Text>
          </div>
        );
      }
    },
    {
      title: 'ACTION',
      align: 'center',
      key: 'action-mark',
      render: (_, record) => {
        return (
          <>
            <Tooltip
              placement="bottom"
              color={secondary}
              title={!isOrgAdmin ? <Text className="tooltip-title">View Design</Text> : ''}
            >
              <Button
                type="text"
                key="view"
                className={isOrgAdmin ? 'view-inactive-btn' : ''}
                icon={<ViewDesignIcon />}
                onClick={() => handleNavigate(record.id)}
              />
            </Tooltip>
            {record.status === Status.Reviewed && (
              <Tooltip
                placement="bottom"
                color={secondary}
                title={!isOrgAdmin ? <Text className="tooltip-title">View Timeline</Text> : ''}
              >
                <Button
                  type="text"
                  className={isOrgAdmin ? 'view-inactive-btn' : ''}
                  style={{ marginLeft: 8 }}
                  key="view"
                  icon={<ViewTimelineIcon />}
                />
              </Tooltip>
            )}
          </>
        );
      }
    }
  ];

  return (
    <div className="marker-details">
      <div className="marker-detail-header">
        <div>Designs</div>
        <div>
          AIRPORT :{' '}
          {!isEmpty(selectdDesignData) && selectdDesignData[0].airport_name
            ? selectdDesignData[0].airport_name
            : '-'}
        </div>
        <Button
          type="text"
          shape="circle"
          icon={<CloseIcon />}
          onClick={() => handleCloseMarkerDetail()}
          className="back-icon-btn"
        />
      </div>
      <div className="marker-detail-table">
        <Table
          className="design-table-main"
          columns={columns}
          dataSource={selectdDesignData || []}
          pagination={false}
          scroll={{ y: 317 }}
          locale={locale}
          rowKey={(record) => record.id}
        />
      </div>
    </div>
  );
};

export default MarkerDetailsComponent;

import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useMapEvents } from 'react-leaflet';
import { latlngToDms } from 'utils/utils';
import './styles.less';

const LiveCoordinates = () => {
  const [currentPosition, setCenterPosition] = useState<any>('');
  useMapEvents({
    mousemove(e: any) {
      if (!isEmpty(e.latlng)) {
        const latlngToDmsString = latlngToDms(`${e.latlng.lat}, ${e.latlng.lng}`);
        setCenterPosition(latlngToDmsString);
      }
    }
  });

  return (
    <div className="current-position">
      <div>Map Source: Leaflet</div>
      <div>{currentPosition}</div>
    </div>
  );
};

export default LiveCoordinates;

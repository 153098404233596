import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import L from 'leaflet';
import { Circle, FeatureGroup, MapContainer, Marker, Polyline, Tooltip } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import moment from 'moment';
import { filter, find, includes, isEmpty, isNull, keys, orderBy, remove, uniq } from 'lodash';
import { Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';
import Sider from 'antd/es/layout/Sider';
import { airportDataCategories, airspaceDataLabel } from 'constants/collectData.constant';
import { defaultCenter } from 'constants/common.constant';
import { blue, primary, red2 } from 'constants/color.constant';
import { acftCategory } from 'constants/designData.constants';
import {
  AerodromePointIcon,
  BlueTrianglePointIcon,
  FlybyWaypointLightIcon,
  FlyoverWaypointLightIcon,
  LightTriangleIcon
} from 'constants/map.constants';
import RenderPALineData from 'components/Map/RenderPALineData';
import DesignToolHeader from 'components/DesignData/DesignToolHeader';
import ARPPolyline from 'components/Map/ARPPolyline';
import ControlMapCenter from 'components/Map/ControlMapCenter';
import LiveCoordinates from 'components/Map/LiveCoordinates';
import SelectWaypointMarkers from 'components/Map/SelectWaypointMarkers';
import MapActionControlV2 from 'components/Map/MapActionControlV2';
import MapLayers from 'components/Map/MapLayers';
import MeasurementControl from 'components/Map/MeasurementControl';
import DesignSidebar from 'components/Sidebar/DesignSidebar';
import DesignStartedSidebar from 'components/Sidebar/DesignStartedSidebar';
import CreateWaypoint from 'components/Map/CreateWaypoint';
import EnterWaypoint from 'components/Map/EnterWaypoint';
import RenderLegData from 'components/Map/RenderLegData';
import RenderProtectionAreaLegs from 'components/Map/RenderProtectionAreaLegs';
import AixmObstaclesAdData from 'components/Map/AixmObstaclesAdData';
import RenderObstaclesDepatureOmniData from 'components/Map/RenderObstaclesDepatureOmniData';
import RenderToleranceData from 'components/Map/RenderToleranceData';
import OverseeLine from 'components/Map/OverseeLine';
import LineStringComponent from 'components/Map/LineStringComponent';
import RefrenceLine from 'components/Map/RefrenceLine';
import RenderPrevLegData from 'components/Map/RenderPrevLegData';
import ContentScreenLoaderV1 from 'components/core/ContentScreenLoaderV1';
import RenderControlledAirspace from 'components/Map/RenderControlledAirspace';
import RenderNavaidsAirspace from 'components/Map/RenderNavaidsAirspace';
import RenderWayPointsAirspace from 'components/Map/RenderWayPointsAirspace';
import RenderFIRAirspace from 'components/Map/RenderFIRAirspace';
import RenderRestrictiveAirspace from 'components/Map/RenderRestrictiveAirspace';
import RenderRNAVRouteAirspace from 'components/Map/RenderRNAVRouteAirspace';
import RenderCNAVRouteAirspace from 'components/Map/RenderCNAVRouteAirspace';
import DesignDataFullScreenMap from 'components/DesignData/DesignDataFullScreenMap';
import DeleteLegModal from 'components/Modals/DeleteLegModal';
import LineTextPath from 'components/Map/LineTextPath';
import RenderMilitryAirspace from 'components/Map/RenderMilitryAirspace';
import RFArcLine from 'components/Map/RFArcLine';
import DraggableMarker from 'components/Map/DraggableMarker';
import RenderADIZAirspace from 'components/Map/RenderADIZAirspace';
import RenderWindspiralData from 'components/Map/RenderWindspiralData';
import RenderSelectedToolHeaderData from 'components/DesignData/RenderSelectedToolHeaderData';
import RenderObstacleTableData from 'components/DesignData/RenderObstacleTableData';
import DesignTableFullScreenView from 'components/DesignData/DesignTableFullScreenView';
import RenderSurveyObstaclePoint from 'components/Map/RenderSurveyObstaclePoint';
import WMTSTileLayer from 'components/Map/WMTSTileLayer';
import LayerLegendImage from 'components/DesignData/LayerLegendImage';
import TerrainWMTSTileLayer from 'components/Map/TerrainWMTSTileLayer';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import {
  useAddDesignLegdataMutation,
  useAddDesignObstacleAnalysisLegdataMutation,
  useGetDesignInitStartInfodataQuery,
  useLazyGetDesignLegsdataQuery,
  useGetDesignAllMetadataQuery,
  useGetDesignQuery,
  useLazyGetDesignInfobtwPointsdataQuery,
  useLazyGetDesignWaypointdataQuery,
  useLazyGetLegObstacleAnalysisdataQuery,
  useLazyGetDesignProcedureListQuery,
  useLazyGetDesignProcedureVersionsQuery,
  useLazyGetAreasemiwidthQuery,
  useLazyGetRefrenceLineQuery,
  useLazyGetDesignLegdataByLegidQuery,
  useLazyGetCodingTableDataQuery,
  useDeleteSelectedDesignLEGMutation,
  useLazyGetSurveyObstaclesQuery,
  useGetCourseRangeMutation,
  useGetComputeV1PointMutation,
  useGetNominalCirclesQuery,
  useLazyGetPathTerminatorsChoicesQuery,
  useAddRemarkMutation
} from 'services/apis/designsApi';
import { enqueueError, setWarningAlert } from 'store/alert';
import {
  setADData,
  setAirspaceData,
  setAvailableADData,
  setAvailableAirspaceData,
  setDesignAllMetaData,
  setDesignProcedureVersionsData,
  setDesignProcedurelist,
  setSelectDesignLegData,
  setSelectDesignLegDataDeparture,
  setSelectDesignLegDataOMNI,
  setSelectWaypointData,
  setSelectedDesign
} from 'store/design';
import {
  silentDesignMetadataByMetaidCall,
  silentDesignProcedureVersionsCall,
  silentInfoBtwCall
} from 'store/design/actions';
import {
  DesignState,
  DesignVersionsDataType,
  InfobtwpointsResponseType,
  InitStartInfoType,
  MetaDataType,
  OAlegdata,
  ProcedureDataType,
  WaypointMarkerResponseType,
  TemperatureType,
  AreasemiwidthDepartureType,
  SurveyObstacleType,
  CourseRangeRequestType,
  CourseRangeResponseType,
  ComputePointRequestType
} from 'store/design/types';
import { UserRole, UserState } from 'store/users/types';
import { CollectDataType, DrawType, Maptype, PathTerminatorOptionsType } from 'types/common.types';
import { drawArcBetweenPoints } from 'utils/Arc';
import {
  getBankAngle,
  getEndPointHeight,
  getEndPointLength,
  getEndPointPDG,
  getTurnAndleDataHandling,
  isFloat,
  nearestSnapPointOnLine,
  tasCalculation
} from 'utils/utils';
import './styles.less';

const Designdata = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const drawRef: any = useRef(null);
  const { designid }: any = useParams();
  const featureGroupRef: any = useRef();
  const dispatch = useAppDispatch();
  const { userSession }: UserState = useAppSelector((state) => state.user);
  const isDesigner = Boolean(userSession?.role === UserRole.Designer);
  const {
    selectedDesign,
    legsData,
    availableADData,
    availableAirspaceData,
    legsDataOMNI,
    designProcedurelist,
    selectWaypointData,
    legsDataDeparture,
    designProcedureVersionsData,
    isLoadingSilentInfoBtwPoint,
    isLoadingSilentDesignProcedureVersions,
    isLoadingDesignMetadataByMetaid
  }: DesignState = useAppSelector((state) => state.design);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedMetaId, setSelectedMetaId] = useState<number | null>(null);
  const [selectedProcedureId, setSelectedProcedureId] = useState<any>();
  const [selectedProcedureData, setSelectedProcedureData] = useState<ProcedureDataType | null>(
    null
  );
  const [selectedProcedureVersionId, setSelectedProcedureVersionId] = useState<number | null>(null);
  const [mapZoom, setMapZoom] = useState<number>(11);
  const [arp, setARP] = useState<any>(null);
  const [selectedDesignMetadata, setSelectedDesignMetadata] = useState<any>(null);
  const [mapCenter, setMapCenter] = useState<any>(defaultCenter);
  const [activeLeg, setActiveLeg] = useState<string>('1');
  const [activeDesignStep, setActiveDesignStep] = useState<number>(0);
  const [isStartDesign, setStartDesign] = useState(false);
  const [mapType, setMapType] = useState<string>(Maptype.satellite);
  const [isShowAeroTable, setShowAeroTable] = useState<boolean>(false);
  const [activeMapTools, setactiveMapTools] = useState<string | null>(null);
  const [activeMapElement, setActiveMapElement] = useState<string[]>([]);
  const [pointLatLng, setPointLatLng] = useState<any>([]);
  const [newPolyLineCoords, setNewPolyLineCoords] = useState<any>([]);
  const [centroidPoint, setCentroidPoint] = useState<any>(null);
  const [activeLegDesignDataDeparture, setActiveLegDesignDataDeparture] = useState<any>(null);
  const [activePrevLegDesignDataDeparture, setActivePrevLegDesignDataDeparture] =
    useState<any>(null);
  const [isactiveCodingTable, setActiveCodingTable] = useState<boolean>(false);
  const [oaLegCheckedKey, setOALegCheckedKey] = useState<string | null>(null);
  const [protectionAreaCheckList, setProtectionAreaCheckList] = useState<string[]>([]);
  const [showDataLayerId, setShowDataLayerId] = useState<string | null>(null);
  const [temperature, setTemperature] = useState<TemperatureType | null>(null);
  const [magneticDeclination, setMagneticDeclination] = useState<number | null>(null);
  const [terrainLayerName, setTerrainLayerName] = useState<string | null>(null);
  const [initInfoBearing, setInitInfoBearing] = useState<number | null>(null);
  const [overseeLineData, setOverseeLineData] = useState<any>([]);
  const [refrenceLineData, setRefrenceLineData] = useState<any>([]);
  const adItem: CollectDataType | undefined = find(airportDataCategories, [
    'value',
    showDataLayerId
  ]);

  const [isGeneratedOA, setGeneratedOA] = useState<boolean>(false);
  const [isCheckedPASwitch, setCheckedPASwitch] = useState<boolean>(false);
  const [palegDatacheckedList, setPAlegDatacheckedList] = useState<string[]>([]);
  const [designClearWay, setDesignClearWay] = useState<number | null>(null);
  /* Procedure --->   DEPARTURE  ---> state --->  START */
  const [activeDeptBtnDeparture, setActiveDeptBtnDeparture] = useState<string>('endpoint');
  const [selectedWayPointMarker, setSelectedWayPointMarker] =
    useState<WaypointMarkerResponseType | null>(null);
  const [selectedPathTerminatorDeparture, setSelectedPathTerminatorDeparture] = useState<
    string | undefined
  >(undefined);
  const [isShowHideOADataDeparture, setShowHideOADataDeparture] = useState(false);
  const [isOATableDepartureShow, setOATableDepartureShow] = useState(false);
  const [endSelectNavaidWaypoint, setEndSelectNavaidWaypoint] = useState<string>('');
  const [endWaypointLat, setEndWaypointLat] = useState<number | null>(null);
  const [endWaypointLng, setEndWaypointLng] = useState<number | null>(null);
  const [endWaypointLatLngDeparture, setEndWaypointLatLngDeparture] = useState<any>([]);

  const [isViewOnMapEnterWaypoint, setViewOnMapEnterWaypoint] = useState(false);
  const [activeFlyBtn, setactiveFlyBtn] = useState<string>('over');
  const [isShowWayPointOnMapDeparture, setShowWayPointOnMapDeparture] = useState<boolean>(false);
  const [areasemiwidthDeparture, setAreasemiwidthDeparture] =
    useState<AreasemiwidthDepartureType | null>(null);
  const [initStartInfoDeparture, setInitStartInfoDataDeparture] =
    useState<InitStartInfoType | null>(null);
  const [courseDeparture, setCourseDeparture] = useState<number | null>(null);
  const [endAltitudeDeparture, setEndAltitudeDeparture] = useState<number | null>(null);
  const [pdgDeparture, setPDGDeparture] = useState<number | null>(3.3);
  const [changeCWYPointInfoDataDeparture, setChangeCWYPointInfoDataDeparture] =
    useState<InfobtwpointsResponseType | null>(null);
  const [gradLengthDeparture, setGradLengthDeparture] = useState<number | null>(null);
  const [gradHeightDeparture, setGradHeightDeparture] = useState<number | null>(null);
  const [cwyDisDeparture, setCWYDisDeparture] = useState<number | null>(null);
  const [turnAngleDeparture, setTurnAngleDeparture] = useState<number | null>(null);
  const [turnDirectionDeparture, setTurnDirectionDeparture] = useState<string | null>(null);
  const [iasSpeedDeparture, setIASSpeedDeparture] = useState<number | null>(
    selectedDesign ? acftCategory[selectedDesign?.acft_cat!].max : null
  );
  const [tasSpeedDeparture, setTasSpeedDeparture] = useState<number | null>(null);
  const [DERCoordsDeparture, setDERCoordsDeparture] = useState<any>([]);
  const [newLegDERCoordsDeparture, setNewLegDERCoordsDeparture] = useState<any>([]);
  const [activeOALegDesignDataDeparture, setActiveOALegDesignDataDeparture] = useState<
    OAlegdata[] | []
  >([]);
  const [activeTerrainLegDesignDataDeparture, setActiveTerrainLegDesignDataDeparture] = useState<
    OAlegdata[] | []
  >([]);
  const [isShowHideOAData, setShowHideOAData] = useState(false);
  const [isShowHideTerrainLayerDeparture, setShowHideTerrainLayerDeparture] = useState(false);
  const [isShowHideObstacleLayer, setShowHideObstacleLayer] = useState(false);
  const [isShowHideTerrainLayer, setShowHideTerrainLayer] = useState(false);
  const [isShowHideOATable, setShowHideOATable] = useState(false);
  const [isShowClosingObstacle, setShowClosingObstacle] = useState(false);
  const [legWindspiralsData, setLegWindspiralsData] = useState<any>(null);
  const [radiusOfTurn, setRadiusOfTurn] = useState<number | null>(null);
  const [CFCourseDept, setCFCourseDept] = useState<number | undefined>(undefined);
  const [courseRangeDataDept, setCourseRangeDataDept] = useState<CourseRangeResponseType[] | []>(
    []
  );
  const [pathTerminatorOptions, setPathTerminatorOptions] = useState<
    PathTerminatorOptionsType[] | []
  >([]);
  // const [isShowCFPathTerminatorConfirmation, setShowCFPathTerminatorConfirmation] = useState(false);
  // const [CFStartPointLatLng, setCFStartPointLatLng] = useState<any>([]);
  /* Procedure --->   DEPARTURE  ---> state --->  END */
  /* Procedure --->   OMNI  ---> state --->  START */
  const [DERCoordsOMNI, setDERCoordsOMNI] = useState<any>([]);
  const [cwyDisOMNI, setCWYDisOMNI] = useState<number | null>(null);
  const [pdgOMNI, setPDGOMNI] = useState<number>(3.3);
  const [disableEndPointFieldOMNI, setDisableEndPointFieldOMNI] = useState<string>('endalt');
  const [endAltGradLengthOMNI, setEndAltGradLengthOMNI] = useState<number | null>(null);
  const [endAltitudeOMNI, setEndAltitudeOMNI] = useState<number | null>(null);
  const [activeLegID, setActiveLegID] = useState<number | null>(null);
  const [initStartInfoOMNI, setInitStartInfoDataOMNI] = useState<InitStartInfoType | null>(null);
  const [initInfoDesignData, setInitInfoDesignData] = useState<any>(null);
  const [changeCWYPointInfoDataOMNI, setChangeCWYPointInfoDataOMNI] =
    useState<InfobtwpointsResponseType | null>(null);
  const [changeLengthPointInfoDataOMNI, setChangeLengthPointInfoDataOMNI] =
    useState<InfobtwpointsResponseType | null>(null);
  const [endPointLatlngOMNI, setEndPointLatlngOMNI] = useState<any>([]);
  const [iasSpeedOMNI, setIASSpeedOMNI] = useState<number | null>(
    selectedDesign ? acftCategory[selectedDesign?.acft_cat!].max : null
  );
  const [tasSpeedOMNI, setTASSpeedOMNI] = useState<number | null>(null);
  const [activeLegDesignDataOMNI, setActiveLegDesignDataOMNI] = useState<any>(null);
  const [activeOALegDesignDataOMNI, setActiveOALegDesignDataOMNI] = useState<OAlegdata[] | []>([]);
  const [activeTerrainLegDesignDataOMNI, setActiveTerrainLegDesignDataOMNI] = useState<
    OAlegdata[] | []
  >([]);
  const [isShowHideOADataOMNI, setShowHideOADataOMNI] = useState(false);
  const [isOATableOmniShow, setOATableOmniShow] = useState(false);
  const [isShowHideDeleteLegModal, setShowHideDeleteLegModal] = useState(false);
  const [deleteLegKey, setDeleteLegKey] = useState<string | null>(null);
  const [isShowHideTerrainLayerOMNI, setShowHideTerrainLayerOMNI] = useState(false);
  /* Procedure --->   OMNI  ---> state --->  END */
  const [activeObscleTerrainDataKey, setActiveObscleTerrainDataKey] = useState<string>('obstacle');
  const [surveyObstacleData, setSurveyObstacleData] = useState<SurveyObstacleType[] | []>([]);
  const [finalArcCords, setFinalArcCords] = useState<any>([]);
  const [arcEndCoord, setArcEndCoord] = useState<any>([]);
  const [arcCenterCoord, setArcCenterCoord] = useState<any>([]);
  const [nominalCircles, setNominalCircles] = useState<any>(null);
  const [isObsTerrainFullScreenTable, setObsTerrainFullScreenTable] = useState<boolean>(false);
  const [isFullScreenMapView, setFullScreenMapView] = useState<boolean>(false);
  const [isLengthAltPDGRangeOMNI, setLengthAltPDGRangeOMNI] = useState<boolean>(false);
  const [isTerminatingWaypoint, setTerminatingWaypoint] = useState<boolean>(false);
  const [remarksDepOmni, setRemarksDepOmni] = useState<string | undefined>('');
  const [isShowHideRemarkDepOmniBtn, setShowHideRemarkDepartureBtn] = useState<boolean>(false);

  const legObstacleAnalysis =
    selectedProcedureData && selectedProcedureData.procedure === 'OMNI'
      ? activeObscleTerrainDataKey === 'obstacle'
        ? activeOALegDesignDataOMNI
        : activeObscleTerrainDataKey === 'terrain'
        ? activeTerrainLegDesignDataOMNI
        : []
      : activeObscleTerrainDataKey === 'obstacle'
      ? activeOALegDesignDataDeparture
      : activeObscleTerrainDataKey === 'terrain'
      ? activeTerrainLegDesignDataDeparture
      : [];

  const { data: designAllMetadata, isLoading: isLoadingAllMetadata } = useGetDesignAllMetadataQuery(
    { orgId: userSession?.organisationid, designId: Number(designid) },
    {
      refetchOnMountOrArgChange: false,
      skip: !(Boolean(userSession && userSession?.organisationid) && Boolean(designid))
    }
  );
  const { data: designData, isLoading: isLoadingDesign } = useGetDesignQuery(
    { orgId: userSession?.organisationid, designId: designid },
    {
      refetchOnMountOrArgChange: true,
      skip: !(Boolean(userSession?.organisationid) && Boolean(designid))
    }
  );
  const [getDesignLegsdata, { data: designlegsData, isLoading: isLoadingLegsData }] =
    useLazyGetDesignLegsdataQuery();
  const { data: initStartInfoData, isLoading: isLoadingInitStartInfo } =
    useGetDesignInitStartInfodataQuery(
      { designId: designid },
      {
        refetchOnMountOrArgChange: true,
        skip: !designid
      }
    );

  const [getSurveyObstacles, { data: collectSurveyObstaclesdata }] =
    useLazyGetSurveyObstaclesQuery();
  const { data: nominalCirclesData } = useGetNominalCirclesQuery(Number(designid), {
    refetchOnMountOrArgChange: true,
    skip: !designid
  });

  const [
    getDesignProcedureVersions,
    { data: designProcedureVersnData, isLoading: isLoadingDesignProcedureVersion }
  ] = useLazyGetDesignProcedureVersionsQuery();

  const [
    getDesignProcedureList,
    { data: selectedDesignProcedureList, isLoading: isLoadingProcedureList }
  ] = useLazyGetDesignProcedureListQuery();

  const [
    getPathTerminatorsChoices,
    { data: pathTerminatorData, isLoading: isLoadingPathTerminatorsChoices }
  ] = useLazyGetPathTerminatorsChoicesQuery();

  const [
    getDesignWaypointdata,
    { data: waypointsData, isFetching: isFetchingWaypoint, isLoading: isLoadingWaypoints }
  ] = useLazyGetDesignWaypointdataQuery();

  const [
    getDesignInfobtwPointsdata,
    { data: finalInfobtwPointsData, isLoading: isLoadingInfoBtwPoints }
  ] = useLazyGetDesignInfobtwPointsdataQuery();

  const [
    getLegObstacleAnalysisdata,
    { data: selectedLegObstactsData, isFetching: isFetchingOAData, isLoading: isLoadingOAData }
  ] = useLazyGetLegObstacleAnalysisdataQuery();

  const [
    getRefrenceLine,
    {
      data: refrenceLineResData,
      isLoading: isLoadingRefrenceline,
      isFetching: isFetchingReferenceline
    }
  ] = useLazyGetRefrenceLineQuery();

  const [getAreasemiwidth, { data: areasemiwidthData, isLoading: isLoadingAreasemiwidth }] =
    useLazyGetAreasemiwidthQuery();

  const [getDesignLegdataByLegid, { data: selectedLegData, isLoading: isLoadingLegdatabyLegId }] =
    useLazyGetDesignLegdataByLegidQuery();

  const [getCodingTableData, { data: codingTableData, isFetching: isLoadingCodingTableData }] =
    useLazyGetCodingTableDataQuery();

  const [addDesignLegdata, { data: generateLegData, isLoading: isLoadingAddLegdata }] =
    useAddDesignLegdataMutation();

  // const [getSnapPoint, { data: snapPointData, isLoading: isLoadingSnappoint }] =
  //   useGetSnapPointMutation();

  const [addDesignObstacleAnalysisLegdata, { isLoading: isLoadingAddOAData }] =
    useAddDesignObstacleAnalysisLegdataMutation();

  const [getCourseRange, { data: courseRangeData, isLoading: isLoadingCourseRange }] =
    useGetCourseRangeMutation();

  const [getComputeV1Point, { data: courseV1PointData, isLoading: isLoadingComputeV1Point }] =
    useGetComputeV1PointMutation();

  const [deleteSelectedDesignLEG, { data: deleteData, isLoading: isLoadingDeleteLegData }] =
    useDeleteSelectedDesignLEGMutation();

  const [addRemark, { isLoading: isLoadingAddRemarkData }] = useAddRemarkMutation();

  const handleGetPathTerminatorData = async (
    designId: number,
    procedureId: number,
    designversionId: number
  ) => {
    await getPathTerminatorsChoices({
      designId,
      procedureId,
      designversionId
    });
  };

  const handleAddNewLeg = () => {
    handleGetPathTerminatorData(
      Number(designid),
      selectedProcedureId!,
      selectedProcedureVersionId!
    );
  };

  useEffect(() => {
    if (!isEmpty(location.state)) {
      setSelectedProcedureData(location.state.selectedProcedureData);
      setSelectedProcedureId(location.state.selectedProcedureData.id);
      setSelectedMetaId(location.state.metaId || null);
      setTimeout(async () => {
        const urlString = `/organisations/${userSession?.organisationid}/designs/${location.state.id}/metadata/${location.state.metaId}/procedures/${location.state.selectedProcedureData.id}/designversions`;
        await dispatch(
          silentDesignProcedureVersionsCall(urlString, async (data: any) => {
            dispatch(setDesignProcedureVersionsData(data || []));
            if (!isEmpty(data)) {
              setSelectedProcedureVersionId(data[0].id || null);
              setTerminatingWaypoint(Boolean(data[0].is_terminal));
              await getDesignLegsdata({
                orgId: userSession?.organisationid,
                designId: Number(location.state.id),
                metaId: location.state.metaId,
                procedureId: location.state.selectedProcedureData.id,
                designversionId: data[0].id
              });

              await handleGetPathTerminatorData(
                Number(designid),
                location.state.selectedProcedureData.id,
                data[0].id
              );
              setStartDesign(true);
            }
          })
        );

        await getSurveyObstacles({
          metaId: location.state.metaId
        });
        await getDesignProcedureList({
          orgId: userSession?.organisationid,
          designid: Number(designid),
          metaId: location.state.metaId
        });
      }, 0);
    }
  }, [location]);

  useEffect(() => {
    if (pathTerminatorData) {
      const pathTerminatorDataRes: any = pathTerminatorData;
      if (pathTerminatorDataRes.status) {
        setPathTerminatorOptions(pathTerminatorDataRes?.data || []);
      }
    }
  }, [pathTerminatorData]);

  const handlecallInfobtwPointsOmni = (length: number) => {
    if (!length) return;
    if (changeCWYPointInfoDataOMNI) {
      const latA = changeCWYPointInfoDataOMNI?.B[0];
      const lngB = changeCWYPointInfoDataOMNI?.B[1];
      const bearingdata = Number(changeCWYPointInfoDataOMNI?.bearing);
      const urlString = `/infobtwpoints?lat_a=${latA}&lng_a=${lngB}&distance=${length}&bearing=${bearingdata}`;
      getDesignInfobtwPointsdata({ urlString });
    } else if (initStartInfoOMNI) {
      const latA = initStartInfoOMNI?.thr_point[0];
      const lngB = initStartInfoOMNI?.thr_point[1];
      const bearingdata = Number(initStartInfoOMNI?.bearing);
      const urlString = `/infobtwpoints?lat_a=${latA}&lng_a=${lngB}&distance=${length}&bearing=${bearingdata}`;
      getDesignInfobtwPointsdata({ urlString });
    }
  };

  const handlecallInfobtwPointsDeparture = (gradLength: number) => {
    if (!gradLength) return;
    if (changeCWYPointInfoDataDeparture) {
      const latA = changeCWYPointInfoDataDeparture?.B[0];
      const lngB = changeCWYPointInfoDataDeparture?.B[1];
      const bearingdata = Number(changeCWYPointInfoDataDeparture?.bearing);
      const urlString = `/infobtwpoints?lat_a=${latA}&lng_a=${lngB}&distance=${gradLength}&bearing=${bearingdata}`;
      getDesignInfobtwPointsdata({ urlString });
    } else if (initStartInfoDeparture) {
      const latA = initStartInfoDeparture?.thr_point[0];
      const lngB = initStartInfoDeparture?.thr_point[1];
      const bearingdata = Number(initStartInfoDeparture?.bearing);
      const urlString = `/infobtwpoints?lat_a=${latA}&lng_a=${lngB}&distance=${gradLength}&bearing=${bearingdata}`;
      getDesignInfobtwPointsdata({ urlString });
    }
  };

  const handleCFComputeCourseRange = async (
    prevStartPoint: number[],
    currentStartPoint: number[],
    currentEndPoint: number[]
  ) => {
    const payload: CourseRangeRequestType = {
      prev_start_point: {
        lat: prevStartPoint[0],
        lng: prevStartPoint[1]
      },
      start_point: {
        lat: currentStartPoint[0],
        lng: currentStartPoint[1]
      },
      end_point: {
        lat: currentEndPoint[0],
        lng: currentEndPoint[1]
      }
    };
    await getCourseRange(payload);
  };

  const handleRefrencelineApiCall = async (legSequence: number | string) => {
    const urlString: string = `/outbound_track?procedureId=${selectedProcedureData?.id}&leg_sequence=${legSequence}`;

    await getRefrenceLine(urlString);
  };

  const handleRefrenceLineData = (refDataRes: any) => {
    setRefrenceLineData(!isEmpty(refDataRes) ? refDataRes.refrence_line : []);
    setOverseeLineData(!isEmpty(refDataRes) ? refDataRes.oversee_line : []);
  };

  // Departure Procedure Leg cleardata
  const handleClearAllFieldsDataDeparture = () => {
    setActiveLeg('1');
    setDERCoordsDeparture(
      initStartInfoDeparture ? [initStartInfoDeparture.der[0], initStartInfoDeparture.der[1]] : []
    );
    setEndSelectNavaidWaypoint('');
    setActiveLegDesignDataDeparture(null);
    setActivePrevLegDesignDataDeparture(null);
    setEndAltitudeDeparture(null);
    setGradHeightDeparture(null);
    setGradLengthDeparture(null);
    setTurnAngleDeparture(null);
    setTurnDirectionDeparture(null);
    setIASSpeedDeparture(selectedDesign ? acftCategory[selectedDesign?.acft_cat!].max : null);
    setTasSpeedDeparture(null);
    setPDGDeparture(3.3);
    setActiveOALegDesignDataDeparture([]);
    setActiveTerrainLegDesignDataDeparture([]);
    setEndWaypointLatLngDeparture([]);
    setShowHideOAData(false);
    setShowHideOATable(false);
    setShowClosingObstacle(false);
    setShowHideOADataDeparture(false);
    setOATableDepartureShow(false);
    setEndWaypointLat(null);
    setEndWaypointLng(null);
    setAreasemiwidthDeparture(null);
    setSelectedWayPointMarker(null);
    setSelectedPathTerminatorDeparture(undefined);
    dispatch(setSelectDesignLegDataDeparture(null));
  };

  // OMNI data clear
  const handleClearAllFieldsDataOMNI = () => {
    setActiveLeg('1');
    setPDGOMNI(3.3);
    setEndAltGradLengthOMNI(null);
    setDisableEndPointFieldOMNI('endalt');
    setEndAltitudeOMNI(null);
    setActiveLegID(null);
    setChangeCWYPointInfoDataOMNI(null);
    setChangeLengthPointInfoDataOMNI(null);
    setEndPointLatlngOMNI([]);
    setIASSpeedOMNI(selectedDesign ? acftCategory[selectedDesign?.acft_cat!].max : null);
    setActiveOALegDesignDataOMNI([]);
    setActiveTerrainLegDesignDataOMNI([]);
    setShowHideOADataOMNI(false);
    setOATableOmniShow(false);
    setShowHideDeleteLegModal(false);
    setShowHideTerrainLayerOMNI(false);
    setActiveLegDesignDataOMNI(null);
    dispatch(setSelectDesignLegDataOMNI(null));
  };

  const handleSetValueGreater1Leg = (
    prevlegStartCoords: any,
    prevlegEndCoords: any,
    prevlegAlt: any,
    turnangle: any,
    turndir: any,
    BPointLatLng: any,
    endLength: number,
    course?: any
  ) => {
    setTurnAngleDeparture(turnangle);
    setTurnDirectionDeparture(turndir);
    setEndWaypointLatLngDeparture(BPointLatLng || []);
    if (course) {
      setCourseDeparture(course);
    }
    const startAlt = prevlegAlt || 0;
    let height: number = 0;
    if (selectedPathTerminatorDeparture === 'CA') {
      height = Number(endAltitudeDeparture!) - Number(startAlt);
    } else if (selectedPathTerminatorDeparture !== 'CA') {
      height = getEndPointHeight(Number(endLength), Number(pdgDeparture || 3.3));
      setEndAltitudeDeparture(Number(startAlt) + Number(height.toFixed(3)));
      if (selectedPathTerminatorDeparture === 'CF') {
        if (course && turnangle !== 0) {
          handleCFComputeCourseRange(prevlegStartCoords, prevlegEndCoords, BPointLatLng);
        }
      }
    }
    setGradHeightDeparture(height);
    const urlString = `/design/${
      selectedDesign?.id
    }/procedure/${selectedProcedureVersionId}/leg/${Number(activeLeg)}/area-semi-width?lat=${
      BPointLatLng[0]
    }&lng=${BPointLatLng[1]}`;
    getAreasemiwidth(urlString);
    setGradLengthDeparture(endLength);
    // const pdg = getEndPointPDG(height, endLength);
    // setPDGDeparture(pdg);
  };

  useEffect(() => {
    if (nominalCirclesData) {
      const nominalCirclesRes: any = nominalCirclesData;
      if (nominalCirclesRes.status) {
        setNominalCircles(nominalCirclesRes.data);
      }
    }
  }, [nominalCirclesData]);

  // Course Range handling
  useEffect(() => {
    if (courseRangeData) {
      const courseRangeDataRes: any = courseRangeData;
      if (courseRangeDataRes.status) {
        setCourseRangeDataDept(courseRangeDataRes?.data || []);
      }
    }
  }, [courseRangeData]);

  // eslint-disable-next-line
  const handleSilentInfoBtwCallCallback = (data: any, newStartCoords: any) => {
    if (data?.status) {
      const finalData = data?.data;
      if (isEmpty(finalData)) return;
      let turnangle1 =
        activePrevLegDesignDataDeparture.parameters.bearing - Number(finalData.bearing);
      let turndirection1 =
        Number(turnangle1.toFixed(3)) === 0
          ? 'on-set'
          : Number(turnangle1.toFixed(3)) > 0
          ? 'left'
          : 'right';

      const turnAngleDir = getTurnAndleDataHandling(
        Math.abs(turnangle1),
        selectedPathTerminatorDeparture!,
        turndirection1,
        Number(activeLeg)
      );

      if (turnAngleDir) {
        if (turnAngleDir.isError) {
          dispatch(setWarningAlert(turnAngleDir?.message));
        } else {
          turnangle1 = turnAngleDir.angle;
          turndirection1 = turnAngleDir.direction;
          handleSetValueGreater1Leg(
            [
              activePrevLegDesignDataDeparture.start_waypoint.lat,
              activePrevLegDesignDataDeparture.start_waypoint.lng
            ],
            [
              activePrevLegDesignDataDeparture.end_waypoint.lat,
              activePrevLegDesignDataDeparture.end_waypoint.lng
            ],
            activePrevLegDesignDataDeparture.end_waypoint.alt,
            turnangle1,
            turndirection1,
            finalData.B,
            finalData.distance,
            null
          );
          setNewLegDERCoordsDeparture(newStartCoords);
        }
      }
    }
  };

  // Course Range handling
  useEffect(() => {
    if (courseV1PointData) {
      const courseV1PointDataRes: any = courseV1PointData;
      if (courseV1PointDataRes.status) {
        setTimeout(() => {
          const urlString = `/infobtwpoints?lat_a=${courseV1PointDataRes?.data?.lat}&lng_a=${courseV1PointDataRes?.data?.lng}&lat_b=${endWaypointLatLngDeparture[0]}&lng_b=${endWaypointLatLngDeparture[1]}`;
          dispatch(
            silentInfoBtwCall(urlString, (data: any) => {
              handleSilentInfoBtwCallCallback(data, [
                courseV1PointDataRes?.data?.lat,
                courseV1PointDataRes?.data?.lng
              ]);
            })
          );
        }, 0);
      }
    }
  }, [courseV1PointData]);

  // Delete leg handling
  useEffect(() => {
    if (deleteData) {
      const deleteDataRes: any = deleteData;
      if (deleteDataRes.status) {
        setActiveLeg(String(Number(deleteLegKey) - 1));
        setShowHideDeleteLegModal(false);
        if (Number(deleteLegKey) === 1) {
          getPathTerminatorsChoices({
            designId: Number(designid),
            procedureId: selectedProcedureId!,
            designversionId: selectedProcedureVersionId!
          });
        }
        getDesignLegsdata({
          orgId: userSession?.organisationid,
          designId: Number(designid),
          metaId: selectedMetaId,
          procedureId: selectedProcedureId!,
          designversionId: selectedProcedureVersionId
        });
        getDesignProcedureVersions({
          orgId: userSession?.organisationid,
          designid: Number(designid),
          metaId: selectedMetaId,
          procedureId: selectedProcedureId!
        });
      }
    }
  }, [deleteData]);

  // Refrence Line data handling
  useEffect(() => {
    if (refrenceLineResData) {
      const refrenceLineResponse: any = refrenceLineResData;
      if (refrenceLineResponse.status) {
        handleRefrenceLineData(refrenceLineResponse.data);
      }
    }
  }, [refrenceLineResData, isFetchingReferenceline]);

  // Omni end point lenght, pdg, endAlt handling
  useEffect(() => {
    if (disableEndPointFieldOMNI === 'endalt') {
      if (pdgOMNI && endAltGradLengthOMNI) {
        const endAlt = getEndPointHeight(Number(endAltGradLengthOMNI), pdgOMNI);
        const finalEndAlt = isFloat(endAlt) ? (endAlt + 1).toFixed() : endAlt;
        setEndAltitudeOMNI(Number(finalEndAlt));
        handlecallInfobtwPointsOmni(Number(endAltGradLengthOMNI));
      }
    } else if (disableEndPointFieldOMNI === 'pdg') {
      if (endAltitudeOMNI && endAltGradLengthOMNI) {
        const endHeight = Number(endAltitudeOMNI) - Number(initStartInfoOMNI?.der_elev || 0);
        const pdg = getEndPointPDG(Number(endHeight), Number(endAltGradLengthOMNI));
        setPDGOMNI(pdg);
        handlecallInfobtwPointsOmni(Number(endAltGradLengthOMNI));
      }
    } else if (disableEndPointFieldOMNI === 'length') {
      if (endAltitudeOMNI && pdgOMNI) {
        const endHeight = Number(endAltitudeOMNI) - Number(initStartInfoOMNI?.der_elev || 0);
        const length = getEndPointLength(Number(endHeight), pdgOMNI);
        setEndAltGradLengthOMNI(length);
        if (length) {
          handlecallInfobtwPointsOmni(Number(length));
        }
      }
    }
  }, [pdgOMNI, endAltGradLengthOMNI, endAltitudeOMNI, disableEndPointFieldOMNI]);

  // get design data handling
  useEffect(() => {
    if (designData) {
      const designDataRes: any = designData;
      if (designDataRes.status) {
        dispatch(setSelectedDesign(!isEmpty(designDataRes.data) ? designDataRes.data : null));

        setMapZoom(13);
        // @ts-ignore
        setMapCenter(
          designDataRes.data
            ? [Number(designDataRes.data.latitude), Number(designDataRes.data.longitude)]
            : defaultCenter
        );
        if (!isEmpty(designDataRes.data)) {
          setIASSpeedDeparture(acftCategory[designDataRes.data.acft_cat].max);
          setIASSpeedOMNI(acftCategory[designDataRes.data.acft_cat].max);
        }
      }
    }
  }, [designData]);

  // design leg data by its id
  useEffect(() => {
    if (collectSurveyObstaclesdata) {
      const collectSurveyObstaclesdataRes: any = collectSurveyObstaclesdata;
      if (collectSurveyObstaclesdataRes.status) {
        setSurveyObstacleData(
          !isEmpty(collectSurveyObstaclesdataRes.data) ? collectSurveyObstaclesdataRes.data : []
        );
      }
    }
  }, [collectSurveyObstaclesdata]);

  // survey obstacles data handling
  useEffect(() => {
    if (selectedLegData) {
      const selectedLegDataRes: any = selectedLegData;
      if (selectedLegDataRes.status) {
        if (selectedProcedureData && selectedProcedureData.procedure === 'DEPARTURE') {
          setActiveLegDesignDataDeparture(
            !isEmpty(selectedLegDataRes.data) ? selectedLegDataRes.data : null
          );
        }
        if (selectedProcedureData && selectedProcedureData.procedure === 'OMNI') {
          setActiveLegDesignDataOMNI(
            !isEmpty(selectedLegDataRes.data) ? selectedLegDataRes.data : null
          );
        }
        setRemarksDepOmni(
          !isEmpty(selectedLegDataRes.data) ? selectedLegDataRes.data.remark : undefined
        );
        setLegWindspiralsData(
          !isEmpty(selectedLegDataRes.data) ? selectedLegDataRes.data.windspirals : null
        );
      }
    }
  }, [selectedLegData]);

  // DER coords Departure
  useEffect(() => {
    const coords: any = [
      !isEmpty(activeLegDesignDataDeparture)
        ? activeLegDesignDataDeparture.start_waypoint.lat
        : isEmpty(activeLegDesignDataDeparture) && !isEmpty(changeCWYPointInfoDataDeparture)
        ? changeCWYPointInfoDataDeparture.B[0]
        : initStartInfoDeparture
        ? initStartInfoDeparture.der[0]
        : null,

      !isEmpty(activeLegDesignDataDeparture)
        ? activeLegDesignDataDeparture.start_waypoint.lng
        : isEmpty(activeLegDesignDataDeparture) && !isEmpty(changeCWYPointInfoDataDeparture)
        ? changeCWYPointInfoDataDeparture.B[1]
        : initStartInfoDeparture
        ? initStartInfoDeparture.der[1]
        : null
    ];
    setDERCoordsDeparture(filter(coords, (el: any) => !isNull(el)));
  }, [activeLegDesignDataDeparture, changeCWYPointInfoDataDeparture, initStartInfoDeparture]);

  // DER coords OMNI
  useEffect(() => {
    const coords: any = [
      !isEmpty(activeLegDesignDataOMNI)
        ? activeLegDesignDataOMNI.start_waypoint.lat
        : isEmpty(activeLegDesignDataOMNI) && !isEmpty(changeCWYPointInfoDataOMNI)
        ? changeCWYPointInfoDataOMNI.B[0]
        : initStartInfoOMNI
        ? initStartInfoOMNI.der[0]
        : null,

      !isEmpty(activeLegDesignDataOMNI)
        ? activeLegDesignDataOMNI.start_waypoint.lng
        : isEmpty(activeLegDesignDataOMNI) && !isEmpty(changeCWYPointInfoDataOMNI)
        ? changeCWYPointInfoDataOMNI.B[1]
        : initStartInfoOMNI
        ? initStartInfoOMNI.der[1]
        : null
    ];
    setDERCoordsOMNI(filter(coords, (el: any) => !isNull(el)));
  }, [activeLegDesignDataOMNI, changeCWYPointInfoDataOMNI, initStartInfoOMNI]);

  const handleMapCenter = (data: any) => {
    setMapCenter(data);
    setMapZoom(11);
  };

  // get design legs handling
  useEffect(() => {
    if (designlegsData) {
      const designlegsDataRes: any = designlegsData;
      if (designlegsDataRes.status) {
        setShowHideDeleteLegModal(false);
        setDeleteLegKey(null);
        setEndSelectNavaidWaypoint('');
        setEndWaypointLat(null);
        setEndWaypointLng(null);
        if (isEmpty(designlegsDataRes.data)) {
          handleRefrencelineApiCall(1);
          setActiveLegDesignDataDeparture(null);
          setActiveLegDesignDataOMNI(null);
          if (selectedProcedureData && selectedProcedureData.procedure === 'OMNI') {
            handleClearAllFieldsDataOMNI();
          } else if (selectedProcedureData && selectedProcedureData.procedure === 'DEPARTURE') {
            handleClearAllFieldsDataDeparture();
          }
          // eslint-disable-next-line
          handleOnClickMapElement('splaycircles');
        }
        if (
          !isEmpty(designlegsDataRes.data) &&
          selectedProcedureData &&
          selectedProcedureData.procedure === 'OMNI'
        ) {
          dispatch(
            setSelectDesignLegDataOMNI(
              !isEmpty(designlegsDataRes.data) ? designlegsDataRes.data : null
            )
          );
          setActiveLegDesignDataDeparture(null);
          setActiveLegDesignDataOMNI(
            !isEmpty(designlegsDataRes.data) ? designlegsDataRes.data?.Leg1 : null
          );
          dispatch(setSelectDesignLegDataDeparture(null));
          if (isEmpty(designlegsDataRes.data)) return;

          if (!isEmpty(designlegsDataRes.data.Leg1)) {
            setActiveLegID(designlegsDataRes.data.Leg1.id || null);
            setEndAltitudeOMNI(designlegsDataRes.data.Leg1.end_waypoint.alt);
            setRemarksDepOmni(designlegsDataRes.data.Leg1.remark || undefined);
          }
        } else if (
          !isEmpty(designlegsDataRes.data) &&
          selectedProcedureData &&
          selectedProcedureData.procedure === 'DEPARTURE'
        ) {
          setActiveLegDesignDataOMNI(null);
          dispatch(setSelectDesignLegDataOMNI(null));
          dispatch(
            setSelectDesignLegDataDeparture(
              !isEmpty(designlegsDataRes.data) ? designlegsDataRes.data : null
            )
          );
          if (isEmpty(designlegsDataRes.data)) return;
          if (activeLeg) {
            setActiveLegDesignDataDeparture(
              !isEmpty(designlegsDataRes.data) ? designlegsDataRes.data[`Leg${activeLeg}`] : null
            );

            if (Number(activeLeg) > 1) {
              const prevKey = (Number(activeLeg) - 1).toString();
              setActivePrevLegDesignDataDeparture(
                !isEmpty(designlegsDataRes.data) ? designlegsDataRes.data[`Leg${prevKey}`] : null
              );
              setSelectedPathTerminatorDeparture(
                designlegsDataRes.data[`Leg${activeLeg}`]?.parameters?.type
              );
            }
            if (!isEmpty(designlegsDataRes.data[`Leg${activeLeg}`])) {
              setRemarksDepOmni(designlegsDataRes.data[`Leg${activeLeg}`].remark || undefined);
              handleMapCenter([
                designlegsDataRes.data[`Leg${activeLeg}`].end_waypoint.lat,
                designlegsDataRes.data[`Leg${activeLeg}`].end_waypoint.lng
              ]);
              setActiveLegID(designlegsDataRes.data[`Leg${activeLeg}`].id || null);
              setSelectedPathTerminatorDeparture(
                designlegsDataRes.data[`Leg${activeLeg}`]?.parameters?.type
              );
              setCourseDeparture(designlegsDataRes.data[`Leg${activeLeg}`].parameters.bearing);
              setEndAltitudeDeparture(designlegsDataRes.data[`Leg${activeLeg}`].end_waypoint.alt);
              setPDGDeparture(designlegsDataRes.data[`Leg${activeLeg}`].parameters.pdg);
              setAreasemiwidthDeparture(
                designlegsDataRes.data[`Leg${activeLeg}`].end_waypoint.aswdetails
              );

              setactiveFlyBtn(designlegsDataRes.data[`Leg${activeLeg}`].end_waypoint.waypoint_mode);
              setGradLengthDeparture(
                designlegsDataRes.data[`Leg${activeLeg}`].parameters.dst_btw_wpt
              );
              const endHeight =
                Number(designlegsDataRes.data[`Leg${activeLeg}`].end_waypoint.alt) -
                Number(designlegsDataRes.data[`Leg${activeLeg}`].start_waypoint.alt);
              setGradHeightDeparture(endHeight);
              setTurnDirectionDeparture(
                designlegsDataRes.data[`Leg${activeLeg}`].parameters.turn_direction
              );
              setTurnAngleDeparture(
                designlegsDataRes.data[`Leg${activeLeg}`].parameters.turn_angle
              );
              setIASSpeedDeparture(designlegsDataRes.data[`Leg${activeLeg}`].parameters.ias);
              setTasSpeedDeparture(designlegsDataRes.data[`Leg${activeLeg}`].parameters.tas);
              setCWYDisDeparture(designlegsDataRes.data[`Leg${activeLeg}`].parameters.cwy);
              if (Number(activeLeg) > 1) {
                setLegWindspiralsData(
                  designlegsDataRes.data[`Leg${activeLeg}`].windspirals || null
                );
                if (designlegsDataRes.data[`Leg${activeLeg}`]?.parameters?.type === 'CF') {
                  setCFCourseDept(designlegsDataRes.data[`Leg${activeLeg}`].parameters.bearing);
                }
                if (designlegsDataRes.data[`Leg${activeLeg}`]?.parameters?.type === 'RF') {
                  setRadiusOfTurn(designlegsDataRes.data[`Leg${activeLeg}`].parameters.radius);
                }
              }
            }
          }
        }
      }
    }
  }, [designlegsData, isLoadingLegsData]);

  useEffect(() => {
    if (selectedLegObstactsData) {
      const legObstactDataRes: any = selectedLegObstactsData;
      if (legObstactDataRes.status) {
        setGeneratedOA(true);
        if (selectedProcedureData && selectedProcedureData.procedure === 'OMNI') {
          setActiveOALegDesignDataOMNI(legObstactDataRes.data.analysed_obstacles || []);
          setActiveTerrainLegDesignDataOMNI(legObstactDataRes.data.analysed_terrain || []);
        } else if (selectedProcedureData && selectedProcedureData.procedure === 'DEPARTURE') {
          setActiveOALegDesignDataDeparture(legObstactDataRes.data.analysed_obstacles || []);
          setActiveTerrainLegDesignDataDeparture(legObstactDataRes.data.analysed_terrain || []);
        }
      } else {
        setGeneratedOA(false);
      }
    }
  }, [selectedLegObstactsData, isFetchingOAData, isLoadingOAData]);

  // get design metadata handling
  useEffect(() => {
    if (designAllMetadata) {
      const designAllMetadataRes: any = designAllMetadata;
      if (designAllMetadataRes.status) {
        dispatch(setDesignAllMetaData(designAllMetadataRes.data || []));
        if (!isEmpty(designAllMetadataRes.data)) {
          const data: MetaDataType[] | [] = orderBy(
            designAllMetadataRes.data,
            (o: MetaDataType) => {
              return moment(o.created_at);
            },
            ['desc']
          );
          if (isEmpty(data)) return;
          setSelectedMetaId(data[0].id || null);
          dispatch(
            silentDesignMetadataByMetaidCall(
              `/organisations/${userSession?.organisationid}/designs/${Number(designid)}/metadata/${
                data[0].id
              }`,
              (selectedDesignMetadata: any) => {
                setSelectedDesignMetadata(selectedDesignMetadata || null);
              }
            )
          );
          getSurveyObstacles({
            metaId: data[0].id
          });
          getDesignProcedureList({
            orgId: userSession?.organisationid,
            designid: Number(designid),
            metaId: data[0].id
          });
        }
      }
    }
  }, [designAllMetadata]);

  useEffect(() => {
    if (selectedDesignMetadata) {
      const selectedDesignMetadataRes: any = selectedDesignMetadata;
      if (selectedDesignMetadataRes.status) {
        if (isEmpty(selectedDesignMetadataRes.data)) return;
        const adDatakeys = keys(selectedDesignMetadataRes.data?.ad_json);
        const airspaceDatakeys = keys(selectedDesignMetadataRes.data?.airspace_geometry);
        dispatch(setADData(uniq([...adDatakeys])));
        dispatch(setAvailableADData(selectedDesignMetadataRes.data?.ad_json));
        dispatch(setAirspaceData(uniq([...airspaceDatakeys])));
        dispatch(setAvailableAirspaceData(selectedDesignMetadataRes.data?.airspace_geometry));
        setTemperature(selectedDesignMetadataRes.data?.tempreature || null);
        setMagneticDeclination(selectedDesignMetadataRes.data?.magnetic_declination || null);
        setTerrainLayerName(selectedDesignMetadataRes.data?.terrain_layer_name || null);
      }
    }
  }, [selectedDesignMetadata]);

  // SID list handling
  useEffect(() => {
    if (selectedDesignProcedureList) {
      const selectedDesignProcedureListRes: any = selectedDesignProcedureList;
      if (selectedDesignProcedureListRes.status) {
        if (isEmpty(selectedDesignProcedureListRes.data)) return;

        dispatch(
          setDesignProcedurelist(
            orderBy(
              selectedDesignProcedureListRes.data,
              (o: ProcedureDataType) => {
                return moment(o.created_at);
              },
              ['desc']
            ) || []
          )
        );
        if (selectedProcedureId) {
          const data: ProcedureDataType | undefined = find(selectedDesignProcedureListRes.data, [
            'id',
            selectedProcedureId
          ]);

          setSelectedProcedureData(data || null);
        }
      }
    }
  }, [selectedDesignProcedureList, isLoadingProcedureList]);

  // get init start info api handling
  useEffect(() => {
    if (initStartInfoData) {
      const initStartInfoDataRes: any = initStartInfoData;
      if (initStartInfoDataRes.status) {
        setARP(!isEmpty(initStartInfoDataRes.data) ? initStartInfoDataRes.data.arp : null);
        setInitInfoBearing(
          !isEmpty(initStartInfoDataRes.data) ? Number(initStartInfoDataRes.data.bearing) : null
        );
        setDesignClearWay(
          !isEmpty(initStartInfoDataRes.data) ? Number(initStartInfoDataRes.data.cwy) : null
        );
        setInitInfoDesignData(
          !isEmpty(initStartInfoDataRes.data) ? initStartInfoDataRes.data : null
        );
        if (selectedProcedureData && selectedProcedureData.procedure === 'OMNI') {
          setInitStartInfoDataOMNI(
            !isEmpty(initStartInfoDataRes.data) ? initStartInfoDataRes.data : null
          );
          setCWYDisOMNI(!isEmpty(initStartInfoDataRes.data) ? initStartInfoDataRes.data.cwy : null);
        } else if (selectedProcedureData && selectedProcedureData.procedure === 'DEPARTURE') {
          setInitStartInfoDataDeparture(
            !isEmpty(initStartInfoDataRes.data) ? initStartInfoDataRes.data : null
          );
          setCourseDeparture(
            !isEmpty(initStartInfoDataRes.data) ? initStartInfoDataRes.data.bearing : null
          );
          setCWYDisDeparture(
            !isEmpty(initStartInfoDataRes.data) ? initStartInfoDataRes.data.cwy : null
          );
        }
      }
    }
  }, [initStartInfoData, selectedProcedureData, isLoadingInitStartInfo]);

  // get waypoint api handling
  useEffect(() => {
    if (waypointsData) {
      const waypointsDataRes: any = waypointsData;
      if (waypointsDataRes.status) {
        dispatch(
          setSelectWaypointData(!isEmpty(waypointsDataRes.data) ? waypointsDataRes.data : [])
        );
      }
    }
  }, [waypointsData]);

  const handleChangeLengthSetInitInfoDataOMNI = (
    changeLengthResdata: InfobtwpointsResponseType | null
  ) => {
    setEndPointLatlngOMNI([]);
    setChangeLengthPointInfoDataOMNI(changeLengthResdata);
  };

  // get final InfobtwPoints Data api handling
  useEffect(() => {
    if (finalInfobtwPointsData) {
      const finalInfobtwPointsDataRes: any = finalInfobtwPointsData;
      if (finalInfobtwPointsDataRes.status) {
        if (!isEmpty(finalInfobtwPointsDataRes.data)) {
          if (selectedProcedureData && selectedProcedureData.procedure === 'OMNI') {
            handleChangeLengthSetInitInfoDataOMNI(finalInfobtwPointsDataRes.data || null);
          } else if (selectedProcedureData && selectedProcedureData.procedure === 'DEPARTURE') {
            const endLength = !isEmpty(finalInfobtwPointsDataRes.data)
              ? finalInfobtwPointsDataRes.data.distance
              : null;

            const changeStartCwyInfoBearing = changeCWYPointInfoDataDeparture
              ? Number(changeCWYPointInfoDataDeparture?.bearing)
              : initStartInfoDeparture
              ? Number(initStartInfoDeparture?.bearing)
              : 0;

            let turnangle =
              changeStartCwyInfoBearing - Number(finalInfobtwPointsDataRes.data.bearing);
            let turndirection =
              Number(turnangle.toFixed(3)) === 0
                ? 'on-set'
                : Number(turnangle.toFixed(3)) > 0
                ? 'left'
                : 'right';
            const course = !isEmpty(finalInfobtwPointsDataRes.data)
              ? Number(Number(finalInfobtwPointsDataRes.data.bearing).toFixed(2))
              : changeCWYPointInfoDataDeparture
              ? Number(changeCWYPointInfoDataDeparture?.bearing)
              : initStartInfoDeparture
              ? Number(initStartInfoDeparture?.bearing)
              : null;
            if (Number(activeLeg) === 1) {
              const turnAngleDir = getTurnAndleDataHandling(
                Math.abs(turnangle),
                selectedPathTerminatorDeparture!,
                turndirection,
                Number(activeLeg)
              );

              if (turnAngleDir) {
                if (turnAngleDir.isError) {
                  dispatch(setWarningAlert(turnAngleDir?.message));
                } else {
                  turnangle = turnAngleDir.angle;
                  turndirection = turnAngleDir.direction;

                  // if (selectedPathTerminatorDeparture === 'CF') {
                  //   if (Math.abs(turnangle) > 0) {
                  //     dispatch(setWarningAlert('Select point near by reference line.'));
                  //     return;
                  //   }
                  //   turnangle = 0;
                  //   turndirection = 'on-set';
                  // }
                  // const urlString = `/areasemiwidth?designId=${selectedDesign?.id}&nav=${selectedDesign?.nav_type}&procedure=${selectedDesign?.procedure_type}&lat=${finalInfobtwPointsDataRes.data.B[0]}&lng=${finalInfobtwPointsDataRes.data.B[1]}`;
                  const urlString = `/design/${
                    selectedDesign?.id
                  }/procedure/${selectedProcedureVersionId}/leg/${Number(
                    activeLeg
                  )}/area-semi-width?lat=${finalInfobtwPointsDataRes.data.B[0]}&lng=${
                    finalInfobtwPointsDataRes.data.B[1]
                  }`;
                  getAreasemiwidth(urlString);
                  setCourseDeparture(course);
                  setGradLengthDeparture(endLength);
                  setTurnAngleDeparture(Math.abs(turnangle));
                  setTurnDirectionDeparture(turndirection);
                  setEndWaypointLatLngDeparture(finalInfobtwPointsDataRes.data.B || []);
                  if (selectedPathTerminatorDeparture !== 'CA') {
                    const endHeight = getEndPointHeight(
                      Number(endLength),
                      Number(pdgDeparture || 3.3)
                    );
                    setGradHeightDeparture(Number(endHeight));
                    const startAlt = initStartInfoDeparture ? initStartInfoDeparture.der_elev : 0;
                    setEndAltitudeDeparture(Number(startAlt) + Number(endHeight));

                    // const pdg = getEndPointPDG(endHeight, endLength);
                    // setPDGDeparture(pdg);
                  } else if (selectedPathTerminatorDeparture === 'CA') {
                    // const endHeight = getEndPointHeight(Number(endLength), Number(pdgDeparture || 3.3));

                    const startAlt = initStartInfoDeparture ? initStartInfoDeparture.der_elev : 0;
                    setGradHeightDeparture(Number(endAltitudeDeparture!) - Number(startAlt));
                    // const pdg = getEndPointPDG(
                    //   Number(endAltitudeDeparture!) - Number(startAlt),
                    //   endLength
                    // );
                    // setPDGDeparture(pdg);
                  }
                }
              }
            } else if (Number(activeLeg) > 1) {
              let turnangle1 =
                activePrevLegDesignDataDeparture.parameters.bearing -
                Number(finalInfobtwPointsDataRes.data.bearing);
              let turndirection1 =
                Number(turnangle1.toFixed(3)) === 0
                  ? 'on-set'
                  : Number(turnangle1.toFixed(3)) > 0
                  ? 'left'
                  : 'right';

              const turnAngleDir = getTurnAndleDataHandling(
                Math.abs(turnangle1),
                selectedPathTerminatorDeparture!,
                turndirection1,
                Number(activeLeg)
              );

              if (turnAngleDir) {
                if (turnAngleDir.isError) {
                  dispatch(setWarningAlert(turnAngleDir?.message));
                } else {
                  turnangle1 = turnAngleDir.angle;
                  turndirection1 = turnAngleDir.direction;
                  setNewLegDERCoordsDeparture([
                    activePrevLegDesignDataDeparture.end_waypoint.lat,
                    activePrevLegDesignDataDeparture.end_waypoint.lng
                  ]);
                  handleSetValueGreater1Leg(
                    [
                      activePrevLegDesignDataDeparture.start_waypoint.lat,
                      activePrevLegDesignDataDeparture.start_waypoint.lng
                    ],
                    [
                      activePrevLegDesignDataDeparture.end_waypoint.lat,
                      activePrevLegDesignDataDeparture.end_waypoint.lng
                    ],
                    activePrevLegDesignDataDeparture.end_waypoint.alt,
                    turnangle1,
                    turndirection1,
                    finalInfobtwPointsDataRes.data.B,
                    endLength,
                    selectedPathTerminatorDeparture === 'CF'
                      ? activePrevLegDesignDataDeparture.parameters.bearing
                      : course
                  );
                }
              }
            }
          }
        }
      }
    }
  }, [finalInfobtwPointsData, isLoadingInfoBtwPoints]);

  const handleLoading = (load: boolean) => {
    setLoading(load);
  };

  useEffect(() => {
    if (areasemiwidthData) {
      const areasemiwidthDataRes: any = areasemiwidthData;
      if (areasemiwidthDataRes.status) {
        setAreasemiwidthDeparture(
          !isEmpty(areasemiwidthDataRes.data) ? areasemiwidthDataRes.data : null
        );
      }
    }
  }, [areasemiwidthData]);

  // get designversions data api handling
  useEffect(() => {
    if (designProcedureVersnData) {
      const designProcedureVersnDataRes: any = designProcedureVersnData;
      if (designProcedureVersnDataRes.status) {
        dispatch(setDesignProcedureVersionsData(designProcedureVersnDataRes.data || []));
        const data: DesignVersionsDataType[] | [] = orderBy(
          designProcedureVersnDataRes.data,
          (o: DesignVersionsDataType) => {
            return moment(o.created_at);
          },
          ['desc']
        );
        if (!isEmpty(data)) {
          setSelectedProcedureVersionId(data[0].id || null);
          setTerminatingWaypoint(Boolean(data[0].is_terminal));
        }
      }
    }
  }, [designProcedureVersnData]);

  const handleInfobtwPointsByLatLng = ({ latB, lngB }: { latB: number; lngB: number }) => {
    if (activePrevLegDesignDataDeparture) {
      const latA = activePrevLegDesignDataDeparture.end_waypoint.lat;
      const lngA = activePrevLegDesignDataDeparture.end_waypoint.lng;

      const urlString = `/infobtwpoints?lat_a=${latA}&lng_a=${lngA}&lat_b=${latB}&lng_b=${lngB}`;

      getDesignInfobtwPointsdata({ urlString });
    } else if (activeLeg === '1' && selectedPathTerminatorDeparture === 'CF') {
      const latA = initStartInfoDeparture?.thr_point[0];
      const lngA = initStartInfoDeparture?.thr_point[1];
      const bearingdata = Number(initStartInfoDeparture?.bearing);
      const urlString = `/infobtwpoints?lat_a=${latA}&lng_a=${lngA}&bearing=${bearingdata}&lat_b=${latB}&lng_b=${lngB}`;
      getDesignInfobtwPointsdata({ urlString });
    }
  };

  const handleFullScreenMapView = (isFullMap: boolean) => {
    setFullScreenMapView(isFullMap);
    setMapZoom(8);
  };

  const handleBackToDesign = async () => {
    await setSelectDesignLegData(null);
    await setSelectDesignLegDataDeparture(null);
    await setSelectDesignLegDataOMNI(null);
    await setChangeCWYPointInfoDataOMNI(null);
    await setChangeLengthPointInfoDataOMNI(null);
    await setActiveOALegDesignDataDeparture([]);
    await setSelectWaypointData([]);
    setEndAltitudeDeparture(null);
    setSelectedWayPointMarker(null);
    setTurnAngleDeparture(null);
    setTurnDirectionDeparture(null);
    setAreasemiwidthDeparture(null);
    setPDGDeparture(3.3);
    setGradLengthDeparture(null);
    setGradHeightDeparture(null);
    setEndAltGradLengthOMNI(null);
    setEndSelectNavaidWaypoint('');
    setEndPointLatlngOMNI([]);
    setActiveLegID(null);
    setEndAltitudeOMNI(null);
    setActiveLegDesignDataOMNI(null);
    setActiveLegDesignDataDeparture(null);
    setStartDesign(false);
    setOATableDepartureShow(false);
    setShowHideOADataDeparture(false);
    setOATableOmniShow(false);
    setShowHideOADataOMNI(false);
    setActiveDesignStep(0);
    navigate(location.pathname, { state: null });
    window.location.reload();
  };

  const handleClearDataDeparture = () => {
    setEndAltitudeDeparture(null);
    setPDGDeparture(3.3);
    setEndSelectNavaidWaypoint('');
    setTurnDirectionDeparture(null);
    setTurnAngleDeparture(null);
    setAreasemiwidthDeparture(null);
    setEndWaypointLatLngDeparture([]);
    setGradLengthDeparture(null);
    setGradHeightDeparture(null);
  };

  const handleProtectionAreaCheckList = (pacheckList: string[] | []) => {
    setProtectionAreaCheckList(pacheckList);
  };

  const handleMapType = (type: string) => {
    setMapType(type);
  };

  const handleChangeCWYdisInputDeparture = async (CWYdis: number | null) => {
    setCWYDisDeparture(CWYdis);
    handleRefrencelineApiCall(1);
    handleClearDataDeparture();
  };

  const handleOnStartDesign = async (isStart: boolean) => {
    if (!selectedProcedureVersionId && !selectedProcedureId) {
      dispatch(enqueueError('Select design first!'));
      return;
    }

    await getDesignLegsdata({
      orgId: userSession?.organisationid,
      designId: Number(designid),
      metaId: selectedMetaId,
      procedureId: selectedProcedureId,
      designversionId: selectedProcedureVersionId
    });

    handleGetPathTerminatorData(
      Number(designid),
      selectedProcedureId!,
      selectedProcedureVersionId!
    );
    setStartDesign(isStart);
  };

  const handleChangeOmniEndPDG = (pdg: number) => {
    setPDGOMNI(pdg);
  };
  const handleChangeOmniEndAltGrad = (endAlt: number | undefined) => {
    setEndAltitudeOMNI(endAlt || null);
  };
  const handleChangeOmniEndLength = (length: number | undefined) => {
    setEndAltGradLengthOMNI(length || null);
  };

  const handleEndDisableFieldOMNI = (key: string) => {
    setDisableEndPointFieldOMNI(key);
    setEndAltGradLengthOMNI(null);
    setEndAltitudeOMNI(null);
    setPDGOMNI(3.3);
  };

  const handleShowAeroTable = (isShow: boolean) => {
    setShowAeroTable(isShow);
  };

  const handleSelectedProcedureId = async (id: number) => {
    setSelectedProcedureId(id);
    if (id) {
      const data: ProcedureDataType | undefined = find(designProcedurelist, ['id', id]);
      setSelectedProcedureData(data || null);
      await getDesignProcedureVersions({
        orgId: userSession?.organisationid,
        designid: Number(designid),
        metaId: selectedMetaId,
        procedureId: id
      });
    }
  };

  const handleShowHideDataLayer = (activedatalayer: string | null) => {
    setShowDataLayerId(activedatalayer);
    if (showDataLayerId === activedatalayer) {
      setShowDataLayerId(null);
      handleShowAeroTable(false);
    } else {
      setShowDataLayerId(activedatalayer);
      handleShowAeroTable(true);
    }
  };

  const handleShowObstacleTableDeparture = (isShow: boolean) => {
    setShowHideOADataDeparture(isShow);
    setShowHideTerrainLayerDeparture(false);
    setOATableDepartureShow(isShow);
    setShowDataLayerId(null);
    setActiveObscleTerrainDataKey('obstacle');
    if (!isShow) {
      setShowClosingObstacle(false);
    }
    if (isShow && !isGeneratedOA) {
      getLegObstacleAnalysisdata({
        orgid: Number(userSession?.organisationid),
        designid: Number(designid),
        metaId: Number(selectedMetaId),
        procedureId: Number(selectedProcedureId)!,
        designversionId: Number(selectedProcedureVersionId),
        legid: Number(activeLegDesignDataDeparture?.id)
      });
    }
  };

  const handleShowHideObstacleTableDeparture = (isShow: boolean) => {
    setOATableDepartureShow(isShow);
    setShowHideOADataDeparture(isShow);
  };

  /* Mark tools Start */
  const handleOnClickMapTools = (tool: string | null) => {
    if (activeMapTools === tool) {
      setactiveMapTools(null);
      if (tool === 'ruler') {
        const el: HTMLElement = document.getElementsByClassName('icon-ruler')[0] as HTMLElement;
        el.click();
      }
    } else {
      setactiveMapTools(tool);

      if (tool === 'line') {
        setTimeout(
          // eslint-disable-next-line
          () => drawRef?.current?._toolbars?.draw._modes.polyline.handler.enable()
        );
      } else if (tool === 'point') {
        setTimeout(
          // eslint-disable-next-line
          () => drawRef?.current?._toolbars?.draw._modes.marker.handler.enable()
        );
      } else if (tool === 'ruler') {
        const el: HTMLElement = document.getElementsByClassName('icon-ruler')[0] as HTMLElement;
        el.click();
      }
    }
  };

  /* Map elements click event */
  const handleOnClickMapElement = (element: string) => {
    if (includes(activeMapElement, element)) {
      remove(activeMapElement, (selectedElement: string) => selectedElement === element);
    } else {
      activeMapElement.push(element);
    }
    if (includes(activeMapElement, 'waypoints') && selectedDesign && selectedDesign.airport_id) {
      setShowWayPointOnMapDeparture(true);
      getDesignWaypointdata(selectedDesign?.airport_id);
    }

    setActiveMapElement([...activeMapElement]);
  };

  /* Draw Point */
  const handleOnCreatePoint = (e: any) => {
    const geoJOSN = e.layer.toGeoJSON();
    const coord = geoJOSN.geometry.coordinates;

    setPointLatLng([coord[1], coord[0]]);
    setactiveMapTools(null);
  };

  /* Draw Polyline */
  const handleOnCreatePolyline = (e: any) => {
    const geoJOSN = e.layer.toGeoJSON();
    const lineCoords: any = geoJOSN.geometry.coordinates;
    // @ts-ignore
    const length = L.GeometryUtil.readableDistance(e.layer.getLatLngs()[0]); // eslint-disable-line

    const centeroid = e.target.getCenter();
    setCentroidPoint(centeroid);
    setNewPolyLineCoords(lineCoords);
    setactiveMapTools(null);
  };

  const handleOnMounted = (ctl: any) => {
    drawRef.current = ctl;
  };

  const handleOnCreateShape = (e: any) => {
    if (e.layerType === DrawType.Polyline) {
      handleOnCreatePolyline(e);
    } else if (e.layerType === DrawType.Point) {
      handleOnCreatePoint(e);
    }
  };
  /* Mark tools End */

  const handleChangeWaypoint = (selectedWaypoint: string) => {
    if (selectedWaypoint !== endSelectNavaidWaypoint) {
      setShowWayPointOnMapDeparture(false);
      setViewOnMapEnterWaypoint(false);
      setEndSelectNavaidWaypoint(selectedWaypoint);
      setCourseDeparture(
        changeCWYPointInfoDataDeparture
          ? Number(changeCWYPointInfoDataDeparture.bearing)
          : initStartInfoDeparture
          ? Number(initStartInfoDeparture.bearing)
          : null
      );
      setAreasemiwidthDeparture(null);
      setEndWaypointLatLngDeparture([]);
      setGradLengthDeparture(null);
      setGradHeightDeparture(null);
      setTurnAngleDeparture(null);
      setTurnDirectionDeparture(null);
      setEndWaypointLat(null);
      setEndWaypointLng(null);
      setSelectedWayPointMarker(null);
      setCourseRangeDataDept([]);
      setArcEndCoord([]);
      setArcCenterCoord([]);
      setFinalArcCords([]);
    }
  };

  const handleActiveFlyBtn = (isActiveBtn: string) => {
    setactiveFlyBtn(isActiveBtn);
  };

  const handleActiveLegModuleTabBtn = (activeBtn: string) => {
    setActiveDeptBtnDeparture(activeBtn);
  };

  const handleEndWaypointLat = (waypointlat: number | undefined) => {
    setEndWaypointLat(waypointlat || null);
    setViewOnMapEnterWaypoint(false);
  };

  const handleEndWaypointLng = (waypointlng: number | undefined) => {
    setEndWaypointLng(waypointlng || null);
    setViewOnMapEnterWaypoint(false);
  };

  const handleCreateWaypointLatLng = async (latlng: any) => {
    if (!isEmpty(activeLegDesignDataDeparture)) return;
    if (!isEmpty(latlng)) {
      if (selectedPathTerminatorDeparture === 'RF') {
        const payload = drawArcBetweenPoints(latlng, activePrevLegDesignDataDeparture);
        if (payload) {
          setArcEndCoord(payload.endPointCoord);
          setArcCenterCoord(payload.centerPointCoord);
          setFinalArcCords(payload.arcCoords);
          setEndWaypointLatLngDeparture(payload.endPointCoord);
          setEndAltitudeDeparture(payload.endAltitude || null);
          setGradLengthDeparture(payload.arclength || null);
          setGradHeightDeparture(payload.endHeight || null);
          setTurnAngleDeparture(payload.turnAngle || null);
          setTurnDirectionDeparture(payload.turnDirection || null);
          setRadiusOfTurn(payload.radiusOfTurn || null);
          setCourseDeparture(payload.outBoundtrack || null);
        }
      } else {
        const snapPointData = await nearestSnapPointOnLine(latlng, refrenceLineData);

        if (snapPointData) {
          if (snapPointData.length > 150) {
            handleInfobtwPointsByLatLng({
              latB: latlng[0],
              lngB: latlng[1]
            });
          } else {
            handleInfobtwPointsByLatLng({
              latB: snapPointData.coordinates[1],
              lngB: snapPointData.coordinates[0]
            });
          }
        }
      }
    }
  };

  const handleCallAreaSemiwidth = async (latlng: any) => {
    if (latlng) {
      const urlString = `/design/${
        selectedDesign?.id
      }/procedure/${selectedProcedureVersionId}/leg/${Number(activeLeg)}/area-semi-width?lat=${
        latlng[0]
      }&lng=${latlng[1]}`;
      await getAreasemiwidth(urlString);
    }
  };

  const handleEnterLatLngViewOnMap = async (isView: boolean) => {
    if (endWaypointLat && endWaypointLng) {
      setViewOnMapEnterWaypoint(isView);
      if (selectedPathTerminatorDeparture === 'RF') {
        handleCreateWaypointLatLng([endWaypointLat, endWaypointLng]);
        handleCallAreaSemiwidth([endWaypointLat, endWaypointLng]);
      } else {
        handleInfobtwPointsByLatLng({ latB: endWaypointLat, lngB: endWaypointLng });
      }
    } else {
      dispatch(enqueueError('Enter Lattitude and Longitude.'));
    }
  };

  const handleCodingTable = (activeTable: boolean) => {
    setActiveCodingTable(activeTable);
    setShowDataLayerId(null);
    if (activeTable) {
      if (isactiveCodingTable) return;
      getCodingTableData({
        orgId: Number(userSession?.organisationid),
        designId: Number(designid),
        metaId: Number(selectedMetaId),
        procedureId: Number(selectedProcedureId)!,
        designversionId: Number(selectedProcedureVersionId)
      });
    }
  };

  const handleGeneratePADataDeparture = async () => {
    const startAlt = initStartInfoDeparture ? initStartInfoDeparture?.der_elev : null;

    const startLat = changeCWYPointInfoDataDeparture
      ? changeCWYPointInfoDataDeparture.B[0]
      : initStartInfoDeparture
      ? initStartInfoDeparture.der[0]
      : null;

    const startLng = changeCWYPointInfoDataDeparture
      ? changeCWYPointInfoDataDeparture.B[1]
      : initStartInfoDeparture
      ? initStartInfoDeparture.der[1]
      : null;

    const endLat = endWaypointLatLngDeparture ? endWaypointLatLngDeparture[0] : null;

    const endLng = endWaypointLatLngDeparture ? endWaypointLatLngDeparture[1] : null;

    const isDataRight1 = Boolean(
      activeLeg &&
        selectedPathTerminatorDeparture &&
        startAlt &&
        startLat &&
        startLng &&
        endAltitudeDeparture &&
        endWaypointLatLngDeparture &&
        activeFlyBtn &&
        endLat &&
        endLng &&
        areasemiwidthDeparture &&
        // cwyDisDeparture &&
        designClearWay &&
        turnDirectionDeparture &&
        gradLengthDeparture &&
        iasSpeedDeparture &&
        pdgDeparture &&
        gradHeightDeparture &&
        gradHeightDeparture > 399
    );

    const isDataRight2 = Boolean(
      activeLeg &&
        selectedPathTerminatorDeparture &&
        startAlt &&
        startLat &&
        startLng &&
        endAltitudeDeparture &&
        endWaypointLatLngDeparture &&
        activeFlyBtn &&
        areasemiwidthDeparture &&
        turnDirectionDeparture &&
        gradLengthDeparture &&
        iasSpeedDeparture &&
        pdgDeparture
    );

    // eslint-disable-next-line
    const payload1 = {
      legsequence: Number(activeLeg),
      type: selectedPathTerminatorDeparture,
      is_terminal: isTerminatingWaypoint,
      startDetails: {
        alt: startAlt,
        waypoint_type: null,
        waypoint_name: 'DER',
        waypoint_mode: null,
        lat: startLat,
        lng: startLng,
        aswdetails: null
      },
      endDetails: {
        alt: Number(endAltitudeDeparture),
        waypoint_type: 'new',
        waypoint_name: 'XXXXX',
        waypoint_mode: activeFlyBtn,
        lat: endLat,
        lng: endLng,
        aswdetails: areasemiwidthDeparture
      },
      parameters: {
        cwy: Number(designClearWay),
        // cwy: Number(cwyDisDeparture),
        turn_angle: turnAngleDeparture || 0,
        turn_direction: turnDirectionDeparture,
        twc: 30,
        bank_angle: 15,
        dst_btw_wpt: Number(gradLengthDeparture),
        ias: Number(iasSpeedDeparture),
        pdg: Number(pdgDeparture)
      }
    };

    let startLatCord = activePrevLegDesignDataDeparture?.end_waypoint?.lat;
    let startLngCord = activePrevLegDesignDataDeparture?.end_waypoint?.lng;
    let isCfPath = true;
    if (
      selectedPathTerminatorDeparture === 'CF' &&
      Number(activeLeg) > 1 &&
      turnAngleDeparture !== 0 &&
      !isEmpty(newLegDERCoordsDeparture)
    ) {
      isCfPath = courseRangeDataDept.some(
        (range) => Number(CFCourseDept) >= range.min && Number(CFCourseDept) <= range.max
      );
      startLatCord = newLegDERCoordsDeparture[0]; // eslint-disable-line
      startLngCord = newLegDERCoordsDeparture[1]; // eslint-disable-line
    }

    const payload2 = {
      legsequence: Number(activeLeg),
      type: selectedPathTerminatorDeparture,
      is_terminal: isTerminatingWaypoint,
      startDetails: {
        alt: activePrevLegDesignDataDeparture?.end_waypoint?.alt,
        waypoint_type: null,
        waypoint_name: activePrevLegDesignDataDeparture?.end_waypoint?.waypoint_name,
        waypoint_mode: activePrevLegDesignDataDeparture?.end_waypoint?.waypoint_mode,
        lat: startLatCord,
        lng: startLngCord,
        aswdetails: activePrevLegDesignDataDeparture?.end_waypoint?.aswdetails
      },
      endDetails: {
        alt: Number(endAltitudeDeparture),
        waypoint_type: selectedWayPointMarker ? 'old' : 'new',
        waypoint_name: selectedWayPointMarker ? selectedWayPointMarker.name : 'XXXXX',
        waypoint_mode: activeFlyBtn,
        lat: endLat,
        lng: endLng,
        aswdetails: areasemiwidthDeparture
      },
      parameters: {
        turn_angle: turnAngleDeparture,
        turn_direction: turnDirectionDeparture,
        twc: 30,
        bank_angle: getBankAngle(Number(activePrevLegDesignDataDeparture?.end_waypoint?.alt)) || 15,
        dst_btw_wpt: Number(gradLengthDeparture),
        ias: Number(iasSpeedDeparture),
        pdg: Number(pdgDeparture)
      }
    };

    const isRight = Number(activeLeg) > 1 ? isDataRight2 : isDataRight1;

    if (
      isRight &&
      userSession &&
      designid &&
      selectedMetaId &&
      selectedProcedureId &&
      endWaypointLatLngDeparture &&
      isCfPath
    ) {
      await addDesignLegdata({
        data: Number(activeLeg) > 1 ? payload2 : payload1,
        orgId: Number(userSession.organisationid!),
        designId: Number(designid),
        metaId: selectedMetaId,
        procedureId: selectedProcedureId,
        designversionId: selectedProcedureVersionId
      });
      setShowWayPointOnMapDeparture(false);
    } else {
      dispatch(enqueueError('Some fields value are missing!'));
    }
  };

  const handleGenerateOADataDeparture = async (legid: number) => {
    if (selectedProcedureData && selectedProcedureData.procedure !== 'DEPARTURE') return;
    if (isEmpty(activeLegDesignDataDeparture)) {
      dispatch(enqueueError('PA Leg not generate...'));
      return;
    }

    await addDesignObstacleAnalysisLegdata({
      data: {
        type: activeLegDesignDataDeparture?.parameters?.type
      },
      orgid: Number(userSession?.organisationid),
      designid: Number(designid),
      metaId: Number(selectedMetaId),
      procedureId: Number(selectedProcedureId)!,
      designversionId: Number(selectedProcedureVersionId),
      legid: Number(legid)
    });
  };

  const handleGetLegDatabyLegid = async (legid: number | null) => {
    if (!legid) return;
    await getDesignLegdataByLegid({
      orgId: userSession?.organisationid,
      designId: Number(designid),
      metaId: selectedMetaId,
      procedureId: selectedProcedureId!,
      designversionId: selectedProcedureVersionId,
      legId: legid
    });
    setActiveLegID(legid);
  };

  const handleRefreshGeneratedOAdata = (legid: number | null) => {
    getDesignLegdataByLegid({
      orgId: userSession?.organisationid,
      designId: Number(designid),
      metaId: selectedMetaId,
      procedureId: selectedProcedureId!,
      designversionId: selectedProcedureVersionId,
      legId: legid
    });
  };

  const handleOnChangeActiveLegIndex = async (activeLKey: string) => {
    if (Number(activeLeg) === Number(activeLKey)) return;
    setActiveLeg(activeLKey);
    setGeneratedOA(false);
    setRefrenceLineData([]);
    setCourseRangeDataDept([]);
    handleShowObstacleTableDeparture(false);
    setTerminatingWaypoint(false);
    setEndSelectNavaidWaypoint('');
    setActiveObscleTerrainDataKey('obstacle');
    // eslint-disable-next-line
    handleChangeRemarksDepOmni(undefined);
    setShowHideRemarkDepartureBtn(false);

    if (!isEmpty(legsDataDeparture) && Number(activeLKey) > 1) {
      const prevKey = (Number(activeLKey) - 1).toString();
      setActivePrevLegDesignDataDeparture(
        !isEmpty(legsDataDeparture[`Leg${prevKey}`]) ? legsDataDeparture[`Leg${prevKey}`] : null
      );
      if (!isEmpty(legsDataDeparture[`Leg${prevKey}`])) {
        handleMapCenter([
          legsDataDeparture[`Leg${prevKey}`].end_waypoint.lat,
          legsDataDeparture[`Leg${prevKey}`].end_waypoint.lng
        ]);
      }
      setActiveLegDesignDataDeparture(
        !isEmpty(legsDataDeparture[`Leg${activeLKey}`])
          ? legsDataDeparture[`Leg${activeLKey}`]
          : null
      );

      if (
        Number(activeLeg) !== Number(activeLKey) &&
        isEmpty(legsDataDeparture[`Leg${activeLKey}`])
      ) {
        handleRefrencelineApiCall(Number(activeLKey));
      }
      if (!isEmpty(legsDataDeparture[`Leg${activeLKey}`])) {
        handleGetLegDatabyLegid(
          !isEmpty(legsDataDeparture[`Leg${activeLKey}`])
            ? legsDataDeparture[`Leg${activeLKey}`]?.id
            : null
        );
        setGradLengthDeparture(legsDataDeparture[`Leg${activeLKey}`].parameters.dst_btw_wpt);
        const endHeight = getEndPointHeight(
          legsDataDeparture[`Leg${activeLKey}`].parameters.dst_btw_wpt,
          legsDataDeparture[`Leg${activeLKey}`].parameters.pdg
        );
        setGradHeightDeparture(endHeight);
        setPDGDeparture(legsDataDeparture[`Leg${activeLKey}`].parameters.pdg);
        setIASSpeedDeparture(legsDataDeparture[`Leg${activeLKey}`].parameters.ias);
        setTasSpeedDeparture(legsDataDeparture[`Leg${activeLKey}`].parameters.tas);
        setactiveFlyBtn(legsDataDeparture[`Leg${activeLKey}`]?.end_waypoint.waypoint_mode);
        setCourseDeparture(legsDataDeparture[`Leg${activeLKey}`].parameters.bearing);
        setSelectedPathTerminatorDeparture(legsDataDeparture[`Leg${activeLKey}`]?.parameters?.type);
        setActiveLegID(legsDataDeparture[`Leg${activeLKey}`]?.id || null);
        if (legsDataDeparture[`Leg${activeLKey}`]?.parameters?.type === 'CF') {
          setCFCourseDept(legsDataDeparture[`Leg${activeLKey}`].parameters.bearing);
        }
      } else {
        setGeneratedOA(false);
        setSelectedPathTerminatorDeparture(undefined);
        setEndWaypointLatLngDeparture([]);
        setAreasemiwidthDeparture(null);
      }
    } else if (Number(activeLKey) === 1) {
      setActiveLegDesignDataDeparture(
        !isEmpty(legsDataDeparture[`Leg${activeLKey}`])
          ? legsDataDeparture[`Leg${activeLKey}`]
          : null
      );
      handleMapCenter([
        legsDataDeparture[`Leg${activeLKey}`].end_waypoint.lat,
        legsDataDeparture[`Leg${activeLKey}`].end_waypoint.lng
      ]);
      handleGetLegDatabyLegid(
        !isEmpty(legsDataDeparture[`Leg${activeLKey}`])
          ? legsDataDeparture[`Leg${activeLKey}`]?.id
          : null
      );
      setactiveFlyBtn(
        !isEmpty(legsDataDeparture[`Leg${activeLKey}`])
          ? legsDataDeparture[`Leg${activeLKey}`]?.end_waypoint.waypoint_mode
          : 'by'
      );
      if (!isEmpty(legsDataDeparture[`Leg${activeLKey}`])) {
        setIASSpeedDeparture(legsDataDeparture[`Leg${activeLKey}`].parameters.ias);
        setTasSpeedDeparture(legsDataDeparture[`Leg${activeLKey}`].parameters.tas);
        setGradLengthDeparture(legsDataDeparture[`Leg${activeLKey}`].parameters.dst_btw_wpt);

        setPDGDeparture(legsDataDeparture[`Leg${activeLKey}`].parameters.pdg);
        setCourseDeparture(legsDataDeparture[`Leg${activeLKey}`].parameters.bearing);
        setSelectedPathTerminatorDeparture(legsDataDeparture[`Leg${activeLKey}`]?.parameters?.type);

        const endHeight =
          Number(legsDataDeparture[`Leg${activeLKey}`]?.end_waypoint.alt) -
          Number(legsDataDeparture[`Leg${activeLKey}`]?.start_waypoint.alt);
        setGradHeightDeparture(endHeight);
      }
      setActivePrevLegDesignDataDeparture(null);
    }
  };

  // Add Leg data handling
  useEffect(() => {
    if (generateLegData) {
      const generateAddLegDataResponse: any = generateLegData;
      if (generateAddLegDataResponse.status) {
        setCourseRangeDataDept([]);
        getDesignLegsdata({
          orgId: userSession?.organisationid,
          designId: Number(designid),
          metaId: selectedMetaId,
          procedureId: selectedProcedureId!,
          designversionId: selectedProcedureVersionId
        });
        getDesignProcedureVersions({
          orgId: userSession?.organisationid,
          designid: Number(designid),
          metaId: selectedMetaId,
          procedureId: selectedProcedureId!
        });
        if (generateAddLegDataResponse.legsequence > 1) {
          setActiveLeg(String(generateAddLegDataResponse.legsequence));
        }
      }
    }
  }, [generateLegData]);

  const handleCreateNewLegDataClear = () => {
    setActiveLegDesignDataDeparture(null);
    setCWYDisDeparture(null);
    handleClearDataDeparture();
    // setRemarksDepOmni(undefined);
    // setSelectedPathTerminatorDeparture('');
  };

  const handleChangePAlegData = () => {
    setPAlegDatacheckedList([]);
    handleProtectionAreaCheckList([]);
    setGeneratedOA(false);
    setShowClosingObstacle(false);
  };

  const handleShowHideOATable = (isShow: boolean) => {
    setShowHideOATable(isShow);
    if (!isShow) {
      setShowClosingObstacle(false);
      setShowHideTerrainLayer(false);
      setShowHideObstacleLayer(false);
    }
  };

  const handleShowHideObstacleLayer = (isShowLayer: boolean) => {
    setShowHideObstacleLayer(isShowLayer);
    if (isShowLayer) {
      setShowHideOAData(true);
      setShowHideOATable(true);
      setShowHideTerrainLayer(false);
      setActiveObscleTerrainDataKey('obstacle');
    } else {
      setShowHideOAData(false);
      setShowHideOATable(false);
    }
  };
  const handleShowHideTerrainLayer = (isShowLayer: boolean) => {
    setShowHideTerrainLayer(isShowLayer);
    if (isShowLayer) {
      setShowHideObstacleLayer(false);
      setShowHideOAData(true);
      setShowHideOATable(true);
      setActiveObscleTerrainDataKey('terrain');
    } else {
      setShowHideObstacleLayer(false);
      setShowHideOAData(false);
      setShowHideOATable(false);
    }
  };

  const handleChangeOALegSwitch = (oacheckedLeg: string, legid: number) => {
    setShowHideTerrainLayer(false);
    if (oaLegCheckedKey === oacheckedLeg) {
      setOALegCheckedKey(null);
      setShowHideOAData(false);
      setShowClosingObstacle(false);
      setShowHideOATable(false);
      setShowHideObstacleLayer(false);
    } else {
      setOALegCheckedKey(oacheckedLeg);
      setActiveObscleTerrainDataKey('obstacle');
      setShowHideOAData(true);
      setShowHideObstacleLayer(true);
      setShowHideOATable(true);
      setActiveLegID(legid);
      getLegObstacleAnalysisdata({
        orgid: Number(userSession?.organisationid),
        designid: Number(designid),
        metaId: Number(selectedMetaId),
        procedureId: Number(selectedProcedureId)!,
        designversionId: Number(selectedProcedureVersionId),
        legid: Number(legid)
      });
    }
  };

  const handleChangeCWYSetInitInfoDataDeparture = (
    changeCWYResdata: InfobtwpointsResponseType | null
  ) => {
    setChangeCWYPointInfoDataDeparture(changeCWYResdata);
    const latA = changeCWYResdata?.B[0];
    const lngB = changeCWYResdata?.B[1];
    setDERCoordsDeparture([latA, lngB]);
  };

  const handleSelectedWaypointMarkerOnClick = async (
    selectedWayPoint: WaypointMarkerResponseType
  ) => {
    if (selectedWayPoint) {
      setSelectedWayPointMarker(selectedWayPoint);
      if (selectedPathTerminatorDeparture === 'RF') {
        handleCreateWaypointLatLng([selectedWayPoint.latitude, selectedWayPoint.longitude]);
        handleCallAreaSemiwidth([selectedWayPoint.latitude, selectedWayPoint.longitude]);
      } else {
        handleInfobtwPointsByLatLng({
          latB: selectedWayPoint.latitude,
          lngB: selectedWayPoint.longitude
        });
      }
    } else {
      setSelectedWayPointMarker(null);
    }
  };

  // const handleCancelPathTerminator = () => {
  //   setShowCFPathTerminatorConfirmation(false);
  // };

  // const handleConfirmPathTerminator = () => {
  //   setSelectedPathTerminatorDeparture('CF');
  //   handleCancelPathTerminator();
  // };

  const handleChangePathTerminatorDeparture = (pathTerminator: string) => {
    handleActiveLegModuleTabBtn('endpoint');
    handleClearDataDeparture();
    setFinalArcCords([]);
    setArcEndCoord([]);
    setArcCenterCoord([]);
    setEndAltitudeDeparture(null);
    setGradLengthDeparture(null);
    setGradHeightDeparture(null);
    setTurnAngleDeparture(null);
    setTurnDirectionDeparture(null);
    setRadiusOfTurn(null);
    setCFCourseDept(undefined);
    setCourseRangeDataDept([]);
    // setCourseDeparture(null);
    setEndWaypointLat(null);
    setEndWaypointLng(null);
    if (pathTerminator === 'CA') {
      handleChangeWaypoint('selectWaypoint');
      handleActiveFlyBtn('over');
    }
    // if (pathTerminator === 'CF') {
    //   setShowCFPathTerminatorConfirmation(true);
    //   return;
    // }
    setSelectedPathTerminatorDeparture(pathTerminator);
  };

  const handleOnChangeDataReviewIAS = (iasValue: number) => {
    if (iasValue) {
      setIASSpeedDeparture(iasValue);
      if (endAltitudeDeparture) {
        const tasData = tasCalculation(
          iasValue,
          temperature ? temperature.tmin : 0,
          endAltitudeDeparture
        );
        setTasSpeedDeparture(tasData);
      }
    } else {
      setIASSpeedDeparture(selectedDesign ? acftCategory[selectedDesign?.acft_cat!].max : null);
    }
  };

  const handleChangeTerminatingWaypoint = (isTerminate: boolean) => {
    setTerminatingWaypoint(isTerminate);
  };

  const handleChangeRemarksDepOmni = (remark: string | undefined) => {
    setRemarksDepOmni(remark);
    setShowHideRemarkDepartureBtn(true);
  };

  const handleAddUpdateRemarkDepOmni = async () => {
    const legDEPOMNIData =
      selectedProcedureData && selectedProcedureData.procedure === 'OMNI'
        ? legsDataOMNI
        : legsDataDeparture;

    const legId = legDEPOMNIData[`Leg${activeLeg}`].id;
    if (!legId) return;

    await addRemark({ data: { remark: remarksDepOmni }, legId });
    setShowHideRemarkDepartureBtn(false);
  };

  const handleViewSelectWaypointOnMap = (
    selectedWaypoint: string,
    altitude?: number,
    pdg?: number
  ) => {
    if (selectedWaypoint === 'selectWaypoint' && selectedDesign && selectedDesign.airport_id) {
      if (selectedPathTerminatorDeparture === 'CA') {
        if (!altitude) {
          dispatch(enqueueError('Enter Altitude...'));
          return;
        }
        let startAlt: number = 0;
        if (Number(activeLeg) === 1) {
          startAlt = initStartInfoDeparture ? initStartInfoDeparture.der_elev : 0;
        } else if (Number(activeLeg) > 1) {
          startAlt = activePrevLegDesignDataDeparture
            ? Number(activePrevLegDesignDataDeparture.end_waypoint.alt)
            : 0;
        }
        const height = Number(altitude!) - startAlt;
        const length: number = getEndPointLength(Number(height), pdg || 3.3);

        handlecallInfobtwPointsDeparture(length);
      } else if (selectedPathTerminatorDeparture !== 'CA') {
        setShowWayPointOnMapDeparture(true);
        getDesignWaypointdata(selectedDesign.airport_id);
      }
    }
  };

  const handleChangeAltitudeDeparture = (value: any) => {
    if (Number(value) === Number(Number(endAltitudeDeparture).toFixed(3))) return;
    setEndAltitudeDeparture(value);
    let newHeight: number = 0;

    if (value > 400) {
      let isRight: boolean = false;
      if (Number(activeLeg) === 1) {
        const startAlt: number = initStartInfoDeparture
          ? Number(initStartInfoDeparture.der_elev)
          : 0;
        newHeight = Number(value) - Number(startAlt);
        isRight = Number(value) >= 400 + startAlt;
      } else if (Number(activeLeg) > 1) {
        const startAlt = activePrevLegDesignDataDeparture
          ? Number(activePrevLegDesignDataDeparture.end_waypoint.alt)
          : 0;
        newHeight = Number(value) - Number(startAlt);
        isRight =
          !isEmpty(activePrevLegDesignDataDeparture) &&
          Number(activePrevLegDesignDataDeparture.end_waypoint.alt) > Number(value);
        // setGradHeightDeparture(newHeight);
      }
      setGradHeightDeparture(newHeight);
      if (isEmpty(activeLegDesignDataDeparture)) {
        if (selectedPathTerminatorDeparture !== 'CA') {
          if (gradLengthDeparture) {
            const pdg = getEndPointPDG(Number(newHeight), Number(gradLengthDeparture));
            if (pdg < 3.3 || pdg > 10) {
              setPDGDeparture(null);
              dispatch(enqueueError(`Current PDG (${pdg}) value should be in between 3.3 to 10.`));
            } else {
              setPDGDeparture(Number(pdg.toFixed(2)));
            }
          }
        } else if (selectedPathTerminatorDeparture === 'CA') {
          if (isRight) {
            handleViewSelectWaypointOnMap('selectWaypoint', value, pdgDeparture || 3.3);
          }
        }
      }
    }
  };

  const handleChangePDGDeparture = (value: any) => {
    if (Number(value) === Number(Number(pdgDeparture).toFixed(2))) return;
    if (value === null) {
      setPDGDeparture(3.3);
    } else {
      setPDGDeparture(value);
    }
    if (isEmpty(activeLegDesignDataDeparture)) {
      if (selectedPathTerminatorDeparture !== 'CA') {
        const newHeight = getEndPointHeight(Number(gradLengthDeparture), value || 3.3);
        setGradHeightDeparture(newHeight);
        if (gradLengthDeparture) {
          if (selectedPathTerminatorDeparture !== 'CA') {
            if (Number(activeLeg) === 1) {
              const startAlt = initStartInfoDeparture ? initStartInfoDeparture.der_elev : 0;
              const newEndAlt = Number(newHeight) + Number(startAlt);
              setEndAltitudeDeparture(Number(newEndAlt));
            } else if (Number(activeLeg) > 1) {
              const startAlt = activePrevLegDesignDataDeparture
                ? Number(activePrevLegDesignDataDeparture.end_waypoint.alt)
                : 0;
              const newEndAlt = Number(newHeight) + Number(startAlt);
              setEndAltitudeDeparture(Number(newEndAlt));
            }
          }
        }
      } else if (selectedPathTerminatorDeparture === 'CA') {
        if (endAltitudeDeparture === null) {
          dispatch(enqueueError('Enter first altitude'));
          return;
        }
        let startAlt = 0;
        if (Number(activeLeg) === 1) {
          startAlt = initStartInfoDeparture ? initStartInfoDeparture.der_elev : 0;
        } else if (Number(activeLeg) > 1) {
          startAlt = activePrevLegDesignDataDeparture
            ? Number(activePrevLegDesignDataDeparture.end_waypoint.alt)
            : 0;
        }
        const height = Number(endAltitudeDeparture) - startAlt;
        handleViewSelectWaypointOnMap('selectWaypoint', Number(height), value);
        setEndAltitudeDeparture(Number(height) + startAlt);
      }
    }
  };

  const handleChangeCFCourseDept = (value: any) => {
    setCFCourseDept(value || undefined);

    const isValidCFCourseDept = courseRangeDataDept.some(
      (range) => Number(value) >= range.min && Number(value) <= range.max
    );

    if (isValidCFCourseDept) {
      const prevKey = (Number(activeLeg) - 1).toString();
      const prevData = !isEmpty(legsDataDeparture[`Leg${prevKey}`])
        ? legsDataDeparture[`Leg${prevKey}`]
        : null;
      if (prevData) {
        const payload: ComputePointRequestType = {
          prev_start_point: {
            lat: prevData.start_waypoint.lat,
            lng: prevData.start_waypoint.lng
          },
          start_point: {
            lat: prevData.end_waypoint.lat,
            lng: prevData.end_waypoint.lng
          },
          end_point: {
            lat: endWaypointLatLngDeparture[0],
            lng: endWaypointLatLngDeparture[1]
          },
          altered_bearing: Number(value)
        };
        getComputeV1Point(payload);
      }
    } else {
      dispatch(enqueueError('Course not in range.'));
    }
  };

  const handleClickOnSpeedTabDeparture = () => {
    if (endAltitudeDeparture) {
      const tasData = tasCalculation(
        iasSpeedDeparture || acftCategory[selectedDesign?.acft_cat!].max,
        temperature ? temperature.tmin : 0,
        endAltitudeDeparture
      );
      setTasSpeedDeparture(tasData);
    }
  };

  useEffect(() => {
    const legdata =
      selectedProcedureData && selectedProcedureData.procedure === 'OMNI'
        ? legsDataOMNI
        : legsDataDeparture;
    const legDataKeys = !isEmpty(legdata) ? Object.keys(legdata) : [];
    if (palegDatacheckedList.length === legDataKeys.length) {
      setCheckedPASwitch(true);
    } else {
      setCheckedPASwitch(false);
    }
  }, [palegDatacheckedList]);

  const handleShowAllProtectionArea = (checked: boolean) => {
    const legdata =
      selectedProcedureData && selectedProcedureData.procedure === 'OMNI'
        ? legsDataOMNI
        : legsDataDeparture;

    setCheckedPASwitch(checked);
    if (checked) {
      setPAlegDatacheckedList(!isEmpty(legdata) ? Object.keys(legdata) : []);
      handleProtectionAreaCheckList(!isEmpty(legdata) ? Object.keys(legdata) : []);
    } else {
      setPAlegDatacheckedList([]);
      handleProtectionAreaCheckList([]);
    }
  };

  const handleChangeProtectionAreaLegSwitch = (checkedLeg: string) => {
    if (includes(palegDatacheckedList, checkedLeg)) {
      remove(palegDatacheckedList, (legData: string) => legData === checkedLeg);
    } else {
      palegDatacheckedList.push(checkedLeg);
    }
    setPAlegDatacheckedList([...palegDatacheckedList]);
    handleProtectionAreaCheckList([...palegDatacheckedList]);
  };

  /* Procedure --->   OMNI  ---> START */

  const handleOMNIChangeCWYdisInput = async (CWYdis: number | null) => {
    setEndPointLatlngOMNI([]);
    setIASSpeedOMNI(selectedDesign ? acftCategory[selectedDesign?.acft_cat!].max : null);
    setChangeLengthPointInfoDataOMNI(null);
    setCWYDisOMNI(CWYdis);
    handleRefrencelineApiCall(1);
  };

  const handleChangeLengthOMNI = (length: number | null) => {
    setEndPointLatlngOMNI([]);
    setEndAltGradLengthOMNI(length);
  };

  const handleChangeCWYSetInitInfoDataOMNI = (
    changeCWYResdata: InfobtwpointsResponseType | null
  ) => {
    setEndPointLatlngOMNI([]);
    setChangeCWYPointInfoDataOMNI(changeCWYResdata);
  };

  const handleClickEndLatLngViewOnMapOMNI = () => {
    setEndPointLatlngOMNI(
      !isEmpty(changeLengthPointInfoDataOMNI)
        ? [changeLengthPointInfoDataOMNI.B[0], changeLengthPointInfoDataOMNI.B[1]]
        : []
    );
  };

  const handleOnChangeDataReviewIASOMNI = (iasValue: number) => {
    if (iasValue) {
      setIASSpeedOMNI(iasValue);
      if (endAltGradLengthOMNI) {
        const endAlt = getEndPointHeight(Number(endAltGradLengthOMNI), Number(pdgOMNI || 3.3));
        const tasData = tasCalculation(iasValue, temperature ? temperature.tmin : 0, endAlt);
        setTASSpeedOMNI(tasData);
      } else {
        dispatch(enqueueError('End point/Altitude/Gradiant length not found!'));
      }
    } else {
      setIASSpeedOMNI(selectedDesign ? acftCategory[selectedDesign?.acft_cat!].max : null);
    }
  };

  const handleGeneratePADataOMNI = async () => {
    if (selectedProcedureData && selectedProcedureData.procedure !== 'OMNI') return;
    if (
      isEmpty(changeLengthPointInfoDataOMNI) ||
      isEmpty(initStartInfoOMNI) ||
      !designClearWay ||
      isLengthAltPDGRangeOMNI
    ) {
      dispatch(enqueueError('Some value are missings..'));
      return;
    }

    const omniPayload = {
      legsequence: 1,
      type: 'Departure-OMNI',
      is_terminal: true,
      startDetails: {
        alt: initStartInfoOMNI.der_elev,
        waypoint_type: null,
        waypoint_name: 'DER',
        waypoint_mode: null,
        lat: initStartInfoOMNI.der[0],
        lng: initStartInfoOMNI.der[1],
        aswdetails: null
      },
      endDetails: {
        alt: Number(endAltitudeOMNI),
        waypoint_type: 'new',
        waypoint_name: 'WPT',
        waypoint_mode: null,
        lat: changeLengthPointInfoDataOMNI.B[0],
        lng: changeLengthPointInfoDataOMNI.B[1],
        aswdetails: null
      },
      parameters: {
        // cwy: Number(cwyDisOMNI),
        cwy: Number(designClearWay),
        turn_angle: 0,
        turn_direction: 'on-set',
        twc: 30,
        bank_angle: 15,
        dst_btw_wpt: Number(endAltGradLengthOMNI),
        ias: iasSpeedOMNI,
        pdg: pdgOMNI
      }
    };

    await addDesignLegdata({
      data: omniPayload,
      orgId: Number(userSession?.organisationid!),
      designId: Number(designid),
      metaId: Number(selectedMetaId),
      procedureId: Number(selectedProcedureId)!,
      designversionId: Number(selectedProcedureVersionId),
      legid: Number(activeLegID)
    });
  };

  const handleGenerateOADataOMNI = async () => {
    if (selectedProcedureData && selectedProcedureData.procedure !== 'OMNI') return;
    if (isEmpty(activeLegDesignDataOMNI)) {
      dispatch(enqueueError('PA Leg not generate...'));
      return;
    }
    await addDesignObstacleAnalysisLegdata({
      data: {
        type: activeLegDesignDataOMNI?.parameters?.type
      },
      orgid: Number(userSession?.organisationid),
      designid: Number(designid),
      metaId: Number(selectedMetaId),
      procedureId: Number(selectedProcedureId)!,
      designversionId: Number(selectedProcedureVersionId),
      legid: Number(activeLegID)
    });
  };

  const handleShowObstacleTableOMNI = (isShow: boolean) => {
    setShowHideOADataOMNI(isShow);
    setOATableOmniShow(isShow);
    setShowHideTerrainLayerOMNI(false);
    setShowDataLayerId(null);
    setActiveObscleTerrainDataKey('obstacle');
    if (!isShow) {
      setShowClosingObstacle(false);
    }
    if (isShow && !isGeneratedOA) {
      getLegObstacleAnalysisdata({
        orgid: Number(userSession?.organisationid),
        designid: Number(designid),
        metaId: Number(selectedMetaId),
        procedureId: Number(selectedProcedureId)!,
        designversionId: Number(selectedProcedureVersionId),
        legid: Number(activeLegID)
      });
    }
  };

  const handleShowHideObstacleTableOmni = () => {
    setOATableOmniShow(false);
    setShowHideOADataOMNI(false);
    setShowClosingObstacle(false);
    setShowHideTerrainLayerOMNI(false);
  };

  const handleClickOnSpeedTabOmni = () => {
    if (endAltitudeOMNI && iasSpeedOMNI) {
      // const height = (3.3 * ((Number(endAltGradLengthOMNI) || 0) * 6076.12)) / 100;
      const tasData = tasCalculation(
        iasSpeedOMNI || acftCategory[selectedDesign?.acft_cat!].max,
        temperature ? temperature.tmin : 0,
        endAltitudeOMNI
      );

      setTASSpeedOMNI(tasData);
    }
  };

  const handleShowLengthAltPDGRangeOMNI = (isShowRange: boolean) => {
    setLengthAltPDGRangeOMNI(isShowRange);
  };

  /* Procedure --->   OMNI  ---> END */

  const handleActiveCurrentDesignStep = (designStep: number) => {
    setActiveDesignStep(designStep);
    setCheckedPASwitch(false);
    setShowHideOAData(false);
    setShowHideOATable(false);
    handleShowHideObstacleTableOmni();
    handleCodingTable(false);
    handleShowObstacleTableDeparture(false);
    setOALegCheckedKey(null);
    if (
      selectedProcedureData &&
      selectedProcedureData.procedure === 'OMNI' &&
      isEmpty(activeLegDesignDataOMNI)
    ) {
      if (!includes(activeMapElement, 'splaycircles')) {
        activeMapElement.push('splaycircles');
      }
    }
    if (
      selectedProcedureData &&
      selectedProcedureData.procedure === 'DEPARTURE' &&
      isEmpty(activeLegDesignDataDeparture)
    ) {
      if (!includes(activeMapElement, 'splaycircles')) {
        activeMapElement.push('splaycircles');
      }
    }
    if (designStep !== 0) {
      setMapType(Maptype.Roadmap);
    } else {
      setMapType(Maptype.satellite);
    }
  };

  const handleChangeShowClosingObstacle = (isShowHide: boolean) => {
    setShowClosingObstacle(isShowHide);
  };

  const handleDeleteLegData = (deletelegKey: string) => {
    setShowHideDeleteLegModal(true);
    setDeleteLegKey(deletelegKey || null);
    setCourseRangeDataDept([]);
  };

  const handleDeleteLeg = () => {
    const legDEPOMNIData =
      selectedProcedureData && selectedProcedureData.procedure === 'OMNI'
        ? legsDataOMNI
        : selectedProcedureData && selectedProcedureData.procedure === 'DEPARTURE'
        ? legsDataDeparture
        : null;
    if (isEmpty(legDEPOMNIData)) return;
    const legId = legDEPOMNIData[`Leg${deleteLegKey}`].id;
    if (!legId) return;
    deleteSelectedDesignLEG({
      orgId: Number(userSession?.organisationid),
      designId: Number(designid),
      metaId: Number(selectedMetaId),
      procedureId: Number(selectedProcedureId)!,
      designversionId: Number(selectedProcedureVersionId),
      legId: Number(legId)
    });
  };

  const handleCancelDeleteLeg = () => {
    setShowHideDeleteLegModal(false);
  };

  const handleShowFullScreenTable = (isShowFullTable: boolean) => {
    setObsTerrainFullScreenTable(isShowFullTable);
  };

  const handleShowTerrainLayerDeparture = (isShowHide: boolean) => {
    setShowHideTerrainLayerDeparture(isShowHide);
    setShowHideOADataDeparture(false);
    setOATableDepartureShow(isShowHide);
    setShowDataLayerId(null);
    setActiveObscleTerrainDataKey('terrain');
    if (!isShowHide) {
      setShowClosingObstacle(false);
    }
    if (isShowHide && !isGeneratedOA) {
      getLegObstacleAnalysisdata({
        orgid: Number(userSession?.organisationid),
        designid: Number(designid),
        metaId: Number(selectedMetaId),
        procedureId: Number(selectedProcedureId)!,
        designversionId: Number(selectedProcedureVersionId),
        legid: Number(activeLegDesignDataDeparture?.id)
      });
    }
  };

  const handleShowTerrainLayerOMNI = (isShowHide: boolean) => {
    setShowHideTerrainLayerOMNI(isShowHide);
    setShowHideOADataOMNI(false);
    setOATableOmniShow(isShowHide);
    setActiveObscleTerrainDataKey('terrain');
    setShowDataLayerId(null);
    if (!isShowHide) {
      setShowClosingObstacle(false);
    }
    if (isShowHide && !isGeneratedOA) {
      getLegObstacleAnalysisdata({
        orgid: Number(userSession?.organisationid),
        designid: Number(designid),
        metaId: Number(selectedMetaId),
        procedureId: Number(selectedProcedureId)!,
        designversionId: Number(selectedProcedureVersionId),
        legid: Number(activeLegID)
      });
    }
  };

  const handleShowObsTerrainObstacles = (key: string) => {
    setActiveObscleTerrainDataKey(key);
  };

  const isBool: boolean = activeMapTools === 'line' || activeMapTools === 'point';

  const isCreateWaypointBool =
    endSelectNavaidWaypoint === 'createWaypoint' && !isEmpty(pointLatLng);

  const mapContainer = useMemo(() => {
    const selectedAirspaceData =
      availableAirspaceData && showDataLayerId && !isEmpty(availableAirspaceData[showDataLayerId])
        ? availableAirspaceData[showDataLayerId]
        : [];

    const step3LegData =
      selectedProcedureData && selectedProcedureData.procedure === 'OMNI'
        ? legsDataOMNI
        : legsDataDeparture;

    return (
      <MapContainer
        center={mapCenter}
        maxZoom={15}
        minZoom={9}
        zoomControl={false}
        className="map-container design-data-map-container"
      >
        <MapLayers maptype={mapType} />
        {arp && <Marker icon={AerodromePointIcon} position={arp} />}
        {!isEmpty(initInfoDesignData) && (
          <ARPPolyline
            weight={1.5}
            arpCoordinates={
              !isEmpty(initInfoDesignData)
                ? [
                    [
                      initInfoDesignData.startpoint.coordinates[0],
                      initInfoDesignData.startpoint.coordinates[1]
                    ],
                    [
                      initInfoDesignData.endpoint.coordinates[0],
                      initInfoDesignData.endpoint.coordinates[1]
                    ]
                  ]
                : null
            }
            decoratorIcon={LightTriangleIcon}
            lineColor="#27A4FF"
          />
        )}
        {/* Departure render start */}
        {activeDesignStep === 1 &&
          Number(activeLeg) === 1 &&
          selectedProcedureData &&
          selectedProcedureData.procedure === 'DEPARTURE' &&
          !isEmpty(DERCoordsDeparture) &&
          isEmpty(activeLegDesignDataDeparture) && (
            <Marker position={DERCoordsDeparture} icon={BlueTrianglePointIcon}>
              <Tooltip>DER</Tooltip>
            </Marker>
          )}
        {activeDesignStep === 1 &&
          Number(activeLeg) > 1 &&
          selectedPathTerminatorDeparture === 'CF' &&
          selectedProcedureData &&
          CFCourseDept &&
          selectedProcedureData.procedure === 'DEPARTURE' &&
          !isEmpty(newLegDERCoordsDeparture) &&
          isEmpty(activeLegDesignDataDeparture) && (
            <Marker
              position={newLegDERCoordsDeparture}
              icon={activeFlyBtn === 'by' ? FlybyWaypointLightIcon : FlyoverWaypointLightIcon}
            >
              <Tooltip>New start point</Tooltip>
            </Marker>
          )}
        {endSelectNavaidWaypoint &&
          activeDesignStep === 1 &&
          selectedProcedureData &&
          Number(activeLeg) === 1 &&
          selectedProcedureData.procedure === 'DEPARTURE' &&
          !isEmpty(DERCoordsDeparture) &&
          !isEmpty(endWaypointLatLngDeparture) &&
          isEmpty(activeLegDesignDataDeparture) && (
            <>
              <Polyline
                pane="shadowPane"
                pathOptions={{ color: red2, weight: 1.5 }}
                positions={[DERCoordsDeparture, endWaypointLatLngDeparture]}
              />
              <LineTextPath
                coordinates={[
                  [endWaypointLatLngDeparture[0], endWaypointLatLngDeparture[1]],
                  [DERCoordsDeparture[0], DERCoordsDeparture[1]]
                ]}
                distance={`${
                  gradLengthDeparture ? `${Number(gradLengthDeparture).toFixed(3)} NM` : '0 NM'
                }`}
                bearing={
                  courseDeparture
                    ? `${courseDeparture.toFixed(3)} / ${(
                        courseDeparture - magneticDeclination!
                      ).toFixed(3)}° M`
                    : '0° M'
                }
              />
            </>
          )}
        {endSelectNavaidWaypoint &&
          activeDesignStep === 1 &&
          Number(activeLeg) > 1 &&
          selectedProcedureData &&
          selectedProcedureData.procedure === 'DEPARTURE' &&
          !isEmpty(newLegDERCoordsDeparture) &&
          !isEmpty(endWaypointLatLngDeparture) &&
          selectedPathTerminatorDeparture !== 'RF' &&
          isEmpty(activeLegDesignDataDeparture) && (
            <>
              <Polyline
                pane="shadowPane"
                pathOptions={{ color: red2, weight: 1.5 }}
                positions={[newLegDERCoordsDeparture, endWaypointLatLngDeparture]}
              />
              <LineTextPath
                coordinates={[
                  [endWaypointLatLngDeparture[0], endWaypointLatLngDeparture[1]],
                  [newLegDERCoordsDeparture[0], newLegDERCoordsDeparture[1]]
                ]}
                distance={`${
                  gradLengthDeparture ? `${Number(gradLengthDeparture).toFixed(3)} NM` : '0 NM'
                }`}
                bearing={
                  courseDeparture
                    ? `${courseDeparture.toFixed(3)} / ${(
                        courseDeparture - magneticDeclination!
                      ).toFixed(3)}° M`
                    : '0° M'
                }
              />
            </>
          )}
        {activeDesignStep === 1 &&
          selectedProcedureData &&
          selectedProcedureData.procedure === 'DEPARTURE' &&
          !isEmpty(refrenceLineData) &&
          isEmpty(activeLegDesignDataDeparture) && <RefrenceLine lineCoords={refrenceLineData} />}
        {activeDesignStep === 1 &&
          selectedProcedureData &&
          selectedProcedureData.procedure === 'DEPARTURE' &&
          !isEmpty(overseeLineData) &&
          isEmpty(activeLegDesignDataDeparture) && <OverseeLine lineCoords={overseeLineData} />}
        {activeDesignStep === 1 &&
          selectedProcedureData &&
          selectedProcedureData.procedure === 'DEPARTURE' &&
          selectedPathTerminatorDeparture !== 'RF' &&
          !isEmpty(endWaypointLatLngDeparture) &&
          isEmpty(activeLegDesignDataDeparture) && (
            <Marker
              position={endWaypointLatLngDeparture}
              icon={activeFlyBtn === 'by' ? FlybyWaypointLightIcon : FlyoverWaypointLightIcon}
            >
              <Tooltip>{selectedWayPointMarker ? selectedWayPointMarker.name : 'XXXX'}</Tooltip>
            </Marker>
          )}
        {activeDesignStep === 1 &&
          selectedProcedureData &&
          selectedProcedureData.procedure === 'DEPARTURE' &&
          !isEmpty(legsDataDeparture) && (
            <RenderLegData
              legsData={legsDataDeparture}
              activeLegKey={`Leg${activeLeg}`}
              procedure="DEPARTURE"
              selectedPathTerminator={
                selectedPathTerminatorDeparture === 'RF' ? selectedPathTerminatorDeparture : null
              }
              magneticDeclination={magneticDeclination}
            />
          )}
        {activeDesignStep === 2 &&
          selectedProcedureData &&
          selectedProcedureData.procedure === 'DEPARTURE' &&
          !isEmpty(step3LegData) &&
          palegDatacheckedList.map((leg, i) => {
            return (
              <RenderLegData
                key={i}
                legsData={step3LegData}
                activeLegKey={leg}
                procedure="DEPARTURE"
                isLine={false}
                selectedPathTerminator={
                  selectedPathTerminatorDeparture === 'RF' ? selectedPathTerminatorDeparture : null
                }
                magneticDeclination={magneticDeclination}
              />
            );
          })}

        {activeDesignStep === 3 &&
          !isEmpty(legsDataDeparture) &&
          Object.keys(legsDataDeparture).map((leg, i) => {
            return (
              <RenderPALineData
                key={i}
                legsData={legsDataDeparture}
                activeLegKey={leg}
                procedure="DEPARTURE"
                pathTerminator={null}
                magneticDeclination={magneticDeclination}
              />
            );
          })}
        {activeDesignStep === 2 &&
          selectedProcedureData &&
          selectedProcedureData.procedure === 'DEPARTURE' &&
          !isEmpty(step3LegData) &&
          Object.keys(step3LegData).map((leg, i) => {
            return (
              <RenderPALineData
                key={i}
                legsData={step3LegData}
                activeLegKey={leg}
                procedure="DEPARTURE"
                pathTerminator={null}
                magneticDeclination={magneticDeclination}
              />
            );
          })}
        {activeDesignStep === 3 &&
          oaLegCheckedKey &&
          legsDataDeparture &&
          selectedProcedureData &&
          selectedProcedureData.procedure === 'DEPARTURE' && (
            <RenderLegData
              legsData={legsDataDeparture}
              activeLegKey={oaLegCheckedKey}
              procedure="DEPARTURE"
              isLine={false}
              selectedPathTerminator={
                selectedPathTerminatorDeparture === 'RF' ? selectedPathTerminatorDeparture : null
              }
              magneticDeclination={magneticDeclination}
            />
          )}
        {activeDesignStep === 1 &&
          selectedProcedureData &&
          selectedProcedureData.procedure === 'DEPARTURE' &&
          isEmpty(activeLegDesignDataDeparture) &&
          !isEmpty(activePrevLegDesignDataDeparture) && (
            <RenderPrevLegData
              legsData={activePrevLegDesignDataDeparture}
              // isLine={false}
              procedure="DEPARTURE"
              selectedPathTerminator={
                activePrevLegDesignDataDeparture?.parameters?.type === 'RF' ? 'RF' : null
              }
              magneticDeclination={magneticDeclination}
            />
          )}
        {activeDesignStep === 1 &&
          !isEmpty(activeLegDesignDataDeparture) &&
          activeObscleTerrainDataKey === 'terrain' &&
          isShowHideTerrainLayerDeparture && (
            <WMTSTileLayer
              icaocode={activeLegDesignDataDeparture?.terrain_layer_name || null}
              isTerrainDataViewonmap
            />
          )}
        {/* Departure render end */}
        {/* Waypoint markers start */}
        {endSelectNavaidWaypoint === 'selectWaypoint' && isShowWayPointOnMapDeparture && (
          <SelectWaypointMarkers
            endSelectNavaidWaypoint={endSelectNavaidWaypoint}
            selectWaypointData={selectWaypointData}
            selectedWayPointMarker={selectedWayPointMarker}
            handleSelectedWaypointMarkerOnClick={handleSelectedWaypointMarkerOnClick}
          />
        )}
        {/* Waypoint markers end */}
        {/* design tool header content render start */}
        {showDataLayerId === 'aixmobstacles' && (
          <AixmObstaclesAdData
            obstacleData={
              availableADData &&
              showDataLayerId === 'aixmobstacles' &&
              !isEmpty(availableADData[showDataLayerId])
                ? availableADData[showDataLayerId]
                : []
            }
          />
        )}
        {activeDesignStep === 1 &&
          !isEmpty(activeOALegDesignDataDeparture) &&
          activeObscleTerrainDataKey === 'obstacle' &&
          isShowHideOADataDeparture && (
            <RenderObstaclesDepatureOmniData
              obstacleData={activeOALegDesignDataDeparture}
              isShowClosingObs={isShowClosingObstacle}
            />
          )}
        {activeDesignStep === 1 &&
          !isEmpty(activeTerrainLegDesignDataDeparture) &&
          activeObscleTerrainDataKey === 'terrain' &&
          isShowHideTerrainLayerDeparture && (
            <RenderObstaclesDepatureOmniData
              obstacleData={activeTerrainLegDesignDataDeparture}
              isShowClosingObs={isShowClosingObstacle}
            />
          )}
        {/* Airspace data render */}
        {showDataLayerId === airspaceDataLabel.ctrl && (
          <RenderControlledAirspace ctrlAirspaceData={selectedAirspaceData} />
        )}
        {showDataLayerId === airspaceDataLabel.geoNavaid && (
          <RenderNavaidsAirspace navaidAirspaceData={selectedAirspaceData} />
        )}
        {showDataLayerId === airspaceDataLabel.waypoint && (
          <RenderWayPointsAirspace waypointAirspaceData={selectedAirspaceData} />
        )}
        {showDataLayerId === airspaceDataLabel.fir && (
          <RenderFIRAirspace firAirspaceData={selectedAirspaceData} />
        )}
        {showDataLayerId === airspaceDataLabel.restrictive && (
          <RenderRestrictiveAirspace restrictiveAirspaceData={selectedAirspaceData} />
        )}
        {showDataLayerId === airspaceDataLabel.routeRNAV && (
          <RenderRNAVRouteAirspace routeRNAVAirspaceData={selectedAirspaceData} />
        )}
        {showDataLayerId === airspaceDataLabel.routeCNAV && (
          <RenderCNAVRouteAirspace routeCNAVAirspaceData={selectedAirspaceData} />
        )}
        {showDataLayerId === airspaceDataLabel.military && (
          <RenderMilitryAirspace militryAirspaceData={selectedAirspaceData} />
        )}
        {showDataLayerId === airspaceDataLabel.adiz && (
          <RenderADIZAirspace adizAirspaceData={selectedAirspaceData} />
        )}
        {showDataLayerId === 'surveyobstacle' && !isEmpty(surveyObstacleData) && (
          <RenderSurveyObstaclePoint obstacleData={surveyObstacleData} />
        )}
        {showDataLayerId === 'srtm' && terrainLayerName && (
          <TerrainWMTSTileLayer WMTSStyle={terrainLayerName} isTerrainDataViewonmap />
        )}
        {/* map elements render start */}
        {!isEmpty(nominalCircles) &&
          includes(activeMapElement, 'splaycircles') &&
          !isEmpty(nominalCircles?.circle_15_nm) &&
          (isEmpty(activeLegDesignDataDeparture) || isEmpty(activeLegDesignDataOMNI)) && (
            <LineStringComponent
              coordinates={nominalCircles.circle_15_nm}
              color={blue}
              dashArray={[5, 5]}
              weight={2}
            />
          )}
        {!isEmpty(nominalCircles) &&
          includes(activeMapElement, 'splaycircles') &&
          !isEmpty(nominalCircles?.circle_30_nm) &&
          (isEmpty(activeLegDesignDataDeparture) || isEmpty(activeLegDesignDataOMNI)) && (
            <LineStringComponent
              coordinates={nominalCircles.circle_30_nm}
              color={blue}
              dashArray={[5, 5]}
              weight={2}
            />
          )}
        {activeDesignStep === 1 &&
          !isEmpty(step3LegData) &&
          includes(activeMapElement, 'tolerances') && (
            <RenderToleranceData legsData={step3LegData} activeLegKey={`Leg${activeLeg}`} />
          )}
        {palegDatacheckedList &&
          activeDesignStep === 2 &&
          !isEmpty(step3LegData) &&
          includes(activeMapElement, 'tolerances') &&
          palegDatacheckedList.map((leg) => {
            return <RenderToleranceData legsData={step3LegData} activeLegKey={leg} />;
          })}
        {activeDesignStep === 3 &&
          oaLegCheckedKey &&
          !isEmpty(step3LegData) &&
          includes(activeMapElement, 'tolerances') && (
            <RenderToleranceData legsData={step3LegData} activeLegKey={oaLegCheckedKey} />
          )}
        {availableAirspaceData &&
          availableAirspaceData[airspaceDataLabel.geoNavaid] &&
          includes(activeMapElement, 'navaids') && (
            <RenderNavaidsAirspace
              navaidAirspaceData={availableAirspaceData[airspaceDataLabel.geoNavaid]}
            />
          )}
        {availableAirspaceData &&
          availableAirspaceData[airspaceDataLabel.routeCNAV] &&
          includes(activeMapElement, 'conventional') && (
            <RenderCNAVRouteAirspace
              routeCNAVAirspaceData={availableAirspaceData[airspaceDataLabel.routeCNAV]}
            />
          )}
        {activeMapElement.includes('surveyobstacles') && !isEmpty(surveyObstacleData) && (
          <RenderSurveyObstaclePoint obstacleData={surveyObstacleData} />
        )}
        {activeMapElement.includes('terrain') && terrainLayerName && (
          <TerrainWMTSTileLayer WMTSStyle={terrainLayerName} isTerrainDataViewonmap />
        )}
        {activeDesignStep === 1 && activeMapElement.includes('windspirals') && (
          <RenderWindspiralData windspiraldata={legWindspiralsData} />
        )}
        {palegDatacheckedList &&
          activeDesignStep === 2 &&
          !isEmpty(step3LegData) &&
          includes(activeMapElement, 'windspirals') &&
          palegDatacheckedList.map((leg) => {
            return <RenderWindspiralData windspiraldata={step3LegData[leg].windspirals} />;
          })}
        {activeDesignStep === 3 &&
          oaLegCheckedKey &&
          !isEmpty(step3LegData) &&
          includes(activeMapElement, 'windspirals') && (
            <RenderWindspiralData windspiraldata={step3LegData[oaLegCheckedKey].windspirals} />
          )}
        {includes(activeMapElement, 'waypoints') && isShowWayPointOnMapDeparture && (
          <SelectWaypointMarkers
            endSelectNavaidWaypoint={endSelectNavaidWaypoint}
            selectWaypointData={selectWaypointData}
            selectedWayPointMarker={selectedWayPointMarker}
            handleSelectedWaypointMarkerOnClick={handleSelectedWaypointMarkerOnClick}
            markerIcon={isShowWayPointOnMapDeparture}
          />
        )}
        {/* map elements render end */}
        {/* design tool header content render end */}
        {selectedPathTerminatorDeparture !== 'RF' && (
          <CreateWaypoint
            activeLegDesignDataDeparture={activeLegDesignDataDeparture}
            endSelectNavaidWaypoint={endSelectNavaidWaypoint}
            handleCreateWaypointLatLng={handleCreateWaypointLatLng}
          />
        )}
        {selectedPathTerminatorDeparture === 'RF' && (
          <CreateWaypoint
            activeLegDesignDataDeparture={activeLegDesignDataDeparture}
            endSelectNavaidWaypoint={endSelectNavaidWaypoint}
            pathTerminator={selectedPathTerminatorDeparture}
            handleCreateWaypointLatLng={handleCreateWaypointLatLng}
            handleCallAreaSemiwidth={handleCallAreaSemiwidth}
          />
        )}
        {endSelectNavaidWaypoint === 'latlngWaypoint' && isViewOnMapEnterWaypoint && (
          <EnterWaypoint
            activeFlyBtn={activeFlyBtn}
            endWaypointName={undefined}
            endWaypointLat={endWaypointLat}
            endWaypointLng={endWaypointLng}
          />
        )}
        {!isEmpty(protectionAreaCheckList) && (
          <RenderProtectionAreaLegs paCheckList={protectionAreaCheckList} legsData={legsData} />
        )}

        {/* Omni data render start */}
        {activeDesignStep === 1 &&
          selectedProcedureData &&
          selectedProcedureData.procedure === 'OMNI' &&
          !isEmpty(DERCoordsOMNI) &&
          isEmpty(activeLegDesignDataOMNI) && (
            <Marker position={DERCoordsOMNI} icon={BlueTrianglePointIcon}>
              <Tooltip>DER</Tooltip>
            </Marker>
          )}
        {activeDesignStep === 1 &&
          selectedProcedureData &&
          selectedProcedureData.procedure === 'OMNI' &&
          !isEmpty(DERCoordsOMNI) &&
          !isEmpty(endPointLatlngOMNI) &&
          isEmpty(activeLegDesignDataOMNI) && (
            <>
              <Polyline
                pane="shadowPane"
                pathOptions={{ color: red2, weight: 1.5 }}
                positions={[DERCoordsOMNI, endPointLatlngOMNI]}
              />
              <LineTextPath
                coordinates={[
                  [endPointLatlngOMNI[0], endPointLatlngOMNI[1]],
                  [DERCoordsOMNI[0], DERCoordsOMNI[1]]
                ]}
                distance={`${endAltGradLengthOMNI || 0} NM`}
                bearing={
                  changeLengthPointInfoDataOMNI
                    ? `${Number(changeLengthPointInfoDataOMNI.bearing).toFixed(3)}° / ${(
                        Number(changeLengthPointInfoDataOMNI.bearing) - magneticDeclination!
                      ).toFixed(3)}° M`
                    : '0° / 0° M'
                }
                // bearing="0° M"
              />
            </>
          )}
        {activeDesignStep === 1 &&
          selectedProcedureData &&
          selectedProcedureData.procedure === 'OMNI' &&
          !isEmpty(endPointLatlngOMNI) &&
          isEmpty(activeLegDesignDataOMNI) && (
            <Marker position={endPointLatlngOMNI} icon={FlyoverWaypointLightIcon}>
              <Tooltip>XXXXX</Tooltip>
            </Marker>
          )}
        {activeDesignStep === 1 &&
          selectedProcedureData &&
          selectedProcedureData.procedure === 'OMNI' &&
          !isEmpty(refrenceLineData) &&
          isEmpty(activeLegDesignDataOMNI) && <RefrenceLine lineCoords={refrenceLineData} />}
        {activeDesignStep === 1 &&
          selectedProcedureData &&
          selectedProcedureData.procedure === 'OMNI' &&
          !isEmpty(overseeLineData) &&
          isEmpty(activeLegDesignDataOMNI) && <OverseeLine lineCoords={overseeLineData} />}
        {activeDesignStep === 1 &&
          !isEmpty(activeOALegDesignDataOMNI) &&
          activeObscleTerrainDataKey === 'obstacle' &&
          isShowHideOADataOMNI && (
            <RenderObstaclesDepatureOmniData obstacleData={activeOALegDesignDataOMNI} />
          )}
        {activeDesignStep === 1 &&
          !isEmpty(activeTerrainLegDesignDataOMNI) &&
          activeObscleTerrainDataKey === 'terrain' &&
          isShowHideTerrainLayerOMNI && (
            <RenderObstaclesDepatureOmniData obstacleData={activeTerrainLegDesignDataOMNI} />
          )}
        {activeDesignStep === 1 &&
          !isEmpty(activeLegDesignDataOMNI) &&
          activeObscleTerrainDataKey === 'terrain' &&
          isShowHideTerrainLayerOMNI && (
            <WMTSTileLayer
              icaocode={activeLegDesignDataOMNI?.terrain_layer_name || null}
              isTerrainDataViewonmap
            />
          )}
        {activeDesignStep === 3 &&
          !isEmpty(step3LegData) &&
          oaLegCheckedKey &&
          activeObscleTerrainDataKey === 'terrain' &&
          isShowHideTerrainLayer && (
            <WMTSTileLayer
              icaocode={step3LegData[oaLegCheckedKey].terrain_layer_name || null}
              isTerrainDataViewonmap
            />
          )}
        {activeDesignStep === 1 && !isEmpty(legsDataOMNI) && (
          <RenderLegData
            legsData={legsDataOMNI}
            procedure="OMNI"
            activeLegKey="Leg1"
            selectedPathTerminator={null}
            magneticDeclination={magneticDeclination}
          />
        )}
        {activeDesignStep === 3 &&
          !isEmpty(legsDataOMNI) &&
          Object.keys(legsDataOMNI).map((leg, i) => {
            return (
              <RenderPALineData
                key={i}
                legsData={legsDataOMNI}
                activeLegKey={leg}
                procedure="OMNI"
                isPoint={!isShowHideOAData}
                pathTerminator={null}
                magneticDeclination={magneticDeclination}
              />
            );
          })}
        {activeDesignStep === 2 &&
          selectedProcedureData &&
          selectedProcedureData.procedure === 'OMNI' &&
          !isEmpty(step3LegData) &&
          Object.keys(step3LegData).map((leg, i) => {
            return (
              <RenderPALineData
                key={i}
                legsData={step3LegData}
                activeLegKey={leg}
                procedure="OMNI"
                isPoint={isEmpty(palegDatacheckedList)}
                pathTerminator={null}
                magneticDeclination={magneticDeclination}
              />
            );
          })}
        {activeDesignStep === 2 &&
          selectedProcedureData &&
          selectedProcedureData.procedure === 'OMNI' &&
          !isEmpty(step3LegData) &&
          palegDatacheckedList.map((leg, i) => {
            return (
              <RenderLegData
                key={i}
                legsData={step3LegData}
                activeLegKey={leg}
                procedure="OMNI"
                isLine={false}
                selectedPathTerminator={null}
                magneticDeclination={magneticDeclination}
              />
            );
          })}
        {activeDesignStep === 3 && !isEmpty(legsDataOMNI) && isShowHideOAData && (
          <RenderLegData
            legsData={legsDataOMNI}
            procedure="OMNI"
            activeLegKey="Leg1"
            selectedPathTerminator={null}
            magneticDeclination={magneticDeclination}
          />
        )}
        {activeDesignStep === 3 &&
          !isEmpty(legObstacleAnalysis) &&
          isShowHideOAData &&
          (isShowHideTerrainLayer || isShowHideObstacleLayer) && (
            <RenderObstaclesDepatureOmniData
              obstacleData={legObstacleAnalysis}
              isShowClosingObs={isShowClosingObstacle}
            />
          )}
        {/* Omni data render end */}
        {activeDesignStep === 1 &&
          !isEmpty(finalArcCords) &&
          selectedPathTerminatorDeparture === 'RF' &&
          isEmpty(activeLegDesignDataDeparture) && <RFArcLine finalArcCords={finalArcCords} />}
        {activeDesignStep === 1 &&
          !isEmpty(activeLegDesignDataDeparture) &&
          activeLegDesignDataDeparture?.parameters?.type === 'RF' && (
            <RFArcLine
              finalArcCords={activeLegDesignDataDeparture.geo_parameters.nominal_track || []}
            />
          )}
        {activeDesignStep === 1 &&
          !isEmpty(arcEndCoord) &&
          endSelectNavaidWaypoint === 'createWaypoint' &&
          selectedPathTerminatorDeparture === 'RF' &&
          isEmpty(activeLegDesignDataDeparture) && (
            <DraggableMarker
              activeFlyBtn={activeFlyBtn}
              arcEndCoord={arcEndCoord}
              handleCreateWaypointLatLng={handleCreateWaypointLatLng}
              handleCallAreaSemiwidth={handleCallAreaSemiwidth}
            />
          )}
        {!isEmpty(arcCenterCoord) &&
          selectedPathTerminatorDeparture === 'RF' &&
          isEmpty(activeLegDesignDataDeparture) && (
            <Circle
              center={arcCenterCoord}
              pathOptions={{ fillColor: primary, color: primary }}
              radius={20}
            >
              <Tooltip>Center Point</Tooltip>
            </Circle>
          )}

        <FeatureGroup ref={featureGroupRef}>
          <EditControl
            position="topright"
            onCreated={handleOnCreateShape}
            onMounted={handleOnMounted}
            draw={{
              rectangle: false,
              polyline: false,
              // activeMapTools === 'line'
              //   ? {
              //       allowIntersection: false,
              //       shapeOptions: {
              //         color: '#1ee0cd',
              //         fillColor: '#1ee0cd',
              //         fillOpacity: 1,
              //         weight: 2
              //       },
              //       showLength: true
              //     }
              //   : false,
              circle: false,
              circlemarker: false,
              polygon: false,
              marker: false
              // activeMapTools === 'point'
              //   ? {
              //       icon:
              //         // eslint-disable-next-line
              //         activeFlyBtn === 'flyover' && endSelectNavaidWaypoint === 'createWaypoint'
              //           ? FlyoverWaypointIcon
              //           : activeFlyBtn === 'flyby' && endSelectNavaidWaypoint === 'createWaypoint'
              //           ? FlybyWaypointIcon
              //           : LightLocation
              //     }
              //   : false
            }}
            edit={{ remove: isBool, edit: false }}
          />
        </FeatureGroup>

        <MeasurementControl />
        <MapActionControlV2
          mapType={mapType}
          handleMapType={handleMapType}
          handleFullScreenMapView={handleFullScreenMapView}
          isFullScreenMapView={isFullScreenMapView}
        />
        <LiveCoordinates />
        <ControlMapCenter zoomLevel={mapZoom} center={mapCenter} />
      </MapContainer>
    );
  }, [
    finalArcCords,
    mapZoom,
    isFullScreenMapView,
    isStartDesign,
    initStartInfoDeparture,
    changeCWYPointInfoDataDeparture,
    activePrevLegDesignDataDeparture,
    protectionAreaCheckList,
    mapCenter,
    featureGroupRef,
    isCreateWaypointBool,
    mapType,
    isShowAeroTable,
    activeMapTools,
    pointLatLng,
    newPolyLineCoords,
    centroidPoint,
    isBool,
    endSelectNavaidWaypoint,
    activeFlyBtn,
    endWaypointLat,
    endWaypointLng,
    isViewOnMapEnterWaypoint,
    activeMapElement,
    waypointsData,
    legsData,
    legsDataOMNI,
    activeLeg,
    activeDesignStep,
    DERCoordsDeparture,
    showDataLayerId,
    endPointLatlngOMNI,
    isShowHideOADataOMNI,
    isShowHideOADataDeparture,
    activeOALegDesignDataOMNI,
    activeOALegDesignDataDeparture,
    selectedDesign,
    selectWaypointData,
    isFetchingWaypoint,
    selectedWayPointMarker,
    isShowWayPointOnMapDeparture,
    endWaypointLatLngDeparture,
    legsDataDeparture,
    DERCoordsOMNI,
    refrenceLineData,
    overseeLineData,
    legObstacleAnalysis,
    isShowHideOAData,
    isShowClosingObstacle,
    legWindspiralsData,
    activeObscleTerrainDataKey,
    activeTerrainLegDesignDataDeparture,
    activeTerrainLegDesignDataOMNI,
    isShowHideTerrainLayerDeparture,
    isShowHideTerrainLayerOMNI,
    isShowHideObstacleLayer,
    isShowHideTerrainLayer,
    initInfoDesignData
  ]);

  return (
    <>
      <Layout hasSider>
        <Content className="main-content design-data-content">
          <DesignToolHeader
            activeDataLayerId={showDataLayerId}
            temperature={temperature}
            activeMapTools={activeMapTools}
            activeMapElement={activeMapElement}
            magneticDeclination={magneticDeclination}
            handleOnClickMapTools={handleOnClickMapTools}
            handleOnClickMapElement={handleOnClickMapElement}
            handleShowHideDataLayer={handleShowHideDataLayer}
          />
          {!isFullScreenMapView && mapContainer}
          <RenderSelectedToolHeaderData
            isShowAeroTable={isShowAeroTable}
            showDataLayerId={showDataLayerId}
            surveyObstacleData={surveyObstacleData}
            availableADData={availableADData}
            adItem={adItem}
            isObsTerrainFullScreenTable={isObsTerrainFullScreenTable}
            handleShowAeroTable={handleShowAeroTable}
            setShowAeroTable={setShowAeroTable}
            handleShowFullScreenTable={handleShowFullScreenTable}
          />
          {showDataLayerId === 'srtm' && terrainLayerName && (
            <LayerLegendImage
              classname="design-layer-data"
              terrainPath={selectedDesign?.icao || null}
            />
          )}
          {activeMapElement.includes('terrain') && terrainLayerName && (
            <LayerLegendImage
              classname="design-layer-data"
              terrainPath={selectedDesign?.icao || null}
            />
          )}
          {activeDesignStep === 1 &&
            activeObscleTerrainDataKey === 'terrain' &&
            !isEmpty(activeLegDesignDataOMNI) &&
            isShowHideTerrainLayerOMNI && (
              <LayerLegendImage
                classname="design-layer-data"
                terrainPath={activeLegDesignDataOMNI?.terrain_layer_name || null}
              />
            )}
          {activeDesignStep === 1 &&
            activeObscleTerrainDataKey === 'terrain' &&
            !isEmpty(activeLegDesignDataDeparture) &&
            isShowHideTerrainLayerDeparture && (
              <LayerLegendImage
                classname="design-layer-data"
                terrainPath={activeLegDesignDataDeparture?.terrain_layer_name || null}
              />
            )}
          {activeDesignStep === 3 &&
            activeObscleTerrainDataKey === 'terrain' &&
            isShowHideTerrainLayer &&
            !isEmpty(activeLegDesignDataDeparture) &&
            selectedProcedureData &&
            selectedProcedureData.procedure === 'DEPARTURE' && (
              <LayerLegendImage
                classname="design-layer-data"
                terrainPath={activeLegDesignDataDeparture?.terrain_layer_name || null}
              />
            )}
          {activeDesignStep === 3 &&
            activeObscleTerrainDataKey === 'terrain' &&
            isShowHideTerrainLayer &&
            !isEmpty(activeLegDesignDataOMNI) &&
            selectedProcedureData &&
            selectedProcedureData.procedure === 'OMNI' && (
              <LayerLegendImage
                classname="design-layer-data"
                terrainPath={activeLegDesignDataOMNI?.terrain_layer_name || null}
              />
            )}
          <RenderObstacleTableData
            isOATableDepartureShow={isOATableDepartureShow}
            isOATableOmniShow={isOATableOmniShow}
            isShowHideOATable={isShowHideOATable}
            isactiveCodingTable={isactiveCodingTable}
            isLoadingCodingTableData={isLoadingCodingTableData}
            activeLeg={activeLeg}
            isShowClosingObstacle={isShowClosingObstacle}
            activeOALegDesignDataDeparture={activeOALegDesignDataDeparture}
            activeOALegDesignDataOMNI={activeOALegDesignDataOMNI}
            activePrevLegDesignDataDeparture={activePrevLegDesignDataDeparture}
            activeLegDesignDataDeparture={activeLegDesignDataDeparture}
            oaLegCheckedKey={oaLegCheckedKey}
            legObstacleAnalysis={legObstacleAnalysis}
            selectedProcedureData={selectedProcedureData}
            codingTableData={codingTableData}
            isLoadingOAData={isLoadingOAData}
            handleShowHideObstacleTableDeparture={handleShowHideObstacleTableDeparture}
            handleShowHideObstacleTableOmni={handleShowHideObstacleTableOmni}
            handleChangeShowClosingObstacle={handleChangeShowClosingObstacle}
            handleShowHideOATable={handleShowHideOATable}
            handleCodingTable={handleCodingTable}
            activeObscleTerrainDataKey={activeObscleTerrainDataKey}
            activeTerrainLegDesignDataDeparture={activeTerrainLegDesignDataDeparture}
            activeTerrainLegDesignDataOMNI={activeTerrainLegDesignDataOMNI}
            handleShowObsTerrainObstacles={handleShowObsTerrainObstacles}
            handleShowTerrainLayerDeparture={handleShowTerrainLayerDeparture}
            handleShowFullScreenTable={handleShowFullScreenTable}
            isObsTerrainFullScreenTable={isObsTerrainFullScreenTable}
          />
        </Content>
        <Sider className="data-sidebar" width={437}>
          {isStartDesign ? (
            <DesignStartedSidebar
              isDesigner={isDesigner}
              magneticDeclination={magneticDeclination}
              designid={designid}
              initInfoBearing={initInfoBearing}
              initStartInfoDeparture={initStartInfoDeparture}
              activeLeg={activeLeg}
              isGeneratedOA={isGeneratedOA}
              selectedProcedureData={selectedProcedureData}
              endWaypointLatLngDeparture={endWaypointLatLngDeparture}
              selectedMetaId={selectedMetaId}
              courseDeparture={courseDeparture}
              activeObscleTerrainDataKey={activeObscleTerrainDataKey}
              isShowHideTerrainLayerDeparture={isShowHideTerrainLayerDeparture}
              activeDeptBtnDeparture={activeDeptBtnDeparture}
              handleActiveLegModuleTabBtn={handleActiveLegModuleTabBtn}
              handleBackToDesign={handleBackToDesign}
              handleOnChangeActiveLegIndex={handleOnChangeActiveLegIndex}
              handleActiveCurrentDesignStep={handleActiveCurrentDesignStep}
              handleShowObstacleTableDeparture={handleShowObstacleTableDeparture}
              handleChangeAltitudeDeparture={handleChangeAltitudeDeparture}
              handleChangePDGDeparture={handleChangePDGDeparture}
              isShowHideOADataDeparture={isShowHideOADataDeparture}
              handleChangeWaypoint={handleChangeWaypoint}
              selectedWayPointMarker={selectedWayPointMarker}
              handleViewSelectWaypointOnMap={handleViewSelectWaypointOnMap}
              handleActiveFlyBtn={handleActiveFlyBtn}
              handleEndWaypointLat={handleEndWaypointLat}
              handleEndWaypointLng={handleEndWaypointLng}
              handleAddNewLeg={handleAddNewLeg}
              activeFlyBtn={activeFlyBtn}
              endSelectNavaidWaypoint={endSelectNavaidWaypoint}
              endWaypointLat={endWaypointLat}
              endWaypointLng={endWaypointLng}
              handleEnterLatLngViewOnMap={handleEnterLatLngViewOnMap}
              handleCodingTable={handleCodingTable}
              isactiveCodingTable={isactiveCodingTable}
              oaLegCheckedKey={oaLegCheckedKey}
              handleGeneratePADataDeparture={handleGeneratePADataDeparture}
              handleGenerateOADataDeparture={handleGenerateOADataDeparture}
              activeOALegDesignDataDeparture={activeOALegDesignDataDeparture}
              handleCreateNewLegDataClear={handleCreateNewLegDataClear}
              selectedPathTerminatorDeparture={selectedPathTerminatorDeparture}
              pathTerminatorOptions={pathTerminatorOptions}
              handleChangePathTerminatorDeparture={handleChangePathTerminatorDeparture}
              changeCWYPointInfoDataDeparture={changeCWYPointInfoDataDeparture}
              handleChangeCWYSetInitInfoDataDeparture={handleChangeCWYSetInitInfoDataDeparture}
              cwyDisDeparture={cwyDisDeparture}
              endAltitudeDeparture={endAltitudeDeparture}
              pdgDeparture={pdgDeparture}
              handleChangeCWYdisInputDeparture={handleChangeCWYdisInputDeparture}
              iasSpeedDeparture={iasSpeedDeparture}
              tasSpeedDeparture={tasSpeedDeparture}
              areasemiwidthDeparture={areasemiwidthDeparture}
              gradLengthDeparture={gradLengthDeparture}
              turnDirectionDeparture={turnDirectionDeparture}
              turnAngleDeparture={turnAngleDeparture}
              activeLegDesignDataDeparture={activeLegDesignDataDeparture}
              activePrevLegDesignDataDeparture={activePrevLegDesignDataDeparture}
              temperature={temperature}
              handleOnChangeDataReviewIAS={handleOnChangeDataReviewIAS}
              handleClickOnSpeedTabDeparture={handleClickOnSpeedTabDeparture}
              handleShowAllProtectionArea={handleShowAllProtectionArea}
              palegDatacheckedList={palegDatacheckedList}
              isCheckedPASwitch={isCheckedPASwitch}
              handleChangeProtectionAreaLegSwitch={handleChangeProtectionAreaLegSwitch}
              handleChangeOALegSwitch={handleChangeOALegSwitch}
              handleChangePAlegData={handleChangePAlegData}
              legOAData={legObstacleAnalysis}
              gradHeightDeparture={gradHeightDeparture}
              handleDeleteLegData={handleDeleteLegData}
              handleShowTerrainLayerDeparture={handleShowTerrainLayerDeparture}
              radiusOfTurn={radiusOfTurn}
              CFCourseDept={CFCourseDept}
              courseRangeDataDept={courseRangeDataDept}
              handleChangeCFCourseDept={handleChangeCFCourseDept}
              handleRefreshGeneratedOAdata={handleRefreshGeneratedOAdata}
              /* Omni Props START */
              isShowHideTerrainLayerOMNI={isShowHideTerrainLayerOMNI}
              cwyDisOMNI={cwyDisOMNI}
              handleOMNIChangeCWYdisInput={handleOMNIChangeCWYdisInput}
              initStartInfoOMNI={initStartInfoOMNI}
              handleChangeCWYSetInitInfoDataOMNI={handleChangeCWYSetInitInfoDataOMNI}
              changeCWYPointInfoDataOMNI={changeCWYPointInfoDataOMNI}
              handleChangeLengthOMNI={handleChangeLengthOMNI}
              endAltGradLengthOMNI={endAltGradLengthOMNI}
              changeLengthPointInfoDataOMNI={changeLengthPointInfoDataOMNI}
              handleClickEndLatLngViewOnMapOMNI={handleClickEndLatLngViewOnMapOMNI}
              handleOnChangeDataReviewIASOMNI={handleOnChangeDataReviewIASOMNI}
              iasSpeedOMNI={iasSpeedOMNI}
              tasSpeedOMNI={tasSpeedOMNI}
              handleGeneratePADataOMNI={handleGeneratePADataOMNI}
              activeLegDesignDataOMNI={activeLegDesignDataOMNI}
              handleGenerateOADataOMNI={handleGenerateOADataOMNI}
              activeOALegDesignDataOMNI={activeOALegDesignDataOMNI}
              handleShowObstacleTableOMNI={handleShowObstacleTableOMNI}
              isShowHideOADataOMNI={isShowHideOADataOMNI}
              handleChangeOmniEndPDG={handleChangeOmniEndPDG}
              handleChangeOmniEndAltGrad={handleChangeOmniEndAltGrad}
              handleChangeOmniEndLength={handleChangeOmniEndLength}
              disableEndPointFieldOMNI={disableEndPointFieldOMNI}
              handleEndDisableFieldOMNI={handleEndDisableFieldOMNI}
              pdgOMNI={pdgOMNI}
              endAltitudeOMNI={endAltitudeOMNI}
              handleClickOnSpeedTabOmni={handleClickOnSpeedTabOmni}
              handleShowTerrainLayerOMNI={handleShowTerrainLayerOMNI}
              handleShowLengthAltPDGRangeOMNI={handleShowLengthAltPDGRangeOMNI}
              isLengthAltPDGRangeOMNI={isLengthAltPDGRangeOMNI}
              /* Omni Props END */
              activeMapElement={activeMapElement}
              handleShowHideObstacleLayer={handleShowHideObstacleLayer}
              handleShowHideTerrainLayer={handleShowHideTerrainLayer}
              isTerminatingWaypoint={isTerminatingWaypoint}
              handleChangeTerminatingWaypoint={handleChangeTerminatingWaypoint}
              handleChangeRemarksDepOmni={handleChangeRemarksDepOmni}
              handleAddUpdateRemarkDepOmni={handleAddUpdateRemarkDepOmni}
              isShowHideRemarkDepOmniBtn={isShowHideRemarkDepOmniBtn}
              isLoadingAddRemarkData={isLoadingAddRemarkData}
              remarksDepOmni={remarksDepOmni}
              isShowHideObstacleLayer={isShowHideObstacleLayer}
              isShowHideTerrainLayer={isShowHideTerrainLayer}
            />
          ) : (
            <DesignSidebar
              designid={designid}
              orgid={userSession?.organisationid || null}
              selectedMetaId={selectedMetaId}
              designProcedurelist={designProcedurelist}
              selectedProcedureId={selectedProcedureId}
              selectedProcedureData={selectedProcedureData}
              handleOnStartDesign={handleOnStartDesign}
              handleSelectedProcedureId={handleSelectedProcedureId}
              handleLoading={handleLoading}
            />
          )}
        </Sider>
      </Layout>
      <ContentScreenLoaderV1
        loading={
          isLoadingDesign ||
          isLoadingLegsData ||
          isLoadingAllMetadata ||
          isLoadingInitStartInfo ||
          isLoadingDesignMetadataByMetaid ||
          isLoadingDesignProcedureVersion ||
          isLoadingProcedureList ||
          isLoadingWaypoints ||
          isLoadingInfoBtwPoints ||
          isLoadingRefrenceline ||
          isLoadingAreasemiwidth ||
          isLoadingLegdatabyLegId ||
          isLoadingAddLegdata ||
          // isLoadingSnappoint ||
          isLoadingAddOAData ||
          loading ||
          isLoadingCourseRange ||
          isLoadingComputeV1Point ||
          isLoadingSilentInfoBtwPoint ||
          isLoadingSilentDesignProcedureVersions ||
          isLoadingPathTerminatorsChoices
        }
        title={isLoadingSilentDesignProcedureVersions ? 'Preparing Procedure...' : 'Loading...'}
      />
      <DeleteLegModal
        isOpenDeleteModal={isShowHideDeleteLegModal}
        deleteLeg={`LEG ${deleteLegKey}`}
        description={
          !isEmpty(designProcedureVersionsData) && designProcedureVersionsData[0]?.is_terminal
            ? `<span>Are you sure you want to delete <b> ${`&ldquo;`} LEG ${deleteLegKey} ${`&rdquo;`}</b> that you have selected as a Terminating Leg?</span><br /><span>All the changes made to this leg will be lost.</span>`
            : `<span>Are you sure you want to delete <b> &ldquo; LEG ${deleteLegKey} &rdquo;</b>? </span><br /><span> All the changes made to this leg will be lost.</span>`
        }
        isLoading={isLoadingDeleteLegData}
        handleDeleteLeg={handleDeleteLeg}
        handleCancelDeleteLeg={handleCancelDeleteLeg}
      />
      {isObsTerrainFullScreenTable && (
        <DesignTableFullScreenView
          isObsTerrainFullScreenTable={isObsTerrainFullScreenTable}
          isOATableOmniShow={isOATableOmniShow}
          isOATableDepartureShow={isOATableDepartureShow}
          obstacleData={legObstacleAnalysis}
          codingData={!isEmpty(codingTableData) ? codingTableData : []}
          isactiveCodingTable={isactiveCodingTable}
          isLoadingCodingTableData={isLoadingCodingTableData}
          activeObscleTerrainDataKey={activeObscleTerrainDataKey}
          oaLegCheckedKey={oaLegCheckedKey}
          showDataLayerId={showDataLayerId}
          availableADData={availableADData}
          adItem={adItem}
          surveyObstacleData={surveyObstacleData}
          isShowClosingObstacle={isShowClosingObstacle}
          handleCodingTable={handleCodingTable}
          handleShowAeroTable={handleShowAeroTable}
          handleShowFullScreenTable={handleShowFullScreenTable}
        />
      )}

      <DesignDataFullScreenMap
        finalArcCords={finalArcCords}
        mapZoom={mapZoom}
        isFullScreenMapView={isFullScreenMapView}
        isStartDesign={isStartDesign}
        initStartInfoDeparture={initStartInfoDeparture}
        changeCWYPointInfoDataDeparture={changeCWYPointInfoDataDeparture}
        activePrevLegDesignDataDeparture={activePrevLegDesignDataDeparture}
        protectionAreaCheckList={protectionAreaCheckList}
        mapCenter={mapCenter}
        featureGroupRef={featureGroupRef}
        isCreateWaypointBool={isCreateWaypointBool}
        mapType={mapType}
        isShowAeroTable={isShowAeroTable}
        activeMapTools={activeMapTools}
        pointLatLng={pointLatLng}
        newPolyLineCoords={newPolyLineCoords}
        centroidPoint={centroidPoint}
        isBool={isBool}
        endSelectNavaidWaypoint={endSelectNavaidWaypoint}
        activeFlyBtn={activeFlyBtn}
        endWaypointLat={endWaypointLat}
        endWaypointLng={endWaypointLng}
        isViewOnMapEnterWaypoint={isViewOnMapEnterWaypoint}
        activeMapElement={activeMapElement}
        waypointsData={waypointsData}
        legsData={legsData}
        legsDataOMNI={legsDataOMNI}
        activeLeg={activeLeg}
        activeDesignStep={activeDesignStep}
        DERCoordsDeparture={DERCoordsDeparture}
        showDataLayerId={showDataLayerId}
        endPointLatlngOMNI={endPointLatlngOMNI}
        isShowHideOADataOMNI={isShowHideOADataOMNI}
        isShowHideOADataDeparture={isShowHideOADataDeparture}
        activeOALegDesignDataOMNI={activeOALegDesignDataOMNI}
        activeOALegDesignDataDeparture={activeOALegDesignDataDeparture}
        selectedDesign={selectedDesign}
        selectWaypointData={selectWaypointData}
        isFetchingWaypoint={isFetchingWaypoint}
        selectedWayPointMarker={selectedWayPointMarker}
        isShowWayPointOnMapDeparture={isShowWayPointOnMapDeparture}
        endWaypointLatLngDeparture={endWaypointLatLngDeparture}
        legsDataDeparture={legsDataDeparture}
        DERCoordsOMNI={DERCoordsOMNI}
        refrenceLineData={refrenceLineData}
        overseeLineData={overseeLineData}
        legObstacleAnalysis={legObstacleAnalysis}
        isShowHideOAData={isShowHideOAData}
        isShowClosingObstacle={isShowClosingObstacle}
        legWindspiralsData={legWindspiralsData}
        activeObscleTerrainDataKey={activeObscleTerrainDataKey}
        activeTerrainLegDesignDataDeparture={activeTerrainLegDesignDataDeparture}
        activeTerrainLegDesignDataOMNI={activeTerrainLegDesignDataOMNI}
        isShowHideTerrainLayerDeparture={isShowHideTerrainLayerDeparture}
        availableAirspaceData={availableAirspaceData}
        isShowHideTerrainLayerOMNI={isShowHideTerrainLayerOMNI}
        selectedProcedureData={selectedProcedureData}
        activeLegDesignDataDeparture={activeLegDesignDataDeparture}
        newLegDERCoordsDeparture={newLegDERCoordsDeparture}
        arp={arp}
        selectedPathTerminatorDeparture={selectedPathTerminatorDeparture}
        CFCourseDept={CFCourseDept}
        airspaceDataLabel={airspaceDataLabel}
        palegDatacheckedList={palegDatacheckedList}
        oaLegCheckedKey={oaLegCheckedKey}
        availableADData={availableADData}
        surveyObstacleData={surveyObstacleData}
        terrainLayerName={terrainLayerName}
        nominalCircles={nominalCircles}
        arcEndCoord={arcEndCoord}
        arcCenterCoord={arcCenterCoord}
        activeLegDesignDataOMNI={activeLegDesignDataOMNI}
        temperature={temperature}
        magneticDeclination={magneticDeclination}
        isShowHideTerrainLayer={isShowHideTerrainLayer}
        isShowHideObstacleLayer={isShowHideObstacleLayer}
        initInfoDesignData={initInfoDesignData}
        handleOnClickMapTools={handleOnClickMapTools}
        handleOnClickMapElement={handleOnClickMapElement}
        handleShowHideDataLayer={handleShowHideDataLayer}
        handleSelectedWaypointMarkerOnClick={handleSelectedWaypointMarkerOnClick}
        handleCreateWaypointLatLng={handleCreateWaypointLatLng}
        handleCallAreaSemiwidth={handleCallAreaSemiwidth}
        handleOnCreateShape={handleOnCreateShape}
        handleOnMounted={handleOnMounted}
        handleMapType={handleMapType}
        handleFullScreenMapView={handleFullScreenMapView}
      />
      {/* <ConfirmationModal
        isOpen={isShowCFPathTerminatorConfirmation}
        isLoading={false}
        height={190}
        width={450}
        okBtnText="Confirm"
        title="Sure! You select Course to a fix (CF) Path Terminator"
        description="Are you sure you want to Select Course to a fix (CF) ? You will not be able to change the above selection once created."
        handleSuccess={handleConfirmPathTerminator}
        handleCancel={handleCancelPathTerminator}
      /> */}
    </>
  );
};

export default Designdata;

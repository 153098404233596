import { Marker, Tooltip } from 'react-leaflet';
import { FlybyWaypointLightIcon, FlyoverWaypointLightIcon } from 'constants/map.constants';
import { useMemo } from 'react';

interface EnterWaypointProps {
  endWaypointName: string | undefined;
  activeFlyBtn: string;
  endWaypointLat: number | null;
  endWaypointLng: number | null;
}

const EnterWaypoint = ({
  endWaypointName,
  activeFlyBtn,
  endWaypointLat,
  endWaypointLng
}: EnterWaypointProps) => {
  if (!endWaypointLat && !endWaypointLng) return null;

  const waypointMarker = useMemo(() => {
    if (!endWaypointLat && !endWaypointLng) return null;
    return (
      <Marker
        icon={activeFlyBtn === 'over' ? FlyoverWaypointLightIcon : FlybyWaypointLightIcon}
        // @ts-ignore
        position={[endWaypointLat, endWaypointLng]}
      >
        <Tooltip
          direction="left"
          className="marker-sticky-tooltip"
          offset={[-5, 0]}
          opacity={1}
          permanent
        >
          {endWaypointName || 'XXXXX'}
        </Tooltip>
      </Marker>
    );
  }, [activeFlyBtn, endWaypointLat, endWaypointLng, endWaypointName]);

  // eslint-disable-next-line
  return <>{waypointMarker}</>;
};

export default EnterWaypoint;

import { createSlice } from '@reduxjs/toolkit';
import { UserState } from './types';

const initialState: UserState = {
  accessToken: null,
  refreshToken: null,
  userSession: null,
  rsaKey: null,
  organisation: null,
  organisationMembers: []
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setRSA: (state, action) => {
      state.rsaKey = action.payload;
    },
    setUser: (state, action) => {
      state.userSession = action.payload;
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    setRefreshToken: (state, action) => {
      state.refreshToken = action.payload;
    },
    setOrganisation: (state, action) => {
      state.organisation = action.payload;
    },
    setOrganisationMembers: (state, action) => {
      state.organisationMembers = action.payload;
    },
    clearUser: () => {
      return initialState;
    }
  }
});

import { white } from 'constants/color.constant';
import { IconProps } from 'types/common.types';

const FullScreenIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0H24V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V0Z"
        fill="#42444A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0711 6.75C14.0711 7.16421 14.4069 7.5 14.8211 7.5L17.3137 7.5V9.99264C17.3137 10.4069 17.6495 10.7426 18.0637 10.7426C18.478 10.7426 18.8137 10.4069 18.8137 9.99264V6.75C18.8137 6.33579 18.478 6 18.0637 6L14.8211 6C14.4069 6 14.0711 6.33579 14.0711 6.75Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0637 14.0711C17.6495 14.0711 17.3137 14.4069 17.3137 14.8211L17.3137 17.3137L14.8211 17.3137C14.4069 17.3137 14.0711 17.6495 14.0711 18.0637C14.0711 18.4779 14.4069 18.8137 14.8211 18.8137L18.0637 18.8137C18.4779 18.8137 18.8137 18.4779 18.8137 18.0637L18.8137 14.8211C18.8137 14.4069 18.4779 14.0711 18.0637 14.0711Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75003 14.0711C7.16424 14.0711 7.50003 14.4069 7.50003 14.8211L7.50003 17.3137L9.99267 17.3137C10.4069 17.3137 10.7427 17.6495 10.7427 18.0637C10.7427 18.4779 10.4069 18.8137 9.99267 18.8137L6.75003 18.8137C6.33581 18.8137 6.00003 18.4779 6.00003 18.0637L6.00003 14.8211C6.00003 14.4069 6.33581 14.0711 6.75003 14.0711Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7426 6.75C10.7426 7.16421 10.4069 7.5 9.99264 7.5L7.5 7.5L7.5 9.99264C7.5 10.4069 7.16421 10.7426 6.75 10.7426C6.33579 10.7426 6 10.4069 6 9.99264L6 6.75C6 6.33579 6.33579 6 6.75 6L9.99264 6C10.4069 6 10.7426 6.33579 10.7426 6.75Z"
        fill={color}
      />
    </svg>
  );
};
FullScreenIcon.defaultProps = {
  color: white,
  width: 24,
  height: 24,
  onClick: () => {}
};

export default FullScreenIcon;

import L from 'leaflet';
import LightLocationIcon from 'assets/svg/LightLocationIcon.svg';
import LocationIcon from 'assets/svg/LocationIcon.svg';
import Triangle from 'assets/svg/TriangleIcon.svg';
import BlueTriangleIcon from 'assets/svg/BlueTriangleIcon.svg';
import FlybyWaypoint from 'assets/svg/FlybyWaypointIcon.svg';
import FlybyWaypointLight from 'assets/svg/FlybyWaypointLightIcon.svg';
import ObstaclePoint from 'assets/svg/ObstacleIcon.svg';
import ObstacleRedPoint from 'assets/svg/ObstacleRedIcon.svg';
import ObstacleWhiteRedPoint from 'assets/svg/ObstacleWhiteRedIcon.svg';
import FlyoverWaypoint from 'assets/svg/FlyoverWaypointIcon.svg';
import FlyoverWaypointLight from 'assets/svg/FlyoverWaypointLightIcon.svg';
import AerodromePoint from 'assets/svg/AerodromePointIcon.svg';
import AerodromePointBlack from 'assets/svg/AerodromePointBlackIcon.svg';
import LightTriangle from 'assets/svg/LightTriangleIcon.svg';
import RedTriangle from 'assets/svg/RedTriangleIcon.svg';
import DMEImg from 'assets/png/DME.png';
import NDBImg from 'assets/png/NDB.png';
import VORImg from 'assets/png/VOR.png';
import VORDMEImg from 'assets/png/VOR_DME.png';
import ReportingPointImg from 'assets/svg/reporting-point.svg';
import RNAVCNAVImg from 'assets/png/RNAVCNAVImg.png';
import ArrowleftIconV1 from 'assets/svg/ArrowleftIconV1.svg';

export const RoundLocationIconWhite = L.icon({
  iconUrl: LocationIcon,
  iconSize: [25, 25],
  className: 'round-loc-icon'
});

export const RoundLocationIconBlack = L.icon({
  iconUrl: LocationIcon,
  iconSize: [25, 25]
});

export const LightLocation = L.icon({
  iconUrl: LightLocationIcon,
  iconSize: [18, 28],
  iconAnchor: [13, 25]
});

export const TriangleIcon = L.icon({
  iconUrl: Triangle,
  iconSize: [22, 22],
  className: 'light-triangle-icon'
});

export const BlueTrianglePointIcon = L.icon({
  iconUrl: BlueTriangleIcon,
  iconSize: [22, 22]
});

export const LightTriangleIcon = L.icon({
  iconUrl: LightTriangle,
  iconSize: [22, 22],
  className: 'light-triangle-icon'
});

export const RedTriangleIcon = L.icon({
  iconUrl: RedTriangle,
  iconSize: [22, 22],
  className: 'light-triangle-icon'
});

export const FlyoverWaypointIcon = L.icon({
  iconUrl: FlyoverWaypoint,
  iconSize: [18, 18]
});

export const FlyoverWaypointLightIcon = L.icon({
  iconUrl: FlyoverWaypointLight,
  iconSize: [18, 18]
});

export const FlybyWaypointIcon = L.icon({
  iconUrl: FlybyWaypoint,
  iconSize: [18, 18]
});

export const FlybyWaypointLightIcon = L.icon({
  iconUrl: FlybyWaypointLight,
  iconSize: [18, 18]
});

export const ObstacleIcon = L.icon({
  iconUrl: ObstaclePoint,
  iconSize: [18, 18]
});

export const ObstacleRedIcon = L.icon({
  iconUrl: ObstacleRedPoint,
  iconSize: [18, 18]
});

export const ObstacleWhiteRedIcon = L.icon({
  iconUrl: ObstacleWhiteRedPoint,
  iconSize: [22, 22]
});

export const AerodromePointIcon = L.icon({
  iconUrl: AerodromePoint,
  iconSize: [18, 18]
});

export const AerodromePointBlackIcon = L.icon({
  iconUrl: AerodromePointBlack,
  iconSize: [18, 18]
});

export const ReportingPointImgIcon = L.icon({
  iconUrl: ReportingPointImg,
  iconSize: [20, 20]
});

export const DMEImgIcon = L.icon({
  iconUrl: DMEImg,
  iconSize: [27, 20]
});

export const NDBImgIcon = L.icon({
  iconUrl: NDBImg,
  iconSize: [40, 40]
});

export const VORImgIcon = L.icon({
  iconUrl: VORImg,
  iconSize: [25, 25]
});

export const VORDMEImgIcon = L.icon({
  iconUrl: VORDMEImg,
  iconSize: [27, 20]
});

export const RNAVCNAVIcon = L.icon({
  iconUrl: RNAVCNAVImg,
  iconSize: [18, 18]
});

export const ARPLineArrowIcon = L.icon({
  iconUrl: ArrowleftIconV1,
  iconSize: [20, 20]
});

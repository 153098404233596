import ReportSecTitleWithIcon from './ReportSecTitleWithIcon';
import SignificantDataTable from './SignificantDataTable';

interface Section13Props {
  scrollElementRef: any;
  sec13: any;
}

const Section13 = ({ scrollElementRef, sec13 }: Section13Props) => {
  return (
    <div
      className="report-code-content preview-significant-main page-break"
      ref={scrollElementRef}
      id="significant"
    >
      <ReportSecTitleWithIcon title="13. SIGNIFICANT OBSTACLE DATA" icon={null} />
      <div className="report-content-data">
        <div className="coding-table-content">
          <div className="obstacle-table">
            <SignificantDataTable significantData={sec13 || []} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section13;

import * as turf from '@turf/turf';
import { getEndPointHeight } from './utils';
import { isEmpty, reverse } from 'lodash';

export const vinc = (latitude1: number, longitude1: number, alpha1To2: number, s: number) => {
  if (s === 0) {
    return [latitude1, longitude1];
  }
  const f = 1.0 / 298.257223563; // WGS84
  const a = 6378137.0; // metres

  const piD4 = Math.atan(1.0);
  const two_pi = piD4 * 8.0;

  latitude1 = (latitude1 * piD4) / 45.0;
  longitude1 = (longitude1 * piD4) / 45.0;
  alpha1To2 = (alpha1To2 * piD4) / 45.0;
  if (alpha1To2 < 0.0) {
    alpha1To2 += two_pi;
  }
  if (alpha1To2 > two_pi) {
    alpha1To2 -= two_pi;
  }

  const b = a * (1.0 - f);

  const TanU1 = (1 - f) * Math.tan(latitude1);
  const U1 = Math.atan(TanU1);
  const sigma1 = Math.atan2(TanU1, Math.cos(alpha1To2));
  const Sinalpha = Math.cos(U1) * Math.sin(alpha1To2);
  const cosalpha_sq = 1.0 - Sinalpha * Sinalpha;

  const u2 = (cosalpha_sq * (a * a - b * b)) / (b * b);
  const A = 1.0 + (u2 / 16384) * (4096 + u2 * (-768 + u2 * (320 - 175 * u2)));
  const B = (u2 / 1024) * (256 + u2 * (-128 + u2 * (74 - 47 * u2)));

  // Starting with the approximation
  let sigma = s / (b * A);

  let last_sigma = 2.0 * sigma + 2.0; // something impossible

  // Iterate the following three equations
  // until there is no significant change in sigma

  // two_sigma_m , delta_sigma
  const two_sigma_m = 2 * sigma1 + sigma;
  while (Math.abs((last_sigma - sigma) / sigma) > 1.0e-9) {
    const delta_sigma =
      B *
      Math.sin(sigma) *
      (Math.cos(two_sigma_m) +
        (B / 4) *
          (Math.cos(sigma) *
            (-1 +
              2 * Math.cos(two_sigma_m) ** 2 -
              (B / 6) *
                Math.cos(two_sigma_m) *
                (-3 + 4 * Math.sin(sigma) ** 2) *
                (-3 + 4 * Math.cos(two_sigma_m) ** 2))));

    last_sigma = sigma;
    sigma = s / (b * A) + delta_sigma;
  }

  let latitude2 = Math.atan2(
    Math.sin(U1) * Math.cos(sigma) + Math.cos(U1) * Math.sin(sigma) * Math.cos(alpha1To2),
    (1 - f) *
      Math.sqrt(
        Sinalpha ** 2 +
          (Math.sin(U1) * Math.sin(sigma) - Math.cos(U1) * Math.cos(sigma) * Math.cos(alpha1To2)) **
            2
      )
  );

  const lembda = Math.atan2(
    Math.sin(sigma) * Math.sin(alpha1To2),
    Math.cos(U1) * Math.cos(sigma) - Math.sin(U1) * Math.sin(sigma) * Math.cos(alpha1To2)
  );

  const C = (f / 16) * cosalpha_sq * (4 + f * (4 - 3 * cosalpha_sq));

  const omega =
    lembda -
    (1 - C) *
      f *
      Sinalpha *
      (sigma +
        C *
          Math.sin(sigma) *
          (Math.cos(two_sigma_m) + C * Math.cos(sigma) * (-1 + 2 * Math.cos(two_sigma_m) ** 2)));

  let longitude2 = longitude1 + omega;

  let alpha21 = Math.atan2(
    Sinalpha,
    -Math.sin(U1) * Math.sin(sigma) + Math.cos(U1) * Math.cos(sigma) * Math.cos(alpha1To2)
  );

  alpha21 += two_pi / 2.0;
  if (alpha21 < 0.0) {
    alpha21 += two_pi;
  }
  if (alpha21 > two_pi) {
    alpha21 -= two_pi;
  }

  latitude2 = (latitude2 * 45.0) / piD4;
  longitude2 = (longitude2 * 45.0) / piD4;
  alpha21 = (alpha21 * 45.0) / piD4;

  return [latitude2, longitude2];
};

export const normalizeABBearing = (startpoint: number[], endpoint: number[]) => {
  const bearing = turf.bearing(startpoint, endpoint);

  // Calculate the remainder when dividing by 360
  let normalized = bearing % 360;

  // Adjust negative remainders to be positive
  if (normalized < 0) {
    normalized += 360;
  }

  return normalized;
};

export const normalizeBearing = (bearing: number) => {
  // Calculate the remainder when dividing by 360
  let normalized = bearing % 360;

  // Adjust negative remainders to be positive
  if (normalized < 0) {
    normalized += 360;
  }

  return normalized;
};

export const determineTurn = (inboundBearing: number, outboundBearing: number) => {
  // Normalize bearings to be between 0 and 360
  const inBearing = inboundBearing % 360;
  const outBearing = outboundBearing % 360;

  // Calculate the angle between the bearings
  const angle = (outBearing - inBearing + 360) % 360;

  // Determine the type of turn based on the angle
  if (angle <= 180) {
    return 'right';
  }
  return 'left';
};

export const changebearing = (turnDirection: string, turnAngle: number, bearing: number) => {
  let final;
  if (turnDirection === 'left') {
    if (bearing - turnAngle < 0) {
      final = bearing - turnAngle + 360;
    } else {
      final = bearing - turnAngle;
    }
  } else if (bearing + turnAngle < 360) {
    final = bearing + turnAngle;
  } else {
    final = bearing + turnAngle - 360;
  }

  return normalizeBearing(parseFloat(final.toFixed(6)));
};

export const findQuadrant = (initialBearing: number, inputBearing: number) => {
  // Normalize bearings to be between 0 and 360
  const initBearing = initialBearing % 360;
  const inpuBearing = inputBearing % 360;
  // Calculate the angle between initial bearing and input bearing
  const angle = (inpuBearing - initBearing + 360) % 360;

  // Determine quadrant based on the angle
  if (angle < 90) {
    return 1;
    // eslint-disable-next-line
  } else if (angle < 180) {
    return 2;
  } else if (angle < 270) {
    return 3;
  } else return 4;
};

export const drawArcBetweenPoints = (latlng: any, activePrevLegDesignDataDeparture: any) => {
  const startPoint = [
    activePrevLegDesignDataDeparture.end_waypoint.lng,
    activePrevLegDesignDataDeparture.end_waypoint.lat
  ];
  const endPoint = [latlng[1], latlng[0]];

  const inBoundBearing = activePrevLegDesignDataDeparture.parameters.bearing;

  const bearingAB = normalizeABBearing(startPoint, endPoint);

  const turnDir = determineTurn(inBoundBearing, bearingAB);

  const axisBearing = changebearing(turnDir, 90, inBoundBearing);

  const perpendiCularLineEndPoint = vinc(
    activePrevLegDesignDataDeparture.end_waypoint.lat,
    activePrevLegDesignDataDeparture.end_waypoint.lng,
    axisBearing,
    100000
  );

  const perpendicularAxisLine = turf.lineString([
    startPoint,
    [perpendiCularLineEndPoint[1], perpendiCularLineEndPoint[0]]
  ]);

  const midpointCollection = turf.midpoint(startPoint, endPoint);

  const midpoint = midpointCollection.geometry.coordinates;

  const quadrant = findQuadrant(inBoundBearing, bearingAB);

  let cordTurnDirection = 'left';

  if (quadrant === 1 || quadrant === 3) {
    cordTurnDirection = 'right';
  }

  const cordBearing = changebearing(cordTurnDirection, 90, bearingAB);

  const perpendicularcordEndLatLong = vinc(midpoint[1], midpoint[0], cordBearing, 100000);

  const perpendicularCordCollection = turf.lineString([
    midpoint,
    [perpendicularcordEndLatLong[1], perpendicularcordEndLatLong[0]]
  ]);

  const centerPointCollection = turf.lineIntersect(
    perpendicularAxisLine,
    perpendicularCordCollection
  );

  if (isEmpty(centerPointCollection.features)) {
    return;
  }
  const centerPoint = centerPointCollection.features[0].geometry.coordinates;

  let degreesOFturn =
    (normalizeABBearing(centerPoint, startPoint) -
      normalizeABBearing(centerPoint, endPoint) +
      360) %
    360;
  if (quadrant === 2 || quadrant === 3) {
    if (degreesOFturn < 180) {
      degreesOFturn = 360 - degreesOFturn;
    }
  } else if (degreesOFturn > 180) {
    degreesOFturn = 360 - degreesOFturn;
  }

  if (degreesOFturn <= 2) {
    return;
  }

  const arcPoints = [startPoint];

  const reversePerpendicularAxis = normalizeABBearing(centerPoint, startPoint);

  let i = 0.1;

  const radiusCenterStart = turf.distance(centerPoint, startPoint) * 1000; // meters
  const radiusCenterEnd = turf.distance(centerPoint, endPoint) * 1000; // meters

  const radiusDiff = Math.abs(radiusCenterStart - radiusCenterEnd); // meters

  let adjustment;

  if (radiusDiff > 0) {
    adjustment = -1; // Subtract gradually
  } else if (radiusDiff < 0) {
    adjustment = 1; // Add gradually
  } else {
    adjustment = 0; // No adjustment needed
  }

  adjustment = (adjustment * (Math.abs(radiusDiff) * i)) / degreesOFturn;

  let adjustment1 = 0;
  while (i < parseFloat(degreesOFturn.toFixed(1))) {
    adjustment1 += adjustment;
    const newPoint = vinc(
      centerPoint[1],
      centerPoint[0],
      changebearing(turnDir, i, reversePerpendicularAxis),
      radiusCenterStart + adjustment1
    );

    arcPoints.push([newPoint[1], newPoint[0]]);
    i += 0.1;
  }

  arcPoints.push(endPoint);

  const outboundBearing = changebearing(turnDir, -90, normalizeABBearing(endPoint, centerPoint));

  const arcLength = (2 * (22 / 7) * radiusCenterStart * (degreesOFturn / 360)) / 1852; // NM

  // const finalArcData = arcPoints.map((data: any) => reverse(data));
  const endheight = getEndPointHeight(arcLength, 3.3); // ft

  // eslint-disable-next-line
  return {
    startPointCoord: [
      activePrevLegDesignDataDeparture.end_waypoint.lat,
      activePrevLegDesignDataDeparture.end_waypoint.lng
    ],
    arcCoords: arcPoints,
    endPointCoord: latlng,
    arclength: arcLength,
    turnAngle: degreesOFturn,
    radiusOfTurn: radiusCenterEnd / 1852, // NM
    outBoundtrack: outboundBearing,
    endHeight: endheight,
    endAltitude: activePrevLegDesignDataDeparture.end_waypoint.alt + endheight,
    centerPointCoord: reverse(centerPoint),
    turnDirection: turnDir
  };
};

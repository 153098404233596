import { primary } from 'constants/color.constant';
import { IconProps } from 'types/common.types';

const SoftwareIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4C4 3.447 4.448 3 5 3C5.552 3 6 3.447 6 4C6 4.553 5.552 5 5 5C4.448 5 4 4.553 4 4ZM8 4C8 3.447 8.448 3 9 3C9.552 3 10 3.447 10 4C10 4.553 9.552 5 9 5C8.448 5 8 4.553 8 4ZM2 6V3C2 2.448 2.449 2 3 2H15C15.551 2 16 2.448 16 3V6H2ZM16 15C16 15.552 15.551 16 15 16H3C2.449 16 2 15.552 2 15V8H16V15ZM15 0H3C1.346 0 0 1.346 0 3V6V8V15C0 16.654 1.346 18 3 18H15C16.654 18 18 16.654 18 15V8V6V3C18 1.346 16.654 0 15 0Z"
        fill={color}
      />
    </svg>
  );
};
SoftwareIcon.defaultProps = {
  color: primary,
  width: 18,
  height: 18,
  onClick: () => {}
};

export default SoftwareIcon;

import { Polyline } from 'react-leaflet';
import { isEmpty } from 'lodash';
import { lightGreen, primary } from 'constants/color.constant';

// const { Text } = Typography;

const LineStringComponent = ({
  index,
  coordinates,
  color,
  fillColor,
  weight,
  fillOpacity,
  dashArray,
  pane
}: any) => {
  const coordinateData = coordinates.map((point: any) => {
    return [Number(point[1]), Number(point[0])];
  });
  if (isEmpty(coordinateData)) return null;

  return (
    <Polyline
      key={index}
      // @ts-ignore
      positions={coordinateData}
      pathOptions={{
        color,
        fillColor,
        fillOpacity,
        weight,
        dashArray
      }}
      pane={pane}
    />
    //   <Popup className="polygon-popup">
    //     <Space style={{ display: 'flex', justifyContent: 'center' }}>
    //       <Text className="tooltip-content" style={{ fontFamily: 'Manrope' }}>
    //         No data found
    //       </Text>
    //     </Space>
    //   </Popup>
    // </Polyline>
  );
};
LineStringComponent.defaultProps = {
  color: primary,
  fillColor: lightGreen,
  fillOpacity: 0.3,
  weight: 1,
  dashArray: [0, 0],
  pane: 'shadowPane'
};

export default LineStringComponent;

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './editor.less';

const Editor = ({ editorHtml, handleChange }: any) => {
  return (
    <ReactQuill
      theme="snow"
      onChange={handleChange}
      value={editorHtml}
      modules={Editor.modules}
      formats={Editor.formats}
      placeholder="Type here"
    />
  );
};

Editor.modules = {
  toolbar: [
    ['bold', 'italic'],
    [{ list: 'ordered' }, { list: 'bullet' }]
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false
  }
};

Editor.formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
  'clean'
];

export default Editor;

import { Table } from 'antd';
import type { TableProps } from 'antd';

interface DepartureturnTableProps {
  departureTurnData: any;
}

const DepartureturnTable = ({ departureTurnData }: DepartureturnTableProps) => {
  const defaultColumns: TableProps<any>['columns'] = [
    {
      title: 'LEGS',
      dataIndex: 'legs',
      key: 'legs',
      align: 'center',
      className: 'table-col-data'
    },
    {
      title: 'IAS + 10%',
      dataIndex: 'ias10',
      key: 'ias10',
      align: 'center',
      className: 'table-col-data'
    },
    {
      title: 'End Altitude',
      dataIndex: 'endAlt',
      key: 'endAlt',
      align: 'center',
      className: 'table-col-data',
      render: (t) => (t ? Number(t).toFixed(2) : '-')
    },
    {
      title: 'Speed',
      dataIndex: 'speed',
      key: 'speed',
      align: 'center',
      className: 'table-col-data',
      render: (t) => (t ? Number(t).toFixed(2) : '-')
    },
    {
      title: 'Bank Angle (°)',
      dataIndex: 'bankAngle',
      key: 'bankAngle',
      align: 'center',
      className: 'table-col-data'
    },
    {
      title: 'Radius of Turn (NM)',
      dataIndex: 'radiusTurn',
      key: 'radiusTurn',
      align: 'center',
      className: 'table-col-data',
      render: (t) => (t ? Number(t).toFixed(2) : '-')
    },
    {
      title: 'Rate of Turn (°/s)',
      dataIndex: 'turnRate',
      key: 'turnRate',
      align: 'center',
      className: 'table-col-data',
      render: (t) => (t ? Number(t).toFixed(2) : '-')
    },
    {
      title: 'Angle of Turn (°)',
      dataIndex: 'turnAngle',
      key: 'turnAngle',
      align: 'center',
      className: 'table-col-data',
      render: (t) => (t || t === 0 ? Number(t).toFixed(2) : '-')
    },
    {
      title: 'Earliest Turn Point (NM)',
      dataIndex: 'erliestPoint',
      key: 'erliestPoint',
      align: 'center',
      className: 'table-col-data',
      render: (t) => (t ? Number(t).toFixed(2) : '-')
    },
    {
      title: 'Latest Turn Point (NM)',
      dataIndex: 'latestPoint',
      key: 'latestPoint',
      align: 'center',
      className: 'table-col-data',
      render: (t) => (t ? Number(t).toFixed(2) : '-')
    },
    {
      title: 'TWC (Kt)',
      dataIndex: 'twc',
      key: 'twc',
      align: 'center',
      className: 'table-col-data',
      render: (t) => (t ? Number(t).toFixed(2) : '-')
    }
  ];

  return (
    <Table
      // className="obstacle-table"
      columns={defaultColumns}
      dataSource={departureTurnData}
      pagination={false}
      rowKey={(record) =>
        `${record.twc}-${record.ias10}-${(Math.random() + 1).toString(36).substring(7)}`
      }
      rowClassName={() => 'table-row-data'}
    />
  );
};

export default DepartureturnTable;

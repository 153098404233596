import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { Table, TableProps } from 'antd';
import { AltDataType } from './MinSectorAltitudeTable';
import ReportSecTitleWithIcon from './ReportSecTitleWithIcon';

interface PreviewSection6Props {
  sec6: any;
}

const PreviewSection6 = ({ sec6 }: PreviewSection6Props) => {
  const [altSec6Data, setAltSec6Data] = useState<AltDataType[] | []>([]);
  const [remarkSec6, setRemarkSec6] = useState<string>('');

  useEffect(() => {
    if (!isEmpty(sec6)) {
      const fData = sec6.rows.map((data: AltDataType, index: number) => {
        return {
          ...data,
          key: index
        };
      });
      setAltSec6Data(fData || []);
      setRemarkSec6(sec6.remark || '');
    }
  }, [sec6]);

  const columns: TableProps<any>['columns'] = [
    {
      title: 'Navigation AID / Centre : ARP',
      align: 'left',
      key: 'tol/ac',
      className: 'table-col-data table-tol-col-data',
      children: [
        {
          title: 'Sector / Distance',
          dataIndex: 'sector',
          key: 'sector',
          align: 'center',
          className: 'table-col-data',
          width: 145,
          render: (value) => value || '-'
        },
        {
          title: 'Controlling Obstacle',
          dataIndex: 'obs',
          key: 'obs',
          align: 'center',
          className: 'table-col-data',
          render: (value) => value || '-'
        },
        {
          title: 'Latitude / Longitude',
          dataIndex: 'lat_long',
          key: 'lat_long',
          align: 'center',
          className: 'table-col-data',
          render: (value) => value || '-'
        },
        {
          title: 'Elevation (ft)',
          dataIndex: 'elv',
          key: 'elv',
          align: 'center',
          className: 'table-col-data',
          render: (value) => value || '-'
        },
        {
          title: 'MOC (ft)',
          dataIndex: 'moc',
          key: 'moc',
          align: 'center',
          className: 'table-col-data',
          render: (value) => value || '-'
        },
        {
          title: 'MSA (ft)',
          dataIndex: 'msa',
          key: 'msa',
          align: 'center',
          className: 'table-col-data',
          render: (value) => value || '-'
        },
        {
          title: 'Obstacle Source',
          dataIndex: 'obs_src',
          key: 'obs_src',
          align: 'center',
          className: 'table-col-data',
          render: (value) => value || '-'
        }
      ]
    }
  ];

  return (
    <div className="report-code-content page-break">
      <ReportSecTitleWithIcon title="6. MINIMUM SECTOR ALTITUDE" icon={null} />
      <div className="report-content-data">
        <div className="minsec-alt-content obstacle-table">
          <Table
            columns={columns}
            dataSource={altSec6Data}
            pagination={false}
            rowClassName={() => 'table-row-data table-tol-row-data'}
            // eslint-disable-next-line
            summary={() => {
              return (
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} className="table-row-data remark-row bd-right">
                    REMARK
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={1}
                    colSpan={6}
                    className="table-row-data remark-row remark-row-data"
                  >
                    {remarkSec6 || '-'}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PreviewSection6;

import { primary } from 'constants/color.constant';
import { IconProps } from 'types/common.types';

const EditFileIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="prime:file-edit">
        <path
          id="Vector"
          d="M8 18.75H6.5C5.81 18.75 5.25 18.19 5.25 17.5V5.5C5.25 4.81 5.81 4.25 6.5 4.25H10.25V9C10.25 9.41 10.59 9.75 11 9.75H15.8C15.9 10.04 16.17 10.25 16.5 10.25C16.91 10.25 17.25 9.91 17.25 9.5V9C17.2471 8.80171 17.1684 8.61207 17.03 8.47L11.53 2.97C11.3879 2.83164 11.1983 2.75292 11 2.75H6.5C4.98 2.75 3.75 3.98 3.75 5.5V17.5C3.75 19.02 4.98 20.25 6.5 20.25H8C8.41 20.25 8.75 19.91 8.75 19.5C8.75 19.09 8.41 18.75 8 18.75ZM11.75 5.31L14.69 8.25H11.75V5.31ZM19.61 11.37C19.23 10.99 18.67 10.76 18.09 10.75C17.49 10.72 16.92 10.95 16.54 11.34L10.15 17.74C10.02 17.87 9.95 18.03 9.93 18.21L9.75 20.44C9.73 20.66 9.81 20.88 9.97 21.03C10.11 21.17 10.3 21.25 10.5 21.25H10.57L12.82 21.04C12.9946 21.0235 13.1576 20.9455 13.28 20.82L19.67 14.42C20.47 13.63 20.44 12.2 19.61 11.37ZM18.61 13.36L12.41 19.57L11.32 19.67L11.4 18.61L17.6 12.4C17.7 12.3 17.88 12.26 18.06 12.25C18.26 12.25 18.44 12.32 18.55 12.43C18.79 12.66 18.82 13.15 18.61 13.36Z"
          fill={color}
        />
      </g>
    </svg>
  );
};
EditFileIcon.defaultProps = {
  color: primary,
  width: 24,
  height: 24,
  onClick: () => {}
};

export default EditFileIcon;

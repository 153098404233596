import { useMemo } from 'react';
import { isEmpty } from 'lodash';
import {
  BlueTrianglePointIcon,
  FlybyWaypointIcon,
  FlyoverWaypointIcon,
  TriangleIcon
} from 'constants/map.constants';
import PointComponent from './PointComponent';
import ARPPolyline from './ARPPolyline';
import RFArcLine from './RFArcLine';
import LineTextPath from './LineTextPath';

interface RenderLegDataProps {
  legsData: any;
  activeLegKey: string;
  procedure?: string | null;
  pathTerminator?: string | null;
  isPoint?: boolean;
  magneticDeclination?: number | null;
}

const RenderPALineData = ({
  legsData,
  activeLegKey,
  procedure,
  pathTerminator,
  isPoint: isStartEndPoint,
  magneticDeclination
}: RenderLegDataProps) => {
  if (isEmpty(legsData)) return null;

  const currentLegData = legsData[activeLegKey];
  if (isEmpty(currentLegData)) return null;

  const startWayPoint = currentLegData.start_waypoint;
  const endWayPoint = currentLegData.end_waypoint;
  const primaryData = currentLegData.primary;
  const secondaryData = currentLegData.secondary;
  const { parameters } = currentLegData;

  const startPointIcon =
    startWayPoint && startWayPoint.waypoint_mode === 'by'
      ? FlybyWaypointIcon
      : startWayPoint && startWayPoint.waypoint_mode === 'over'
      ? FlyoverWaypointIcon
      : BlueTrianglePointIcon;

  const endPointIcon =
    endWayPoint && endWayPoint.waypoint_mode === 'by'
      ? FlybyWaypointIcon
      : endWayPoint && endWayPoint.waypoint_mode === 'over'
      ? FlyoverWaypointIcon
      : procedure === 'OMNI'
      ? FlyoverWaypointIcon
      : TriangleIcon;

  const isFictitiousStart = startWayPoint.waypoint_name === 'Fictitious WPT';
  const isFictitiousEnd = endWayPoint.waypoint_name === 'Fictitious WPT';

  const terminator = currentLegData.parameters.type;

  const legData = useMemo(() => {
    return (
      <>
        {startWayPoint && isStartEndPoint && (
          <PointComponent
            pointIcon={startPointIcon}
            index={1}
            geometry={{ coordinates: [startWayPoint.lng, startWayPoint.lat] }}
            properties={null}
            isFictitious={isFictitiousStart}
          />
        )}
        {endWayPoint && isStartEndPoint && (
          <PointComponent
            index={2}
            pointIcon={endPointIcon}
            geometry={{ coordinates: [endWayPoint.lng, endWayPoint.lat] }}
            properties={null}
            isFictitious={isFictitiousEnd}
          />
        )}

        {startWayPoint && endWayPoint && !pathTerminator && terminator !== 'RF' && (
          <ARPPolyline
            lineColor="#696B72"
            arpCoordinates={[
              [startWayPoint.lat, startWayPoint.lng],
              [endWayPoint.lat, endWayPoint.lng]
            ]}
            decoratorIcon={TriangleIcon}
          />
        )}
        {startWayPoint && endWayPoint && (
          <LineTextPath
            coordinates={[
              [endWayPoint.lat, endWayPoint.lng],
              [startWayPoint.lat, startWayPoint.lng]
            ]}
            distance={`${parameters.dst_btw_wpt.toFixed(3)} NM`}
            bearing={`${parameters.bearing.toFixed(3)}° / ${(
              parameters.bearing - magneticDeclination!
            ).toFixed(3)}° M`}
          />
        )}
        {terminator === 'RF' && (
          <RFArcLine finalArcCords={currentLegData?.geo_parameters?.nominal_track || []} />
        )}
      </>
    );
  }, [
    currentLegData,
    activeLegKey,
    startWayPoint,
    endWayPoint,
    primaryData,
    secondaryData,
    procedure,
    isStartEndPoint,
    isFictitiousStart,
    isFictitiousEnd,
    magneticDeclination
  ]);

  // eslint-disable-next-line
  return <>{legData}</>;
};

RenderPALineData.defaultProps = {
  procedure: null,
  isPoint: true,
  pathTerminator: null,
  magneticDeclination: null
};

export default RenderPALineData;

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { find, isEmpty } from 'lodash';
import { Button, Col, Divider, Form, Input, Row, Typography } from 'antd';
import ArrowleftIcon from 'assets/svg/ArrowleftIcon';
import { routes } from 'constants/pageRoutes.constants';
import { aircraftCategoryOptions } from 'constants/designData.constants';
import { statusbuttonText } from 'constants/userData.constants';
import PageHeader from 'components/PageHeader/PageHeader';
import UserOptionItemComponent from 'components/InputComponents/UserOptionItemComponent';
import ContentScreenLoaderV1 from 'components/core/ContentScreenLoaderV1';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { useGetDesignQuery, useUpdateDesignStatusMutation } from 'services/apis/designsApi';
import {
  setADData,
  setAirspaceData,
  setAvailableADData,
  setAvailableAirspaceData,
  setSelectedDesign,
  setTemperatureData
} from 'store/design';
import { UserRole, UserState } from 'store/users/types';
import { OptionsType } from 'types/common.types';
import './styles.less';

const { Text } = Typography;

const Assigndata = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { designid }: any = useParams();
  const { userSession }: UserState = useAppSelector((state) => state.user);
  const isDesigner = Boolean(userSession?.role === UserRole.Designer);
  const {
    data: designData,
    isSuccess: isSuccessDesign,
    isLoading: isLoadingDesign
  } = useGetDesignQuery(
    { orgId: userSession?.organisationid, designId: designid },
    { refetchOnMountOrArgChange: true }
  );
  const [
    updateDesignStatus,
    {
      data: designUpdateStatusData,
      isLoading: isLoadingUpdateStatus,
      isSuccess: isSuccessDesignStatus
    }
  ] = useUpdateDesignStatusMutation();
  const [designer, setDesigner] = useState('');
  const [reviewer, setReviewer] = useState('');
  const [cfnCode, setCfnCode] = useState('');
  const [status, setStatus] = useState('Assigned');

  let initialValues = {
    name: '',
    proceduretype: '',
    navtype: '',
    reason: '',
    airport: '',
    nav_spec: '',
    runway: '',
    runwaydirection: '',
    acft_cat: '',
    goals: '',
    summary: '',
    terrain_tolerance: 0,
    vegetation_tolerance: 0,
    survery_tolerance: 0
  };

  useEffect(() => {
    if (designData) {
      const designRes: any = designData;
      if (designRes.status) {
        dispatch(setSelectedDesign(designRes.data));
        if (isEmpty(designRes.data)) return;
        const acftCat: OptionsType | undefined = find(aircraftCategoryOptions, [
          'value',
          designRes.data.acft_cat
        ]);
        initialValues = {
          name: designRes.data.name,
          proceduretype: designRes.data.procedure_type,
          navtype: designRes.data.nav_type,
          reason: designRes.data.reason,
          airport: designRes.data.airport_name,
          nav_spec: designRes.data.nav_spec,
          runway: designRes.data.runway,
          runwaydirection: designRes.data.runwaydirection,
          acft_cat: acftCat ? acftCat.label : '',
          goals: designRes.data.goals,
          summary: designRes.data.summary,
          terrain_tolerance: designRes.data.terrain_tolerance || 0,
          vegetation_tolerance: designRes.data.vegetation_tolerance || 0,
          survery_tolerance: designRes.data.survery_tolerance || 0
        };
        setStatus(designRes.data.status);
        setCfnCode(designRes.data.cfn);
        setDesigner(designRes.data.designer);
        setReviewer(designRes.data.reviwer);
      }
    }
    form.setFieldsValue(initialValues);
  }, [designData, isSuccessDesign]);

  const handleCallback = () => {
    dispatch(setADData([]));
    dispatch(setAvailableADData(null));
    dispatch(setAirspaceData([]));
    dispatch(setAvailableAirspaceData(null));
    dispatch(setSelectedDesign(null));
    dispatch(setTemperatureData(null));
    navigate(`/collect-data/${designid}`);
  };

  useEffect(() => {
    if (designUpdateStatusData) {
      const designUpdateStatusDataRes: any = designUpdateStatusData;
      if (designUpdateStatusDataRes.status) {
        handleCallback();
      }
    }
  }, [isSuccessDesignStatus, designUpdateStatusData]);

  const handleNavigate = () => {
    setSelectedDesign(null);
    navigate(routes.DesignList);
  };

  const handleSubmit = async () => {
    if (status === 'Assigned') {
      if (!userSession) return;
      await updateDesignStatus({
        orgId: userSession?.organisationid,
        designId: designid,
        designStatus: 'Designing'
      });
    } else {
      handleCallback();
    }
  };

  return (
    <>
      <div className="create-new-design assigned-design">
        <div className="new-design-label">
          <PageHeader icon={<ArrowleftIcon />} title="Assigned Design" onClick={handleNavigate} />
        </div>
        <div className="design-content">
          <div className="cfn-code">
            <Text>CFN Code : {!isEmpty(cfnCode) && cfnCode} </Text>
          </div>
          <Divider className="design-divider" />
          <Form
            form={form}
            layout="vertical"
            autoComplete="off"
            className="design-form"
            onFinish={handleSubmit}
          >
            <div className="form-main">
              <Row gutter={[64, 20]} className="input-main">
                <Col>
                  <Form.Item name="name" label="Name of Procedure Design" className="input-label">
                    <Input className="form-input input-clr" readOnly />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name="proceduretype"
                    label="Type of Procedure Design"
                    className="input-label"
                  >
                    <Input className="form-input input-clr" readOnly />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name="navtype"
                    label="Type of Navigation System"
                    className="input-label"
                  >
                    <Input className="form-input input-clr" readOnly />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[64, 20]} className="input-main">
                <Col>
                  <Form.Item name="reason" label="Reason for IFP Design" className="input-label">
                    <Input className="form-input input-clr" readOnly />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="airport" label="Airport" className="input-label">
                    <Input className="form-input input-clr" readOnly />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name="nav_spec"
                    label="Navigation Specification"
                    className="input-label"
                  >
                    <Input className="form-input input-clr" readOnly />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[64, 20]} className="input-main">
                <Col>
                  <Form.Item name="runway" label="Runway" className="input-label">
                    <Input className="form-input input-clr" readOnly />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name="runwaydirection"
                    label="Runway Direction"
                    className="input-label"
                  >
                    <Input className="form-input input-clr" readOnly />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="acft_cat" label="Aircraft Category" className="input-label">
                    <Input className="form-input input-clr" readOnly />
                  </Form.Item>
                </Col>
              </Row>
              <Divider className="design-divider2" />
              {
                // new
              }
              <Row gutter={[64, 20]} className="input-main">
                <Col>
                  <Form.Item
                    name="terrain_tolerance"
                    label="Terrain Tolerance"
                    className="input-label"
                  >
                    <Input className="form-input input-clr input-tlrnc" prefix="m" readOnly />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name="vegetation_tolerance"
                    label="Vegetation Tolerance"
                    className="input-label"
                  >
                    <Input className="form-input input-clr input-tlrnc" prefix="m" readOnly />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name="survery_tolerance"
                    label="Survey Tolerance"
                    className="input-label"
                  >
                    <Input className="form-input input-clr input-tlrnc" prefix="m" readOnly />
                  </Form.Item>
                </Col>
              </Row>
              <Divider className="design-divider2" />
              <Row className="input-main" style={{ marginLeft: 0 }}>
                <Col>
                  <Form.Item name="goals" label="Goals to be Achieved" className="input-label">
                    <Input className="form-input-main input-clr" readOnly />
                  </Form.Item>
                </Col>
              </Row>
              <Divider className="design-divider2" />
              <Row gutter={[64, 20]} className="input-main">
                <Col>
                  <Form.Item name="designer" label="Procedure Designer" className="input-label">
                    <Input
                      prefix={<UserOptionItemComponent userDetail={designer} />}
                      className="form-input input-clr"
                      readOnly
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="reviewer" label="Procedure Reviewer" className="input-label">
                    <Input
                      className="form-input input-clr"
                      prefix={<UserOptionItemComponent userDetail={reviewer} />}
                      readOnly
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Divider className="design-divider2" />
              <Form.Item
                name="summary"
                label="Summary of Design (Optional)"
                className="input-label"
              >
                <Input className="form-input-main input-clr" readOnly />
              </Form.Item>
            </div>
            <div>
              <Button
                className="create-design-btn assign-btn"
                disabled={status === 'Assigned' && !isDesigner}
                type="primary"
                htmlType="submit"
                loading={isSuccessDesignStatus}
                size="large"
              >
                {!isDesigner ? 'Continue' : statusbuttonText[status]}
              </Button>
            </div>
          </Form>
        </div>
      </div>
      <ContentScreenLoaderV1 loading={isLoadingDesign || isLoadingUpdateStatus} />
    </>
  );
};

export default Assigndata;

import axios from 'axios';

export function fileUploadApicall(
  url: string,
  onSuccess: Function,
  onFailure: Function,
  accessToken?: string,
  file?: File
) {
  const formData = new FormData();

  if (file) {
    formData.append('file', file, file.name);
  }

  // For local ngrok network
  // 'ngrok-skip-browser-warning': '69420'

  let uploaddHeader: any = {
    'Content-Type': 'multipart/form-data'
  };
  if (accessToken) {
    uploaddHeader = { ...uploaddHeader, Authorization: accessToken };
  }

  try {
    axios
      .post(url, formData, { headers: uploaddHeader })
      .then((response) => {
        const responseJson = response.data;
        if (responseJson.status) {
          onSuccess(responseJson);
        } else {
          onFailure(responseJson);
        }
      })
      .catch((error) => {
        if (error.response) {
          onFailure(error.response.data);
        } else {
          onFailure(error);
        }
      });
  } catch (error) {
    onFailure(error);
  }
}

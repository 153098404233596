import { createSlice } from '@reduxjs/toolkit';
import { DesignState } from './types';

const initialState: DesignState = {
  designs: [],
  runways: [],
  adDataKeys: ['geoadministrative', 'runwayphysicals', 'declareddistaces', 'aixmobstacles'],
  airspaceDataKeys: [],
  selectWaypointData: [],
  designAllMetadata: [],
  designProcedurelist: [],
  designProcedureVersionsData: [],
  availableADData: null,
  availableAirspaceData: null,
  selectedDesign: null,
  legsData: null,
  legsDataOMNI: null,
  legsDataDeparture: null,
  designTemperature: null,
  isLoadingAddSurveyFileUpload: false,
  isLoadingSilentInfoBtwPoint: false,
  isLoadingSilentDesignProcedureVersions: false,
  isLoadingDesignMetadataByMetaid: false
};

export const designSlice = createSlice({
  name: 'design',
  initialState,
  reducers: {
    setDesigns: (state, action) => {
      state.designs = action.payload;
    },
    setSelectedDesign: (state, action) => {
      state.selectedDesign = action.payload;
    },
    setRunways: (state, action) => {
      state.runways = action.payload;
    },
    setADData: (state, action) => {
      state.adDataKeys = action.payload;
    },
    setAvailableADData: (state, action) => {
      state.availableADData = action.payload;
    },
    setAirspaceData: (state, action) => {
      state.airspaceDataKeys = action.payload;
    },
    setAvailableAirspaceData: (state, action) => {
      state.availableAirspaceData = action.payload;
    },
    setSelectWaypointData: (state, action) => {
      state.selectWaypointData = action.payload;
    },
    // remove below action
    setSelectDesignLegData: (state, action) => {
      state.legsData = action.payload;
    },
    setDesignAllMetaData: (state, action) => {
      state.designAllMetadata = action.payload;
    },
    setSelectDesignLegDataDeparture: (state, action) => {
      state.legsDataDeparture = action.payload;
    },
    setSelectDesignLegDataOMNI: (state, action) => {
      state.legsDataOMNI = action.payload;
    },
    setTemperatureData: (state, action) => {
      state.designTemperature = action.payload;
    },
    setDesignProcedurelist: (state, action) => {
      state.designProcedurelist = action.payload;
    },
    setDesignProcedureVersionsData: (state, action) => {
      state.designProcedureVersionsData = action.payload;
    },
    setIsLoadingAddSurveyFileUpload: (state, action) => {
      state.isLoadingAddSurveyFileUpload = action.payload;
    },
    setIsLoadingSilentInfoBtwPoint: (state, action) => {
      state.isLoadingSilentInfoBtwPoint = action.payload;
    },
    setIsLoadingSilentDesignProcedureVersions: (state, action) => {
      state.isLoadingSilentDesignProcedureVersions = action.payload;
    },
    setIsLoadingDesignMetadataByMetaid: (state, action) => {
      state.isLoadingDesignMetadataByMetaid = action.payload;
    },
    clearDesign: () => {
      return initialState;
    }
  }
});

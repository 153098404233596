import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { red3 } from 'constants/color.constant';
import {
  BlueTrianglePointIcon,
  FlybyWaypointIcon,
  FlyoverWaypointIcon,
  TriangleIcon
} from 'constants/map.constants';
import PointComponent from './PointComponent';
import PolygonComponent from './PolygonComponent';
import MultiPolygonComponent from './MultiPolygonComponent';
import ARPPolyline from './ARPPolyline';
import LineTextPath from './LineTextPath';

interface RenderPrevLegDataProps {
  legsData: any;
  procedure?: string | null;
  isLine?: boolean;
  selectedPathTerminator?: string | null;
  magneticDeclination?: number | null;
}

const RenderPrevLegData = ({
  legsData,
  procedure,
  isLine: isShowPolyline,
  magneticDeclination,
  selectedPathTerminator
}: RenderPrevLegDataProps) => {
  if (isEmpty(legsData)) return null;

  const startWayPoint = legsData.start_waypoint;
  const endWayPoint = legsData.end_waypoint;
  const primaryData = legsData.primary;
  const secondaryData = legsData.secondary;
  const { parameters } = legsData;

  const startPointIcon =
    startWayPoint && startWayPoint.waypoint_mode === 'by'
      ? FlybyWaypointIcon
      : startWayPoint && startWayPoint.waypoint_mode === 'over'
      ? FlyoverWaypointIcon
      : BlueTrianglePointIcon;

  const endPointIcon =
    endWayPoint && endWayPoint.waypoint_mode === 'by'
      ? FlybyWaypointIcon
      : endWayPoint && endWayPoint.waypoint_mode === 'over'
      ? FlyoverWaypointIcon
      : procedure === 'OMNI'
      ? FlyoverWaypointIcon
      : TriangleIcon;

  const isFictitiousStart = startWayPoint.waypoint_name === 'Fictitious WPT';
  const isFictitiousEnd = endWayPoint.waypoint_name === 'Fictitious WPT';

  const legData = useMemo(() => {
    return (
      <>
        {startWayPoint && (
          <PointComponent
            pointIcon={startPointIcon}
            index={1}
            geometry={{ coordinates: [startWayPoint.lng, startWayPoint.lat] }}
            properties={null}
            isFictitious={isFictitiousStart}
          />
        )}
        {endWayPoint && (
          <PointComponent
            index={2}
            pointIcon={endPointIcon}
            geometry={{ coordinates: [endWayPoint.lng, endWayPoint.lat] }}
            properties={null}
            isFictitious={isFictitiousEnd}
          />
        )}
        {primaryData && primaryData.type === 'Polygon' && (
          <PolygonComponent
            index={3}
            coordinates={primaryData.coordinates}
            properties={null}
            weight={2}
            color={red3}
            fillColor={red3}
          />
        )}
        {secondaryData && secondaryData.type === 'Polygon' && (
          <PolygonComponent
            index={3}
            coordinates={secondaryData.coordinates}
            properties={null}
            isLine
            weight={2}
            color={red3}
            fillColor="#FF3B3B33"
            fillOpacity={0.7}
          />
        )}
        {secondaryData && secondaryData.type === 'MultiPolygon' && (
          <MultiPolygonComponent
            index={3}
            coordinates={secondaryData.coordinates}
            properties={null}
            isLine
            weight={2}
            fillColor="#6E3BD14D"
            fillOpacity={0.7}
            pane="mapPane"
          />
        )}
        {startWayPoint && endWayPoint && (
          <LineTextPath
            coordinates={[
              [endWayPoint.lat, endWayPoint.lng],
              [startWayPoint.lat, startWayPoint.lng]
            ]}
            distance={`${parameters.dst_btw_wpt.toFixed(3)} NM`}
            bearing={`${parameters.bearing.toFixed(3)}° / ${(
              parameters.bearing - magneticDeclination!
            ).toFixed(3)}° M`}
          />
        )}
        {procedure === 'DEPARTURE' &&
          !selectedPathTerminator &&
          isShowPolyline &&
          startWayPoint &&
          endWayPoint && (
            <ARPPolyline
              lineColor="#696B72"
              decoratorIcon={TriangleIcon}
              arpCoordinates={[
                [startWayPoint.lat, startWayPoint.lng],
                [endWayPoint.lat, endWayPoint.lng]
              ]}
            />
          )}
      </>
    );
  }, [
    legsData,
    startWayPoint,
    endWayPoint,
    primaryData,
    secondaryData,
    procedure,
    isShowPolyline,
    isFictitiousStart,
    isFictitiousEnd
  ]);

  // eslint-disable-next-line
  return <>{legData}</>;
};

RenderPrevLegData.defaultProps = {
  procedure: null,
  isLine: true,
  magneticDeclination: null,
  selectedPathTerminator: null
};

export default RenderPrevLegData;

import { useMemo } from 'react';
import { isEmpty } from 'lodash';
import { Polyline } from 'react-leaflet';
import { black } from 'constants/color.constant';

interface OverseeLineProps {
  lineCoords: any;
}

const OverseeLine = ({ lineCoords }: OverseeLineProps) => {
  if (isEmpty(lineCoords)) return null;
  const coords = lineCoords.map((point: any) => {
    return [Number(point[1]), Number(point[0])];
  });
  const lineShape = useMemo(() => {
    return (
      <Polyline
        pane="shadowPane"
        pathOptions={{ color: black, weight: 1.5, dashArray: [5, 5] }}
        positions={coords}
      />
    );
  }, [lineCoords]);

  // eslint-disable-next-line
  return <>{lineShape}</>;
};

export default OverseeLine;

import { IconProps } from 'types/common.types';

const FlybyWaypointIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="9.00018" cy="9.00018" r="3.68182" stroke={color} />
      <path
        d="M2.25 9C3.98864 8.38636 7.77273 6.17727 9 2.25"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.75 9C14.0114 9.61364 10.2273 11.8227 9 15.75"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.75 9C14.0114 8.38636 10.2273 6.17727 9 2.25"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.25 9C3.98864 9.61364 7.77273 11.8227 9 15.75"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
FlybyWaypointIcon.defaultProps = {
  color: '#696B72',
  width: 18,
  height: 18,
  onClick: () => {}
};

export default FlybyWaypointIcon;

import { useEffect, useState } from 'react';
import { filter } from 'lodash';
import { Typography } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { OAlegdata } from 'store/design/types';

const { Text } = Typography;

interface ObsTerrainFullScreenTableProps {
  obstacleData: OAlegdata[] | [];
  isObsTerrainFullScreenTable: boolean;
  isShowClosingObstacle: boolean;
  activeObscleTerrainDataKey: string;
}

const ObsTerrainFullScreenTable = ({
  obstacleData,
  isObsTerrainFullScreenTable,
  isShowClosingObstacle,
  activeObscleTerrainDataKey
}: ObsTerrainFullScreenTableProps) => {
  const [obstacleTableData, setObstacleTableData] = useState<OAlegdata[] | []>([]);

  useEffect(() => {
    let obsData = [...obstacleData];
    if (isShowClosingObstacle) {
      let data = filter(obsData, ['is_close_in', isShowClosingObstacle]);
      data = data.sort((a: any, b: any) => a.is_close_in - b.is_close_in);
      data = [...data].sort((a: any, b: any) => a.penetration - b.penetration);

      setObstacleTableData([...data]);
    } else {
      obsData = obsData.sort((a: any, b: any) => a.is_close_in - b.is_close_in);
      obsData = [...obsData].sort((a: any, b: any) => a.penetration - b.penetration);
      setObstacleTableData([...obsData]);
    }
  }, [
    isObsTerrainFullScreenTable,
    obstacleData,
    activeObscleTerrainDataKey,
    isShowClosingObstacle
  ]);

  const columns: ColumnsType<OAlegdata> = [
    {
      title: 'UID',
      dataIndex: 'id',
      align: 'center',
      key: 'id',
      render: (value) => value || '-'
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (value) => value || '-'
    },
    {
      title: 'Coordinates',
      key: 'coordinates',
      render: (record: OAlegdata) => {
        return (
          <>
            <Text style={{ color: '#696b72', display: 'block', fontSize: 12 }}>
              {record.latitude || '-'}{' '}
            </Text>
            <Text style={{ color: '#696b72', display: 'block', fontSize: 12 }}>
              {record.longitude || '-'}
            </Text>
          </>
        );
      }
    },
    {
      title: 'Elevation (m)',
      dataIndex: 'elevation',
      align: 'center',
      key: 'elevation',
      render: (value) => {
        return value ? Number(value).toFixed(3) : '-';
      }
    },
    {
      title: 'Description',
      dataIndex: 'description',
      align: 'center',
      key: 'description',
      render: (value) => value || '-'
    },
    {
      title: 'MOCA (m)',
      dataIndex: 'moc',
      key: 'moc',
      render: (value) => {
        return value ? Number(value).toFixed(3) : '-';
      }
    },
    {
      title: 'Penetration (m)',
      dataIndex: 'penetration',
      key: 'penetration',
      render: (value) => {
        return (
          <Text style={{ color: Number(value) <= 0 ? '#27AE60' : '#EB5757', fontSize: 12 }}>
            {Number(value) <= 0 ? 'clear' : `${Number(value).toFixed(3)}`}
          </Text>
        );
      }
    }
  ];
  return (
    <Table
      className={
        isObsTerrainFullScreenTable ? 'full-collect-table collect-table-main' : 'collect-table-main'
      }
      scroll={{
        y: isObsTerrainFullScreenTable ? 'calc(100vh - 204px)' : 'calc(100% - 45px)'
      }}
      dataSource={obstacleTableData || []}
      pagination={false}
      columns={columns}
      rowKey={(record) => record.id}
    />
  );
};

export default ObsTerrainFullScreenTable;

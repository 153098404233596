import { isEmpty, upperFirst } from 'lodash';
import * as turf from '@turf/turf';
import {
  CACFVAVIPathOptions,
  CARFTFPathOptions,
  CFDFPathOptions,
  CFDFTFPathOptions,
  CFTFPathOptions
} from 'constants/designData.constants';
import { DMEImgIcon, NDBImgIcon, VORDMEImgIcon, VORImgIcon } from 'constants/map.constants';

export const shortLabel = (string: string) => {
  const label = string
    .split(' ')
    .map((word) => upperFirst(word[0]))
    .join('');

  return label;
};

export const converteParaSexagesimal = (decimal: any) => {
  const apenasDecimal = parseFloat(`0.${decimal.split('.')[1]}`);
  const primeroPasso = (apenasDecimal * 60).toString();
  const segundoPasso = parseFloat(`0.${primeroPasso.split('.')[1]}`);

  const graus = decimal.split('.')[0];
  const minutos = primeroPasso.split('.')[0];
  const segundos: any = segundoPasso * 60;

  let resultado = `${graus}° `;
  resultado += `${minutos}´ `;
  resultado += `${parseFloat(segundos).toFixed(2)}"`;

  return resultado;
};

export const latlngToDms = (decimais: any) => {
  let resultado = '';
  if (decimais) {
    // lat
    const primeiroValor = decimais.split(',')[0];
    const primeiroValorConvertido = converteParaSexagesimal(primeiroValor);
    const primeiroResultado = primeiroValorConvertido.replace('-', '');
    const lat = primeiroValor > 0 ? `N ${primeiroResultado} ` : `S ${primeiroResultado} `;

    // long
    const segundoValor = decimais.split(',')[1];
    const segundoValorConvertido = converteParaSexagesimal(segundoValor);
    const segundoResultado = segundoValorConvertido.replace('-', '');
    const lng = primeiroValor > 0 ? `  E ${segundoResultado}` : `  W ${segundoResultado}`;

    resultado = `${lat} ${lng}`;
  }

  return resultado;
};

export const latToDms = (lattitude: number) => {
  let latDms = '';
  if (lattitude) {
    const primeiroValorConvertido = converteParaSexagesimal(lattitude.toString());

    const primeiroResultado = primeiroValorConvertido.replace('-', '');

    const lat = lattitude > 0 ? `${primeiroResultado}  N` : `${primeiroResultado}  S`;

    latDms = lat;
  }

  return latDms;
};

export const lngToDms = (longitude: number) => {
  let lngDms = '';
  if (longitude) {
    const primeiroValorConvertido = converteParaSexagesimal(longitude.toString());

    const primeiroResultado = primeiroValorConvertido.replace('-', '');

    const lng = longitude > 0 ? `${primeiroResultado}  E` : `${primeiroResultado}  W`;

    lngDms = lng;
  }

  return lngDms;
};

// export const tas = (ias: number, tmin: number, altgradHeight: number) => {
//   return (
//     (ias *
//       1.852 *
//       ((171233 * (288 + tmin - 0.006496 * (altgradHeight * 0.3048)) ** 0.5) /
//         (288 - 0.006496 * (altgradHeight * 0.3048)) ** 2.628)) /
//     0.539957
//   );
// };

export const isFloat = (n: number) => {
  return Number(n) === n && n % 1 !== 0;
};

export const tasCalculation = (ias: number, tmin: number, endAlt: number) => {
  const endAltitudemtr: number = endAlt / 3.28084;

  return (
    (ias * 1.852 * (171233 * (288 + tmin - 0.006496 * endAltitudemtr) ** 0.5)) /
    (288 - 0.006496 * endAltitudemtr) ** 2.628 /
    1.852
  );
};

export const getNavaidIcon = (name: string | null) => {
  let icon = VORImgIcon;

  switch (name) {
    case 'NDB':
      icon = NDBImgIcon;
      break;
    case 'VOR_DME':
      icon = VORDMEImgIcon;
      break;
    case 'VOR':
      icon = VORImgIcon;
      break;
    case 'DME':
      icon = DMEImgIcon;
      break;
    default:
      icon = VORImgIcon;
      break;
  }

  return icon;
};

export const getBankAngle = (alt: number) => {
  let bankAngle = 15;

  if (alt <= 1000) {
    bankAngle = 15;
  } else if (alt > 1000 || alt <= 3000) {
    bankAngle = 20;
  } else if (alt > 3000) {
    bankAngle = 25;
  }

  return bankAngle;
};

export const getTurnDirectionAngle = (angle: number, direction: string) => {
  if (angle > 180) {
    const turnangle = 360 - Number(angle);
    const turndirection = direction === 'left' ? 'right' : 'left';
    return { angle: turnangle, direction: turndirection };
  }
  return null;
};

export const getTurnAndleDataHandling = (
  turnangle: number,
  pathTerminator: string,
  turnDirection: string,
  activeLeg: number
) => {
  let data: { angle: number; direction: string; isError?: boolean; message?: string } | null = null;

  if (pathTerminator === 'CA') {
    if (turnangle > 180) {
      const newdata = getTurnDirectionAngle(turnangle, turnDirection);
      if (newdata) {
        if (Number(newdata.angle.toFixed(3)) === 0) {
          data = { angle: turnangle, direction: turnDirection, isError: false };
        } else if (newdata.angle > 0 && newdata.angle <= 15) {
          data = {
            angle: newdata.angle >= 0.05 ? newdata.angle : 0,
            direction: newdata.angle >= 0.05 ? newdata.direction : 'on-set',
            isError: false
          };
        } else {
          data = {
            ...newdata,
            isError: true,
            message: 'Turn angle is greater than 15.'
          };
        }
      }
    } else if (turnangle < 180) {
      if (Number(turnangle.toFixed(3)) === 0) {
        data = { angle: turnangle, direction: turnDirection, isError: false };
      } else if (turnangle > 0 && turnangle <= 15) {
        data = {
          angle: turnangle >= 0.05 ? turnangle : 0,
          direction: turnangle >= 0.05 ? turnDirection : 'on-set',
          isError: false
        };
      } else {
        data = {
          angle: turnangle,
          direction: turnDirection,
          isError: true,
          message: `Turn angle ${turnangle} is greater than 15.`
        };
      }
    }
  } else if (pathTerminator === 'CF' && activeLeg === 1) {
    if (turnangle > 180) {
      const newdata = getTurnDirectionAngle(turnangle, turnDirection);
      if (newdata) {
        if (Number(newdata.angle.toFixed(2)) === 0) {
          data = { angle: 0, direction: 'on-set', isError: false };
        } else if (newdata.angle >= 0 && newdata.angle <= 0.05) {
          data = {
            angle: 0,
            direction: 'on-set',
            isError: false
          };
        } else {
          data = {
            ...newdata,
            isError: true,
            message: 'Select point near by reference line.'
          };
        }
      }
    } else if (Number(turnangle) < 180) {
      if (Number(turnangle.toFixed(2)) === 0) {
        data = { angle: 0, direction: 'on-set', isError: false };
      } else if (turnangle >= 0 && turnangle <= 0.05) {
        data = {
          angle: 0,
          direction: 'on-set',
          isError: false
        };
      } else {
        data = {
          angle: turnangle,
          direction: turnDirection,
          isError: true,
          message: 'Select point near by reference line.'
        };
      }
    }
  } else if (pathTerminator === 'CF' && activeLeg > 1) {
    if (turnangle > 180) {
      const newdata = getTurnDirectionAngle(turnangle, turnDirection);
      if (newdata) {
        if (Number(newdata.angle.toFixed()) === 0) {
          data = { angle: 0, direction: 'on-set', isError: false };
        } else if (newdata.angle >= 30 && newdata.angle <= 90) {
          data = {
            angle: newdata.angle,
            direction: newdata.direction,
            isError: false
          };
        } else {
          data = {
            ...newdata,
            isError: true,
            message: `Turn angle (${newdata.angle}) should be 0 or between 30 to 90.`
          };
        }
      }
    } else if (Number(turnangle) < 180) {
      if (Number(turnangle.toFixed()) === 0) {
        data = { angle: 0, direction: 'on-set', isError: false };
      } else if (turnangle >= 30 && turnangle <= 90) {
        data = {
          angle: turnangle,
          direction: turnDirection,
          isError: false
        };
      } else {
        data = {
          angle: turnangle,
          direction: turnDirection,
          isError: true,
          message: `Turn angle (${turnangle}) should be 0 or between 30 to 90.`
        };
      }
    }
  } else if (pathTerminator === 'TF') {
    if (turnangle > 180) {
      const newdata = getTurnDirectionAngle(turnangle, turnDirection);

      if (newdata) {
        if (Number(newdata.angle.toFixed(3)) === 0) {
          data = { angle: turnangle, direction: turnDirection, isError: false };
        } else if (newdata.angle >= 0 && newdata.angle <= 120) {
          data = {
            angle: newdata.angle >= 0.05 ? newdata.angle : 0,
            direction: newdata.angle >= 0.05 ? newdata.direction : 'on-set',
            isError: false
          };
        } else if (newdata.angle > 0 && newdata.angle <= 0.009) {
          data = {
            angle: newdata.angle,
            direction: 'on-set',
            isError: false
          };
        } else {
          data = {
            ...newdata,
            isError: true,
            message: `Turn angle (${newdata.angle}) should be between 0 to 120.`
          };
        }
      }
    } else if (turnangle < 180) {
      if (Number(turnangle.toFixed(3)) === 0) {
        data = { angle: turnangle, direction: turnDirection, isError: false };
      } else if (turnangle >= 0 && turnangle <= 120) {
        data = {
          angle: turnangle >= 0.05 ? turnangle : 0,
          direction: turnangle >= 0.05 ? turnDirection : 'on-set',
          isError: false
        };
      } else if (turnangle > 0 && turnangle <= 0.009) {
        data = {
          angle: turnangle,
          direction: 'on-set',
          isError: false
        };
      } else {
        data = {
          angle: turnangle,
          direction: turnDirection,
          isError: true,
          message: `Turn angle (${turnangle}) should be between 0 to 120.`
        };
      }
    }
  } else if (pathTerminator === 'DF') {
    if (turnangle > 180) {
      const newdata = getTurnDirectionAngle(turnangle, turnDirection);
      if (newdata) {
        if (Number(newdata.angle.toFixed(3)) === 0) {
          data = { angle: turnangle, direction: turnDirection, isError: false };
        } else if (newdata.angle > 30 && newdata.angle <= 140) {
          data = {
            angle: newdata.angle >= 0.05 ? newdata.angle : 0,
            direction: newdata.angle >= 0.05 ? newdata.direction : 'on-set',
            isError: false
          };
        } else {
          data = {
            ...newdata,
            isError: true,
            message: `Turn angle(${turnangle}) should be between 30 to 140.`
          };
        }
      }
    } else if (turnangle < 180) {
      if (Number(turnangle.toFixed(3)) === 0) {
        data = { angle: turnangle, direction: turnDirection, isError: false };
      } else if (turnangle > 30 && turnangle <= 140) {
        data = {
          angle: turnangle >= 0.05 ? turnangle : 0,
          direction: turnangle >= 0.05 ? turnDirection : 'on-set',
          isError: false
        };
      } else {
        data = {
          angle: turnangle,
          direction: turnDirection,
          isError: true,
          message: `Turn angle(${turnangle}) should be between 30 to 140.`
        };
      }
    }
  } else if (pathTerminator === 'RF') {
    data = null;
  }

  return data;
};

export const getTurnAngleMinMax = (pathTerminator: string, activeLeg: number) => {
  if (pathTerminator === 'CA') {
    return { min: 0, max: 15 };
  }
  if (pathTerminator === 'CF' && activeLeg === 1) {
    return { min: 0, max: 0 };
  }
  if (pathTerminator === 'CF' && activeLeg > 1) {
    return { min: `0 or 30`, max: 90 };
  }
  if (pathTerminator === 'TF') {
    return { min: 0, max: 120 };
  }
  if (pathTerminator === 'DF') {
    return { min: 30, max: 140 };
  }
  if (pathTerminator === 'RF') {
    return null;
  }
  return null;
};

// Height-ft, length-NM, PDG-%
export const getEndPointHeight = (length: number, pdg: number) => {
  return (length * 6076.12 * (pdg || 3.3)) / 100;
};

// Altitude-ft, length-NM, PDG-%
// export const getEndPointAltitude = (length: number, pdg: number) => {
//   return (length * 6076.12 * (pdg || 3.3)) / 100;
// };

// Length-NM, Height-ft, PDG-%
export const getEndPointLength = (height: number, pdg: number) => {
  return (height * 100) / (pdg * 6076.12);
};

// PDG-%, height-ft, length-NM
export const getEndPointPDG = (height: number, length: number) => {
  return (height * 100) / (Number(length) * 6076.12);
};

export const getPathTerminatorOptions = (
  prevLeg: string | null,
  currentLeg: string,
  prevFlymode: string | null,
  prevLegPathTerminator: string | null
) => {
  if (currentLeg === '1') {
    return CACFVAVIPathOptions;
  }
  if (
    (prevLeg === '1' && prevFlymode === 'over' && prevLegPathTerminator === 'CF') ||
    (Number(prevLeg) >= 2 && prevFlymode === 'over' && prevLegPathTerminator === 'CF')
  ) {
    return CFDFPathOptions;
  }
  if (
    (prevLeg === '1' && prevFlymode === 'by' && prevLegPathTerminator === 'CF') ||
    (Number(prevLeg) >= 2 && prevFlymode === 'by' && prevLegPathTerminator === 'CF') ||
    (Number(prevLeg) > 1 && prevLegPathTerminator === 'TF') ||
    (Number(prevLeg) > 1 && prevLegPathTerminator === 'RF')
  ) {
    return prevLegPathTerminator === 'RF' ? CARFTFPathOptions : CARFTFPathOptions;
  }
  if (
    (prevLeg === '1' && prevLegPathTerminator === 'CA') ||
    (Number(prevLeg) >= 2 && prevLegPathTerminator === 'CA')
  ) {
    return CFDFPathOptions;
  }
  // if (
  //   (Number(prevLeg) > 1 && prevLegPathTerminator === 'TF') ||
  //   (Number(prevLeg) > 1 && prevLegPathTerminator === 'RF')
  // ) {
  //   return typeOfFixLegOptions1;
  // }
  if (Number(prevLeg) > 1 && prevLegPathTerminator === 'DF' && prevFlymode === 'over') {
    return CFDFTFPathOptions;
  }
  if (Number(prevLeg) > 1 && prevLegPathTerminator === 'DF' && prevFlymode === 'by') {
    return CFTFPathOptions;
  }
  return [];
};

export const nearestSnapPointOnLine = (latlang: any, refrenceLineData: any) => {
  if (isEmpty(refrenceLineData)) return null;
  const line = turf.lineString(refrenceLineData);
  const pt = turf.point([latlang[1], latlang[0]]);

  const snapped = turf.nearestPointOnLine(line, pt, { units: 'kilometers' });
  const finaldata = {
    coordinates: [],
    length: 0
  };

  if (snapped) {
    finaldata.coordinates = snapped?.geometry?.coordinates;
    finaldata.length = Number(snapped?.properties?.dist) * 1000;
    return finaldata;
  }

  return null;
};

const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

export const generateString = (length: number) => {
  let result = ' ';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

import { primary } from 'constants/color.constant';
import { IconProps } from 'types/common.types';

const ARPPointIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      onClick={onClick}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 1L10 3" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <path d="M3 10H1" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <path d="M19 10L17 10" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <path d="M10 19L10 17" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <circle cx="10" cy="10" r="6.5" stroke={color} strokeWidth="2" />
    </svg>
  );
};
ARPPointIcon.defaultProps = {
  color: primary,
  width: 20,
  height: 20,
  onClick: () => {}
};

export default ARPPointIcon;

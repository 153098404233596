import { primary } from 'constants/color.constant';
import { IconProps } from 'types/common.types';

const ObstacleDataIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      onClick={onClick}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.25 14.6196L9.16304 3.75L12.75 14.6196"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="9.16292" cy="13.9676" r="0.652174" fill={color} />
    </svg>
  );
};
ObstacleDataIcon.defaultProps = {
  color: primary,
  width: 18,
  height: 18,
  onClick: () => {}
};

export default ObstacleDataIcon;

import { Divider, DividerProps } from 'antd';
import { lightGrey1 } from 'constants/color.constant';

interface ModifiedDividerProps extends DividerProps {
  // Add any additional props you want to modify or extend
  color?: string;
  marginTop?: string;
  marginBottom?: string;
}

const CustomDivider = ({
  className,
  type = 'horizontal',
  marginTop,
  marginBottom,
  color,
  ...props
}: ModifiedDividerProps) => {
  return (
    <Divider
      className={className}
      type={type}
      style={{ marginTop, marginBottom, borderBlockStartColor: color }}
      {...props}
    />
  );
};
CustomDivider.defaultProps = {
  marginTop: 0,
  marginBottom: 0,
  color: lightGrey1
};

export default CustomDivider;

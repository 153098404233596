import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { capitalize, isEmpty, orderBy } from 'lodash';
import moment from 'moment';
import { Button, Dropdown, Table, Tooltip, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import ViewDesignIcon from 'assets/svg/ViewDesignIcon';
import ViewTimelineIcon from 'assets/svg/ViewTimelineIcon';
import EditFileIcon from 'assets/svg/EditFileIcon';
import InfoCircleIcon from 'assets/svg/InfoCircleIcon';
import CloseIcon from 'assets/svg/CloseIcon';
import { lightGrey2, secondary } from 'constants/color.constant';
import { DateFormat2, TimeFormat } from 'constants/common.constant';
import AvatarUserComponent from 'components/core/AvatarUserComponent';
import DesignStatusComponents from 'components/core/DesignStatusComponents';
import Emptytext from 'components/core/Emptytext';
import { TimezoneContext } from 'context/createusecontext';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { setDesigns } from 'store/design';
import { DesignResponse, DesignState, ProcedureDataType } from 'store/design/types';
import { UserRole, UserState } from 'store/users/types';
import { DesignStatus as Status } from 'types/common.types';
import './styles.less';

const { Text } = Typography;

interface ListDesignTableV1Props {
  selectedActiveTab: (active: string) => void;
  activeTab: string;
  DesignDataFilter: DesignResponse[] | [];
}

const ListDesignTableV1 = ({
  selectedActiveTab,
  activeTab,
  DesignDataFilter
}: ListDesignTableV1Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const timeZone = useContext(TimezoneContext);
  const { userSession: currentUser }: UserState = useAppSelector((state) => state.user);
  const { designs: designListData }: DesignState = useAppSelector((state) => state.design);
  const isOrgAdmin = currentUser?.role === UserRole.Admin;
  const [collapsedId, setCollapsedId] = useState<number | null>(null);

  const handleNavigate = (id: number) => {
    if (!isOrgAdmin) {
      navigate(`/assign/${id}`);
      dispatch(setDesigns([]));
    }
  };

  const handleNavigateEditDesign = (id: number) => {
    if (!isOrgAdmin) {
      navigate(`/edit-design/${id}`);
      dispatch(setDesigns([]));
    }
  };

  const handleOpenChange = (id: number) => {
    setCollapsedId(id);
  };

  // eslint-disable-next-line
  const handleClickSID = (id: number, data: ProcedureDataType, metaid: number) => {
    if (!isOrgAdmin) {
      navigate(`/design/${id}`, { state: { id, selectedProcedureData: data, metaId: metaid } });
      dispatch(setDesigns([]));
    }
  };

  const columns: ColumnsType<DesignResponse> = [
    {
      title: 'AIRPORT',
      dataIndex: 'airport_name',
      key: 'airport_name',
      width: 250,
      className: 'airport-name',
      render: (value) => {
        return <Text className="arp-title">{value}</Text>;
      }
    },
    {
      title: 'CFN CODE',
      dataIndex: 'cfn',
      key: 'cfn',
      width: 150,
      render: (value) => {
        return <Text className="cfn-code">{value}</Text>;
      }
    },
    {
      title: 'TYPE OF PROCEDURE',
      dataIndex: 'procedure_type',
      align: 'center',
      key: 'procedure_type',
      render(value) {
        return <Text className="type-procedure">{value}</Text>;
      }
    },
    {
      title: 'NAME OF PROCEDURE',
      dataIndex: 'name',
      key: 'name',
      render(value) {
        return <Text className="name-procedure">{value || '-'}</Text>;
      }
    },
    {
      title: 'NO. OF PROCEDURES',
      dataIndex: 'procedures',
      align: 'center',
      key: 'procedures',
      render(_, record) {
        const totalProcedure = record.procedures.length;

        return (
          <Text className="all-procedures">
            {String(totalProcedure).length === 1 ? `0${totalProcedure}` : totalProcedure}
            {totalProcedure > 0 && (
              <Dropdown
                className="procedure-info"
                overlayClassName="procedure-dropdown"
                placement="bottomLeft"
                open={record.id === collapsedId}
                onOpenChange={() => handleOpenChange(record.id)}
                // eslint-disable-next-line
                dropdownRender={() => {
                  return (
                    <div className="procedure-dropdown-div">
                      <div className="dropdown-title">
                        <Text>No. of Procedures ({totalProcedure})</Text>
                        <CloseIcon height={16} width={16} onClick={() => setCollapsedId(null)} />
                      </div>
                      <ul className="procedure-lists">
                        {orderBy(record.procedures, ['id'], ['asc']).map(
                          (data: ProcedureDataType) => (
                            <li key={data.id}>
                              <a onClick={() => handleClickSID(record.id, data, record.metaId)}>
                                {data.name} - {data.procedure}
                              </a>
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  );
                }}
                trigger={['click']}
              >
                <Button
                  type="text"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  icon={<InfoCircleIcon color="#696B72" />}
                />
              </Dropdown>
            )}
          </Text>
        );
      }
    },
    {
      title: 'MANAGER',
      dataIndex: 'manager',
      align: 'center',
      key: 'manager',
      render: (value) => {
        return <AvatarUserComponent data={value} userRole="manager" />;
      }
    },
    {
      title: 'DESIGNER',
      dataIndex: 'designer',
      align: 'center',
      key: 'designer',
      render: (value) => {
        return <AvatarUserComponent data={value} userRole="designer" />;
      }
    },
    {
      title: 'REVIEWER',
      dataIndex: 'reviwer',
      align: 'center',
      key: 'reviwer',
      render: (value) => {
        return <AvatarUserComponent data={value} userRole="reviewer" />;
      }
    },
    {
      title: 'STATUS',
      align: 'center',
      key: 'status',
      width: 120,
      render: (_, record) => {
        const data = capitalize(record.status);
        return <DesignStatusComponents data={data} />;
      }
    },
    {
      title: 'LAST UPDATED',
      dataIndex: 'modified_at',
      key: 'modified_at',
      align: 'center',
      render(value) {
        const date: any = value ? moment(value).format(DateFormat2) : '-';
        const time: any = value ? `${moment(value).format(TimeFormat)} ${timeZone?.timezone}` : '';
        return (
          <div className="flexDirectionColumn">
            <Text style={{ color: `${lightGrey2}`, fontSize: 14, fontWeight: 500 }}>{date}</Text>
            <Text style={{ color: `${lightGrey2}`, fontSize: 14, fontWeight: 500 }}>{time}</Text>
          </div>
        );
      }
    },
    {
      title: 'ACTION',
      dataIndex: 'action',
      align: 'center',
      key: 'action',
      render: (_, record) => {
        return (
          <>
            <Tooltip
              placement="bottom"
              color={secondary}
              title={!isOrgAdmin ? <Text className="tooltip-title">View Design</Text> : ''}
            >
              <Button
                type="text"
                className={isOrgAdmin ? 'view-inactive-btn' : ''}
                icon={<ViewDesignIcon />}
                onClick={() => handleNavigate(record.id)}
              />
            </Tooltip>
            {currentUser?.role === UserRole.Manager && record.status === Status.Assigned && (
              <Tooltip
                placement="bottom"
                color={secondary}
                title={<Text className="tooltip-title">Edit Design</Text>}
              >
                <Button
                  onClick={() => handleNavigateEditDesign(record.id)}
                  type="text"
                  style={{ marginLeft: 8 }}
                  icon={<EditFileIcon />}
                />
              </Tooltip>
            )}
            {record.status === Status.Reviewed && (
              <Tooltip
                placement="bottom"
                color={secondary}
                title={!isOrgAdmin ? <Text className="tooltip-title">View Timeline</Text> : ''}
              >
                <Button
                  type="text"
                  className={isOrgAdmin ? 'view-inactive-btn' : ''}
                  style={{ marginLeft: 8 }}
                  icon={<ViewTimelineIcon />}
                />
              </Tooltip>
            )}
          </>
        );
      }
    }
  ];

  const locale = {
    emptyText: (
      <Emptytext
        isDesignData={Boolean(!isEmpty(designListData) && isEmpty(DesignDataFilter))}
        activeTabText={activeTab}
      />
    )
  };

  return (
    <>
      {!isEmpty(designListData) && (
        <div className="design-table-tab">
          <Button.Group>
            <Button
              className={activeTab === 'all' ? 'active-btn btn-all' : 'btn-all'}
              onClick={() => selectedActiveTab('all')}
            >
              ALL
            </Button>
            <Button
              className={activeTab === Status.Assigned ? 'active-btn btn-all' : 'btn-all'}
              onClick={() => selectedActiveTab(Status.Assigned)}
            >
              ASSIGNED
            </Button>
            <Button
              className={activeTab === Status.Designing ? 'active-btn btn-all' : 'btn-all'}
              onClick={() => selectedActiveTab(Status.Designing)}
            >
              DESIGNING
            </Button>
            <Button
              className={activeTab === Status['In Review'] ? 'active-btn btn-all' : 'btn-all'}
              onClick={() => selectedActiveTab(Status['In Review'])}
            >
              IN REVIEW
            </Button>
            <Button
              className={activeTab === Status.Reviewed ? 'active-btn btn-all' : 'btn-all'}
              onClick={() => selectedActiveTab(Status.Reviewed)}
            >
              REVIEWED
            </Button>
          </Button.Group>
        </div>
      )}
      <div
        className={
          !isEmpty(designListData)
            ? 'design-table-content'
            : 'design-table-content list-table-content'
        }
      >
        <Table
          className="design-table-main design-list-expand-table"
          scroll={{ y: 'calc(100vh - 264px)' }}
          locale={locale}
          pagination={false}
          columns={columns}
          dataSource={DesignDataFilter}
          rowKey={(record) => record.id}
          expandable={{
            expandedRowKeys: DesignDataFilter.filter((d) =>
              Object.keys(d).includes('children').valueOf()
            ).map((d) => d.id),
            defaultExpandAllRows: true
          }}
        />
      </div>
    </>
  );
};

export default ListDesignTableV1;

import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MapContainer } from 'react-leaflet';
import { isEmpty } from 'lodash';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { defaultCenter } from 'constants/common.constant';
import { routes } from 'constants/pageRoutes.constants';
import ControlMapCenter from 'components/Map/ControlMapCenter';
import LiveCoordinates from 'components/Map/LiveCoordinates';
import MapActionControlV1 from 'components/Map/MapActionControlV1';
import MarkerComponent from 'components/Map/MarkerComponent';
import MapLayers from 'components/Map/MapLayers';
import MarkerDetailsComponent from 'components/HomeMapComponents/MarkerDetailsComponent';
import CreateNewDesignModal from 'components/Modals/CreateNewDesignModal';
import NewdesignAssignModal from 'components/Modals/NewdesignAssignModal';
import HomeSubHeader from 'components/SubHeader/HomeSubheader';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { useGetOrganisationsMembersQuery } from 'services/apis/userApi';
import { useGetDesignsQuery } from 'services/apis/designsApi';
import { getAirportSearchList, setDesigns } from 'store/design';
import { UserRole } from 'store/users/types';
import { DesignState } from 'store/design/types';
import { Maptype } from 'types/common.types';
import './styles.less';

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state) => state.user.userSession);
  const { designs: designListData }: DesignState = useAppSelector((state) => state.design);
  const designData = useAppSelector((state) => state.design.designs);
  const [airportName, setAirportName] = useState<string | null>(null);
  const searchAirportLists = useAppSelector((state) => getAirportSearchList(state, airportName));

  const { data: organisationMembers, isSuccess: isSuccessOrgMembers } =
    useGetOrganisationsMembersQuery(currentUser?.organisationid, {
      skip: Boolean(
        !(currentUser && currentUser?.organisationid) ||
          (currentUser && currentUser?.role === UserRole.Designer) ||
          (currentUser && currentUser?.role === UserRole.Reviewer)
      ),
      refetchOnMountOrArgChange: true
    });
  const { data: designsData } = useGetDesignsQuery(
    currentUser && currentUser.organisationid ? currentUser.organisationid : skipToken,
    { refetchOnMountOrArgChange: true }
  );

  const [isShowCreateDesignModal, setShowCreateDesignModal] = useState<boolean>(false);
  const [isShowMarkerDetails, setShowMarkerDetails] = useState<boolean>(false);
  const [mapType, setMapType] = useState<string>(Maptype.Roadmap);
  const [selectedDesignData, setSelectedDesignData] = useState<string | null>(null);

  useEffect(() => {
    if (designsData) {
      const designsDataResponse: any = designsData;
      if (designsDataResponse.status) {
        dispatch(setDesigns(designsDataResponse.data));
      }
    }
  }, [designsData]);

  useEffect(() => {
    if (isSuccessOrgMembers) {
      const orgMenbersResponse: any = organisationMembers;
      if (orgMenbersResponse.status) {
        setShowCreateDesignModal(Boolean(isEmpty(orgMenbersResponse.data)));
      }
    }
  }, [isSuccessOrgMembers]);

  const handleCloseCreateNewDesignModal = (isOpenClose: boolean) => {
    setShowCreateDesignModal(isOpenClose);
  };

  const handleCreatenewDesign = () => {
    navigate(routes.CreateDesign);
    setAirportName(null);
  };

  const handleNewdesignAssign = () => {
    navigate(routes.Assign);
    setAirportName(null);
  };

  const handleCloseMarkerDetail = () => {
    setShowMarkerDetails(false);
    setAirportName(null);
  };

  const handleShowMarkerDetail = (icao: string) => {
    if (selectedDesignData === icao) {
      setShowMarkerDetails(!isShowMarkerDetails);
      setAirportName(null);
    } else {
      setShowMarkerDetails(true);
    }
  };

  const handleSelectedMarkerData = (icao: string | null) => {
    setSelectedDesignData(icao);
  };
  const handleUserManagement = () => {
    navigate(routes.UserManagement);
  };

  const handleMapType = (type: string) => {
    setMapType(type);
  };

  const handleSearchAirportByName = (arpName: string | null) => {
    setAirportName(arpName);
  };

  const mapContainer = useMemo(() => {
    return (
      <MapContainer
        center={defaultCenter}
        zoom={5}
        maxZoom={15}
        zoomControl={false}
        className="map-container home-map-container"
      >
        <MapLayers maptype={mapType} />
        <MapActionControlV1 mapType={mapType} handleMapType={handleMapType} />
        <LiveCoordinates />
        <MarkerComponent
          markerData={designData}
          handleShowMarkerDetail={handleShowMarkerDetail}
          isShowMarkerDetails={isShowMarkerDetails}
          handleSelectedMarkerData={handleSelectedMarkerData}
          selectedDesign={selectedDesignData}
        />
        <ControlMapCenter zoomLevel={5} center={defaultCenter} />
      </MapContainer>
    );
  }, [isShowMarkerDetails, mapType, designData, selectedDesignData]);

  return (
    <div className="main-home">
      {mapContainer}
      <HomeSubHeader
        isMapPage
        selectedDesignData={selectedDesignData}
        searchAirportLists={searchAirportLists}
        handleSearchAirportByName={handleSearchAirportByName}
        handleSelectedMarkerData={handleSelectedMarkerData}
        handleShowMarkerDetail={handleShowMarkerDetail}
      />
      {isShowMarkerDetails && selectedDesignData && (
        <MarkerDetailsComponent
          handleCloseMarkerDetail={handleCloseMarkerDetail}
          selectedDesignData={selectedDesignData}
        />
      )}
      {((currentUser?.role === UserRole.Manager && isEmpty(designListData)) ||
        currentUser?.role === UserRole.Admin) && (
        <CreateNewDesignModal
          isOpen={isShowCreateDesignModal}
          handleCreatenewDesign={handleCreatenewDesign}
          handleOpenCloseModal={handleCloseCreateNewDesignModal}
          handleUserManagement={handleUserManagement}
        />
      )}
      {currentUser?.role === UserRole.Designer && (
        <NewdesignAssignModal
          isOpen={isShowCreateDesignModal}
          handleNewdesignAssign={handleNewdesignAssign}
          handleOpenCloseModal={handleCloseCreateNewDesignModal}
        />
      )}
    </div>
  );
};

export default Home;

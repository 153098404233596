import { Spin } from 'antd';
import { CgSpinner } from 'react-icons/cg';

interface FullScreenLoaderProps {
  loading: boolean;
  isShowText?: boolean;
  color?: string;
}

const FullScreenLoader = ({ loading, isShowText, color }: FullScreenLoaderProps) => {
  return loading ? (
    <div className="full-screen-loader">
      <div>
        <Spin indicator={<CgSpinner className="spinner" color={color} />} />
      </div>
      {isShowText && <div className="loading-text">Loading...</div>}
    </div>
  ) : null;
};
FullScreenLoader.defaultProps = {
  isShowText: true,
  color: '#000'
};

export default FullScreenLoader;

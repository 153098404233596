import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { includes, isEmpty, orderBy, remove } from 'lodash';
import { Moment } from 'moment';
import { Layout } from 'antd';
import Sider from 'antd/es/layout/Sider';
import { Content } from 'antd/es/layout/layout';
import Collect from 'components/CollectDataContainer/Collect';
import CollectSidebar from 'components/Sidebar/CollectSidebar';
import ContentScreenLoaderV1 from 'components/core/ContentScreenLoaderV1';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import {
  useGetDesignInitStartInfodataQuery,
  useGetDesignAllMetadataQuery,
  useGetDesignQuery,
  useGetVersionsQuery,
  useLazyGetSurveyObstaclesQuery,
  useGetNominalCirclesQuery
} from 'services/apis/designsApi';
import { setADData, setAirspaceData, setDesignAllMetaData, setSelectedDesign } from 'store/design';
import { DesignState, SurveyObstacleType } from 'store/design/types';
import { silentDesignMetadataByMetaidCall } from 'store/design/actions';
import { UserRole, UserState } from 'store/users/types';
import './styles.less';

const Collectdata = () => {
  const { designid }: any = useParams();
  const dispatch = useAppDispatch();
  const { userSession }: UserState = useAppSelector((state) => state.user);
  const { isLoadingDesignMetadataByMetaid }: DesignState = useAppSelector((state) => state.design);
  const isDesigner = Boolean(userSession?.role === UserRole.Designer);
  const [selectedDesignMetadata, setSelectedDesignMetadata] = useState<any>(null);
  const [currentMetaId, setCurrentMetaId] = useState<number | null>(null);
  const [surveyObstacleData, setSurveyObstacleData] = useState<SurveyObstacleType[] | []>([]);
  const [magneticDeclinationUnit, setMagneticDeclinationUnit] = useState<string>('w');

  const { data: designData, isLoading: isLoadingDesign } = useGetDesignQuery(
    { orgId: userSession?.organisationid, designId: designid },
    {
      refetchOnMountOrArgChange: true,
      skip: !(Boolean(userSession?.organisationid) && Boolean(designid))
    }
  );
  const { data: versionsData, isLoading: isLoadingVersion } = useGetVersionsQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );
  const {
    data: collectDesignAllMetadata,
    isSuccess: isSuccessGetMetadata,
    isLoading: isLoadingAllMetadata
  } = useGetDesignAllMetadataQuery(
    { orgId: userSession?.organisationid, designId: Number(designid) },
    {
      refetchOnMountOrArgChange: true,
      skip: !(Boolean(userSession?.organisationid) && Boolean(designid))
    }
  );
  const { data: nominalCirclesData } = useGetNominalCirclesQuery(Number(designid), {
    refetchOnMountOrArgChange: true,
    skip: !designid
  });

  const { data: initStartInfoCollectData, isLoading: isLoadingInitStartInfo } =
    useGetDesignInitStartInfodataQuery(
      { designId: designid },
      {
        refetchOnMountOrArgChange: true,
        skip: !designid
      }
    );

  const [getSurveyObstacles, { data: collectSurveyObstaclesdata }] =
    useLazyGetSurveyObstaclesQuery();

  const [loading, setLoading] = useState<boolean>(false);
  const [initInfoCollectData, setInitInfoCollectData] = useState<any>(null);
  const [stockCollapseActiveKey, setStockCollapseActiveKey] = useState<string[]>(['addata']);
  const [adDatacheckedList, setAdDataCheckedList] = useState<string[]>([
    'geoadministrative',
    'runwayphysicals',
    'declareddistaces',
    'aixmobstacles'
  ]);
  const [airspaceDatacheckedList, setAirspaceDataCheckedList] = useState<string[]>([]);
  const [airportId, setAirportId] = useState<number | null>(null);
  const [airportLat, setAirportLat] = useState<number | null>(null);
  const [airportLng, setAirportLng] = useState<number | null>(null);
  const [versionId, setVersionId] = useState<number | null>(null);
  const [tmin, setTmin] = useState<number | null | string>(null);
  const [tmax, setTmax] = useState<number | null | string>(null);
  const [surveyFile, setSurveyFile] = useState<File | undefined>(undefined);
  const [magneticVariation, setMagneticVariation] = useState<any>(null);
  const [nominalCircles, setNominalCircles] = useState<any>(null);
  const [isAddStockMagneticVariation, setAddStockMagneticVariation] = useState<boolean>(false);
  const [isAddSurveyFileObsDataToStock, setAddSurveyFileObsDataToStock] = useState<boolean>(false);
  const [magneticModalDate, setMagneticModalDate] = useState<Moment | null>(null);
  const [magneticVariationModal, setMagneticVariationModal] = useState<string>('');

  useEffect(() => {
    if (currentMetaId) {
      dispatch(
        silentDesignMetadataByMetaidCall(
          `/organisations/${userSession?.organisationid}/designs/${Number(
            designid
          )}/metadata/${currentMetaId}`,
          (selectedDesignMetadata: any) => {
            setSelectedDesignMetadata(selectedDesignMetadata || null);
          }
        )
      );
      getSurveyObstacles({
        metaId: currentMetaId
      });
    }
  }, [currentMetaId]);

  useEffect(() => {
    if (nominalCirclesData) {
      const nominalCirclesRes: any = nominalCirclesData;
      if (nominalCirclesRes.status) {
        setNominalCircles(nominalCirclesRes.data);
      }
    }
  }, [nominalCirclesData]);

  useEffect(() => {
    if (designData) {
      const designDataRes: any = designData;
      if (designDataRes.status) {
        dispatch(setSelectedDesign(!isEmpty(designDataRes.data) ? designDataRes.data : null));
        setAirportId(designDataRes.data ? designDataRes.data.airport_id : null);
        setAirportLat(designDataRes.data ? designDataRes.data.latitude : null);
        setAirportLng(designDataRes.data ? designDataRes.data.longitude : null);
      }
    }
  }, [designData]);

  useEffect(() => {
    if (initStartInfoCollectData) {
      const initStartInfoCollectDataRes: any = initStartInfoCollectData;
      if (initStartInfoCollectDataRes.status) {
        setInitInfoCollectData(initStartInfoCollectDataRes.data || null);
      }
    }
  }, [initStartInfoCollectData]);

  useEffect(() => {
    if (collectSurveyObstaclesdata) {
      const collectSurveyObstaclesdataRes: any = collectSurveyObstaclesdata;
      if (collectSurveyObstaclesdataRes.status) {
        setSurveyObstacleData(collectSurveyObstaclesdataRes.data || []);
      }
    }
  }, [collectSurveyObstaclesdata]);

  useEffect(() => {
    if (collectDesignAllMetadata) {
      const collectDesignAllMetadataRes: any = collectDesignAllMetadata;
      if (collectDesignAllMetadataRes.status) {
        dispatch(setDesignAllMetaData(collectDesignAllMetadataRes.data || []));
        if (!isEmpty(collectDesignAllMetadataRes.data)) {
          const data: any = orderBy(
            collectDesignAllMetadataRes.data,
            [(obj) => new Date(obj.created_at)],
            ['asc']
          );
          if (isEmpty(data)) return;
          setCurrentMetaId(data[0].id);
        }
      }
    }
  }, [collectDesignAllMetadata]);

  const handleGetUpdatedSurveyObsData = () => {
    if (currentMetaId) {
      setSurveyFile(undefined);
      getSurveyObstacles({
        metaId: currentMetaId
      });
    }
  };

  const handleLoading = (isLoad: boolean) => {
    setLoading(isLoad);
  };

  const handleCollapseActiveKey = (activekey: string[] | []) => {
    setStockCollapseActiveKey(activekey);
  };

  const handleCurrentVersion = (version: number | null) => {
    setVersionId(version);
  };

  const handleChangeTmin = (min: number | string | null) => {
    setTmin(min);
  };

  const handleChangeTmax = (max: number | string | null) => {
    setTmax(max);
  };

  const handleSetAdDataCheckedList = (data: string[]) => {
    setAdDataCheckedList(data);
  };

  const handleSetMagneticVariationModal = (magneticModal: string) => {
    setMagneticVariationModal(magneticModal);
  };

  const handleSetMagneticModalDate = (modalDate: Moment | null) => {
    setMagneticModalDate(modalDate);
  };

  const handleRemoveADDataStock = (dataKey: string) => {
    if (includes(adDatacheckedList, dataKey)) {
      remove(adDatacheckedList, (adDataVal: string) => adDataVal === dataKey);
    }
    setAdDataCheckedList([...adDatacheckedList]);
    dispatch(setADData([...adDatacheckedList]));
  };

  const handleSetAirspaceDataCheckedList = (dataKeyArr: string[]) => {
    setAirspaceDataCheckedList(dataKeyArr);
  };

  const handleRemoveAirspaceDataStock = (dataKey: string) => {
    if (includes(airspaceDatacheckedList, dataKey)) {
      remove(airspaceDatacheckedList, (adDataVal: string) => adDataVal === dataKey);
    }
    setAirspaceDataCheckedList([...airspaceDatacheckedList]);
    dispatch(setAirspaceData([...airspaceDatacheckedList]));
  };

  const handleSelectSurveyFile = (uploadedFile: File | undefined) => {
    setSurveyFile(uploadedFile);
  };

  const handleChangeMagneticVariation = (value: number | null) => {
    setMagneticVariation(value);
    setAddStockMagneticVariation(false);
  };

  const handleAddtoStockMagneticVar = (isAdd: boolean) => {
    setAddStockMagneticVariation(isAdd);
  };

  const handleAddSurveyFileObsDataToStock = (isAdd: boolean) => {
    setAddSurveyFileObsDataToStock(isAdd);
    if (isAdd) {
      handleCollapseActiveKey(['obstacle']);
    } else {
      setSurveyFile(undefined);
    }
  };

  const handleChangeMagneticUnit = (magneticUnit: string) => {
    setMagneticDeclinationUnit(magneticUnit);
  };

  return (
    <>
      <Layout hasSider>
        <Content className="main-content">
          <Collect
            tmin={tmin}
            tmax={tmax}
            designData={designData}
            isDesigner={isDesigner}
            collectDesignMetadata={selectedDesignMetadata || null}
            collectDesignAllMetadata={collectDesignAllMetadata || []}
            versionsData={versionsData}
            initInfoCollectData={initInfoCollectData}
            airportLat={airportLat}
            airportLng={airportLng}
            handleCollapseActiveKey={handleCollapseActiveKey}
            handleCurrentVersion={handleCurrentVersion}
            handleChangeTmin={handleChangeTmin}
            handleChangeTmax={handleChangeTmax}
            handleLoading={handleLoading}
            adDatacheckedList={adDatacheckedList}
            handleSetAdDataCheckedList={handleSetAdDataCheckedList}
            airspaceDatacheckedList={airspaceDatacheckedList}
            handleSetAirspaceDataCheckedList={handleSetAirspaceDataCheckedList}
            surveyObstacleData={surveyObstacleData}
            currentMetaId={currentMetaId}
            surveyFile={surveyFile}
            handleSelectSurveyFile={handleSelectSurveyFile}
            handleChangeMagneticVariation={handleChangeMagneticVariation}
            magneticVariation={magneticVariation}
            handleAddtoStockMagneticVar={handleAddtoStockMagneticVar}
            handleAddSurveyFileObsDataToStock={handleAddSurveyFileObsDataToStock}
            handleChangeMagneticUnit={handleChangeMagneticUnit}
            magneticDeclinationUnit={magneticDeclinationUnit}
            nominalCircles={nominalCircles}
            handleSetMagneticVariationModal={handleSetMagneticVariationModal}
            magneticVariationModal={magneticVariationModal}
            handleSetMagneticModalDate={handleSetMagneticModalDate}
            magneticModalDate={magneticModalDate}
          />
        </Content>
        <Sider className="data-sidebar" width={288}>
          <CollectSidebar
            tmin={tmin}
            tmax={tmax}
            isDisabled={!isSuccessGetMetadata}
            isDesigner={isDesigner}
            designid={designid}
            airportId={airportId}
            collapseActiveKey={stockCollapseActiveKey}
            versionId={versionId}
            airportLat={airportLat}
            airportLng={airportLng}
            surveyFile={surveyFile}
            currentMetaId={currentMetaId}
            handleLoading={handleLoading}
            handleRemoveADDataStock={handleRemoveADDataStock}
            handleRemoveAirspaceDataStock={handleRemoveAirspaceDataStock}
            handleGetUpdatedSurveyObsData={handleGetUpdatedSurveyObsData}
            magneticVariation={magneticVariation}
            isAddStockMagneticVariation={isAddStockMagneticVariation}
            isAddSurveyFileObsDataToStock={isAddSurveyFileObsDataToStock}
            handleAddSurveyFileObsDataToStock={handleAddSurveyFileObsDataToStock}
            magneticDeclinationUnit={magneticDeclinationUnit}
            magneticModalDate={magneticModalDate}
            magneticVariationModal={magneticVariationModal}
          />
        </Sider>
      </Layout>
      <ContentScreenLoaderV1
        loading={
          isLoadingDesign ||
          isLoadingVersion ||
          isLoadingAllMetadata ||
          isLoadingInitStartInfo ||
          loading ||
          isLoadingDesignMetadataByMetaid
        }
      />
    </>
  );
};

export default Collectdata;

import { enqueueError } from 'store/alert';
import { client } from 'services/axiosClient';
import { RSA_URL } from 'utils/apiUrls';
import { AppDispatch } from '..';

export type Rsa = {
  rsa: string;
};

export const getRSA = (callback: Function) => async (dispatch: AppDispatch) => {
  try {
    const result = await client.get(RSA_URL);
    if (callback) callback(result.data);
  } catch (error: any) {
    dispatch(enqueueError('Something went wrong!'));
  }
};

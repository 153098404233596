import { useState } from 'react';
import { Popup, GeoJSON } from 'react-leaflet';
import { isEmpty, upperCase } from 'lodash';
import { Space, Typography } from 'antd';
import { red1 } from 'constants/color.constant';
import { FeatureGeometryType } from 'types/common.types';

const { Text } = Typography;

interface RenderFIRAirspaceProps {
  firAirspaceData: any;
}

const RenderFIRAirspace = ({ firAirspaceData }: RenderFIRAirspaceProps) => {
  const [selectedOverE, setSelectedOverE] = useState<FeatureGeometryType | null>(null);
  if (firAirspaceData && isEmpty(firAirspaceData?.features)) return null;

  const handlePolygonOver = (e: any, element: any) => {
    e.target.bringToFront();
    setSelectedOverE(element);
  };

  const handlePolygonOut = () => {
    setSelectedOverE(null);
  };

  return (
    // eslint-disable-next-line
    <>
      {firAirspaceData?.features.map((data: any) => {
        return (
          <GeoJSON
            key={data.name}
            pane="tilePane"
            data={data}
            pathOptions={{
              color: selectedOverE?.properties.name === data.properties.name ? red1 : '#0112dd',
              weight: selectedOverE?.properties.name === data.properties.name ? 3 : 2,
              fillColor: '#0112dd'
            }}
            eventHandlers={{
              mouseover: (e: any) => handlePolygonOver(e, data),
              mouseout: () => handlePolygonOut()
            }}
          >
            {!isEmpty(data.properties) && (
              <Popup className="polygon-popup">
                <Space direction="vertical">
                  {Object.keys(data.properties).map((key: any) => (
                    <Space direction="vertical" key={key}>
                      <Text className="tooltip-title" style={{ fontFamily: 'Manrope' }}>
                        {upperCase(key).replace(/[^a-zA-Z0-9 ]/g, ' ')}
                      </Text>
                      <Text className="tooltip-content" style={{ fontFamily: 'Manrope' }}>
                        {data.properties[key] || '-'}
                      </Text>
                    </Space>
                  ))}
                </Space>
              </Popup>
            )}
          </GeoJSON>
        );
      })}
    </>
  );
};

export default RenderFIRAirspace;

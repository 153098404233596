import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Divider, Form, Input, InputNumber, Row, Select, Typography } from 'antd';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import DownArrow from 'assets/svg/DownArrow';
import PageHeader from 'components/PageHeader/PageHeader';
import { designationAdmin } from 'constants/userData.constants';
import { routes } from 'constants/pageRoutes.constants';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { UpdateUserPayload, UserRole } from 'store/users/types';
import { setOrganisation } from 'store/users';
import { useGetOrganisationQuery, useUpdateUserMutation } from 'services/apis/userApi';
import { decryptValue, encryptValue } from 'utils/decodeEncodeData';
import './styles.less';

const { Text } = Typography;

const Memberdetail = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { userSession } = useAppSelector((state) => state.user);
  const { data: organisationData, refetch: getOrganisationRefetch } = useGetOrganisationQuery(
    userSession ? userSession.organisationid : skipToken
  );
  const [updateUser, { isLoading: isLoadingUpdateUser, isSuccess: isSuccessUpdateUser }] =
    useUpdateUserMutation();

  useEffect(() => {
    if (organisationData) {
      const organisationResponse: any = organisationData;
      if (organisationResponse.status) {
        form.setFieldsValue({
          orgname: organisationResponse.data ? organisationResponse.data.name : undefined
        });
        dispatch(setOrganisation(organisationResponse.data));
      }
    }
  }, [organisationData]);

  useEffect(() => {
    if (!userSession) return;
    if (userSession.firstname) {
      navigate(routes.Home, { replace: true });
    }
    getOrganisationRefetch();
    form.setFieldsValue({
      useremail: userSession.email ? decryptValue(userSession.email) : undefined,
      city: 'Banglore',
      country: 'india'
    });
  }, []);

  useEffect(() => {
    if (isSuccessUpdateUser) {
      if (userSession.role === UserRole.Designer || userSession.role === UserRole.Reviewer) {
        navigate(routes.Home, { replace: true });
      } else {
        navigate(routes.OrgOnBoard, { replace: true });
      }
    }
  }, [isSuccessUpdateUser]);

  const handleSubmitUserDetail = async (values: UpdateUserPayload) => {
    const payload = {
      firstname: encryptValue(values.firstname),
      lastname: encryptValue(values.lastname),
      phone: encryptValue(values.phone.toString()),
      empId: Number(values.empId),
      designation: values.designation,
      city: values.city,
      country: values.country,
      yearsofexp: values.yearsofexp,
      gender: '',
      age: 0
    };

    await updateUser({ data: payload, userId: userSession.id });
  };

  return (
    <div className="main-detail">
      <div className="detail-label">
        <PageHeader title="Enter your Details" />
      </div>
      <div className="member-detail">
        <Text>Personal Details</Text>
        <Divider />
      </div>
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        className="detail-form"
        onFinish={handleSubmitUserDetail}
      >
        <div className="form-main">
          <Row gutter={[64, 24]} className="input-main">
            <Col>
              <Form.Item
                name="firstname"
                rules={[
                  { required: true },
                  {
                    pattern: /^(?!\s).+(?<!\s)$/,
                    message: 'Enter valid first name'
                  }
                ]}
                label="First Name"
                className="input-label"
              >
                <Input placeholder="Enter here" className="form-input" />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="lastname"
                rules={[
                  { required: true },
                  {
                    pattern: /^(?!\s).+(?<!\s)$/,
                    message: 'Enter valid last name'
                  }
                ]}
                label="Last Name"
                className="input-label"
              >
                <Input placeholder="Enter here" className="form-input" />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="orgname" label="Organisation Name" className="input-label">
                <Input placeholder="Enter here" className="form-input" readOnly />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[64, 24]} className="input-main">
            <Col>
              <Form.Item
                name="empId"
                rules={[
                  { required: true, message: 'Required' },
                  {
                    pattern: /^[0-9]*$/,
                    message: 'Add only number'
                  }
                ]}
                label="Employee ID"
                className="input-label phone-input-num"
              >
                <InputNumber placeholder="Enter here" maxLength={6} className="form-input" />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="designation"
                rules={[{ required: true }]}
                label="Position / Designation"
                className="input-label"
              >
                <Select
                  suffixIcon={<DownArrow />}
                  showSearch
                  placeholder="Select"
                  className="form-input form-input-select"
                  options={designationAdmin}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="yearsofexp"
                rules={[
                  { required: true, message: 'Required' },
                  {
                    pattern: /^[0-9]*$/,
                    message: 'Add only number'
                  }
                ]}
                label="Years of Experience"
                className="input-label phone-input-num"
              >
                <InputNumber maxLength={2} placeholder="Enter here" className="form-input" />
              </Form.Item>
            </Col>
          </Row>
          <Divider className="form-divider" />
          <Row gutter={[64, 24]} className="input-main">
            <Col>
              <Form.Item
                name="useremail"
                rules={[{ required: true }]}
                label="Email Address"
                className="input-label"
              >
                <Input placeholder="Enter here" className="form-input" disabled />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="phone"
                rules={[
                  { required: true, message: 'Required' },
                  {
                    pattern: /^[0-9]*$/,
                    message: 'Add only number'
                  }
                ]}
                label="Phone Number"
                className="input-label phone-input-num"
              >
                <InputNumber maxLength={12} placeholder="Enter here" className="form-input" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[64, 24]} className="input-main">
            <Col>
              <Form.Item
                name="country"
                rules={[{ required: true, message: 'Select Country' }]}
                label="Country"
                className="input-label"
              >
                <Select
                  suffixIcon={<DownArrow />}
                  showSearch
                  placeholder="Select"
                  className="form-input form-input-select"
                  allowClear
                  labelInValue
                  options={[{ id: 1, label: 'India', value: 'india' }]}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="city"
                rules={[{ required: true }]}
                label="City"
                className="input-label"
              >
                <Input placeholder="Enter here" className="form-input" readOnly />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div>
          <Button
            className="save-btn"
            loading={isLoadingUpdateUser}
            type="primary"
            htmlType="submit"
            block
            size="large"
          >
            Save & Continue
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default Memberdetail;

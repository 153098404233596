import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { find, isEmpty, remove, uniq } from 'lodash';
import { Button, Divider, Form, Input, Table, Typography, Select, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import DeleteIcon from 'assets/svg/DeleteIcon';
import RequiredTitle from 'components/core/RequiredTitle';
import PageHeader from 'components/PageHeader/PageHeader';
import { routes } from 'constants/pageRoutes.constants';
import { adminMemberRole, memberRole } from 'constants/userData.constants';
import { useAppSelector } from 'hooks/useReduxHook';
import {
  useAddOrganisationMembersMutation,
  useGetOrganisationsMembersQuery
} from 'services/apis/userApi';
import { OrganisationMember, UserRole, UserRoleText } from 'store/users/types';
import { decryptValue } from 'utils/decodeEncodeData';
import './styles.less';

const { Text } = Typography;

const Organization = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const currentUser = useAppSelector((state) => state.user.userSession);
  const { data: organisationMembers, isLoading: isLoadingOrgMembers } =
    useGetOrganisationsMembersQuery(
      currentUser && currentUser.organisationid ? currentUser.organisationid : skipToken
    );

  const [
    addOrganisationMembers,
    { isLoading: isAddOrgMembersLoading, isSuccess: isSuccessAddOrgMembers }
  ] = useAddOrganisationMembersMutation();
  const [orgMembers, setOrgMembers] = useState<OrganisationMember[] | []>([]);
  const [addOrgMembers, setAddOrgMembers] = useState<any>([]);

  useEffect(() => {
    if (isSuccessAddOrgMembers) {
      navigate(routes.Home);
    }
  }, [isSuccessAddOrgMembers]);

  useEffect(() => {
    if (organisationMembers) {
      const orgMenbersResponse: any = organisationMembers;
      if (orgMenbersResponse.status) {
        setOrgMembers(orgMenbersResponse.data || []);
      }
    }
  }, [organisationMembers]);

  const handleAddMember = (values: { email: string; role: string }) => {
    if (!find(orgMembers.concat(addOrgMembers), ['email', values.email])) {
      const addNewMembers = [...addOrgMembers];
      addNewMembers.push({
        email: values.email,
        role: values.role
      });
      setAddOrgMembers([...addNewMembers]);
      form.resetFields();
    } else {
      messageApi.error('Email already exist');
    }
  };

  const handleSkipDoneAddMembers = async (type: string) => {
    if (isEmpty(addOrgMembers) && type !== 'skip') {
      messageApi.error('Atleast add one new member!');
    } else if (type === 'done') {
      const payload = { members: addOrgMembers };
      await addOrganisationMembers({ data: payload, orgId: currentUser.organisationid });
    } else if (type === 'skip') {
      navigate(routes.Home);
    }
  };

  const handleRemoveMember = (email: string) => {
    const oldAddOrgMembers = [...addOrgMembers];
    remove(oldAddOrgMembers, (member: any) => member.email === email);

    setAddOrgMembers([...oldAddOrgMembers]);
  };

  const columnsData: ColumnsType<OrganisationMember> = [
    {
      title: 'EMAIL ADDRESS',
      dataIndex: 'email',
      key: 'email',
      render: (email) => {
        if (find(orgMembers, ['email', email])) {
          return email ? decryptValue(email) : '-';
        }
        return email;
      }
    },
    {
      title: 'ROLE',
      dataIndex: 'role',
      key: 'role',
      render: (role) => {
        return role ? UserRoleText[role] : '-';
      }
    },
    {
      title: 'ACTIONS',
      key: 'action',
      align: 'center',
      width: '15%',
      render: (record) => {
        return (
          !find(orgMembers, ['email', record.email]) && (
            <Button
              type="text"
              onClick={() => handleRemoveMember(record.email)}
              icon={<DeleteIcon />}
            />
          )
        );
      }
    }
  ];

  return (
    <div className="main-org">
      {contextHolder}
      <div className="org-label">
        <PageHeader title="Organisation Onboarding" />
      </div>
      <div className="org-member">
        <div>
          <Text>Add Members to Organisation</Text>
          <div>
            <Button onClick={() => handleSkipDoneAddMembers('skip')} className="skip-btn">
              Skip
            </Button>
            <Button
              type="primary"
              className="done-btn"
              loading={isAddOrgMembersLoading}
              onClick={() => handleSkipDoneAddMembers('done')}
            >
              Done
            </Button>
          </div>
        </div>
        <Divider />
      </div>
      <Form autoComplete="off" form={form} onFinish={handleAddMember} className="add-member-form">
        <div className="add-member-content">
          <div className="member-content">
            <div className="input-main-content">
              <div>
                <RequiredTitle title="Email address of Member" className="form-title" />
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: 'Please Enter the valid mail id' },
                    {
                      pattern: /^[a-z0-9.]+@[a-z]+\.[a-z]{2,3}$/,
                      message: 'Enter the valid email'
                    }
                  ]}
                  style={{ marginBottom: 0 }}
                >
                  <Input type="email" placeholder="Enter here" className="add-member-input" />
                </Form.Item>
              </div>
              <div>
                <RequiredTitle title="Role" className="form-title" />
                <Form.Item
                  name="role"
                  rules={[{ required: true, message: 'Please Enter the valid role' }]}
                  style={{ marginBottom: 0 }}
                >
                  <Select
                    className="add-member-select"
                    placeholder="Select"
                    allowClear
                    options={currentUser?.role === UserRole.Admin ? adminMemberRole : memberRole}
                    // components={{
                    //   IndicatorSeparator: () => null
                    // }}
                    // getOptionLabel={(opt: any) => opt.label}
                    // styles={styles.customStyles}
                  />
                </Form.Item>
              </div>
              {/* {currentUser.role === UserRole.Manager && (
                <div>
                  <RequiredTitle title="Select Designer" className="form-title" />
                  <Select
                    name="designer"
                    className="add-member-select"
                    placeholder="Select"
                    components={{
                      IndicatorSeparator: () => null
                    }}
                    styles={styles.customStyles}
                  />
                </div>
              )} */}
            </div>
            <div>
              <Button
                className="add-user-btn"
                type="primary"
                htmlType="submit"
                block
                disabled={isAddOrgMembersLoading}
                size="large"
                ghost
              >
                Add User
              </Button>
            </div>
          </div>
        </div>
      </Form>
      <div className="user-list-table">
        <Table
          loading={isLoadingOrgMembers}
          className="user-table"
          scroll={{ y: 'calc(100% - 55px)' }}
          dataSource={uniq(orgMembers.concat(addOrgMembers)) || []}
          columns={columnsData}
          pagination={false}
          rowKey={(record) => record.email}
        />
      </div>
    </div>
  );
};

export default Organization;

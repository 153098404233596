import { IconProps } from 'types/common.types';

const ViewDesignIcon = ({ height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 7.5V5.25C3 4.65326 3.23705 4.08097 3.65901 3.65901C4.08097 3.23705 4.65326 3 5.25 3H7.5M3 16.5V18.75C3 19.3467 3.23705 19.919 3.65901 20.341C4.08097 20.7629 4.65326 21 5.25 21H7.5M16.5 3H18.75C19.3467 3 19.919 3.23705 20.341 3.65901C20.7629 4.08097 21 4.65326 21 5.25V7.5M16.5 21H18.75C19.3467 21 19.919 20.7629 20.341 20.341C20.7629 19.919 21 19.3467 21 18.75V16.5M12 10.875V10.8863M12 18.75L8.0625 13.125C7.68345 12.4399 7.48971 11.6678 7.50042 10.8849C7.51113 10.102 7.72592 9.33547 8.12357 8.661C8.52121 7.98654 9.08795 7.4275 9.76779 7.03911C10.4476 6.65073 11.217 6.44645 12 6.44645C12.783 6.44645 13.5524 6.65073 14.2322 7.03911C14.912 7.4275 15.4788 7.98654 15.8764 8.661C16.2741 9.33547 16.4889 10.102 16.4996 10.8849C16.5103 11.6678 16.3165 12.4399 15.9375 13.125L12 18.75Z"
        stroke="#6E3BD1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
ViewDesignIcon.defaultProps = {
  width: 24,
  height: 24,
  onClick: () => {}
};

export default ViewDesignIcon;

import { isEmpty, keys } from 'lodash';
import { FeatureGroup, MapContainer, Marker } from 'react-leaflet';
import AircraftIconV1 from 'assets/svg/AircraftIconV1';
import { ObstacleRedIcon } from 'constants/map.constants';
import ReportContentCard from 'components/core/ReportContentCard';
import MapLayers from 'components/Map/MapLayers';
import RenderLegData from 'components/Map/RenderLegData';
import RFArcLine from 'components/Map/RFArcLine';
import { useAppSelector } from 'hooks/useReduxHook';
import { DesignState } from 'store/design/types';
import { Maptype } from 'types/common.types';
import { generateString } from 'utils/utils';
import ReportSecTitleWithIcon from './ReportSecTitleWithIcon';
import ObstacleAnalysisTable from './ObstacleAnalysisTable';
import { Fragment } from 'react';

interface Section11Props {
  scrollElementRef: any;
  sec11: any;
  count?: number;
}

const Section11 = ({ scrollElementRef, count, sec11 }: Section11Props) => {
  const { selectedDesign }: DesignState = useAppSelector((state) => state.design);
  if (!selectedDesign) return null;

  return (
    <div
      className="report-code-content sec-3"
      key={count}
      ref={scrollElementRef}
      id="obstacleanalysis"
    >
      <ReportSecTitleWithIcon title="11. OBSTACLE ANALYSIS" icon={null} />
      <div className="report-content-data">
        {keys(sec11).map((ct: string, i) => (
          <div className="coding-table-content" key={`${generateString(i + 15)}-${ct}-${i + 1}`}>
            <ReportContentCard
              icon={<AircraftIconV1 height={16} />}
              title={`${ct} ( DER - ${sec11[`${ct}`].end_point} )`}
            />
            <div className="obstacle-table">
              <div className="obstacle-map">
                <MapContainer
                  className="obs-map"
                  zoomControl={false}
                  center={[selectedDesign?.latitude, selectedDesign?.longitude]}
                  zoom={10}
                >
                  <FeatureGroup>
                    {sec11[`${ct}`]?.legs &&
                      keys(sec11[`${ct}`]?.legs).map((legkey, i) => {
                        return (
                          <Fragment key={`${legkey}-${i}-leg-${ct}-${generateString(i + 5)}`}>
                            <RenderLegData
                              key={generateString(i + 7)}
                              legsData={sec11[`${ct}`]?.legs}
                              activeLegKey={legkey}
                              procedure={
                                sec11[`${ct}`]?.legs[`${legkey}`].parameters.type === 'OMNI'
                                  ? sec11[`${ct}`]?.legs[`${legkey}`].parameters.type
                                  : 'DEPARTURE'
                              }
                              isLine
                              selectedPathTerminator={
                                sec11[`${ct}`]?.legs[`${legkey}`].parameters.type === 'RF'
                                  ? 'RF'
                                  : null
                              }
                              magneticDeclination={null}
                            />
                            {sec11[`${ct}`]?.legs[`${legkey}`].parameters.type === 'RF' && (
                              <RFArcLine
                                finalArcCords={
                                  sec11[`${ct}`]?.legs[`${legkey}`]?.geo_parameters?.nominal_track
                                    ? sec11[`${ct}`]?.legs[`${legkey}`]?.geo_parameters
                                        ?.nominal_track
                                    : []
                                }
                              />
                            )}
                            {!isEmpty(sec11[`${ct}`]?.obstacles) &&
                              sec11[`${ct}`]?.obstacles.map((obs: any) => {
                                if (isEmpty(obs?.coordinates)) return null;
                                const coords: any = [obs?.coordinates[1], obs?.coordinates[0]];
                                return <Marker icon={ObstacleRedIcon} position={coords} />;
                              })}
                          </Fragment>
                        );
                      })}
                  </FeatureGroup>
                  <MapLayers maptype={Maptype.Roadmap} />
                </MapContainer>
              </div>
              <ObstacleAnalysisTable obstacledata={sec11[`${ct}`]?.obstacles || []} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

Section11.defaultProps = {
  count: 0
};

export default Section11;

import { Typography } from 'antd';
import { DesignStatusBackgoundColor, DesignStatusColor } from 'constants/common.constant';
import './styles.less';

const { Text } = Typography;

interface DesignStatusComponentsProps {
  data: string;
}

const DesignStatusComponents = ({ data }: DesignStatusComponentsProps) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Text
        className="design-status"
        style={{
          color: DesignStatusColor[data],
          backgroundColor: DesignStatusBackgoundColor[data]
        }}
      >
        {data || '-'}
      </Text>
    </div>
  );
};

export default DesignStatusComponents;

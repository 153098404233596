import Table, { ColumnsType } from 'antd/es/table';

interface ObsPDGConclusionProps {
  conclusion: any[];
}

const ObsPDGConclusion = ({ conclusion }: ObsPDGConclusionProps) => {
  const columns: ColumnsType<any> = [
    {
      title: 'CONCLUSION',
      align: 'left',
      key: 'title',
      className: 'table-col-data table-tol-col-data',
      children: [
        {
          title: 'PDG Required',
          dataIndex: 'pdg',
          key: 'pdgreq',
          align: 'center',
          className: 'table-col-data'
        },
        {
          title: 'Up to',
          dataIndex: 'up_to',
          key: 'upto',
          align: 'center',
          className: 'table-col-data'
        },
        {
          title: 'Remarks',
          dataIndex: 'remarks',
          key: 'remarks',
          align: 'center',
          className: 'table-col-data'
        }
      ]
    }
  ];
  return (
    <Table
      columns={columns}
      dataSource={conclusion}
      pagination={false}
      rowKey={(record) => `${record.pdg}-${Math.floor(Math.random() * 100)}`}
      rowClassName={() => 'table-row-data '}
    />
  );
};

export default ObsPDGConclusion;

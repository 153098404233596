export const primary = '#6E3BD1';
export const secondary = '#42444a';
export const white = '#ffffff';
export const black = '#000000';
export const blackShade = '#030303';
export const lightGrey = '#E0E0E0';
export const red = '#EB5757';
export const red1 = '#da0000';
export const red2 = '#f90000';
export const red3 = '#FF3B3B';
export const orange = '#F2994A';
export const green = '#27AE60';
export const blue = '#2F80ED';
export const lightGreen = '#43ffdd';
export const lightGrey1 = '#C7C7CC';
export const lightGrey2 = '#95969D';
export const typocolor = '#696B72';
export const areaHighLightColor = '#c62828';

export const colorTray = [
  '#7F5E8F',
  '#D46861',
  '#74AA87',
  '#F4F525',
  '#4930DB',
  '#CD9D73',
  '#C6E09F',
  '#7BB899',
  '#8862AC',
  '#FBF505',
  '#CF7C1B',
  '#F1035C',
  '#2904D9',
  '#7AF5AA',
  '#FB5FDB',
  '#3AC071',
  '#F1A02F',
  '#EB5F12',
  '#547FDE',
  '#E1679B',
  '#A600DB',
  '#2D373B',
  '#39C85A',
  '#654E82',
  '#1C8536',
  '#CF5ADC',
  '#4BAF20',
  '#403511',
  '#D37A3F',
  '#D83990',
  '#FD333E',
  '#4793CE',
  '#342C9B',
  '#34BBB4',
  '#351599',
  '#B832C1',
  '#02319A',
  '#196F14',
  '#C916B0',
  '#CB3717',
  '#3426E2',
  '#9E5B5C',
  '#43D9B2',
  '#2B410E',
  '#0BCFFD',
  '#CEA80F',
  '#2999F0',
  '#86E49B',
  '#989EE7',
  '#FA1415',
  '#417FBE',
  '#7A247F',
  '#525814',
  '#CF986D',
  '#51FE33',
  '#59928B',
  '#43FEE2',
  '#3AF823',
  '#4ED2F9',
  '#5FF407',
  '#31EB1C',
  '#1A31A4',
  '#1470A1',
  '#CB5EE0',
  '#BFE00B',
  '#85D2CF',
  '#091FE0',
  '#B9CF10',
  '#20EB7A',
  '#8ED55B',
  '#EF363B',
  '#CF8512',
  '#756CA6',
  '#E3E74B',
  '#15ABF5',
  '#611D6F',
  '#4B158C',
  '#3F4C1F',
  '#14E111',
  '#688B40',
  '#606E1E',
  '#3BA3C4',
  '#B00878',
  '#31E26E',
  '#2794D6',
  '#CF54EE',
  '#23A408',
  '#692EDB',
  '#D344C7',
  '#728082',
  '#B16704',
  '#D0D374',
  '#3AE752',
  '#A69608',
  '#465017',
  '#E115F3',
  '#C8D82D',
  '#63DBB1',
  '#FAB921',
  '#F4A11C',
  '#5CD1B1',
  '#4A0333',
  '#50B0C5',
  '#2BEA6D',
  '#CD634A',
  '#771426',
  '#574A33',
  '#40B4DF',
  '#A57DD0',
  '#8496F2',
  '#4DF9B7',
  '#74F931',
  '#C9511F',
  '#A065E0',
  '#622AE8',
  '#D1CA71',
  '#88C85D',
  '#FD4343',
  '#BE9F13',
  '#FC34A3',
  '#F440C7',
  '#CCE114',
  '#F8889A',
  '#F3D1C8',
  '#3242B4',
  '#6FA49C',
  '#E98847',
  '#CF421B',
  '#9A3DB9',
  '#CA81E8',
  '#4332CD',
  '#9A478C',
  '#4E29DA',
  '#B6B814',
  '#E06EC7',
  '#5342C1',
  '#822018',
  '#87BD31',
  '#CFF670',
  '#785DBC',
  '#6E064D',
  '#6E7321',
  '#9E5D32',
  '#814CB1',
  '#001DB8',
  '#340C58',
  '#6332EA',
  '#E0F0D7',
  '#8A547E',
  '#2A09E7',
  '#6AC3F3',
  '#7EBF55',
  '#42FE71',
  '#9E372E',
  '#1D8650',
  '#2AE72A',
  '#DE5EA0',
  '#991605',
  '#273D74',
  '#DDC50B',
  '#2CBFED',
  '#15E2E4',
  '#0152E6',
  '#BD94DA',
  '#4E8656',
  '#6123B1',
  '#A91511',
  '#C35859',
  '#5AF0E4',
  '#7F8C0E',
  '#F1A654',
  '#A0BEBB',
  '#429196',
  '#04520F',
  '#247FA5',
  '#31A38A',
  '#FF06CC',
  '#94871C',
  '#77EFE9',
  '#C75666',
  '#99A7D5',
  '#11222B',
  '#4D3210',
  '#9CB7D8',
  '#180625',
  '#20DAD3',
  '#0D8C1C',
  '#C7A44E',
  '#9BBC97',
  '#9A9880',
  '#0E00B3',
  '#BB0E43',
  '#BFF81F',
  '#D9D5D0',
  '#3F1192',
  '#8CA828',
  '#EA1AA7',
  '#859AD8',
  '#25237E',
  '#5BD318',
  '#BCBC41',
  '#BE5F64',
  '#CDDB0E',
  '#06686D',
  '#86C12F',
  '#B4F2FB',
  '#9E7AAD',
  '#0A6E62',
  '#27A7C4',
  '#EB16EB',
  '#8D520C',
  '#F3D000',
  '#0F5D0F',
  '#D39C00',
  '#11503C',
  '#F0A64C',
  '#F389FC',
  '#532925',
  '#F1AFC7',
  '#0F3691',
  '#FF27B8',
  '#B7D30A',
  '#89A181',
  '#404418',
  '#952ED5',
  '#092A99',
  '#AE1BEB',
  '#46B333',
  '#F1D021',
  '#25F907',
  '#48BFA7',
  '#23AFDD',
  '#F54438',
  '#711374',
  '#77142D',
  '#0DA0BB',
  '#F93F51',
  '#9B39C1',
  '#36D090',
  '#B9B4B7',
  '#25E9DA',
  '#C963B6',
  '#FC19D2',
  '#83A9D3',
  '#A5B822',
  '#BF5817',
  '#FCFBBB',
  '#04D3E3',
  '#F4B2EC',
  '#A9DD77',
  '#A817B0',
  '#3BD43D',
  '#E95D42',
  '#E9BBE6',
  '#9A14F2',
  '#111C0E',
  '#AE7635',
  '#306A7D',
  '#47ACAB',
  '#143D5A',
  '#A3F92D',
  '#E9CE86',
  '#7EA535',
  '#01EEDE',
  '#B170FB',
  '#B253C9',
  '#F81902',
  '#D48456',
  '#324D69',
  '#4A21B1',
  '#4FE9EC',
  '#EE3852',
  '#B03267',
  '#CA3050',
  '#C7C1E4',
  '#1A4A26',
  '#E7D568',
  '#199D2B',
  '#4AF89B',
  '#B2B569',
  '#27E22F',
  '#C384D7',
  '#5D31EA',
  '#0FD66B',
  '#D2B843',
  '#B65C6D',
  '#937E76',
  '#5F2D7F',
  '#218901',
  '#9155CF',
  '#BDA389',
  '#847F0D',
  '#B9F25B',
  '#A0D33A',
  '#C80DA9',
  '#F86DFB',
  '#5A47DF',
  '#C5B5DF',
  '#E17DE5',
  '#74706B',
  '#E66261',
  '#A0C37D',
  '#BB0F20',
  '#9C5F58',
  '#70F6C6',
  '#AD1B1C',
  '#22D3F1',
  '#48B5A0',
  '#F4C9EC',
  '#C26650',
  '#644D36',
  '#662387',
  '#4814CA',
  '#BE6805',
  '#94506E',
  '#6A70E0',
  '#BEF85A',
  '#0723AF',
  '#3C9A85',
  '#EA0DDB',
  '#16971C',
  '#66F1A7',
  '#4BFC55',
  '#500837',
  '#2A8C40',
  '#7F2B69',
  '#FA8AB7',
  '#C7E93F',
  '#D706D4',
  '#CE0898',
  '#421EBE',
  '#24963F',
  '#E49E04',
  '#327B4B',
  '#62BD70',
  '#E4942F',
  '#41B42F',
  '#D2A517',
  '#C2FAC4',
  '#22985D',
  '#717AC1',
  '#5F949F',
  '#51EF33',
  '#53A8AB',
  '#D01862',
  '#6FF056',
  '#ACEEAF',
  '#53E1BE',
  '#615836',
  '#4019AB',
  '#E594B9',
  '#1DB55B',
  '#EDF46C',
  '#6BAC27',
  '#36B2A9',
  '#3C551A',
  '#05C9F7',
  '#903733',
  '#82B768',
  '#91F059',
  '#DD596E',
  '#E44A8A',
  '#6BEE24',
  '#FD30D9',
  '#D0EDD1',
  '#5FAF16',
  '#DEF6FE',
  '#2E9025',
  '#4AF5BE',
  '#5CD1C6',
  '#7BF140',
  '#3302B4',
  '#5C6F29',
  '#F90B3D',
  '#F93D53',
  '#D3C3B0',
  '#B97BEF',
  '#2B9DDD',
  '#A7E020',
  '#BAC845',
  '#B5F962',
  '#A8F410',
  '#1876B1',
  '#04BB93',
  '#5700D6',
  '#517B25',
  '#CAFC78',
  '#43B442',
  '#5AF8F1',
  '#6E320C',
  '#8BBE4E',
  '#CBBD90',
  '#86FE14',
  '#E49FCF',
  '#44DA0E',
  '#1EA078',
  '#A95A2E',
  '#D7A1DF',
  '#6C54BE',
  '#6A1CCD',
  '#7D150B',
  '#ECC176',
  '#5D01FC',
  '#F6E91D',
  '#EF675D',
  '#57E7A4',
  '#CCA10F',
  '#D39A42',
  '#6EA125',
  '#864F14',
  '#E45175',
  '#0B21EC',
  '#FF2CE6',
  '#7987AF',
  '#7E2DB7',
  '#9B706E',
  '#BD2C28',
  '#9A4D97',
  '#8225FE',
  '#8A3891',
  '#A18AE1',
  '#BAFA7F',
  '#14C1EE',
  '#E5D6F8',
  '#8D929F',
  '#0D705F',
  '#EE9829',
  '#9C1A80',
  '#22D114',
  '#39DB3E',
  '#894CD7',
  '#606C41',
  '#D6F80B',
  '#69FC67',
  '#C34F34',
  '#6157F2',
  '#03FF96',
  '#9ED64E',
  '#E467AB',
  '#E39874',
  '#C066D4',
  '#68C18A',
  '#E7BB33',
  '#790481',
  '#5B376B',
  '#282AA6',
  '#702389',
  '#17AF77',
  '#BF6A09',
  '#D4305F',
  '#37CC77',
  '#E0EDD7',
  '#2BFD89',
  '#F3C0FF',
  '#180D9E',
  '#0F6120',
  '#18CD95',
  '#B93C5B',
  '#278260',
  '#E63B92',
  '#9F1B98',
  '#42BD93',
  '#5A1089',
  '#1CF9F9',
  '#0A03B9',
  '#1AC898',
  '#D13BAE',
  '#34FADC',
  '#BDA6C3',
  '#B38699',
  '#D70D71',
  '#2947E2',
  '#9FAAE0',
  '#628335',
  '#4EBB84',
  '#6FA517',
  '#E31D44',
  '#8FEAFD',
  '#282ABA',
  '#06486F',
  '#9D9E10',
  '#1556EA',
  '#B9D170',
  '#F2F500',
  '#ECA414',
  '#C861E8',
  '#462CE1',
  '#DA5BCD',
  '#F5D891',
  '#ED79E1',
  '#E12786',
  '#D2CAD6',
  '#C015E5',
  '#C052C4',
  '#FAF207',
  '#6FBD8C',
  '#8A2F14',
  '#78EEF7',
  '#95DC99',
  '#59AD8A',
  '#2B45CE',
  '#33537F',
  '#62CA65',
  '#6DC978',
  '#7BE4E2',
  '#BF56C0',
  '#34E30E',
  '#0A7841',
  '#B07998',
  '#9B343D',
  '#6664D2',
  '#70B2D7',
  '#C284A2',
  '#CA7703',
  '#0C5F69',
  '#D4F764',
  '#CB71BB',
  '#A013A5',
  '#13A386',
  '#A855FF',
  '#D801F3',
  '#B603D9',
  '#2ACB11',
  '#6D6213',
  '#7FD1F5',
  '#75940E',
  '#A023E7',
  '#E459E8',
  '#0A7D37',
  '#A3F4EB',
  '#2900D8',
  '#81F937',
  '#5FEF97',
  '#C2B4D2',
  '#252689',
  '#00EBC4',
  '#FE48B6',
  '#6784F8',
  '#684972',
  '#D0086D',
  '#63B07C',
  '#B1AC54',
  '#E9C86A',
  '#D06A1A',
  '#3B970F',
  '#0F49D7',
  '#F63B22',
  '#35AAC4',
  '#1BEE5E',
  '#30AE21',
  '#F5EFC9',
  '#87234A',
  '#E0F8CB',
  '#115F95',
  '#44A957',
  '#065E87',
  '#83B46C',
  '#BDF8F8',
  '#55DD50',
  '#15E507',
  '#510EC5',
  '#E95FCF',
  '#6F05D8',
  '#DA79CA',
  '#724D09',
  '#0750E4',
  '#C45435',
  '#B50D85',
  '#764B4E',
  '#B0B7A7',
  '#F9A363',
  '#54ACAB',
  '#2002E9',
  '#10DE3D',
  '#818AE8',
  '#3F711D',
  '#282111',
  '#F06099',
  '#7AA429',
  '#DB2A78',
  '#2814A0',
  '#443991',
  '#901CF3',
  '#816686',
  '#7EC074',
  '#B86D71',
  '#AA6CDD',
  '#BD34DD',
  '#8F5286',
  '#E45E08',
  '#357FCF',
  '#827E0A',
  '#E1EA3F',
  '#FE7A00',
  '#C16F0D',
  '#4822DA',
  '#93BE1A',
  '#5EB090',
  '#C5A338',
  '#22EE04',
  '#6AABD2',
  '#CB00A0',
  '#6A622E',
  '#DB1F84',
  '#38DC2E',
  '#F0097D',
  '#898081',
  '#FD75E4',
  '#60BDC9',
  '#D4314F',
  '#766E62',
  '#9761DC',
  '#355FAE',
  '#E5A069',
  '#5B260F',
  '#0370D6',
  '#35D86E',
  '#1BAE0C',
  '#264F3B',
  '#A1B4A7',
  '#AF95FC',
  '#A11F3F',
  '#2DC2FB',
  '#19A7E8',
  '#F806B4',
  '#B6FCE3',
  '#0BE8FB',
  '#6D1F9D',
  '#9F6A12',
  '#5DAF31',
  '#A355B7',
  '#67FC4C',
  '#EA86CB',
  '#1F6379',
  '#86BFC2',
  '#53AF77',
  '#0568F0',
  '#D9C44A',
  '#D6A3CF',
  '#45E2B9',
  '#DFB92F',
  '#14AF91',
  '#E742EE',
  '#E6A170',
  '#1D5E11',
  '#B4E778',
  '#F4E8D0',
  '#D56C95',
  '#675178',
  '#935773',
  '#D53203',
  '#3A8826',
  '#9D597E',
  '#228710',
  '#826B6B',
  '#9784EA',
  '#3DF343',
  '#C3F078',
  '#93AEEC',
  '#975129',
  '#CBA1E1',
  '#BFCBFB',
  '#BADB93',
  '#33CD7C',
  '#F6AF83',
  '#C87A7D',
  '#103AD2',
  '#1BC079',
  '#4B53E6',
  '#FC4893',
  '#691A10',
  '#C114DC',
  '#72DA50',
  '#67E30B',
  '#CF8F9F',
  '#A5FD1F',
  '#D7E75A',
  '#A5C87F',
  '#AD87DA',
  '#223F95',
  '#B582DB',
  '#CAB5A8',
  '#4B59C6',
  '#B1BAA3',
  '#5D7DE1',
  '#2813AE',
  '#ACF5E7',
  '#8FDB57',
  '#97A5F6',
  '#5B7115',
  '#80DBE7',
  '#4FEF57',
  '#B123F0',
  '#6559D7',
  '#1F8D70',
  '#D8AEA2',
  '#E2EC6C',
  '#BB84C9',
  '#96982F',
  '#62AA47',
  '#C4C905',
  '#AD7674',
  '#5E9067',
  '#A85FDC',
  '#3DEA5F',
  '#ADE6C8',
  '#9BDDC2',
  '#99427B',
  '#A76337',
  '#420E92',
  '#3D8AFE',
  '#DAAABA',
  '#8AD0EF',
  '#DBD448',
  '#504A22',
  '#3AED50',
  '#982919',
  '#0FCFBA',
  '#34FE4B',
  '#F42FA5',
  '#F78062',
  '#396CFB',
  '#FE7DFC',
  '#C8CB7E',
  '#7A771D',
  '#25A821',
  '#2C7D2E',
  '#8E8353',
  '#50B9D2',
  '#FA0018',
  '#C56D73',
  '#9096A6',
  '#FCEEB1',
  '#7F82C8',
  '#E1866F',
  '#7353C8',
  '#252A8B',
  '#3182F1',
  '#5E44F7',
  '#5AFDB5',
  '#E81651',
  '#EC0B94',
  '#0D63DC',
  '#8B26B9',
  '#B379F8',
  '#E3F294',
  '#9AE491',
  '#DA3AF2',
  '#C40749',
  '#45E1A1',
  '#3031E8',
  '#FAF31E',
  '#8D8251',
  '#72D46C',
  '#85D221',
  '#2AA222',
  '#9025AB',
  '#A65A9A',
  '#288E70',
  '#21747A',
  '#DF186D',
  '#8EE200',
  '#CDB191',
  '#372779',
  '#D0D6E0',
  '#794CF8',
  '#71CC57',
  '#427D87',
  '#348484',
  '#E78F4F',
  '#D7BA0E',
  '#6A25A2',
  '#83023D',
  '#E5C98A',
  '#E60003',
  '#CEB08F',
  '#691D5F',
  '#C8E43D',
  '#6377C0',
  '#D2AA6E',
  '#AA86DF',
  '#6D287A',
  '#8B16DD',
  '#E6E17C',
  '#9FCD7D',
  '#C1CD42',
  '#9F1DA9',
  '#3B932F',
  '#22ACD1',
  '#404552',
  '#FA0DCA',
  '#5A990D',
  '#409E00',
  '#EF1FDB',
  '#2268B0',
  '#9B0F91',
  '#99BBC2',
  '#FBEF0F',
  '#B74CF1',
  '#943A22',
  '#4E3068',
  '#F4D2CF',
  '#12AD10',
  '#9B90B1',
  '#D923AB',
  '#F97923',
  '#5A52B5',
  '#95C21F',
  '#076013',
  '#58862A',
  '#DBA40D',
  '#DBF1E8',
  '#5EDCA1',
  '#8D7422',
  '#C51ADF',
  '#E3C4A2',
  '#8F5FBD',
  '#8495B8',
  '#27F8AF',
  '#77FEBB',
  '#778FCD',
  '#1E6378',
  '#C37AE9',
  '#E3967E',
  '#11D05E',
  '#F8E41F',
  '#D01372',
  '#CE6991',
  '#92EE98',
  '#2B9355',
  '#691B69',
  '#6ECA9C',
  '#0FEB7D',
  '#5967BB',
  '#1E1181',
  '#502ADD',
  '#76AB83',
  '#F08A77',
  '#74CB1C',
  '#D482BA',
  '#626E85',
  '#314FE7',
  '#B4AB8E',
  '#35F994',
  '#B94479',
  '#0C9262',
  '#A3BBE2',
  '#E1C5DA',
  '#EB40CE',
  '#71ED87',
  '#F524EC',
  '#D933D1',
  '#D6B0D2',
  '#810195',
  '#ED55F4',
  '#952564',
  '#42B9D7',
  '#25209F',
  '#59E717',
  '#6EB207',
  '#BAB775',
  '#25B5DC',
  '#5C3967',
  '#49C0BD',
  '#2B4ED3',
  '#8CD999',
  '#C873D7',
  '#A9D03E',
  '#237246',
  '#A1F8E8',
  '#5B7C40',
  '#7C6EAA',
  '#BCA91F',
  '#F5C497',
  '#C63573',
  '#99B6EB',
  '#205B36',
  '#3DB154',
  '#797712',
  '#3D8422',
  '#1ED458',
  '#4A8AB4',
  '#B42FDF',
  '#0EF646',
  '#CD3877',
  '#E8D098',
  '#8D5C50',
  '#75A4FB',
  '#1CD1F5',
  '#80C53B',
  '#0CF66A',
  '#53A227',
  '#AFA9DF',
  '#D1A618',
  '#60B350',
  '#310CA2',
  '#73BC76',
  '#90EE88',
  '#3825DA',
  '#76C0C8',
  '#9047C2',
  '#633849',
  '#B2F7AB',
  '#D012A2',
  '#AD261C',
  '#747D9F',
  '#38D752',
  '#85A260',
  '#2D7288',
  '#051DC1',
  '#12AC34',
  '#6DB7F8',
  '#19EB01',
  '#050AA7',
  '#92958F',
  '#45F353',
  '#60DD18',
  '#407989',
  '#7F3809',
  '#D9E76F',
  '#C22F67',
  '#3225D3',
  '#C3BE3D',
  '#E5A01A',
  '#259B8D',
  '#D9C7CC',
  '#428D0E',
  '#F238E7',
  '#44D0EB',
  '#108644',
  '#54847B',
  '#6696BC',
  '#41FEBA',
  '#904878',
  '#BF6B53',
  '#CCE1F7',
  '#09F48A',
  '#9586F6',
  '#C59A4E',
  '#E58A1A',
  '#8C4896',
  '#766E12',
  '#08EF31',
  '#4EEB02',
  '#B1A24A',
  '#46DFEC',
  '#60D3E4',
  '#E7269E',
  '#CED963',
  '#765628',
  '#E5C2EC',
  '#0C37F0',
  '#12C5B7',
  '#16CDDF',
  '#156C26',
  '#12AD74',
  '#8D344A',
  '#C3C00C',
  '#D118BE',
  '#8C3F4D',
  '#B0AE2C',
  '#FFD881',
  '#D52C54',
  '#B490E4',
  '#0067E1',
  '#FEF6D2',
  '#7C3087',
  '#93DE43',
  '#C173E4',
  '#AE21D3',
  '#16943F',
  '#76CC7D',
  '#784675',
  '#D19E0A',
  '#C08511',
  '#3BB78A',
  '#235F9C',
  '#DA9FEC',
  '#1EF508',
  '#D58250',
  '#C7ADC5',
  '#8F481C',
  '#A07849',
  '#7E2282',
  '#8FD1F6',
  '#F595E4',
  '#162FF3',
  '#C51681',
  '#F323F8',
  '#0B8CDB',
  '#02F0C5',
  '#7AC622',
  '#EE774A',
  '#0CD350',
  '#C0BF60',
  '#94EB41',
  '#0C852D',
  '#4DFFC0',
  '#0E7FCF',
  '#B12E45',
  '#C3DB9F',
  '#52BE76',
  '#FF4901',
  '#964680',
  '#826777',
  '#6ADF3B',
  '#445E82',
  '#830F01',
  '#FC8DBB',
  '#FC7540',
  '#45C489',
  '#909A47',
  '#6B215A',
  '#FCD7E9',
  '#8E619A',
  '#61DB29',
  '#A3986E',
  '#0A8299',
  '#5F242B',
  '#5DCC43',
  '#CE863C',
  '#57DD14',
  '#6D30D2',
  '#49D7FC',
  '#57457D',
  '#5A51F8',
  '#E73EDA',
  '#922052',
  '#48667D',
  '#5B421B',
  '#BF8AFC',
  '#66F5C1',
  '#CA0C5C',
  '#852869',
  '#905369',
  '#62BFCC',
  '#93952B',
  '#188258',
  '#88FAAA',
  '#3E5161',
  '#B2DB3B',
  '#10612E',
  '#DB0E76',
  '#FC6CAE',
  '#B5B475',
  '#A151A2',
  '#B27DF7',
  '#D558B7',
  '#8A12A5',
  '#0F5E8D',
  '#183136',
  '#FE0A4B',
  '#7920FC',
  '#C204FE',
  '#320CCC',
  '#D88B13',
  '#AF14FC',
  '#4BDE55',
  '#1AA962',
  '#DE195C',
  '#FF7EA2',
  '#2F6022',
  '#4EC702',
  '#1F46DC',
  '#41960B',
  '#11DDE5',
  '#DDA6D9',
  '#F2A748',
  '#734F2A',
  '#BC0F54',
  '#F77E61',
  '#980D6B',
  '#EDFB6A',
  '#3A608B',
  '#9C823A',
  '#BE2F8D',
  '#A38BB1',
  '#66274F',
  '#AF075C',
  '#0FD7A2',
  '#E6B7DF',
  '#BADEA7',
  '#466DB5',
  '#58B602',
  '#79383E',
  '#C53E19',
  '#128D07',
  '#AA0239',
  '#D1182D',
  '#1BF9E2',
  '#2DCED5',
  '#437136',
  '#84EDC2',
  '#F3488B',
  '#4D3ABD',
  '#DC77D9',
  '#40D654',
  '#F6C6DF',
  '#D27F71',
  '#7A07FB',
  '#7E2653',
  '#FBC57A',
  '#6D119F',
  '#E7A477',
  '#A991AB',
  '#9158AC',
  '#4BD108',
  '#1E5D75',
  '#7284B3',
  '#AFC610',
  '#4828DA',
  '#6660FA',
  '#F451F1',
  '#25AA43',
  '#BF49E5',
  '#9819AF',
  '#727AF7',
  '#18D35B',
  '#DF44D5',
  '#25B35B',
  '#0440EF',
  '#CD34E7',
  '#9A713B',
  '#0112DD',
  '#E021E9',
  '#1DD380',
  '#C4431E',
  '#7B0C20',
  '#E6FDB6',
  '#E0A457',
  '#F892B2',
  '#15CB4F',
  '#D5CB11',
  '#C442AA',
  '#BAF53E',
  '#2E7CFB',
  '#9FFBB4',
  '#859E04',
  '#44B59B',
  '#4B09EE',
  '#FA30B4',
  '#F986D6',
  '#ECC76A',
  '#647750',
  '#C0CD34',
  '#1F8A32',
  '#405263',
  '#7F393B',
  '#6F52A1',
  '#D14165',
  '#52149D',
  '#654528',
  '#8FCD85',
  '#4616EE',
  '#EC61A2',
  '#60CE28',
  '#E6D587',
  '#31E5FC',
  '#DDD03B'
];

import { primary, white } from 'constants/color.constant';
import { IconProps } from 'types/common.types';

const RadioCheckIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="7" cy="7" r="6.5" fill={color} stroke={color} />
      <circle cx="7" cy="7" r="3" fill={white} />
    </svg>
  );
};
RadioCheckIcon.defaultProps = {
  color: primary,
  width: 14,
  height: 14,
  onClick: () => {}
};

export default RadioCheckIcon;

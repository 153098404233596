import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import DownloadPdfIcon from 'assets/svg/DownloadPdfIcon';
import MinSectorAltitudeTable, { AltDataType } from './MinSectorAltitudeTable';
import ReportSecTitleWithIcon from './ReportSecTitleWithIcon';

interface Section6Props {
  scrollElementRef: any;
  sec6: any;
  handleCallSaveAeroReportDataApi: (data: { section_key: string; fields: any }) => void;
}

const Section6 = ({ scrollElementRef, sec6, handleCallSaveAeroReportDataApi }: Section6Props) => {
  const [finalSec6Data, setFinalSec6Data] = useState<AltDataType[] | []>([]);
  const [altSec6Data, setAltSec6Data] = useState<AltDataType[] | []>([]);
  const [remarkSec6, setRemarkSec6] = useState<string>('');

  useEffect(() => {
    if (!isEmpty(sec6)) {
      const fData = sec6.rows.map((data: AltDataType, index: number) => {
        return {
          ...data,
          key: index
        };
      });
      setAltSec6Data(fData || []);
      setRemarkSec6(sec6.remark || '');
    }
  }, [sec6]);

  const handleGetRemarkData = (r: string) => {
    setRemarkSec6(r);
  };

  const handleGetAltitudeTableData = (data: AltDataType[] | []) => {
    setFinalSec6Data(data);
  };

  const handleSaveMinSectorAltitude = () => {
    if (!isEmpty(finalSec6Data)) {
      const payload: { section_key: string; fields: any } = {
        section_key: 'sec_6',
        fields: {
          remark: remarkSec6,
          rows: finalSec6Data
        }
      };

      handleCallSaveAeroReportDataApi(payload);
    }
  };

  return (
    <div className="report-code-content sec-3" ref={scrollElementRef} id="sectoraltitude">
      <ReportSecTitleWithIcon
        title="6. MINIMUM SECTOR ALTITUDE"
        icon={<DownloadPdfIcon />}
        handleSaveData={handleSaveMinSectorAltitude}
      />
      <div className="report-content-data">
        <div className="minsec-alt-content obstacle-table">
          <MinSectorAltitudeTable
            altData={altSec6Data}
            remarkSec6={remarkSec6}
            handleSaveData={handleGetAltitudeTableData}
            handleSaveRemarkData={handleGetRemarkData}
          />
        </div>
      </div>
    </div>
  );
};

export default Section6;

import { Modal } from 'antd';

interface ConfirmationModalProps {
  isOpen: boolean;
  isLoading: boolean;
  okBtnText: string;
  description: string;
  handleSuccess: Function;
  handleCancel: Function;
  height?: number | string;
  width?: number | string;
  title?: string;
}

const ConfirmationModal = ({
  isOpen,
  okBtnText,
  description,
  isLoading,
  handleSuccess,
  handleCancel,
  title,
  height,
  width
}: ConfirmationModalProps) => {
  return (
    <Modal
      className="confirmation-modal"
      title={title}
      width={width}
      style={{
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        minHeight: height
      }}
      open={isOpen}
      centered
      okText={okBtnText}
      okButtonProps={{ loading: isLoading }}
      cancelButtonProps={{ type: 'text' }}
      onOk={() => handleSuccess()}
      onCancel={() => handleCancel()}
    >
      <p className="modal-desc">{description}</p>
    </Modal>
  );
};

ConfirmationModal.defaultProps = {
  title: 'Are you sure you want to Proceed',
  height: 201,
  width: 420
};

export default ConfirmationModal;

import Cookies from 'universal-cookie';
import jwtDecode from 'jwt-decode';
import { toast } from 'react-toastify';
import { ACCESS_TOKEN, AES_KEY, REFRESH_TOKEN, USER_ROLE } from 'constants/common.constant';
import { store } from 'store/index';
import { setAccessToken, setRSA, setRefreshToken, setUser } from 'store/users';
import { clearDesign } from 'store/design';
import { clearAlerts } from 'store/alert';
import { JWT } from 'types/common.types';
import { BASE_URL, REFRESH_BOTH_TOKEN } from 'utils/apiUrls';
import { createAxiosClient } from './createAxiosClient';

const cookies = new Cookies();

export const Timeout = (time: number) => {
  const controller = new AbortController();
  setTimeout(() => controller.abort(), time * 1000);
  return controller;
};

export const getStorageValue = (storageKey: string) => {
  const value = cookies.get(storageKey);
  return value;
};

export const setStorageValue = async (key: string, value: string | undefined) => {
  // const now = new Date();
  // const time = now.getTime();
  // let expireTime = time + 2 * 60 * 1000;
  // now.setTime(expireTime);

  await cookies.set(key, value, { path: '/' });

  return value;
};

export const getCurrentAccessToken = () => {
  // if (store.getState().user.accessToken) {
  //   setStorageValue(ACCESS_TOKEN, store.getState().user.accessToken);
  // }
  return store.getState().user.accessToken;
};

export const getCurrentRefreshToken = () => {
  return store.getState().user.refreshToken;
};

export const setRefreshedTokens = (tokens: {
  accessToken: string | undefined;
  refreshToken: string | undefined;
}) => {
  store.dispatch(setAccessToken(tokens.accessToken));
  store.dispatch(setRefreshToken(tokens.refreshToken));
  setStorageValue(ACCESS_TOKEN, tokens.accessToken);
  setStorageValue(REFRESH_TOKEN, tokens.refreshToken);
};

export const clearCookies = () => {
  cookies.remove(AES_KEY, { path: '/' });
  cookies.remove(ACCESS_TOKEN, { path: '/' });
  cookies.remove(REFRESH_TOKEN, { path: '/' });
  cookies.remove(USER_ROLE, { path: '/' });
  // document.cookie = `${AES_KEY}=; Path=/; Expires='Thu, 01 Jan 1970 00:00:00 UTC';`;
  // document.cookie = `${ACCESS_TOKEN}=; Path=/; Expires='Thu, 01 Jan 1970 00:00:00 UTC';`;
  // document.cookie = `${REFRESH_TOKEN}=; Path=/; Expires='Thu, 01 Jan 1970 00:00:00 UTC';`;
  // document.cookie = `${USER_ROLE}=; Path=/; Expires='Thu, 01 Jan 1970 00:00:00 UTC';`;
};

export const clearData = () => {
  clearCookies();
  store.dispatch(setRSA(null));
  store.dispatch(setUser(null));
  store.dispatch(setAccessToken(null));
  store.dispatch(setRefreshToken(null));
  store.dispatch(clearDesign());
  store.dispatch(clearAlerts());
  cookies.remove(REFRESH_TOKEN);
  cookies.remove(AES_KEY);
  cookies.remove(USER_ROLE);
  cookies.remove(ACCESS_TOKEN);
  localStorage.clear();
  window.location.reload();
};

export async function refreshAccessToken(requestMethod: string, url: string, refreshToken: string) {
  const formData = {
    method: requestMethod,
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
      Authorization: refreshToken
    }
  };

  // eslint-disable-next-line
  return await fetch(url, formData)
    .then((response) => {
      return response
        .json()
        .then((responseJson) => {
          return responseJson;
        })
        .catch(() => {
          toast.error('Refresh token expired');
          return null;
        });
    })
    .catch(() => {
      toast.error('Refresh token expired');
      return null;
    });
}

export const getAsyncStorageValue = async (storageKey: string) => {
  if (storageKey === ACCESS_TOKEN) {
    const currentTime = Math.round(new Date().getTime() / 1000);

    const aesKey = getStorageValue(AES_KEY);
    const refreshToken = cookies.get(REFRESH_TOKEN);
    const accessToken = cookies.get(ACCESS_TOKEN);
    const decodeRefreshToken: JWT = jwtDecode(refreshToken);
    const decodeAccessToken: JWT = jwtDecode(accessToken);

    if (decodeRefreshToken.exp <= currentTime) {
      await clearData();
      return null;
    }

    if (decodeAccessToken.exp <= currentTime) {
      return refreshAccessToken('POST', REFRESH_BOTH_TOKEN, refreshToken)
        .then(async (response) => {
          if (response.status === false) {
            await clearData();
            return null;
          }

          const decodeAccessToken: JWT = jwtDecode(response.data.accessToken);
          await setStorageValue(ACCESS_TOKEN, response.data.accessToken);
          await setStorageValue(REFRESH_TOKEN, response.data.refreshToken);
          await setStorageValue(USER_ROLE, decodeAccessToken.user_claims.role);
          await setStorageValue(AES_KEY, aesKey);

          return response.data.accessToken;
        })
        .catch(() => {
          toast.error('Access token expired');
          return null;
        });
    }

    return cookies.get(storageKey);
  }

  return cookies.get(storageKey);
};

// For local ngrok network
// 'ngrok-skip-browser-warning': '69420'

export const client = createAxiosClient({
  options: {
    baseURL: BASE_URL,
    timeout: Timeout(100).signal,
    headers: {
      'access-control-allow-credentials': true,
      'Content-Type': 'application/json'
    }
  },
  getCurrentAccessToken,
  getCurrentRefreshToken,
  refreshTokenUrl: REFRESH_BOTH_TOKEN,
  clearData,
  setRefreshedTokens
});

import { has, isEmpty, keys } from 'lodash';
import AircraftIconV1 from 'assets/svg/AircraftIconV1';
import ReportContentCard from 'components/core/ReportContentCard';
import CalculatePDGTable from './CalculatePDGTable';
import ObstacleOISLegTable from './ObstacleOISLegTable';
import ObsPDGConclusion from './ObsPDGConclusion';
import ReportSecTitleWithIcon from './ReportSecTitleWithIcon';

interface PreviewSection8Props {
  scrollElementRef: any;
  sec8: any;
}

const PreviewSection8 = ({ scrollElementRef, sec8 }: PreviewSection8Props) => {
  return (
    <div className="report-code-content sec-8" ref={scrollElementRef} id="obstacleoischeck">
      <div className="report-content-data">
        {keys(sec8).map((ct: string, i: number) => {
          if (isEmpty(sec8[`${ct}`])) return null;
          return keys(sec8[`${ct}`]).map((leg: string) => {
            return (
              <div key={`${ct}-${leg}`} className="page-break">
                {i === 0 && (
                  <ReportSecTitleWithIcon
                    classname="sec11-title-header sec8-title-header"
                    title="8. OBSTACLE ASSESSMENT - OIS CHECK"
                    icon={null}
                  />
                )}
                <div className="coding-table-content">
                  <ReportContentCard
                    icon={<AircraftIconV1 height={16} />}
                    title={`${ct}  (${sec8[`${ct}`][`${leg}`].start_point} - ${
                      sec8[`${ct}`][`${leg}`].end_point
                    })  -  ${leg}`}
                  />
                  <div className="obstacle-table">
                    <ObstacleOISLegTable obsOISData={sec8[`${ct}`][`${leg}`].obs_table || []} />
                  </div>
                </div>
                {has(sec8[`${ct}`][`${leg}`], 'calc_table') &&
                  !isEmpty(sec8[`${ct}`][`${leg}`].calc_table) && (
                    <div className="minsec-alt-content obstacle-table">
                      <CalculatePDGTable
                        pdgData={sec8[`${ct}`][`${leg}`].calc_table.rows || []}
                        finalRow={sec8[`${ct}`][`${leg}`].calc_table.final_row || []}
                      />
                    </div>
                  )}
                <div className="obstacle-table">
                  <ObsPDGConclusion
                    conclusion={[
                      {
                        pdg: sec8[`${ct}`][`${leg}`]?.conclusion?.pdg
                          ? `${sec8[`${ct}`][`${leg}`]?.conclusion?.pdg} %`
                          : '-',
                        up_to: sec8[`${ct}`][`${leg}`]?.conclusion?.up_to
                          ? `${sec8[`${ct}`][`${leg}`]?.conclusion?.up_to.toFixed(2)} Ft`
                          : '-',
                        remarks: 'If unable SID not authorised'
                      }
                    ]}
                  />
                </div>
              </div>
            );
          });
        })}
      </div>
    </div>
  );
};

export default PreviewSection8;

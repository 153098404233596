import { Typography } from 'antd';
import { isEmpty } from 'lodash';
import './styles.less';

const { Text } = Typography;

interface ApproachrunwaylightingProps {
  rwylights: any;
}

const Approachrunwaylighting = ({ rwylights }: ApproachrunwaylightingProps) => {
  return (
    <div className="ad-data-table-main">
      {!isEmpty(rwylights) ? (
        <>
          <table className="rwylights-table">
            <thead>
              <tr>
                <th>Runway Designator</th>
                <th>Approach lighting system</th>
                <th>thr Runway Lights</th>
                <th>Visual Slope Indicators</th>
              </tr>
            </thead>
            <tbody>
              {rwylights.map((rwy: any, j: number) => {
                return (
                  <tr key={`${j}_vor`}>
                    <td>{rwy?.rd || '-'}</td>
                    <td>
                      <div>
                        <Typography className="data-size-color">
                          {rwy.approachlightingsystems?.classICAO || ''}
                        </Typography>
                        <Typography className="data-size-color">
                          {rwy.approachlightingsystems?.length || '-'}
                        </Typography>
                        <Typography className="data-size-color">
                          {rwy.approachlightingsystems?.intensityLevel || ''}
                        </Typography>
                      </div>
                    </td>
                    <td>
                      <div>
                        <Typography className="data-size-color">
                          {rwy.thrrunwaylights?.colour || ''}
                        </Typography>
                        <Typography className="data-size-color">
                          {rwy.thrrunwaylights?.wingbar || ''}
                        </Typography>
                      </div>
                    </td>
                    <td>
                      <div>
                        <Typography className="data-size-color">
                          {rwy.visualglideslopeindicators?.type || ''}
                        </Typography>
                        <Typography className="data-size-color">
                          {rwy.visualglideslopeindicators?.position || ''}
                        </Typography>
                        <Typography className="data-size-color">
                          {rwy.visualglideslopeindicators?.slopeAngle || ''}
                        </Typography>
                        <Typography className="data-size-color">
                          {rwy.visualglideslopeindicators?.minimumEyeHeightOverThreshold || ''}
                        </Typography>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <table className="rwylights-table">
            <thead>
              <tr>
                <th>cl Runway Lights</th>
                <th>Edge Runway Lights</th>
                <th>End Runway Lights</th>
                <th>Stop Way Lights</th>
                <th>Remarks</th>
              </tr>
            </thead>
            <tbody>
              {rwylights.map((rwy: any, j: number) => {
                return (
                  <tr key={`${j}_ins`}>
                    <td>
                      <div>
                        <Typography className="data-size-color">
                          {rwy.clrunwaylights.colour || ''}
                        </Typography>
                        <Typography className="data-size-color">
                          {rwy.clrunwaylights.length || '-'}
                        </Typography>
                        <Typography className="data-size-color">
                          {rwy.clrunwaylights.spacing || ''}
                        </Typography>
                        <Typography className="data-size-color">
                          {rwy.clrunwaylights.intensityLevel || ''}
                        </Typography>
                      </div>
                    </td>
                    <td>
                      <div>
                        <Typography className="data-size-color">
                          {rwy.edgerunwaylights.length || '-'}
                        </Typography>
                        <Typography className="data-size-color">
                          {rwy.edgerunwaylights.spacing || ''}
                        </Typography>
                        <Typography className="data-size-color">
                          {rwy.edgerunwaylights.colour || ''}
                        </Typography>

                        <Typography className="data-size-color">
                          {rwy.edgerunwaylights.intensityLevel || ''}
                        </Typography>
                      </div>
                    </td>
                    <td>{rwy.endrunwaylights.colour || 'NIL'}</td>
                    <td>
                      <div>
                        <Typography className="data-size-color">
                          {rwy.stopwaylights.length || '-'}
                        </Typography>
                        <Typography className="data-size-color">
                          {rwy.stopwaylights.colour || ''}
                        </Typography>
                      </div>
                    </td>
                    <td>{rwy.remarks || 'NIL'}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      ) : (
        <Text style={{ height: '100%' }} className="flexJustifyAlignCenter ad-no-data">
          No Data
        </Text>
      )}
    </div>
  );
};

export default Approachrunwaylighting;

import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import DownloadPdfIcon from 'assets/svg/DownloadPdfIcon';
import Editor from 'components/TextEditor/Editor';
import ReportSecTitleWithIcon from './ReportSecTitleWithIcon';

interface Section4Props {
  sec4: any;
  scrollElementRef: any;
  handleCallSaveAeroReportDataApi: (data: { section_key: string; fields: any }) => void;
}

const Section4 = ({ sec4, scrollElementRef, handleCallSaveAeroReportDataApi }: Section4Props) => {
  const [ifpDesignHtml, setIFPDesignHtml] = useState<string | null>(null);

  useEffect(() => {
    if (!isEmpty(sec4)) {
      setIFPDesignHtml(sec4.ifp_design_constr || null);
    }
  }, [sec4]);

  const handleIFPchange = (html: any) => {
    setIFPDesignHtml(html);
  };

  const handleSaveIFPDesignData = () => {
    const payload: { section_key: string; fields: any } = {
      section_key: 'sec_4',
      fields: {
        ifp_design_constr: ifpDesignHtml
      }
    };
    handleCallSaveAeroReportDataApi(payload);
  };

  return (
    <div className="report-code-content sec-3" ref={scrollElementRef} id="ifpdesign">
      <ReportSecTitleWithIcon
        title="4. IFP DESIGN CONSTRAINTS AND NARRATIVE"
        icon={<DownloadPdfIcon />}
        handleSaveData={handleSaveIFPDesignData}
      />
      <div className="report-content-data">
        <div className="ifp-design-content">
          <Editor editorHtml={ifpDesignHtml} handleChange={handleIFPchange} />
        </div>
      </div>
    </div>
  );
};

export default Section4;

import { white } from 'constants/color.constant';
import { IconProps } from 'types/common.types';

const SquareCircleIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="11.9999" cy="12.0002" r="3.68182" stroke={color} />
      <path
        d="M5.25 12C6.98864 11.3864 10.7727 9.17727 12 5.25"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.75 12C17.0114 12.6136 13.2273 14.8227 12 18.75"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.75 12C17.0114 11.3864 13.2273 9.17727 12 5.25"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.25 12C6.98864 12.6136 10.7727 14.8227 12 18.75"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
SquareCircleIcon.defaultProps = {
  color: white,
  width: 24,
  height: 24,
  onClick: () => {}
};

export default SquareCircleIcon;

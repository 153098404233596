import { Typography } from 'antd';
import './styles.less';

const { Text } = Typography;

interface EmptytextProps {
  activeTabText?: string;
  isDesignData?: boolean;
}

const Emptytext = ({ isDesignData, activeTabText }: EmptytextProps) => {
  return (
    <Text className="empty-label">
      {isDesignData
        ? activeTabText !== 'all'
          ? `No ${activeTabText} Designs`
          : ''
        : 'No Designs Created'}
    </Text>
  );
};

Emptytext.defaultProps = {
  isDesignData: false,
  activeTabText: ''
};

export default Emptytext;

import { IconProps } from 'types/common.types';

const CloseIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icons / Close / Close">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.414 11.9998L17.707 7.70676C18.098 7.31576 18.098 6.68376 17.707 6.29276C17.316 5.90176 16.684 5.90176 16.293 6.29276L12 10.5858L7.70701 6.29276C7.31601 5.90176 6.68401 5.90176 6.29301 6.29276C5.90201 6.68376 5.90201 7.31576 6.29301 7.70676L10.586 11.9998L6.29301 16.2928C5.90201 16.6838 5.90201 17.3158 6.29301 17.7068C6.48801 17.9018 6.74401 17.9998 7.00001 17.9998C7.25601 17.9998 7.51201 17.9018 7.70701 17.7068L12 13.4138L16.293 17.7068C16.488 17.9018 16.744 17.9998 17 17.9998C17.256 17.9998 17.512 17.9018 17.707 17.7068C18.098 17.3158 18.098 16.6838 17.707 16.2928L13.414 11.9998Z"
          fill={color}
        />
      </g>
    </svg>
  );
};
CloseIcon.defaultProps = {
  color: '#030303',
  width: 24,
  height: 24,
  onClick: () => {}
};

export default CloseIcon;

import { IconProps } from 'types/common.types';

const ReCenterIcon = ({ height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 24H24V4C24 1.79086 22.2091 0 20 0H4C1.79086 0 0 1.79086 0 4V24Z" fill="#42444A" />
      <mask id="mask0_983_4072" maskUnits="userSpaceOnUse" x="2" y="2" width="20" height="20">
        <path
          d="M2 6C2 3.79086 3.79086 2 6 2H18C20.2091 2 22 3.79086 22 6V22H2V6Z"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask0_983_4072)">
        <path
          d="M11.2694 20V18.5388C9.74734 18.3683 8.4414 17.7382 7.3516 16.6484C6.2618 15.5586 5.63166 14.2527 5.46119 12.7306H4V11.2694H5.46119C5.63166 9.74734 6.2618 8.4414 7.3516 7.3516C8.4414 6.2618 9.74734 5.63166 11.2694 5.46119V4H12.7306V5.46119C14.2527 5.63166 15.5586 6.2618 16.6484 7.3516C17.7382 8.4414 18.3683 9.74734 18.5388 11.2694H20V12.7306H18.5388C18.3683 14.2527 17.7382 15.5586 16.6484 16.6484C15.5586 17.7382 14.2527 18.3683 12.7306 18.5388V20H11.2694ZM12 17.1142C13.4125 17.1142 14.618 16.6149 15.6164 15.6164C16.6149 14.618 17.1142 13.4125 17.1142 12C17.1142 10.5875 16.6149 9.38204 15.6164 8.38356C14.618 7.38508 13.4125 6.88584 12 6.88584C10.5875 6.88584 9.38204 7.38508 8.38356 8.38356C7.38508 9.38204 6.88584 10.5875 6.88584 12C6.88584 13.4125 7.38508 14.618 8.38356 15.6164C9.38204 16.6149 10.5875 17.1142 12 17.1142ZM12 14.9224C11.1963 14.9224 10.5084 14.6362 9.93607 14.0639C9.36377 13.4916 9.07763 12.8037 9.07763 12C9.07763 11.1963 9.36377 10.5084 9.93607 9.93607C10.5084 9.36377 11.1963 9.07763 12 9.07763C12.8037 9.07763 13.4916 9.36377 14.0639 9.93607C14.6362 10.5084 14.9224 11.1963 14.9224 12C14.9224 12.8037 14.6362 13.4916 14.0639 14.0639C13.4916 14.6362 12.8037 14.9224 12 14.9224ZM12 13.4612C12.4018 13.4612 12.7458 13.3181 13.032 13.032C13.3181 12.7458 13.4612 12.4018 13.4612 12C13.4612 11.5982 13.3181 11.2542 13.032 10.968C12.7458 10.6819 12.4018 10.5388 12 10.5388C11.5982 10.5388 11.2542 10.6819 10.968 10.968C10.6819 11.2542 10.5388 11.5982 10.5388 12C10.5388 12.4018 10.6819 12.7458 10.968 13.032C11.2542 13.3181 11.5982 13.4612 12 13.4612Z"
          fill="white"
        />
      </g>
    </svg>
  );
};
ReCenterIcon.defaultProps = {
  width: 24,
  height: 24,
  onClick: () => {}
};

export default ReCenterIcon;

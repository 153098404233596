import { AxiosError } from 'axios';
import { ACCESS_TOKEN, TOKEN_EXPIRE_ERROR } from 'constants/common.constant';
import { client, getAsyncStorageValue } from 'services/axiosClient';
import { enqueueError } from 'store/alert';
import { fileUploadApicall } from 'utils/connect';
import { UPLOAD_SURVEYFILE_URL } from 'utils/apiUrls';
import {
  setIsLoadingAddSurveyFileUpload,
  setIsLoadingSilentDesignProcedureVersions,
  setIsLoadingSilentInfoBtwPoint,
  setIsLoadingDesignMetadataByMetaid
} from '.';
import { AppDispatch } from '..';

export const addSurveyObstacles =
  (metaId: number, token: string, callback: Function, file?: File) => (dispatch: AppDispatch) => {
    try {
      dispatch(setIsLoadingAddSurveyFileUpload(true));

      const onSuccess = (response: Response) => {
        dispatch(setIsLoadingAddSurveyFileUpload(false));
        callback(response);
      };
      const onFailure = (error: any) => {
        if (error) {
          if (error.message === TOKEN_EXPIRE_ERROR) {
            getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
              dispatch(addSurveyObstacles(metaId, reToken, callback, file));
            });
          } else {
            dispatch(enqueueError(error.message));
            dispatch(setIsLoadingAddSurveyFileUpload(false));
          }
        } else {
          dispatch(enqueueError('Something went wrong'));
        }
      };

      fileUploadApicall(UPLOAD_SURVEYFILE_URL(metaId), onSuccess, onFailure, token, file);
    } catch (error: any) {
      dispatch(setIsLoadingAddSurveyFileUpload(false));
      dispatch(enqueueError(error.message));
    }
  };

export const silentInfoBtwCall =
  (url: string, callback: Function) => async (dispatch: AppDispatch) => {
    try {
      dispatch(setIsLoadingSilentInfoBtwPoint(true));
      const result = await client.get(url);
      callback(result.data);
      dispatch(setIsLoadingSilentInfoBtwPoint(false));
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      const errorData: any = err.response?.data || {};
      dispatch(enqueueError(errorData?.message));
      callback({
        data: {
          statusCode: err.response?.status,
          status: false,
          ...errorData
        }
      });
      dispatch(setIsLoadingSilentInfoBtwPoint(false));
    }
  };

export const silentDesignProcedureVersionsCall =
  (url: string, callback: Function) => async (dispatch: AppDispatch) => {
    try {
      dispatch(setIsLoadingSilentDesignProcedureVersions(true));
      const result = await client.get(url);
      callback(result.data?.data);
      dispatch(setIsLoadingSilentDesignProcedureVersions(false));
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      const errorData: any = err.response?.data || {};
      dispatch(enqueueError(errorData?.message));
      callback({
        data: {
          statusCode: err.response?.status,
          status: false,
          ...errorData
        }
      });
      dispatch(setIsLoadingSilentDesignProcedureVersions(false));
    }
  };

export const silentDesignMetadataByMetaidCall =
  (url: string, callback: Function) => async (dispatch: AppDispatch) => {
    try {
      dispatch(setIsLoadingDesignMetadataByMetaid(true));
      const result = await client.get(url);
      callback(result.data);
      dispatch(setIsLoadingDesignMetadataByMetaid(false));
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      const errorData: any = err.response?.data || {};
      dispatch(enqueueError(errorData?.message));
      callback({
        data: {
          statusCode: err.response?.status,
          status: false,
          ...errorData
        }
      });
      dispatch(setIsLoadingDesignMetadataByMetaid(false));
    }
  };

import { Button, Table, Typography } from 'antd';
import { isEmpty } from 'lodash';
import { ColumnsType } from 'antd/es/table';
import CloseIcon from 'assets/svg/CloseIcon';
import ExpandIcon from 'assets/svg/ExpandIcon';
import { primary } from 'constants/color.constant';
import { ObstacleType } from 'store/design/types';
import './styles.less';

const { Text } = Typography;

interface AerodromeObsaclesTableProps {
  handleShowAeroTable: (isHide: boolean) => void;
  handleShowFullScreenTable: (isHide: boolean) => void;
  obstacleData: ObstacleType[] | [];
}

const AerodromeObsaclesTable = ({
  handleShowAeroTable,
  obstacleData,
  handleShowFullScreenTable
}: AerodromeObsaclesTableProps) => {
  const columns: ColumnsType<ObstacleType> = [
    {
      title: 'RWY/ Area Affected',
      align: 'center',
      key: 'rwy_designator',
      render: (record) => {
        return (
          <div>
            {!isEmpty(record.rwy_designator) ? (
              <Text style={{ color: '#696b72', display: 'block' }}>{record.rwy_designator}</Text>
            ) : (
              '-'
            )}
          </div>
        );
      }
    },
    {
      title: 'Obstacle Type',
      dataIndex: 'obstacle_type',
      key: 'obstacle_type'
    },
    {
      title: 'Coordinates',
      key: 'coordinates',
      render: (record) => {
        return (
          <>
            <Text style={{ color: '#696b72', display: 'block' }}>
              {record.latitude_dd.toFixed(6)}
            </Text>
            <Text style={{ color: '#696b72', display: 'block' }}>
              {record.longitude_dd.toFixed(6)}
            </Text>
          </>
        );
      }
    },
    {
      title: 'Elevation',
      dataIndex: 'elevation',
      align: 'center',
      key: 'elevation'
    },
    {
      title: 'Marking/LGT',
      align: 'center',
      key: 'marking',
      render: (record) => {
        return (
          <Text style={{ color: '#696b72', display: 'block' }}>
            {record.marking || '-'}/{record.lighted || '-'}
          </Text>
        );
      }
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      key: 'remarks'
    }
  ];

  return (
    <div className="aerodrome-details">
      <div className="aerodrome-header">
        <div>
          <Text>Aerodrome Obstacles</Text>
          <Button
            type="text"
            icon={<ExpandIcon />}
            onClick={() => handleShowFullScreenTable(true)}
            className="back-icon-btn"
          />
        </div>
        <Button
          type="text"
          shape="circle"
          icon={<CloseIcon color={primary} />}
          className="back-icon-btn"
          onClick={() => handleShowAeroTable(false)}
        />
      </div>
      <div>
        <Table
          className="aero-obstacle-table design-table-main"
          columns={columns}
          dataSource={obstacleData || []}
          pagination={false}
          scroll={{ y: 250 }}
          rowKey={(record) => record.id}
        />
      </div>
    </div>
  );
};

export default AerodromeObsaclesTable;

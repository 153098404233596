import { useMap } from 'react-leaflet';
import Control from 'react-leaflet-custom-control';
import { Dropdown, Radio, Typography, MenuProps } from 'antd';
// import EyeVisible from 'assets/svg/EyeVisible';
import FullScreenIcon from 'assets/svg/FullScreenIcon';
import MapLayerIcon from 'assets/svg/MapLayerIcon';
import MinusIcon from 'assets/svg/MinusIcon';
import PlusIcon from 'assets/svg/PlusIcon';
import ReCenterIcon from 'assets/svg/ReCenterIcon';
// import { white } from 'constants/color.constant';
import { defaultCenter } from 'constants/common.constant';
import { useAppSelector } from 'hooks/useReduxHook';
import { DesignState } from 'store/design/types';
import { Maptype } from 'types/common.types';
import './styles.less';

const { Text } = Typography;

interface MapActionControlV2Props {
  mapType: string;
  isFullScreenMapView: boolean;
  handleMapType: (data: string) => void;
  handleFullScreenMapView: (fullMapView: boolean) => void;
}

const MapActionControlV2 = ({
  mapType,
  isFullScreenMapView,
  handleMapType,
  handleFullScreenMapView
}: MapActionControlV2Props) => {
  const map = useMap();
  const { selectedDesign }: DesignState = useAppSelector((state) => state.design);
  const handleZoomIn = () => map.zoomIn();
  const handleZoomOut = () => map.zoomOut();
  const handleCenter = () => {
    map.setView(
      selectedDesign ? [selectedDesign.latitude, selectedDesign.longitude] : defaultCenter,
      14
    );
  };

  const handleChangeMapType = (mapType: string) => {
    handleMapType(mapType);
  };

  const items: MenuProps['items'] = [
    {
      label: (
        <div onClick={() => handleChangeMapType(Maptype.Roadmap)} className="roadmap">
          <Radio value={Maptype.Roadmap} checked={mapType === Maptype.Roadmap} />
          <Text>Google Maps Roads</Text>
        </div>
      ),
      key: 'roadmap'
    },
    {
      label: (
        <div onClick={() => handleChangeMapType(Maptype.satellite)}>
          <Radio value={Maptype.satellite} checked={mapType === Maptype.satellite} />
          <Text>Google Maps Satellite</Text>
        </div>
      ),
      key: 'satellite'
    }
  ];
  return (
    <Control position="bottomright">
      <div className="map-action-control">
        <Dropdown
          menu={{ items }}
          trigger={['click']}
          placement="topRight"
          overlayClassName="maplayer-dropdown"
        >
          <div className="map-layer">
            <MapLayerIcon />
          </div>
        </Dropdown>
        <div style={{ height: 24 }} className="map-recenter" onClick={handleCenter}>
          <ReCenterIcon />
        </div>
        {/* <div className="map-eye">
          <EyeVisible color={white} height={20} width={20} />
        </div> */}
        <div
          className="map-full-screen"
          onClick={() => handleFullScreenMapView(!isFullScreenMapView)}
        >
          <FullScreenIcon />
        </div>
        <div style={{ height: 24 }} className="zoom-plus" onClick={handleZoomIn}>
          <PlusIcon />
        </div>
        <div className="zoom-minus" onClick={handleZoomOut}>
          <MinusIcon />
        </div>
      </div>
    </Control>
  );
};

export default MapActionControlV2;

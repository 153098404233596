import { isEmpty } from 'lodash';
import { Button, Divider, Input, Switch, Typography } from 'antd';
import { OAlegdata } from 'store/design/types';
import { SlRefresh } from 'react-icons/sl';

const { Text } = Typography;
const { TextArea } = Input;

interface OmniPAOALegProps {
  handleShowObstacleTableOMNI: (isShow: boolean) => void;
  handleChangeRemarksDepOmni: (isTerminate: string | undefined) => void;
  remarksDepOmni: string | undefined;
  isDesigner: boolean;
  isShowHideOADataOMNI: boolean;
  isShowHideRemarkDepOmniBtn: boolean;
  isLoadingAddRemarkData: boolean;
  handleAddUpdateRemarkDepOmni: Function;
  activeLegDesignDataOMNI: any;
  activeLegKey: string;
  activeObscleTerrainDataKey: string;
  handleGeneratePADataOMNI: Function;
  handleGenerateOADataOMNI: Function;
  activeOALegDesignDataOMNI: OAlegdata[] | [];
  isShowHideTerrainLayerOMNI: boolean;
  handleShowTerrainLayerOMNI: Function;
  handleRefreshGeneratedOAdata: Function;
}

const OmniPAOALeg = ({
  isDesigner,
  // eslint-disable-next-line
  activeObscleTerrainDataKey,
  isShowHideOADataOMNI,
  handleShowObstacleTableOMNI,
  activeLegDesignDataOMNI,
  activeLegKey,
  handleGeneratePADataOMNI,
  // eslint-disable-next-line
  activeOALegDesignDataOMNI,
  handleGenerateOADataOMNI,
  isShowHideTerrainLayerOMNI,
  handleShowTerrainLayerOMNI,
  remarksDepOmni,
  isShowHideRemarkDepOmniBtn,
  isLoadingAddRemarkData,
  handleAddUpdateRemarkDepOmni,
  handleChangeRemarksDepOmni,
  handleRefreshGeneratedOAdata
}: OmniPAOALegProps) => {
  const isObstacleGenerate =
    !isEmpty(activeLegDesignDataOMNI) && activeLegDesignDataOMNI.is_obstacle_analysed;

  const generateOABtnText = isObstacleGenerate
    ? isObstacleGenerate === 'completed'
      ? 'Generated'
      : isObstacleGenerate === 'in-progress'
      ? 'In Progress'
      : isObstacleGenerate === 'failed'
      ? 'Retry'
      : 'Generate'
    : 'Generate';

  const generateOABtnClass = isObstacleGenerate
    ? isObstacleGenerate === 'completed'
      ? 'generated-complt-btn generate-btn'
      : isObstacleGenerate === 'in-progress'
      ? 'generate-progress-btn generate-btn'
      : isObstacleGenerate === 'failed'
      ? 'generate-failed-btn generate-btn'
      : 'generate-btn'
    : 'generate-btn';

  const isOAComplete = isObstacleGenerate === 'completed';

  const handleChangeRemarks = (e: any) => {
    const { value } = e.target;
    handleChangeRemarksDepOmni(value);
  };

  return (
    <div className="leg-tab pa-oa-leg">
      <div className="generate-area-content">
        <Text>Generate Protection Area</Text>
        <Button
          disabled={!isEmpty(activeLegDesignDataOMNI) || !isDesigner}
          onClick={() =>
            !isEmpty(activeLegDesignDataOMNI) || !isDesigner ? () => {} : handleGeneratePADataOMNI()
          }
          className={
            !isEmpty(activeLegDesignDataOMNI) ? 'generated-area-btn generate-btn' : 'generate-btn'
          }
        >
          {!isEmpty(activeLegDesignDataOMNI) ? 'Generated' : 'Generate'}
        </Button>
      </div>
      <Divider className="content-divider" />
      <div className="generate-area-content">
        <Text>Obstacle Analysis</Text>
        {activeLegKey === '1' ? (
          <div className="refresh-generate-btn">
            {isObstacleGenerate === 'in-progress' && (
              <SlRefresh onClick={() => handleRefreshGeneratedOAdata(activeLegDesignDataOMNI.id)} />
            )}
            <Button
              style={{ marginLeft: 5 }}
              disabled={!isDesigner || isEmpty(activeLegDesignDataOMNI)}
              onClick={() =>
                isEmpty(isObstacleGenerate) || isObstacleGenerate === 'failed'
                  ? handleGenerateOADataOMNI(activeLegDesignDataOMNI.id)
                  : () => {}
              }
              className={generateOABtnClass}
            >
              {generateOABtnText}
            </Button>
          </div>
        ) : (
          <Button className="generate-btn">Conduct</Button>
        )}
      </div>
      <div className="obstacle-switch-content">
        <Text
          className={
            isShowHideOADataOMNI
              ? isOAComplete
                ? 'oa-data-label oa-checked-label'
                : 'oa-data-label'
              : isOAComplete
              ? 'oa-data-label'
              : 'oa-label'
          }
        >
          Obstacle Analysis Results
        </Text>
        <Switch
          className={isObstacleGenerate ? 'oa-switch-data obstacle-switch' : 'obstacle-switch'}
          disabled={isEmpty(isObstacleGenerate) || isObstacleGenerate !== 'completed'}
          checked={isShowHideOADataOMNI}
          onChange={() => handleShowObstacleTableOMNI(!isShowHideOADataOMNI)}
        />
      </div>
      <div className="obstacle-switch-content">
        <Text
          className={
            isShowHideTerrainLayerOMNI
              ? isOAComplete
                ? 'oa-data-label oa-checked-label'
                : 'oa-data-label'
              : isOAComplete
              ? 'oa-data-label'
              : 'oa-label'
          }
        >
          Terrain Analysis Results
        </Text>
        <Switch
          className={isObstacleGenerate ? 'oa-switch-data obstacle-switch' : 'obstacle-switch'}
          disabled={isEmpty(isObstacleGenerate) || isObstacleGenerate !== 'completed'}
          checked={isShowHideTerrainLayerOMNI}
          onChange={() => handleShowTerrainLayerOMNI(!isShowHideTerrainLayerOMNI)}
        />
      </div>
      <div className="obstacle-remark-content">
        <Text className="remark-label">Remarks</Text>
        <TextArea
          disabled={!isDesigner || isEmpty(activeLegDesignDataOMNI)}
          className="remark-text-area"
          value={activeLegDesignDataOMNI && remarksDepOmni !== null ? remarksDepOmni : undefined}
          onChange={handleChangeRemarks}
          title="Description"
          placeholder="Type here"
        />
        {(isShowHideRemarkDepOmniBtn || isLoadingAddRemarkData) && (
          <div className="remarks-btn">
            <Button
              type="text"
              loading={isLoadingAddRemarkData}
              onClick={() => handleAddUpdateRemarkDepOmni()}
            >
              Submit
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default OmniPAOALeg;

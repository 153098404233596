import { useMap } from 'react-leaflet';
import Control from 'react-leaflet-custom-control';
import { Dropdown, Radio, Typography, MenuProps } from 'antd';
import MapLayerIcon from 'assets/svg/MapLayerIcon';
import MinusIcon from 'assets/svg/MinusIcon';
import PlusIcon from 'assets/svg/PlusIcon';
import { Maptype } from 'types/common.types';
import './styles.less';

const { Text } = Typography;

interface MapActionControlV1Props {
  mapType: string;
  handleMapType: (data: string) => void;
}

const MapActionControlV1 = ({ mapType, handleMapType }: MapActionControlV1Props) => {
  const map = useMap();
  const handleZoomIn = () => map.zoomIn();
  const handleZoomOut = () => map.zoomOut();

  const handleChangeMapType = (mapType: string) => {
    handleMapType(mapType);
  };

  const items: MenuProps['items'] = [
    {
      label: (
        <div onClick={() => handleChangeMapType(Maptype.Roadmap)} className="roadmap">
          <Radio value={Maptype.Roadmap} checked={mapType === Maptype.Roadmap} />
          <Text>Google Maps Roads</Text>
        </div>
      ),
      key: 'roadmap'
    },
    {
      label: (
        <div onClick={() => handleChangeMapType(Maptype.satellite)}>
          <Radio value={Maptype.satellite} checked={mapType === Maptype.satellite} />
          <Text>Google Maps Satellite</Text>
        </div>
      ),
      key: 'satellite'
    }
  ];
  return (
    <Control position="bottomright">
      <div className="map-action-control">
        <Dropdown
          menu={{ items }}
          trigger={['click']}
          placement="topRight"
          overlayClassName="maplayer-dropdown"
        >
          <div className="map-layer">
            <MapLayerIcon />
          </div>
        </Dropdown>
        <div style={{ height: 24 }} className="zoom-plus" onClick={handleZoomIn}>
          <PlusIcon />
        </div>
        <div className="zoom-minus" onClick={handleZoomOut}>
          <MinusIcon />
        </div>
      </div>
    </Control>
  );
};

export default MapActionControlV1;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Button, Input, Switch, Table, Select, Form } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import ArrowleftIcon from 'assets/svg/ArrowleftIcon';
import EditIcon from 'assets/svg/EditIcon';
import OpenEyeIcon from 'assets/svg/OpenEyeIcon';
import SyncIcon from 'assets/svg/SyncIcon';
import { routes } from 'constants/pageRoutes.constants';
import { adminMemberRole } from 'constants/userData.constants';
import RequiredTitle from 'components/core/RequiredTitle';
import PageHeader from 'components/PageHeader/PageHeader';
import { useAppSelector } from 'hooks/useReduxHook';
import { OrganisationMember, UserRole } from 'store/users/types';
import {
  useAddOrganisationMembersMutation,
  useEnableDisableOrgMemberMutation,
  useGetOrganisationsMembersQuery
} from 'services/apis/userApi';
import { decryptValue } from 'utils/decodeEncodeData';
import './styles.less';

const Usermanagement = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const currentUser = useAppSelector((state) => state.user.userSession);
  const {
    data: organisationMembers,
    isLoading: isLoadingOrgMembers,
    refetch: refetchMembers
  } = useGetOrganisationsMembersQuery(
    currentUser && currentUser.organisationid ? currentUser.organisationid : skipToken
  );
  const [addOrganisationMembers, { isLoading: isAddOrgMembersLoading }] =
    useAddOrganisationMembersMutation();
  const [
    enableDisableOrgMember,
    { isLoading: isLoadingEnableDisableMember, isSuccess: isSuccessEnableDisableMember }
  ] = useEnableDisableOrgMemberMutation();
  const [orgMembers, setOrgMembers] = useState<OrganisationMember[] | []>([]);
  const [memberId, setMemberId] = useState<string | null>(null);

  useEffect(() => {
    if (organisationMembers) {
      const orgMembersResponse: any = organisationMembers;
      if (orgMembersResponse.status) {
        setOrgMembers(orgMembersResponse.data || []);
      }
    }
  }, [organisationMembers]);

  useEffect(() => {
    if (isSuccessEnableDisableMember) {
      refetchMembers();
    }
  }, [isSuccessEnableDisableMember]);

  const handleAddMember = async (values: { email: string; role: string }) => {
    await addOrganisationMembers({
      data: { members: [values] },
      orgId: currentUser.organisationid
    });
    form.resetFields();
  };

  const handleEnableDisableMember = async (isEnableDisable: boolean, memberId: string) => {
    setMemberId(memberId);
    await enableDisableOrgMember({
      status: isEnableDisable,
      orgId: currentUser.organisationid,
      memberId
    });
  };

  const handleNavigate = () => {
    navigate(routes.Home);
  };

  const columns: ColumnsType<OrganisationMember> = [
    {
      title: 'EMAIL ADDRESS',
      dataIndex: 'email',
      key: 'email',
      render: (email) => {
        return email ? decryptValue(email) : '-';
      }
    },
    {
      title: 'ROLE',
      dataIndex: 'role',
      key: 'role'
    },
    // {
    //   title: 'ASSIGNEE',
    //   dataIndex: 'assignee',
    //   key: 'assignee',
    //   render: (_, record) => {
    //     return (
    //       <div className="user-mng-assignee">
    //         {record.assignee ? (
    //           <>
    //             <Avatar size={24} className="assigne-avatar">
    //               {shortLabel(record.assignee)}
    //             </Avatar>
    //             <Text className="assignee-label">{record.assignee}</Text>
    //           </>
    //         ) : (
    //           '-'
    //         )}
    //       </div>
    //     );
    //   }
    // },
    {
      title: 'Actions',
      key: 'action',
      align: 'center',
      width: '20%',
      render: (record) => {
        return (
          <div className="user-mng-actions">
            <EditIcon disabled />
            <SyncIcon disabled />
            <OpenEyeIcon disabled />
            <Switch
              disabled={record.role === UserRole.Admin}
              loading={memberId === record.id ? isLoadingEnableDisableMember : false}
              defaultChecked={record.enable}
              onChange={(checked) => handleEnableDisableMember(checked, record.id)}
            />
          </div>
        );
      }
    }
  ];

  return (
    <div className="main-org">
      <div className="org-label user-mng-label">
        <Button type="text" shape="circle" icon={<ArrowleftIcon />} onClick={handleNavigate} />
        <PageHeader title="User Management" />
      </div>
      <Form autoComplete="off" form={form} onFinish={handleAddMember} className="add-member-form">
        <div className="add-member-content">
          <div className="member-content">
            <div className="input-main-content">
              <div className="add-user-input-email">
                <RequiredTitle title="Email address of Member" className="form-title" />
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: 'Please Enter the valid mail id' },
                    {
                      pattern: /^[a-z0-9.]+@[a-z]+\.[a-z]{2,3}$/,
                      message: 'Enter the valid email'
                    }
                  ]}
                  style={{ marginBottom: 0 }}
                >
                  <Input type="email" placeholder="Enter here" className="add-member-input" />
                </Form.Item>
              </div>
              <div>
                <RequiredTitle title="Role" className="form-title" />
                <Form.Item
                  name="role"
                  rules={[{ required: true, message: 'Please Enter the valid role' }]}
                  style={{ marginBottom: 0 }}
                >
                  <Select
                    className="add-member-select"
                    placeholder="Select"
                    allowClear
                    options={adminMemberRole}
                    // components={{
                    //   IndicatorSeparator: () => null
                    // }}
                    // getOptionLabel={(opt: any) => opt.label}
                    // styles={styles.customStyles}
                  />
                </Form.Item>
              </div>
              {/* {currentUser.role === UserRole.Manager && (
                <div>
                  <RequiredTitle title="Select Designer" className="form-title" />
                  <Select
                    name="designer"
                    className="add-member-select"
                    placeholder="Select"
                    components={{
                      IndicatorSeparator: () => null
                    }}
                    styles={styles.customStyles}
                  />
                </div>
              )} */}
            </div>
            <div>
              <Button
                className="add-user-btn"
                type="primary"
                htmlType="submit"
                block
                disabled={isAddOrgMembersLoading}
                size="large"
              >
                Add User
              </Button>
            </div>
          </div>
        </div>
      </Form>
      <div className="user-list-table" style={{ height: 'calc(100% - 143px)' }}>
        <Table
          className="user-table"
          scroll={{ y: 'calc(100% - 55px)' }}
          loading={isLoadingOrgMembers}
          dataSource={orgMembers}
          columns={columns}
          pagination={false}
          rowKey={(record) => record.id}
        />
      </div>
    </div>
  );
};

export default Usermanagement;

import { designApiService } from 'services/apiService';
import {
  ComputePointRequestType,
  CourseRangeRequestType,
  CreateDesignApiRequest,
  DesignMetadataUpload,
  SnapPointRequestType
} from 'store/design/types';

export const designapi = designApiService.injectEndpoints({
  endpoints: (builder: any) => ({
    getDesigns: builder.query({
      query: (orgId: number) => ({ url: `/organisations/${orgId}/designs`, method: 'GET' }),
      providesTags: ['Design']
    }),
    getDesign: builder.query({
      query: ({ orgId, designId }: any) => {
        return {
          url: `/organisations/${orgId}/designs/${designId}`,
          method: 'GET'
        };
      }
    }),
    addDesign: builder.mutation({
      query: (payload: { data: CreateDesignApiRequest; orgId: number }) => ({
        url: `/organisations/${payload.orgId}/designs`,
        method: 'POST',
        body: payload.data
      }),
      invalidatesTags: ['Design']
    }),
    getRunWay: builder.query({
      query: () => ({
        url: '/inetra/airport_runway',
        method: 'GET'
      })
    }),
    getVersions: builder.query({
      query: () => ({
        url: '/inetra/versions',
        method: 'GET'
      }),
      transformResponse: (response: any) => {
        return response;
      },
      providesTags: ['DesignVersion']
    }),
    getDesignAllMetadata: builder.query({
      query: ({ orgId, designId }: any) => ({
        url: `/organisations/${orgId}/designs/${designId}/metadata`,
        method: 'GET'
      }),
      providesTags: ['Metadata']
    }),
    // getDesignMetadataBymetaId: builder.query({
    //   query: ({ orgId, designId, metaId }: any) => ({
    //     url: `/organisations/${orgId}/designs/${designId}/metadata/${metaId}`,
    //     method: 'GET',
    //   }),
    //   providesTags: ['Metadata']
    // }),
    getDesignMetadataLoad: builder.query({
      query: (urlstring: any) => ({
        url: urlstring,
        method: 'GET'
      }),
      providesTags: ['Metadata']
    }),
    startDesign: builder.mutation({
      query: (payload: { data: DesignMetadataUpload; orgId: number; designId: number }) => ({
        url: `/organisations/${payload.orgId}/designs/${payload.designId}/metadata`,
        method: 'PUT',
        body: payload.data
      }),
      invalidatesTags: ['Metadata']
    }),
    updateProcedureAndDescription: builder.mutation({
      query: (payload: {
        data: {
          procedure: string;
          description: string;
        };
        orgId: number;
        designId: number;
        metaId: number;
        procedureId: number;
      }) => ({
        url: `/organisations/${payload.orgId}/designs/${payload.designId}/metadata/${payload.metaId}/procedures/${payload.procedureId}`,
        method: 'PUT',
        body: payload.data
      }),
      invalidatesTags: ['Design', 'Procedures']
    }),
    getDesignInitStartInfodata: builder.query({
      query: ({ designId }: { designId: number }) => ({
        url: `/designs/${designId}/init_start_info`,
        method: 'GET'
      })
    }),
    getDesignInfobtwPointsdata: builder.query({
      query: ({ urlString }: { urlString: string }) => {
        return {
          url: urlString,
          method: 'GET'
        };
      },
      providesTags: ['Design']
    }),
    getDesignWaypointdata: builder.query({
      query: (airportId: number) => {
        return {
          url: `/waypoint/airport/${airportId}`,
          method: 'GET'
        };
      },
      providesTags: ['Waypoint']
    }),
    getDesignTemperaturedata: builder.query({
      query: ({ airportLat, airportLng }: { airportLat: number; airportLng: number }) => {
        return {
          url: `/temperature/lat/${airportLat}/lng/${airportLng}`,
          method: 'GET'
        };
      },
      providesTags: ['Design']
    }),
    getLegObstacleAnalysisdata: builder.query({
      query: ({
        orgid,
        designid,
        metaId,
        procedureId,
        designversionId,
        legid
      }: {
        orgid: number;
        designid: number;
        metaId: number;
        procedureId: number;
        designversionId: number;
        legid: number;
      }) => {
        return {
          url: `/organisations/${orgid}/designs/${designid}/metadata/${metaId}/procedures/${procedureId}/designversions/${designversionId}/legs/${legid}/obstacle_analysis`,
          method: 'GET'
        };
      },
      providesTags: ['DesignObstacle']
    }),
    addDesignObstacleAnalysisLegdata: builder.mutation({
      query: (payload: {
        data: { type: string };
        orgid: number;
        designid: number;
        metaId: number;
        procedureId: number;
        designversionId: number;
        legid: number;
      }) => {
        return {
          url: `/organisations/${payload.orgid}/designs/${payload.designid}/metadata/${payload.metaId}/procedures/${payload.procedureId}/designversions/${payload.designversionId}/legs/${payload.legid}/obstacle_analysis`,
          method: 'POST',
          body: payload.data
        };
      },
      invalidatesTags: ['DesignObstacle']
    }),
    updateDesignStatus: builder.mutation({
      query: ({ orgId, designId, designStatus }: any) => ({
        url: `/organisations/${orgId}/designs/${designId}/status/${designStatus}`,
        method: 'PUT'
      }),
      invalidatesTags: ['Design']
    }),
    getDesignProcedureList: builder.query({
      query: ({ orgId, designid, metaId }: { orgId: number; designid: number; metaId: number }) => {
        return {
          url: `/organisations/${orgId}/designs/${designid}/metadata/${metaId}/procedures`,
          method: 'GET'
        };
      },
      providesTags: ['Procedures']
    }),
    addDesignProcedure: builder.mutation({
      query: ({ orgId, designid, metaId }: { orgId: number; designid: number; metaId: number }) => {
        return {
          url: `/organisations/${orgId}/designs/${designid}/metadata/${metaId}/procedures`,
          method: 'POST',
          body: {}
        };
      },
      invalidatesTags: ['Procedures']
    }),
    getDesignProcedureVersions: builder.query({
      query: ({
        orgId,
        designid,
        metaId,
        procedureId
      }: {
        orgId: number;
        designid: number;
        metaId: number;
        procedureId: number;
      }) => {
        return {
          url: `/organisations/${orgId}/designs/${designid}/metadata/${metaId}/procedures/${procedureId}/designversions`,
          method: 'GET'
        };
      },
      providesTags: ['Design', 'Metadata']
    }),
    getDesignLegsdata: builder.query({
      query: ({
        orgId,
        designId,
        metaId,
        procedureId,
        designversionId
      }: {
        orgId: number;
        designId: number;
        metaId: number;
        procedureId: number;
        designversionId: number;
      }) => ({
        url: `/organisations/${orgId}/designs/${designId}/metadata/${metaId}/procedures/${procedureId}/designversions/${designversionId}/legs`,
        method: 'GET'
      }),
      providesTags: ['Design', 'DesignObstacle']
    }),
    getDesignLegdataByLegid: builder.query({
      query: ({
        orgId,
        designId,
        metaId,
        procedureId,
        designversionId,
        legId
      }: {
        orgId: number;
        designId: number;
        metaId: number;
        procedureId: number;
        designversionId: number;
        legId: number;
      }) => ({
        url: `/organisations/${orgId}/designs/${designId}/metadata/${metaId}/procedures/${procedureId}/designversions/${designversionId}/legs/${legId}`,
        method: 'GET'
      }),
      providesTags: ['Leg']
    }),
    addDesignLegdata: builder.mutation({
      query: (payload: {
        data: any;
        orgId: number;
        designId: number;
        metaId: number;
        procedureId: number;
        designversionId: number;
      }) => {
        return {
          url: `/organisations/${payload.orgId}/designs/${payload.designId}/metadata/${payload.metaId}/procedures/${payload.procedureId}/designversions/${payload.designversionId}/legs`,
          method: 'POST',
          body: payload.data
        };
      },
      invalidatesTags: ['Design']
    }),
    deleteSelectedDesignLEG: builder.mutation({
      query: ({
        orgId,
        designId,
        metaId,
        procedureId,
        designversionId,
        legId
      }: {
        orgId: number;
        designId: number;
        metaId: number;
        procedureId: number;
        designversionId: number;
        legId: number;
      }) => ({
        url: `/organisations/${orgId}/designs/${designId}/metadata/${metaId}/procedures/${procedureId}/designversions/${designversionId}/legs/${legId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Design']
    }),
    getAreasemiwidth: builder.query({
      query: (urlString: string) => ({
        url: urlString,
        method: 'GET'
      }),
      providesTags: ['AreaSemiWidth']
    }),
    getRefrenceLine: builder.query({
      query: (urlString: string) => ({
        url: urlString,
        method: 'GET'
      }),
      providesTags: ['RefrenceLine']
    }),
    getSnapPoint: builder.mutation({
      query: (data: SnapPointRequestType) => {
        return {
          url: `/snap_point`,
          method: 'POST',
          body: data
        };
      },
      invalidatesTags: ['Design']
    }),
    getNominalCircles: builder.query({
      query: (id: number) => {
        return {
          url: `/nominal_circles/design/${id}`,
          method: 'GET'
        };
      },
      invalidatesTags: ['Design']
    }),
    getCodingTableData: builder.query({
      query: ({
        orgId,
        designId,
        metaId,
        procedureId,
        designversionId
      }: {
        orgId: number;
        designId: number;
        metaId: number;
        procedureId: number;
        designversionId: number;
      }) => ({
        url: `/organisations/${orgId}/designs/${designId}/metadata/${metaId}/procedures/${procedureId}/designversions/${designversionId}/legs/coding_table`,
        method: 'GET'
      }),
      providetags: ['Coding']
    }),
    getSurveyObstacles: builder.query({
      query: ({ metaId }: { metaId: number }) => ({
        url: `/metadata/${metaId}/survey_obstacles`,
        method: 'GET'
      }),
      providetags: ['SurveyObsacles']
    }),
    getMagneticDeclination: builder.query({
      query: ({ lat, lng, year, month, day, elevation, model }: any) => ({
        url: `/magnetic_declination?lat=${lat}&lng=${lng}&year=${year}&month=${month}&day=${day}&elevation=${elevation}&model=${model}`,
        method: 'GET'
      }),
      providetags: ['Magnetic']
    }),
    getCourseRange: builder.mutation({
      query: (data: CourseRangeRequestType) => {
        return {
          url: `/cf_compute_course_range`,
          method: 'POST',
          body: data
        };
      },
      invalidatesTags: ['CFCourse']
    }),
    getComputeV1Point: builder.mutation({
      query: (data: ComputePointRequestType) => {
        return {
          url: `/cf_compute_v1_point`,
          method: 'POST',
          body: data
        };
      },
      invalidatesTags: ['ComputePoint']
    }),
    getPathTerminatorsChoices: builder.query({
      query: ({
        designId,
        procedureId,
        designversionId
      }: {
        orgId: number;
        designId: number;
        metaId: number;
        procedureId: number;
        designversionId: number;
      }) => ({
        url: `/designs/${designId}/procedures/${procedureId}/designversions/${designversionId}/path_terminators_choices`,
        method: 'GET'
      }),
      providetags: ['PathTerminator']
    }),
    addRemark: builder.mutation({
      query: (payload: { data: { remark: any }; legId: number }) => ({
        url: `/legs/${payload.legId}/remark`,
        method: 'PUT',
        body: payload.data
      }),
      invalidatesTags: ['Remark']
    }),
    getAeroReport: builder.query({
      query: ({ designId }: { designId: number }) => ({
        url: `/designs/${designId}/aero_report`,
        method: 'GET'
      }),
      providetags: ['AeroReport']
    }),
    saveAeroReportDetails: builder.mutation({
      query: (payload: { data: any; designid: number }) => ({
        url: `/designs/${payload.designid}/aero_report`,
        method: 'PATCH',
        body: payload.data
      }),
      invalidatesTags: ['AeroReport']
    }),
    completeProcedures: builder.mutation({
      query: (procedure_id: number) => {
        return {
          url: `/procedures/${procedure_id}/complete`,
          method: 'POST'
        };
      },
      invalidatesTags: ['Procedures']
    })
  }),
  overrideExisting: true
});

export const {
  useGetDesignsQuery,
  useGetDesignQuery,
  useAddDesignMutation,
  useGetRunWayQuery,
  useGetVersionsQuery,
  useGetDesignAllMetadataQuery,
  useLazyGetDesignMetadataLoadQuery,
  useStartDesignMutation,
  useUpdateProcedureAndDescriptionMutation,
  useGetDesignInitStartInfodataQuery,
  useLazyGetDesignLegsdataQuery,
  useAddDesignLegdataMutation,
  useLazyGetDesignInfobtwPointsdataQuery,
  useLazyGetDesignWaypointdataQuery,
  useLazyGetDesignTemperaturedataQuery,
  useLazyGetLegObstacleAnalysisdataQuery,
  useAddDesignObstacleAnalysisLegdataMutation,
  useUpdateDesignStatusMutation,
  // useLazyGetDesignMetadataBymetaIdQuery,
  useLazyGetDesignProcedureListQuery,
  useAddDesignProcedureMutation,
  useLazyGetDesignProcedureVersionsQuery,
  useLazyGetAreasemiwidthQuery,
  useLazyGetRefrenceLineQuery,
  useLazyGetDesignLegdataByLegidQuery,
  useGetSnapPointMutation,
  useGetNominalCirclesQuery,
  useLazyGetCodingTableDataQuery,
  useDeleteSelectedDesignLEGMutation,
  useLazyGetSurveyObstaclesQuery,
  useLazyGetMagneticDeclinationQuery,
  useGetCourseRangeMutation,
  useGetComputeV1PointMutation,
  useAddRemarkMutation,
  useLazyGetPathTerminatorsChoicesQuery,
  useGetAeroReportQuery,
  useSaveAeroReportDetailsMutation,
  useCompleteProceduresMutation
} = designapi;

import { useEffect, useState } from 'react';
import { isEmpty, keys } from 'lodash';
import DownloadPdfIcon from 'assets/svg/DownloadPdfIcon';
import { Sec7TableDataType } from 'store/design/types';
import ReportSecTitleWithIcon from './ReportSecTitleWithIcon';
import SIDRouteDescTable from './SIDRouteDescTable';

interface Section7Props {
  scrollElementRef: any;
  sec7: any;
  handleCallSaveAeroReportDataApi: (data: { section_key: string; fields: any }) => void;
}

const Section7 = ({ scrollElementRef, sec7, handleCallSaveAeroReportDataApi }: Section7Props) => {
  const [sec7V1, setSec7V1] = useState<Sec7TableDataType[] | []>([]);
  const [finalSec7Data, setFinalSec7Data] = useState<Sec7TableDataType[] | []>([]);

  useEffect(() => {
    if (!isEmpty(sec7)) {
      const sidKeys = keys(sec7);
      const fData = sidKeys.map((sid: string, index: number) => {
        return {
          key: index + 1,
          sid,
          last_wpt: sec7[`${sid}`].last_wpt,
          description: sec7[`${sid}`].text || ''
        };
      });
      setSec7V1(fData);
    }
  }, [sec7]);

  const handleGetSIDDescriptionData = (data: Sec7TableDataType[] | []) => {
    setFinalSec7Data(data);
  };

  const handleSaveSIDRouteData = () => {
    if (!isEmpty(finalSec7Data)) {
      let fdata = {};
      finalSec7Data.map((d) => {
        fdata = { ...fdata, [d.sid]: { last_wpt: d.last_wpt, text: d.description } };
        return true;
      });
      const payload: { section_key: string; fields: any } = {
        section_key: 'sec_7',
        fields: fdata
      };
      handleCallSaveAeroReportDataApi(payload);
    }
  };

  return (
    <div className="report-code-content sec-3" ref={scrollElementRef} id="sidroute">
      <ReportSecTitleWithIcon
        title="7. SID ROUTE DESCRIPTION"
        icon={<DownloadPdfIcon />}
        handleSaveData={handleSaveSIDRouteData}
      />
      <div className="report-content-data">
        <div className="sid-route-content">
          <SIDRouteDescTable sidrouteData={sec7V1} handleSaveData={handleGetSIDDescriptionData} />
        </div>
      </div>
    </div>
  );
};

export default Section7;

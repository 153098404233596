import { debounce, isEmpty } from 'lodash';
import { InputNumber, Typography } from 'antd';
import InfoCircleIcon from 'assets/svg/InfoCircleIcon';
import { primary, red } from 'constants/color.constant';
import { InitStartInfoType } from 'store/design/types';
import CustomDivider from 'components/core/CustomDivider';

const { Text } = Typography;

interface AltGradiantLegProps {
  selectedPathTerminatorDeparture: string | undefined;
  initStartInfoDeparture: InitStartInfoType | null;
  gradLengthDeparture: number | null;
  gradHeightDeparture: number | null;
  activeLegDesignDataDeparture: any;
  activePrevLegDesignDataDeparture: any;
  endAltitudeDeparture: number | null;
  pdgDeparture: number | null;
  handleChangeAltitudeDeparture: Function;
  handleChangePDGDeparture: Function;
}

const AltGradiantLeg = ({
  selectedPathTerminatorDeparture,
  activeLegDesignDataDeparture,
  activePrevLegDesignDataDeparture,
  gradHeightDeparture,
  initStartInfoDeparture,
  gradLengthDeparture,
  endAltitudeDeparture,
  pdgDeparture,
  handleChangeAltitudeDeparture,
  handleChangePDGDeparture
}: AltGradiantLegProps) => {
  const handleOnchangeAltitudeDeparture = debounce((value: number | null) => {
    handleChangeAltitudeDeparture(value);
  }, 500);

  const handleOnchangePDGDeparture = debounce((value: number | null) => {
    handleChangePDGDeparture(value);
  }, 500);

  return (
    <div className="leg-tab alt-grad-tab">
      <div className="alt-grad-content">
        <Text>Start Altitude</Text>
        <InputNumber
          type="number"
          placeholder="Enter"
          className="alt-input"
          suffix="ft"
          min={0}
          readOnly
          value={
            !isEmpty(activeLegDesignDataDeparture)
              ? Number(activeLegDesignDataDeparture.start_waypoint.alt).toFixed(3) || undefined
              : !isEmpty(activePrevLegDesignDataDeparture)
              ? Number(activePrevLegDesignDataDeparture.end_waypoint.alt).toFixed(3) || undefined
              : initStartInfoDeparture
              ? initStartInfoDeparture.der_elev
              : 0
          }
        />
      </div>
      <div className="start-alt-info">
        <InfoCircleIcon color={red} />
        <Text>MAX(DER,AD ELEV) +5 m</Text>
      </div>
      <div className="alt-grad-height alt-grad-content">
        <Text>{selectedPathTerminatorDeparture === 'RF' ? 'Arc Length' : 'Length'}</Text>
        <InputNumber
          className="alt-input"
          type="number"
          suffix="NM"
          min={0}
          readOnly
          value={Number(Number(gradLengthDeparture).toFixed(3))}
        />
      </div>
      {selectedPathTerminatorDeparture === 'CA' && (
        <div className="alt-grad-content" style={{ paddingTop: 16 }}>
          <Text>PDG</Text>
          <InputNumber
            className="alt-input"
            type="number"
            suffix="NM"
            min={3.3}
            max={10}
            readOnly
            value={pdgDeparture || 3.3}
          />
        </div>
      )}
      {selectedPathTerminatorDeparture !== 'CA' && (
        <div className="alt-grad-content">
          <Text>PDG</Text>
          <InputNumber
            type="number"
            placeholder="Automatically"
            suffix="%"
            min={3.3}
            max={10}
            value={pdgDeparture}
            onChange={handleOnchangePDGDeparture}
            readOnly={!isEmpty(activeLegDesignDataDeparture)}
            style={{ width: 180, color: primary, border: `1px solid ${primary}` }}
          />
        </div>
      )}
      {selectedPathTerminatorDeparture === 'CA' && (
        <div className="alt-grad-content" style={{ paddingTop: 16 }}>
          <Text>End Altitude</Text>
          <InputNumber
            className="alt-input"
            type="number"
            suffix="ft"
            min={0}
            readOnly
            value={
              activeLegDesignDataDeparture
                ? Number(Number(activeLegDesignDataDeparture.end_waypoint.alt).toFixed(3))
                : Number(Number(endAltitudeDeparture).toFixed(3)) || undefined
            }
          />
        </div>
      )}
      {selectedPathTerminatorDeparture !== 'CA' && (
        <div className="end-alt alt-grad-content ">
          <Text>End Altitude</Text>
          <InputNumber
            type="number"
            placeholder="Enter height"
            suffix="ft"
            disabled={isEmpty(selectedPathTerminatorDeparture)}
            readOnly={!isEmpty(activeLegDesignDataDeparture)}
            value={
              activeLegDesignDataDeparture
                ? Number(Number(activeLegDesignDataDeparture.end_waypoint.alt).toFixed(3))
                : Number(Number(endAltitudeDeparture).toFixed(3)) || undefined
            }
            onChange={handleOnchangeAltitudeDeparture}
            style={{
              width: 180,
              border: `1px solid ${primary}`
            }}
          />
        </div>
      )}
      <div className="end-alt-grad-info">
        <InfoCircleIcon color="#eb5757" />
        <Text>Start Altitude + Height</Text>
      </div>
      <CustomDivider marginTop="16px" marginBottom="16px" />
      <div className="alt-grad-height alt-grad-content" style={{ paddingTop: 0 }}>
        <Text>Height</Text>
        <InputNumber
          className="alt-input"
          type="number"
          suffix="ft"
          min={0}
          readOnly
          value={Number(gradHeightDeparture?.toFixed(2)) || undefined}
        />
      </div>
    </div>
  );
};

export default AltGradiantLeg;

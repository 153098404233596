import { useEffect, useMemo, useRef, useState } from 'react';
import { Marker, Popup, useMap } from 'react-leaflet';
import { Button, Space, Typography } from 'antd';
import { capitalize, filter, isEmpty } from 'lodash';
import { ObstacleWhiteRedIcon, ObstacleRedIcon } from 'constants/map.constants';
import { OAlegdata } from 'store/design/types';

interface RenderObstaclesDepatureOmniDataProps {
  obstacleData: OAlegdata[] | [];
  isShowClosingObs?: boolean;
}

const { Text } = Typography;

const RenderObstaclesDepatureOmniData = ({
  obstacleData,
  isShowClosingObs
}: RenderObstaclesDepatureOmniDataProps) => {
  const map = useMap();
  const popupElRef = useRef(null);
  const [selectedPointId, setSelectedPointId] = useState<number | null>(null);
  const [obstacleTableData, setObstacleTableData] = useState<OAlegdata[] | []>([]);

  useEffect(() => {
    const obsData = [...obstacleData];
    if (isShowClosingObs) {
      const data = filter(obsData, ['is_close_in', isShowClosingObs]);
      setObstacleTableData([...data]);
    } else {
      setObstacleTableData([...obsData]);
    }
  }, [isShowClosingObs, obstacleData]);

  const handleClosePopup = () => {
    if (!map) return;

    setSelectedPointId(null);
    map.closePopup();
  };

  const obstacleMemo = useMemo(() => {
    if (isEmpty(obstacleTableData)) return null;
    return obstacleTableData.map((obstacle: OAlegdata) => {
      const data = {
        description: obstacle.description,
        elevation: obstacle.elevation,
        id: obstacle.id,
        uid: obstacle.uid,
        latitude: obstacle.latitude,
        longitude: obstacle.longitude,
        type: obstacle.type,
        dk: obstacle.dk,
        do: obstacle.do,
        dr: obstacle.dr,
        leg_id: obstacle.leg_id,
        moc: obstacle.moc,
        pdg: obstacle.pdg,
        penetration: obstacle.penetration,
        region: obstacle.region
      };
      return (
        <Marker
          key={obstacle.id}
          position={[obstacle.coordinates.coordinates[1], obstacle.coordinates.coordinates[0]]}
          icon={selectedPointId === obstacle.id ? ObstacleWhiteRedIcon : ObstacleRedIcon}
          eventHandlers={{
            click: (e: any) => {
              if (obstacle.id !== selectedPointId) {
                setSelectedPointId(obstacle.id);
              } else {
                setSelectedPointId(null);
                e.target.closePopup();
              }
            }
          }}
        >
          {!isEmpty(obstacle) && (
            <Popup
              className="point-popup omni-obstacle"
              offset={[10, 0]}
              minWidth={232}
              closeOnClick={false}
              closeButton={false}
              maxHeight={226}
            >
              <Button className="close-btn" type="text" onClick={handleClosePopup}>
                x
              </Button>
              <Space direction="vertical">
                {Object.keys(data).map((name: string, index: number) => (
                  <div key={index}>
                    <Text className="market-title">{capitalize(name)}</Text>
                    <Text className="market-data">
                      {
                        // @ts-ignore
                        data[name] || '-'
                      }
                    </Text>
                  </div>
                ))}
              </Space>
            </Popup>
          )}
        </Marker>
      );
    });
  }, [obstacleData, selectedPointId, map, popupElRef, obstacleTableData, isShowClosingObs]);

  // eslint-disable-next-line
  return <>{obstacleMemo}</>;
};
RenderObstaclesDepatureOmniData.defaultProps = {
  isShowClosingObs: false
};

export default RenderObstaclesDepatureOmniData;

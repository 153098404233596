import AircraftIconV1 from 'assets/svg/AircraftIconV1';
import ReportContentCard from 'components/core/ReportContentCard';
import DepartureturnTable from './DepartureturnTable';
import ReportSecTitleWithIcon from './ReportSecTitleWithIcon';
import { keys } from 'lodash';

interface Section9Props {
  scrollElementRef: any;
  sec9: any;
}

const Section9 = ({ scrollElementRef, sec9 }: Section9Props) => {
  return (
    <div className="report-code-content page-break" ref={scrollElementRef} id="departureturn">
      <ReportSecTitleWithIcon title="9. DEPARTURE - TURN PARAMETER " icon={null} />
      <div className="report-content-data">
        {sec9 &&
          keys(sec9).map((ct: string) => {
            const data: any = [];
            keys(sec9[`${ct}`].rows).map((d) => {
              return data.push({
                legs: `${d} ( ${sec9[`${ct}`].rows[`${d}`].start_point} - ${
                  sec9[`${ct}`].rows[`${d}`].end_point
                } )`,
                ias10: sec9[`${ct}`].rows[`${d}`].ias || null,
                endAlt: sec9[`${ct}`].rows[`${d}`].end_alt || null,
                speed: sec9[`${ct}`].rows[`${d}`].speed || null,
                bankAngle: sec9[`${ct}`].rows[`${d}`].bank_angle || null,
                radiusTurn: sec9[`${ct}`].rows[`${d}`].radius || null,
                turnRate: sec9[`${ct}`].rows[`${d}`].rate || null,
                turnAngle: sec9[`${ct}`].rows[`${d}`].aot || null,
                erliestPoint: sec9[`${ct}`].rows[`${d}`].etp || null,
                latestPoint: sec9[`${ct}`].rows[`${d}`].ltp || null,
                twc: sec9[`${ct}`].rows[`${d}`].twc || null
              });
            });

            return (
              <div className="coding-table-content sec-9" key={ct}>
                <ReportContentCard
                  icon={<AircraftIconV1 height={16} />}
                  title={`${ct} ( DER - ${sec9[`${ct}`].end_point} )`}
                  infoText="ISA + 15°"
                />
                <div className="obstacle-table">
                  <DepartureturnTable departureTurnData={data || []} />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Section9;

import { alertSlice } from './slice';

export const {
  enqueueError,
  dequeueError,
  setSuccessAlert,
  setWarningAlert,
  clearAlerts,
  setInfoAlert
} = alertSlice.actions;
export default alertSlice.reducer;

import { Marker, Popup } from 'react-leaflet';
import { Space, Typography } from 'antd';
import { isEmpty } from 'lodash';
import { LightLocation } from 'constants/map.constants';
import { DivIcon, Icon } from 'leaflet';

const { Text } = Typography;

interface PointComponentProps {
  index: number;
  geometry: any;
  properties: any | null;
  pointIcon?: Icon | DivIcon | undefined;
  isFictitious?: boolean;
}

const PointComponent = ({
  pointIcon,
  index,
  geometry,
  properties,
  isFictitious
}: PointComponentProps) => {
  const coords: any = geometry.coordinates;

  if (isEmpty(coords)) return null;

  return (
    <Marker
      opacity={isFictitious ? 0 : 1}
      key={index}
      position={[coords[1], coords[0]]}
      icon={pointIcon}
    >
      {!isEmpty(properties) && (
        <Popup className="point-popup" offset={[0, 0]} minWidth={232} maxHeight={226}>
          <Space direction="vertical">
            {Object.keys(properties).map((name: any, index: number) => (
              <div key={`${index + 1}-${name}-${properties[name]}`}>
                <Text className="market-title">{name}</Text>
                <Text className="market-data">{properties[name]}</Text>
              </div>
            ))}
          </Space>
        </Popup>
      )}
      {/* <Tooltip></Tooltip> */}
    </Marker>
  );
};
PointComponent.defaultProps = {
  pointIcon: LightLocation,
  isFictitious: false
};

export default PointComponent;

import { isEmpty } from 'lodash';
import { Input, InputNumber, Typography } from 'antd';
import InfoCircleIcon from 'assets/svg/InfoCircleIcon';
import { primary, red } from 'constants/color.constant';
import { acftCategory } from 'constants/designData.constants';
import { useAppSelector } from 'hooks/useReduxHook';
import { DesignState } from 'store/design/types';

const { Text } = Typography;

interface SpeedLegProps {
  isDesigner: boolean;
  activeLegDesignDataDeparture: any;
  iasSpeedDeparture: number | null;
  tasSpeedDeparture: number | null;
  handleOnChangeDataReviewIAS: Function;
  endAltitudeDeparture: number | null;
}

const SpeedLeg = ({
  isDesigner,
  activeLegDesignDataDeparture,
  handleOnChangeDataReviewIAS,
  iasSpeedDeparture,
  tasSpeedDeparture,
  endAltitudeDeparture
}: SpeedLegProps) => {
  const { selectedDesign }: DesignState = useAppSelector((state) => state.design);

  const handleChangeIAS = (value: number | null) => {
    handleOnChangeDataReviewIAS(value);
  };

  return (
    <div className="data-review-leg leg-tab">
      <div className="data-review-content">
        <div className="data-content-info">
          <Text>IAS</Text>
          <div className="ias-speed-info" style={{ padding: 0 }}>
            <InfoCircleIcon color={red} />
            <Text>IAS + 10%</Text>
          </div>
        </div>
        <InputNumber
          className="data-review-ias dept-ias"
          type="number"
          placeholder="Enter IAS"
          disabled={!isEmpty(activeLegDesignDataDeparture) || !isDesigner}
          value={
            !isEmpty(activeLegDesignDataDeparture)
              ? activeLegDesignDataDeparture.parameters.ias || undefined
              : iasSpeedDeparture
          }
          min={acftCategory[selectedDesign?.acft_cat!].min}
          max={acftCategory[selectedDesign?.acft_cat!].max}
          onChange={handleChangeIAS}
          suffix={<Text style={{ color: primary, fontSize: 12, fontWeight: 400 }}>kts</Text>}
        />
      </div>
      <div className="ias-speed-info">
        <InfoCircleIcon color={red} />
        <Text>
          The Aircraft Category
          {selectedDesign
            ? `- ${selectedDesign?.acft_cat} (
        ${acftCategory[selectedDesign?.acft_cat!].min} -
          ${acftCategory[selectedDesign?.acft_cat!].max} kt)`
            : ''}
        </Text>
      </div>
      <div className="data-review-content">
        <Text>TAS</Text>
        <Input
          type="text"
          readOnly
          value={
            endAltitudeDeparture && tasSpeedDeparture ? tasSpeedDeparture!.toFixed(3) : undefined
          }
          suffix="kts"
        />
      </div>
      <div className="data-review-content">
        <Text>TWC</Text>
        <InputNumber
          className="data-review-twc"
          type="number"
          placeholder="Enter TWC"
          readOnly
          value={30}
          min={100}
          max={350}
          suffix={<Text style={{ color: primary, fontSize: 12, fontWeight: 400 }}>kts</Text>}
        />
      </div>
    </div>
  );
};

export default SpeedLeg;

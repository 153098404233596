import { white } from 'constants/color.constant';
import { IconProps } from 'types/common.types';

const DotTriangleIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.25 17.6196L12.163 6.75L15.75 17.6196"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="12.1629" cy="16.9676" r="0.652174" fill={color} />
    </svg>
  );
};
DotTriangleIcon.defaultProps = {
  color: white,
  width: 24,
  height: 24,
  onClick: () => {}
};

export default DotTriangleIcon;

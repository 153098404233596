import { useState } from 'react';
import { CircleMarker, Polygon, Popup } from 'react-leaflet';
import { isEmpty, upperCase } from 'lodash';
import { Space, Typography } from 'antd';
import { mapDrawType } from 'constants/collectData.constant';
import { colorTray, red2 } from 'constants/color.constant';
import { FeatureGeometryType } from 'types/common.types';

const { Text } = Typography;

interface RenderRestrictiveAirspaceProps {
  restrictiveAirspaceData: any;
}

const RenderRestrictiveAirspace = ({ restrictiveAirspaceData }: RenderRestrictiveAirspaceProps) => {
  const [selectedOverE, setSelectedOverE] = useState<FeatureGeometryType | null>(null);

  if (restrictiveAirspaceData && isEmpty(restrictiveAirspaceData?.features)) return null;

  const handlePolygonOver = (e: any, element: any) => {
    e.target.bringToFront();
    setSelectedOverE(element);
  };

  const handlePolygonOut = () => {
    setSelectedOverE(null);
  };

  return (
    // eslint-disable-next-line
    <>
      {
        // eslint-disable-next-line
        restrictiveAirspaceData.features.map((data: any, index: number) => {
          if (data.geometry.type === mapDrawType.polygon) {
            if (!isEmpty(data.geometry.coordinates)) {
              const coordinateData = data.geometry.coordinates.map((points: any) => {
                return points.map((point: any) => {
                  return [Number(point[1]), Number(point[0])];
                });
              });
              return (
                <Polygon
                  key={index}
                  positions={coordinateData}
                  pathOptions={{
                    color:
                      selectedOverE?.properties?.name === data.properties.name
                        ? red2
                        : colorTray[index],
                    fillColor: colorTray[index],
                    fillOpacity: 0.5,
                    weight: 1
                  }}
                  eventHandlers={{
                    mouseover: (e) => handlePolygonOver(e, data),
                    mouseout: () => handlePolygonOut()
                  }}
                  pane="shadowPane"
                >
                  {!isEmpty(data.properties) && (
                    <Popup className="polygon-popup point-popup" maxHeight={226}>
                      <Space direction="vertical">
                        {Object.keys(data.properties).map((key: any) => (
                          <Space direction="vertical" key={key}>
                            <Text className="tooltip-title" style={{ fontFamily: 'Manrope' }}>
                              {upperCase(key).replace(/[^a-zA-Z0-9 ]/g, ' ')}
                            </Text>
                            <Text className="tooltip-content" style={{ fontFamily: 'Manrope' }}>
                              {data.properties[key] || '-'}
                            </Text>
                          </Space>
                        ))}
                      </Space>
                    </Popup>
                  )}
                </Polygon>
              );
            }
          }
          const coords: any = data.geometry.coordinates;
          if (data.geometry.type === mapDrawType.point) {
            return (
              <CircleMarker
                key={index}
                pane="tooltipPane"
                center={[coords[1], coords[0]]}
                pathOptions={{
                  color:
                    selectedOverE?.properties?.name === data.properties.name
                      ? red2
                      : colorTray[index],
                  fillColor: colorTray[index],
                  fillOpacity: 1
                }}
                radius={6}
                eventHandlers={{
                  mouseover: (e) => handlePolygonOver(e, data),
                  mouseout: () => handlePolygonOut()
                }}
              >
                {!isEmpty(data.properties) && (
                  <Popup className="point-popup" offset={[0, 0]} minWidth={232} maxHeight={226}>
                    <Space direction="vertical">
                      {Object.keys(data.properties).map((name: any, index: number) => (
                        <div key={`${index + 1}-${name}-${data.properties[name]}`}>
                          <Text className="market-title">{name}</Text>
                          <Text className="market-data">{data.properties[name]}</Text>
                        </div>
                      ))}
                    </Space>
                  </Popup>
                )}
              </CircleMarker>
            );
          }
        })
      }
    </>
  );
};

export default RenderRestrictiveAirspace;

import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { find, includes, isEmpty, remove } from 'lodash';
import { Button, Divider, Input, Steps, Switch, Tabs, Typography } from 'antd';
import moment from 'moment';
import ArrowleftIconV1 from 'assets/svg/ArrowleftIconV1';
import ArrowRightIconV1 from 'assets/svg/ArrowRightIconV1';
import DeleteIcon from 'assets/svg/DeleteIcon';
import { TimezoneContext } from 'context/createusecontext';
import { primary, white } from 'constants/color.constant';
import { DateFormat2, TimeFormat } from 'constants/common.constant';
import { aircraftCategoryOptions } from 'constants/designData.constants';
import ProtectionAreaSwitch from 'components/core/ProtectionAreaSwitch';
import ObstacleAnalysisSwitch from 'components/DesignData/ObstacleAnalysisSwitch';
import LegData from 'components/DesignData/LegData';
import OmniLegData from 'components/DesignData/OmniLegData';
import DesignProcedureModal from 'components/Modals/DesignProcedureModal';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import {
  DesignState,
  InfobtwpointsResponseType,
  InitStartInfoType,
  OAlegdata,
  ProcedureDataType,
  WaypointMarkerResponseType,
  TemperatureType,
  AreasemiwidthDepartureType,
  CourseRangeResponseType
} from 'store/design/types';
import { enqueueError, setWarningAlert } from 'store/alert';
import { UserState } from 'store/users/types';
import {
  useCompleteProceduresMutation,
  useUpdateProcedureAndDescriptionMutation
} from 'services/apis/designsApi';
import { LegTabItemsType, OptionsType, PathTerminatorOptionsType } from 'types/common.types';
import './styles.less';

const { Text } = Typography;
const { TextArea } = Input;

interface DesignStartedSidebarProps {
  designid: any;
  // legsData: any;
  activeLegDesignDataDeparture: any;
  activePrevLegDesignDataDeparture: any;
  endWaypointLatLngDeparture: any;
  selectedProcedureData: ProcedureDataType | null;
  selectedWayPointMarker: WaypointMarkerResponseType | null;
  activeLeg: string;
  activeDeptBtnDeparture: string;
  handleActiveLegModuleTabBtn: (activebtn: string) => void;
  initStartInfoDeparture: InitStartInfoType | null;
  selectedMetaId: number | null;
  initInfoBearing: number | null;
  handleShowObstacleTableDeparture: (isShow: boolean) => void;
  handleChangeWaypoint: (waypoint: string) => void;
  handleViewSelectWaypointOnMap: (waypoint: string) => void;
  handleActiveFlyBtn: (isActiveBtn: string) => void;
  handleEndWaypointLat: (waypointlat: number | undefined) => void;
  handleEndWaypointLng: (waypointlng: number | undefined) => void;
  handleEnterLatLngViewOnMap: (isView: boolean) => void;
  handleChangeAltitudeDeparture: Function;
  handleChangePDGDeparture: Function;
  endSelectNavaidWaypoint: string | null;
  gradLengthDeparture: number | null;
  iasSpeedDeparture: number | null;
  tasSpeedDeparture: number | null;
  gradHeightDeparture: number | null;
  selectedPathTerminatorDeparture: string | undefined;
  endWaypointLat: number | null;
  endWaypointLng: number | null;
  cwyDisDeparture: number | null;
  changeCWYPointInfoDataDeparture: InfobtwpointsResponseType | null;
  activeFlyBtn: string;
  isShowHideOADataDeparture: boolean;
  isGeneratedOA: boolean;
  handleCodingTable: (activeTable: boolean) => void;
  handleGeneratePADataDeparture: Function;
  handleGenerateOADataDeparture: Function;
  activeOALegDesignDataDeparture: OAlegdata[] | [];
  handleCreateNewLegDataClear: Function;
  handleChangePathTerminatorDeparture: Function;
  handleChangeCWYdisInputDeparture: Function;
  handleOnChangeDataReviewIAS: Function;
  handleChangeCWYSetInitInfoDataDeparture: Function;
  handleOnChangeActiveLegIndex: Function;
  handleActiveCurrentDesignStep: Function;
  isactiveCodingTable: boolean;
  isShowHideTerrainLayerDeparture: boolean;
  oaLegCheckedKey: string | null;
  turnDirectionDeparture: string | null;
  turnAngleDeparture: number | null;
  courseDeparture: number | null;
  temperature: TemperatureType | null;
  isDesigner: boolean;
  courseRangeDataDept: CourseRangeResponseType[] | [];
  areasemiwidthDeparture: AreasemiwidthDepartureType | null;
  handleClickOnSpeedTabDeparture: Function;
  handleShowTerrainLayerDeparture: Function;
  /* Omni Props START */
  cwyDisOMNI: number | null;
  endAltGradLengthOMNI: number | null;
  endAltitudeDeparture: number | null;
  pdgDeparture: number | null;
  handleOMNIChangeCWYdisInput: Function;
  initStartInfoOMNI: InitStartInfoType | null;
  handleChangeCWYSetInitInfoDataOMNI: Function;
  changeCWYPointInfoDataOMNI: InfobtwpointsResponseType | null;
  handleChangeLengthOMNI: Function;
  changeLengthPointInfoDataOMNI: InfobtwpointsResponseType | null;
  handleClickEndLatLngViewOnMapOMNI: Function;
  handleOnChangeDataReviewIASOMNI: Function;
  iasSpeedOMNI: number | null;
  tasSpeedOMNI: number | null;
  handleGeneratePADataOMNI: Function;
  handleGenerateOADataOMNI: Function;
  handleBackToDesign: Function;
  // legsDataOMNI: any;
  activeLegDesignDataOMNI: any;
  activeOALegDesignDataOMNI: OAlegdata[] | [];
  handleShowObstacleTableOMNI: (isShow: boolean) => void;
  isShowHideOADataOMNI: boolean;
  handleChangeOmniEndPDG: Function;
  handleChangeOmniEndAltGrad: Function;
  handleChangeOmniEndLength: Function;
  handleEndDisableFieldOMNI: Function;
  handleClickOnSpeedTabOmni: Function;
  disableEndPointFieldOMNI: string;
  endAltitudeOMNI: number | null;
  magneticDeclination: number | null;
  radiusOfTurn: number | null;
  pdgOMNI: number;
  handleShowTerrainLayerOMNI: Function;
  isShowHideTerrainLayerOMNI: boolean;
  /* Omni Props END */
  activeObscleTerrainDataKey: string;
  handleShowAllProtectionArea: (isShow: boolean) => void;
  handleShowLengthAltPDGRangeOMNI: (isShow: boolean) => void;
  handleShowHideObstacleLayer: (isShow: boolean) => void;
  handleShowHideTerrainLayer: (isShow: boolean) => void;
  palegDatacheckedList: string[];
  isCheckedPASwitch: boolean;
  isLengthAltPDGRangeOMNI: boolean;
  isShowHideObstacleLayer: boolean;
  isShowHideTerrainLayer: boolean;
  handleChangeProtectionAreaLegSwitch: (checkedLeg: string) => void;
  handleChangeOALegSwitch: (oacheckedLeg: string, legid: number) => void;
  handleChangeTerminatingWaypoint: (isTerminate: boolean) => void;
  handleChangeRemarksDepOmni: (isTerminate: string | undefined) => void;
  isLoadingAddRemarkData: boolean;
  handleAddUpdateRemarkDepOmni: Function;
  handleChangePAlegData: Function;
  legOAData: OAlegdata[] | [];
  isTerminatingWaypoint: boolean;
  isShowHideRemarkDepOmniBtn: boolean;
  remarksDepOmni: string | undefined;
  handleDeleteLegData: Function;
  CFCourseDept: number | undefined;
  handleChangeCFCourseDept: Function;
  handleAddNewLeg: Function;
  handleRefreshGeneratedOAdata: Function;
  activeMapElement: string[];
  pathTerminatorOptions: PathTerminatorOptionsType[] | [];
}

const DesignStartedSidebar = ({
  isDesigner,
  designid,
  isShowHideTerrainLayerDeparture,
  activeLegDesignDataDeparture,
  activePrevLegDesignDataDeparture,
  endWaypointLatLngDeparture,
  selectedWayPointMarker,
  activeDeptBtnDeparture,
  pathTerminatorOptions,
  handleActiveLegModuleTabBtn,
  // eslint-disable-next-line
  initInfoBearing,
  temperature,
  cwyDisDeparture,
  radiusOfTurn,
  activeLeg,
  endAltitudeDeparture,
  pdgDeparture,
  courseDeparture,
  isGeneratedOA,
  selectedMetaId,
  magneticDeclination,
  initStartInfoDeparture,
  selectedProcedureData,
  gradHeightDeparture,
  activeObscleTerrainDataKey,
  changeCWYPointInfoDataDeparture,
  handleOnChangeActiveLegIndex,
  handleChangeAltitudeDeparture,
  handleChangePDGDeparture,
  handleShowObstacleTableDeparture,
  handleChangeWaypoint,
  handleViewSelectWaypointOnMap,
  handleActiveFlyBtn,
  handleEndWaypointLat,
  handleEndWaypointLng,
  handleEnterLatLngViewOnMap,
  handleShowLengthAltPDGRangeOMNI,
  isLengthAltPDGRangeOMNI,
  handleClickOnSpeedTabDeparture,
  isShowHideOADataDeparture,
  endSelectNavaidWaypoint,
  endWaypointLat,
  endWaypointLng,
  activeFlyBtn,
  courseRangeDataDept,
  handleCodingTable,
  isactiveCodingTable,
  handleGeneratePADataDeparture,
  handleGenerateOADataDeparture,
  activeOALegDesignDataDeparture,
  oaLegCheckedKey,
  handleChangePathTerminatorDeparture,
  handleCreateNewLegDataClear,
  handleChangeCWYdisInputDeparture,
  selectedPathTerminatorDeparture,
  handleChangeCWYSetInitInfoDataDeparture,
  handleOnChangeDataReviewIAS,
  iasSpeedDeparture,
  tasSpeedDeparture,
  gradLengthDeparture,
  turnDirectionDeparture,
  turnAngleDeparture,
  areasemiwidthDeparture,
  handleActiveCurrentDesignStep,
  handleShowAllProtectionArea,
  palegDatacheckedList,
  isCheckedPASwitch,
  handleChangeProtectionAreaLegSwitch,
  handleChangeOALegSwitch,
  handleChangeTerminatingWaypoint,
  handleChangeRemarksDepOmni,
  handleAddUpdateRemarkDepOmni,
  isLoadingAddRemarkData,
  isTerminatingWaypoint,
  remarksDepOmni,
  isShowHideRemarkDepOmniBtn,
  handleChangePAlegData,
  legOAData,
  handleDeleteLegData,
  handleShowTerrainLayerDeparture,
  handleChangeCFCourseDept,
  CFCourseDept,
  activeMapElement,
  handleAddNewLeg,
  handleRefreshGeneratedOAdata,
  /* Omni Props START */
  handleShowTerrainLayerOMNI,
  isShowHideTerrainLayerOMNI,
  cwyDisOMNI,
  endAltGradLengthOMNI,
  handleOMNIChangeCWYdisInput,
  initStartInfoOMNI,
  handleChangeCWYSetInitInfoDataOMNI,
  changeCWYPointInfoDataOMNI,
  handleChangeLengthOMNI,
  changeLengthPointInfoDataOMNI,
  handleClickEndLatLngViewOnMapOMNI,
  handleOnChangeDataReviewIASOMNI,
  iasSpeedOMNI,
  tasSpeedOMNI,
  handleGeneratePADataOMNI,
  activeLegDesignDataOMNI,
  handleGenerateOADataOMNI,
  activeOALegDesignDataOMNI,
  isShowHideObstacleLayer,
  isShowHideTerrainLayer,
  handleShowObstacleTableOMNI,
  handleShowHideObstacleLayer,
  handleShowHideTerrainLayer,
  isShowHideOADataOMNI,
  handleBackToDesign,
  handleChangeOmniEndPDG,
  handleChangeOmniEndAltGrad,
  handleChangeOmniEndLength,
  handleEndDisableFieldOMNI,
  disableEndPointFieldOMNI,
  pdgOMNI,
  endAltitudeOMNI,
  handleClickOnSpeedTabOmni
}: /* Omni Props END */
DesignStartedSidebarProps) => {
  const dispatch = useAppDispatch();
  const {
    selectedDesign,
    legsDataDeparture,
    legsDataOMNI,
    designProcedureVersionsData
  }: DesignState = useAppSelector((state) => state.design);
  const { userSession }: UserState = useAppSelector((state) => state.user);
  const [updateProcedureAndDescription, { isLoading: isUpdateProcedureLoading }] =
    useUpdateProcedureAndDescriptionMutation();
  // eslint-disable-next-line
  const [completeProcedures, { isLoading: isCompleteProcedureLoading }] =
    useCompleteProceduresMutation();
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [isShowHideProcedureModal, setShowHideProcedureModal] = useState<boolean>(false);
  const [currentDesignStep, setCurrentDesignStep] = useState<number>(0);
  // const [isLastLegDataDepartureData, setLastLegDataDepartureData] = useState<boolean>(false);
  const [activeDeptBtn, setActiveDeptBtn] = useState<string>(
    selectedProcedureData && selectedProcedureData.procedure
      ? selectedProcedureData.procedure
      : 'OMNI'
  );
  const [description, setDescription] = useState<string | undefined>(
    selectedProcedureData && selectedProcedureData.description
      ? selectedProcedureData.description
      : undefined
  );
  const [legTabItems, setLegTabItems] = useState<LegTabItemsType[] | []>([
    {
      label: 'LEG 1',
      key: '1',
      closable: true,
      closeIcon: <DeleteIcon height={16} width={16} />
    }
  ]);

  const newTabIndex = useRef(
    !isEmpty(legsDataDeparture) ? Object.keys(legsDataDeparture).length + 1 : 2
  );

  const timeZone = useContext(TimezoneContext);
  const date: any =
    selectedDesign && selectedDesign.created_at
      ? moment(selectedDesign.created_at).format(DateFormat2)
      : '-';
  const time: any =
    selectedDesign && selectedDesign.created_at
      ? `${moment(selectedDesign.created_at).format(TimeFormat)} ${timeZone?.timezone}`
      : '';

  useEffect(() => {
    if (
      !isEmpty(legsDataDeparture) &&
      selectedProcedureData &&
      selectedProcedureData.procedure === 'DEPARTURE'
    ) {
      newTabIndex.current = Object.keys(legsDataDeparture).length + 1;
      const tabItems: any[] = [];
      const legArray: string[] | [] = Object.keys(legsDataDeparture);
      legArray.map((leg: string, index: number) => {
        return tabItems.push({
          label: `LEG ${index + 1}`,
          key: String(index + 1),
          // closable: legArray.length !== 1 && legArray.length - 1 === index,
          // closeIcon:
          //   legArray.length !== 1 && legArray.length - 1 === index ? (
          //     <DeleteIcon height={16} width={16} />
          //   ) : null,
          closable: legArray.length - 1 === index,
          closeIcon: legArray.length - 1 === index ? <DeleteIcon height={16} width={16} /> : null
        });
      });

      setLegTabItems([...tabItems]);
      handleOnChangeActiveLegIndex(activeLeg);
    }
  }, [legsDataDeparture]);

  useEffect(() => {
    setBtnLoading(isUpdateProcedureLoading);
  }, [isUpdateProcedureLoading]);

  const handleChangeLeg = (activeLKey: string) => {
    handleOnChangeActiveLegIndex(activeLKey);
  };

  const addLeg = () => {
    const newActiveKey = `${newTabIndex.current++}`;
    const newPanes = [...legTabItems];

    newPanes.push({
      label: `LEG ${newActiveKey}`,
      key: newActiveKey,
      closable: false
    });

    const itemdata = newPanes.map((data) => {
      return data.key === newActiveKey
        ? { ...data, ...{ closable: true, closeIcon: <DeleteIcon height={16} width={16} /> } }
        : { ...data, ...{ closable: false, closeIcon: null } };
    });

    setLegTabItems([...itemdata]);
    handleOnChangeActiveLegIndex(newActiveKey);
    handleCreateNewLegDataClear();
    handleAddNewLeg();
  };

  const handleAddLeg = (
    targetKey: React.MouseEvent | React.KeyboardEvent | string,
    action: 'add' | 'remove'
  ) => {
    if (action === 'add') {
      if (isDesigner && activeLegDesignDataDeparture) {
        addLeg();
        if (!includes(activeMapElement, 'splaycircles')) {
          activeMapElement.push('splaycircles');
        }
      }
    } else if (action === 'remove') {
      if (
        !isEmpty(legsDataDeparture) &&
        selectedProcedureData &&
        selectedProcedureData.procedure === 'DEPARTURE'
      ) {
        const id = legsDataDeparture[`Leg${targetKey}`]?.id;

        if (id) {
          handleDeleteLegData(targetKey);
        } else {
          if (Number(targetKey) === 1) return;
          const items = [...legTabItems];
          remove(items, (item) => {
            return item.key === targetKey;
          });

          let finalItems = [...items];
          finalItems = [...items].map((data, index) => {
            return [...items].length - 1 === index
              ? { ...data, ...{ closable: true, closeIcon: <DeleteIcon height={16} width={16} /> } }
              : { ...data, ...{ closable: false, closeIcon: null } };
          });

          newTabIndex.current = [...finalItems].length + 1;
          setLegTabItems([...finalItems]);
          handleChangeLeg(String([...finalItems].length));
        }
      } else if (
        !isEmpty(legsDataOMNI) &&
        selectedProcedureData &&
        selectedProcedureData.procedure === 'OMNI'
      ) {
        const id = legsDataOMNI.Leg1?.id;

        if (id) handleDeleteLegData('1');
      }
    }
  };

  const handleNextPrevStep = () => {
    if (currentDesignStep >= 0 && currentDesignStep < 3) {
      let step: number = currentDesignStep;
      setCurrentDesignStep((step += 1));
      handleActiveCurrentDesignStep(step);
    }
  };

  const handleProceedDeparture = async () => {
    if (
      isDesigner &&
      userSession &&
      userSession.organisationid &&
      designid &&
      selectedMetaId &&
      selectedProcedureData
    ) {
      if (!description) {
        dispatch(enqueueError('Add description...'));
        return;
      }

      // handleRefrencelineApiCall(1, initInfoBearing);

      const payload = {
        data: { procedure: activeDeptBtn, description },
        orgId: userSession?.organisationid,
        designId: Number(designid!),
        metaId: selectedMetaId,
        procedureId: selectedProcedureData.id
      };

      if (isDesigner && selectedProcedureData?.description !== description) {
        await updateProcedureAndDescription(payload);
      }
    }
    setShowHideProcedureModal(false);
    handleNextPrevStep();
  };

  const handleCancelDeparture = () => {
    setShowHideProcedureModal(false);
  };

  const handleDesignContinue = async () => {
    const step3LegData =
      selectedProcedureData && selectedProcedureData.procedure === 'OMNI'
        ? legsDataOMNI
        : legsDataDeparture;
    if (currentDesignStep === 0) {
      if (selectedProcedureData && isEmpty(selectedProcedureData.procedure)) {
        setShowHideProcedureModal(true);
      } else {
        if (
          isDesigner &&
          selectedProcedureData &&
          selectedProcedureData.description !== description
        ) {
          const payload = {
            data: { procedure: activeDeptBtn, description },
            orgId: userSession?.organisationid,
            designId: designid!,
            metaId: selectedMetaId,
            procedureId: selectedProcedureData.id
          };
          await updateProcedureAndDescription(payload);
        }

        handleNextPrevStep();
      }
    } else if (currentDesignStep > 0 && currentDesignStep < 3) {
      if (currentDesignStep === 1 && isEmpty(step3LegData)) {
        dispatch(setWarningAlert('Create atleast one leg to continue.'));
        return;
      }
      handleNextPrevStep();
    }
  };

  const handleProcedureComplete = () => {
    if (!selectedProcedureData) {
      dispatch(setWarningAlert('Something went wrong.'));
      return;
    }

    completeProcedures(selectedProcedureData.id!);
  };

  const handleonChangeDesignStep = (value: number) => {
    const step3LegData =
      selectedProcedureData && selectedProcedureData.procedure === 'OMNI'
        ? legsDataOMNI
        : legsDataDeparture;
    if (
      currentDesignStep === 0 &&
      selectedProcedureData &&
      isEmpty(selectedProcedureData.procedure)
    ) {
      dispatch(setWarningAlert('Complete step 1 first...'));
      return;
    }
    if (currentDesignStep === 0 && (value === 2 || value === 3) && isEmpty(step3LegData)) {
      dispatch(setWarningAlert('Create atleast one leg to continue.'));
      return;
    }
    if (currentDesignStep === 1 && (value === 2 || value === 3) && isEmpty(step3LegData)) {
      dispatch(setWarningAlert('Create atleast one leg to continue.'));
      return;
    }

    setCurrentDesignStep(value);
    handleActiveCurrentDesignStep(value);
    handleChangePAlegData();
  };

  const legData = useMemo(
    () => (
      <LegData
        isDesigner={isDesigner}
        courseDeparture={courseDeparture}
        activePrevLegDesignDataDeparture={activePrevLegDesignDataDeparture}
        endWaypointLatLngDeparture={endWaypointLatLngDeparture}
        initStartInfoDeparture={initStartInfoDeparture}
        activeLegKey={activeLeg}
        isGeneratedOA={isGeneratedOA}
        activeLegDesignDataDeparture={activeLegDesignDataDeparture}
        selectedWayPointMarker={selectedWayPointMarker}
        activeDeptBtnDeparture={activeDeptBtnDeparture}
        handleActiveLegModuleTabBtn={handleActiveLegModuleTabBtn}
        handleShowObstacleTableDeparture={handleShowObstacleTableDeparture}
        isShowHideOADataDeparture={isShowHideOADataDeparture}
        handleChangeWaypoint={handleChangeWaypoint}
        endAltitudeDeparture={endAltitudeDeparture}
        pdgDeparture={pdgDeparture}
        handleChangeAltitudeDeparture={handleChangeAltitudeDeparture}
        handleChangePDGDeparture={handleChangePDGDeparture}
        handleViewSelectWaypointOnMap={handleViewSelectWaypointOnMap}
        handleActiveFlyBtn={handleActiveFlyBtn}
        handleEndWaypointLat={handleEndWaypointLat}
        handleEndWaypointLng={handleEndWaypointLng}
        endSelectNavaidWaypoint={endSelectNavaidWaypoint}
        endWaypointLat={endWaypointLat}
        endWaypointLng={endWaypointLng}
        activeFlyBtn={activeFlyBtn}
        handleEnterLatLngViewOnMap={handleEnterLatLngViewOnMap}
        handleGeneratePADataDeparture={handleGeneratePADataDeparture}
        handleGenerateOADataDeparture={handleGenerateOADataDeparture}
        activeOALegDesignDataDeparture={activeOALegDesignDataDeparture}
        selectedPathTerminatorDeparture={selectedPathTerminatorDeparture}
        handleChangePathTerminatorDeparture={handleChangePathTerminatorDeparture}
        changeCWYPointInfoDataDeparture={changeCWYPointInfoDataDeparture}
        handleChangeCWYSetInitInfoDataDeparture={handleChangeCWYSetInitInfoDataDeparture}
        cwyDisDeparture={cwyDisDeparture}
        handleChangeCWYdisInputDeparture={handleChangeCWYdisInputDeparture}
        iasSpeedDeparture={iasSpeedDeparture}
        tasSpeedDeparture={tasSpeedDeparture}
        gradLengthDeparture={gradLengthDeparture}
        turnDirectionDeparture={turnDirectionDeparture}
        turnAngleDeparture={turnAngleDeparture}
        areasemiwidthDeparture={areasemiwidthDeparture}
        handleOnChangeDataReviewIAS={handleOnChangeDataReviewIAS}
        handleClickOnSpeedTabDeparture={handleClickOnSpeedTabDeparture}
        gradHeightDeparture={gradHeightDeparture}
        activeObscleTerrainDataKey={activeObscleTerrainDataKey}
        magneticDeclination={magneticDeclination}
        isShowHideTerrainLayerDeparture={isShowHideTerrainLayerDeparture}
        handleShowTerrainLayerDeparture={handleShowTerrainLayerDeparture}
        radiusOfTurn={radiusOfTurn}
        CFCourseDept={CFCourseDept}
        handleChangeCFCourseDept={handleChangeCFCourseDept}
        courseRangeDataDept={courseRangeDataDept}
        pathTerminatorOptions={pathTerminatorOptions}
        isTerminatingWaypoint={isTerminatingWaypoint}
        remarksDepOmni={remarksDepOmni}
        isShowHideRemarkDepOmniBtn={isShowHideRemarkDepOmniBtn}
        handleChangeTerminatingWaypoint={handleChangeTerminatingWaypoint}
        isLoadingAddRemarkData={isLoadingAddRemarkData}
        handleAddUpdateRemarkDepOmni={handleAddUpdateRemarkDepOmni}
        handleChangeRemarksDepOmni={handleChangeRemarksDepOmni}
        handleRefreshGeneratedOAdata={handleRefreshGeneratedOAdata}
      />
    ),
    [
      isDesigner,
      activeLeg,
      isGeneratedOA,
      endAltitudeDeparture,
      activeDeptBtnDeparture,
      activeFlyBtn,
      areasemiwidthDeparture,
      gradLengthDeparture,
      activeOALegDesignDataDeparture,
      activeLegDesignDataDeparture,
      cwyDisDeparture,
      changeCWYPointInfoDataDeparture,
      courseDeparture,
      endSelectNavaidWaypoint,
      endWaypointLat,
      endWaypointLatLngDeparture,
      endWaypointLng,
      isShowHideOADataDeparture,
      initStartInfoDeparture,
      iasSpeedDeparture,
      pdgDeparture,
      selectedPathTerminatorDeparture,
      tasSpeedDeparture,
      turnDirectionDeparture,
      turnAngleDeparture,
      temperature,
      activePrevLegDesignDataDeparture,
      gradHeightDeparture,
      activeObscleTerrainDataKey,
      magneticDeclination,
      isShowHideTerrainLayerDeparture,
      radiusOfTurn,
      CFCourseDept,
      courseRangeDataDept,
      pathTerminatorOptions,
      isTerminatingWaypoint,
      remarksDepOmni,
      isLoadingAddRemarkData,
      isShowHideRemarkDepOmniBtn
    ]
  );

  const omniLegData = useMemo(
    () => (
      <OmniLegData
        isDesigner={isDesigner}
        initStartInfoOMNI={initStartInfoOMNI}
        activeLegKey="1"
        cwyDisOMNI={cwyDisOMNI}
        endAltGradLengthOMNI={endAltGradLengthOMNI}
        handleOMNIChangeCWYdisInput={handleOMNIChangeCWYdisInput}
        changeLengthPointInfoDataOMNI={changeLengthPointInfoDataOMNI}
        handleClickEndLatLngViewOnMapOMNI={handleClickEndLatLngViewOnMapOMNI}
        changeCWYPointInfoDataOMNI={changeCWYPointInfoDataOMNI}
        handleChangeLengthOMNI={handleChangeLengthOMNI}
        handleChangeCWYSetInitInfoDataOMNI={handleChangeCWYSetInitInfoDataOMNI}
        handleOnChangeDataReviewIASOMNI={handleOnChangeDataReviewIASOMNI}
        iasSpeedOMNI={iasSpeedOMNI}
        tasSpeedOMNI={tasSpeedOMNI}
        handleGeneratePADataOMNI={handleGeneratePADataOMNI}
        activeLegDesignDataOMNI={activeLegDesignDataOMNI}
        activeOALegDesignDataOMNI={activeOALegDesignDataOMNI}
        handleShowObstacleTableOMNI={handleShowObstacleTableOMNI}
        isShowHideOADataOMNI={isShowHideOADataOMNI}
        handleGenerateOADataOMNI={handleGenerateOADataOMNI}
        temperature={temperature}
        handleChangeOmniEndPDG={handleChangeOmniEndPDG}
        handleChangeOmniEndAltGrad={handleChangeOmniEndAltGrad}
        handleChangeOmniEndLength={handleChangeOmniEndLength}
        disableEndPointFieldOMNI={disableEndPointFieldOMNI}
        handleEndDisableFieldOMNI={handleEndDisableFieldOMNI}
        pdgOMNI={pdgOMNI}
        endAltitudeOMNI={endAltitudeOMNI}
        handleClickOnSpeedTabOmni={handleClickOnSpeedTabOmni}
        isShowHideTerrainLayerOMNI={isShowHideTerrainLayerOMNI}
        activeObscleTerrainDataKey={activeObscleTerrainDataKey}
        handleShowTerrainLayerOMNI={handleShowTerrainLayerOMNI}
        handleShowLengthAltPDGRangeOMNI={handleShowLengthAltPDGRangeOMNI}
        isLengthAltPDGRangeOMNI={isLengthAltPDGRangeOMNI}
        remarksDepOmni={remarksDepOmni}
        isShowHideRemarkDepOmniBtn={isShowHideRemarkDepOmniBtn}
        isLoadingAddRemarkData={isLoadingAddRemarkData}
        handleAddUpdateRemarkDepOmni={handleAddUpdateRemarkDepOmni}
        handleChangeRemarksDepOmni={handleChangeRemarksDepOmni}
        handleRefreshGeneratedOAdata={handleRefreshGeneratedOAdata}
      />
    ),
    [
      isDesigner,
      cwyDisOMNI,
      initStartInfoOMNI,
      endAltGradLengthOMNI,
      changeCWYPointInfoDataOMNI,
      isShowHideTerrainLayerOMNI,
      temperature,
      changeLengthPointInfoDataOMNI,
      iasSpeedOMNI,
      tasSpeedOMNI,
      activeLegDesignDataOMNI,
      activeOALegDesignDataOMNI,
      isShowHideOADataOMNI,
      disableEndPointFieldOMNI,
      pdgOMNI,
      endAltitudeOMNI,
      isLengthAltPDGRangeOMNI,
      activeObscleTerrainDataKey,
      remarksDepOmni,
      isLoadingAddRemarkData,
      isShowHideRemarkDepOmniBtn
    ]
  );

  const paLegSwitch = useMemo(() => {
    const legdata =
      selectedProcedureData && selectedProcedureData.procedure === 'OMNI'
        ? legsDataOMNI
        : legsDataDeparture;

    const sortLegdata: any = {};

    if (isEmpty(legdata)) return null;

    Object.keys(legdata)
      .sort((a, b) => {
        return legdata[a].id - legdata[b].id;
      })
      .forEach((key) => {
        sortLegdata[key] = legdata[key];
      });
    const legsDataKeys: string[] | [] = !isEmpty(sortLegdata) ? Object.keys(sortLegdata) : [];

    return legsDataKeys.map((item: string) => {
      return (
        <ProtectionAreaSwitch
          key={item}
          isCheckedPALegSwitch={Boolean(includes(palegDatacheckedList, item))}
          currentLegData={legdata[item]}
          data={item}
          handleChangePALegSwitch={handleChangeProtectionAreaLegSwitch}
        />
      );
    });
  }, [palegDatacheckedList, legsDataDeparture, legsDataOMNI, activeLeg]);

  const oaLegSwitch = useMemo(() => {
    const legdata =
      selectedProcedureData && selectedProcedureData.procedure === 'OMNI'
        ? legsDataOMNI
        : legsDataDeparture;

    const sortLegdata: any = {};

    if (isEmpty(legdata)) return null;

    Object.keys(legdata)
      .sort((a, b) => {
        return legdata[a].id - legdata[b].id;
      })
      .forEach((key) => {
        sortLegdata[key] = legdata[key];
      });
    const legsDataKeys: string[] | [] = !isEmpty(sortLegdata) ? Object.keys(sortLegdata) : [];

    return legsDataKeys.map((item: string) => (
      <ObstacleAnalysisSwitch
        key={item}
        isCheckedOALegSwitch={oaLegCheckedKey === item}
        currentLegData={legdata[item]}
        data={item}
        isShowHideObstacleLayer={isShowHideObstacleLayer}
        isShowHideTerrainLayer={isShowHideTerrainLayer}
        handleChangeOALegSwitch={handleChangeOALegSwitch}
        handleShowHideObstacleLayer={handleShowHideObstacleLayer}
        handleShowHideTerrainLayer={handleShowHideTerrainLayer}
        legOAData={legOAData}
      />
    ));
  }, [
    legTabItems,
    oaLegCheckedKey,
    activeLeg,
    legOAData,
    isShowHideObstacleLayer,
    isShowHideTerrainLayer
  ]);

  const acftCat: OptionsType | undefined = find(aircraftCategoryOptions, [
    'value',
    selectedDesign?.acft_cat
  ]);

  const departureLegTab = useMemo(() => {
    const isTerminate = designProcedureVersionsData && designProcedureVersionsData[0].is_terminal;
    return (
      activeDeptBtn === 'DEPARTURE' && (
        <Tabs
          type="editable-card"
          onChange={handleChangeLeg}
          activeKey={activeLeg}
          onEdit={handleAddLeg}
          items={legTabItems}
          hideAdd={
            isEmpty(legsDataDeparture)
              ? true
              : isTerminate
              ? true
              : !legsDataDeparture[`Leg${legTabItems[legTabItems.length - 1]?.key}`]
          }
          popupClassName="leg-drop-down"
        />
      )
    );
  }, [activeDeptBtn, activeLegDesignDataDeparture, legTabItems, activeMapElement]);

  return (
    <div className="design-main-sidebar">
      <div className="data-started-sid">
        <Text>
          {selectedDesign ? selectedDesign.name : '-'}{' '}
          {selectedProcedureData ? `[${selectedProcedureData.type}]` : ''}{' '}
          {selectedProcedureData && selectedProcedureData.procedure
            ? `[${selectedProcedureData.procedure}]`
            : ''}
        </Text>
        <Text>
          Design started on : {date}; {time}
        </Text>
      </div>
      <div
        className={
          currentDesignStep === 1 || currentDesignStep === 2 || currentDesignStep === 3
            ? 'data-design data-started-design data-leg-started-design'
            : 'data-design data-started-design'
        }
      >
        <Steps
          current={currentDesignStep}
          onChange={handleonChangeDesignStep}
          className="started-design-step"
          items={[
            {
              title: '',
              status: currentDesignStep === 0 ? 'process' : 'wait',
              className:
                currentDesignStep === 0
                  ? 'current-design-step'
                  : currentDesignStep > 0
                  ? 'finished-step'
                  : ''
            },
            {
              title: '',
              status: currentDesignStep === 1 ? 'process' : 'wait',
              className:
                currentDesignStep === 1
                  ? 'current-design-step'
                  : currentDesignStep > 1
                  ? 'finished-step'
                  : ''
            },
            {
              title: '',
              status: currentDesignStep === 2 ? 'process' : 'wait',
              className:
                currentDesignStep === 2
                  ? 'current-design-step'
                  : currentDesignStep > 2
                  ? 'finished-step'
                  : ''
            },
            {
              title: '',
              status: currentDesignStep === 3 ? 'process' : 'wait',
              className:
                currentDesignStep === 3
                  ? 'current-design-step'
                  : currentDesignStep > 3
                  ? 'finished-step'
                  : ''
            }
          ]}
        />
        {currentDesignStep === 0 && (
          <div className="data-design-content">
            <div className="departure-content">
              <div>
                <div>Departure Procedure</div>
                <Button.Group className="dep-btn-grp">
                  <Button
                    disabled={Boolean(
                      selectedProcedureData && selectedProcedureData.procedure === 'OMNI'
                    )}
                    className={activeDeptBtn === 'DEPARTURE' ? 'dep-active-btn' : ''}
                    onClick={() => setActiveDeptBtn('DEPARTURE')}
                  >
                    Departure
                  </Button>
                  <Button
                    disabled={Boolean(
                      selectedProcedureData && selectedProcedureData.procedure === 'DEPARTURE'
                    )}
                    className={activeDeptBtn === 'OMNI' ? 'omni-active-btn' : ''}
                    onClick={() => setActiveDeptBtn('OMNI')}
                  >
                    Omni
                  </Button>
                </Button.Group>
              </div>
              <div className="designator-content">
                <div>Designator</div>
                <div style={{ paddingRight: 5 }}>
                  RWY
                  {selectedDesign && selectedDesign.runwaydirection
                    ? selectedDesign.runwaydirection
                    : ''}
                </div>
              </div>
              <div className="designator-content">
                <div>Name of Procedure</div>
                <div style={{ paddingRight: 5 }}>
                  {selectedDesign && selectedDesign.name ? selectedDesign.name : '-'}
                </div>
              </div>
            </div>
            <Divider
              style={{
                borderBlockStart: '0.5px solid #C7C7CC'
              }}
            />
            <div className="departure-content">
              <div className="designator-content">
                <div>Aerodrome</div>
                <div style={{ paddingRight: 5 }}>
                  {selectedDesign && selectedDesign.airport_name
                    ? selectedDesign.airport_name
                    : '-'}
                </div>
              </div>
              {activeDeptBtn === 'DEPARTURE' && (
                <>
                  <div className="designator-content">
                    <div>Nav. Specification</div>
                    <div style={{ paddingRight: 5 }}>
                      {selectedDesign && selectedDesign.nav_type ? selectedDesign.nav_type : '-'}
                    </div>
                  </div>
                  <div className="designator-content">
                    <div>Nav. Sensors</div>
                    <div style={{ paddingRight: 5 }}>
                      {selectedDesign && selectedDesign.nav_spec ? selectedDesign.nav_spec : '-'}
                    </div>
                  </div>
                </>
              )}
              <div className="designator-content">
                <div>ACFT Cat</div>
                <div style={{ paddingRight: 5 }}>{acftCat ? acftCat.label : '-'}</div>
              </div>
            </div>
            <Divider
              style={{
                borderBlockStart: '0.5px solid #C7C7CC'
              }}
            />
            <div>
              <div>Description</div>
              <TextArea
                disabled={!isDesigner}
                style={{ marginTop: 8, color: `${primary}`, minHeight: 72 }}
                title="Description"
                placeholder="Type here"
                value={
                  selectedProcedureData && selectedProcedureData.description
                    ? description
                    : undefined
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                  setDescription(e.target.value)
                }
              />
            </div>
          </div>
        )}
        {currentDesignStep === 1 && (
          <>
            {/* {activeDeptBtn === 'DEPARTURE' && (
              <div className="rsid-main-content">
                <div className="rsdi-select-main">
                  <div>RSID11</div>
                  <div>
                    <Select
                      style={{ width: 136 }}
                      suffixIcon={<DownArrow />}
                      onChange={handleChangeRSIDType}
                      allowClear
                      placeholder="Type"
                      disabled
                      options={rsidOptions}
                    />
                    <Select
                      style={{ width: 136 }}
                      suffixIcon={<DownArrow />}
                      onChange={handleChangeRSIDRunway}
                      placeholder="Runway"
                      allowClear
                      disabled
                      options={[]}
                    />
                  </div>
                </div>
              </div>
            )} */}
            <div className="leg-tabs">
              {departureLegTab}
              {activeDeptBtn === 'OMNI' && (
                <Tabs
                  type="editable-card"
                  onEdit={handleAddLeg}
                  activeKey={activeLeg}
                  items={legTabItems}
                  hideAdd
                />
              )}
            </div>
            {activeDeptBtn === 'DEPARTURE' && legData}
            {activeDeptBtn === 'OMNI' && omniLegData}
          </>
        )}
        {currentDesignStep === 2 && (
          <>
            <div className="rsid-coding-content">
              <div className="rsid-btn-main">
                <div style={{ color: primary, fontSize: 16 }}>CODING TABLE</div>
                <div>
                  {/* <Text>Coding Table</Text> */}
                  <Button.Group className="coding-btn-grp">
                    <Button
                      className={
                        !isactiveCodingTable
                          ? 'coding-btn off-coding-table'
                          : 'coding-btn on-coding-btn'
                      }
                      onClick={() => handleCodingTable(false)}
                    >
                      OFF
                    </Button>
                    <Button
                      className={isactiveCodingTable ? 'coding-btn on-coding-table' : 'coding-btn'}
                      onClick={() => handleCodingTable(true)}
                    >
                      ON
                    </Button>
                  </Button.Group>
                </div>
              </div>
            </div>
            <Divider className="pa-divider" />
            <div className="pa-generated-content">
              <Text>Protection Area Generated</Text>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Text className="show-content">Show all </Text>
                <Switch
                  className="pa-gen-switch"
                  disabled={
                    selectedProcedureData && selectedProcedureData.procedure === 'OMNI'
                      ? Boolean(!legsDataOMNI)
                      : Boolean(!legsDataDeparture)
                  }
                  onChange={handleShowAllProtectionArea}
                  checked={isCheckedPASwitch}
                />
              </div>
            </div>
            <Divider className="pa-leg-divider" />
            <div className="leg-data-main">{paLegSwitch}</div>
          </>
        )}
        {currentDesignStep === 3 && (
          <>
            <div className="oa-generated">Obstacle & Terrain Analysis Generated</div>
            <Divider className="oa-gen-divider" />
            <div className="oa-list-div">{oaLegSwitch}</div>
          </>
        )}
      </div>
      <div className="start-design-div started-design">
        <Button
          type="link"
          className="back-design-btn"
          onClick={() => handleBackToDesign()}
          icon={<ArrowleftIconV1 />}
        >
          Procedures
        </Button>
        {currentDesignStep === 3 ? (
          <Button
            type="primary"
            disabled={
              !isDesigner ||
              selectedProcedureData?.status === 'completed' ||
              !designProcedureVersionsData[0].is_terminal
            }
            loading={isCompleteProcedureLoading}
            onClick={handleProcedureComplete}
            style={{ width: 159 }}
          >
            Complete
          </Button>
        ) : (
          <Button type="primary" onClick={handleDesignContinue} style={{ width: 120 }}>
            Next
            <ArrowRightIconV1 height={20} width={20} color={white} />
          </Button>
        )}
      </div>
      <DesignProcedureModal
        procedureType={activeDeptBtn}
        isOpenProcedureModal={isShowHideProcedureModal}
        handleProceedDeparture={handleProceedDeparture}
        handleCancelDeparture={handleCancelDeparture}
        isLoading={btnLoading}
      />
    </div>
  );
};

export default DesignStartedSidebar;

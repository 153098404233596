import { IconProps } from 'types/common.types';

const ViewTimelineIcon = ({ height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.6001 7.19985C7.6001 7.6242 7.76867 8.03117 8.06873 8.33122C8.36878 8.63128 8.77575 8.79985 9.2001 8.79985C9.62444 8.79985 10.0314 8.63128 10.3315 8.33122C10.6315 8.03117 10.8001 7.6242 10.8001 7.19985C10.8001 6.77551 10.6315 6.36854 10.3315 6.06848C10.0314 5.76842 9.62444 5.59985 9.2001 5.59985C8.77575 5.59985 8.36878 5.76842 8.06873 6.06848C7.76867 6.36854 7.6001 6.77551 7.6001 7.19985ZM14.8001 11.9999C14.8001 12.4242 14.9687 12.8312 15.2687 13.1312C15.5688 13.4313 15.9758 13.5999 16.4001 13.5999C16.8244 13.5999 17.2314 13.4313 17.5315 13.1312C17.8315 12.8312 18.0001 12.4242 18.0001 11.9999C18.0001 11.5755 17.8315 11.1685 17.5315 10.8685C17.2314 10.5684 16.8244 10.3999 16.4001 10.3999C15.9758 10.3999 15.5688 10.5684 15.2687 10.8685C14.9687 11.1685 14.8001 11.5755 14.8001 11.9999ZM7.6001 16.7999C7.6001 17.2242 7.76867 17.6312 8.06873 17.9312C8.36878 18.2313 8.77575 18.3999 9.2001 18.3999C9.62444 18.3999 10.0314 18.2313 10.3315 17.9312C10.6315 17.6312 10.8001 17.2242 10.8001 16.7999C10.8001 16.3755 10.6315 15.9685 10.3315 15.6685C10.0314 15.3684 9.62444 15.1999 9.2001 15.1999C8.77575 15.1999 8.36878 15.3684 8.06873 15.6685C7.76867 15.9685 7.6001 16.3755 7.6001 16.7999Z"
        stroke="#6E3BD1"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M4 16.8H7.6M10.8 16.8H20M4 7.19995H7.6M10.8 7.19995H20"
        stroke="#6E3BD1"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M4 4V20"
        stroke="#6E3BD1"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M4 12H14.8M18 12H20" stroke="#6E3BD1" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
};
ViewTimelineIcon.defaultProps = {
  width: 24,
  height: 24,
  onClick: () => {}
};

export default ViewTimelineIcon;

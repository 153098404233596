import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isEmpty, omit } from 'lodash';
import { Button, Form, Input, Typography } from 'antd';
import SatsureIcon from 'assets/svg/SatsureIcon';
import SatsureSkieswhite from 'assets/svg/SatsureSkieswhite';
import { routes } from 'constants/pageRoutes.constants';
import { ACCESS_TOKEN, AES_KEY, REFRESH_TOKEN, USER_ROLE } from 'constants/common.constant';
import RequiredTitle from 'components/core/RequiredTitle';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { useLoginMutation } from 'services/apis/userApi';
import { clearCookies, setStorageValue } from 'services/axiosClient';
import { enqueueError } from 'store/alert';
import { setAccessToken, setRefreshToken, setRSA, setUser } from 'store/users';
import { UserRequest, UserResponse, UserState } from 'store/users/types';
import { getRSA } from 'store/users/actions';
import { loginSync } from 'utils/yupsync';
import { decryptRsa, rsaEnc } from 'utils/decodeEncodeData';
import './styles.less';

const { Text } = Typography;
const { Title } = Typography;

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { rsaKey }: UserState = useAppSelector((state) => state.user);
  const [login, { data: loginData, isLoading: loginLoading }] = useLoginMutation();

  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(
      getRSA((data: any) => {
        if (data.status) {
          dispatch(setRSA(data.data.rsa));
        } else {
          dispatch(enqueueError('RSA key not exist!'));
        }
      })
    );
  }, []);

  const handleLogin = async (values: UserRequest) => {
    if (rsaKey) {
      login({
        email: rsaEnc(values.email, rsaKey),
        password: rsaEnc(values.password, rsaKey)
      });
    } else {
      dispatch(
        getRSA((data: any) => {
          if (data.status) {
            login({
              email: rsaEnc(values.email, data.data.rsa),
              password: rsaEnc(values.password, data.data.rsa)
            });
            dispatch(setRSA(data.data.rsa));
          } else {
            dispatch(enqueueError('RSA key not exist!'));
          }
        })
      );
    }
  };

  useEffect(() => {
    if (loginData) {
      const loginResponse: { data: UserResponse; status: boolean } | any = loginData;
      if (loginResponse.status) {
        if (isEmpty(loginResponse.data)) return;
        clearCookies();
        decryptRsa(loginResponse.data.accessToken)
          .then((aesKey: any) => {
            dispatch(setUser(omit(loginResponse.data, ['accessToken', 'refreshToken'])));
            dispatch(setAccessToken(loginResponse.data.accessToken));
            dispatch(setRefreshToken(loginResponse.data.refreshToken));
            setStorageValue(AES_KEY, aesKey);
            setStorageValue(ACCESS_TOKEN, loginResponse.data.accessToken);
            setStorageValue(REFRESH_TOKEN, loginResponse.data.refreshToken);
            setStorageValue(USER_ROLE, loginResponse.data.role);
          })
          .then(() => {
            if (loginResponse.data.firstname && loginResponse.data.lastname) {
              navigate(routes.Home, { replace: true });
            } else {
              navigate(routes.UserDetail, { replace: true });
            }
          });
      }
    }
  }, [loginData]);

  return (
    <div className="main-login-page">
      <div className="satsure-skies-logo">
        <SatsureSkieswhite />
      </div>
      <div className="login-content">
        <div>
          <div className="welcome-label">
            <Title>Welcome to</Title>
            <Title> Cognitive Flight Navigation Procedure Design (CFNPD)</Title>
          </div>
          <Form
            form={form}
            layout="vertical"
            autoComplete="off"
            className="login-form"
            onFinish={handleLogin}
          >
            <RequiredTitle title="Email" className="ann-form-label" isRequired />
            <Form.Item name="email" className="email-input" rules={[loginSync]}>
              <Input placeholder="Enter your mail address" className="form-input" />
            </Form.Item>
            <RequiredTitle title="Password" className="ann-form-label" isRequired />
            <Form.Item name="password" className="password-input" rules={[loginSync]}>
              <Input.Password placeholder="Enter your password" className="form-input" />
            </Form.Item>
            {/* <div className="forgot-password">
            <Button type="link"  onClick={() => navigate(routes.ForgotPass)}>
              Forgot Password ?
            </Button>
          </div> */}
            <Button
              loading={loginLoading}
              className="login-btn"
              type="primary"
              htmlType="submit"
              block
              size="large"
            >
              Log In
            </Button>
          </Form>
        </div>
        <div className="satsure-logo">
          <Text>Powered By</Text>
          <SatsureIcon />
        </div>
      </div>
    </div>
  );
};

export default Login;

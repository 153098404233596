import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AiOutlineSearch } from 'react-icons/ai';
import { Button, Input, Select } from 'antd';
import MapIcon from 'assets/svg/MapIcon';
import ListIcon from 'assets/svg/ListIcon';
import { black, secondary, white } from 'constants/color.constant';
import { routes } from 'constants/pageRoutes.constants';
import { useAppSelector } from 'hooks/useReduxHook';
import { UserResponse, UserRole } from 'store/users/types';
import { OptionsType } from 'types/common.types';
import 'components/SubHeader/styles.less';

interface HomeSubHeaderProps {
  searchAirportLists: OptionsType[] | [];
  handleSearchAirportByName: (name: string | null) => void;
  handleSelectedMarkerData: (icao: string | null) => void;
  handleShowMarkerDetail: (icao: string) => void;
  selectedDesignData: string | null;
  isMapPage?: boolean;
  isListPage?: boolean;
  handleSearchAirport?: Function;
  filterText?: string;
}

const HomeSubHeader = ({
  selectedDesignData,
  searchAirportLists,
  handleSearchAirportByName,
  handleSelectedMarkerData,
  handleShowMarkerDetail,
  isMapPage,
  isListPage,
  handleSearchAirport,
  filterText
}: HomeSubHeaderProps) => {
  const navigate = useNavigate();
  const currentUser: UserResponse = useAppSelector((state) => state.user.userSession);

  const handleCreatenewDesign = () => {
    navigate(routes.CreateDesign);
  };

  const handleNavigateUserManagement = () => {
    navigate(routes.UserManagement);
  };

  const handleNavigateHomepage = () => {
    navigate(routes.Home);
  };

  const handleListView = () => {
    navigate(routes.DesignList);
  };

  const handleSearch = (name: string) => {
    handleSearchAirportByName(name);
  };

  const handleChange = (icao: string) => {
    handleSelectedMarkerData(icao || null);
    handleShowMarkerDetail(icao);
  };

  const handleClear = () => {
    handleSelectedMarkerData(null);
    handleSearchAirportByName(null);
  };

  return (
    <div className="home-sub-header">
      <div className="search-input-div home-search-div">
        {!isMapPage && (
          <Input
            placeholder="Search Airports"
            className="search-input"
            value={filterText || undefined}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              handleSearchAirport &&
              handleSearchAirport((e.target as HTMLInputElement).value || null)
            }
            prefix={<AiOutlineSearch />}
          />
        )}
        {isMapPage && (
          <Select
            showSearch
            value={selectedDesignData}
            allowClear
            placeholder="Search Airports"
            className="search-select-home"
            defaultActiveFirstOption={false}
            suffixIcon={<AiOutlineSearch />}
            filterOption={false}
            onSearch={handleSearch}
            onChange={handleChange}
            onClear={handleClear}
            notFoundContent={
              <div style={{ textAlign: 'center', margin: '10px 0' }}>Enter Airport Name</div>
            }
            options={searchAirportLists}
          />
        )}
      </div>
      <div className="create-list-div home-btns-div">
        {currentUser?.role === UserRole.Admin && (
          <Button type="primary" onClick={handleNavigateUserManagement}>
            User Management
          </Button>
        )}
        {currentUser?.role === UserRole.Manager && (
          <Button type="primary" onClick={() => handleCreatenewDesign()}>
            Create New Design
          </Button>
        )}
        <Button.Group className="list-map-grp-btn">
          <Button
            className={isMapPage ? 'active-view-btn' : ''}
            icon={<MapIcon color={isMapPage ? white : black} />}
            onClick={handleNavigateHomepage}
          />
          <Button
            className={isListPage ? 'active-view-btn' : ''}
            icon={<ListIcon color={isListPage ? white : secondary} />}
            onClick={handleListView}
          />
        </Button.Group>
      </div>
    </div>
  );
};

HomeSubHeader.defaultProps = {
  isMapPage: false,
  isListPage: false,
  handleSearchAirport: () => {},
  filterText: ''
};

export default HomeSubHeader;

import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { Col, Layout, Row } from 'antd';
import { Content } from 'antd/es/layout/layout';
import ArrowleftIconV1 from 'assets/svg/ArrowleftIconV1';
import { TimezoneContext } from 'context/createusecontext';
import { DateFormat2, TimeFormat } from 'constants/common.constant';
import { primary } from 'constants/color.constant';
import PreviewAeroReport from 'components/AeroReportContainer/PreviewAeroReport';
import Section0 from 'components/AeroReportContainer/Section0';
import Section1 from 'components/AeroReportContainer/Section1';
import Section2 from 'components/AeroReportContainer/Section2';
import Section3 from 'components/AeroReportContainer/Section3';
import Section4 from 'components/AeroReportContainer/Section4';
import Section5 from 'components/AeroReportContainer/Section5';
import Section6 from 'components/AeroReportContainer/Section6';
import Section7 from 'components/AeroReportContainer/Section7';
import PreviewSection8 from 'components/AeroReportContainer/PreviewSection8';
import Section9 from 'components/AeroReportContainer/Section9';
import Section10 from 'components/AeroReportContainer/Section10';
import Section11 from 'components/AeroReportContainer/Section11';
import FullScreenLoader from 'components/core/FullScreenLoader';
import Section12 from 'components/AeroReportContainer/Section12';
import Section13 from 'components/AeroReportContainer/Section13';
import PageHeader from 'components/PageHeader/PageHeader';
import AeroReportSidebar from 'components/Sidebar/AeroReportSidebar';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import {
  useGetAeroReportQuery,
  useGetDesignQuery,
  useSaveAeroReportDetailsMutation
} from 'services/apis/designsApi';
import { setSelectedDesign } from 'store/design';
import { CodingTableDataType, DesignState, Sec10 } from 'store/design/types';
import { UserState } from 'store/users/types';
import './styles.less';

export interface ObstacleDataType {
  key: React.Key;
  obstacle: string;
  source: string;
  vertical: string | number | null;
  horizontal: string | number | null;
  remarks: string | null;
}

const AeroReport = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { selectedDesign }: DesignState = useAppSelector((state) => state.design);
  const { userSession }: UserState = useAppSelector((state) => state.user);
  const [scrollId, setScrollId] = useState('#datasource');
  const { designid }: any = useParams();
  const [magneticDeclination, setMagneticDeclination] = useState<number | null>();
  const [isPreviewReport, setShowPreviewReport] = useState<boolean>(false);
  const [isPdfLoading, setPdfLoading] = useState<boolean>(false);
  const [sec1, setSec1] = useState<any>(null);
  const [sec2, setSec2] = useState<any>(null);
  const [sec3, setSec3] = useState<any>(null);
  const [sec4, setSec4] = useState<any>(null);
  const [sec5, setSec5] = useState<any>(null);
  const [sec6, setSec6] = useState<any>(null);
  const [sec7, setSec7] = useState<any>(null);
  const [sec8, setSec8] = useState<any>(null);
  const [sec9, setSec9] = useState<any>(null);
  const [sec10, setSec10] = useState<Sec10[] | []>([]);
  const [sec11, setSec11] = useState<any>(null);
  const [sec12, setSec12] = useState<{ [key: string]: CodingTableDataType[] | [] } | null>(null);
  const [sec13, setSec13] = useState<any>([]);

  const scrollElementRef: any = useRef();
  const scrollToComponent = (id: string) => {
    if (window.location.hash === id) {
      scrollElementRef.current.scrollTo(0, 1000);
      scrollElementRef.current.focus();
    }
  };

  useEffect(() => scrollToComponent(scrollId), [scrollId]);

  const [saveAeroReportDetails, { isLoading: isLoadingSaveReportData }] =
    useSaveAeroReportDetailsMutation();

  const { data: designData } = useGetDesignQuery(
    { orgId: userSession?.organisationid, designId: designid },
    {
      refetchOnMountOrArgChange: true,
      skip: !(Boolean(userSession?.organisationid) && Boolean(designid))
    }
  );

  const { data: aeroReportData, refetch: refetchAeroReportData } = useGetAeroReportQuery(
    { designId: designid },
    {
      refetchOnMountOrArgChange: true,
      skip: !designid
    }
  );

  const timeZone = useContext(TimezoneContext);
  const date: any =
    selectedDesign && selectedDesign.created_at
      ? moment(selectedDesign.created_at).format(DateFormat2)
      : '-';
  const time: any =
    selectedDesign && selectedDesign.created_at
      ? `${moment(selectedDesign.created_at).format(TimeFormat)} ${timeZone?.timezone}`
      : '';

  // get design data handling
  useEffect(() => {
    if (designData) {
      const designDataRes: any = designData;
      if (designDataRes.status) {
        dispatch(setSelectedDesign(!isEmpty(designDataRes.data) ? designDataRes.data : null));
      }
    }
  }, [designData]);

  // get aero Report data handling
  useEffect(() => {
    if (aeroReportData) {
      const aeroReportDataRes: any = aeroReportData;
      if (aeroReportDataRes.status) {
        if (!isEmpty(aeroReportDataRes.data)) {
          setMagneticDeclination(aeroReportDataRes.data.magnetic_declination || null);
          setSec1(aeroReportDataRes.data.sec_1 || null);
          setSec2(aeroReportDataRes.data.sec_2 || null);
          setSec3(aeroReportDataRes.data.sec_3 || null);
          setSec4(aeroReportDataRes.data.sec_4 || null);
          setSec5(aeroReportDataRes.data.sec_5 || null);
          setSec6(aeroReportDataRes.data.sec_6 || null);
          setSec7(aeroReportDataRes.data.sec_7 || null);
          setSec8(aeroReportDataRes.data.sec_8 || null);
          setSec9(aeroReportDataRes.data.sec_9 || null);
          setSec10(aeroReportDataRes.data.sec_10 || []);
          setSec11(aeroReportDataRes.data.sec_11 || null);
          setSec12(aeroReportDataRes.data.sec_12 || []);
          setSec13(aeroReportDataRes.data.sec_13 || []);
        }
      }
    }
  }, [aeroReportData]);

  useEffect(() => {
    if (!isLoadingSaveReportData) {
      refetchAeroReportData();
    }
  }, [isLoadingSaveReportData]);

  const handleOnclickScrollId = (id: string) => {
    setScrollId(id);
  };

  const handleCallSaveAeroReportDataApi = async (payload: { section_key: string; fields: any }) => {
    if (isEmpty(designid)) return;
    if (!isEmpty(payload.fields)) await saveAeroReportDetails({ data: payload, designid });
  };

  return (
    <Layout hasSider>
      <Content className="main-content aero-report-main">
        <Row className="report-content-header">
          <Col span={11}>
            <PageHeader
              title="back to Design"
              onClick={() => navigate(`/design/${designid}`)}
              icon={<ArrowleftIconV1 color={primary} height={20} />}
            />
          </Col>
          <Col span={13}>Aero Report</Col>
        </Row>
        <div className="report-content-main">
          <div style={{ width: '100%' }}>
            <Section0 sec3={sec3} sec2={sec2} />
            <div>
              <Section1
                sec1={sec1}
                scrollElementRef={scrollElementRef}
                handleCallSaveAeroReportDataApi={handleCallSaveAeroReportDataApi}
              />
              <Section2
                sec2={sec2}
                sec3={sec3}
                scrollElementRef={scrollElementRef}
                handleCallSaveAeroReportDataApi={handleCallSaveAeroReportDataApi}
              />
              <Section3
                sec3={sec3}
                scrollElementRef={scrollElementRef}
                magneticDeclination={magneticDeclination}
                handleCallSaveAeroReportDataApi={handleCallSaveAeroReportDataApi}
              />
              <Section4
                sec4={sec4}
                scrollElementRef={scrollElementRef}
                handleCallSaveAeroReportDataApi={handleCallSaveAeroReportDataApi}
              />
              <Section5
                sec5={sec5}
                magVar={sec3 ? sec3?.aero_mag_var : ' '}
                scrollElementRef={scrollElementRef}
                handleCallSaveAeroReportDataApi={handleCallSaveAeroReportDataApi}
              />
              <Section6
                sec6={sec6}
                scrollElementRef={scrollElementRef}
                handleCallSaveAeroReportDataApi={handleCallSaveAeroReportDataApi}
              />
              <Section7
                scrollElementRef={scrollElementRef}
                sec7={sec7}
                handleCallSaveAeroReportDataApi={handleCallSaveAeroReportDataApi}
              />
              <PreviewSection8 scrollElementRef={scrollElementRef} sec8={sec8} />
              <Section9 scrollElementRef={scrollElementRef} sec9={sec9} />
              <Section10 scrollElementRef={scrollElementRef} sec10={sec10} />
              <Section11 scrollElementRef={scrollElementRef} sec11={sec11} />
              <Section12 scrollElementRef={scrollElementRef} sec12={sec12} />
              <Section13 scrollElementRef={scrollElementRef} sec13={sec13} />
            </div>
          </div>
        </div>
      </Content>
      <AeroReportSidebar
        date={date}
        time={time}
        handleOnclickScrollId={handleOnclickScrollId}
        handleHideShowPreviewReport={() => setShowPreviewReport(true)}
      />
      <PreviewAeroReport
        isOpen={isPreviewReport}
        sec1={sec1}
        sec2={sec2}
        sec3={sec3}
        sec4={sec4}
        sec5={sec5}
        sec6={sec6}
        sec7={sec7}
        sec8={sec8}
        sec9={sec9}
        sec10={sec10}
        sec11={sec11}
        sec12={sec12}
        sec13={sec13}
        magneticDeclination={magneticDeclination}
        onClose={() => setShowPreviewReport(false)}
        handlePdfLoading={(isLoad: boolean) => setPdfLoading(isLoad)}
      />
      <FullScreenLoader
        loading={isPdfLoading || isLoadingSaveReportData}
        isShowText={false}
        color={primary}
      />
    </Layout>
  );
};

export default AeroReport;

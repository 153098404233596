import React from 'react';
import { store } from 'store';
import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import 'antd/dist/reset.css';
import { ConfigProvider } from 'antd';
import reportWebVitals from './reportWebVitals';
import 'fonts/ttf/ManropeRegular.ttf';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'styles/leaflet.linear-measurement.css';
import App from './App';
import { primary } from 'constants/color.constant';
import AlertComponent from 'components/AlertComponent';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://aeb653942da3af02024bf3443ec7dd64@o4506252999000064.ingest.sentry.io/4506275535257600',
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        // tracePropagationTargets: ['localhost', /^https:\/\/cfnpd.satsure\.co\]
      }),
      new Sentry.Replay()
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

root.render(
  <Provider store={store}>
    <ConfigProvider
      theme={{
        token: {
          fontFamily: 'Manrope',
          colorPrimary: primary,
          borderRadius: 4
        }
      }}
    >
      <App />
      <AlertComponent />
    </ConfigProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { primary } from 'constants/color.constant';
import { IconProps } from 'types/common.types';

const AirspaceIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      onClick={onClick}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="8.5" stroke={color} />
      <circle cx="12" cy="12" r="5.5" stroke={color} />
      <circle cx="12" cy="12" r="2.5" stroke={color} />
      <circle cx="12" cy="12" r="11.5" stroke={color} />
    </svg>
  );
};
AirspaceIcon.defaultProps = {
  color: primary,
  width: 24,
  height: 24,
  onClick: () => {}
};

export default AirspaceIcon;

import { FocusEvent } from 'react';
import { isEmpty } from 'lodash';
import { InputNumber, Space, Typography } from 'antd';
import { secondary } from 'constants/color.constant';
import RequiredTitle from 'components/core/RequiredTitle';
import { useAppDispatch } from 'hooks/useReduxHook';
import { enqueueError } from 'store/alert';
import { TemperatureType } from 'store/design/types';

const { Text } = Typography;

interface CollectTemperatureDataTabProps {
  designTemperature: TemperatureType | null;
  tmin: number | string | null;
  tmax: number | string | null;
  handleChangeTmin: (min: number | string | null) => void;
  handleChangeTmax: (max: number | string | null) => void;
}

const CollectTemperatureDataTab = ({
  designTemperature,
  tmin,
  tmax,
  handleChangeTmin,
  handleChangeTmax
}: CollectTemperatureDataTabProps) => {
  const dispatch = useAppDispatch();

  const handleOnBlurTmin = (e: FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value > tmax!) {
      dispatch(enqueueError('Temperature minimum value should not greater than maximum value!'));
      return;
    }
    handleChangeTmin(value);
  };

  const handleOnBlurTmax = (e: FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value < tmin!) {
      dispatch(enqueueError('Temperature maximum value should not less than minimum value!'));
      return;
    }
    handleChangeTmax(value);
  };

  return (
    <Space direction="vertical">
      <Space size={[32, 32]} className="temperature-content" wrap>
        <div className="temperature-inr-content">
          <RequiredTitle
            title="Temperature Minimum"
            style={{ fontSize: 14, fontWeight: 500, color: secondary }}
          />
          <InputNumber
            suffix="&deg;C"
            min={-99}
            max={(!isEmpty(tmax) && Number(tmax)) || 99}
            placeholder="tmin"
            disabled={!isEmpty(designTemperature)}
            onBlur={handleOnBlurTmin}
            value={tmin}
          />
        </div>
        <div className="temperature-inr-content">
          <RequiredTitle
            title="Temperature Maximum"
            style={{ fontSize: 14, fontWeight: 500, color: secondary }}
          />
          <InputNumber
            value={tmax}
            suffix="&deg;C"
            max={99}
            min={!isEmpty(tmin) ? Number(tmin) : undefined}
            disabled={!tmin || !isEmpty(designTemperature)}
            placeholder="tmax"
            onBlur={handleOnBlurTmax}
          />
        </div>
      </Space>
      <Space>
        <Text className="temperature-inr-data">
          The minimum and maximum temperature data is derived using Indian Meteorological Department
          (IMD) Gridded Temperature data at 1.0°x1.0° resolution. The minimum and maximum
          temperature is an average of all corresponding values of year range 2018-2022 at ARP
          location
        </Text>
      </Space>
    </Space>
  );
};

export default CollectTemperatureDataTab;

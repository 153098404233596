import { useEffect, useRef } from 'react';
import L from 'leaflet';
import { useMap } from 'react-leaflet';
import { isEmpty } from 'lodash';
import '../../scripts/polyline-decorator/L.PolylineDecorator';

interface PolylineArrowDecoratorProps {
  coordinates: any;
  decoratorIcon: any;
}

const PolylineArrowDecorator = ({ coordinates, decoratorIcon }: PolylineArrowDecoratorProps) => {
  const map = useMap();
  const mapref: any = useRef(null);

  useEffect(() => {
    if (isEmpty(coordinates)) return;

    // @ts-ignore
    mapref.current = L.polylineDecorator(coordinates, {
      patterns: [
        {
          offset: '50%',
          // repeat: '33%',
          // @ts-ignore
          symbol: L.Symbol.marker({
            rotate: true,
            pane: 'tooltipPane',
            markerOptions: {
              icon: decoratorIcon
            }
          })
        }
      ]
    }).addTo(map);

    // eslint-disable-next-line
    return () => {
      mapref.current.remove(map);
    };
  }, [map, coordinates, mapref]);
  return null;
};

export default PolylineArrowDecorator;

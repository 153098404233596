import { secondary } from 'constants/color.constant';
import { IconProps } from 'types/common.types';

const MinusIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Zoom out">
        <path
          d="M0 0H24V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V0Z"
          fill={color}
        />
        <path
          id="Vector 32"
          d="M8 11H16.2286"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
MinusIcon.defaultProps = {
  color: secondary,
  width: 24,
  height: 24,
  onClick: () => {}
};

export default MinusIcon;

import { Marker, Popup } from 'react-leaflet';
import { isEmpty } from 'lodash';
import { Space, Typography } from 'antd';
import { mapDrawType } from 'constants/collectData.constant';
import { LightLocation } from 'constants/map.constants';
import { getNavaidIcon } from 'utils/utils';

const { Text } = Typography;

interface RenderNavaidsAirspaceProps {
  navaidAirspaceData: any;
}

const RenderNavaidsAirspace = ({ navaidAirspaceData }: RenderNavaidsAirspaceProps) => {
  if (navaidAirspaceData && isEmpty(navaidAirspaceData?.features)) return null;

  return (
    <>
      {navaidAirspaceData.features.map((data: any, i: any) => {
        const coords: any = data.geometry.coordinates;
        return (
          data.geometry.type === mapDrawType.point && (
            <Marker
              key={i}
              position={[coords[1], coords[0]]}
              icon={!isEmpty(data.properties) ? getNavaidIcon(data.properties.type) : LightLocation}
            >
              {!isEmpty(data.properties) && (
                <Popup className="point-popup" offset={[0, 0]} minWidth={232} maxHeight={226}>
                  <Space direction="vertical">
                    {Object.keys(data.properties).map((name: any, index: number) => (
                      <div key={`${index + 1}-${name}-${data.properties[name]}`}>
                        <Text className="market-title">{name}</Text>
                        <Text className="market-data">{data.properties[name]}</Text>
                      </div>
                    ))}
                  </Space>
                </Popup>
              )}
            </Marker>
          )
        );
      })}
    </>
  );
};

export default RenderNavaidsAirspace;

import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';

interface CalculatePDGTableProps {
  pdgData: any;
  finalRow: any[];
}

const CalculatePDGTable = ({ pdgData, finalRow }: CalculatePDGTableProps) => {
  const altColumns: ColumnsType<any> = [
    {
      title: 'CALCULATION  OF REQUIRED PDG (IN CASE OF OIS INFRINGEMENT)',
      align: 'left',
      key: 'title',
      className: 'table-col-data table-tol-col-data',
      children: [
        {
          title: 'No.',
          dataIndex: 'no',
          key: 'no',
          align: 'center',
          className: 'table-col-data',
          render: (value, record, index) => index + 1
        },
        {
          title: 'Do (m)',
          key: 'do',
          align: 'center',
          className: 'table-col-data',
          render: (value, record) => (record[0] ? record[0].toFixed(1) : '')
        },
        {
          title: 'Obst Elev + Tol (m)',
          key: 'obstele',
          align: 'center',
          className: 'table-col-data',
          render: (value, record) => (record[1] ? record[1].toFixed(1) : '')
        },
        {
          title: 'MOC (m)',
          key: 'moc',
          align: 'center',
          className: 'table-col-data',
          render: (value, record) => (record[2] ? record[2].toFixed(1) : '')
        },
        {
          title: 'RH over Obst + MOC (m)',
          key: 'rhoverobst',
          align: 'center',
          className: 'table-col-data',
          render: (value, record) => (record[3] ? record[3].toFixed(3) : '')
        },
        {
          title: 'PDG Rounded',
          key: 'pdground',
          align: 'center',
          className: 'table-col-data',
          render: (value, record) => (record[4] ? record[4].toFixed(1) : '')
        },
        {
          title: 'dPDG',
          key: 'dpdg',
          align: 'center',
          className: 'table-col-data',
          render: () => '-'
        }
      ]
    }
  ];

  return (
    <Table
      columns={altColumns}
      dataSource={pdgData}
      pagination={false}
      rowKey={(record) => `${record[1]}-${Math.floor(Math.random() * 100)}`}
      rowClassName={() => 'table-row-data table-tol-row-data'}
      // eslint-disable-next-line
      summary={() => {
        return (
          <Table.Summary.Row>
            <Table.Summary.Cell
              colSpan={5}
              index={0}
              align="left"
              className="table-row-data remark-row bd-right"
            >
              Proposed higher PDG
            </Table.Summary.Cell>
            <Table.Summary.Cell
              index={1}
              align="center"
              className="table-row-data remark-row remark-row-data"
            >
              {finalRow[0] ? finalRow[0] : '-'}
            </Table.Summary.Cell>
            <Table.Summary.Cell
              index={2}
              align="center"
              className="table-row-data remark-row remark-row-data"
            >
              {finalRow[1] ? finalRow[1].toFixed(2) : '-'}
            </Table.Summary.Cell>
          </Table.Summary.Row>
        );
      }}
    />
  );
};

export default CalculatePDGTable;

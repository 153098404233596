import { IconProps } from 'types/common.types';

const CollapseIconBtn = ({ height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9436 5.99934L12.6669 6C13.0349 6 13.3336 6.29867 13.3336 6.66734C13.3329 7.03534 13.0342 7.33334 12.6669 7.33334H12.6662L9.34958 7.33134C8.98291 7.33134 8.68491 7.03467 8.68291 6.668L8.66691 3.33667C8.66491 2.96867 8.96158 2.66867 9.33025 2.66667H9.33358C9.70025 2.66667 9.99825 2.96267 10.0002 3.33L10.0082 5.04867L12.1949 2.862C12.4556 2.60134 12.8776 2.60134 13.1382 2.862C13.3982 3.12267 13.3982 3.544 13.1382 3.80467L10.9436 5.99934ZM3.33625 8.66694L6.66758 8.6836C7.03425 8.6856 7.33091 8.98294 7.33091 9.3496L7.33358 12.6663C7.33358 13.0343 7.03491 13.3336 6.66691 13.3336C6.29825 13.3336 6.00025 13.0349 6.00025 12.6676L5.99891 10.9443L3.80491 13.1383C3.67425 13.2683 3.50358 13.3336 3.33358 13.3336C3.16291 13.3336 2.99225 13.2683 2.86225 13.1383C2.60158 12.8776 2.60158 12.4556 2.86225 12.1956L5.04825 10.0089L3.33025 10.0003C2.96158 9.99827 2.66491 9.6976 2.66691 9.33027C2.66825 8.96294 2.96691 8.66694 3.33358 8.66694H3.33625Z"
        fill="#42444A"
      />
    </svg>
  );
};
CollapseIconBtn.defaultProps = {
  width: 16,
  height: 16,
  onClick: () => {}
};

export default CollapseIconBtn;

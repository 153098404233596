import { primary } from 'constants/color.constant';
import { IconProps } from 'types/common.types';

const AircraftIconV1 = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      onClick={onClick}
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.87599 18.5185C7.93803 18.5185 7.93803 18.5185 8 18.5185L9.24029 19.6581C9.7364 20.114 10.4806 20.114 10.9767 19.6581C11.4729 19.2023 11.4728 18.5185 10.9767 18.0627L9.24029 16.4103L9.24029 9.23075L13.8914 13.5613C14.3876 14.0171 15.1317 14.0171 15.6279 13.5613C16.1241 13.1054 16.124 12.4217 15.6279 11.9658L9.24035 6.03987L9.24035 1.13959C9.24035 0.512822 8.68221 -3.19871e-07 8.00006 -3.49688e-07C7.31791 -3.79506e-07 6.75977 0.512822 6.75977 1.13959L6.75977 6.03987L0.372111 11.9658C0.12403 12.1937 1.66207e-05 12.4786 1.66082e-05 12.7635C1.65958e-05 13.0484 0.12403 13.3333 0.372111 13.5612C0.868214 14.017 1.6124 14.017 2.10856 13.5612L6.75972 9.23069L6.75972 16.4102L5.02326 18.0626C4.77518 18.2906 4.65117 18.5754 4.65117 18.8603C4.65117 19.1452 4.77518 19.4301 4.89925 19.658C5.39536 20.1138 6.13954 20.1138 6.6357 19.658L7.87599 18.5185Z"
        fill={color}
      />
    </svg>
  );
};
AircraftIconV1.defaultProps = {
  color: primary,
  width: 16,
  height: 20,
  onClick: () => {}
};

export default AircraftIconV1;

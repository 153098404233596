import { white } from 'constants/color.constant';
import { IconProps } from 'types/common.types';

const HexagonDotIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="2" fill={color} />
      <path
        d="M8.20142 7.42654L5.40142 11.4265C5.1604 11.7709 5.1604 12.2291 5.40142 12.5735L8.20142 16.5735C8.38855 16.8408 8.69434 17 9.02066 17H14.9793C15.3057 17 15.6114 16.8408 15.7986 16.5735L18.5986 12.5735C18.8396 12.2291 18.8396 11.7709 18.5986 11.4265L15.7986 7.42654C15.6114 7.15921 15.3057 7 14.9793 7H9.02066C8.69434 7 8.38855 7.15921 8.20142 7.42654Z"
        stroke={color}
        strokeLinejoin="round"
      />
    </svg>
  );
};
HexagonDotIcon.defaultProps = {
  color: white,
  width: 24,
  height: 24,
  onClick: () => {}
};

export default HexagonDotIcon;

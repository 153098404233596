import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { clearAlerts } from 'store/alert';
import AlertType from './AlertType';

const AlertComponent = () => {
  const dispatch = useAppDispatch();
  const errorQueue = useAppSelector((state) => state.alert.errorQueue);
  const successAlert = useAppSelector((state) => state.alert.successAlert);
  const warningAlert = useAppSelector((state) => state.alert.warningAlert);
  const infoAlert = useAppSelector((state) => state.alert.infoAlert);

  useEffect(() => {
    const timer: any = setTimeout(() => {
      if (
        !isEmpty(errorQueue) ||
        !isEmpty(successAlert) ||
        !isEmpty(warningAlert) ||
        !isEmpty(infoAlert)
      ) {
        dispatch(clearAlerts());
      }
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [errorQueue, successAlert, warningAlert, infoAlert]);

  return (
    <>
      {!isEmpty(errorQueue) && (
        <AlertType type="error" description={errorQueue[0]} message="Error" />
      )}
      {!isEmpty(successAlert) && (
        <AlertType type="success" description={successAlert} message="Success" />
      )}
      {!isEmpty(warningAlert) && (
        <AlertType type="warning" description={warningAlert} message="Warning" />
      )}
      {!isEmpty(infoAlert) && <AlertType type="info" description={infoAlert} message="Info" />}
    </>
  );
};

export default AlertComponent;

import { Polygon, Popup } from 'react-leaflet';
import { Space, Typography } from 'antd';
import { flatten, isEmpty, upperCase } from 'lodash';
import { lightGreen, primary } from 'constants/color.constant';

const { Text } = Typography;

const MultiPolygonComponent = ({
  index,
  coordinates,
  properties,
  color,
  fillColor,
  isLine,
  weight,
  fillOpacity,
  pane
}: any) => {
  const coordinateData = coordinates.map((points: any) => {
    return points.map((point: any) => {
      return point.map((p: any) => {
        return [Number(p[1]), Number(p[0])];
      });
    });
  });

  return (
    <Polygon
      key={index}
      // @ts-ignore
      positions={flatten(coordinateData)}
      pathOptions={{
        color,
        fillColor,
        fillOpacity,
        weight,
        dashArray: isLine ? [10, 10] : [0, 0]
      }}
      pane={pane}
    >
      {!isEmpty(properties) && (
        <Popup className="polygon-popup">
          <Space direction="vertical">
            {Object.keys(properties).map((key: any) => (
              <Space direction="vertical" key={key}>
                <Text className="tooltip-title" style={{ fontFamily: 'Manrope' }}>
                  {upperCase(key).replace(/[^a-zA-Z0-9 ]/g, ' ')}
                </Text>
                <Text className="tooltip-content" style={{ fontFamily: 'Manrope' }}>
                  {properties[key] || '-'}
                </Text>
              </Space>
            ))}
          </Space>
        </Popup>
        // ) : (
        //   <Popup className="polygon-popup">
        //     <Space style={{ display: 'flex', justifyContent: 'center' }}>
        //       <Text className="tooltip-content" style={{ fontFamily: 'Manrope' }}>
        //         No data found
        //       </Text>
        //     </Space>
        //   </Popup>
      )}
    </Polygon>
  );
};

MultiPolygonComponent.defaultProps = {
  color: primary,
  fillColor: lightGreen,
  isLine: false,
  fillOpacity: 0.3,
  weight: 1,
  pane: 'markerPane'
};

export default MultiPolygonComponent;

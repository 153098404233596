import { CollectDataType } from 'types/common.types';

export const ADDataLabel = {
  geoadministrative: 'geoadministrative',
  aixmobstacles: 'aixmobstacles',
  runwayphysicals: 'runwayphysicals',
  declareddistaces: 'declareddistaces',
  rwylights: 'rwylights',
  airspaceservice: 'airspaceservice',
  airtrafficservices: 'airtrafficservices',
  navaids: 'navaids'
};

export const airportDataCategories: CollectDataType[] | [] = [
  {
    id: 1,
    codeID: '(AD 2.2)',
    codeLabel: 'Aerodrome Geographical Data',
    label: '( AD 2.2 ) Aerodrome Geographical Data',
    value: 'geoadministrative'
  },
  {
    id: 2,
    codeID: '(AD 2.10)',
    codeLabel: 'Aerodrome Obstacles',
    label: '( AD 2.10 ) Aerodrome Obstacles',
    value: 'aixmobstacles'
  },
  {
    id: 3,
    codeID: '(AD 2.12)',
    codeLabel: 'Runway Physical Characteristics',
    label: '( AD 2.12 ) Runway Physical Characteristics',
    value: 'runwayphysicals'
  },
  {
    id: 4,
    codeID: '(AD 2.13)',
    codeLabel: 'Declared Distances',
    label: '( AD 2.13 ) Declared Distances',
    value: 'declareddistaces'
  },
  {
    id: 5,
    codeID: '(AD 2.14)',
    codeLabel: 'Approach and Runway Lighting',
    label: '( AD 2.14 ) Approach and Runway Lighting',
    value: 'rwylights'
  },
  {
    id: 6,
    codeID: '(AD 2.17)',
    codeLabel: 'Air Traffic Service',
    label: '( AD 2.17 ) Air Traffic Service',
    value: 'airspaceservice'
  },
  {
    id: 7,
    codeID: '(AD2.18)',
    codeLabel: 'Air Traffic Services Comunnications Facilities',
    label: '( AD 2.18 ) Air Traffic Services Comunnications Facilities',
    value: 'airtrafficservices'
  },
  {
    id: 8,
    codeID: '(AD 2.19)',
    codeLabel: 'Radio Navigation and Landing Aids',
    label: '( AD 2.19 ) Radio Navigation and Landing Aids',
    value: 'navaids'
  }
];

export const airspaceCatData: { [key: string]: string } = {
  ctrl: 'Controlled Airspace',
  geo_navaid: 'Navaids',
  waypoint: 'Reporting Points',
  fir: 'FIR',
  restrictive: 'Prohibited, Restricted and Danger Airspace',
  'route-CNAV': 'Conventional Routes',
  adiz: 'ADIZ',
  military: 'Military Exercise and Training Airspace',
  'route-RNAV': 'Area Navigational Routes'
};

export const airspaceDataCategories: CollectDataType[] | [] = [
  {
    id: 1,
    codeID: 'ctrl',
    label: 'Controlled Airspace',
    value: 'ctrl'
  },
  {
    id: 2,
    codeID: 'geo_navaid',
    label: 'Navaids',
    value: 'geo_navaid'
  },
  {
    id: 3,
    codeID: 'waypoint',
    label: 'Reporting Points',
    value: 'waypoint'
  },
  {
    id: 4,
    codeID: 'fir',
    label: 'FIR',
    value: 'fir'
  },
  {
    id: 5,
    codeID: 'restrictive',
    label: 'Prohibited, Restricted and Danger Airspace',
    value: 'restrictive'
  },
  {
    id: 6,
    codeID: 'route-CNAV',
    label: 'Conventional Routes',
    value: 'route-CNAV'
  },
  {
    id: 7,
    codeID: 'adiz',
    label: 'ADIZ',
    value: 'adiz'
  },
  {
    id: 8,
    codeID: 'military',
    label: 'Military Exercise and Training Airspace',
    value: 'military'
  },
  {
    id: 9,
    codeID: 'route-RNAV',
    label: 'Area Navigational Routes',
    value: 'route-RNAV'
  }
];

export const airspaceDataLabel = {
  ctrl: 'ctrl',
  geoNavaid: 'geo_navaid',
  waypoint: 'waypoint',
  fir: 'fir',
  restrictive: 'restrictive',
  routeCNAV: 'route-CNAV',
  routeRNAV: 'route-RNAV',
  adiz: 'adiz',
  military: 'military'
};

export const WMTSLayer = 'cfnpd:india_srtm30';

export const mapDrawType = {
  polygon: 'Polygon',
  lineString: 'LineString',
  point: 'Point',
  polyline: 'Polyline'
};

import { Button, Modal } from 'antd';
import React from 'react';

const DesignProcedureModal = ({
  procedureType,
  isOpenProcedureModal,
  handleProceedDeparture,
  handleCancelDeparture,
  isLoading
}: any) => {
  return (
    <Modal
      open={isOpenProcedureModal}
      title="Are you sure you want to Proceed"
      width={420}
      centered
      className="procedure-modal"
      style={{
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
      }}
      onOk={handleProceedDeparture}
      onCancel={handleCancelDeparture}
      footer={[
        <Button key="back" type="link" onClick={handleCancelDeparture}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={isLoading} onClick={handleProceedDeparture}>
          Proceed
        </Button>
      ]}
    >
      <div>
        Are you sure you want to proceed further with
        <b> &ldquo; {procedureType} Procedure &rdquo;</b> ? You will not be able to change the above
        selection once confirmed.
      </div>
    </Modal>
  );
};

export default DesignProcedureModal;

import { includes } from 'lodash';
import { Typography } from 'antd';
import ConventionalRoutesIcon from 'assets/svg/ConventionalRoutesIcon';
import DotTriangleIcon from 'assets/svg/DotTriangleIcon';
import HexagonDotIcon from 'assets/svg/HexagonDotIcon';
import NominalCirclesIcon from 'assets/svg/NominalCirclesIcon';
import TerrainIcon from 'assets/svg/TerrainIcon';
import TornadoIcon from 'assets/svg/TornadoIcon';
import ToleranceIcon from 'assets/svg/ToleranceIcon';
import TriangleIcon from 'assets/svg/TriangleIcon';
import { secondary, white } from 'constants/color.constant';
import DesignMapElement from './DesignMapElement';

const { Text } = Typography;

interface DesignHeaderMapElementsProps {
  activeMapElement: string[];
  handleOnClickMapElement: (element: string) => void;
}

const DesignHeaderMapElements = ({
  activeMapElement,
  handleOnClickMapElement
}: DesignHeaderMapElementsProps) => {
  return (
    <div>
      <Text>Map Elements</Text>
      <div className="map-element">
        <DesignMapElement
          icon={
            <TornadoIcon
              height={20}
              width={20}
              color={includes(activeMapElement, 'windspirals') ? secondary : white}
            />
          }
          element="windspirals"
          title="Wind Spirals"
          activeElement={activeMapElement}
          handleOnClickMapElement={handleOnClickMapElement}
        />
        <DesignMapElement
          icon={
            <NominalCirclesIcon
              height={20}
              width={20}
              color={includes(activeMapElement, 'splaycircles') ? secondary : white}
            />
          }
          element="splaycircles"
          title="Splay Circles"
          activeElement={activeMapElement}
          handleOnClickMapElement={handleOnClickMapElement}
        />
        <DesignMapElement
          icon={
            <ToleranceIcon
              height={20}
              width={20}
              color={includes(activeMapElement, 'tolerances') ? secondary : white}
            />
          }
          element="tolerances"
          title="Tolerances"
          activeElement={activeMapElement}
          handleOnClickMapElement={handleOnClickMapElement}
        />
        <DesignMapElement
          icon={
            <DotTriangleIcon
              height={20}
              width={20}
              color={includes(activeMapElement, 'surveyobstacles') ? secondary : white}
            />
          }
          element="surveyobstacles"
          title="Survey Obstacles"
          activeElement={activeMapElement}
          handleOnClickMapElement={handleOnClickMapElement}
        />
        <DesignMapElement
          icon={
            <TerrainIcon
              height={20}
              width={20}
              color={includes(activeMapElement, 'terrain') ? secondary : white}
            />
          }
          element="terrain"
          title="Terrain SRTM"
          activeElement={activeMapElement}
          handleOnClickMapElement={handleOnClickMapElement}
        />
        <DesignMapElement
          icon={
            <TriangleIcon
              height={20}
              width={20}
              color={includes(activeMapElement, 'waypoints') ? secondary : white}
            />
          }
          element="waypoints"
          title="Waypoints"
          activeElement={activeMapElement}
          handleOnClickMapElement={handleOnClickMapElement}
        />
        <DesignMapElement
          icon={
            <HexagonDotIcon
              height={20}
              width={20}
              color={includes(activeMapElement, 'navaids') ? secondary : white}
            />
          }
          element="navaids"
          title="Navaids"
          activeElement={activeMapElement}
          handleOnClickMapElement={handleOnClickMapElement}
        />
        <DesignMapElement
          icon={
            <ConventionalRoutesIcon
              height={20}
              width={20}
              color={includes(activeMapElement, 'conventional') ? secondary : white}
            />
          }
          element="conventional"
          title="ATS Routes"
          activeElement={activeMapElement}
          tooltipPlacement="bottomRight"
          handleOnClickMapElement={handleOnClickMapElement}
        />
      </div>
    </div>
  );
};

export default DesignHeaderMapElements;

import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { Divider, Input, Row, Select, Typography } from 'antd';
import ARPPointIcon from 'assets/svg/ARPPointIcon';
import AirspaceIcon from 'assets/svg/AirspaceIcon';
import DownloadPdfIcon from 'assets/svg/DownloadPdfIcon';
import RunwayIcon from 'assets/svg/RunwayIcon';
import { airspaceClassOptions } from 'constants/aeroReport.constant';
import { lightGrey1 } from 'constants/color.constant';
import ListDataCard from 'components/core/ListDataCard';
import ReportContentCard from 'components/core/ReportContentCard';
import { latToDms } from 'utils/utils';
import ReportSecTitleWithIcon from './ReportSecTitleWithIcon';

const { Text } = Typography;

interface Section3Props {
  sec3: any;
  scrollElementRef: any;
  magneticDeclination: any;
  handleCallSaveAeroReportDataApi: (data: { section_key: string; fields: any }) => void;
}

const Section3 = ({
  sec3,
  scrollElementRef,
  magneticDeclination,
  handleCallSaveAeroReportDataApi
}: Section3Props) => {
  const [airspaceRemark, setAirspaceRemark] = useState<string | null>(null);
  const [aerodromeRemark, setAerodromeRemark] = useState<string | null>(null);
  const [runwayRemark, setRunwayRemark] = useState<string | null>(null);
  const [tma, setTMA] = useState<string | null>(null);
  const [cta, setCTA] = useState<string | null>(null);
  const [ctr, setCTR] = useState<string | null>(null);

  useEffect(() => {
    if (!isEmpty(sec3)) {
      setTMA(sec3.airspace_class?.tma || null);
      setCTA(sec3.airspace_class?.cta || null);
      setCTR(sec3.airspace_class?.ctr || null);
      setAerodromeRemark(sec3.aero_remark || null);
      setAirspaceRemark(sec3.airspace_remark || null);
      setRunwayRemark(sec3.rwy_remark || null);
    }
  }, [sec3]);

  const handleOnchangeAerodromeRemark = (e: any) => {
    setAerodromeRemark(e.target.value);
  };

  const handleOnchangeRunwayRemark = (e: any) => {
    setRunwayRemark(e.target.value);
  };

  const handleOnchangeAirspaceRemark = (e: any) => {
    setAirspaceRemark(e.target.value);
  };

  const handleChangeTMA = (value: string) => {
    setTMA(value);
  };

  const handleChangeCTA = (value: string) => {
    setCTA(value);
  };

  const handleChangeCTR = (value: string) => {
    setCTR(value);
  };

  const handleSaveGeneralData = () => {
    const payload: { section_key: string; fields: any } = {
      section_key: 'sec_3',
      fields: { airspace_class: {} }
    };
    if (tma) {
      payload.fields.airspace_class.tma = tma;
    }
    if (cta) {
      payload.fields.airspace_class.cta = cta;
    }
    if (ctr) {
      payload.fields.airspace_class.ctr = ctr;
    }
    if (aerodromeRemark) {
      payload.fields.aero_remark = aerodromeRemark;
    }
    if (airspaceRemark) {
      payload.fields.airspace_remark = airspaceRemark;
    }
    if (runwayRemark) {
      payload.fields.rwy_remark = runwayRemark;
    }
    handleCallSaveAeroReportDataApi(payload);
  };

  return (
    <div className="report-code-content sec-3" ref={scrollElementRef} id="general">
      <ReportSecTitleWithIcon
        title="3. GENERAL DATA"
        icon={<DownloadPdfIcon />}
        handleSaveData={handleSaveGeneralData}
      />
      <div className="report-content-data">
        <div className="general-data-content">
          <ReportContentCard icon={<ARPPointIcon />} title="AERODROME DATA" />
          <Row className="type-data-list">
            <ListDataCard title="Aerodrome Name" listData={[sec3?.aero_name || '']} />
            <ListDataCard title="Aerodrome Elevation" listData={[sec3?.aero_elv || '']} />
            <ListDataCard title="Magnetic Variation" listData={[sec3?.aero_mag_var || '']} />
          </Row>
          <Row className="type-data-list">
            <ListDataCard title="Transition Altitude" listData={[sec3?.aero_trans_alt || '']} />
            <ListDataCard title="ISA Deviation (°C)" listData={[sec3?.isa_dev || 'ISA + 15°']} />
            <ListDataCard
              title="ARP Coordinates (WGS 84)"
              listData={[`LAT : ${sec3?.arp?.lat || '-'}`, `LNG : ${sec3?.arp?.lng || '-'}`]}
            />
          </Row>
          <Row className="type-data-list">
            <ListDataCard
              title="Temperature (°C)"
              listData={[
                `AD REF TEMP : ${sec3?.ad_ref_temp || '-'}`,
                `HIGH : ${
                  sec3?.ad_high_temp
                    ? // eslint-disable-next-line
                      `${(sec3?.ad_high_temp).toFixed(2)} °`
                    : ' - '
                }`,
                `LOW : ${
                  sec3?.ad_low_temp
                    ? // eslint-disable-next-line
                      `${(sec3?.ad_low_temp).toFixed(2)} °`
                    : ' - '
                }`
              ]}
            />
          </Row>
          <Row className="type-data-list">
            <div className="list-data-card" style={{ width: '552px' }}>
              <Text>Remark</Text>
              <Input
                autoComplete="off"
                placeholder="Type here"
                value={aerodromeRemark || undefined}
                onChange={handleOnchangeAerodromeRemark}
              />
            </div>
          </Row>
        </div>
        <Divider dashed style={{ borderColor: lightGrey1, margin: '16px 0' }} />
        <div className="general-data-content">
          <ReportContentCard icon={<RunwayIcon />} title="RUNWAY DETAILS" />
          <Row className="type-data-list">
            <ListDataCard title="Runway" listData={[sec3?.rwy || '-']} />
            <ListDataCard title="Runway Direction" listData={[sec3?.rwy_dir || '-']} />
            <ListDataCard
              title="RWY Bearing"
              listData={[
                `TRUE : ${sec3?.rwy_bearing}°`,
                `MAG :  ${
                  magneticDeclination
                    ? (Number(sec3?.rwy_bearing!) - magneticDeclination).toFixed(2)
                    : 0
                }°`
              ]}
            />
          </Row>
          <Row className="type-data-list">
            <ListDataCard
              title="Declared Distance (TORA)"
              listData={[sec3?.tora ? `${sec3?.tora} m` : '-']}
            />
            <ListDataCard
              title="Declared Distance (TODA)"
              listData={[sec3?.toda ? `${sec3?.toda} m` : '-']}
            />
          </Row>
          <Row className="type-data-list">
            <ListDataCard title="DER Designation" listData={[sec3?.rwy || '-']} />
            <ListDataCard
              title="DER Elevation"
              titleInfo="MAX(DER,AD ELEV)"
              listData={[sec3?.der_elv ? `${sec3?.der_elv.toFixed(2)} ft` : '-']}
            />
            <ListDataCard
              title="DER Coordinates (WGS 84)"
              listData={[
                `LAT : ${sec3?.der_coords?.lat ? latToDms(sec3?.der_coords?.lat) : '-'}`,
                `LNG : ${sec3?.der_coords?.lng ? latToDms(sec3?.der_coords?.lng) : '-'}`
              ]}
            />
          </Row>
          <Row className="type-data-list">
            <ListDataCard title="Other Lights" listData={['As Published in AIP']} />
            <ListDataCard title="Runway Centreline Lights" listData={['As Published in AIP']} />
          </Row>
          <Row className="type-data-list">
            <div className="list-data-card" style={{ width: '552px' }}>
              <Text>Remark</Text>
              <Input
                autoComplete="off"
                placeholder="Type here"
                value={runwayRemark || undefined}
                onChange={handleOnchangeRunwayRemark}
              />
            </div>
          </Row>
        </div>
        <Divider dashed style={{ borderColor: lightGrey1 }} />
        <div className="general-data-content">
          <ReportContentCard icon={<AirspaceIcon />} title="AIRSPACE DETAILS" />
          <Row className="type-data-list">
            <div className="list-data-card">
              <Text>Airspace Classification</Text>
              <div>
                <div>
                  <Text className="select-title">TMA : </Text>
                  <Select
                    className="airspace-select"
                    style={{ width: 100 }}
                    value={tma}
                    onChange={handleChangeTMA}
                    placeholder="select"
                    options={airspaceClassOptions}
                  />
                </div>
                <div>
                  <Text className="select-title">CTA : </Text>
                  <Select
                    className="airspace-select"
                    style={{ width: 100 }}
                    value={cta}
                    onChange={handleChangeCTA}
                    placeholder="select"
                    options={airspaceClassOptions}
                  />
                </div>
                <div>
                  <Text className="select-title">CTR : </Text>
                  <Select
                    className="airspace-select"
                    style={{ width: 100 }}
                    value={ctr}
                    onChange={handleChangeCTR}
                    placeholder="select"
                    options={airspaceClassOptions}
                  />
                </div>
              </div>
            </div>
            <ListDataCard
              title="Special Use Area close to Airport"
              listData={[sec3?.spl_use_area || '-']}
            />
          </Row>
          <Row className="type-data-list">
            <div className="list-data-card" style={{ width: '552px' }}>
              <Text>Remark</Text>
              <Input
                autoComplete="off"
                placeholder="Type here"
                value={airspaceRemark || undefined}
                onChange={handleOnchangeAirspaceRemark}
              />
            </div>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Section3;

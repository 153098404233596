import { Table } from 'antd';
import type { TableProps } from 'antd';
import { Sec10 } from 'store/design/types';
import { latToDms, lngToDms } from 'utils/utils';

interface WaypointPATableProps {
  waypointData: Sec10[] | [];
}

const WaypointPATable = ({ waypointData }: WaypointPATableProps) => {
  const defaultColumns: TableProps<Sec10>['columns'] = [
    {
      title: 'Fix Designation',
      key: 'waypoint_name',
      className: 'table-col-data',
      render: (record) => (record.waypoint_name ? record.waypoint_name : '-')
    },
    {
      title: 'Waypoint Type (FO/FB/RF)',
      dataIndex: 'waypoint_mode',
      key: 'waypoint_mode',
      align: 'center',
      className: 'table-col-data',
      render: (mode) => (mode ? (mode === 'over' ? 'FO' : mode === 'by' ? 'FB' : 'FO') : '-')
    },
    {
      title: 'Waypoint Coordinates',
      align: 'center',
      key: 'waycoords',
      className: 'table-col-data',
      render: (record) => {
        return (
          <>
            <div>{latToDms(record.lat)}</div>
            <div>{lngToDms(record.lng)}</div>
          </>
        );
      }
    },
    {
      title: 'ATT (NM)',
      align: 'center',
      key: 'att',
      className: 'table-col-data',
      render: (record) => (record.aswdetails ? record.aswdetails.att : '-')
    },
    {
      title: 'XTT (NM)',
      align: 'center',
      key: 'xtt',
      className: 'table-col-data',
      render: (record) => (record.aswdetails ? record.aswdetails.xtt : '-')
    },
    {
      title: 'BV (NM)',
      key: 'bv',
      align: 'center',
      className: 'table-col-data',
      render: (record) => (record.aswdetails ? record.aswdetails.bv : '-')
    },
    {
      title: 'AREA SEMI WIDTH 1/2 AW (NM)',
      key: 'aw',
      align: 'center',
      className: 'table-col-data',
      render: (record) => (record.aswdetails ? record.aswdetails.aw : '-')
    }
  ];

  return (
    <Table
      columns={defaultColumns}
      dataSource={waypointData}
      pagination={false}
      rowKey={(record) =>
        `${record.waypoint_name}-${record.lat}-${Math.floor(Math.random() * 100)}`
      }
      rowClassName={() => 'table-row-data'}
    />
  );
};

export default WaypointPATable;

import { IconProps } from 'types/common.types';

const LogOutIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 12"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.333344 6.00001L3.66634 8.59231V6.64809H9.66574V5.35194H3.66634V3.40771L0.333344 6.00001Z"
        fill={color}
      />
      <path
        d="M7.66662 0.16677C6.87838 0.164645 6.09755 0.314603 5.36935 0.607956C4.64115 0.901309 3.98006 1.33223 3.42438 1.87574L4.36695 2.79212C5.24819 1.93537 6.42008 1.46292 7.66662 1.46292C8.91316 1.46292 10.085 1.93537 10.9663 2.79212C11.8475 3.64888 12.3335 4.78819 12.3335 6.00009C12.3335 7.21199 11.8475 8.35131 10.9663 9.20807C10.085 10.0648 8.91316 10.5373 7.66662 10.5373C6.42008 10.5373 5.24819 10.0648 4.36695 9.20807L3.42438 10.1244C4.55693 11.2262 6.06345 11.8334 7.66662 11.8334C9.26979 11.8334 10.7763 11.2262 11.9089 10.1244C13.0421 9.02336 13.6667 7.55871 13.6667 6.00009C13.6667 4.44147 13.0421 2.97682 11.9089 1.87574C11.3532 1.33223 10.6921 0.901309 9.96389 0.607956C9.23569 0.314603 8.45486 0.164645 7.66662 0.16677Z"
        fill={color}
      />
    </svg>
  );
};
LogOutIcon.defaultProps = {
  color: '#666666',
  height: 14,
  width: 12,
  onClick: () => {}
};
export default LogOutIcon;

import { Fragment, useState } from 'react';
import { Marker, Polyline, Popup } from 'react-leaflet';
import { isEmpty } from 'lodash';
import { Space, Typography } from 'antd';
import { red2 } from 'constants/color.constant';
import { mapDrawType } from 'constants/collectData.constant';
import { RNAVCNAVIcon } from 'constants/map.constants';
import { FeatureGeometryType } from 'types/common.types';

const { Text } = Typography;

interface RenderCNAVRouteAirspaceProps {
  routeCNAVAirspaceData: any;
}

const RenderCNAVRouteAirspace = ({ routeCNAVAirspaceData }: RenderCNAVRouteAirspaceProps) => {
  const [selectedOverE, setSelectedOverE] = useState<FeatureGeometryType | null>(null);
  if (routeCNAVAirspaceData && isEmpty(routeCNAVAirspaceData?.features)) return null;

  const handlePolygonOver = (e: any, element: any) => {
    e.target.bringToFront();
    setSelectedOverE(element);
  };

  const handlePolygonOut = () => {
    setSelectedOverE(null);
  };

  return (
    // eslint-disable-next-line
    <>
      {
        // eslint-disable-next-line
        routeCNAVAirspaceData.features.map((cnav: any, index: number) => {
          if (cnav.geometry.type === mapDrawType.lineString) {
            const coordinateData = cnav.geometry.coordinates.map((point: any) => {
              return [Number(point[1]), Number(point[0])];
            });
            const coords: any = cnav.geometry.coordinates;
            return (
              <Fragment key={index}>
                <Polyline
                  key={index}
                  // @ts-ignore
                  positions={coordinateData}
                  pathOptions={{
                    color: red2,
                    weight: selectedOverE?.id === cnav.id ? 3 : 1
                  }}
                  eventHandlers={{
                    mouseover: (e) => handlePolygonOver(e, cnav),
                    mouseout: () => handlePolygonOut()
                  }}
                  pane="shadowPane"
                >
                  {!isEmpty(cnav.properties) && (
                    <Popup
                      className="point-popup polygon-popup"
                      offset={[0, 0]}
                      minWidth={232}
                      maxHeight={226}
                    >
                      <Space direction="vertical">
                        <div>
                          <Text className="market-title">Designator Number</Text>
                          <Text className="market-data">
                            {cnav.properties.designatorNumber || '-'}
                          </Text>
                        </div>
                        <div>
                          <Text className="market-title">Designator Prefix</Text>
                          <Text className="market-data">
                            {cnav.properties.designatorPrefix || '-'}
                          </Text>
                        </div>
                        <div>
                          <Text className="market-title">Designator Second Letter</Text>
                          <Text className="market-data">
                            {cnav.properties.designatorSecondLetter || '-'}
                          </Text>
                        </div>
                        <div>
                          <Text className="market-title">Multiple Identifier</Text>
                          <Text className="market-data">
                            {cnav.properties.multipleIdentifier || '-'}
                          </Text>
                        </div>
                      </Space>
                    </Popup>
                  )}
                </Polyline>
                {coords.map((c: any, i: number) => {
                  return <Marker key={`${i + 1}`} position={[c[1], c[0]]} icon={RNAVCNAVIcon} />;
                })}
              </Fragment>
            );
          }
        })
      }
    </>
  );
};

export default RenderCNAVRouteAirspace;

import { IconProps } from 'types/common.types';

const FlyOverWaypointIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="9" cy="9" r="6.4" stroke={color} strokeWidth="0.7" />
      <circle cx="9" cy="9" r="4" stroke={color} />
      <path
        d="M9.25414 4.52003C9.0436 4.50774 8.81907 4.50585 8.58752 4.52011L9 3.90139L9.42045 4.53206C9.36577 4.52735 9.3103 4.52331 9.25414 4.52003Z"
        fill={color}
        stroke={color}
      />
      <path
        d="M8.74586 13.48C8.95641 13.4923 9.18093 13.4942 9.41248 13.4799L9 14.0986L8.57955 13.4679C8.63423 13.4727 8.6897 13.4767 8.74586 13.48Z"
        fill={color}
        stroke={color}
      />
      <path
        d="M4.52003 8.74586C4.50774 8.9564 4.50585 9.18093 4.52011 9.41248L3.90139 9L4.53206 8.57955C4.52735 8.63423 4.52331 8.6897 4.52003 8.74586Z"
        fill={color}
        stroke={color}
      />
      <path
        d="M13.48 9.25414C13.4923 9.0436 13.4942 8.81907 13.4799 8.58752L14.0986 9L13.4679 9.42045C13.4727 9.36577 13.4767 9.3103 13.48 9.25414Z"
        fill={color}
        stroke={color}
      />
    </svg>
  );
};
FlyOverWaypointIcon.defaultProps = {
  color: '#696B72',
  width: 18,
  height: 18,
  onClick: () => {}
};

export default FlyOverWaypointIcon;

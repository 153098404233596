import { useEffect, useState } from 'react';
import { capitalize, isEmpty } from 'lodash';
import { Button, Table, Typography } from 'antd';
import { AiOutlineCheck } from 'react-icons/ai';
import { ColumnsType } from 'antd/es/table';
import CloseIcon from 'assets/svg/CloseIcon';
import ExpandIcon from 'assets/svg/ExpandIcon';
import { primary } from 'constants/color.constant';
import { CodingTableDataType } from 'store/design/types';
import './styles.less';

const { Text } = Typography;

interface CodingTableProps {
  isLoading: boolean;
  isObsTerrainFullScreenTable: boolean;
  codingData: any;
  handleCodingTable: (activeTable: boolean) => void;
  handleShowFullScreenTable: (showFullTable: boolean) => void;
}

const CodingTable = ({
  isLoading,
  codingData,
  isObsTerrainFullScreenTable,
  handleCodingTable,
  handleShowFullScreenTable
}: CodingTableProps) => {
  const [codingTableData, setCodingTableData] = useState<CodingTableDataType[] | []>([]);

  useEffect(() => {
    if (codingData) {
      const codingDataRes: any = codingData;
      if (codingDataRes.status) {
        setCodingTableData(!isEmpty(codingDataRes.data) ? codingDataRes.data : []);
      }
    }
  }, [codingData]);

  const columns: ColumnsType<CodingTableDataType> = [
    {
      title: 'Waypoint Identifier',
      dataIndex: 'waypoint',
      align: 'center',
      key: 'waypoint',
      render: (_, record) => {
        return !isEmpty(record?.waypoint) ? record?.waypoint : '-';
      }
    },
    {
      title: 'Path Terminator',
      dataIndex: 'path_terminator',
      align: 'center',
      key: 'path_terminator',
      render: (_, record) => {
        return !isEmpty(record?.path_terminator) ? record?.path_terminator : '-';
      }
    },
    {
      title: 'Fly Over',
      dataIndex: 'fly_over',
      align: 'center',
      key: 'fly_over',
      render: (_, record) => {
        return record?.fly_over ? <AiOutlineCheck /> : '';
      }
    },
    {
      title: 'Course Angle',
      dataIndex: 'course',
      align: 'center',
      key: 'course',
      render: (_, record) => {
        return record?.course ? record?.course : '-';
      }
    },
    {
      title: 'Turn Direction',
      dataIndex: 'turn_direction',
      align: 'center',
      key: 'turn_direction',
      render: (_, record) => {
        return record?.turn_direction
          ? record?.turn_direction === 'on-set'
            ? ''
            : capitalize(record?.turn_direction)
          : '';
      }
    },
    {
      title: 'Upper Limit Altitude ft',
      dataIndex: 'upper_limit',
      align: 'center',
      key: 'upper_limit',
      render: (_, record) => {
        return record?.upper_limit ? record?.upper_limit : '-';
      }
    },
    {
      title: 'Lower Limit Altitude ft',
      dataIndex: 'lower_limit',
      align: 'center',
      key: 'lower_limit',
      render: (_, record) => {
        return record?.lower_limit ? record?.lower_limit : '-';
      }
    },
    {
      title: 'Speed Limit kt',
      dataIndex: 'speed',
      align: 'center',
      key: 'speed',
      render: (_, record) => {
        return record?.speed ? record?.speed : '-';
      }
    },
    {
      title: 'TM DST NM',
      dataIndex: 'distance',
      align: 'center',
      key: 'distance',
      render: (_, record) => {
        return record?.distance ? record?.distance : '-';
      }
    },
    {
      title: 'VA',
      dataIndex: 'va',
      align: 'center',
      key: 'va',
      render: (_, record) => {
        return record?.va ? record?.va : '-';
      }
    },
    {
      title: 'Navigation Specification',
      dataIndex: 'navigation',
      align: 'center',
      key: 'navigation',
      render: (_, record) => {
        return record?.navigation ? record?.navigation : '-';
      }
    }
  ];

  return (
    <div
      className={
        isObsTerrainFullScreenTable ? 'full-coding-details coding-details' : 'coding-details'
      }
    >
      {!isObsTerrainFullScreenTable && (
        <div className="coding-header">
          <div>
            <Text>Coding Table</Text>
            <Button
              type="text"
              icon={<ExpandIcon />}
              onClick={() => handleShowFullScreenTable(true)}
              className="back-icon-btn"
            />
          </div>
          <Button
            type="text"
            shape="circle"
            icon={<CloseIcon color={primary} />}
            className="back-icon-btn"
            onClick={() => handleCodingTable(false)}
          />
        </div>
      )}
      <div>
        <Table
          className={
            isObsTerrainFullScreenTable
              ? 'full-collect-table collect-table-main'
              : 'coding-table design-table-main'
          }
          scroll={{
            y: isObsTerrainFullScreenTable ? 'calc(100vh - 204px)' : 250
          }}
          columns={columns}
          loading={isLoading}
          dataSource={codingTableData || []}
          pagination={false}
          rowKey={(record) => record.distance}
        />
      </div>
    </div>
  );
};

export default CodingTable;

import { useEffect, useState } from 'react';
import { capitalize, filter, isEmpty, upperCase } from 'lodash';
import { Button, Switch, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import CloseIcon from 'assets/svg/CloseIcon';
import ExpandIcon from 'assets/svg/ExpandIcon';
import { primary } from 'constants/color.constant';
import { useAppSelector } from 'hooks/useReduxHook';
import { DesignState, OAlegdata, ProcedureDataType } from 'store/design/types';
import './styles.less';

const { Text } = Typography;

interface LegObstacleAnalysisTableProps {
  obstacleData: OAlegdata[] | [];
  isShowClosingObstacle: boolean;
  isLoadingOAData: boolean;
  activeObscleTerrainDataKey: string;
  oaLegCheckedKey: string;
  selectedProcedureData: ProcedureDataType | null;
  handleShowHideOATable: (isShow: boolean) => void;
  handleChangeShowClosingObstacle: (isShow: boolean) => void;
  handleShowFullScreenTable: (isShow: boolean) => void;
  handleShowObsTerrainObstacles: (key: string) => void;
}

const LegObstacleAnalysisTable = ({
  obstacleData,
  isShowClosingObstacle,
  isLoadingOAData,
  selectedProcedureData,
  oaLegCheckedKey,
  // eslint-disable-next-line
  activeObscleTerrainDataKey,
  handleShowHideOATable,
  handleChangeShowClosingObstacle,
  // eslint-disable-next-line
  handleShowObsTerrainObstacles,
  handleShowFullScreenTable
}: LegObstacleAnalysisTableProps) => {
  const { legsDataOMNI, legsDataDeparture }: DesignState = useAppSelector((state) => state.design);
  const [obstacleTableData, setObstacleTableData] = useState<OAlegdata[] | []>([]);

  const legdata =
    selectedProcedureData && selectedProcedureData?.procedure === 'OMNI'
      ? legsDataOMNI
      : selectedProcedureData && selectedProcedureData?.procedure === 'DEPARTURE'
      ? legsDataDeparture
      : null;

  useEffect(() => {
    let obsData = [...obstacleData];
    if (isShowClosingObstacle) {
      let data = filter(obsData, ['is_close_in', isShowClosingObstacle]);
      data = [...data].sort((a: any, b: any) => a.is_close_in - b.is_close_in);
      data = [...data].sort((a: any, b: any) => a.penetration - b.penetration);

      setObstacleTableData([...data]);
    } else {
      obsData = [...obsData].sort((a: any, b: any) => a.is_close_in - b.is_close_in);
      obsData = [...obsData].sort((a: any, b: any) => a.penetration - b.penetration);
      setObstacleTableData([...obsData]);
    }
  }, [isShowClosingObstacle, obstacleData]);

  const columns: ColumnsType<OAlegdata> = [
    {
      title: 'UID',
      dataIndex: 'id',
      align: 'center',
      key: 'id',
      render: (value) => value || '-'
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: 'Coordinates',
      key: 'coordinates',
      render: (record: OAlegdata) => {
        return (
          <>
            <Text style={{ color: '#696b72', display: 'block', fontSize: 12 }}>
              {record.latitude || '-'}{' '}
            </Text>
            <Text style={{ color: '#696b72', display: 'block', fontSize: 12 }}>
              {record.longitude || '-'}
            </Text>
          </>
        );
      }
    },
    {
      title: 'Elevation (m)',
      dataIndex: 'elevation',
      align: 'center',
      key: 'elevation'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      align: 'center',
      key: 'description'
    },
    {
      title: 'MOCA (m)',
      dataIndex: 'moc',
      key: 'moc'
    },
    {
      title: 'Penetration (m)',
      dataIndex: 'penetration',
      key: 'penetration',
      render(value) {
        return (
          <Text style={{ color: Number(value) <= 0 ? '#27AE60' : '#EB5757', fontSize: 12 }}>
            {Number(value) <= 0 ? 'clear' : `${Number(value).toFixed(3)}`}
          </Text>
        );
      }
    }
  ];

  return (
    <div className="obstacle-details">
      <div className="obstacle-header">
        <div>
          <Text>
            {upperCase(oaLegCheckedKey)} - {capitalize(activeObscleTerrainDataKey)} Analysis Results
          </Text>
          <Button
            type="text"
            icon={<ExpandIcon />}
            onClick={() => handleShowFullScreenTable(true)}
            className="back-icon-btn"
          />
        </div>
        <div>
          {!isEmpty(legdata) &&
            oaLegCheckedKey === 'Leg1' &&
            legdata[`${oaLegCheckedKey}`]?.parameters?.type === 'CA' && (
              <>
                <Text>Show Closing Obstacles</Text>
                <Switch
                  size="small"
                  className="oa-result-switch"
                  disabled={isEmpty(obstacleData)}
                  onChange={() => handleChangeShowClosingObstacle(!isShowClosingObstacle)}
                />
              </>
            )}
          <Button
            type="text"
            shape="circle"
            icon={<CloseIcon color={primary} />}
            className="back-icon-btn"
            onClick={() => handleShowHideOATable(false)}
          />
        </div>
        {/* <Button
          type="text"
          shape="circle"
          icon={<CloseIcon color={primary} />}
          className="back-icon-btn"
          onClick={() => handleShowHideOATable(false)}
        /> */}
      </div>
      <div>
        <Table
          className="obstacle-analysis-table design-table-main"
          columns={columns}
          dataSource={obstacleTableData || []}
          pagination={false}
          scroll={{ y: 250 }}
          rowKey={(record) => record.id}
          loading={isLoadingOAData}
        />
      </div>
    </div>
  );
};

export default LegObstacleAnalysisTable;

import { useMemo } from 'react';
import { Circle, FeatureGroup, MapContainer, Marker, Polyline, Tooltip } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import { includes, isEmpty } from 'lodash';
import { Drawer } from 'antd';
import ARPPolyline from 'components/Map/ARPPolyline';
import AixmObstaclesAdData from 'components/Map/AixmObstaclesAdData';
import ControlMapCenter from 'components/Map/ControlMapCenter';
import CreateWaypoint from 'components/Map/CreateWaypoint';
import DraggableMarker from 'components/Map/DraggableMarker';
import EnterWaypoint from 'components/Map/EnterWaypoint';
import LineStringComponent from 'components/Map/LineStringComponent';
import LiveCoordinates from 'components/Map/LiveCoordinates';
import MapActionControlV2 from 'components/Map/MapActionControlV2';
import MapLayers from 'components/Map/MapLayers';
import MeasurementControl from 'components/Map/MeasurementControl';
import OverseeLine from 'components/Map/OverseeLine';
import RFArcLine from 'components/Map/RFArcLine';
import RefrenceLine from 'components/Map/RefrenceLine';
import RenderADIZAirspace from 'components/Map/RenderADIZAirspace';
import RenderCNAVRouteAirspace from 'components/Map/RenderCNAVRouteAirspace';
import RenderControlledAirspace from 'components/Map/RenderControlledAirspace';
import RenderFIRAirspace from 'components/Map/RenderFIRAirspace';
import RenderLegData from 'components/Map/RenderLegData';
import RenderMilitryAirspace from 'components/Map/RenderMilitryAirspace';
import RenderNavaidsAirspace from 'components/Map/RenderNavaidsAirspace';
import RenderObstaclesDepatureOmniData from 'components/Map/RenderObstaclesDepatureOmniData';
import RenderPALineData from 'components/Map/RenderPALineData';
import RenderPrevLegData from 'components/Map/RenderPrevLegData';
import RenderProtectionAreaLegs from 'components/Map/RenderProtectionAreaLegs';
import RenderRNAVRouteAirspace from 'components/Map/RenderRNAVRouteAirspace';
import RenderRestrictiveAirspace from 'components/Map/RenderRestrictiveAirspace';
import RenderSurveyObstaclePoint from 'components/Map/RenderSurveyObstaclePoint';
import RenderToleranceData from 'components/Map/RenderToleranceData';
import RenderWayPointsAirspace from 'components/Map/RenderWayPointsAirspace';
import RenderWindspiralData from 'components/Map/RenderWindspiralData';
import SelectWaypointMarkers from 'components/Map/SelectWaypointMarkers';
import TerrainWMTSTileLayer from 'components/Map/TerrainWMTSTileLayer';
import WMTSTileLayer from 'components/Map/WMTSTileLayer';
import { blue, primary, red2 } from 'constants/color.constant';
import {
  AerodromePointIcon,
  BlueTrianglePointIcon,
  FlybyWaypointLightIcon,
  FlyoverWaypointLightIcon,
  TriangleIcon
} from 'constants/map.constants';
import DesignToolHeader from './DesignToolHeader';
import LayerLegendImage from './LayerLegendImage';

const DesignDataFullScreenMap = ({
  finalArcCords,
  mapZoom,
  isFullScreenMapView,
  isStartDesign,
  initStartInfoDeparture,
  changeCWYPointInfoDataDeparture,
  activePrevLegDesignDataDeparture,
  protectionAreaCheckList,
  mapCenter,
  featureGroupRef,
  isCreateWaypointBool,
  mapType,
  isShowAeroTable,
  activeMapTools,
  pointLatLng,
  newPolyLineCoords,
  centroidPoint,
  isBool,
  endSelectNavaidWaypoint,
  activeFlyBtn,
  endWaypointLat,
  endWaypointLng,
  isViewOnMapEnterWaypoint,
  activeMapElement,
  waypointsData,
  legsData,
  legsDataOMNI,
  activeLeg,
  activeDesignStep,
  DERCoordsDeparture,
  showDataLayerId,
  endPointLatlngOMNI,
  isShowHideOADataOMNI,
  isShowHideOADataDeparture,
  activeOALegDesignDataOMNI,
  activeOALegDesignDataDeparture,
  selectedDesign,
  selectWaypointData,
  isFetchingWaypoint,
  selectedWayPointMarker,
  isShowWayPointOnMapDeparture,
  endWaypointLatLngDeparture,
  legsDataDeparture,
  DERCoordsOMNI,
  refrenceLineData,
  overseeLineData,
  legObstacleAnalysis,
  isShowHideOAData,
  isShowClosingObstacle,
  legWindspiralsData,
  activeObscleTerrainDataKey,
  activeTerrainLegDesignDataDeparture,
  activeTerrainLegDesignDataOMNI,
  isShowHideTerrainLayerDeparture,
  availableAirspaceData,
  isShowHideTerrainLayerOMNI,
  selectedProcedureData,
  activeLegDesignDataDeparture,
  newLegDERCoordsDeparture,
  arp,
  selectedPathTerminatorDeparture,
  CFCourseDept,
  airspaceDataLabel,
  palegDatacheckedList,
  oaLegCheckedKey,
  availableADData,
  surveyObstacleData,
  terrainLayerName,
  nominalCircles,
  arcEndCoord,
  arcCenterCoord,
  activeLegDesignDataOMNI,
  temperature,
  magneticDeclination,
  isShowHideTerrainLayer,
  isShowHideObstacleLayer,
  initInfoDesignData,
  handleOnClickMapTools,
  handleOnClickMapElement,
  handleShowHideDataLayer,
  handleSelectedWaypointMarkerOnClick,
  handleCreateWaypointLatLng,
  handleCallAreaSemiwidth,
  handleOnCreateShape,
  handleOnMounted,
  handleMapType,
  handleFullScreenMapView
}: any) => {
  const FullmapContainer = useMemo(() => {
    const selectedAirspaceData =
      availableAirspaceData && showDataLayerId && !isEmpty(availableAirspaceData[showDataLayerId])
        ? availableAirspaceData[showDataLayerId]
        : [];

    const step3LegData =
      selectedProcedureData && selectedProcedureData.procedure === 'OMNI'
        ? legsDataOMNI
        : legsDataDeparture;

    return (
      <MapContainer
        center={mapCenter}
        maxZoom={15}
        minZoom={9}
        zoomControl={false}
        className="map-container design-data-map-container"
      >
        <MapLayers maptype={mapType} />
        {arp && <Marker icon={AerodromePointIcon} position={arp} />}
        {!isEmpty(initInfoDesignData) && (
          <ARPPolyline
            arpCoordinates={
              !isEmpty(initInfoDesignData)
                ? [
                    [
                      initInfoDesignData.startpoint.coordinates[0],
                      initInfoDesignData.startpoint.coordinates[1]
                    ],
                    [
                      initInfoDesignData.endpoint.coordinates[0],
                      initInfoDesignData.endpoint.coordinates[1]
                    ]
                  ]
                : null
            }
            decoratorIcon={TriangleIcon}
            lineColor="#696B72"
          />
        )}
        {/* Departure render start */}
        {activeDesignStep === 1 &&
          Number(activeLeg) === 1 &&
          selectedProcedureData &&
          selectedProcedureData.procedure === 'DEPARTURE' &&
          !isEmpty(DERCoordsDeparture) &&
          isEmpty(activeLegDesignDataDeparture) && (
            <Marker position={DERCoordsDeparture} icon={BlueTrianglePointIcon}>
              <Tooltip>DER</Tooltip>
            </Marker>
          )}
        {activeDesignStep === 1 &&
          Number(activeLeg) > 1 &&
          selectedPathTerminatorDeparture === 'CF' &&
          selectedProcedureData &&
          CFCourseDept &&
          selectedProcedureData.procedure === 'DEPARTURE' &&
          !isEmpty(newLegDERCoordsDeparture) &&
          isEmpty(activeLegDesignDataDeparture) && (
            <Marker
              position={newLegDERCoordsDeparture}
              icon={activeFlyBtn === 'by' ? FlybyWaypointLightIcon : FlyoverWaypointLightIcon}
            >
              <Tooltip>New start point</Tooltip>
            </Marker>
          )}
        {endSelectNavaidWaypoint &&
          activeDesignStep === 1 &&
          selectedProcedureData &&
          Number(activeLeg) === 1 &&
          selectedProcedureData.procedure === 'DEPARTURE' &&
          !isEmpty(DERCoordsDeparture) &&
          !isEmpty(endWaypointLatLngDeparture) &&
          isEmpty(activeLegDesignDataDeparture) && (
            <Polyline
              pane="shadowPane"
              pathOptions={{ color: red2, weight: 1.5 }}
              positions={[DERCoordsDeparture, endWaypointLatLngDeparture]}
            />
          )}
        {endSelectNavaidWaypoint &&
          activeDesignStep === 1 &&
          Number(activeLeg) > 1 &&
          selectedProcedureData &&
          selectedProcedureData.procedure === 'DEPARTURE' &&
          !isEmpty(newLegDERCoordsDeparture) &&
          !isEmpty(endWaypointLatLngDeparture) &&
          selectedPathTerminatorDeparture !== 'RF' &&
          isEmpty(activeLegDesignDataDeparture) && (
            <Polyline
              pane="shadowPane"
              pathOptions={{ color: red2, weight: 1.5 }}
              positions={[newLegDERCoordsDeparture, endWaypointLatLngDeparture]}
            />
          )}
        {activeDesignStep === 1 &&
          selectedProcedureData &&
          selectedProcedureData.procedure === 'DEPARTURE' &&
          !isEmpty(refrenceLineData) &&
          isEmpty(activeLegDesignDataDeparture) && <RefrenceLine lineCoords={refrenceLineData} />}
        {activeDesignStep === 1 &&
          selectedProcedureData &&
          selectedProcedureData.procedure === 'DEPARTURE' &&
          !isEmpty(overseeLineData) &&
          isEmpty(activeLegDesignDataDeparture) && <OverseeLine lineCoords={overseeLineData} />}
        {activeDesignStep === 1 &&
          selectedProcedureData &&
          selectedProcedureData.procedure === 'DEPARTURE' &&
          selectedPathTerminatorDeparture !== 'RF' &&
          !isEmpty(endWaypointLatLngDeparture) &&
          isEmpty(activeLegDesignDataDeparture) && (
            <Marker
              position={endWaypointLatLngDeparture}
              icon={activeFlyBtn === 'by' ? FlybyWaypointLightIcon : FlyoverWaypointLightIcon}
            >
              <Tooltip>{selectedWayPointMarker ? selectedWayPointMarker.name : 'XXXX'}</Tooltip>
            </Marker>
          )}
        {activeDesignStep === 1 &&
          selectedProcedureData &&
          selectedProcedureData.procedure === 'DEPARTURE' &&
          !isEmpty(legsDataDeparture) && (
            <RenderLegData
              legsData={legsDataDeparture}
              activeLegKey={`Leg${activeLeg}`}
              procedure="DEPARTURE"
              selectedPathTerminator={
                selectedPathTerminatorDeparture === 'RF' ? selectedPathTerminatorDeparture : null
              }
            />
          )}
        {activeDesignStep === 2 &&
          selectedProcedureData &&
          selectedProcedureData.procedure === 'DEPARTURE' &&
          !isEmpty(step3LegData) &&
          palegDatacheckedList.map((leg: string, i: number) => {
            return (
              <RenderLegData
                key={i}
                legsData={step3LegData}
                activeLegKey={leg}
                procedure="DEPARTURE"
                isLine={false}
                selectedPathTerminator={
                  selectedPathTerminatorDeparture === 'RF' ? selectedPathTerminatorDeparture : null
                }
              />
            );
          })}

        {activeDesignStep === 3 &&
          !isEmpty(legsDataDeparture) &&
          Object.keys(legsDataDeparture).map((leg, i) => {
            return (
              <RenderPALineData
                key={i}
                legsData={legsDataDeparture}
                activeLegKey={leg}
                procedure="DEPARTURE"
                pathTerminator={null}
              />
            );
          })}
        {activeDesignStep === 2 &&
          selectedProcedureData &&
          selectedProcedureData.procedure === 'DEPARTURE' &&
          !isEmpty(step3LegData) &&
          Object.keys(step3LegData).map((leg, i) => {
            return (
              <RenderPALineData
                key={i}
                legsData={step3LegData}
                activeLegKey={leg}
                procedure="DEPARTURE"
                pathTerminator={null}
              />
            );
          })}
        {activeDesignStep === 3 &&
          oaLegCheckedKey &&
          legsDataDeparture &&
          selectedProcedureData &&
          selectedProcedureData.procedure === 'DEPARTURE' && (
            <RenderLegData
              legsData={legsDataDeparture}
              activeLegKey={oaLegCheckedKey}
              procedure="DEPARTURE"
              isLine={false}
              selectedPathTerminator={
                selectedPathTerminatorDeparture === 'RF' ? selectedPathTerminatorDeparture : null
              }
            />
          )}
        {activeDesignStep === 1 &&
          selectedProcedureData &&
          selectedProcedureData.procedure === 'DEPARTURE' &&
          isEmpty(activeLegDesignDataDeparture) &&
          !isEmpty(activePrevLegDesignDataDeparture) && (
            <RenderPrevLegData
              legsData={activePrevLegDesignDataDeparture}
              isLine={false}
              procedure="DEPARTURE"
              selectedPathTerminator={
                activePrevLegDesignDataDeparture?.parameters?.type === 'RF' ? 'RF' : null
              }
            />
          )}
        {activeDesignStep === 1 &&
          !isEmpty(activeLegDesignDataDeparture) &&
          activeObscleTerrainDataKey === 'terrain' &&
          isShowHideTerrainLayerDeparture && (
            <WMTSTileLayer
              icaocode={activeLegDesignDataDeparture?.terrain_layer_name || null}
              isTerrainDataViewonmap
            />
          )}
        {/* Departure render end */}
        {/* Waypoint markers start */}
        {endSelectNavaidWaypoint === 'selectWaypoint' && isShowWayPointOnMapDeparture && (
          <SelectWaypointMarkers
            endSelectNavaidWaypoint={endSelectNavaidWaypoint}
            selectWaypointData={selectWaypointData}
            selectedWayPointMarker={selectedWayPointMarker}
            handleSelectedWaypointMarkerOnClick={handleSelectedWaypointMarkerOnClick}
          />
        )}
        {/* Waypoint markers end */}
        {/* design tool header content render start */}
        {showDataLayerId === 'aixmobstacles' && (
          <AixmObstaclesAdData
            obstacleData={
              availableADData &&
              showDataLayerId === 'aixmobstacles' &&
              !isEmpty(availableADData[showDataLayerId])
                ? availableADData[showDataLayerId]
                : []
            }
          />
        )}
        {activeDesignStep === 1 &&
          !isEmpty(activeOALegDesignDataDeparture) &&
          activeObscleTerrainDataKey === 'obstacle' &&
          isShowHideOADataDeparture && (
            <RenderObstaclesDepatureOmniData
              obstacleData={activeOALegDesignDataDeparture}
              isShowClosingObs={isShowClosingObstacle}
            />
          )}
        {activeDesignStep === 1 &&
          !isEmpty(activeTerrainLegDesignDataDeparture) &&
          activeObscleTerrainDataKey === 'terrain' &&
          isShowHideTerrainLayerDeparture && (
            <RenderObstaclesDepatureOmniData
              obstacleData={activeTerrainLegDesignDataDeparture}
              isShowClosingObs={isShowClosingObstacle}
            />
          )}
        {/* Airspace data render */}
        {showDataLayerId === airspaceDataLabel.ctrl && (
          <RenderControlledAirspace ctrlAirspaceData={selectedAirspaceData} />
        )}
        {showDataLayerId === airspaceDataLabel.geoNavaid && (
          <RenderNavaidsAirspace navaidAirspaceData={selectedAirspaceData} />
        )}
        {showDataLayerId === airspaceDataLabel.waypoint && (
          <RenderWayPointsAirspace waypointAirspaceData={selectedAirspaceData} />
        )}
        {showDataLayerId === airspaceDataLabel.fir && (
          <RenderFIRAirspace firAirspaceData={selectedAirspaceData} />
        )}
        {showDataLayerId === airspaceDataLabel.restrictive && (
          <RenderRestrictiveAirspace restrictiveAirspaceData={selectedAirspaceData} />
        )}
        {showDataLayerId === airspaceDataLabel.routeRNAV && (
          <RenderRNAVRouteAirspace routeRNAVAirspaceData={selectedAirspaceData} />
        )}
        {showDataLayerId === airspaceDataLabel.routeCNAV && (
          <RenderCNAVRouteAirspace routeCNAVAirspaceData={selectedAirspaceData} />
        )}
        {showDataLayerId === airspaceDataLabel.military && (
          <RenderMilitryAirspace militryAirspaceData={selectedAirspaceData} />
        )}
        {showDataLayerId === airspaceDataLabel.adiz && (
          <RenderADIZAirspace adizAirspaceData={selectedAirspaceData} />
        )}
        {showDataLayerId === 'surveyobstacle' && !isEmpty(surveyObstacleData) && (
          <RenderSurveyObstaclePoint obstacleData={surveyObstacleData} />
        )}
        {showDataLayerId === 'srtm' && terrainLayerName && (
          <TerrainWMTSTileLayer WMTSStyle={terrainLayerName} isTerrainDataViewonmap />
        )}
        {/* map elements render start */}
        {!isEmpty(nominalCircles) &&
          includes(activeMapElement, 'splaycircles') &&
          !isEmpty(nominalCircles?.circle_15_nm) &&
          (isEmpty(activeLegDesignDataDeparture) || isEmpty(activeLegDesignDataOMNI)) && (
            <LineStringComponent
              coordinates={nominalCircles.circle_15_nm}
              color={blue}
              dashArray={[5, 5]}
              weight={2}
            />
          )}
        {!isEmpty(nominalCircles) &&
          includes(activeMapElement, 'splaycircles') &&
          !isEmpty(nominalCircles?.circle_30_nm) &&
          (isEmpty(activeLegDesignDataDeparture) || isEmpty(activeLegDesignDataOMNI)) && (
            <LineStringComponent
              coordinates={nominalCircles.circle_30_nm}
              color={blue}
              dashArray={[5, 5]}
              weight={2}
            />
          )}
        {activeDesignStep === 1 &&
          !isEmpty(step3LegData) &&
          includes(activeMapElement, 'tolerances') && (
            <RenderToleranceData legsData={step3LegData} activeLegKey={`Leg${activeLeg}`} />
          )}
        {palegDatacheckedList &&
          activeDesignStep === 2 &&
          !isEmpty(step3LegData) &&
          includes(activeMapElement, 'tolerances') &&
          palegDatacheckedList.map((leg: string) => {
            return <RenderToleranceData legsData={step3LegData} activeLegKey={leg} />;
          })}
        {activeDesignStep === 3 &&
          oaLegCheckedKey &&
          !isEmpty(step3LegData) &&
          includes(activeMapElement, 'tolerances') && (
            <RenderToleranceData legsData={step3LegData} activeLegKey={oaLegCheckedKey} />
          )}
        {availableAirspaceData &&
          availableAirspaceData[airspaceDataLabel.geoNavaid] &&
          includes(activeMapElement, 'navaids') && (
            <RenderNavaidsAirspace
              navaidAirspaceData={availableAirspaceData[airspaceDataLabel.geoNavaid]}
            />
          )}
        {availableAirspaceData &&
          availableAirspaceData[airspaceDataLabel.routeCNAV] &&
          includes(activeMapElement, 'conventional') && (
            <RenderCNAVRouteAirspace
              routeCNAVAirspaceData={availableAirspaceData[airspaceDataLabel.routeCNAV]}
            />
          )}
        {activeMapElement.includes('surveyobstacles') && !isEmpty(surveyObstacleData) && (
          <RenderSurveyObstaclePoint obstacleData={surveyObstacleData} />
        )}
        {activeMapElement.includes('terrain') && terrainLayerName && (
          <TerrainWMTSTileLayer WMTSStyle={terrainLayerName} isTerrainDataViewonmap />
        )}
        {activeDesignStep === 1 && activeMapElement.includes('windspirals') && (
          <RenderWindspiralData windspiraldata={legWindspiralsData} />
        )}
        {palegDatacheckedList &&
          activeDesignStep === 2 &&
          !isEmpty(step3LegData) &&
          includes(activeMapElement, 'windspirals') &&
          palegDatacheckedList.map((leg: string) => {
            return <RenderWindspiralData windspiraldata={step3LegData[leg].windspirals} />;
          })}
        {activeDesignStep === 3 &&
          oaLegCheckedKey &&
          !isEmpty(step3LegData) &&
          includes(activeMapElement, 'windspirals') && (
            <RenderWindspiralData windspiraldata={step3LegData[oaLegCheckedKey].windspirals} />
          )}
        {includes(activeMapElement, 'waypoints') && isShowWayPointOnMapDeparture && (
          <SelectWaypointMarkers
            endSelectNavaidWaypoint={endSelectNavaidWaypoint}
            selectWaypointData={selectWaypointData}
            selectedWayPointMarker={selectedWayPointMarker}
            handleSelectedWaypointMarkerOnClick={handleSelectedWaypointMarkerOnClick}
            markerIcon={isShowWayPointOnMapDeparture}
          />
        )}
        {/* map elements render end */}
        {/* design tool header content render end */}
        {selectedPathTerminatorDeparture !== 'RF' && (
          <CreateWaypoint
            activeLegDesignDataDeparture={activeLegDesignDataDeparture}
            endSelectNavaidWaypoint={endSelectNavaidWaypoint}
            handleCreateWaypointLatLng={handleCreateWaypointLatLng}
          />
        )}
        {selectedPathTerminatorDeparture === 'RF' && (
          <CreateWaypoint
            activeLegDesignDataDeparture={activeLegDesignDataDeparture}
            endSelectNavaidWaypoint={endSelectNavaidWaypoint}
            pathTerminator={selectedPathTerminatorDeparture}
            handleCreateWaypointLatLng={handleCreateWaypointLatLng}
            handleCallAreaSemiwidth={handleCallAreaSemiwidth}
          />
        )}
        {endSelectNavaidWaypoint === 'latlngWaypoint' && isViewOnMapEnterWaypoint && (
          <EnterWaypoint
            activeFlyBtn={activeFlyBtn}
            endWaypointName={undefined}
            endWaypointLat={endWaypointLat}
            endWaypointLng={endWaypointLng}
          />
        )}
        {!isEmpty(protectionAreaCheckList) && (
          <RenderProtectionAreaLegs paCheckList={protectionAreaCheckList} legsData={legsData} />
        )}

        {/* Omni data render start */}
        {activeDesignStep === 1 &&
          selectedProcedureData &&
          selectedProcedureData.procedure === 'OMNI' &&
          !isEmpty(DERCoordsOMNI) &&
          isEmpty(activeLegDesignDataOMNI) && (
            <Marker position={DERCoordsOMNI} icon={BlueTrianglePointIcon}>
              <Tooltip>DER</Tooltip>
            </Marker>
          )}
        {activeDesignStep === 1 &&
          selectedProcedureData &&
          selectedProcedureData.procedure === 'OMNI' &&
          !isEmpty(DERCoordsOMNI) &&
          !isEmpty(endPointLatlngOMNI) &&
          isEmpty(activeLegDesignDataOMNI) && (
            <ARPPolyline
              lineColor="#696B72"
              arpCoordinates={[DERCoordsOMNI, endPointLatlngOMNI]}
              decoratorIcon={TriangleIcon}
            />
          )}
        {activeDesignStep === 1 &&
          selectedProcedureData &&
          selectedProcedureData.procedure === 'OMNI' &&
          !isEmpty(endPointLatlngOMNI) &&
          isEmpty(activeLegDesignDataOMNI) && (
            <Marker position={endPointLatlngOMNI} icon={FlyoverWaypointLightIcon}>
              <Tooltip>XXXXX</Tooltip>
            </Marker>
          )}
        {activeDesignStep === 1 &&
          selectedProcedureData &&
          selectedProcedureData.procedure === 'OMNI' &&
          !isEmpty(refrenceLineData) &&
          isEmpty(activeLegDesignDataOMNI) && <RefrenceLine lineCoords={refrenceLineData} />}
        {activeDesignStep === 1 &&
          selectedProcedureData &&
          selectedProcedureData.procedure === 'OMNI' &&
          !isEmpty(overseeLineData) &&
          isEmpty(activeLegDesignDataOMNI) && <OverseeLine lineCoords={overseeLineData} />}
        {activeDesignStep === 1 &&
          !isEmpty(activeOALegDesignDataOMNI) &&
          activeObscleTerrainDataKey === 'obstacle' &&
          isShowHideOADataOMNI && (
            <RenderObstaclesDepatureOmniData obstacleData={activeOALegDesignDataOMNI} />
          )}
        {activeDesignStep === 1 &&
          !isEmpty(activeTerrainLegDesignDataOMNI) &&
          activeObscleTerrainDataKey === 'terrain' &&
          isShowHideTerrainLayerOMNI && (
            <RenderObstaclesDepatureOmniData obstacleData={activeTerrainLegDesignDataOMNI} />
          )}
        {activeDesignStep === 1 &&
          !isEmpty(activeLegDesignDataOMNI) &&
          activeObscleTerrainDataKey === 'terrain' &&
          isShowHideTerrainLayerOMNI && (
            <WMTSTileLayer
              icaocode={activeLegDesignDataOMNI?.terrain_layer_name || null}
              isTerrainDataViewonmap
            />
          )}
        {activeDesignStep === 1 && !isEmpty(legsDataOMNI) && (
          <RenderLegData
            legsData={legsDataOMNI}
            procedure="OMNI"
            activeLegKey="Leg1"
            selectedPathTerminator={null}
          />
        )}
        {activeDesignStep === 3 &&
          !isEmpty(step3LegData) &&
          oaLegCheckedKey &&
          activeObscleTerrainDataKey === 'terrain' &&
          isShowHideTerrainLayer && (
            <WMTSTileLayer
              icaocode={step3LegData[oaLegCheckedKey].terrain_layer_name || null}
              isTerrainDataViewonmap
            />
          )}
        {activeDesignStep === 3 &&
          !isEmpty(legsDataOMNI) &&
          Object.keys(legsDataOMNI).map((leg, i) => {
            return (
              <RenderPALineData
                key={i}
                legsData={legsDataOMNI}
                activeLegKey={leg}
                procedure="OMNI"
                isPoint={!isShowHideOAData}
                pathTerminator={null}
              />
            );
          })}
        {activeDesignStep === 2 &&
          selectedProcedureData &&
          selectedProcedureData.procedure === 'OMNI' &&
          !isEmpty(step3LegData) &&
          Object.keys(step3LegData).map((leg, i) => {
            return (
              <RenderPALineData
                key={i}
                legsData={step3LegData}
                activeLegKey={leg}
                procedure="OMNI"
                isPoint={isEmpty(palegDatacheckedList)}
                pathTerminator={null}
              />
            );
          })}
        {activeDesignStep === 2 &&
          selectedProcedureData &&
          selectedProcedureData.procedure === 'OMNI' &&
          !isEmpty(step3LegData) &&
          palegDatacheckedList.map((leg: string, i: number) => {
            return (
              <RenderLegData
                key={i}
                legsData={step3LegData}
                activeLegKey={leg}
                procedure="OMNI"
                isLine={false}
                selectedPathTerminator={null}
              />
            );
          })}
        {activeDesignStep === 3 && !isEmpty(legsDataOMNI) && isShowHideOAData && (
          <RenderLegData
            legsData={legsDataOMNI}
            procedure="OMNI"
            activeLegKey="Leg1"
            selectedPathTerminator={null}
          />
        )}
        {activeDesignStep === 3 &&
          !isEmpty(legObstacleAnalysis) &&
          isShowHideOAData &&
          (isShowHideTerrainLayer || isShowHideObstacleLayer) && (
            <RenderObstaclesDepatureOmniData
              obstacleData={legObstacleAnalysis}
              isShowClosingObs={isShowClosingObstacle}
            />
          )}
        {/* Omni data render end */}
        {activeDesignStep === 1 &&
          !isEmpty(finalArcCords) &&
          selectedPathTerminatorDeparture === 'RF' &&
          isEmpty(activeLegDesignDataDeparture) && <RFArcLine finalArcCords={finalArcCords} />}
        {activeDesignStep === 1 &&
          !isEmpty(activeLegDesignDataDeparture) &&
          activeLegDesignDataDeparture?.parameters?.type === 'RF' && (
            <RFArcLine
              finalArcCords={activeLegDesignDataDeparture.geo_parameters.nominal_track || []}
            />
          )}
        {activeDesignStep === 1 &&
          !isEmpty(arcEndCoord) &&
          endSelectNavaidWaypoint === 'createWaypoint' &&
          selectedPathTerminatorDeparture === 'RF' &&
          isEmpty(activeLegDesignDataDeparture) && (
            <DraggableMarker
              activeFlyBtn={activeFlyBtn}
              arcEndCoord={arcEndCoord}
              handleCreateWaypointLatLng={handleCreateWaypointLatLng}
              handleCallAreaSemiwidth={handleCallAreaSemiwidth}
            />
          )}
        {!isEmpty(arcCenterCoord) &&
          selectedPathTerminatorDeparture === 'RF' &&
          isEmpty(activeLegDesignDataDeparture) && (
            <Circle
              center={arcCenterCoord}
              pathOptions={{ fillColor: primary, color: primary }}
              radius={200}
            >
              <Tooltip>Center Point</Tooltip>
            </Circle>
          )}

        <FeatureGroup ref={featureGroupRef}>
          <EditControl
            position="topright"
            onCreated={handleOnCreateShape}
            onMounted={handleOnMounted}
            draw={{
              rectangle: false,
              polyline: false,
              // activeMapTools === 'line'
              //   ? {
              //       allowIntersection: false,
              //       shapeOptions: {
              //         color: '#1ee0cd',
              //         fillColor: '#1ee0cd',
              //         fillOpacity: 1,
              //         weight: 2
              //       },
              //       showLength: true
              //     }
              //   : false,
              circle: false,
              circlemarker: false,
              polygon: false,
              marker: false
              // activeMapTools === 'point'
              //   ? {
              //       icon:
              //         // eslint-disable-next-line
              //         activeFlyBtn === 'flyover' && endSelectNavaidWaypoint === 'createWaypoint'
              //           ? FlyoverWaypointIcon
              //           : activeFlyBtn === 'flyby' && endSelectNavaidWaypoint === 'createWaypoint'
              //           ? FlybyWaypointIcon
              //           : LightLocation
              //     }
              //   : false
            }}
            edit={{ remove: isBool, edit: false }}
          />
        </FeatureGroup>

        <MeasurementControl />
        <MapActionControlV2
          mapType={mapType}
          handleMapType={handleMapType}
          handleFullScreenMapView={handleFullScreenMapView}
          isFullScreenMapView={isFullScreenMapView}
        />
        <LiveCoordinates />
        <ControlMapCenter zoomLevel={mapZoom} center={mapCenter} />
      </MapContainer>
    );
  }, [
    finalArcCords,
    mapZoom,
    isFullScreenMapView,
    isStartDesign,
    initStartInfoDeparture,
    changeCWYPointInfoDataDeparture,
    activePrevLegDesignDataDeparture,
    protectionAreaCheckList,
    mapCenter,
    featureGroupRef,
    isCreateWaypointBool,
    mapType,
    isShowAeroTable,
    activeMapTools,
    pointLatLng,
    newPolyLineCoords,
    centroidPoint,
    isBool,
    endSelectNavaidWaypoint,
    activeFlyBtn,
    endWaypointLat,
    endWaypointLng,
    isViewOnMapEnterWaypoint,
    activeMapElement,
    waypointsData,
    legsData,
    legsDataOMNI,
    activeLeg,
    activeDesignStep,
    DERCoordsDeparture,
    showDataLayerId,
    endPointLatlngOMNI,
    isShowHideOADataOMNI,
    isShowHideOADataDeparture,
    activeOALegDesignDataOMNI,
    activeOALegDesignDataDeparture,
    selectedDesign,
    selectWaypointData,
    isFetchingWaypoint,
    selectedWayPointMarker,
    isShowWayPointOnMapDeparture,
    endWaypointLatLngDeparture,
    legsDataDeparture,
    DERCoordsOMNI,
    refrenceLineData,
    overseeLineData,
    legObstacleAnalysis,
    isShowHideOAData,
    isShowClosingObstacle,
    legWindspiralsData,
    activeObscleTerrainDataKey,
    activeTerrainLegDesignDataDeparture,
    activeTerrainLegDesignDataOMNI,
    isShowHideTerrainLayerDeparture,
    isShowHideTerrainLayerOMNI,
    isShowHideTerrainLayer,
    isShowHideObstacleLayer,
    initInfoDesignData
  ]);

  return (
    <Drawer
      title=""
      width="100%"
      placement="right"
      mask={false}
      className="design-full-map-drawer"
      open={isFullScreenMapView}
    >
      <DesignToolHeader
        activeDataLayerId={showDataLayerId}
        temperature={temperature}
        activeMapTools={activeMapTools}
        activeMapElement={activeMapElement}
        magneticDeclination={magneticDeclination}
        handleOnClickMapTools={handleOnClickMapTools}
        handleOnClickMapElement={handleOnClickMapElement}
        handleShowHideDataLayer={handleShowHideDataLayer}
      />
      {FullmapContainer}
      {showDataLayerId === 'srtm' && terrainLayerName && (
        <LayerLegendImage
          classname="design-layer-data"
          terrainPath={selectedDesign?.icao || null}
        />
      )}
      {activeMapElement.includes('terrain') && terrainLayerName && (
        <LayerLegendImage
          classname="design-layer-data"
          terrainPath={selectedDesign?.icao || null}
        />
      )}
      {activeDesignStep === 1 &&
        activeObscleTerrainDataKey === 'terrain' &&
        !isEmpty(activeLegDesignDataOMNI) &&
        isShowHideTerrainLayerOMNI && (
          <LayerLegendImage
            classname="design-layer-data"
            terrainPath={activeLegDesignDataOMNI?.terrain_layer_name || null}
          />
        )}
      {activeDesignStep === 1 &&
        activeObscleTerrainDataKey === 'terrain' &&
        !isEmpty(activeLegDesignDataDeparture) &&
        isShowHideTerrainLayerDeparture && (
          <LayerLegendImage
            classname="design-layer-data"
            terrainPath={activeLegDesignDataDeparture?.terrain_layer_name || null}
          />
        )}
      {activeDesignStep === 3 &&
        activeObscleTerrainDataKey === 'terrain' &&
        isShowHideTerrainLayer &&
        !isEmpty(activeLegDesignDataDeparture) &&
        selectedProcedureData &&
        selectedProcedureData.procedure === 'DEPARTURE' && (
          <LayerLegendImage
            classname="design-layer-data"
            terrainPath={activeLegDesignDataDeparture?.terrain_layer_name || null}
          />
        )}
      {activeDesignStep === 3 &&
        activeObscleTerrainDataKey === 'terrain' &&
        isShowHideTerrainLayer &&
        !isEmpty(activeLegDesignDataOMNI) &&
        selectedProcedureData &&
        selectedProcedureData.procedure === 'OMNI' && (
          <LayerLegendImage
            classname="design-layer-data"
            terrainPath={activeLegDesignDataOMNI?.terrain_layer_name || null}
          />
        )}
    </Drawer>
  );
};

export default DesignDataFullScreenMap;

import { Space, Typography } from 'antd';
import { isEmpty } from 'lodash';
import { Marker, Popup } from 'react-leaflet';
import { ObstacleRedIcon } from 'constants/map.constants';
import { SurveyObstacleType } from 'store/design/types';
import { useMemo } from 'react';

interface RenderSurveyObstaclePointProps {
  obstacleData: SurveyObstacleType[] | [];
}

const { Text } = Typography;

const RenderSurveyObstaclePoint = ({ obstacleData }: RenderSurveyObstaclePointProps) => {
  if (isEmpty(obstacleData)) return null;
  const obstacleMemo = useMemo(() => {
    return obstacleData.map((point: SurveyObstacleType, index: number) => (
      <Marker
        key={index}
        position={[point.coordinates?.coordinates[1], point.coordinates?.coordinates[0]]}
        icon={ObstacleRedIcon}
      >
        {!isEmpty(point) && (
          <Popup className="point-popup ad-obstacle" offset={[10, 0]} minWidth={232}>
            <Space direction="vertical">
              <div>
                <Text className="market-title">ID</Text>
                <Text className="market-data">{point.id || '-'}</Text>
              </div>
              <div>
                <Text className="market-title">Type</Text>
                <Text className="market-data">{point.type || '-'}</Text>
              </div>
              <div>
                <Text className="market-title">Description</Text>
                <Text className="market-data">{point.description || '-'}</Text>
              </div>
              <div>
                <Text className="market-title">Latitude</Text>
                <Text className="market-data">{point.latitude || '-'}</Text>
              </div>
              <div>
                <Text className="market-title">Longitude</Text>
                <Text className="market-data">{point.longitude || '-'}</Text>
              </div>
              <div>
                <Text className="market-title">Top Elevation (m)</Text>
                <Text className="market-data">{point.elevation || '-'}</Text>
              </div>
            </Space>
          </Popup>
        )}
      </Marker>
    ));
  }, [obstacleData]);

  // eslint-disable-next-line
  return <>{obstacleMemo}</>;
};

export default RenderSurveyObstaclePoint;

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { filter, find, isEmpty } from 'lodash';
import { Button, Col, Divider, Form, Input, InputNumber, Row, Select, Typography } from 'antd';
import ArrowleftIcon from 'assets/svg/ArrowleftIcon';
import DownArrow from 'assets/svg/DownArrow';
import { routes } from 'constants/pageRoutes.constants';
import {
  aircraftCategoryOptions,
  designTypeOptions,
  navTypeOptions,
  navaidOptions1,
  navaidOptions2
} from 'constants/designData.constants';
import PageHeader from 'components/PageHeader/PageHeader';
import UserOptionItemComponent from 'components/InputComponents/UserOptionItemComponent';
import ConfirmationModal from 'components/core/ConfirmationModal';
import ContentScreenLoaderV1 from 'components/core/ContentScreenLoaderV1';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { useAddDesignMutation, useGetRunWayQuery } from 'services/apis/designsApi';
import { useGetOrganisationsMembersQuery } from 'services/apis/userApi';
import { getAirportData, setRunways, getRunwayData, getRunwayDirectionData } from 'store/design';
import {
  ARPRunwayResponse,
  CreateDesignFormRequest,
  CreateDesignApiRequest
} from 'store/design/types';
import { OrganisationMember } from 'store/users/types';
import { OptionsType } from 'types/common.types';
import './styles.less';

const { Text } = Typography;
const { Option } = Select;

const CreateNewDesign = () => {
  const navigate = useNavigate();
  const dispatch: any = useAppDispatch();
  const currentUser = useAppSelector((state) => state.user.userSession);
  const [addDesign, { data: addDesignData, isLoading: isAddDesignLoading }] =
    useAddDesignMutation();
  const [form] = Form.useForm();
  const [orgMembers, setOrgMembers] = useState<OrganisationMember[] | []>([]);
  const [proceduretype, setProceduretype] = useState<string | undefined>(undefined);
  const [navType, setNavType] = useState<string | undefined>();
  const [selectedAirport, setSelectedAirport] = useState<ARPRunwayResponse | null>();
  const [navSpec, setNavSpec] = useState<string | undefined>();
  const [selectedRunway, setSelectedRunway] = useState<string | undefined>();
  const [selectedRunwayDirection, setSelectedRunwayDirection] = useState<string | undefined>();
  const [acftCat, setAcftCat] = useState<string | undefined>();
  const [isOpenConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [payload, setPayload] = useState<CreateDesignApiRequest | null>(null);
  const airportRWYData = useAppSelector(getAirportData);
  const rwyData = useAppSelector((state) =>
    getRunwayData(state, selectedAirport ? selectedAirport.id : null)
  );
  const rwyDirectionData = useAppSelector((state) => getRunwayDirectionData(state, selectedRunway));

  const { data: runwayData, isLoading: isLoadingRunwayData } = useGetRunWayQuery({});
  const { data: memberdetail } = useGetOrganisationsMembersQuery(
    currentUser && currentUser.organisationid ? currentUser.organisationid : skipToken
  );

  useEffect(() => {
    if (runwayData) {
      const runwayDataResponse: any = runwayData;
      if (runwayDataResponse.status) {
        dispatch(setRunways(runwayDataResponse.data));
      }
    }
  }, [runwayData]);

  useEffect(() => {
    if (memberdetail) {
      const orgMembersResponse: any = memberdetail;
      if (orgMembersResponse.status) {
        setOrgMembers(orgMembersResponse.data || []);
      }
    }
  }, [memberdetail]);

  useEffect(() => {
    if (addDesignData) {
      const addDesignResponse: any = addDesignData;
      if (addDesignResponse.status) {
        navigate(routes.DesignList);
      }
    }
  }, [addDesignData]);

  const handleChangeProcedureType = (value: string | undefined) => {
    setProceduretype(value);
    form.setFieldsValue({
      nav_type: undefined,
      nav_spec: undefined,
      runway: undefined,
      runway_direction: undefined
    });
    setSelectedAirport(null);
  };

  const handleChangeNavType = (value: string | undefined) => {
    setNavType(value);
    form.setFieldsValue({ nav_spec: undefined });
  };

  const handleAirportChange = (value: string) => {
    const airportData = find(airportRWYData, ['icao', value]);
    setSelectedAirport(airportData || null);
    form.setFieldsValue({ runway: undefined, runway_direction: undefined });
    setSelectedRunway(undefined);
  };

  const handleOnChangeNavSpecification = (value: string | undefined) => {
    setNavSpec(value);
  };

  const handleChangeRunway = (value: string | undefined) => {
    setSelectedRunway(value);
    form.setFieldsValue({
      runway_direction: undefined
    });
  };

  const handleChangeRunwayDirection = (value: string | undefined) => {
    setSelectedRunwayDirection(value);
  };

  const handleChangeACFTCat = (value: string | undefined) => {
    setAcftCat(value);
  };

  const handleBackToHomePage = () => {
    navigate(routes.Home);
  };

  const handleUpdateDesign = async () => {
    if (!payload) return;
    await addDesign({ data: payload, orgId: currentUser.organisationid });
    setOpenConfirmModal(false);
  };

  const handleCancel = () => {
    setOpenConfirmModal(false);
  };

  const handleUpdateDesignFinish = async (values: CreateDesignFormRequest) => {
    if (!currentUser && !selectedAirport) return;
    const reqPayload: CreateDesignApiRequest = {
      name: values.name,
      icao: values.airport,
      airport_name: selectedAirport ? selectedAirport.name : '',
      arplat: selectedAirport ? selectedAirport.latitude.toString() : '',
      arplng: selectedAirport ? selectedAirport.longitude.toString() : '',
      runway: values.runway,
      runway_direction: values.runway_direction,
      nav_type: values.nav_type,
      nav_spec: values.nav_spec,
      acft_cat: values.acft_cat,
      procedure_type: values.procedure_type,
      reason: values.reason,
      goals: values.goals,
      summary: values.summary || '',
      designer_id: values.designer,
      manager_id: currentUser.id,
      designduedate: '2023-10-25',
      reviwer_id: values.reviewer,
      reviwerduedate: '2023-10-25',
      survery_tolerance: values.survery_tolerance || 0,
      terrain_tolerance: values.terrain_tolerance || 0,
      vegetation_tolerance: values.vegetation_tolerance || 0
    };
    setPayload(reqPayload);
    setOpenConfirmModal(true);
  };

  return (
    <>
      <div className="create-new-design">
        <div className="new-design-label">
          <PageHeader
            icon={<ArrowleftIcon />}
            title="Create New Design & Assign"
            onClick={handleBackToHomePage}
          />
        </div>
        <div className="design-content">
          <div className="cfn-code">
            <Text>CFN Code : XXXX_XXXXX_XXXXXX</Text>
          </div>
          <Divider className="design-divider" />
          <Form
            form={form}
            onFinish={handleUpdateDesignFinish}
            layout="vertical"
            autoComplete="off"
            className="design-form"
          >
            <div className="form-main">
              <Row gutter={[64, 20]} className="input-main">
                <Col>
                  <Form.Item
                    rules={[
                      { required: true, message: ' ' },
                      {
                        pattern: /^(?!\s).+(?<!\s)$/,
                        message: 'Enter valid Name of Procedure'
                      }
                    ]}
                    name="name"
                    label="Name of Procedure"
                    className="input-label"
                  >
                    <Input placeholder="Type here" className="form-input" />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name="procedure_type"
                    rules={[{ required: true, message: ' ' }]}
                    label="Type of Procedure Design"
                    className="input-label"
                  >
                    <Select
                      placeholder="Select"
                      showSearch
                      value={proceduretype}
                      onChange={handleChangeProcedureType}
                      suffixIcon={<DownArrow />}
                      className="form-input form-input-select"
                      allowClear
                    >
                      {designTypeOptions.map((type: OptionsType, ind: number) => {
                        return (
                          <Option disabled={type.value !== 'SID'} key={ind} value={type.value}>
                            {type.label}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name="nav_type"
                    rules={[{ required: true, message: ' ' }]}
                    label="Type of Navigation System"
                    className="input-label"
                  >
                    <Select
                      placeholder="Select"
                      showSearch
                      value={navType}
                      onChange={handleChangeNavType}
                      suffixIcon={<DownArrow />}
                      className="form-input form-input-select"
                      allowClear
                    >
                      {navTypeOptions.map((type: OptionsType, ind: number) => {
                        return (
                          <Option disabled={type.isDisabled} key={ind} value={type.value}>
                            {type.label}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[64, 20]} className="input-main">
                <Col>
                  <Form.Item
                    rules={[
                      { required: true, message: ' ' },
                      {
                        pattern: /^(?!\s).+(?<!\s)$/,
                        message: 'Enter valid reason'
                      }
                    ]}
                    name="reason"
                    label="Reason for IFP Design"
                    className="input-label"
                  >
                    <Input placeholder="Type here" className="form-input" />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    rules={[{ required: true, message: ' ' }]}
                    name="airport"
                    label="Airport"
                    className="input-label"
                  >
                    <Select
                      placeholder="Select"
                      showSearch
                      onChange={handleAirportChange}
                      loading={isLoadingRunwayData}
                      value={selectedAirport ? selectedAirport.icao : undefined}
                      filterOption={(inputValue, option) =>
                        // @ts-ignore
                        option.children.toLowerCase().includes(inputValue.toLowerCase())
                      }
                      suffixIcon={<DownArrow />}
                      className="form-input form-input-select"
                      allowClear
                    >
                      {!isEmpty(airportRWYData) &&
                        airportRWYData.map(
                          (arp: { icao: string; id: number; name: string }, index) => {
                            return (
                              <Option value={arp.icao} key={index}>
                                {`${arp.name} (${arp.icao})`}
                              </Option>
                            );
                          }
                        )}
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    rules={[{ required: true, message: ' ' }]}
                    name="nav_spec"
                    label="Navigation Specification"
                    className="input-label"
                  >
                    <Select
                      placeholder="Select"
                      showSearch
                      value={navSpec || null}
                      suffixIcon={<DownArrow />}
                      onChange={handleOnChangeNavSpecification}
                      className="form-input form-input-select"
                      allowClear
                      options={
                        navType
                          ? navType === 'RNP1' || navType === 'RNP2'
                            ? navaidOptions2
                            : navaidOptions1
                          : []
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[64, 20]} className="input-main">
                <Col>
                  <Form.Item
                    rules={[{ required: true, message: ' ' }]}
                    name="runway"
                    label="Runway"
                    className="input-label"
                  >
                    <Select
                      placeholder="Select"
                      showSearch
                      value={selectedRunway}
                      loading={isLoadingRunwayData}
                      suffixIcon={<DownArrow />}
                      className="form-input form-input-select"
                      allowClear
                      onChange={handleChangeRunway}
                    >
                      {!isEmpty(rwyData) &&
                        rwyData.map((rwy: any, index) => {
                          return (
                            <Option value={rwy} key={index}>
                              {rwy}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    rules={[{ required: true, message: ' ' }]}
                    name="runway_direction"
                    label="Runway Direction"
                    className="input-label"
                  >
                    <Select
                      placeholder="Select"
                      showSearch
                      value={selectedRunwayDirection}
                      disabled={!selectedRunway}
                      loading={isLoadingRunwayData}
                      suffixIcon={<DownArrow />}
                      onChange={handleChangeRunwayDirection}
                      className="form-input form-input-select"
                      allowClear
                    >
                      {selectedRunway &&
                        !isEmpty(rwyDirectionData) &&
                        rwyDirectionData.map((rwyD: string, index) => {
                          return (
                            <Option value={rwyD} key={index}>
                              {rwyD}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    rules={[{ required: true, message: ' ' }]}
                    name="acft_cat"
                    label="Aircraft Category"
                    className="input-label"
                  >
                    <Select
                      placeholder="Select"
                      showSearch
                      value={acftCat}
                      onChange={handleChangeACFTCat}
                      suffixIcon={<DownArrow />}
                      className="form-input form-input-select"
                      allowClear
                      options={aircraftCategoryOptions}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Divider className="design-divider2" />
              <Row gutter={[64, 20]} className="input-main">
                <Col>
                  <Form.Item
                    rules={[{ required: true, message: ' ' }]}
                    name="terrain_tolerance"
                    label="Terrain Tolerance"
                    className="input-label input-num"
                  >
                    <InputNumber
                      placeholder="Type here"
                      min={0}
                      max={99}
                      className="form-input"
                      addonAfter="m"
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    rules={[{ required: true, message: ' ' }]}
                    name="vegetation_tolerance"
                    label="Vegetation Tolerance"
                    className="input-label input-num"
                  >
                    <InputNumber
                      placeholder="Type here"
                      min={0}
                      max={20}
                      className="form-input"
                      addonAfter="m"
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    rules={[{ required: true, message: ' ' }]}
                    name="survery_tolerance"
                    label="Survey Tolerance"
                    className="input-label input-num"
                  >
                    <InputNumber
                      placeholder="Type here"
                      min={0}
                      max={20}
                      className="form-input"
                      addonAfter="m"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Divider className="design-divider2" />
              <Row gutter={[64, 20]} className="input-main">
                <Col>
                  <Form.Item
                    rules={[
                      { required: true, message: ' ' },
                      {
                        pattern: /^(?!\s).+(?<!\s)$/,
                        message: 'Enter valid goal'
                      }
                    ]}
                    name="goals"
                    label="Goals to be Achieved"
                    className="input-label"
                  >
                    <Input placeholder="Type here" className="form-input-main" />
                  </Form.Item>
                </Col>
              </Row>
              <Divider className="design-divider2" />
              <Row gutter={[64, 20]} className="input-main">
                <Col>
                  <Form.Item
                    rules={[{ required: true, message: ' ' }]}
                    name="designer"
                    label="Procedure Designer"
                    className="input-label"
                  >
                    <Select
                      placeholder="Select"
                      showSearch
                      suffixIcon={<DownArrow />}
                      className="form-input form-input-select"
                      allowClear
                    >
                      {!isEmpty(orgMembers) &&
                        filter(orgMembers, ['role', 'designer']).map((designer: any, index) => {
                          return (
                            <Option value={designer.id} key={index}>
                              <UserOptionItemComponent userDetail={designer} />
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    rules={[{ required: true, message: ' ' }]}
                    name="reviewer"
                    label="Procedure Reviewer"
                    className="input-label"
                  >
                    <Select
                      placeholder="Select"
                      showSearch
                      suffixIcon={<DownArrow />}
                      className="form-input form-input-select"
                      allowClear
                    >
                      {!isEmpty(orgMembers) &&
                        filter(orgMembers, ['role', 'reviewer']).map((reviewer: any, index) => {
                          return (
                            <Option value={reviewer.id} key={index}>
                              <UserOptionItemComponent userDetail={reviewer} />
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Divider className="design-divider2" />

              <Form.Item
                name="summary"
                rules={[
                  {
                    pattern: /^(?!\s).+(?<!\s)$/,
                    message: 'Enter valid summary'
                  }
                ]}
                label="Summary of Design (Optional)"
                className="input-label"
              >
                <Input placeholder="Type here" className="form-input-main" />
              </Form.Item>
            </div>
            <div>
              <Button
                className="back-btn"
                htmlType="submit"
                type="text"
                size="large"
                onClick={handleBackToHomePage}
              >
                Back
              </Button>
              <Button
                className="create-design-btn"
                type="primary"
                htmlType="submit"
                size="large"
                loading={isAddDesignLoading}
              >
                Create Design & Assign
              </Button>
            </div>
          </Form>
        </div>
      </div>
      <ContentScreenLoaderV1 loading={isAddDesignLoading} />
      <ConfirmationModal
        isOpen={isOpenConfirmModal}
        isLoading={isAddDesignLoading}
        okBtnText="Create"
        description="Are you sure you want to Create a New Design and Assign it to selected Designer & Reviewer ? You will not be able to change the above selection once created."
        handleSuccess={handleUpdateDesign}
        handleCancel={handleCancel}
      />
    </>
  );
};

export default CreateNewDesign;

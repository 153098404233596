import { useEffect, useState } from 'react';
import { filter, isEmpty } from 'lodash';
import { Typography } from 'antd';
import './styles.less';

const { Text } = Typography;

interface NavaidItemProps {
  navaids: any;
}

const NavaidItem = ({ navaids }: NavaidItemProps) => {
  const [ils, setILS] = useState<any[]>([]);
  const [ilsdme, setILSDME] = useState<any[]>([]);
  const [ndb, setNDB] = useState<any[]>([]);
  const [mkr, setMKR] = useState<any[]>([]);
  const [dme, setDME] = useState<any[]>([]);
  const [vorDME, setVORDME] = useState<any[]>([]);

  useEffect(() => {
    if (!isEmpty(navaids)) {
      const ils = filter(navaids, ['type', 'ILS']) || [];
      const ilsdme = filter(navaids, ['type', 'ILS_DME']) || [];
      const ndb = filter(navaids, ['type', 'NDB']) || [];
      const mkr = filter(navaids, ['type', 'MKR']) || [];
      const dme = filter(navaids, ['type', 'DME']) || [];
      const vorDME = filter(navaids, ['type', 'VOR_DME']) || [];
      setILS(ils);
      setILSDME(ilsdme);
      setNDB(ndb);
      setMKR(mkr);
      setDME(dme);
      setVORDME(vorDME);
    }
  }, [navaids]);

  return (
    <div className="ad-data-table-main">
      {!isEmpty(navaids) ? (
        <table className="navaids-table">
          <thead>
            <tr>
              <th>Type</th>
              <th>Identification</th>
              <th>Frequency,Channel</th>
              <th>Hours of operation</th>
              <th>Coordinates</th>
              <th>Elevation</th>
              <th>Rwy Dir</th>
              <th>Remarks</th>
            </tr>
          </thead>
          <tbody>
            {!isEmpty(ilsdme) && (
              <>
                <tr>
                  <td colSpan={8} className="navid-type">
                    Type {'>'} {ilsdme[0].type}
                  </td>
                </tr>
                {ilsdme.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td> {data.type || 'NIL'}</td>
                      <td> {data.identification || 'NIL'}</td>
                      <td>
                        <table>
                          <tbody>
                            {data.details?.LOC?.channel && (
                              <tr>
                                <td>LOC:</td>
                                <td>{data.details.LOC.channel}</td>
                              </tr>
                            )}
                            {data.details?.GP?.channel && (
                              <tr>
                                <td>GP:</td>
                                <td>{data.details.GP.channel}</td>
                              </tr>
                            )}
                            {data.details?.DME?.channel && (
                              <tr>
                                <td>DME:</td>
                                <td>{data.details.DME.channel}</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </td>
                      <td>
                        <table>
                          <tbody>
                            {data.details?.LOC?.hours_of_operation && (
                              <tr>
                                <td>LOC:</td>
                                <td>{data.details.LOC.hours_of_operation}</td>
                              </tr>
                            )}
                            {data.details?.GP?.hours_of_operation && (
                              <tr>
                                <td>GP:</td>
                                <td>{data.details.GP.hours_of_operation}</td>
                              </tr>
                            )}
                            {data.details?.DME?.hours_of_operation && (
                              <tr>
                                <td>DME:</td>
                                <td>{data.details.DME.hours_of_operation}</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </td>
                      <td style={{ width: 150 }}>
                        <table>
                          <tbody>
                            <tr>
                              <td>LOC:</td>
                              <td>
                                <Typography className="data-size-color">
                                  {data.details.LOC.latitude || '-'}
                                </Typography>
                                <Typography className="data-size-color">
                                  {data.details.LOC.longitude || '-'}
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td>GP:</td>
                              <td>
                                <Typography className="data-size-color">
                                  {data.details.GP.latitude || '-'}
                                </Typography>
                                <Typography className="data-size-color">
                                  {data.details.GP.longitude || '-'}
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td>DME:</td>
                              <td>
                                <Typography className="data-size-color">
                                  {data.details.DME.latitude || '-'}
                                </Typography>
                                <Typography className="data-size-color">
                                  {data.details.DME.longitude || '-'}
                                </Typography>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td>
                        <table>
                          <tbody>
                            <tr>
                              <td>LOC:</td>
                              <td>{data.details.LOC.elevation || '-'}</td>
                            </tr>
                            <tr>
                              <td>GP:</td>
                              <td>{data.details.GP.elevation || '-'}</td>
                            </tr>
                            <tr>
                              <td>DME:</td>
                              <td>{data.details.DME.elevation || '-'}</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td>
                        <Text className="data-size-color">{data.rwy_dir || ''}</Text>
                      </td>
                      <td>
                        <table>
                          <tbody>
                            <tr>
                              <td>LOC:</td>
                              <td>{data.details.LOC.remarks || '-'}</td>
                            </tr>
                            <tr>
                              <td>GP:</td>
                              <td>{data.details.GP.remarks || '-'}</td>
                            </tr>
                            <tr>
                              <td>DME:</td>
                              <td>{data.details.DME.remarks || '-'}</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  );
                })}
              </>
            )}
            {!isEmpty(ils) && (
              <>
                <tr>
                  <td colSpan={8} className="navid-type">
                    Type {'>'} {ils[0].type}
                  </td>
                </tr>
                {ils.map((data, index) => (
                  <tr key={index}>
                    <td>{data.type || ''}</td>
                    <td>{data.identification || 'NIL'}</td>
                    <td style={{ width: 80 }}>
                      <table>
                        <tbody>
                          {data.details?.LOC?.channel && (
                            <tr>
                              <td>LOC:</td>
                              <td>{data.details.LOC.channel}</td>
                            </tr>
                          )}
                          {data.details?.GP?.channel && (
                            <tr>
                              <td>GP:</td>
                              <td>{data.details.GP.channel}</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </td>
                    <td style={{ width: 100 }}>
                      <table>
                        <tbody>
                          {data.details?.LOC?.hours_of_operation && (
                            <tr>
                              <td>LOC:</td>
                              <td>{data.details.LOC.hours_of_operation}</td>
                            </tr>
                          )}
                          {data.details?.GP?.hours_of_operation && (
                            <tr>
                              <td>GP:</td>
                              <td>{data.details.GP.hours_of_operation}</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </td>
                    <td style={{ width: 150 }}>
                      <table>
                        <tbody>
                          <tr>
                            <td>LOC:</td>
                            <td>
                              <Text>{data.details.LOC.latitude || '-'}</Text>
                              <Text>{data.details.LOC.longitude || '-'}</Text>
                            </td>
                          </tr>
                          <tr>
                            <td>GP:</td>
                            <td>
                              <Text>{data.details.GP.latitude || '-'}</Text>
                              <Text>{data.details.GP.longitude || '-'}</Text>
                            </td>
                          </tr>
                          <tr>
                            <td>DME:</td>
                            <td>
                              <Text>{data.details.DME.latitude || '-'}</Text>
                              <Text>{data.details.DME.longitude || '-'}</Text>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td>
                      <table>
                        <tbody>
                          {data.details?.LOC?.elevation && (
                            <tr>
                              <td>LOC:</td>
                              <td>{data.details.LOC.elevation}</td>
                            </tr>
                          )}
                          {data.details?.GP?.elevation && (
                            <tr>
                              <td>GP:</td>
                              <td>{data.details.GP.elevation}</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </td>
                    <td>
                      <Text>{data.rwy_dir || ''}</Text>
                    </td>
                    <td>
                      <table>
                        <tbody>
                          {data.details?.LOC?.remarks && (
                            <tr>
                              <td>LOC:</td>
                              <td>{data.details.LOC.remarks}</td>
                            </tr>
                          )}
                          {data.details?.GP?.remarks && (
                            <tr>
                              <td>GP:</td>
                              <td>{data.details.GP.remarks}</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                ))}
              </>
            )}
            {!isEmpty(ndb) && (
              <>
                <tr>
                  <td colSpan={8} className="navid-type">
                    Type {'>'} {ndb[0].type}
                  </td>
                </tr>
                {ndb.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td>{data.class || 'NIL'}</td>
                      <td>{data.identification || 'NIL'}</td>
                      <td>{data?.frequency || 'NIL'}</td>
                      <td>{data?.hours_of_operation || 'NIL'}</td>
                      <td>
                        <Text>{data.latitude || ''}</Text>
                        <Text>{data.longitude || ''}</Text>
                      </td>
                      <td>{data.elevation || 'NIL'}</td>
                      <td>{data?.rwy_dir || 'NIL'}</td>
                      <td>{data?.remarks || 'NIL'}</td>
                    </tr>
                  );
                })}
              </>
            )}
            {!isEmpty(mkr) && (
              <>
                <tr>
                  <td colSpan={8} className="navid-type">
                    Type {'>'} {mkr[0].type}
                  </td>
                </tr>
                {mkr.map((data, index) => (
                  <tr key={index}>
                    <td>{data.type || 'NIL'}</td>
                    <td>{data.identification || 'NIL'}</td>
                    <td>{data.frequency || 'NIL'}</td>
                    <td>{data?.hours_of_operation || 'NIL'}</td>
                    <td>
                      <Text>{data.latitude || ''}</Text>
                      <Text>{data.longitude || ''}</Text>
                    </td>
                    <td>{data.elevation || 'NIL'}</td>
                    <td>{data?.rwy_dir || 'NIL'}</td>
                    <td>{data?.remarks || 'NIL'}</td>
                  </tr>
                ))}
              </>
            )}
            {!isEmpty(dme) && (
              <>
                <tr>
                  <td colSpan={8} className="navid-type">
                    Type {'>'} {dme[0].type}
                  </td>
                </tr>
                {dme.map((data, index) => (
                  <tr key={index}>
                    <td>{data.type || 'NIL'}</td>
                    <td>{data.identification || 'NIL'}</td>
                    <td>{data.channel || 'NIL'}</td>
                    <td>{data?.hours_of_operation || 'NIL'}</td>
                    <td>
                      <Text>{data.latitude || ''}</Text>
                      <Text>{data.longitude || ''}</Text>
                    </td>
                    <td>{data.elevation || 'NIL'}</td>
                    <td>{data?.rwy_dir || 'NIL'}</td>
                    <td>{data?.remarks || 'NIL'}</td>
                  </tr>
                ))}
              </>
            )}
            {!isEmpty(vorDME) && (
              <>
                <tr>
                  <td colSpan={8} className="navid-type">
                    Type {'>'} {vorDME[0].type}
                  </td>
                </tr>
                {vorDME.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td>{data.type || 'NIL'}</td>
                      <td>{data.identification || 'NIL'}</td>
                      <td>
                        <Typography className="data-size-color">{data.channel || ''}</Typography>
                        <Typography className="data-size-color">{data.frequency || ''}</Typography>
                      </td>
                      <td>{data?.hours_of_operation || 'NIL'}</td>
                      <td>
                        <Typography className="data-size-color">{data.latitude || ''}</Typography>
                        <Typography className="data-size-color">{data.longitude || ''}</Typography>
                      </td>
                      <td>{data.elevation || 'NIL'}</td>
                      <td>{data?.rwy_dir || 'NIL'}</td>
                      <td className="data-size-color">{data?.remarks || 'NIL'}</td>
                    </tr>
                  );
                })}
              </>
            )}
          </tbody>
        </table>
      ) : (
        <Text style={{ height: '100%' }} className="flexJustifyAlignCenter ad-no-data">
          No Data
        </Text>
      )}
    </div>
  );
};

export default NavaidItem;

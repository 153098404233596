import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { isEmpty } from 'lodash';

interface CreateWaypointProps {
  handleCreateWaypointLatLng: Function;
  endSelectNavaidWaypoint: string;
  activeLegDesignDataDeparture: any;
  pathTerminator?: string | null;
  handleCallAreaSemiwidth?: Function;
}

const CreateWaypoint = ({
  handleCreateWaypointLatLng,
  endSelectNavaidWaypoint,
  activeLegDesignDataDeparture,
  pathTerminator,
  handleCallAreaSemiwidth
}: CreateWaypointProps) => {
  const map = useMap();

  useEffect(() => {
    if (endSelectNavaidWaypoint === 'createWaypoint') {
      map.on('click', (e: any) => {
        e.originalEvent.preventDefault();
        if (isEmpty(activeLegDesignDataDeparture)) {
          handleCreateWaypointLatLng([e.latlng.lat, e.latlng.lng]);
          if (pathTerminator === 'RF' && handleCallAreaSemiwidth) {
            handleCallAreaSemiwidth([e.latlng.lat, e.latlng.lng]);
          }
        }
      });
    } else {
      map.off('click');
    }
  }, [endSelectNavaidWaypoint]);

  return null;
};
CreateWaypoint.defaultProps = {
  pathTerminator: null,
  handleCallAreaSemiwidth: () => {}
};

export default CreateWaypoint;

import { OptionsType } from 'types/common.types';

export const mapElements: string[] = [
  'windspirals',
  'splaycircles',
  'tolerances',
  'surveyobstacles',
  'terrain',
  'waypoints',
  'navaids',
  'conventional'
];

export const acftCategory: { [key: string]: { min: number; max: number } } = {
  A: { min: 110, max: 121 },
  B: { min: 143, max: 165 },
  C: { min: 182, max: 264 },
  D: { min: 203.5, max: 291.5 },
  DL: { min: 253, max: 302.5 }
};

export const modalDate: { [key: string]: { min: number; max: number } } = {
  igrf: { min: 1590, max: 2024 },
  wmm: { min: 2019, max: 2024 }
};

export const navTypeOptions: OptionsType[] = [
  { label: 'RNP 1', value: 'RNP1', isDisabled: false },
  { label: 'RNP 2', value: 'RNP2', isDisabled: true },
  { label: 'RNAV 1', value: 'RNAV1', isDisabled: false },
  { label: 'RNAV 2', value: 'RNAV2', isDisabled: false }
];

export const designTypeOptions: OptionsType[] = [
  { label: 'SID', value: 'SID' },
  { label: 'APCH', value: 'APCH' },
  { label: 'STAR', value: 'STAR' }
];

export const aircraftCategoryOptions: OptionsType[] = [
  { label: 'A', value: 'A' },
  { label: 'A/B', value: 'B' },
  { label: 'A/B/C', value: 'C' },
  { label: 'A/B/C/D', value: 'D' },
  { label: 'A/B/C/D/DL', value: 'DL' }
];

export const collectDataTabkey = [
  { id: 1, label: 'AD Data', value: 'ad_json' },
  { id: 2, label: 'Airspace Data', value: 'airspace_geometry' }
];

export const fixADdataKey = [
  'geoadministrative',
  'runwayphysicals',
  'declareddistaces',
  'aixmobstacles'
];

export const navOptions: OptionsType[] = [
  { label: 'RNP 1', value: 'RNP1' },
  { label: 'RNP 2', value: 'RNP2' },
  { label: 'RNAV 1', value: 'RNAV1' },
  { label: 'RNAV 2', value: 'RNAV2' },
  { label: 'A-RNP', value: 'ARNP' }
];

export const navaidOptions1: OptionsType[] = [
  { label: 'GNSS', value: 'GNSS' },
  { label: 'DME-DME', value: 'DME-DME' }
];

export const navaidOptions2: OptionsType[] = [{ label: 'GNSS', value: 'GNSS' }];

export const CACFVAVIPathOptions: OptionsType[] = [
  { label: 'Course to Altitude (CA)', value: 'CA', isDisabled: false },
  { label: 'Course to Fix (CF)', value: 'CF', isDisabled: false },
  { label: '(VA)', value: 'VA', isDisabled: true },
  { label: '(VI)', value: 'VI', isDisabled: true }
];

export const CFTFRFPathOptions: OptionsType[] = [
  { label: 'Course to Fix (CA)', value: 'CA', isDisabled: true },
  { label: 'Course to Fix (CF)', value: 'CF', isDisabled: false },
  { label: 'Track to Fix (TF)', value: 'TF', isDisabled: false },
  { label: 'Radius to Fix (RF)', value: 'RF', isDisabled: false }
];

export const CARFTFPathOptions: OptionsType[] = [
  { label: 'Course to Fix (CA)', value: 'CA', isDisabled: true },
  { label: 'Course to Fix (CF)', value: 'CF', isDisabled: false },
  { label: 'Track to Fix (TF)', value: 'TF', isDisabled: false },
  { label: 'Radius to Fix (RF)', value: 'RF', isDisabled: false }
];

export const CFDFPathOptions: OptionsType[] = [
  { label: 'Track to Fix (CA)', value: 'CA', isDisabled: true },
  { label: 'Course to Fix (CF)', value: 'CF', isDisabled: false },
  { label: 'Direct to Fix (DF)', value: 'DF', isDisabled: false }
];

// export const typeOfFixLegOptions3: OptionsType[] = [
//   { label: 'Track to Fix (CA)', value: 'CA', isDisabled: true },
//   { label: 'Course to Fix (CF)', value: 'CF', isDisabled: false },
//   { label: 'Track to Fix (TF)', value: 'TF', isDisabled: false },
//   { label: 'Radius to Fix (RF)', value: 'RF', isDisabled: false }
// ];

export const CFDFTFPathOptions: OptionsType[] = [
  { label: 'Track to Fix (CA)', value: 'CA', isDisabled: true },
  { label: 'Course to Fix (CF)', value: 'CF', isDisabled: false },
  { label: 'Direct to Fix (DF)', value: 'DF', isDisabled: false },
  { label: 'Track to Fix (TF)', value: 'TF', isDisabled: false }
];

export const CFTFPathOptions: OptionsType[] = [
  { label: 'Track to Fix (CA)', value: 'CA', isDisabled: true },
  { label: 'Course to Fix (CF)', value: 'CF', isDisabled: false },
  { label: 'Track to Fix (TF)', value: 'TF', isDisabled: false }
];

export const procedureStatusBtnTexts: { [key: string]: string } = {
  'not-started': 'Start Procedure',
  'in-progress': 'Continue Procedure',
  completed: 'View Procedure'
};

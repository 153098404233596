import { primary } from 'constants/color.constant';
import React from 'react';

const Documentdata = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        color: primary,
        fontSize: 24
      }}
    >
      Coming Soon...
    </div>
  );
};

export default Documentdata;

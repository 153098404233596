import { primary } from 'constants/color.constant';
import { IconProps } from 'types/common.types';

const ArrowleftIconV1 = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66672 10.0002C6.66672 9.81025 6.73089 9.62108 6.86005 9.46692L11.0267 4.46692C11.3209 4.11358 11.8467 4.06525 12.2001 4.36025C12.5534 4.65442 12.6009 5.17942 12.3067 5.53358L8.57672 10.0094L12.1726 14.4778C12.4609 14.8361 12.4042 15.3611 12.0459 15.6494C11.6876 15.9377 11.1634 15.8811 10.8742 15.5228L6.85089 10.5228C6.72839 10.3702 6.66672 10.1852 6.66672 10.0002Z"
        fill={color}
      />
    </svg>
  );
};
ArrowleftIconV1.defaultProps = {
  color: primary,
  width: 20,
  height: 20,
  onClick: () => {}
};

export default ArrowleftIconV1;

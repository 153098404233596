import { Fragment, useState } from 'react';
import { Marker, Polyline, Popup } from 'react-leaflet';
import { isEmpty } from 'lodash';
import { Space, Typography } from 'antd';
import { mapDrawType } from 'constants/collectData.constant';
import { RNAVCNAVIcon } from 'constants/map.constants';
import { FeatureGeometryType } from 'types/common.types';

const { Text } = Typography;

interface RenderRNAVRouteAirspaceProps {
  routeRNAVAirspaceData: any;
}

const RenderRNAVRouteAirspace = ({ routeRNAVAirspaceData }: RenderRNAVRouteAirspaceProps) => {
  const [selectedOverE, setSelectedOverE] = useState<FeatureGeometryType | null>(null);
  if (routeRNAVAirspaceData && isEmpty(routeRNAVAirspaceData?.features)) return null;

  const handlePolygonOver = (e: any, element: any) => {
    e.target.bringToFront();
    setSelectedOverE(element);
  };

  const handlePolygonOut = () => {
    setSelectedOverE(null);
  };

  return (
    // eslint-disable-next-line
    <>
      {
        // eslint-disable-next-line
        routeRNAVAirspaceData.features.map((data: any, index: number) => {
          if (data.geometry.type === mapDrawType.lineString) {
            if (!isEmpty(data.geometry.coordinates)) {
              const coords = data.geometry.coordinates;
              const coordinateData = data.geometry.coordinates.map((points: any) => {
                return [Number(points[1]), Number(points[0])];
              });

              return (
                <Fragment key={index}>
                  <Polyline
                    key={index}
                    positions={coordinateData}
                    pathOptions={{
                      color: '#007900',
                      fillColor: '#007900',
                      fillOpacity: 0.3,
                      weight: selectedOverE?.id === data.id ? 3 : 1
                    }}
                    eventHandlers={{
                      mouseover: (e) => handlePolygonOver(e, data),
                      mouseout: () => handlePolygonOut()
                    }}
                    pane="shadowPane"
                  >
                    {!isEmpty(data.properties) && (
                      <Popup
                        className="point-popup polygon-popup"
                        offset={[0, 0]}
                        minWidth={232}
                        maxHeight={226}
                      >
                        <Space direction="vertical">
                          <div>
                            <Text className="market-title">Designator Number</Text>
                            <Text className="market-data">
                              {data.properties.designatorNumber || '-'}
                            </Text>
                          </div>
                          <div>
                            <Text className="market-title">Designator Prefix</Text>
                            <Text className="market-data">
                              {data.properties.designatorPrefix || '-'}
                            </Text>
                          </div>
                          <div>
                            <Text className="market-title">Designator Second Letter</Text>
                            <Text className="market-data">
                              {data.properties.designatorSecondLetter || '-'}
                            </Text>
                          </div>
                          <div>
                            <Text className="market-title">Multiple Identifier</Text>
                            <Text className="market-data">
                              {data.properties.multipleIdentifier || '-'}
                            </Text>
                          </div>
                        </Space>
                      </Popup>
                    )}
                  </Polyline>
                  {coords.map((c: any) => {
                    return (
                      <Marker key={index + 1 + c[0]} position={[c[1], c[0]]} icon={RNAVCNAVIcon} />
                    );
                  })}
                </Fragment>
              );
            }
          }
        })
      }
    </>
  );
};

export default RenderRNAVRouteAirspace;

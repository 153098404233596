import { white } from 'constants/color.constant';
import { IconProps } from 'types/common.types';

const ToleranceIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="6.5" y="6.5" width="11" height="11" rx="0.5" stroke={color} />
      <path d="M12 3.5V20.5" stroke={color} strokeLinecap="round" />
      <path d="M3.5 12H20.5" stroke={color} strokeLinecap="round" />
    </svg>
  );
};
ToleranceIcon.defaultProps = {
  color: white,
  width: 24,
  height: 24,
  onClick: () => {}
};

export default ToleranceIcon;

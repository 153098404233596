import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import { WMTSLayer } from 'constants/collectData.constant';
import 'scripts/wmts';
import { GET_GEOSERVER_WMTS_URL } from 'utils/apiUrls';

interface TerrainWMTSTileLayerProps {
  isTerrainDataViewonmap: boolean;
  WMTSStyle: string | undefined | null;
  WMTSPane?: string;
}
const TerrainWMTSTileLayer = ({
  WMTSStyle,
  isTerrainDataViewonmap,
  WMTSPane
}: TerrainWMTSTileLayerProps) => {
  const map = useMap();
  const wmsLayerGroup = L.featureGroup();

  useEffect(() => {
    if (!isTerrainDataViewonmap && !WMTSStyle) return;

    const wmtsParams = {
      layer: WMTSLayer,
      format: 'image/png8',
      transparent: true,
      maxZoom: 15,
      style: WMTSStyle,
      pane: WMTSPane,
      opacity: 1,
      tilematrixset: 'EPSG:900913',
      height: 256,
      width: 256
    };

    const wmtslayer = L.tileLayer
      // @ts-ignore
      .wmts(GET_GEOSERVER_WMTS_URL, wmtsParams)
      .addTo(map);
    wmsLayerGroup.addLayer(wmtslayer);
    wmsLayerGroup.addTo(map);

    // eslint-disable-next-line
    return () => {
      wmsLayerGroup.removeFrom(map);
    };
  }, [WMTSStyle, isTerrainDataViewonmap]);

  return null;
};

TerrainWMTSTileLayer.defaultProps = {
  WMTSPane: 'tilePane'
};

export default TerrainWMTSTileLayer;

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { capitalize, isEmpty, upperFirst } from 'lodash';
import { Avatar, Button, Dropdown, MenuProps, Typography } from 'antd';
import LogOutIcon from 'assets/svg/LogOutIcon';
import SatsureSkiesBlack from 'assets/svg/SatsureSkiesBlack';
import UserIcon from 'assets/svg/UserIcon';
import NotificationIcon from 'assets/svg/NotificationIcon';
import { routes } from 'constants/pageRoutes.constants';
import { ACCESS_TOKEN, REFRESH_TOKEN } from 'constants/common.constant';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { getStorageValue, clearData } from 'services/axiosClient';
import { UserRoleText } from 'store/users/types';
import { designapi } from 'services/apis/designsApi';
import { useLogoutMutation, userapi } from 'services/apis/userApi';
import { shortLabel } from 'utils/utils';
import { decryptValue } from 'utils/decodeEncodeData';
import './styles.less';

const { Text } = Typography;

const HeaderV1 = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const accessToken = getStorageValue(ACCESS_TOKEN);
  const refreshToken = getStorageValue(REFRESH_TOKEN);
  const { userSession } = useAppSelector((state) => state.user);
  const [firstName, setFirstName] = useState<string | null>(null);
  const [lastName, setLastName] = useState<string | null>(null);
  const [compressName, setCompressName] = useState<string | null>(null);
  const [logout] = useLogoutMutation();

  useEffect(() => {
    if (!isEmpty(userSession)) {
      if (userSession.firstname && userSession.lastname) {
        setFirstName(upperFirst(decryptValue(userSession.firstname)));
        setLastName(upperFirst(decryptValue(userSession.lastname)));
        setCompressName(
          shortLabel(`${decryptValue(userSession.firstname)} ${decryptValue(userSession.lastname)}`)
        );
      } else {
        setFirstName(upperFirst(decryptValue(userSession.email)));
        setCompressName(capitalize(userSession.email.charAt(0)));
      }
    }
  }, []);

  const handleNavigate = () => {
    if (!isEmpty(accessToken)) {
      navigate(routes.Home);
    } else {
      navigate(routes.LoginUrl, { replace: true });
    }
  };

  const handleLogout = async () => {
    if (refreshToken) {
      logout(refreshToken);
      dispatch(userapi.util.resetApiState());
      dispatch(designapi.util.resetApiState());
    }
    await clearData();
    navigate(routes.LoginUrl, { replace: true });
  };

  const items: MenuProps['items'] = [
    {
      label: (
        <div className="user-detail">
          <Avatar>{compressName || '-'}</Avatar>
          <div>
            <Text>
              {firstName || '-'} {lastName}
            </Text>
            <Text>{userSession ? UserRoleText[userSession.role] : '-'}</Text>
          </div>
        </div>
      ),
      key: '0'
    },
    {
      label: (
        <div className="profile-setting" onClick={handleLogout}>
          <LogOutIcon />
          <Text>Log out</Text>
        </div>
      ),
      key: '1'
    }
  ];

  return (
    <div className="header-div">
      <SatsureSkiesBlack width={97} height={37} onClick={handleNavigate} />
      <Text className="header-title">CFNPD</Text>
      <div className="header-icons">
        <Button type="text" shape="circle" icon={<NotificationIcon />} />
        <Dropdown
          menu={{ items }}
          trigger={['click']}
          className="user-menu"
          overlayClassName="user-menu-item"
        >
          <Button
            aria-label="user-icon"
            type="text"
            shape="circle"
            icon={<UserIcon />}
            onClick={(e) => e.preventDefault()}
          />
        </Dropdown>
      </div>
    </div>
  );
};

export default HeaderV1;

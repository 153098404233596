import { Typography } from 'antd';
import './styles.less';

const { Link } = Typography;

interface ReportCardTitleProps {
  title: string;
  handleOnclick: (id: string) => void;
  id?: string;
  classname?: string;
}

const ReportCardTitle = ({ title, id, handleOnclick, classname }: ReportCardTitleProps) => {
  return (
    <div className={`report-sidebar-card ${classname}`}>
      <Link href={id} onClick={() => handleOnclick(id!)}>
        {title}
      </Link>
    </div>
  );
};
ReportCardTitle.defaultProps = {
  id: '',
  classname: ''
};

export default ReportCardTitle;

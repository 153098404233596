import { OptionsType } from 'types/common.types';

export const airspaceClassOptions: OptionsType[] = [
  { value: 'A', label: 'CLASS A' },
  { value: 'B', label: 'CLASS B' },
  { value: 'C', label: 'CLASS C' },
  { value: 'D', label: 'CLASS D' }
];

export const airspaceClass: { [key: string]: string } = {
  A: 'CLASS A',
  B: 'CLASS B',
  C: 'CLASS C',
  D: 'CLASS D'
};

export const aeroReportLists: { id: string; title: string }[] = [
  { id: '#datasource', title: '1. Data Sources, Reference & Tools' },
  { id: '#procedure', title: '2. Procedure Details' },
  { id: '#general', title: '3. General Data' },
  { id: '#ifpdesign', title: '4. IFP Design Constraints & Narrative' },
  { id: '#profileview', title: '5. Plan & Profile View' },
  { id: '#sectoraltitude', title: '6. Minimum Sector Altitude' },
  { id: '#sidroute', title: '7. SID Route Description' },
  { id: '#obstacleoischeck', title: '8. Obstacle Assessment - OIS Check' },
  { id: '#departureturn', title: '9. Departure - Turn Parameter' },
  { id: '#waypointpa', title: '10. Waypoint data & Protection Area' },
  { id: '#obstacleanalysis', title: '11. Obstacle Analysis' },
  { id: '#codingtable', title: '12. Coding Table' },
  { id: '#significant', title: '13. Significant Obstacle Data' }
];

import { white } from 'constants/color.constant';
import { IconProps } from 'types/common.types';

const RoundCircleIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="6.75" stroke={color} />
      <ellipse cx="12" cy="11.9993" rx="3.27273" ry="3.27273" stroke={color} strokeWidth="0.5" />
      <path
        d="M6 12C7.54545 11.4545 10.9091 9.49091 12 6"
        stroke={color}
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 12C16.4545 12.5455 13.0909 14.5091 12 18"
        stroke={color}
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 12C16.4545 11.4545 13.0909 9.49091 12 6"
        stroke={color}
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 12C7.54545 12.5455 10.9091 14.5091 12 18"
        stroke={color}
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 9L12 6.75L13.5 9L12 8.625L10.5 9Z"
        fill={color}
        stroke={color}
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 15L12 17.25L10.5 15L12 15.375L13.5 15Z"
        fill={color}
        stroke={color}
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M9 13.5L6 12L9 10.5L8.5 12L9 13.5Z"
        fill={color}
        stroke={color}
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M15 10.5L18 12L15 13.5L15.5 12L15 10.5Z"
        fill={color}
        stroke={color}
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
    </svg>
  );
};
RoundCircleIcon.defaultProps = {
  color: white,
  width: 24,
  height: 24,
  onClick: () => {}
};

export default RoundCircleIcon;

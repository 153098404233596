import { Table, TableProps, Typography } from 'antd';
import { typocolor } from 'constants/color.constant';

interface ObstacleOISTableProps {
  obsOISData: any;
}

const { Text } = Typography;

const ObstacleOISLegTable = ({ obsOISData }: ObstacleOISTableProps) => {
  const defaultColumns: TableProps<any>['columns'] = [
    {
      title: 'Obstacle',
      key: 'obstacle',
      align: 'left',
      className: 'table-col-data',
      render: (value, record) => (record[0] === 'terrain' ? 'Terrain (DTM)' : record[0])
    },
    {
      title: 'Obstacle Elevation + Tol (m)',
      key: 'ele',
      align: 'center',
      className: 'table-col-data',
      render: (value, record) => (record[1] ? record[1].toFixed(1) : '')
    },
    {
      title: 'Distance from DER (NM)',
      key: 'distance',
      align: 'center',
      className: 'table-col-data',
      render: (value, record) => (record[2] ? record[2].toFixed(2) : '')
    },
    {
      title: 'OIS Elevation',
      key: 'oisele',
      align: 'center',
      className: 'table-col-data',
      render: (value, record) => (record[3] ? record[3].toFixed(3) : '')
    },
    {
      title: 'Remark',
      key: 'remark',
      align: 'center',
      className: 'table-col-data',
      render: (value, record) =>
        record[4] ? (
          <Text style={{ color: typocolor }}>Obstacle penetrates the OIS Surface</Text>
        ) : (
          <Text type="danger">Obstacle does not penetrate the OIS surface</Text>
        )
    }
  ];

  return (
    <Table
      columns={defaultColumns}
      dataSource={obsOISData}
      pagination={false}
      rowKey={(record) => `${record[1]}-${(Math.random() + 1).toString(36).substring(7)}`}
      rowClassName={() => 'table-row-data'}
    />
  );
};

export default ObstacleOISLegTable;

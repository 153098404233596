import { ReactNode } from 'react';
import { Button, Typography } from 'antd';

interface ReportContentCardProps {
  title: string;
  icon: ReactNode | null;
  infoText?: string | null;
  classname?: string;
}

const { Text } = Typography;

const ReportContentCard = ({ title, icon, classname, infoText }: ReportContentCardProps) => {
  return (
    <div className={`report-data ${classname}`}>
      <div>
        {icon && <Button disabled type="text" shape="circle" icon={icon} className="icon-btn" />}
        <Text className="report-title">{title}</Text>
      </div>
      {infoText && <Text className="report-title">{infoText}</Text>}
    </div>
  );
};
ReportContentCard.defaultProps = {
  classname: '',
  infoText: null
};

export default ReportContentCard;

import { IconProps } from 'types/common.types';

const ExpandIcon = ({ height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z"
        fill="transparent"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6678 6.68366C17.0345 6.68566 17.3312 6.98299 17.3312 7.34966L17.3332 10.6663C17.3338 11.0343 17.0352 11.3337 16.6672 11.3337H16.6665C16.2985 11.3337 16.0005 11.035 15.9998 10.6677L15.9985 8.94433L13.8045 11.1383C13.6745 11.2683 13.5038 11.3337 13.3332 11.3337C13.1632 11.3337 12.9925 11.2683 12.8618 11.1383C12.6018 10.8777 12.6018 10.4557 12.8618 10.1957L15.0485 8.00899L13.3305 8.00033C12.9618 7.99833 12.6645 7.69766 12.6665 7.33033C12.6685 6.96299 12.9672 6.66699 13.3332 6.66699H13.3365L16.6678 6.68366ZM10.1954 12.8621C10.4561 12.6014 10.8774 12.6014 11.1381 12.8621C11.3988 13.1227 11.3988 13.5441 11.1381 13.8047L8.94342 15.9994L10.6668 16.0001C11.0354 16.0001 11.3334 16.2987 11.3334 16.6674C11.3328 17.0354 11.0348 17.3334 10.6668 17.3334H10.6661L7.34942 17.3314C6.98276 17.3314 6.68542 17.0347 6.68342 16.6681L6.66676 13.3367C6.66476 12.9687 6.96209 12.6687 7.33009 12.6667H7.33342C7.70009 12.6667 7.99809 12.9627 8.00009 13.3301L8.00876 15.0487L10.1954 12.8621Z"
        fill="#42444A"
      />
    </svg>
  );
};
ExpandIcon.defaultProps = {
  width: 24,
  height: 24,
  onClick: () => {}
};

export default ExpandIcon;

import { blue, green, orange, primary, red } from './color.constant';

// Async variable names.
export const ACCESS_TOKEN = 'CFNPD_ACCESS_TOKEN';
export const REFRESH_TOKEN = 'CFNPD_REFRESH_TOKEN';
export const AES_KEY = 'CFNPD_AES_KEY';
export const RSA_KEY = 'CFNPD_RSA_KEY';
export const USER_ROLE = 'CFNPD_USER_ROLE';

// error
export const TOKEN_EXPIRE_ERROR = 'Token Already Expired';

// Date format
export const DateFormat1 = 'Do MMMM YYYY';
export const DateFormat2 = 'D MMM YYYY';
export const DateFormat3 = 'YYYY-MM-DD';
export const DateFormat4 = 'D MMM YYYY, HH:mm:ss';
export const DateFormat5 = 'MM/DD/YYYY HH:mm:ss';
export const DateFormat6 = 'DD MMMM YYYY';
export const DateFormat7 = 'DD-MM-YYYY';
export const DayMonthFormat = 'DD MMMM';
export const YearFormat = 'YYYY';
export const TimeFormat = 'HH:mm:ss';

// Google map
export const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GMAP_KEY;
export const defaultCenter: any = [20.5937, 78.9629];
export const mapStyleWithoutLabel = [{ elementType: 'labels', stylers: [{ visibility: 'off' }] }];

export enum CoordinateType {
  Point = 'Point',
  MultiPoint = 'MultiPoint',
  Polygon = 'Polygon',
  MultiPolygon = 'MultiPolygon',
  LineString = 'LineString',
  MultilineString = 'MultiLineString'
}

export interface ProjectTabType {
  title: string;
  value: string;
}

export const DesignStatusColor: { [key: string]: string } = {
  all: primary,
  designing: orange,
  Designing: orange,
  assigned: red,
  Assigned: red,
  inreview: blue,
  'In Review': blue,
  Reviewed: green,
  reviewed: green
};

export const DesignStatusBackgoundColor: { [key: string]: string } = {
  all: primary,
  designing: 'rgba(242, 153, 74, 0.15)',
  Designing: 'rgba(242, 153, 74, 0.15)',
  assigned: 'rgba(235, 87, 87, 0.15)',
  Assigned: 'rgba(235, 87, 87, 0.15)',
  inreview: 'rgba(47, 128, 237, 0.15)',
  'In Review': 'rgba(47, 128, 237, 0.15)',
  Reviewed: 'rgba(39, 174, 96, 0.15)',
  reviewed: 'rgba(39, 174, 96, 0.15)'
};

export const vectorColors: string[] = [
  '#043E95',
  '#1F76B5',
  '#1DA7C6',
  '#67C9D7',
  '#A7E1EA',
  '#909090',
  '#9C27DD',
  '#267300',
  '#0070D7',
  '#C25D00',
  '#C98544',
  '#CD00B9',
  '#0066FF',
  '#6398FE',
  '#00DE9D',
  '#00FFFF',
  '#D20000',
  '#A10000'
];

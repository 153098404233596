import { Button, Typography } from 'antd';
import { isEmpty } from 'lodash';
import CloseIcon from 'assets/svg/CloseIcon';
import ExpandIcon from 'assets/svg/ExpandIcon';
import { primary } from 'constants/color.constant';
import CollectADDataTable from 'components/CollectADDataTable/CollectADDataTable';
import AerodromeObsaclesTable from 'components/DataLayersTable/AerodromeObsaclesTable';
import DesignSurveyObsaclesTable from 'components/DataLayersTable/DesignSurveyObsaclesTable';
import { SurveyObstacleType } from 'store/design/types';
import { CollectDataType } from 'types/common.types';

const { Text } = Typography;

interface RenderSelectedToolHeaderDataProps {
  isShowAeroTable: boolean;
  isObsTerrainFullScreenTable: boolean;
  showDataLayerId: string | null;
  availableADData: any;
  adItem: CollectDataType | undefined;
  setShowAeroTable: any;
  surveyObstacleData: SurveyObstacleType[] | [];
  handleShowAeroTable: (isShow: boolean) => void;
  handleShowFullScreenTable: (isShow: boolean) => void;
}

const RenderSelectedToolHeaderData = ({
  isShowAeroTable,
  showDataLayerId,
  availableADData,
  adItem,
  setShowAeroTable,
  surveyObstacleData,
  isObsTerrainFullScreenTable,
  handleShowAeroTable,
  handleShowFullScreenTable
}: RenderSelectedToolHeaderDataProps) => {
  return isShowAeroTable && showDataLayerId === 'aixmobstacles' ? (
    <AerodromeObsaclesTable
      handleShowAeroTable={handleShowAeroTable}
      obstacleData={
        showDataLayerId === 'aixmobstacles' && !isEmpty(availableADData[showDataLayerId])
          ? availableADData[showDataLayerId]
          : []
      }
      handleShowFullScreenTable={handleShowFullScreenTable}
    />
  ) : isShowAeroTable &&
    !isEmpty(showDataLayerId) &&
    !isEmpty(adItem) &&
    showDataLayerId !== 'aixmobstacles' ? (
    <div className="aerodrome-details">
      <div className="aerodrome-header">
        <div>
          <Text>{adItem && adItem.codeLabel}</Text>
          <Button
            type="text"
            icon={<ExpandIcon />}
            onClick={() => handleShowFullScreenTable(true)}
            className="back-icon-btn"
          />
        </div>
        <Button
          type="text"
          shape="circle"
          icon={<CloseIcon color={primary} />}
          className="back-icon-btn"
          onClick={() => setShowAeroTable(false)}
        />
      </div>
      <div className="ad-data-layers">
        <CollectADDataTable availableDataActiveCodeId={showDataLayerId} activeTab={null} />
      </div>
    </div>
  ) : isShowAeroTable && showDataLayerId === 'surveyobstacle' ? (
    <DesignSurveyObsaclesTable
      obstacleData={surveyObstacleData}
      handleShowAeroTable={handleShowAeroTable}
      isObsTerrainFullScreenTable={isObsTerrainFullScreenTable}
      handleShowFullScreenTable={handleShowFullScreenTable}
    />
  ) : (
    // eslint-disable-next-line
    <></>
  );
};

export default RenderSelectedToolHeaderData;

import { upperCase } from 'lodash';
import { Col, Row, Switch, Typography } from 'antd';
import CustomDivider from 'components/core/CustomDivider';
import { OAlegdata } from 'store/design/types';
import './styles.less';

const { Text } = Typography;

interface ObstacleAnalysisSwitchProps {
  data: string;
  handleChangeOALegSwitch: (oacheckedLeg: string, legid: number) => void;
  isCheckedOALegSwitch: boolean;
  isShowHideObstacleLayer: boolean;
  isShowHideTerrainLayer: boolean;
  handleShowHideObstacleLayer: (isShow: boolean) => void;
  handleShowHideTerrainLayer: (isShow: boolean) => void;
  currentLegData: any;
  legOAData: OAlegdata[] | [];
}
const ObstacleAnalysisSwitch = ({
  data,
  handleChangeOALegSwitch,
  handleShowHideObstacleLayer,
  handleShowHideTerrainLayer,
  isShowHideObstacleLayer,
  isShowHideTerrainLayer,
  isCheckedOALegSwitch,
  currentLegData,
  legOAData
}: ObstacleAnalysisSwitchProps) => {
  return (
    <div className="oa-leg-content-main">
      <div className={isCheckedOALegSwitch ? 'pa-leg-content' : 'pa-leg-content oa-content-main'}>
        <Text className="pa-leg-label">{upperCase(data)}</Text>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Text
            className={
              isCheckedOALegSwitch ? 'show-oa-content checked-oa-content' : 'show-oa-content'
            }
          >
            Obstacle Analysis Results
          </Text>
          <Switch
            size="small"
            className="oa-switch"
            onChange={() => handleChangeOALegSwitch(data, currentLegData.id)}
            checked={isCheckedOALegSwitch}
          />
        </div>
      </div>
      {isCheckedOALegSwitch && (
        <div style={{ padding: '0 16px 16px' }}>
          <Row justify="space-between" style={{ paddingBottom: 16 }}>
            <Col span={11} className="leg-content">
              <div>
                <div>No. of Obstacles</div>
                <div>{legOAData.length}</div>
              </div>
              <div>
                <div>Penetration</div>
                <div style={{ color: '#27AE60' }}>-</div>
              </div>
            </Col>
            <Col>
              <CustomDivider type="vertical" className="leg-divider" />
            </Col>
            <Col span={11} className="leg-content">
              <div>
                <div>Highest Obstacle Elev.</div>
                <div style={{ color: '#27AE60' }}>0 ft</div>
              </div>
              <div>
                <div>Highest Terrain Elev.</div>
                <div style={{ color: '#27AE60' }}>0 ft</div>
              </div>
            </Col>
          </Row>
          <Row justify="space-between">
            <div className="" style={{ display: 'flex', gap: 16 }}>
              <Text className="obstacle-switch-label">Obstacle Analysis Results</Text>
              <Switch
                size="small"
                className="oa-switch"
                checked={isShowHideObstacleLayer}
                onChange={() => handleShowHideObstacleLayer(!isShowHideObstacleLayer)}
              />
            </div>
            <div style={{ display: 'flex', gap: 16 }}>
              <Text className="obstacle-switch-label">Terrain Analysis Results</Text>
              <Switch
                size="small"
                className="oa-switch"
                checked={isShowHideTerrainLayer}
                onChange={() => handleShowHideTerrainLayer(!isShowHideTerrainLayer)}
              />
            </div>
          </Row>
        </div>
      )}
    </div>
  );
};

export default ObstacleAnalysisSwitch;

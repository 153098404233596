// @ts-nocheck
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import { Form, Input, Table, Typography } from 'antd';
import PlusCircleIcon from 'assets/svg/PlusCircleIcon';
import DeleteIcon from 'assets/svg/DeleteIcon';
import { secondary } from 'constants/color.constant';

const { Text } = Typography;

type InputRef = typeof Input;
type FormInstance<T> = typeof Form<T>;

const EditableContext = createContext<FormInstance<any> | null>(null);

interface ItemType {
  key: React.Key;
  elv: string | null;
  moc: number | null;
  msa: string | null;
  lat_long: string | null;
  obs: string | null;
  obs_src: string | null;
  sector: string | null;
}

interface EditableRowProps {
  index: number;
}

export interface AltDataType {
  key: React.Key;
  elv: string | null;
  lat_long: string | null;
  moc: string | number | null;
  msa: string | null;
  obs: string | null;
  obs_src: string | null;
  sector: string | null;
}

const EditableRow = ({ index, ...props }: EditableRowProps) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr key={index} {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof ItemType;
  record: ItemType;
  handleSave: (record: ItemType) => void;
}

const EditableCell = ({
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}: EditableCellProps) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      // eslint-disable-next-line
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item style={{ margin: 0 }} name={dataIndex}>
        <Input
          autoComplete="off"
          placeholder="Type here"
          ref={inputRef}
          onPressEnter={save}
          onBlur={save}
        />
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

interface MinSectorAltitudeTableProps {
  altData: AltDataType[] | [];
  handleSaveData: (data: AltDataType[] | []) => void;
  handleSaveRemarkData: (data: string) => void;
  remarkSec6: string;
}

const MinSectorAltitudeTable = ({
  altData,
  remarkSec6,
  handleSaveData,
  handleSaveRemarkData
}: MinSectorAltitudeTableProps) => {
  const [dataSource, setDataSource] = useState<AltDataType[] | []>([]);
  const [count, setCount] = useState(1);
  const [isEditRemark, setEditRemark] = useState<boolean>(false);
  const [remark, setRemark] = useState<string>('Type here');

  useEffect(() => {
    setDataSource([...altData]);
  }, [altData]);
  useEffect(() => {
    setRemark(remarkSec6);
  }, [remarkSec6]);

  const handleDelete = (key: React.Key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
  };

  const altColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: 'Navigation AID / Centre : ARP',
      align: 'left',
      key: 'tol/ac',
      className: 'table-col-data table-tol-col-data',
      children: [
        {
          title: '',
          key: 'action',
          align: 'center',
          className: 'table-col-data remark-row-data delete-row',
          render: (_, record: { key: React.Key }) =>
            dataSource.length >= 1 && record.key !== 0 ? (
              <DeleteIcon height={20} width={20} onClick={() => handleDelete(record.key)} />
            ) : null
        },
        {
          title: 'Sector / Distance',
          dataIndex: 'sector',
          key: 'sector',
          align: 'center',
          className: 'table-col-data',
          width: 145,
          editable: true
        },
        {
          title: 'Controlling Obstacle',
          dataIndex: 'obs',
          key: 'obs',
          align: 'center',
          className: 'table-col-data',
          editable: true
        },
        {
          title: 'Latitude / Longitude',
          dataIndex: 'lat_long',
          key: 'lat_long',
          align: 'center',
          className: 'table-col-data',
          editable: true
        },
        {
          title: 'Elevation (ft)',
          dataIndex: 'elv',
          key: 'elv',
          align: 'center',
          className: 'table-col-data',
          editable: true
        },
        {
          title: 'MOC (ft)',
          dataIndex: 'moc',
          key: 'moc',
          align: 'center',
          className: 'table-col-data',
          editable: true
        },
        {
          title: 'MSA (ft)',
          dataIndex: 'msa',
          key: 'msa',
          align: 'center',
          className: 'table-col-data',
          editable: true
        },
        {
          title: 'Obstacle Source',
          dataIndex: 'obs_src',
          key: 'obs_src',
          align: 'center',
          className: 'table-col-data',
          editable: true
        }
      ]
    }
  ];

  const handleSave = (row: AltDataType) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row
    });
    setDataSource(newData);
    handleSaveData(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell
    }
  };

  const mapColumns = (col) => {
    // if (!col.editable) {
    //   return col;
    // }
    const newCol = {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave
      })
    };
    if (col.children) {
      newCol.children = col.children.map(mapColumns);
    }
    return newCol;
  };

  const columns = altColumns.map(mapColumns);

  const handleAdd = () => {
    const newData: AltDataType = {
      key: count,
      sector: 'Type Here',
      obs: 'Type Here',
      lat_long: 'Type Here',
      elv: 'Type Here',
      moc: 'Type Here',
      msa: 'Type Here',
      obs_src: 'Type Here'
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };

  return (
    <Table
      columns={columns}
      components={components}
      dataSource={dataSource}
      pagination={false}
      rowClassName={() => 'table-row-data table-tol-row-data'}
      // eslint-disable-next-line
      summary={() => {
        return (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell
                align="left"
                index={0}
                colSpan={8}
                className="table-row-data  remark-row-data table-tol-col-data"
              >
                <div className="add-new-row">
                  <PlusCircleIcon onClick={handleAdd} />
                  <Text onClick={handleAdd}>Add new Row</Text>
                </div>
              </Table.Summary.Cell>
            </Table.Summary.Row>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} className="table-row-data remark-row bd-right">
                REMARK
              </Table.Summary.Cell>
              <Table.Summary.Cell
                index={1}
                colSpan={7}
                className="table-row-data remark-row remark-row-data"
              >
                {isEditRemark ? (
                  <Input
                    autoComplete="off"
                    placeholder="Type here"
                    value={remark || undefined}
                    onChange={(e) => {
                      setRemark(e.target.value);
                      handleSaveRemarkData(e.target.value);
                    }}
                    onBlur={() => setEditRemark(false)}
                    // onPressEnter={(e) => {
                    //   setRemark(e.target.value);
                    //   handleSaveRemarkData(e.target.value);
                    //   setEditRemark(false);
                    // }}
                  />
                ) : (
                  <div
                    style={{
                      width: '100%',
                      cursor: 'pointer',
                      color: secondary,
                      fontWeight: 500,
                      fontSize: 14,
                      lineHeight: '19.12px'
                    }}
                    onClick={() => setEditRemark(!isEditRemark)}
                  >
                    {!isEmpty(remark) ? remark : 'Type Here'}
                  </div>
                )}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        );
      }}
    />
  );
};

export default MinSectorAltitudeTable;

import { createSelector } from '@reduxjs/toolkit';
import { filter, find, flatMap, isEmpty, keys, uniq, uniqBy } from 'lodash';
import { RootState } from '..';
import { designSlice } from './slice';
import { DesignResponse, RunwayResponse } from './types';
import { OptionsType } from 'types/common.types';

export const {
  setADData,
  setAirspaceData,
  setRunways,
  setDesigns,
  setAvailableADData,
  setAvailableAirspaceData,
  setSelectedDesign,
  setSelectWaypointData,
  setSelectDesignLegData,
  setSelectDesignLegDataOMNI,
  setSelectDesignLegDataDeparture,
  setTemperatureData,
  setDesignAllMetaData,
  setDesignProcedurelist,
  setDesignProcedureVersionsData,
  setIsLoadingAddSurveyFileUpload,
  setIsLoadingSilentInfoBtwPoint,
  setIsLoadingSilentDesignProcedureVersions,
  setIsLoadingDesignMetadataByMetaid,
  clearDesign
} = designSlice.actions;
export default designSlice.reducer;

const designsData = (state: RootState) => state.design.designs;
const statusTab = (state: RootState, name: string) => name;
const selectRunwayData = (state: RootState) => state.design.runways;
const selectedRunway = (state: RootState, rwy: string | undefined) => rwy;
const selectedAirportId = (state: RootState, airportId: number | null) => airportId;
const selectedAirportName = (state: RootState, airportName: string | null) => airportName;

export const modifyDesignList = (designData: DesignResponse[]) => {
  if (isEmpty(designData)) return [];

  const obj: any = {};
  const designList: DesignResponse[] = [];

  designData.forEach((item: any) => {
    if (!obj[item.icao]) obj[item.icao] = [];
    obj[item.icao].push(item);
  });

  keys(obj).map((icaokey) => {
    if (obj[icaokey].length === 1) {
      designList.push(obj[icaokey][0]);
    }

    if (obj[icaokey].length > 1) {
      const expandableData = obj[icaokey];
      const firstData = expandableData.shift();

      // eslint-disable-next-line
      const expandedData = expandableData.map(({ airport_name, ...rest }: DesignResponse) => ({
        ...rest
      }));
      designList.push({ ...firstData, children: expandedData });
    }
    return designList;
  });
  return designList;
};

export const getDesignData = createSelector(
  [designsData, statusTab],
  (designData: DesignResponse[], statusTab: string) => {
    if (isEmpty(designData)) return [];

    if (statusTab !== 'all') {
      const filteredLayer = filter(designData, ['status', statusTab]);
      return modifyDesignList(filteredLayer);
    }
    return modifyDesignList(designData);
  }
);

export const getAirportData = createSelector([selectRunwayData], (runwayData: RunwayResponse[]) => {
  if (isEmpty(runwayData)) return [];
  return runwayData.map((runway: RunwayResponse) => {
    return {
      id: runway.id,
      icao: runway.icao,
      name: runway.name,
      latitude: runway.latitude,
      longitude: runway.longitude
    };
  });
});

export const getRunwayData = createSelector(
  [selectRunwayData, selectedAirportId],
  (runwayData: RunwayResponse[], selectedAirportId: number | null) => {
    if (isEmpty(runwayData)) return [];
    if (selectedAirportId) {
      const selArp = find(runwayData, ['id', selectedAirportId]);
      if (selArp) {
        return Object.keys(selArp.runways);
      }
    }
    return [];
  }
);

export const getRunwayDirectionData = createSelector(
  [selectRunwayData, selectedRunway],
  (runwayData: RunwayResponse[], runway: string | undefined) => {
    if (!runway) return [];

    const data = runwayData.map((rway: RunwayResponse) => {
      return uniq(rway.runways[`${runway}`]);
    });
    return uniq(flatMap(data).filter((rw) => rw.length));
  }
);

export const getAirportSearchList = createSelector(
  [designsData, selectedAirportName],
  (designData: DesignResponse[], airportName: string | null) => {
    if (!airportName) return [];

    const data: OptionsType[] | [] = [];

    designData.map((d) => {
      if (
        (airportName &&
          airportName.length > 1 &&
          d.airport_name.toLowerCase().includes(airportName.toLowerCase())) ||
        d.icao.toLowerCase().includes(airportName.toLowerCase())
      ) {
        // @ts-ignore
        data.push({ label: `${d.airport_name} (${d.icao})`, value: d.icao });
      }
      return true;
    });

    return uniqBy(data, 'icao');
  }
);

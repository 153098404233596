import { find, isEmpty } from 'lodash';
import { Button, Collapse, Dropdown, Typography } from 'antd';
import type { CollapseProps } from 'antd';
import ArrowRightIcon from 'assets/svg/ArrowRightIcon';
import DownArrow from 'assets/svg/DownArrow';
import EyeCloseIcon from 'assets/svg/EyeCloseIcon';
import EyeOpenIcon from 'assets/svg/EyeOpenIcon';
import { airportDataCategories, airspaceCatData } from 'constants/collectData.constant';
import { white } from 'constants/color.constant';
import { useAppSelector } from 'hooks/useReduxHook';
import { TemperatureType } from 'store/design/types';
import { CollectDataType } from 'types/common.types';
import DesignHeaderMapElements from './DesignHeaderMapElements';
import DesignHeaderMarkTools from './DesignHeaderMarkTools';
import './styles.less';

const { Text } = Typography;

interface DesignToolHeaderProps {
  handleOnClickMapTools: (tool: string) => void;
  handleOnClickMapElement: (element: string) => void;
  handleShowHideDataLayer: (element: string | null) => void;
  activeMapTools: string | null;
  activeDataLayerId: string | null;
  magneticDeclination: number | null;
  activeMapElement: string[] | [];
  temperature: TemperatureType | null;
}

const DesignToolHeader = ({
  activeMapTools,
  activeMapElement,
  activeDataLayerId,
  temperature,
  magneticDeclination,
  handleOnClickMapTools,
  handleOnClickMapElement,
  handleShowHideDataLayer
}: DesignToolHeaderProps) => {
  const adDataKeys = useAppSelector((state) => state.design.adDataKeys);
  const airspaceDataKeys = useAppSelector((state) => state.design.airspaceDataKeys);

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: 'Aerodrome',
      children: (
        <div className={!isEmpty(adDataKeys) ? 'ad-data-list' : 'no-data ad-data-list'}>
          {!isEmpty(adDataKeys) ? (
            adDataKeys.map((data: string) => {
              const adItem: CollectDataType | undefined = find(airportDataCategories, [
                'value',
                data
              ]);

              if (adItem) {
                return (
                  <div className="ad-data-label" key={adItem.id + adItem.value}>
                    <div>
                      <p>{adItem.codeID}</p>
                      <p>{adItem.codeLabel}</p>
                    </div>
                    <div onClick={() => handleShowHideDataLayer(adItem.value)}>
                      {activeDataLayerId === adItem.value ? <EyeOpenIcon /> : <EyeCloseIcon />}
                    </div>
                  </div>
                );
              }
              return null;
            })
          ) : (
            <Text className="no-data">No Data Added</Text>
          )}
        </div>
      )
    },
    {
      key: '2',
      label: 'Airspace',
      children: (
        <div className={!isEmpty(airspaceDataKeys) ? 'ad-data-list' : 'no-data ad-data-list'}>
          {!isEmpty(airspaceDataKeys) ? (
            airspaceDataKeys.map((item: any) => {
              return (
                <div className="ad-data-label" key={item}>
                  <div key={item}>
                    <p>{airspaceCatData[item]}</p>
                  </div>
                  <div onClick={() => handleShowHideDataLayer(item)}>
                    {activeDataLayerId === item ? <EyeOpenIcon /> : <EyeCloseIcon />}
                  </div>
                </div>
              );
            })
          ) : (
            <Text className="no-data">No Data Added</Text>
          )}
        </div>
      )
    },
    {
      key: '3',
      label: 'Obstacles Data',
      children: (
        <div className="ad-data-list">
          <div className="ad-data-label">
            <div>
              <p>Terrain - SRTM</p>
            </div>
            <div onClick={() => handleShowHideDataLayer('srtm')}>
              {activeDataLayerId === 'srtm' ? <EyeOpenIcon /> : <EyeCloseIcon />}
            </div>
          </div>
          <div className="ad-data-label">
            <div>
              <p>Obstacles - Survey</p>
            </div>
            <div onClick={() => handleShowHideDataLayer('surveyobstacle')}>
              {activeDataLayerId === 'surveyobstacle' ? <EyeOpenIcon /> : <EyeCloseIcon />}
            </div>
          </div>
        </div>
      )
    },
    {
      key: '4',
      label: 'Magnetic Variation',
      children: (
        <div className={magneticDeclination ? 'ad-data-list' : 'ad-data-list no-data'}>
          {magneticDeclination ? (
            <Text className="no-data">
              Magnetic Variation:{' '}
              {Number(magneticDeclination) >= 0
                ? `${magneticDeclination}  °E`
                : `${Math.abs(magneticDeclination)}  °W`}
            </Text>
          ) : (
            <Text className="no-data">No Data Added</Text>
          )}
        </div>
      )
    },
    {
      key: '5',
      label: 'Temperature',
      children: (
        <div className={temperature ? 'ad-data-list temp-data' : 'ad-data-list no-data'}>
          {temperature ? (
            <>
              <Text className="no-data">Temperature Minimum: {temperature?.tmin || '-'}</Text>
              <Text className="no-data">Temperature Maximum: {temperature?.tmax || '-'}</Text>
            </>
          ) : (
            <Text className="no-data">No Data Added</Text>
          )}
        </div>
      )
    },
    {
      key: '6',
      label: 'Aircraft Performance',
      children: (
        <div className="ad-data-list no-data">
          <Text className="no-data">No Data Added</Text>
        </div>
      )
    },
    {
      key: '7',
      label: 'User Defined Data',
      children: (
        <div className="ad-data-list no-data">
          <Text className="no-data">No Data Added</Text>
        </div>
      )
    }
  ];

  return (
    <div className="design-tool-header">
      <div className="layer-dropdown-main">
        <Dropdown
          className="layer-dropdown"
          trigger={['click']}
          // eslint-disable-next-line
          dropdownRender={() => (
            <div className="design-collapse-main">
              <Collapse
                accordion
                size="small"
                items={items}
                // eslint-disable-next-line
                expandIcon={() => <ArrowRightIcon height={16} width={16} />}
                expandIconPosition="end"
                collapsible="icon"
                className="design-collapse"
              />
            </div>
          )}
        >
          <Button
            type="link"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <Text>Data Layers & Tables</Text>
            <DownArrow color={white} />
          </Button>
        </Dropdown>
      </div>
      <div className="map-tool-element">
        <DesignHeaderMarkTools
          activeMapTools={activeMapTools}
          handleOnClickMapTools={handleOnClickMapTools}
        />
        <DesignHeaderMapElements
          activeMapElement={activeMapElement}
          handleOnClickMapElement={handleOnClickMapElement}
        />
      </div>
    </div>
  );
};

export default DesignToolHeader;

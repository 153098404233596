import ReportSecTitleWithIcon from './ReportSecTitleWithIcon';
import WaypointPATable from './WaypointPATable';
import { Sec10 } from 'store/design/types';

interface Section10Props {
  scrollElementRef: any;
  sec10: Sec10[] | [];
}

const Section10 = ({ scrollElementRef, sec10 }: Section10Props) => {
  return (
    <div className="report-code-content page-break" ref={scrollElementRef} id="waypointpa">
      <ReportSecTitleWithIcon
        title="10. WAYPOINT DATA AND PROTECTION AREA"
        icon={null}
        handleSaveData={() => {}}
      />
      <div className="report-content-data">
        <div className="obstacle-table">
          <WaypointPATable waypointData={sec10} />
        </div>
      </div>
    </div>
  );
};

export default Section10;

import { Marker } from 'react-leaflet';
import { isEmpty, uniqBy } from 'lodash';
import { RoundLocationIconBlack, RoundLocationIconWhite } from 'constants/map.constants';
import { DesignResponse } from 'store/design/types';

interface MarkerComponentProps {
  markerData: DesignResponse[] | [];
  handleShowMarkerDetail: Function;
  isShowMarkerDetails: boolean;
  handleSelectedMarkerData: (icao: string | null) => void;
  selectedDesign: string | null;
}

const MarkerComponent = ({
  markerData,
  handleShowMarkerDetail,
  isShowMarkerDetails,
  handleSelectedMarkerData,
  selectedDesign
}: MarkerComponentProps) => {
  const allMarkerdata: DesignResponse[] | [] = !isEmpty(markerData)
    ? uniqBy(markerData, 'icao')
    : [];

  return (
    <div>
      {!isEmpty(allMarkerdata) &&
        allMarkerdata.map((data: DesignResponse) => {
          return (
            <Marker
              key={data.id}
              position={[data.latitude, data.longitude]}
              icon={
                isShowMarkerDetails && selectedDesign === data.icao
                  ? RoundLocationIconWhite
                  : RoundLocationIconBlack
              }
              eventHandlers={{
                click: () => {
                  handleShowMarkerDetail(data.icao);
                  handleSelectedMarkerData(data.icao);
                }
              }}
            />
          );
        })}
    </div>
  );
};

export default MarkerComponent;

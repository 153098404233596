import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { Divider, Table, TableProps, Typography } from 'antd';
import DocFileIcon from 'assets/svg/DocFileIcon';
import ObstacleDataIcon from 'assets/svg/ObstacleDataIcon';
import SoftwareIcon from 'assets/svg/SoftwareIcon';
import { lightGrey1 } from 'constants/color.constant';
import ReportContentCard from 'components/core/ReportContentCard';
import { ObstacleDataType } from 'pages/AeroReport/AeroReport';
import ReportSecTitleWithIcon from './ReportSecTitleWithIcon';

const { Text } = Typography;

interface PreviewSection1Props {
  sec1: any;
}

const PreviewSection1 = ({ sec1 }: PreviewSection1Props) => {
  const [topographyData, setTopographyData] = useState<any>([]);
  const [surveyData, setSurveyData] = useState<any>([]);

  useEffect(() => {
    let surveyObsData: ObstacleDataType[] = [];
    let terrainObsData: ObstacleDataType[] = [];
    if (!isEmpty(sec1)) {
      surveyObsData = [
        {
          key: 1,
          obstacle: 'Area-1 eTOD - Obstacle Data',
          source: 'AIP',
          vertical: sec1.etod_obs_vt,
          horizontal: sec1.etod_obs_ht,
          remarks: ''
        },
        {
          key: 2,
          obstacle: 'Uploaded Survey Data',
          source: 'Airspace Standards',
          vertical: sec1.survey_obs_vt,
          horizontal: sec1.survey_obs_ht,
          remarks: ''
        }
      ];
      terrainObsData = [
        {
          key: 1,
          obstacle: 'Area-1 eTOD - Terrain Data',
          source: 'SRTM',
          vertical: sec1.etod_terr_vt,
          horizontal: sec1.etod_terr_ht,
          remarks: ''
        },
        {
          key: 2,
          obstacle: 'Terrain spot elevation within 40 NM',
          source: 'Airspace Standards',
          vertical: sec1.terr_spot_elv_vt,
          horizontal: sec1.terr_spot_elv_ht,
          remarks: ''
        }
      ];
    }
    setTopographyData(terrainObsData);
    setSurveyData(surveyObsData);
  }, [sec1]);

  const columns: TableProps<any>['columns'] = [
    {
      title: 'Obstacle',
      dataIndex: 'obstacle',
      key: 'obstacle',
      className: 'table-col-data'
    },
    {
      title: 'Source',
      dataIndex: 'source',
      key: 'source',
      className: 'table-col-data'
    },
    {
      title: 'Tolerance / Accuracy',
      align: 'center',
      key: 'tolerance',
      children: [
        {
          title: 'Vertical',
          dataIndex: 'vertical',
          key: 'vertical',
          align: 'center',
          className: 'table-col-data',
          width: 150,
          render: (vt: string | number | null) => {
            return vt ? `${vt} M (${(Number(vt) * 3.28084).toFixed(0)} ft)` : '';
          }
        },
        {
          title: 'Horizontal',
          dataIndex: 'horizontal',
          key: 'horizontal',
          align: 'center',
          className: 'table-col-data',
          width: 150,
          render: (ht: string | number | null) => {
            return ht ? `${ht} M (${(Number(ht) * 3.28084).toFixed(0)} ft)` : '';
          }
        }
      ],
      className: 'table-col-data'
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      key: 'remarks',
      className: 'table-col-data'
    }
  ];

  return (
    <div className="report-code-content sec-1">
      <ReportSecTitleWithIcon title="1. DATA SOURCES, REFERENCE AND SOFTWARE TOOLS" icon={null} />
      <div className="report-content-data">
        <div className="data-sources-tool">
          <div className="reference-content clearfix">
            <div>
              <ReportContentCard icon={<DocFileIcon />} title="REFERENCE DOCUMENTS :" />
              <ul>
                <li>a. ICAO PANS OPS DOC. 8168 Vliume II AMDT 8</li>
                <li>b. ICAO DOC 9368</li>
                <li>c. AIRAC Cycle {sec1?.airac_ed || '12'}</li>
              </ul>
            </div>
            <Divider dashed type="vertical" style={{ borderColor: lightGrey1 }} />
            <div>
              <ReportContentCard icon={<SoftwareIcon />} title="SOFTWARE :" />
              <ul>
                <li>a. SatSure {'<>'} CFNPD v1.0</li>
              </ul>
            </div>
          </div>
          <Divider dashed style={{ borderColor: lightGrey1 }} />
          <div className="obs-terrain-content">
            <ReportContentCard icon={<ObstacleDataIcon />} title="OBSTACLES AND TOLERANCE :" />
            <div className="obstacle-table">
              <ReportContentCard
                classname="table-title"
                icon={null}
                title="Topographical Obstacles"
              />
              <Table
                columns={columns}
                dataSource={topographyData}
                pagination={false}
                rowClassName={() => 'table-row-data'}
              />
            </div>
            <Text className="table-note">
              *Note : Vegetation Allowance of +15m (50ft) is applied over significant terrain (hill
              & mountain)
            </Text>
            <div className="obstacle-table terrain-table">
              <ReportContentCard classname="table-title" icon={null} title="Survey Obstacles" />
              <Table
                columns={columns}
                dataSource={surveyData}
                pagination={false}
                rowClassName={() => 'table-row-data'}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewSection1;

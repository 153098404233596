import { useMemo } from 'react';
import { isEmpty } from 'lodash';
import { Polyline } from 'react-leaflet';

interface RefrenceLineProps {
  lineCoords: any;
}

const RefrenceLine = ({ lineCoords }: RefrenceLineProps) => {
  if (isEmpty(lineCoords)) return null;
  const coords = lineCoords.map((point: any) => {
    return [Number(point[1]), Number(point[0])];
  });
  const lineShape = useMemo(() => {
    return (
      <Polyline
        pane="shadowPane"
        pathOptions={{ color: '#696B72', weight: 1.5 }}
        positions={coords}
      />
    );
  }, [lineCoords]);

  // eslint-disable-next-line
  return <>{lineShape}</>;
};

export default RefrenceLine;

import { Button, Divider, Input, Space, Tooltip, Typography, Upload, UploadProps } from 'antd';
import * as XLSX from 'xlsx';
import { isEmpty } from 'lodash';
import RadioCheckIcon from 'assets/svg/RadioCheckIcon';
import RadioUnCheckIcon from 'assets/svg/RadioUnCheckIcon';
import { lightGrey1 } from 'constants/color.constant';

const { Text, Link } = Typography;

const parseDMS = require('parse-dms');

const CollectObstacleDataTab = ({
  selectedDesign,
  obstacleActiveBtn,
  obstacleActiveTabData,
  setObstacleActiveTabData,
  surveyFile,
  handleSelectSurveyFile,
  setSurveyFileObsData,
  handleObstacleDataViewOnMap,
  handleChangeObstacleActiveButtonData
}: any) => {
  const uploadprops: UploadProps = {
    beforeUpload: (file) => {
      handleSelectSurveyFile(file);
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = e.target?.result;
        const readedData = XLSX.read(data, { type: 'binary' });
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];
        const dataParse = XLSX.utils.sheet_to_json(ws);
        // eslint-disable-next-line
        const sheetCoulmns = XLSX.utils.sheet_to_json(ws, { header: 1 });
        const obsdata: any = [];
        if (!isEmpty(dataParse)) {
          dataParse.map((data: any) =>
            obsdata.push({
              uid: data?.UID ? data?.UID : null,
              type: data?.TYPE ? data?.TYPE : null,
              description: data?.DESCRIPTION ? data?.DESCRIPTION : null,
              latitude: data?.LATITUDE ? data?.LATITUDE.replace(/Â/g, ' ') : null,
              longitude: data?.LONGITUDE ? data?.LONGITUDE.replace(/Â/g, '') : null,
              coordinates: {
                coordinates: [
                  parseDMS(data?.LONGITUDE.replace(/Â/g, '')).lon,
                  parseDMS(data?.LATITUDE.replace(/Â/g, '')).lat
                ],
                type: 'Point'
              },
              elevation: data && data['TOP ELEV'] ? data['TOP ELEV'] : null
            })
          );
        }
        setSurveyFileObsData(obsdata);
      };

      reader.readAsBinaryString(file);
      return false;
    }
  };
  return (
    <Space direction="horizontal" size={[40, 0]} align="start" className="obstacle-content">
      <Space direction="vertical">
        <Text className="obst-terr-title">Obstacles</Text>
        <Button.Group className="obstacle-grp-btn">
          <Button
            className={
              obstacleActiveBtn === 'terrain' ? 'active-view-btn terrain-active' : 'terrain-btn'
            }
            onClick={() => handleChangeObstacleActiveButtonData('terrain')}
          >
            Terrain
          </Button>
          <Button
            className={
              obstacleActiveBtn === 'survey' ? 'active-view-btn survey-active' : 'survey-btn'
            }
            onClick={() => handleChangeObstacleActiveButtonData('survey')}
          >
            Survey
          </Button>
        </Button.Group>
        <Space direction="vertical" size={[0, 0]} className="obs-active-btn-content">
          {obstacleActiveBtn === 'terrain' && (
            <>
              <Space direction="vertical" size={[0, 0]}>
                <Upload disabled accept=".tiff">
                  <Button
                    disabled
                    type="text"
                    className={
                      obstacleActiveTabData === 'uploadtiff'
                        ? 'active-view-btn upload-btn'
                        : 'upload-btn'
                    }
                    icon={<RadioUnCheckIcon />}
                  >
                    Uploaded (filename.tiff)
                  </Button>
                </Upload>
                <Text className="obst-info-text">Supported file format : .tiff</Text>
              </Space>

              <Space direction="vertical" size={[0, 0]}>
                <Button
                  className={
                    obstacleActiveTabData === 'srtm' ? 'active-view-btn srtm-btn' : 'srtm-btn'
                  }
                  type="text"
                  icon={
                    obstacleActiveTabData === 'srtm' ? <RadioCheckIcon /> : <RadioUnCheckIcon />
                  }
                  onClick={() => setObstacleActiveTabData('srtm')}
                >
                  SRTM
                </Button>
                <Text className="obst-info-text">
                  Data will be ingested from SRTM 30 m Data Source
                </Text>
              </Space>
            </>
          )}
          {obstacleActiveBtn === 'survey' && (
            <Space direction="vertical" size={[0, 0]}>
              <Upload accept=".csv" showUploadList={false} {...uploadprops}>
                <Button
                  type="text"
                  onClick={() => setObstacleActiveTabData('uploadcsv')}
                  className={
                    obstacleActiveTabData === 'uploadcsv'
                      ? 'active-view-btn upload-btn'
                      : 'upload-btn'
                  }
                  icon={
                    obstacleActiveTabData === 'uploadcsv' ? (
                      <RadioCheckIcon />
                    ) : (
                      <RadioUnCheckIcon />
                    )
                  }
                >
                  Uploaded
                  {surveyFile && (
                    <Tooltip placement="topLeft" title={surveyFile.name}>
                      <Text className="csv-file-name">({surveyFile.name})</Text>
                    </Tooltip>
                  )}
                </Button>
              </Upload>
              <Text className="obst-info-text">
                Supported file format : .csv
                <Link
                  style={{ paddingLeft: 5 }}
                  href="https://media.cfnpd.satsure.co/templates/Survey-Data-Template.csv"
                  target="_blank"
                >
                  download template
                </Link>
              </Text>
            </Space>
          )}
          {obstacleActiveBtn === 'terrain' && (
            <div className="flexJustifyAlignCenter" style={{ marginTop: 12 }}>
              <Button
                disabled={isEmpty(obstacleActiveTabData)}
                onClick={handleObstacleDataViewOnMap}
              >
                View On Map
              </Button>
            </div>
          )}
        </Space>
      </Space>
      <Divider type="vertical" style={{ borderColor: `${lightGrey1}`, height: '13em' }} />
      <Space direction="vertical">
        <Text className="obst-terr-title">Tolerances</Text>
        <Space direction="horizontal">
          <Space direction="vertical" size={[0, 32]}>
            <Text className="tolrnc-content-text">Terrain</Text>
            <Text className="tolrnc-content-text">Vegetation</Text>
            <Text className="tolrnc-content-text">Survey</Text>
          </Space>
          <Space direction="vertical" size={[0, 20]}>
            <Input
              className="tolerance-input"
              readOnly
              placeholder="Automatically"
              addonAfter="m"
              value={selectedDesign?.terrain_tolerance || ''}
              style={{ marginLeft: 40, maxWidth: 299 }}
            />
            <Input
              className="tolerance-input"
              readOnly
              placeholder="Automatically"
              addonAfter="m"
              value={selectedDesign?.vegetation_tolerance || ''}
              style={{ marginLeft: 40, maxWidth: 299 }}
            />
            <Input
              className="tolerance-input"
              readOnly
              placeholder="Automatically"
              addonAfter="m"
              value={selectedDesign?.survery_tolerance || ''}
              style={{ marginLeft: 40, maxWidth: 299 }}
            />
          </Space>
        </Space>
      </Space>
    </Space>
  );
};

export default CollectObstacleDataTab;

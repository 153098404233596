import React from 'react';
import { isEmpty } from 'lodash';
import { Button, Divider, Input, Switch, Typography } from 'antd';
import { SlRefresh } from 'react-icons/sl';
import { OAlegdata } from 'store/design/types';

const { Text } = Typography;
const { TextArea } = Input;

interface PAOALegProps {
  handleShowObstacleTableDeparture: (isShow: boolean) => void;
  handleChangeRemarksDepOmni: (text: string | undefined) => void;
  remarksDepOmni: string | undefined;
  isDesigner: boolean;
  isShowHideRemarkDepOmniBtn: boolean;
  isLoadingAddRemarkData: boolean;
  activeObscleTerrainDataKey: string;
  isGeneratedOA: boolean;
  isShowHideOADataDeparture: boolean;
  isShowHideTerrainLayerDeparture: boolean;
  activeLegDesignDataDeparture: any;
  handleGeneratePADataDeparture: Function;
  handleAddUpdateRemarkDepOmni: Function;
  handleGenerateOADataDeparture: Function;
  handleShowTerrainLayerDeparture: Function;
  handleRefreshGeneratedOAdata: Function;
  activeOALegDesignDataDeparture: OAlegdata[] | [];
}

const PAOALeg = ({
  isDesigner,
  // eslint-disable-next-line
  isGeneratedOA,
  // eslint-disable-next-line
  activeObscleTerrainDataKey,
  handleShowObstacleTableDeparture,
  isShowHideOADataDeparture,
  activeLegDesignDataDeparture,
  isShowHideTerrainLayerDeparture,
  // eslint-disable-next-line
  activeOALegDesignDataDeparture,
  handleGeneratePADataDeparture,
  handleGenerateOADataDeparture,
  handleShowTerrainLayerDeparture,
  remarksDepOmni,
  isShowHideRemarkDepOmniBtn,
  isLoadingAddRemarkData,
  handleAddUpdateRemarkDepOmni,
  handleChangeRemarksDepOmni,
  handleRefreshGeneratedOAdata
}: PAOALegProps) => {
  const isObstacleGenerate: string | null =
    !isEmpty(activeLegDesignDataDeparture) && activeLegDesignDataDeparture.is_obstacle_analysed;

  const generateOABtnText = isObstacleGenerate
    ? isObstacleGenerate === 'completed'
      ? 'Generated'
      : isObstacleGenerate === 'in-progress'
      ? 'In Progress'
      : isObstacleGenerate === 'failed'
      ? 'Retry'
      : 'Generate'
    : 'Generate';

  const generateOABtnClass = isObstacleGenerate
    ? isObstacleGenerate === 'completed'
      ? 'generated-complt-btn generate-btn'
      : isObstacleGenerate === 'in-progress'
      ? 'generate-progress-btn generate-btn'
      : isObstacleGenerate === 'failed'
      ? 'generate-failed-btn generate-btn'
      : 'generate-btn'
    : 'generate-btn';

  const isOAComplete = isObstacleGenerate === 'completed';

  return (
    <div className="leg-tab pa-oa-leg">
      <div className="generate-area-content">
        <Text>Generate Protection Area</Text>
        <Button
          onClick={() =>
            !isEmpty(activeLegDesignDataDeparture) || !isDesigner
              ? () => {}
              : handleGeneratePADataDeparture()
          }
          className={
            !isEmpty(activeLegDesignDataDeparture)
              ? 'generated-area-btn generate-btn'
              : 'generate-btn'
          }
        >
          {!isEmpty(activeLegDesignDataDeparture) ? 'Generated' : 'Generate'}
        </Button>
      </div>
      <Divider className="content-divider" />
      <div className="generate-area-content">
        <Text>Obstacle Analysis</Text>
        <div className="refresh-generate-btn">
          {isObstacleGenerate === 'in-progress' && (
            <SlRefresh
              onClick={() => handleRefreshGeneratedOAdata(activeLegDesignDataDeparture.id)}
            />
          )}
          <Button
            style={{ marginLeft: 5 }}
            disabled={!isDesigner || isEmpty(activeLegDesignDataDeparture)}
            onClick={() =>
              isEmpty(isObstacleGenerate) || isObstacleGenerate === 'failed'
                ? handleGenerateOADataDeparture(activeLegDesignDataDeparture.id)
                : () => {}
            }
            className={generateOABtnClass}
          >
            {generateOABtnText}
          </Button>
        </div>
      </div>
      {/* {activeLegKey === '1' ? ( */}
      <div className="obstacle-switch-content">
        <Text
          className={
            isShowHideOADataDeparture
              ? isOAComplete
                ? 'oa-data-label oa-checked-label'
                : 'oa-data-label'
              : isOAComplete
              ? 'oa-data-label'
              : 'oa-label'
          }
        >
          Obstacle Analysis Results
        </Text>
        <Switch
          className={isObstacleGenerate ? 'oa-switch-data obstacle-switch' : 'obstacle-switch'}
          disabled={isEmpty(isObstacleGenerate) || isObstacleGenerate !== 'completed'}
          checked={isShowHideOADataDeparture}
          onChange={() => handleShowObstacleTableDeparture(!isShowHideOADataDeparture)}
        />
      </div>
      <div className="obstacle-switch-content">
        <Text
          className={
            isShowHideTerrainLayerDeparture
              ? isOAComplete
                ? 'oa-data-label oa-checked-label'
                : 'oa-data-label'
              : isOAComplete
              ? 'oa-data-label'
              : 'oa-label'
          }
        >
          Terrain Analysis Results
        </Text>
        <Switch
          className={isObstacleGenerate ? 'oa-switch-data obstacle-switch' : 'obstacle-switch'}
          disabled={isEmpty(isObstacleGenerate) || isObstacleGenerate !== 'completed'}
          checked={isShowHideTerrainLayerDeparture}
          onChange={() => handleShowTerrainLayerDeparture(!isShowHideTerrainLayerDeparture)}
        />
      </div>
      <div className="obstacle-remark-content">
        <Text className="remark-label">Remarks</Text>
        <TextArea
          disabled={!isDesigner || isEmpty(activeLegDesignDataDeparture)}
          className="remark-text-area"
          value={
            activeLegDesignDataDeparture && remarksDepOmni !== null ? remarksDepOmni : undefined
          }
          onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
            handleChangeRemarksDepOmni(e.target.value)
          }
          title="Remark"
          placeholder="Type here"
        />
        {(isShowHideRemarkDepOmniBtn || isLoadingAddRemarkData) && (
          <div className="remarks-btn">
            <Button
              type="text"
              loading={isLoadingAddRemarkData}
              onClick={() => handleAddUpdateRemarkDepOmni()}
            >
              Submit
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PAOALeg;

import { secondary } from 'constants/color.constant';
import { IconProps } from 'types/common.types';

const PlusIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Zoom in">
        <path d="M0 24H24V4C24 1.79086 22.2091 0 20 0H4C1.79086 0 0 1.79086 0 4V24Z" fill={color} />
        <path
          id="Vector 31"
          d="M12.2 7V12.2M12.2 17.4V12.2M12.2 12.2H17.4H7"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
PlusIcon.defaultProps = {
  color: secondary,
  width: 24,
  height: 24,
  onClick: () => {}
};

export default PlusIcon;

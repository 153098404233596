import { useEffect, useState } from 'react';
import { debounce, isEmpty } from 'lodash';
import { Button, InputNumber, Typography } from 'antd';
import InfoCircleIcon from 'assets/svg/InfoCircleIcon';
import LockIcon from 'assets/svg/LockIcon';
import { primary, red } from 'constants/color.constant';
import { InfobtwpointsResponseType, InitStartInfoType } from 'store/design/types';
import { latToDms, lngToDms } from 'utils/utils';

const { Text } = Typography;

interface OmniEndPointAltGradLegProps {
  isDesigner: boolean;
  activeLegDesignDataOMNI: any;
  endAltGradLengthOMNI: number | null;
  handleChangeLengthOMNI: Function;
  changeLengthPointInfoDataOMNI: InfobtwpointsResponseType | null;
  handleClickEndLatLngViewOnMapOMNI: Function;
  handleChangeOmniEndPDG: Function;
  handleChangeOmniEndAltGrad: Function;
  handleChangeOmniEndLength: Function;
  disableEndPointFieldOMNI: string;
  handleEndDisableFieldOMNI: Function;
  pdgOMNI: number;
  endAltitudeOMNI: number | null;
  initStartInfoOMNI: InitStartInfoType | null;
  isLengthAltPDGRangeOMNI: boolean;
  handleShowLengthAltPDGRangeOMNI: (isShow: boolean) => void;
}

const OmniEndPointAltGradLeg = ({
  isDesigner,
  endAltGradLengthOMNI,
  activeLegDesignDataOMNI,
  handleChangeLengthOMNI,
  changeLengthPointInfoDataOMNI,
  handleClickEndLatLngViewOnMapOMNI,
  handleChangeOmniEndPDG,
  handleChangeOmniEndAltGrad,
  handleChangeOmniEndLength,
  handleEndDisableFieldOMNI,
  disableEndPointFieldOMNI,
  pdgOMNI,
  endAltitudeOMNI,
  initStartInfoOMNI,
  isLengthAltPDGRangeOMNI,
  handleShowLengthAltPDGRangeOMNI
}: OmniEndPointAltGradLegProps) => {
  const [endHeightOMNI, setEndHeightOMNI] = useState<number | null>(null);

  useEffect(() => {
    if (endAltitudeOMNI) {
      const endHeight = Number(endAltitudeOMNI) - Number(initStartInfoOMNI?.der_elev || 0);
      setEndHeightOMNI(endHeight);
    }
  }, [endAltitudeOMNI]);

  useEffect(() => {
    if (
      Number(endAltGradLengthOMNI) > 25 ||
      Number(pdgOMNI) < 3.3 ||
      Number(pdgOMNI) > 10 ||
      Number(endHeightOMNI) <= 399
    ) {
      handleShowLengthAltPDGRangeOMNI(true);
    } else {
      handleShowLengthAltPDGRangeOMNI(false);
    }
  }, [endAltGradLengthOMNI, pdgOMNI, endHeightOMNI]);

  const handleChangeLength = debounce((value: number | null) => {
    handleChangeOmniEndLength(value);
    handleChangeLengthOMNI(value);
  }, 500);

  const handleChangePDG = debounce((value: number | null) => {
    handleChangeOmniEndPDG(value);
  }, 500);

  const handleChangeENDAlt = debounce((value: number | null) => {
    handleChangeOmniEndAltGrad(value);
  }, 500);

  const handleLock = (key: string) => {
    if (!isEmpty(activeLegDesignDataOMNI) || !isDesigner) return;
    handleEndDisableFieldOMNI(key);
    handleChangeOmniEndAltGrad(null);
    handleChangeLengthOMNI(null);
    handleChangeOmniEndPDG(3.3);
  };

  return (
    <div className="leg-tab end-point">
      <div className="end-alt-grad-div">
        <div
          className={
            disableEndPointFieldOMNI === 'pdg' ? 'alt-grad-content active-grad' : 'alt-grad-content'
          }
          style={{ order: disableEndPointFieldOMNI === 'pdg' ? 3 : 1 }}
        >
          <Text>PDG</Text>
          <div style={{ display: 'flex', gap: 8, flexDirection: 'column' }}>
            <div style={{ display: 'flex', gap: 8 }}>
              <InputNumber
                type="number"
                placeholder="Automatically"
                suffix="%"
                min={3.3}
                max={10}
                value={
                  disableEndPointFieldOMNI === 'pdg' ? Number(Number(pdgOMNI).toFixed(3)) : pdgOMNI
                }
                onChange={handleChangePDG}
                disabled={!isEmpty(activeLegDesignDataOMNI) || !isDesigner}
                readOnly={disableEndPointFieldOMNI === 'pdg'}
                style={{
                  width: 184,
                  color: primary,
                  border: `1px solid ${primary}`,
                  backgroundColor: disableEndPointFieldOMNI === 'pdg' ? '#F2ECFC' : 'white'
                }}
              />
              {/* {disableEndPointFieldOMNI !== 'pdg' && ( */}
              <div
                style={{
                  // height: 28,
                  width: 28,
                  borderRadius: '50%',
                  border: `1px solid ${primary}`,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  backgroundColor: disableEndPointFieldOMNI === 'pdg' ? '#F2ECFC' : 'white'
                }}
              >
                <LockIcon
                  onClick={() =>
                    disableEndPointFieldOMNI !== 'pdg' ? handleLock('pdg') : () => {}
                  }
                />
              </div>
              {/* )} */}
            </div>
            {pdgOMNI && (Number(pdgOMNI) < 3.3 || Number(pdgOMNI) > 10) && (
              <div className="alt-range-nfo">
                <InfoCircleIcon color={red} height={16} width={16} />
                PDG : Between 3.3 to 10.
              </div>
            )}
          </div>
        </div>
        <div
          className={
            disableEndPointFieldOMNI === 'length'
              ? 'alt-grad-content active-grad'
              : 'alt-grad-content'
          }
          style={{ order: disableEndPointFieldOMNI === 'length' ? 3 : 1 }}
        >
          <Text>Length</Text>
          <div style={{ display: 'flex', gap: 8, flexDirection: 'column' }}>
            <div style={{ display: 'flex', gap: 8 }}>
              <InputNumber
                type="number"
                suffix="NM"
                min={1}
                max={25}
                readOnly={disableEndPointFieldOMNI === 'length'}
                onChange={handleChangeLength}
                disabled={!isEmpty(activeLegDesignDataOMNI) || !isDesigner}
                // readOnly
                value={
                  !isEmpty(activeLegDesignDataOMNI)
                    ? Number(Number(activeLegDesignDataOMNI.parameters.dst_btw_wpt).toFixed(3)) ||
                      undefined
                    : !isEmpty(changeLengthPointInfoDataOMNI)
                    ? Number(Number(changeLengthPointInfoDataOMNI.distance).toFixed(3)) || undefined
                    : Number(Number(endAltGradLengthOMNI).toFixed(3)) || undefined
                }
                style={{
                  width: 184,
                  color: primary,
                  border: `1px solid ${primary}`,
                  backgroundColor: disableEndPointFieldOMNI === 'length' ? '#F2ECFC' : 'white'
                }}
              />
              {/* {disableEndPointFieldOMNI !== 'length' && ( */}
              <div
                style={{
                  // height: 28,
                  width: 28,
                  borderRadius: '50%',
                  border: `1px solid ${primary}`,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  backgroundColor: disableEndPointFieldOMNI === 'length' ? '#F2ECFC' : 'white'
                }}
              >
                <LockIcon
                  onClick={() =>
                    disableEndPointFieldOMNI !== 'length' ? handleLock('length') : () => {}
                  }
                />
              </div>
              {/* )} */}
            </div>
            {endAltGradLengthOMNI && Number(endAltGradLengthOMNI) > 25 && (
              <div className="alt-range-nfo">
                <InfoCircleIcon color={red} height={16} width={16} />
                Length should be less than 25.
              </div>
            )}
          </div>
        </div>
        <div
          className={
            disableEndPointFieldOMNI === 'endalt'
              ? 'end-alt alt-grad-content active-grad'
              : 'end-alt alt-grad-content'
          }
          style={{ order: disableEndPointFieldOMNI === 'endalt' ? 3 : 1 }}
        >
          <Text>End Altitude</Text>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
            <div style={{ display: 'flex', gap: 8 }}>
              <InputNumber
                type="number"
                suffix="ft"
                readOnly={disableEndPointFieldOMNI === 'endalt'}
                value={endAltitudeOMNI}
                onChange={handleChangeENDAlt}
                disabled={!isEmpty(activeLegDesignDataOMNI) || !isDesigner}
                style={{
                  width: 184,
                  color: primary,
                  border: `1px solid ${primary}`,
                  backgroundColor: disableEndPointFieldOMNI === 'endalt' ? '#F2ECFC' : 'white'
                }}
              />
              {/* {disableEndPointFieldOMNI !== 'endalt' && ( */}
              <div
                style={{
                  // height: 28,
                  width: 28,
                  borderRadius: '50%',
                  border: `1px solid ${primary}`,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  backgroundColor: disableEndPointFieldOMNI === 'endalt' ? '#F2ECFC' : 'white'
                }}
              >
                <LockIcon
                  onClick={() =>
                    disableEndPointFieldOMNI !== 'endalt' ? handleLock('endalt') : () => {}
                  }
                />
              </div>
              {/* )} */}
            </div>
            {endHeightOMNI && Number(endHeightOMNI) <= 399 && (
              <div className="alt-range-nfo">
                <InfoCircleIcon color={red} height={16} width={16} />
                {`Minimum Altitude :  ${400 + Number(initStartInfoOMNI?.der_elev)}.`}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="alt-grad-content" style={{ order: 4 }}>
        <Text>Height</Text>
        <Text className="omni-height">{endHeightOMNI ? `${endHeightOMNI} ft` : '-'}</Text>
      </div>
      <div className="alt-grad-content" style={{ order: 4 }}>
        <Text>
          End Point Name -{' '}
          {!isEmpty(activeLegDesignDataOMNI)
            ? activeLegDesignDataOMNI?.end_waypoint?.waypoint_name
            : 'XXXXX'}
        </Text>
        <Button
          type="primary"
          disabled={
            !isEmpty(activeLegDesignDataOMNI) ||
            isEmpty(changeLengthPointInfoDataOMNI) ||
            isLengthAltPDGRangeOMNI
          }
          ghost
          onClick={() => handleClickEndLatLngViewOnMapOMNI()}
        >
          View on Map
        </Button>
      </div>
      <div className="min-distance" style={{ order: 5 }}>
        <Text>Latitude</Text>
        <Text>
          {!isEmpty(activeLegDesignDataOMNI)
            ? latToDms(activeLegDesignDataOMNI?.end_waypoint?.lat)
            : !isEmpty(changeLengthPointInfoDataOMNI)
            ? latToDms(changeLengthPointInfoDataOMNI.B[0])
            : '-'}
        </Text>
      </div>
      <div className="min-distance" style={{ order: 6 }}>
        <Text>Longitude</Text>
        <Text>
          {!isEmpty(activeLegDesignDataOMNI)
            ? lngToDms(activeLegDesignDataOMNI?.end_waypoint?.lng)
            : !isEmpty(changeLengthPointInfoDataOMNI)
            ? lngToDms(changeLengthPointInfoDataOMNI.B[1])
            : '-'}
        </Text>
      </div>
    </div>
  );
};

export default OmniEndPointAltGradLeg;

import { useNavigate } from 'react-router-dom';
import { capitalize } from 'lodash';
import { Button, Col, Divider, Form, Input, Row, Select, Typography } from 'antd';
import DownArrow from 'assets/svg/DownArrow';
import PageHeader from 'components/PageHeader/PageHeader';
import { routes } from 'constants/pageRoutes.constants';
import { UserRole } from 'store/users/types';
import './styles.less';

const { Text } = Typography;
const { Option } = Select;
// not in use this page

const DesignerDetail = () => {
  const navigate = useNavigate();

  const onFinish = () => {
    navigate(routes.Home);
  };

  return (
    <div className="main-detail">
      <div className="detail-label">
        <PageHeader title="Enter your Details" />
      </div>
      <div className="member-detail">
        <Text>Personal Details</Text>
        <Divider style={{ marginBottom: 24 }} />
      </div>
      <Form
        layout="vertical"
        autoComplete="off"
        className="detail-form"
        onFinish={onFinish}
        initialValues={{ orgname: 'Airport Authority of India', email: '' }}
      >
        <div className="form-main">
          <Row gutter={[64, 24]} className="input-main">
            <Col>
              <Form.Item name="firstname" label="First Name" className="input-label">
                <Input placeholder="Enter here" className="form-input" />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="lastname" label="Last Name" className="input-label">
                <Input placeholder="Enter here" className="form-input" />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="orgname" label="Organisation Name" className="input-label">
                <Input placeholder="Enter here" className="form-input" readOnly />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[64, 24]} className="input-main">
            <Col>
              <Form.Item name="empid" label="Employee ID" className="input-label">
                <Input placeholder="Enter here" className="form-input" />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="position" label="Position / Designation" className="input-label">
                <Select
                  suffixIcon={<DownArrow />}
                  showSearch
                  placeholder="Select"
                  className="form-input form-input-select"
                  allowClear
                >
                  <Option value="designer">{capitalize(UserRole.Designer)}</Option>
                  <Option value="reviewer">{capitalize(UserRole.Reviewer)}</Option>
                  <Option value="both">Both</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="experience" label="Years of Experience" className="input-label">
                <Input placeholder="Enter here" className="form-input" />
              </Form.Item>
            </Col>
          </Row>
          <Divider className="form-divider" />
          <Row gutter={[64, 24]} className="input-main">
            <Col>
              <Form.Item name="email" label="Email Address" className="input-label">
                <Input placeholder="Enter here" className="form-input" readOnly />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="phone" label="Phone Number" className="input-label">
                <Input placeholder="Enter here" className="form-input" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[64, 24]} className="input-main">
            <Col>
              <Form.Item name="location" label="Location" className="input-label">
                <Select
                  suffixIcon={<DownArrow />}
                  showSearch
                  placeholder="Select"
                  className="form-input form-input-select"
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="address" label="Address" className="input-label">
                <Input placeholder="Enter here" className="form-input" />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="city" label="City" className="input-label">
                <Input placeholder="Enter here" className="form-input" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item name="postal-code" label="Postal Code" className="input-label">
                <Input placeholder="Enter here" className="form-input" />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div>
          <Button className="save-btn" type="primary" htmlType="submit" block size="large">
            Save & Continue
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default DesignerDetail;

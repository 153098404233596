import { white } from 'constants/color.constant';
import { IconProps } from 'types/common.types';

const NominalCirclesIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="8.5" stroke={color} />
      <circle cx="12" cy="12" r="4.5" stroke={color} />
    </svg>
  );
};
NominalCirclesIcon.defaultProps = {
  color: white,
  width: 24,
  height: 24,
  onClick: () => {}
};

export default NominalCirclesIcon;

import { ReactNode } from 'react';
import { UserRole } from 'store/users/types';

export enum Maptype {
  Roadmap = 'roadmap',
  satellite = 'satellite',
  terrain = 'terrain',
  Hybrid = 'hybrid'
}

// export enum DesignStatus {
//   Designing = 'designing',
//   Assigned = 'assigned',
//   'In Review' = 'inreview',
//   Completed = 'completed'
// }

export enum DesignStatus {
  Designing = 'Designing',
  Assigned = 'Assigned',
  'In Review' = 'In Review',
  Reviewed = 'Reviewed'
  // designing = 'designing',
  // assigned = 'assigned',
  // inreview = 'inreview',
  // completed = 'completed'
}

export enum DrawType {
  Point = 'marker',
  Polyline = 'polyline',
  Ruler = 'ruler'
}

export type Response<T> = {
  data: T;
  status: boolean;
  message?: string;
};

export type PostResponse = {
  status: boolean;
  message: string;
};

export type JWT = {
  iat: number;
  nbf: number;
  jti: string;
  exp: number;
  identity: string;
  fresh: boolean;
  type: string;
  user_claims: { role: UserRole; IsActive: boolean };
};

export interface IconProps {
  color?: string;
  height?: number;
  width?: number;
  disabled?: boolean;
  onClick?: () => void;
}

export interface OptionsTypeNumber {
  label: string;
  value: number;
}

export interface PathTerminatorOptionsType {
  is_active: boolean;
  path_terminator_name: string;
  path_terminators_code: string;
}

export interface OptionsType {
  label: string;
  value: string;
  isDisabled?: boolean;
}

export interface CollectDataType {
  id: number;
  codeID: string;
  codeLabel?: string;
  label: string;
  value: string;
}

export interface GeometryType {
  coordinates: any;
  type: string;
}

export interface FeatureGeometryType {
  geometry: GeometryType;
  id: string;
  properties: any;
  type: string;
}

export interface LegTabItemsType {
  label: string;
  key: string;
  closable: boolean;
  closeIcon?: ReactNode | null;
}

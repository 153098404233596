import { Typography } from 'antd';
import './styles.less';

const { Text } = Typography;

interface DesignStepProps {
  title: string | null;
  description: string | null;
}

const DesignStep = ({ title, description }: DesignStepProps) => {
  return (
    <div className="design-step">
      <Text>{title}</Text>
      <Text>{description}</Text>
    </div>
  );
};

export default DesignStep;

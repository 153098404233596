export const routes = {
  LoginUrl: '/',
  Home: '/home',
  DesignList: '/design-list',
  UserDetail: '/user-detail',
  DesignerDetail: '/designer-detail',
  OrgOnBoard: '/org-on-board',
  CreateDesign: '/create-design',
  EditDesign: '/edit-design/:designid',
  Assign: '/assign/:designid',
  Collect: '/collect-data/:designid',
  Design: '/design/:designid',
  AeroReport: '/report/:designid',
  Review: '/review/:designid',
  Document: '/document/:designid',
  UserManagement: '/user-management'
  // ForgotPass: '/forgot-password',
  // ResetPass: '/reset-password',
  // EditOrganization: '/edit-org/:id',
  // ProjectList: '/projects',
  // AddProject: '/create-project',
  // RepoFile: '/repo-file',
  // AddMember: '/add-member',
  // Mapview: '/map-view',
  // ProjectView: '/project-view',
  // Settings: '/setting'
};

import { white } from 'constants/color.constant';
import { IconProps } from 'types/common.types';

const TriangleIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12 6.75L17.1962 15.75H6.80385L12 6.75Z" fill={color} />
    </svg>
  );
};
TriangleIcon.defaultProps = {
  color: white,
  width: 24,
  height: 24,
  onClick: () => {}
};

export default TriangleIcon;

import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { client } from './axiosClient';
import { enqueueError } from 'store/alert';
import { store } from 'store/index';
import { ErrorResponse } from 'store/users/types';

const axiosBaseQuery =
  (): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig['method'];
      body?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
      headers?: AxiosRequestConfig['headers'];
    },
    unknown,
    ErrorResponse
  > =>
  async ({ url, method, body, headers }) => {
    try {
      if (method === 'POST') {
        const result = await client.post(url, body);
        return { data: result.data };
      }
      if (method === 'DELETE') {
        const result = await client.delete(url, { headers });
        return { data: result.data };
      }
      if (method === 'PUT') {
        const result = await client.put(url, body);
        return { data: result.data };
      }
      if (method === 'PATCH') {
        const result = await client.patch(url, body);
        return { data: result.data };
      }

      const result = await client.get(url);
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      const errorData: any = err.response?.data || {};
      if (errorData.message) {
        store.dispatch(enqueueError(errorData?.message));
      } else {
        store.dispatch(enqueueError(errorData?.data.message));
      }
      return {
        data: {
          statusCode: err.response?.status,
          status: false,
          ...errorData
        }
      };
    }
  };

export const userApiService = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: 'userapi',
  tagTypes: ['User'],
  refetchOnReconnect: true,
  endpoints: () => ({})
});

export const designApiService = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: 'designapi',
  tagTypes: [
    'Design',
    'Coding',
    'SurveyObsacles',
    'DesignObstacle',
    'Procedures',
    'Metadata',
    'Waypoint',
    'DesignVersion',
    'Leg',
    'Magnetic',
    'AreaSemiWidth',
    'RefrenceLine',
    'CFCourse',
    'ComputePoint',
    'PathTerminator',
    'Remark',
    'AeroReport'
  ],
  refetchOnReconnect: true,
  endpoints: () => ({})
});

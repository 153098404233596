import { secondary } from 'constants/color.constant';
import { IconProps } from 'types/common.types';

const NotificationIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9999 3C12.9999 2.44772 12.5522 2 11.9999 2C11.4477 2 10.9999 2.44772 10.9999 3V3.75H10.4425C8.21745 3.75 6.37585 5.48001 6.23695 7.70074L6.01595 11.2342C5.93169 12.5814 5.4794 13.8797 4.70834 14.9876C4.01166 15.9886 4.63188 17.3712 5.84281 17.5165L9.24994 17.9254V19C9.24994 20.5188 10.4812 21.75 11.9999 21.75C13.5187 21.75 14.7499 20.5188 14.7499 19V17.9254L18.1571 17.5165C19.368 17.3712 19.9882 15.9886 19.2915 14.9876C18.5205 13.8797 18.0682 12.5814 17.9839 11.2342L17.7629 7.70074C17.624 5.48001 15.7824 3.75 13.5573 3.75H12.9999V3ZM10.4425 5.25C9.00952 5.25 7.82348 6.36417 7.73403 7.79438L7.51303 11.3278C7.41163 12.949 6.86738 14.5112 5.93953 15.8444C5.88918 15.9168 5.934 16.0167 6.02153 16.0272L9.75919 16.4757C11.2477 16.6543 12.7522 16.6543 14.2407 16.4757L17.9783 16.0272C18.0659 16.0167 18.1107 15.9168 18.0603 15.8444C17.1325 14.5112 16.5882 12.949 16.4868 11.3278L16.2658 7.79438C16.1764 6.36417 14.9903 5.25 13.5573 5.25H10.4425ZM11.9999 20.25C11.3096 20.25 10.7499 19.6904 10.7499 19V18.25H13.2499V19C13.2499 19.6904 12.6903 20.25 11.9999 20.25Z"
        fill={color}
      />
    </svg>
  );
};
NotificationIcon.defaultProps = {
  color: secondary,
  width: 24,
  height: 24,
  onClick: () => {}
};

export default NotificationIcon;

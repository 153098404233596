import { memo, useMemo } from 'react';
import { isEmpty } from 'lodash';
import RenderLegData from './RenderLegData';

interface RenderProtectionAreaLegsProps {
  legsData: any;
  paCheckList: string[] | [];
}

const RenderProtectionAreaLegs = ({ paCheckList, legsData }: RenderProtectionAreaLegsProps) => {
  if (isEmpty(paCheckList)) return null;
  const PAlegsData = useMemo(() => {
    return paCheckList.map((legkey: string) => {
      return <RenderLegData key={legkey} legsData={legsData} activeLegKey={legkey} />;
    });
  }, [paCheckList, legsData]);

  // eslint-disable-next-line
  return <>{PAlegsData}</>;
};

export default memo(RenderProtectionAreaLegs);

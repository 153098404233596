import { Table, TableProps } from 'antd';
import { latToDms, lngToDms } from 'utils/utils';

interface SignificantDataTableProps {
  significantData: any;
}

const SignificantDataTable = ({ significantData }: SignificantDataTableProps) => {
  const columns: TableProps<any>['columns'] = [
    {
      title: 'Obstacle ID',
      dataIndex: 'uid',
      key: 'id',
      align: 'center',
      className: 'table-col-data',
      render: (text) => text || '-'
    },
    {
      title: 'Obstacle',
      dataIndex: 'type',
      key: 'obstacle',
      align: 'center',
      className: 'table-col-data'
    },
    {
      title: 'Coordinates',
      key: 'Coordinates',
      align: 'center',
      className: 'table-col-data',
      render: (record) => {
        return (
          <>
            <div>
              {record?.coordinates && record?.coordinates[1]
                ? latToDms(record?.coordinates[0])
                : ''}
            </div>
            <div>
              {record?.coordinates && record?.coordinates[0]
                ? lngToDms(record?.coordinates[1])
                : ''}
            </div>
          </>
        );
      }
    },
    {
      title: 'Elevation (ft)',
      dataIndex: 'elv',
      key: 'ele',
      align: 'center',
      className: 'table-col-data',
      render: (text) => (text ? text.toFixed(2) : '-')
    },
    {
      title: 'Obstacle Source',
      dataIndex: 'src',
      key: 'source',
      align: 'center',
      className: 'table-col-data'
    }
  ];

  return (
    <Table
      columns={columns}
      dataSource={significantData}
      pagination={false}
      rowKey={(r) => `${r.elv}-${r?.coordinates.join(',')}`}
      rowClassName={() => 'table-row-data'}
    />
  );
};

export default SignificantDataTable;

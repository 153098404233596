import { useMemo } from 'react';
import { CircleMarker, Marker } from 'react-leaflet';
import { isEmpty } from 'lodash';
import { AerodromePointIcon, AerodromePointBlackIcon } from 'constants/map.constants';
import { Maptype } from 'types/common.types';
import { black } from 'constants/color.constant';

interface RenderARPStartEndPointProps {
  initInfoCollectData: any;
  mapType: string;
  ringColor?: string;
}

const RenderARPStartEndPoint = ({
  initInfoCollectData,
  mapType,
  ringColor
}: RenderARPStartEndPointProps) => {
  if (isEmpty(initInfoCollectData)) return null;

  const ARPlineMemo = useMemo(
    () => (
      <>
        <Marker
          icon={mapType === Maptype.Roadmap ? AerodromePointBlackIcon : AerodromePointIcon}
          position={[initInfoCollectData.arp[0], initInfoCollectData.arp[1]]}
        />
        <CircleMarker
          pane="markerPane"
          center={[
            initInfoCollectData.startpoint.coordinates[0],
            initInfoCollectData.startpoint.coordinates[1]
          ]}
          pathOptions={{ fillColor: '#73FF1E', color: ringColor, fillOpacity: 1 }}
          radius={8}
        />
        <CircleMarker
          pane="markerPane"
          center={[
            initInfoCollectData.endpoint.coordinates[0],
            initInfoCollectData.endpoint.coordinates[1]
          ]}
          pathOptions={{ fillColor: '#FF1EF6', color: ringColor, fillOpacity: 1 }}
          radius={8}
        />
      </>
    ),
    [initInfoCollectData, mapType]
  );

  // eslint-disable-next-line
  return <>{ARPlineMemo}</>;
};

RenderARPStartEndPoint.defaultProps = {
  ringColor: black
};

export default RenderARPStartEndPoint;

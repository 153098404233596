import { Spin } from 'antd';
import { primary } from 'constants/color.constant';
import { CgSpinner } from 'react-icons/cg';

interface ContentScreenLoaderV1Props {
  loading: boolean;
  title?: string;
}

const ContentScreenLoaderV1 = ({ loading, title }: ContentScreenLoaderV1Props) => {
  return loading ? (
    <div className="content-screen-loader">
      <div>
        <Spin indicator={<CgSpinner className="spinner" color={primary} />} />
        <div className="loading-text">{title}</div>
      </div>
    </div>
  ) : null;
};
ContentScreenLoaderV1.defaultProps = {
  title: 'Loading...'
};

export default ContentScreenLoaderV1;

import { useMemo } from 'react';
import { Marker, Tooltip } from 'react-leaflet';
import {
  FlyoverWaypointIcon,
  FlyoverWaypointLightIcon,
  TriangleIcon
} from 'constants/map.constants';
import { WaypointMarkerResponseType } from 'store/design/types';

interface SelectWaypointMarkersProps {
  endSelectNavaidWaypoint?: string;
  selectWaypointData: WaypointMarkerResponseType[] | [];
  selectedWayPointMarker?: WaypointMarkerResponseType | null;
  handleSelectedWaypointMarkerOnClick: (marker: WaypointMarkerResponseType) => void;
  markerIcon?: boolean;
}

const SelectWaypointMarkers = ({
  endSelectNavaidWaypoint,
  selectWaypointData,
  selectedWayPointMarker,
  markerIcon,
  handleSelectedWaypointMarkerOnClick
}: SelectWaypointMarkersProps) => {
  const waypointsMemo = useMemo(() => {
    return (
      selectWaypointData &&
      selectWaypointData.map((marker: WaypointMarkerResponseType, index: number) => {
        return (
          <Marker
            eventHandlers={{
              click: () => {
                handleSelectedWaypointMarkerOnClick(marker);
              }
            }}
            key={index}
            position={[marker.latitude, marker.longitude]}
            icon={
              markerIcon
                ? TriangleIcon
                : selectedWayPointMarker?.id === marker.id
                ? FlyoverWaypointLightIcon
                : FlyoverWaypointIcon
            }
          >
            <Tooltip
              direction="left"
              className="marker-sticky-tooltip waypoint-tooltip"
              offset={[0, 0]}
              opacity={1}
              permanent
            >
              {marker.name}
            </Tooltip>
          </Marker>
        );
      })
    );
  }, [endSelectNavaidWaypoint, selectedWayPointMarker, selectWaypointData, markerIcon]);

  // eslint-disable-next-line
  return <>{waypointsMemo}</>;
};

SelectWaypointMarkers.defaultProps = {
  endSelectNavaidWaypoint: '',
  selectedWayPointMarker: null,
  markerIcon: false
};

export default SelectWaypointMarkers;

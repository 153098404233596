import { useState } from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { Button, DatePicker, Input, Radio, Space, Typography } from 'antd';
import { DateFormat3 } from 'constants/common.constant';
import { modalDate } from 'constants/designData.constants';
import { useAppSelector } from 'hooks/useReduxHook';
import { latToDms, lngToDms } from 'utils/utils';

const { Text } = Typography;

interface CollectMagneticVariationDataTabProps {
  handleChangeMagneticModal: Function;
  magneticVariationModal: string;
  isFetchingMagneticDeclination: boolean;
  handleChangeModalDate: (date: any) => void;
  magneticModalDate: any;
  handleSelectMagneticCalculate: Function;
  initInfoCollectData: any;
}

const CollectMagneticVariationDataTab = ({
  handleChangeMagneticModal,
  magneticVariationModal,
  handleChangeModalDate,
  magneticModalDate,
  handleSelectMagneticCalculate,
  initInfoCollectData,
  isFetchingMagneticDeclination
}: CollectMagneticVariationDataTabProps) => {
  const { designTemperature } = useAppSelector((state) => state.design);
  const [activeElevUnit, setActiveElevUnit] = useState<string>('ft');

  const minYear = magneticVariationModal === 'IGRF' ? modalDate.igrf.min : modalDate.wmm.min;
  const maxYear = magneticVariationModal === 'IGRF' ? modalDate.igrf.max : modalDate.wmm.max;

  const minDate = moment({ year: minYear, month: 0, day: 1 });
  const maxDate = moment({ year: maxYear, month: 11, day: 31 });

  const disabledDate = (current: any) => {
    return current && (current < minDate.startOf('day') || current > maxDate.endOf('day'));
  };

  const handleSelectElevationUnit = (elevation: string) => {
    setActiveElevUnit(elevation);
  };

  return (
    <Space direction="vertical" size={[0, 24]} className="magnetic-content">
      <div>
        <Text className="magnetic-label">Magnetic Variation</Text>
      </div>
      <Space direction="vertical" size={[0, 8]}>
        <Space direction="horizontal" size={[32, 0]}>
          <Space direction="vertical" size={[0, 8]}>
            <Text className="coord-label">Latitude - Longitude</Text>
            <Input
              className="coord-input"
              value={
                !isEmpty(initInfoCollectData)
                  ? `${latToDms(initInfoCollectData.arp[0])} ${lngToDms(
                      initInfoCollectData.arp[1]
                    )}`
                  : ''
              }
              disabled
              onChange={() => {}}
            />
          </Space>
          {/* <Space direction="vertical" size={[0, 8]}>
            <Text className="coord-label">Longitude</Text>
            <CoordinateInput
              className="coord-input"
              value={initInfoCollectData ? lngToDms(initInfoCollectData.arp[1]) : ''}
              disabled
            />
          </Space> */}
          <Space direction="vertical" size={[0, 8]}>
            <Text className="coord-label">Elevation</Text>
            <Input
              className="elevation-input"
              disabled
              value={
                initInfoCollectData &&
                (activeElevUnit === 'ft'
                  ? initInfoCollectData.der_elev
                  : (initInfoCollectData.der_elev / 3.281).toFixed(3))
              }
              placeholder="Enter Elevation"
              addonAfter={
                <Button.Group className="elevation-grp-btn">
                  <Button
                    onClick={() => handleSelectElevationUnit('ft')}
                    className={`${
                      activeElevUnit === 'ft' ? 'elev-data-btn ft-active-btn' : 'elev-data-btn'
                    }`}
                  >
                    ft
                  </Button>
                  <Button
                    onClick={() => handleSelectElevationUnit('m')}
                    className={`${
                      activeElevUnit === 'm' ? 'elev-data-btn m-active-btn' : 'elev-data-btn'
                    }`}
                  >
                    m
                  </Button>
                </Button.Group>
              }
            />
          </Space>
        </Space>
        <Space direction="horizontal" size={[32, 0]}>
          <Text className="suggestion-label">
            Add latitude longitude format - dd mm ss N/S dd mm ss W/E
          </Text>
          <Text className="suggestion-label">Ex: 04° 08′ 15″ N 162° 03′ 42″ E</Text>
        </Space>
      </Space>
      <Space direction="horizontal" size={[32, 0]} className="modal-content-main" wrap>
        <Space direction="vertical" className="modal-content">
          <Text className="coord-label">Modal</Text>
          <Radio.Group
            className="magnetic-modal"
            onChange={(e) => handleChangeMagneticModal(e.target.value)}
            value={magneticVariationModal}
            disabled={!isEmpty(designTemperature)}
          >
            <Radio value="IGRF">IGRF (1590 - 2024)</Radio>
            <Radio value="WMM">WMM (2019 - 2024)</Radio>
            <Radio value="CONSTANT">CONSTANT</Radio>
          </Radio.Group>
        </Space>
        <Space direction="vertical" className="modal-content">
          <Text className="coord-label">Date</Text>
          {!isEmpty(designTemperature) ? (
            <DatePicker
              // @ts-ignore
              value={(magneticModalDate && moment(magneticModalDate, DateFormat3)) || null}
              suffixIcon={null}
              style={{ width: '100%' }}
              disabled
            />
          ) : (
            <DatePicker
              format={DateFormat3}
              disabledDate={disabledDate}
              value={magneticModalDate}
              onChange={handleChangeModalDate}
              suffixIcon={null}
              style={{ width: '100%' }}
              disabled={magneticVariationModal === 'CONSTANT' || isEmpty(magneticVariationModal)}
            />
          )}
        </Space>
        <Space direction="vertical">
          <div className="empty-variation-label" />
          <Button
            type="primary"
            className="calculate-btn"
            loading={isFetchingMagneticDeclination}
            disabled={
              isEmpty(magneticModalDate) ||
              isFetchingMagneticDeclination ||
              !isEmpty(designTemperature)
            }
            onClick={() => handleSelectMagneticCalculate()}
          >
            Calculate
          </Button>
        </Space>
      </Space>
    </Space>
  );
};

export default CollectMagneticVariationDataTab;

import { Button, Modal } from 'antd';

interface DeleteLegModalProps {
  deleteLeg: string;
  description: string;
  isOpenDeleteModal: boolean;
  isLoading: boolean;
  handleDeleteLeg: Function;
  handleCancelDeleteLeg: Function;
}

const DeleteLegModal = ({
  deleteLeg,
  description,
  isOpenDeleteModal,
  isLoading,
  handleCancelDeleteLeg,
  handleDeleteLeg
}: DeleteLegModalProps) => {
  return (
    <Modal
      open={isOpenDeleteModal}
      title={`Are you sure you want to Delete ${deleteLeg}`}
      width={420}
      centered
      className="procedure-modal"
      style={{
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
      }}
      onOk={() => handleDeleteLeg()}
      onCancel={() => handleCancelDeleteLeg()}
      footer={[
        <Button key="back" type="link" onClick={() => handleCancelDeleteLeg()}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={isLoading} onClick={() => handleDeleteLeg()}>
          Delete
        </Button>
      ]}
    >
      {
        // eslint-disable-next-line react/no-danger
        <div dangerouslySetInnerHTML={{ __html: description }} />
      }
    </Modal>
  );
};

export default DeleteLegModal;

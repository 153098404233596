import { useMemo } from 'react';
import { isEmpty } from 'lodash';
import * as turf from '@turf/turf';
import {
  BlueTrianglePointIcon,
  FlybyWaypointIcon,
  FlyoverWaypointIcon,
  TriangleIcon
} from 'constants/map.constants';
import { red3 } from 'constants/color.constant';
import PointComponent from './PointComponent';
import PolygonComponent from './PolygonComponent';
import MultiPolygonComponent from './MultiPolygonComponent';
import ARPPolyline from './ARPPolyline';
import LineTextPath from './LineTextPath';

interface RenderLegDataProps {
  legsData: any;
  activeLegKey: string;
  procedure?: string | null;
  magneticDeclination?: number | null;
  selectedPathTerminator?: string | null;
  isLine?: boolean;
}

const RenderLegData = ({
  legsData,
  activeLegKey,
  procedure,
  selectedPathTerminator,
  magneticDeclination,
  isLine: isShowPolyline
}: RenderLegDataProps) => {
  if (isEmpty(legsData)) return null;

  const currentLegData = legsData[activeLegKey];
  if (isEmpty(currentLegData)) return null;

  const startWayPoint = currentLegData.start_waypoint;
  const endWayPoint = currentLegData.end_waypoint;
  const primaryData = currentLegData.primary;
  const secondaryData = currentLegData.secondary;
  const { parameters } = currentLegData;

  const startPointIcon =
    startWayPoint && startWayPoint.waypoint_mode === 'by'
      ? FlybyWaypointIcon
      : startWayPoint && startWayPoint.waypoint_mode === 'over'
      ? FlyoverWaypointIcon
      : BlueTrianglePointIcon;

  const endPointIcon =
    endWayPoint && endWayPoint.waypoint_mode === 'by'
      ? FlybyWaypointIcon
      : endWayPoint && endWayPoint.waypoint_mode === 'over'
      ? FlyoverWaypointIcon
      : procedure === 'OMNI'
      ? FlyoverWaypointIcon
      : TriangleIcon;

  const isFictitiousStart = startWayPoint.waypoint_name === 'Fictitious WPT';
  const isFictitiousEnd = endWayPoint.waypoint_name === 'Fictitious WPT';

  const midPoint = turf.midpoint(
    turf.point([startWayPoint.lng, startWayPoint.lat]),
    turf.point([endWayPoint.lng, endWayPoint.lat])
  );

  const legData = useMemo(() => {
    return (
      <>
        {startWayPoint && (
          <PointComponent
            pointIcon={startPointIcon}
            index={1}
            geometry={{ coordinates: [startWayPoint.lng, startWayPoint.lat] }}
            properties={null}
            isFictitious={isFictitiousStart}
          />
        )}
        {endWayPoint && (
          <PointComponent
            index={2}
            pointIcon={endPointIcon}
            geometry={{ coordinates: [endWayPoint.lng, endWayPoint.lat] }}
            properties={null}
            isFictitious={isFictitiousEnd}
          />
        )}
        {primaryData && primaryData.type === 'Polygon' && (
          <PolygonComponent
            index={3}
            coordinates={primaryData.coordinates}
            properties={null}
            weight={2}
            color={red3}
            fillColor={red3}
          />
        )}
        {secondaryData && secondaryData.type === 'Polygon' && (
          <PolygonComponent
            index={3}
            coordinates={secondaryData.coordinates}
            properties={null}
            isLine
            weight={2}
            fillColor="#6E3BD14D"
            fillOpacity={0.7}
          />
        )}
        {secondaryData && secondaryData.type === 'MultiPolygon' && (
          <MultiPolygonComponent
            index={3}
            coordinates={secondaryData.coordinates}
            properties={null}
            isLine
            weight={2}
            fillColor="#6E3BD14D"
            fillOpacity={0.7}
            pane="mapPane"
          />
        )}
        {startWayPoint && endWayPoint && (
          <LineTextPath
            coordinates={[
              [endWayPoint.lat, endWayPoint.lng],
              [startWayPoint.lat, startWayPoint.lng]
            ]}
            distance={`${parameters.dst_btw_wpt.toFixed(3)} NM`}
            bearing={`${parameters.bearing.toFixed(3)}° / ${(
              parameters.bearing - magneticDeclination!
            ).toFixed(3)}° M`}
          />
        )}
        {/* {selectedPathTerminator !== 'RF' && midPoint && (
          <Marker
            opacity={0}
            riseOffset={0}
            position={[midPoint.geometry.coordinates[1], midPoint.geometry.coordinates[0]]}>
            <Tooltip direction="left" className="center-point-data" permanent>
              <div className="mid-distance-data">{`${parameters.dst_btw_wpt.toFixed(3)} NM`}</div>
            </Tooltip>
          </Marker>
        )} */}
        {/* {selectedPathTerminator !== 'RF' && midPoint && (
          <Marker
            opacity={0}
            riseOffset={0}
            position={[midPoint.geometry.coordinates[1], midPoint.geometry.coordinates[0]]}>
            <Tooltip direction="right" className="center-point-data" permanent>
              <div className="mid-bearing-data">{`${parameters.bearing.toFixed(3)}° / ${(
                parameters.bearing - magneticDeclination!
              ).toFixed(3)}° M`}</div>
            </Tooltip>
          </Marker>
        )} */}
        {Boolean(startWayPoint) &&
          Boolean(endWayPoint) &&
          isShowPolyline &&
          !selectedPathTerminator && (
            <ARPPolyline
              lineColor="#696B72"
              arpCoordinates={[
                [startWayPoint.lat, startWayPoint.lng],
                [endWayPoint.lat, endWayPoint.lng]
              ]}
              decoratorIcon={TriangleIcon}
            />
          )}
      </>
    );
  }, [
    currentLegData,
    activeLegKey,
    startWayPoint,
    endWayPoint,
    primaryData,
    secondaryData,
    procedure,
    isShowPolyline,
    isFictitiousStart,
    isFictitiousEnd,
    magneticDeclination,
    midPoint
  ]);

  // eslint-disable-next-line
  return <>{legData}</>;
};

RenderLegData.defaultProps = {
  procedure: null,
  isLine: true,
  selectedPathTerminator: null,
  magneticDeclination: null
};

export default RenderLegData;

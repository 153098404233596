import { capitalize } from 'lodash';
import { Avatar } from 'antd';
import { blue, orange, primary, secondary, white } from 'constants/color.constant';
import { useAppSelector } from 'hooks/useReduxHook';
import { UserRole } from 'store/users/types';
import { decryptValue } from 'utils/decodeEncodeData';
import { shortLabel } from 'utils/utils';
import { MemberType } from 'store/design/types';

interface AvatarUserComponentProps {
  data: MemberType;
  userRole: string;
}

const AvatarUserComponent = ({ data, userRole }: AvatarUserComponentProps) => {
  const { userSession } = useAppSelector((state) => state.user);
  const isManager = userSession?.role === UserRole.Manager && userRole === UserRole.Manager;
  const isDesigner = userSession?.role === UserRole.Designer && userRole === UserRole.Designer;
  const isReviewer = userSession?.role === UserRole.Reviewer && userRole === UserRole.Reviewer;

  return (
    <div>
      <Avatar
        style={{
          backgroundColor: white,
          color: isManager ? primary : isDesigner ? orange : isReviewer ? blue : secondary,
          borderColor: isManager ? primary : isDesigner ? orange : isReviewer ? blue : secondary,
          verticalAlign: 'middle',
          fontSize: '12px',
          fontWeight: 500,
          boxShadow: '2px 0px 4px rgba(103, 103, 103, 0.15)'
        }}
      >
        {data && data.firstname && data.lastname
          ? shortLabel(`${decryptValue(data.firstname!)} ${decryptValue(data.lastname!)}`)
          : capitalize(decryptValue(data.email)).charAt(0)}
      </Avatar>
    </div>
  );
};

export default AvatarUserComponent;

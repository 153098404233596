import { useMemo, useRef, useState } from 'react';
import { Marker, Popup, useMap } from 'react-leaflet';
import { Button, Space, Typography } from 'antd';
import { capitalize, isEmpty } from 'lodash';
import { ObstacleWhiteRedIcon, ObstacleRedIcon } from 'constants/map.constants';
import { ObstacleType } from 'store/design/types';

interface AixmObstaclesAdDataProps {
  obstacleData: ObstacleType[] | [];
}

const { Text } = Typography;

const AixmObstaclesAdData = ({ obstacleData }: AixmObstaclesAdDataProps) => {
  const map = useMap();
  const popupElRef = useRef(null);
  const [selectedPointId, setSelectedPointId] = useState<number | null>(null);

  const handleClosePopup = () => {
    if (!map) return;

    setSelectedPointId(null);
    map.closePopup();
  };

  const obstacleMemo = useMemo(() => {
    if (isEmpty(obstacleData)) return null;
    return obstacleData.map((obstacle: ObstacleType) => {
      return (
        <Marker
          key={obstacle.id}
          position={[obstacle.latitude_dd, obstacle.longitude_dd]}
          icon={selectedPointId === obstacle.id ? ObstacleWhiteRedIcon : ObstacleRedIcon}
          eventHandlers={{
            click: (e: any) => {
              if (obstacle.id !== selectedPointId) {
                setSelectedPointId(obstacle.id);
              } else {
                setSelectedPointId(null);
                e.target.closePopup();
              }
            }
          }}
        >
          {!isEmpty(obstacle) && (
            <Popup
              className="point-popup ad-obstacle"
              closeOnClick={false}
              offset={[10, 0]}
              minWidth={232}
              closeButton={false}
              ref={popupElRef}
              maxHeight={226}
            >
              <Button className="close-btn" type="text" onClick={handleClosePopup}>
                x
              </Button>
              <Space direction="vertical">
                {Object.keys(obstacle).map((name: string, index: number) => (
                  <div key={index}>
                    <Text className="market-title">{capitalize(name)}</Text>
                    <Text className="market-data">
                      {obstacle[name as keyof ObstacleType] || '-'}
                    </Text>
                  </div>
                ))}
              </Space>
            </Popup>
          )}
        </Marker>
      );
    });
  }, [obstacleData, selectedPointId, map, popupElRef]);

  // eslint-disable-next-line
  return <>{obstacleMemo}</>;
};

// AixmObstaclesAdData.defaultProps = {
//   icon: ObstacleRedIcon
// };

export default AixmObstaclesAdData;

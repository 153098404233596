import { IconProps } from 'types/common.types';

const ArrowRightIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3334 10.0002C13.3334 10.1902 13.2692 10.3794 13.1401 10.5336L8.9734 15.5336C8.67923 15.8869 8.1534 15.9352 7.80007 15.6402C7.44673 15.3461 7.39923 14.8211 7.6934 14.4669L11.4234 9.99107L7.82757 5.52274C7.53923 5.1644 7.5959 4.63941 7.95423 4.35107C8.31257 4.06274 8.83673 4.1194 9.1259 4.47774L13.1492 9.47774C13.2717 9.63024 13.3334 9.81524 13.3334 10.0002Z"
        fill={color}
      />
    </svg>
  );
};
ArrowRightIcon.defaultProps = {
  color: '#95969D',
  width: 20,
  height: 20,
  onClick: () => {}
};

export default ArrowRightIcon;

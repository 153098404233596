import { capitalize, isEmpty, upperCase } from 'lodash';
import { Button, Drawer, Typography } from 'antd';
import ArrowleftIcon from 'assets/svg/ArrowleftIcon';
import CollapseIconBtn from 'assets/svg/CollapseIconBtn';
import CodingTable from 'components/DataLayersTable/CodingTable';
import CollectObstactleDataTable from 'components/CollectDataContainer/CollectObstactleDataTable';
import CollectADDataTable from 'components/CollectADDataTable/CollectADDataTable';
import DesignSurveyObsaclesTable from 'components/DataLayersTable/DesignSurveyObsaclesTable';
import ObsTerrainFullScreenTable from 'components/DataLayersTable/ObsTerrainFullScreenTable';
import { OAlegdata, SurveyObstacleType } from 'store/design/types';
import { CollectDataType } from 'types/common.types';

const { Text } = Typography;

interface DesignTableFullScreenViewProps {
  obstacleData: OAlegdata[] | [];
  isObsTerrainFullScreenTable: boolean;
  isShowClosingObstacle: boolean;
  activeObscleTerrainDataKey: string;
  oaLegCheckedKey: string | null;
  showDataLayerId: string | null;
  isLoadingCodingTableData: boolean;
  isOATableDepartureShow: boolean;
  isactiveCodingTable: boolean;
  isOATableOmniShow: boolean;
  codingData: any;
  adItem: CollectDataType | undefined;
  surveyObstacleData: SurveyObstacleType[] | [];
  availableADData: any;
  handleShowAeroTable: (isHide: boolean) => void;
  handleCodingTable: (activeTable: boolean) => void;
  handleShowFullScreenTable: (isShowHideTable: boolean) => void;
}

const DesignTableFullScreenView = ({
  isObsTerrainFullScreenTable,
  obstacleData,
  activeObscleTerrainDataKey,
  isShowClosingObstacle,
  oaLegCheckedKey,
  isLoadingCodingTableData,
  isactiveCodingTable,
  codingData,
  showDataLayerId,
  availableADData,
  adItem,
  isOATableDepartureShow,
  isOATableOmniShow,
  surveyObstacleData,
  handleCodingTable,
  handleShowFullScreenTable,
  handleShowAeroTable
}: DesignTableFullScreenViewProps) => {
  return (
    <Drawer
      title={
        <div>
          <div>
            <Text>
              {isactiveCodingTable
                ? 'Coding Table'
                : oaLegCheckedKey !== null
                ? `${upperCase(oaLegCheckedKey)} - ${capitalize(
                    activeObscleTerrainDataKey
                  )} Analysis Results`
                : isOATableDepartureShow || isOATableOmniShow
                ? `${capitalize(activeObscleTerrainDataKey)} Analysis Results`
                : showDataLayerId && showDataLayerId === 'aixmobstacles'
                ? 'Aerodome Obstacles'
                : showDataLayerId && showDataLayerId === 'surveyobstacle'
                ? 'Survey Obstacles'
                : adItem && adItem.codeLabel}
            </Text>
            <Button
              key="back-icon"
              type="text"
              icon={<CollapseIconBtn />}
              onClick={() => handleShowFullScreenTable(false)}
              className="back-icon-btn"
            />
          </div>
        </div>
      }
      placement="bottom"
      height="calc(100vh - 56px)"
      width="100vw"
      open={isObsTerrainFullScreenTable}
      contentWrapperStyle={{ boxShadow: 'none' }}
      maskStyle={{ backgroundColor: 'transparent' }}
      onClose={() => handleShowFullScreenTable(false)}
      className="full-table-view"
      closeIcon={
        <Button
          key="back-icon"
          type="text"
          shape="circle"
          icon={<ArrowleftIcon />}
          onClick={() => handleShowFullScreenTable(false)}
          className="back-icon-btn"
        />
      }
    >
      <div className="collect-table-content">
        {isactiveCodingTable && (
          <CodingTable
            handleCodingTable={handleCodingTable}
            isLoading={isLoadingCodingTableData}
            codingData={!isEmpty(codingData) ? codingData : []}
            handleShowFullScreenTable={handleShowFullScreenTable}
            isObsTerrainFullScreenTable={isObsTerrainFullScreenTable}
          />
        )}

        {showDataLayerId === 'aixmobstacles' && !isEmpty(adItem) && (
          <CollectObstactleDataTable
            tableExpandView={isObsTerrainFullScreenTable}
            obstacleData={
              showDataLayerId === 'aixmobstacles' && !isEmpty(availableADData[showDataLayerId])
                ? availableADData[showDataLayerId]
                : []
            }
          />
        )}
        {showDataLayerId !== 'aixmobstacles' && (
          <CollectADDataTable availableDataActiveCodeId={showDataLayerId} activeTab={null} />
        )}
        {showDataLayerId === 'surveyobstacle' && (
          <DesignSurveyObsaclesTable
            obstacleData={surveyObstacleData}
            isObsTerrainFullScreenTable={isObsTerrainFullScreenTable}
            handleShowAeroTable={handleShowAeroTable}
            handleShowFullScreenTable={handleShowFullScreenTable}
          />
        )}

        {(isOATableDepartureShow || isOATableOmniShow || !isEmpty(oaLegCheckedKey)) && (
          <ObsTerrainFullScreenTable
            isObsTerrainFullScreenTable={isObsTerrainFullScreenTable}
            obstacleData={obstacleData}
            activeObscleTerrainDataKey={activeObscleTerrainDataKey}
            isShowClosingObstacle={isShowClosingObstacle}
          />
        )}
      </div>
    </Drawer>
  );
};

export default DesignTableFullScreenView;

import { isEmpty } from 'lodash';
import { Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { ObstacleType } from 'store/design/types';

const { Text } = Typography;

interface CollectDataTableProps {
  tableExpandView: boolean;
  obstacleData: ObstacleType[] | [];
}

const CollectObstactleDataTable = ({ tableExpandView, obstacleData }: CollectDataTableProps) => {
  const columns: ColumnsType<ObstacleType> = [
    {
      title: 'RWY/ Area Affected',
      align: 'center',
      key: 'rwy_designator',
      render: (record) => {
        return (
          <div>
            {!isEmpty(record.rwy_designator) ? (
              <Text style={{ color: '#696b72', display: 'block' }}>{record.rwy_designator}</Text>
            ) : (
              '-'
            )}
          </div>
        );
      }
    },
    {
      title: 'Obstacle Type',
      dataIndex: 'obstacle_type',
      key: 'obstacle_type'
    },
    {
      title: 'Coordinates',
      key: 'coordinates',
      render: (record) => {
        return (
          <>
            <Text
              className={tableExpandView ? 'full-co-ord' : 'co-ord'}
              style={{ color: '#696b72', display: 'block' }}
            >
              {record.latitude_dd.toFixed(6)}
            </Text>
            <Text
              className={tableExpandView ? 'full-co-ord' : 'co-ord'}
              style={{ color: '#696b72', display: 'block' }}
            >
              {record.longitude_dd.toFixed(6)}
            </Text>
          </>
        );
      }
    },
    {
      title: 'Elevation',
      dataIndex: 'elevation',
      align: 'center',
      key: 'elevation'
    },
    {
      title: 'Marking/LGT',
      align: 'center',
      key: 'marking',
      render: (record) => {
        return (
          <Text
            className={tableExpandView ? 'full-co-ord' : 'co-ord'}
            style={{ color: '#696b72', display: 'block' }}
          >
            {record.marking || '-'}/{record.lighted || '-'}
          </Text>
        );
      }
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      key: 'remarks'
    }
  ];

  return (
    <div className="collect-table-content">
      <Table
        className={tableExpandView ? 'full-collect-table collect-table-main' : 'collect-table-main'}
        scroll={{ y: tableExpandView ? 'calc(100vh - 204px)' : 'calc(100% - 45px)' }}
        dataSource={obstacleData || []}
        pagination={false}
        columns={columns}
        rowKey={(record) => record.id}
      />
    </div>
  );
};

export default CollectObstactleDataTable;

import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import ReportSecTitleWithIcon from './ReportSecTitleWithIcon';

interface PreviewSection4Props {
  sec4: any;
}

const PreviewSection4 = ({ sec4 }: PreviewSection4Props) => {
  const [ifpDesignHtml, setIFPDesignHtml] = useState<string | null>(null);

  useEffect(() => {
    if (!isEmpty(sec4)) {
      setIFPDesignHtml(sec4.ifp_design_constr || null);
    }
  }, [sec4]);

  return (
    <div className="report-code-content page-break">
      <ReportSecTitleWithIcon title="4. IFP DESIGN CONSTRAINTS AND NARRATIVE" icon={null} />
      <div className="report-content-data">
        <div className="ifp-design-content">
          <div
            className="ql-container ql-snow design-html"
            // eslint-disable-next-line
            dangerouslySetInnerHTML={{ __html: ifpDesignHtml || '<p>No Data Found.</p>' }}
          />
        </div>
      </div>
    </div>
  );
};

export default PreviewSection4;

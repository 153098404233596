import { useEffect, useState } from 'react';
import { capitalize, isEmpty, upperFirst } from 'lodash';
import { useNavigate, NavLink, useParams } from 'react-router-dom';
import { Dropdown, MenuProps, Avatar, Typography, Button } from 'antd';
import UserIcon from 'assets/svg/UserIcon';
import LogOutIcon from 'assets/svg/LogOutIcon';
import SatsureSkiesBlack from 'assets/svg/SatsureSkiesBlack';
import NotificationIcon from 'assets/svg/NotificationIcon';
import QuestionMarkIcon from 'assets/svg/QuestionMarkIcon';
import DocumentIcon from 'assets/svg/DocumentIcon';
import { ACCESS_TOKEN, REFRESH_TOKEN } from 'constants/common.constant';
import { routes } from 'constants/pageRoutes.constants';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { useLogoutMutation, userapi } from 'services/apis/userApi';
import { getStorageValue, clearData } from 'services/axiosClient';
import { designapi } from 'services/apis/designsApi';
import { UserRoleText, UserState } from 'store/users/types';
import { DesignState } from 'store/design/types';
import { enqueueError } from 'store/alert';
import { DesignStatus } from 'types/common.types';
import { decryptValue } from 'utils/decodeEncodeData';
import { shortLabel } from 'utils/utils';

const { Text } = Typography;

const HeaderV2 = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { designid }: any = useParams();
  const accessToken = getStorageValue(ACCESS_TOKEN);
  const refreshToken = getStorageValue(REFRESH_TOKEN);
  const { userSession }: UserState = useAppSelector((state) => state.user);
  const { selectedDesign }: DesignState = useAppSelector((state) => state.design);
  const isStartDesign = selectedDesign && selectedDesign.status === DesignStatus.Assigned;
  const [firstName, setFirstName] = useState<string | null>(null);
  const [lastName, setLastName] = useState<string | null>(null);
  const [compressName, setCompressName] = useState<string | null>(null);
  const [logout] = useLogoutMutation();

  useEffect(() => {
    if (isEmpty(userSession)) return;
    if (userSession.firstname && userSession.lastname) {
      setFirstName(upperFirst(decryptValue(userSession.firstname)));
      setLastName(upperFirst(decryptValue(userSession.lastname)));
      setCompressName(
        shortLabel(`${decryptValue(userSession.firstname)} ${decryptValue(userSession.lastname)}`)
      );
    } else {
      setFirstName(upperFirst(decryptValue(userSession.email)));
      setCompressName(capitalize(userSession.email.charAt(0)));
    }
  }, []);

  const handleNavigate = () => {
    if (!isEmpty(accessToken) && accessToken !== 'null') {
      navigate(routes.Home);
    } else {
      navigate(routes.LoginUrl);
    }
  };

  const handleCollectLink = (e: any) => {
    if (designid && !isStartDesign) {
      navigate(`/collect-data/${designid}`);
    } else {
      e.preventDefault();
      dispatch(enqueueError('Design not started'));
    }
  };

  const handleDesignLink = (e: any) => {
    if (designid && !isEmpty(selectedDesign?.procedures)) {
      navigate(`/design/${designid}`);
    } else {
      e.preventDefault();
      dispatch(enqueueError('Design not started'));
    }
  };

  const handleAeroReportLink = (e: any) => {
    e.preventDefault();
  };

  const handleReviewLink = (e: any) => {
    if (designid && !isEmpty(selectedDesign?.procedures)) {
      navigate(`/review/${designid}`);
    } else {
      e.preventDefault();
      dispatch(enqueueError('Design not started'));
    }
  };

  const handleDocumentLink = (e: any) => {
    if (designid && !isEmpty(selectedDesign?.procedures)) {
      navigate(`/document/${designid}`);
    } else {
      e.preventDefault();
      dispatch(enqueueError('Design not started'));
    }
  };

  const handleLogout = () => {
    if (refreshToken) {
      logout(refreshToken);
      dispatch(userapi.util.resetApiState());
      dispatch(designapi.util.resetApiState());
    }
    clearData();
    navigate(routes.LoginUrl);
  };

  const items: MenuProps['items'] = [
    {
      label: (
        <div className="user-detail">
          <Avatar>{compressName || '-'}</Avatar>
          <div>
            <Text>
              {firstName || '-'} {lastName}
            </Text>
            <Text>{userSession && userSession.role ? UserRoleText[userSession.role] : '-'}</Text>
          </div>
        </div>
      ),
      key: '0'
    },
    {
      label: (
        <div className="profile-setting" onClick={handleLogout}>
          <LogOutIcon />
          <Text>Log out</Text>
        </div>
      ),
      key: '1'
    }
  ];

  return (
    <div className="header-div">
      <div className="left-side-content">
        <SatsureSkiesBlack width={97} height={37} onClick={handleNavigate} />
        <div className="list-link">
          <NavLink
            to={designid ? `/assign/${designid}` : '/home'}
            className={({ isActive }) => (isActive ? 'nav-link active-link' : 'nav-link')}
          >
            Assign
          </NavLink>
          <NavLink
            onClick={handleCollectLink}
            to={designid ? `/collect-data/${designid}` : '/home'}
            className={({ isActive }) => (isActive ? 'nav-link active-link' : 'nav-link')}
          >
            Collect Data
          </NavLink>
          <NavLink
            onClick={handleDesignLink}
            to={`/design/${designid}`}
            className={({ isActive }) => (isActive ? 'nav-link active-link' : 'nav-link')}
          >
            Design
          </NavLink>
          <NavLink
            onClick={handleAeroReportLink}
            to={`/report/${designid}`}
            className={({ isActive }) => (isActive ? 'nav-link active-link' : 'nav-link')}
          >
            Aero Report
          </NavLink>
          <NavLink
            onClick={handleReviewLink}
            to={`/review/${designid}`}
            className={({ isActive }) => (isActive ? 'nav-link active-link' : 'nav-link')}
          >
            Review
          </NavLink>
          <NavLink
            onClick={handleDocumentLink}
            to={`/document/${designid}`}
            className={({ isActive }) => (isActive ? 'nav-link active-link' : 'nav-link')}
          >
            Document
          </NavLink>
        </div>
      </div>
      <div className="header-icons">
        <Button type="text" shape="circle" icon={<NotificationIcon />} />
        <QuestionMarkIcon />
        <DocumentIcon />
        <Dropdown
          menu={{ items }}
          trigger={['click']}
          className="user-menu"
          overlayClassName="user-menu-item"
        >
          <Button
            aria-label="user-icon"
            type="text"
            shape="circle"
            icon={<UserIcon />}
            onClick={(e) => e.preventDefault()}
          />
        </Dropdown>
      </div>
    </div>
  );
};

export default HeaderV2;

import { primary } from 'constants/color.constant';
import { IconProps } from 'types/common.types';

const PrimaryPlusIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 19"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0417 8.70866H10.2917V3.95866C10.2917 3.52087 9.93708 3.16699 9.50008 3.16699C9.06308 3.16699 8.70841 3.52087 8.70841 3.95866V8.70866H3.95841C3.52141 8.70866 3.16675 9.06253 3.16675 9.50033C3.16675 9.93812 3.52141 10.292 3.95841 10.292H8.70841V15.042C8.70841 15.4798 9.06308 15.8337 9.50008 15.8337C9.93708 15.8337 10.2917 15.4798 10.2917 15.042V10.292H15.0417C15.4787 10.292 15.8334 9.93812 15.8334 9.50033C15.8334 9.06253 15.4787 8.70866 15.0417 8.70866Z"
        fill={color}
      />
    </svg>
  );
};
PrimaryPlusIcon.defaultProps = {
  color: primary,
  width: 19,
  height: 19,
  onClick: () => {}
};

export default PrimaryPlusIcon;

import { useMemo } from 'react';
import { Polygon } from 'react-leaflet';
import { isEmpty } from 'lodash';
import { primary } from 'constants/color.constant';

interface RenderToleranceDataProps {
  legsData: any;
  activeLegKey: string;
  color?: string;
  weight?: number;
  pane?: string;
  fillColor?: string;
}

const RenderToleranceData = ({
  legsData,
  activeLegKey,
  color,
  weight,
  pane,
  fillColor
}: RenderToleranceDataProps) => {
  if (isEmpty(legsData)) return null;

  const currentLegData = legsData[activeLegKey];
  if (isEmpty(currentLegData)) return null;

  const coordinateData = currentLegData.geo_parameters?.tolerance?.map((points: any) => {
    return points.map((point: any) => {
      return [Number(point[1]), Number(point[0])];
    });
  });

  if (isEmpty(coordinateData)) return null;

  const toleranceData = useMemo(() => {
    return (
      <Polygon
        positions={coordinateData}
        pathOptions={{
          color,
          weight,
          fillColor
        }}
        pane={pane}
      />
    );
  }, [coordinateData, activeLegKey]);

  // eslint-disable-next-line
  return <>{toleranceData}</>;
};

RenderToleranceData.defaultProps = {
  color: primary,
  weight: 2,
  fillColor: 'transparent',
  pane: 'markerPane'
};

export default RenderToleranceData;

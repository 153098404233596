import { isEmpty } from 'lodash';
import { Button, InputNumber, Typography } from 'antd';
import InfoCircleIcon from 'assets/svg/InfoCircleIcon';
import CustomDivider from 'components/core/CustomDivider';
import { primary, red } from 'constants/color.constant';
import { InitStartInfoType } from 'store/design/types';
import { getBankAngle, getTurnAngleMinMax } from 'utils/utils';

const { Text } = Typography;

interface TurnLegProps {
  initStartInfoDeparture: InitStartInfoType | null;
  activeLegDesignDataDeparture: any;
  selectedPathTerminatorDeparture: string | undefined;
  setActiveCourseSuffix: any;
  activePrevLegDesignDataDeparture: any;
  turnDirectionDeparture: string | null;
  turnAngleDeparture: number | null;
  courseDeparture: number | null;
  magneticDeclination: number | null;
  radiusOfTurn: number | null;
  activeCourseSuffix: string;
  activeLegKey: string;
}

const TurnLeg = ({
  turnDirectionDeparture,
  initStartInfoDeparture,
  turnAngleDeparture,
  activeLegDesignDataDeparture,
  activePrevLegDesignDataDeparture,
  courseDeparture,
  setActiveCourseSuffix,
  magneticDeclination,
  activeCourseSuffix,
  selectedPathTerminatorDeparture,
  radiusOfTurn,
  activeLegKey
}: TurnLegProps) => {
  const turnDir = !isEmpty(activeLegDesignDataDeparture)
    ? activeLegDesignDataDeparture.parameters.turn_direction
    : turnDirectionDeparture;

  const course = !isEmpty(activeLegDesignDataDeparture)
    ? activeCourseSuffix === 't'
      ? activeLegDesignDataDeparture.parameters.bearing
      : Number(
          (
            Number(activeLegDesignDataDeparture.parameters.bearing) - Number(magneticDeclination)
          ).toFixed(3)
        )
    : courseDeparture
    ? activeCourseSuffix === 't'
      ? courseDeparture
      : Number((Number(courseDeparture) - Number(magneticDeclination)).toFixed(3))
    : undefined;

  const turnAngleMinMax =
    selectedPathTerminatorDeparture &&
    getTurnAngleMinMax(selectedPathTerminatorDeparture, Number(activeLegKey));

  return (
    <div className="leg-tab turn-leg">
      <div className="turn-angle-content turn-dir-content" style={{ paddingBottom: 0 }}>
        <Text>Turn Direction</Text>
        <Button.Group className="turn-dir-btn">
          <Button
            disabled={turnDir !== 'left'}
            className={turnDir === 'left' ? 'active-dir-btn' : ''}
          >
            Left
          </Button>
          <Button
            disabled={turnDir !== 'on-set'}
            className={turnDir === 'on-set' ? 'active-dir-btn' : ''}
          >
            On-Set
          </Button>
          <Button
            disabled={turnDir !== 'right'}
            className={turnDir === 'right' ? 'active-dir-btn' : ''}
          >
            Right
          </Button>
        </Button.Group>
      </div>
      <div className="bank-angle turn-dir-content alt-grad-content">
        <Text>
          {selectedPathTerminatorDeparture === 'RF' ? 'Degrees of Turn' : 'Angle of Turn'}
        </Text>
        <InputNumber
          type="number"
          placeholder="Automatically"
          className="alt-input"
          style={{ border: 0 }}
          suffix="°deg"
          readOnly
          value={
            !isEmpty(activeLegDesignDataDeparture)
              ? Number(activeLegDesignDataDeparture.parameters.turn_angle).toFixed(3) || 0
              : turnAngleDeparture
              ? Number(turnAngleDeparture).toFixed(3)
              : 0
          }
        />
      </div>
      {turnAngleMinMax && (
        <div className="bank-angle-info">
          <InfoCircleIcon color={red} />
          <Text>
            Angle of Turn
            {` - (Min.: ${turnAngleMinMax.min}, Max.: ${turnAngleMinMax.max})`}
          </Text>
        </div>
      )}
      <CustomDivider marginTop="10px" />
      <div className="bank-angle turn-dir-content">
        <Text>Bank Angle</Text>
        <InputNumber
          type="number"
          placeholder="Enter Angle"
          defaultValue={
            !isEmpty(activeLegDesignDataDeparture)
              ? activeLegDesignDataDeparture.parameters.bank_angle
              : activePrevLegDesignDataDeparture
              ? getBankAngle(Number(activePrevLegDesignDataDeparture.end_waypoint.alt) || 0)
              : initStartInfoDeparture
              ? getBankAngle(initStartInfoDeparture.der_elev || 0)
              : 15
          }
          min={15}
          max={25}
          readOnly
          suffix="°deg"
        />
      </div>
      <div className="outbound-track turn-dir-content">
        <Text>Outbound Track</Text>
        <div className="course-div">
          <InputNumber
            style={{
              width: 98,
              border: `0.5px solid ${primary}`
            }}
            readOnly
            value={course.toFixed(3)}
          />
          <Button.Group className="course-grp-btn">
            <Button
              className={activeCourseSuffix === 't' ? 'active-view-btn-t course-btn' : 'course-btn'}
              onClick={() => setActiveCourseSuffix('t')}
            >
              °T
            </Button>
            <Button
              className={activeCourseSuffix === 'm' ? 'active-view-btn-m course-btn' : 'course-btn'}
              onClick={() => setActiveCourseSuffix('m')}
            >
              °M
            </Button>
          </Button.Group>
        </div>
      </div>
      <div className="bank-angle turn-dir-content alt-grad-content reaction-time">
        <Text>Pilot Reaction Time</Text>
        {/* <InputNumber type="number" placeholder="Enter" readOnly suffix="sec" defaultValue={6} /> */}
        <InputNumber
          type="number"
          placeholder="Automatically"
          className="alt-input"
          style={{ border: 0 }}
          suffix="sec"
          readOnly
          defaultValue={6}
        />
      </div>
      {selectedPathTerminatorDeparture === 'RF' && (
        <div className="bank-angle turn-dir-content">
          <Text>Radius of Turn</Text>
          <InputNumber
            type="number"
            value={radiusOfTurn ? Number(radiusOfTurn.toFixed(3)) : undefined}
            min={15}
            max={25}
            readOnly
            suffix="NM"
          />
        </div>
      )}
    </div>
  );
};

export default TurnLeg;

import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import DownloadPdfIcon from 'assets/svg/DownloadPdfIcon';
import ReportContentCard from 'components/core/ReportContentCard';
import Editor from 'components/TextEditor/Editor';
import ReportSecTitleWithIcon from './ReportSecTitleWithIcon';

interface Section5Props {
  sec5: any;
  scrollElementRef: any;
  handleCallSaveAeroReportDataApi: (data: { section_key: string; fields: any }) => void;
  magVar: any;
}

const Section5 = ({
  sec5,
  scrollElementRef,
  magVar,
  handleCallSaveAeroReportDataApi
}: Section5Props) => {
  const [procedureInstrctHtml, setProcedureInstrctHtml] = useState<string | null>(null);
  const [planViewHtml, setPlanViewHtml] = useState<string>(
    '<ol><li>ELEV, ALT IN FEET</li><li>DIST IN NM</li><li>BRG ARE MAG</li><li>MAG VAR   - 2019</li><li>GNSS REQUIRED</li><li>RNAV 1 OPERATIONS</li></ol>'
  );

  useEffect(() => {
    if (!isEmpty(sec5)) {
      setPlanViewHtml(
        !isEmpty(sec5.plan_view_notes)
          ? sec5.plan_view_notes
          : `<ol><li>ELEV, ALT IN FEET</li><li>DIST IN NM</li><li>BRG ARE MAG</li><li>MAG VAR   - ${magVar}</li><li>GNSS REQUIRED</li><li>RNAV 1 OPERATIONS</li></ol>`
      );
      setProcedureInstrctHtml(sec5.proc_instr || null);
    }
  }, [sec5]);

  const handleProcInstrictchange = (html: any) => {
    setProcedureInstrctHtml(html);
  };

  const handleViewNoteschange = (html: any) => {
    setPlanViewHtml(html);
  };

  const handleSavePlanAndProfileData = () => {
    const payload: { section_key: string; fields: any } = {
      section_key: 'sec_5',
      fields: {
        plan_view_notes: planViewHtml,
        proc_instr: procedureInstrctHtml
      }
    };

    handleCallSaveAeroReportDataApi(payload);
  };

  return (
    <div className="report-code-content sec-3" ref={scrollElementRef} id="profileview">
      <ReportSecTitleWithIcon
        title="5. PLAN AND PROFILE VIEW"
        icon={<DownloadPdfIcon />}
        handleSaveData={handleSavePlanAndProfileData}
      />
      <div className="report-content-data">
        <div className="plan-profile-content">
          <ReportContentCard icon={null} title="PLAN VIEW NOTES :" />
          <div className="plan-view-note">
            <Editor editorHtml={planViewHtml} handleChange={handleViewNoteschange} />
          </div>
          <ReportContentCard icon={null} title="PROCEDURE INSTRUCTION :" />
          <div className="procedure-instruct">
            <Editor editorHtml={procedureInstrctHtml} handleChange={handleProcInstrictchange} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section5;

import { secondary } from 'constants/color.constant';
import DesignStep from './DesignStep';

const ProcessTabComponent = () => {
  return (
    <div className="process-tab-cntent">
      <div className="design-desc">
        After clicking on Start Design, you will go through step wise design process of{' '}
        <b style={{ color: secondary }}>SID (Standard Instrument Departure)</b>
      </div>
      <div className="design-desc2">Here, are brief of the steps to follow</div>
      <div className="design-steps">
        <DesignStep
          title="Step 1"
          description="Choose departure procedure type, and select relevant parameters like airport, navigation, navaid, AFCT category, and MSA based on design needs."
        />
        <DesignStep
          title="Step 2"
          description="Add Legs by selecting waypoints on the map. Specify details like start and end points, altitude or gradient, turns, and speed limits."
        />
        <DesignStep
          title="Step 3"
          description="Check coding table, create protection area for each Leg, and see obstacle assessment for each Leg visually."
        />
        <DesignStep
          title="Step 4"
          description="Check coding table, create protection area for each Leg, and see obstacle assessment for each Leg visually."
        />
      </div>
    </div>
  );
};

export default ProcessTabComponent;

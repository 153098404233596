import { Col, Row } from 'antd';
import ARPPointIcon from 'assets/svg/ARPPointIcon';
import { aeroReportLists } from 'constants/aeroReport.constant';
import CustomDivider from 'components/core/CustomDivider';
import ReportContentCard from 'components/core/ReportContentCard';
import ReportCardTitle from './ReportCardTitle';

const Section0 = ({ sec2, sec3 }: any) => {
  return (
    <>
      <div className="report-code-title sec-1">CFN CODE OF DESIGN</div>
      <Row className="report-info-cont-card">
        <Col span={7}>
          <ReportContentCard icon={<ARPPointIcon />} title="AIRPORT NAME" />
          <div>{sec3?.aero_name || '-'}</div>
        </Col>
        <Col span={7}>
          <ReportContentCard icon={<ARPPointIcon />} title="PROCEDURE NAME" />
          <div>{sec2?.proc_design_type || '-'}</div>
        </Col>
        <Col span={7}>
          <ReportContentCard icon={<ARPPointIcon />} title="RUNWAY" />
          <div>{sec3?.rwy || '-'}</div>
        </Col>
      </Row>
      <div className="report-info-content">
        <div className="report-info-title">TABLE OF CONTENT</div>
        <CustomDivider />
        <div className="report-info-cont">
          {aeroReportLists.map((t: { id: string; title: string }, i: number) => (
            <ReportCardTitle
              classname="table-content-card"
              key={i}
              title={t.title}
              id={t.id}
              handleOnclick={() => {}}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Section0;

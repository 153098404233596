// @ts-nocheck
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { Form, Input, Table } from 'antd';
import { Sec7TableDataType } from 'store/design/types';

type InputRef = typeof Input;
type FormInstance<T> = typeof Form<T>;

const EditableContext = createContext<FormInstance<any> | null>(null);

interface ItemType {
  key: React.Key;
  sid: string;
  last_wpt: string;
  description: string | null;
}

interface EditableRowProps {
  index: number;
}

const EditableRow = ({ index, ...props }: EditableRowProps) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr key={index} {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof ItemType;
  record: ItemType;
  handleSave: (record: ItemType) => void;
}

const EditableCell = ({
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}: EditableCellProps) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      // eslint-disable-next-line
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item style={{ margin: 0 }} name={dataIndex}>
        <Input
          autoComplete="off"
          placeholder="Type here"
          ref={inputRef}
          onPressEnter={save}
          onBlur={save}
        />
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

interface SIDRouteDescTableProps {
  sidrouteData: Sec7TableDataType[] | [];
  handleSaveData: (data: Sec7TableDataType[] | []) => void;
}

const SIDRouteDescTable = ({ sidrouteData, handleSaveData }: SIDRouteDescTableProps) => {
  const [dataSource, setDataSource] = useState<Sec7TableDataType[]>(sidrouteData);

  useEffect(() => {
    setDataSource(sidrouteData);
  }, [sidrouteData]);

  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: 'SID ID',
      width: 150,
      key: 'sidid',
      className: 'table-col-data',
      render: (record) => (
        <>
          <div className="sid-title">{record.sid}</div>
          <div className="sid-title">(DER - {record.last_wpt})</div>
        </>
      )
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      key: 'description',
      className: 'table-col-data',
      align: 'center',
      editable: true
    }
  ];

  const handleSave = (row: Sec7TableDataType) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row
    });
    setDataSource(newData);
    handleSaveData(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell
    }
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Sec7TableDataType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave
      })
    };
  });

  return (
    <Table
      className="sid-route-table"
      components={components}
      rowClassName={() => 'editable-row table-sid-row-data'}
      dataSource={dataSource}
      pagination={false}
      columns={columns as ColumnTypes}
    />
  );
};

export default SIDRouteDescTable;

import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { Typography } from 'antd';
import RadioCheckIcon from 'assets/svg/RadioCheckIcon';
import RadioUnCheckIcon from 'assets/svg/RadioUnCheckIcon';
import { primary } from 'constants/color.constant';
import ListDataCard from 'components/core/ListDataCard';
import ReportSecTitleWithIcon from './ReportSecTitleWithIcon';

const { Text } = Typography;

interface PreviewSection2Props {
  sec2: any;
  sec3: any;
}

const PreviewSection2 = ({ sec2, sec3 }: PreviewSection2Props) => {
  const [procedureRemark, setProcedureRemark] = useState<string | null>(null);

  useEffect(() => {
    if (!isEmpty(sec2)) {
      setProcedureRemark(sec2.remark || null);
    }
  }, [sec2]);

  return (
    <div className="report-code-content page-break">
      <ReportSecTitleWithIcon title="2. PROCEDURE DETAILS" icon={null} />
      <div className="report-content-data">
        <div className="procedure-detail-content">
          <div className="procedure-type-list clearfix">
            <Text>Procedure Type</Text>
            <div className="type-list">
              <div>
                {sec2?.proc_type === 'NEW' ? <RadioCheckIcon /> : <RadioUnCheckIcon />}
                <Text className="list-title">NEW</Text>
              </div>
              <div>
                {sec2?.proc_type === 'REVISED' ? <RadioCheckIcon /> : <RadioUnCheckIcon />}
                <Text className="list-title">REVISED</Text>
              </div>
            </div>
          </div>
          <div className="type-data-list clearfix">
            <ListDataCard title="Procedure Method" listData={['ICAO PANS - OPS']} />
            <ListDataCard title="State Criteria" listData={['DGCA']} />
            <ListDataCard title="Procedure ID" listData={[sec2?.proc_id || '']} />
            <ListDataCard
              title="Type of Procedure Design"
              listData={[sec2?.proc_design_type || '']}
            />
            <ListDataCard title="Type of Navigation System" listData={[sec2?.nav_sys_type || '']} />
            <ListDataCard
              title="Navigation Specification"
              listData={[sec2?.nav_spec || '']}
              info={`${sec2?.nav_spec} is required for operating on these IFPs`}
            />
            <ListDataCard title="Categories of Aircraft" listData={[sec3?.acft_cat || '']} />
            <ListDataCard title="Total Number of Procedure" listData={[sec2?.num_of_proc || '0']} />
          </div>
          <div className="type-data-list remark-list clearfix">
            <div className="list-data-card" style={{ width: '552px' }}>
              <Text>Remark</Text>
              <Text style={{ color: primary }}>{procedureRemark || undefined}</Text>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewSection2;

import React, { useEffect, useRef, useState } from 'react';
import { PDFExport, PageTemplateProps, savePDF } from '@progress/kendo-react-pdf';
import { Button, Drawer, Space, Typography } from 'antd';
import ArrowleftIconV1 from 'assets/svg/ArrowleftIconV1';
import { primary } from 'constants/color.constant';
import { CodingTableDataType, Sec10 } from 'store/design/types';
import PreviewSection1 from './PreviewSection1';
import PreviewSection2 from './PreviewSection2';
import PreviewSection3 from './PreviewSection3';
import PreviewSection4 from './PreviewSection4';
import PreviewSection5 from './PreviewSection5';
import PreviewSection6 from './PreviewSection6';
import PreviewSection7 from './PreviewSection7';
import PreviewSection8 from './PreviewSection8';
import Section9 from './Section9';
import Section10 from './Section10';
import PreviewSection11 from './PreviewSection11';
import Section12 from './Section12';
import Section13 from './Section13';
import './reportpreview.less';

interface PreviewAeroReportProps {
  isOpen: boolean;
  onClose:
    | ((e: React.MouseEvent<Element, globalThis.MouseEvent> | React.KeyboardEvent<Element>) => void)
    | undefined;
  sec1: any;
  sec2: any;
  sec3: any;
  sec4: any;
  sec5: any;
  sec6: any;
  sec7: any;
  sec8: any;
  sec9: any;
  sec10: Sec10[] | [];
  sec11: any;
  sec12: { [key: string]: CodingTableDataType[] | [] } | null;
  sec13: any;
  handlePdfLoading: (isLoading: boolean) => void;
  magneticDeclination: any;
}

const { Text } = Typography;

const PageTemplate = ({ ...props }: PageTemplateProps) => {
  return (
    <div>
      <div className="aero-pdf-header">
        <div>
          <div className="aero-header-label">SID Aero Report</div>
          <div className="aero-header-title">CFN CODE OF DESIGN</div>
        </div>
      </div>
      <div className="aero-pdf-footer">
        <div>{props.pageNum}</div>
      </div>
    </div>
  );
};

const PreviewAeroReport = ({
  isOpen,
  onClose,
  sec1,
  sec2,
  sec3,
  sec4,
  sec5,
  sec6,
  sec7,
  sec8,
  sec9,
  sec10,
  sec11,
  sec12,
  sec13,
  magneticDeclination,
  handlePdfLoading
}: PreviewAeroReportProps) => {
  const reportContainerRef = useRef<HTMLDivElement>(null);
  const pdfExportComponent = useRef<PDFExport>(null);
  const [count, setCount] = useState(0);
  const [isPdfLoading, setPdfLoading] = useState<boolean>(false);

  useEffect(() => {
    setCount(count + 1);
  }, [isOpen]);

  const exportPDFWithMethod = () => {
    setPdfLoading(true);
    handlePdfLoading(true);
    const element = reportContainerRef.current || document.body;
    savePDF(
      element,
      {
        paperSize: 'A4',
        margin: { top: 20, left: 15, right: 15, bottom: 20 },
        repeatHeaders: true,
        scale: 0.6,
        pageTemplate: PageTemplate,
        fileName: `Aero Report for ${new Date().toLocaleString()}.pdf`,
        forcePageBreak: `.page-break`,
        keepTogether: '.page-together'
      },
      () => {
        setPdfLoading(false);
        handlePdfLoading(false);
      }
    );
  };

  return (
    <Drawer
      className="preview-report-drawer"
      title={
        <Text
          className="ant-drawer-title"
          disabled={isPdfLoading}
          style={{ cursor: 'pointer' }}
          onClick={onClose}
        >
          back to design
        </Text>
      }
      placement="bottom"
      width={500}
      height="calc(100% - 56px)"
      onClose={onClose}
      open={isOpen}
      closeIcon={<ArrowleftIconV1 disabled={isPdfLoading} color={primary} height={18} />}
      extra={
        <Space>
          <Text className="header-title">PREVIEW REPORT</Text>
          <Button type="primary" onClick={() => exportPDFWithMethod()}>
            Download as PDF
          </Button>
        </Space>
      }
    >
      <div className="report-content-main preview-report--main">
        <PDFExport
          ref={pdfExportComponent}
          paperSize="A4"
          creator="satsure"
          margin={{ top: 40, bottom: 40, right: '0.5cm', left: '0.5cm' }}
        >
          <div ref={reportContainerRef} className="pdf-export-container">
            <div className="report-code-title sec-1">CFN CODE OF DESIGN</div>
            <PreviewSection1 sec1={sec1} />
            <PreviewSection2 sec2={sec2} sec3={sec3} />
            <PreviewSection3 sec3={sec3} magneticDeclination={magneticDeclination} />
            <PreviewSection4 sec4={sec4} />
            <PreviewSection5 sec5={sec5} magVar={sec3 ? sec3?.aero_mag_var : ' '} />
            <PreviewSection6 sec6={sec6} />
            <PreviewSection7 sec7={sec7} />
            <PreviewSection8 scrollElementRef={null} sec8={sec8} />
            <Section9 scrollElementRef={null} sec9={sec9} />
            <Section10 scrollElementRef={null} sec10={sec10} />
            <PreviewSection11 scrollElementRef={null} count={count} sec11={sec11} />
            <Section12 scrollElementRef={null} sec12={sec12} />
            <Section13 scrollElementRef={null} sec13={sec13} />
          </div>
        </PDFExport>
      </div>
    </Drawer>
  );
};

export default PreviewAeroReport;

import { Radio, Typography } from 'antd';
import { DateFormat2, TimeFormat } from 'constants/common.constant';
import { TimezoneContext } from 'context/createusecontext';
import moment from 'moment';
import { useContext } from 'react';
import { ProcedureDataType } from 'store/design/types';

const { Text } = Typography;

interface ProcedureCardProps {
  selectedProcedureId: number;
  value: number;
  procedure: ProcedureDataType;
}

const ProcedureCard = ({ selectedProcedureId, value, procedure }: ProcedureCardProps) => {
  const timeZone = useContext(TimezoneContext);
  const date: any = procedure.created_at ? moment(procedure.created_at).format(DateFormat2) : '-';
  const time: any = procedure.created_at
    ? `${moment(procedure.created_at).format(TimeFormat)} ${timeZone?.timezone}`
    : '';

  return (
    <Radio
      value={value}
      className={selectedProcedureId === value ? 'deactive-sid active-sid' : 'deactive-sid'}
    >
      <Text>
        {procedure.name}
        {procedure.procedure ? ` - ${procedure.procedure}` : ''}
      </Text>
      <Text>
        {date}; {time}
      </Text>
    </Radio>
  );
};

export default ProcedureCard;

import { Col, Row, Switch, Typography } from 'antd';
import CustomDivider from './CustomDivider';
import './styles.less';

const { Text } = Typography;

interface ProtectionAreaSwitchProps {
  data: string;
  currentLegData: any;
  handleChangePALegSwitch: (checkedleg: string) => void;
  isCheckedPALegSwitch: boolean;
}
const ProtectionAreaSwitch = ({
  data,
  handleChangePALegSwitch,
  isCheckedPALegSwitch,
  currentLegData
}: ProtectionAreaSwitchProps) => {
  return (
    <div className="oa-leg-content-main">
      <div className={isCheckedPALegSwitch ? 'pa-leg-content' : 'pa-leg-content pa-content-main'}>
        <Text className="pa-leg-label">{data}</Text>
        <Switch
          size="small"
          className="oa-switch"
          onChange={() => handleChangePALegSwitch(data)}
          checked={isCheckedPALegSwitch}
        />
      </div>
      {isCheckedPALegSwitch && (
        <Row justify="space-between" style={{ padding: '0 16px 16px ' }}>
          <Col span={11} className="leg-content">
            <div>
              <div>Path Terminator</div>
              <div>{currentLegData?.parameters?.type}</div>
            </div>

            <div>
              <div>Length</div>
              <div>
                {currentLegData?.parameters?.dst_btw_wpt
                  ? currentLegData?.parameters?.dst_btw_wpt.toFixed(3)
                  : '-'}{' '}
                NM
              </div>
            </div>
          </Col>
          <Col>
            <CustomDivider type="vertical" className="leg-divider" />
          </Col>
          <Col span={11} className="leg-content">
            <div>
              <div>{currentLegData?.start_waypoint?.waypoint_name} Altitude</div>
              <div>{Number(currentLegData?.start_waypoint?.alt).toFixed(2)} ft</div>
            </div>
            <div>
              <div>{currentLegData.end_waypoint.waypoint_name} Altitude</div>
              <div>{Number(currentLegData.end_waypoint.alt).toFixed(2)} ft</div>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ProtectionAreaSwitch;

import React from 'react';
import { Col, Row, Typography } from 'antd';
import { capitalize, isEmpty } from 'lodash';
import CustomDivider from 'components/core/CustomDivider';
import { lightGrey2, typocolor } from 'constants/color.constant';
import { AreasemiwidthDepartureType } from 'store/design/types';
import { latToDms, lngToDms } from 'utils/utils';

const { Text } = Typography;

interface LegStartPointsDetailProps {
  areasemiwidthDeparture: AreasemiwidthDepartureType | null;
  flyModeIcon: any;
  activePrevLegDesignDataDeparture: any;
}

const LegStartPointsDetail = ({
  activePrevLegDesignDataDeparture,
  areasemiwidthDeparture,
  flyModeIcon
}: LegStartPointsDetailProps) => {
  return (
    <div className="start-point-content">
      <div className="der-content">
        <Text>Start Point Details</Text>
      </div>
      <Row justify="space-between" style={{ marginTop: 16, paddingBottom: 12 }}>
        <Col span={11} className="general-content">
          <div>
            <div style={{ color: lightGrey2 }}>Waypoint Name</div>
            <div style={{ color: typocolor }}>
              {!isEmpty(activePrevLegDesignDataDeparture)
                ? activePrevLegDesignDataDeparture.end_waypoint.waypoint_name
                : 'XXXXX'}
            </div>
          </div>
          <div>
            <div style={{ color: lightGrey2 }}>XTT</div>
            <div style={{ color: typocolor }}>
              {activePrevLegDesignDataDeparture
                ? `${activePrevLegDesignDataDeparture.end_waypoint.aswdetails?.xtt} NM`
                : areasemiwidthDeparture
                ? `${areasemiwidthDeparture.xtt} NM`
                : '-'}
            </div>
          </div>
          <div>
            <div style={{ color: lightGrey2 }}>ATT</div>
            <div style={{ color: typocolor }}>
              {activePrevLegDesignDataDeparture
                ? `${activePrevLegDesignDataDeparture.end_waypoint.aswdetails?.att} NM`
                : areasemiwidthDeparture
                ? `${areasemiwidthDeparture.att} NM`
                : '-'}
            </div>
          </div>
          <div>
            <div style={{ color: lightGrey2 }}>1/2 AW</div>
            <div style={{ color: typocolor }}>
              {activePrevLegDesignDataDeparture
                ? `${activePrevLegDesignDataDeparture.end_waypoint.aswdetails?.aw} NM`
                : areasemiwidthDeparture
                ? `${areasemiwidthDeparture.aw} NM`
                : '-'}
            </div>
          </div>
        </Col>
        <Col>
          <CustomDivider type="vertical" className="general-divider" />
        </Col>
        <Col span={11} className="general-content">
          <div>
            <div style={{ color: lightGrey2 }}>Waypoint Altitude</div>
            <div style={{ color: typocolor }}>
              {activePrevLegDesignDataDeparture
                ? Number(activePrevLegDesignDataDeparture.end_waypoint.alt).toFixed()
                : '-'}{' '}
              ft MSL
            </div>
          </div>
          <div>
            <div style={{ color: lightGrey2 }}>Fly Mode</div>
            <div style={{ color: typocolor, display: 'flex', gap: 5 }}>
              {flyModeIcon}
              {'  '}
              {activePrevLegDesignDataDeparture
                ? ` Fly ${capitalize(activePrevLegDesignDataDeparture.end_waypoint.waypoint_mode)} `
                : '-'}
            </div>
          </div>
          <div>
            <div style={{ color: lightGrey2 }}>LAT</div>
            <div style={{ color: typocolor }}>
              {!isEmpty(activePrevLegDesignDataDeparture)
                ? latToDms(activePrevLegDesignDataDeparture.end_waypoint.lat)
                : '-'}
            </div>
          </div>
          <div>
            <div style={{ color: lightGrey2 }}>LONG</div>
            <div style={{ color: typocolor }}>
              {!isEmpty(activePrevLegDesignDataDeparture)
                ? lngToDms(activePrevLegDesignDataDeparture.end_waypoint.lng)
                : '-'}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default LegStartPointsDetail;

import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import ReportContentCard from 'components/core/ReportContentCard';
import ReportSecTitleWithIcon from './ReportSecTitleWithIcon';

interface PreviewSection5Props {
  sec5: any;
  magVar: any;
}

const PreviewSection5 = ({ sec5, magVar }: PreviewSection5Props) => {
  const [procedureInstrctHtml, setProcedureInstrctHtml] = useState<string | null>(null);
  const [planViewHtml, setPlanViewHtml] = useState<string>(
    '<ol><li>ELEV, ALT IN FEET</li><li>DIST IN NM</li><li>BRG ARE MAG</li><li>MAG VAR   - 2019</li><li>GNSS REQUIRED</li><li>RNAV 1 OPERATIONS</li></ol>'
  );

  useEffect(() => {
    if (!isEmpty(sec5)) {
      setPlanViewHtml(
        !isEmpty(sec5.plan_view_notes)
          ? sec5.plan_view_notes
          : `<ol><li>ELEV, ALT IN FEET</li><li>DIST IN NM</li><li>BRG ARE MAG</li><li>MAG VAR  - ${magVar}</li><li>GNSS REQUIRED</li><li>RNAV 1 OPERATIONS</li></ol>`
      );
      setProcedureInstrctHtml(sec5.proc_instr || null);
    }
  }, [sec5]);

  return (
    <div className="report-code-content page-break">
      <ReportSecTitleWithIcon title="5. PLAN AND PROFILE VIEW" icon={null} />
      <div className="report-content-data">
        <div className="plan-profile-content">
          <ReportContentCard icon={null} title="PLAN VIEW NOTES :" />
          <div className="plan-view-note">
            <div
              className="ql-container ql-snow design-html"
              // eslint-disable-next-line
              dangerouslySetInnerHTML={{ __html: planViewHtml }}
            />
          </div>
          <ReportContentCard icon={null} title="PROCEDURE INSTRUCTION :" />
          <div className="procedure-instruct">
            <div
              className="ql-container ql-snow design-html"
              // eslint-disable-next-line
              dangerouslySetInnerHTML={{ __html: procedureInstrctHtml || '<p>No Data Found.</p>' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewSection5;

import { white } from 'constants/color.constant';
import { IconProps } from 'types/common.types';

const TornadoIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.2911 8.99016C15.4179 7.11696 12.381 7.11696 10.5086 8.99019C8.96047 10.5376 8.96047 13.0478 10.5088 14.5953C11.7967 15.8832 13.8848 15.8832 15.1722 14.5951C16.2519 13.5154 16.2519 11.7644 15.172 10.6845C14.2591 9.77167 12.778 9.77167 11.8644 10.6843C11.6165 10.932 11.6163 11.3339 11.864 11.5818C12.1117 11.8298 12.5136 11.83 12.7615 11.5822C13.1794 11.1647 13.8572 11.1647 14.2745 11.582C14.8587 12.1662 14.8587 13.1136 14.2745 13.6977C13.4827 14.49 12.1984 14.49 11.4062 13.6977C10.3537 12.6458 10.3537 10.9396 11.406 9.88771C12.783 8.51018 15.0161 8.51018 16.3935 9.88759C18.1765 11.6711 18.1765 14.5625 16.3935 16.3461C14.1022 18.6373 10.3873 18.6373 8.096 16.346C5.17008 13.4201 5.17008 8.67578 8.096 5.74987C8.34383 5.50204 8.34383 5.10021 8.096 4.85238C7.84817 4.60455 7.44633 4.60455 7.1985 4.85238C3.77693 8.27395 3.77693 13.8219 7.1985 17.2435C9.98547 20.0305 14.5041 20.0305 17.291 17.2435C19.5696 14.9642 19.5696 11.2694 17.2911 8.99016Z"
        fill={color}
      />
    </svg>
  );
};
TornadoIcon.defaultProps = {
  color: white,
  width: 24,
  height: 24,
  onClick: () => {}
};

export default TornadoIcon;

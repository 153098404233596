import { Button, Typography } from 'antd';
import Sider from 'antd/es/layout/Sider';
import { aeroReportLists } from 'constants/aeroReport.constant';
import ReportCardTitle from 'components/AeroReportContainer/ReportCardTitle';

const { Text } = Typography;

interface AeroReportSidebarProps {
  date: any;
  time: any;
  handleOnclickScrollId: (id: string) => void;
  handleHideShowPreviewReport: () => void;
}

const AeroReportSidebar = ({
  date,
  time,
  handleOnclickScrollId,
  handleHideShowPreviewReport
}: AeroReportSidebarProps) => {
  return (
    <Sider className="data-sidebar aero-report-sidebar" width={288}>
      <div className="data-started-sid">
        <Text>CFN REPORT</Text>
        <Text>
          Design started on : {date}; {time}
        </Text>
      </div>
      <div className="report-sidebar-content">
        {aeroReportLists.map((t: { id: string; title: string }, i: number) => (
          <ReportCardTitle
            key={i}
            title={t.title}
            id={t.id}
            handleOnclick={handleOnclickScrollId}
          />
        ))}
      </div>
      <div className="report-btn-content">
        <div>
          <Button type="primary" ghost onClick={handleHideShowPreviewReport}>
            Preview Report
          </Button>
          {/* <Button type="primary" ghost onClick={() => handleDownloadPdf()}>
            Download as PDF
          </Button> */}
        </div>
        <div>
          <Button type="primary">Submit for Review</Button>
        </div>
      </div>
    </Sider>
  );
};

export default AeroReportSidebar;

import { Button, Modal, Space, Typography } from 'antd';
import { useAppSelector } from 'hooks/useReduxHook';
import { UserRole } from 'store/users/types';
import './styles.less';

interface CreateNewDesignModalProps {
  isOpen: boolean;
  handleOpenCloseModal: (data: boolean) => void;
  handleCreatenewDesign: () => void;
  handleUserManagement: () => void;
}

const { Text } = Typography;

const CreateNewDesignModal = ({
  isOpen,
  handleOpenCloseModal,
  handleCreatenewDesign,
  handleUserManagement
}: CreateNewDesignModalProps) => {
  const currentUser = useAppSelector((state) => state.user.userSession);

  return (
    <Modal
      title=""
      centered
      open={isOpen}
      className="create-design-modal"
      onCancel={() => handleOpenCloseModal(false)}
      footer={null}
      width={521}
    >
      <Space direction="vertical" className="create-modal-content">
        <Text className="modal-content-title">
          {currentUser?.role === UserRole.Manager
            ? 'No Designs Created'
            : 'No Users Added to  Organisation'}
        </Text>
        <Space direction="vertical" align="center">
          {currentUser?.role === UserRole.Manager ? (
            <Button type="primary" onClick={handleCreatenewDesign}>
              Create New Design
            </Button>
          ) : (
            <Button type="primary" onClick={handleUserManagement}>
              User Management
            </Button>
          )}
          <Button onClick={() => handleOpenCloseModal(false)}>Cancel</Button>
        </Space>
      </Space>
    </Modal>
  );
};

export default CreateNewDesignModal;

import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { Button, InputNumber, Space, Typography } from 'antd';
import { TemperatureType } from 'store/design/types';

const { Text } = Typography;

interface MagneticDeclinationDataStockProps {
  magneticVariation: any;
  magneticVariationModal: string;
  designTemperature: TemperatureType | null;
  isDesigner: boolean;
  handleChangeMagneticVariation: (value: any) => void;
  isFetchingMagneticDeclination: boolean;
  handleAddtoStockMagneticVar: (isAdd: boolean) => void;
  handleChangeMagneticUnit: (magneticUnit: string) => void;
  magneticDeclinationUnit: string;
}

const MagneticDeclinationDataStock = ({
  magneticVariation,
  magneticVariationModal,
  designTemperature,
  isDesigner,
  handleChangeMagneticVariation,
  isFetchingMagneticDeclination,
  handleAddtoStockMagneticVar,
  handleChangeMagneticUnit,
  magneticDeclinationUnit
}: MagneticDeclinationDataStockProps) => {
  useEffect(() => {
    if (magneticVariationModal !== 'CONSTANT' && magneticVariation !== null) {
      if (magneticVariation < 0) {
        handleChangeMagneticUnit('w');
      } else {
        handleChangeMagneticUnit('e');
      }
    }
  }, [magneticVariation]);

  return (
    <div className="magnetic-lower">
      <Space direction="vertical" size={[0, 8]}>
        <Text>Magnetic Variation</Text>
        <InputNumber
          min={0}
          className={
            magneticVariationModal === 'IGRF' || magneticVariationModal === 'WMM'
              ? 'variation-input modal-input'
              : 'variation-input'
          }
          readOnly={magneticVariationModal === 'IGRF' || magneticVariationModal === 'WMM'}
          value={magneticVariation < 0 ? Math.abs(magneticVariation) : magneticVariation}
          disabled={
            !isEmpty(magneticVariation) ||
            isEmpty(magneticVariationModal) ||
            !isEmpty(designTemperature) ||
            !isDesigner
          }
          onChange={handleChangeMagneticVariation}
          addonAfter={
            <Button.Group className="variation-grp-btn">
              <Button
                type="primary"
                disabled={
                  isEmpty(magneticVariationModal) ||
                  magneticVariationModal === 'IGRF' ||
                  magneticVariationModal === 'WMM' ||
                  !isEmpty(designTemperature)
                }
                onClick={() => handleChangeMagneticUnit('w')}
                className={`${
                  magneticDeclinationUnit === 'w' ? 'elev-data-btn w-active-btn' : 'elev-data-btn'
                }`}
              >
                °W
              </Button>
              <Button
                type="primary"
                disabled={
                  isEmpty(magneticVariationModal) ||
                  magneticVariationModal === 'IGRF' ||
                  magneticVariationModal === 'WMM' ||
                  !isEmpty(designTemperature)
                }
                onClick={() => handleChangeMagneticUnit('e')}
                className={`${
                  magneticDeclinationUnit === 'e' ? 'elev-data-btn e-active-btn' : 'elev-data-btn'
                }`}
              >
                °E
              </Button>
            </Button.Group>
          }
        />
      </Space>
      <Space direction="vertical" size={[0, 24]} className="apply-btn-main">
        <Button
          type="primary"
          className="apply-btn"
          disabled={
            isEmpty(magneticVariationModal) ||
            magneticVariation === null ||
            !isEmpty(designTemperature) ||
            !isDesigner ||
            isFetchingMagneticDeclination
          }
          onClick={() => handleAddtoStockMagneticVar(true)}
        >
          Add to Data Stock
        </Button>
        <div className="magnetic-note-main">
          <Text className="magnetic-note">NOTE :</Text>
          <Text className="magnetic-note">
            The Magnetic Variation will be applied to all Procedures of this Project
          </Text>
        </div>
      </Space>
    </div>
  );
};

export default MagneticDeclinationDataStock;

import React, { useMemo } from 'react';
import { isEmpty } from 'lodash';
import { CoordinateType } from 'constants/common.constant';
import { Polyline } from 'react-leaflet';
import { blue } from 'constants/color.constant';

interface RenderWindspiralDataProps {
  windspiraldata: any;
}

const RenderWindspiralData = ({ windspiraldata }: RenderWindspiralDataProps) => {
  if (isEmpty(windspiraldata)) return null;
  const lineMemo = useMemo(() => {
    const coords = windspiraldata.coordinates;
    let coordinateData: any = [];
    if (windspiraldata.type === CoordinateType.MultilineString) {
      coordinateData = coords.map((points: any) => {
        return points.map((point: any) => {
          return [Number(point[1]), Number(point[0])];
        });
      });
    } else if (windspiraldata.type === CoordinateType.LineString) {
      coordinateData = coords.map((point: any) => {
        return [Number(point[1]), Number(point[0])];
      });
    }
    if (isEmpty(coordinateData)) return null;

    return (
      <Polyline
        pane="shadowPane"
        pathOptions={{ color: blue, weight: 2 }}
        positions={coordinateData}
      />
    );
  }, [windspiraldata]);

  // eslint-disable-next-line
  return <>{lineMemo}</>;
};

export default RenderWindspiralData;

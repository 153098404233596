import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { Input, Radio, RadioChangeEvent, Row, Typography } from 'antd';
import DownloadPdfIcon from 'assets/svg/DownloadPdfIcon';
import ListDataCard from 'components/core/ListDataCard';
import ReportSecTitleWithIcon from './ReportSecTitleWithIcon';

const { Text } = Typography;

interface Section2Props {
  sec2: any;
  sec3: any;
  scrollElementRef: any;
  handleCallSaveAeroReportDataApi: (data: { section_key: string; fields: any }) => void;
}

const Section2 = ({
  sec2,
  sec3,
  scrollElementRef,
  handleCallSaveAeroReportDataApi
}: Section2Props) => {
  const [procedureType, setProcedureType] = useState('');
  const [procedureRemark, setProcedureRemark] = useState<string | null>(null);

  useEffect(() => {
    if (!isEmpty(sec2)) {
      setProcedureRemark(sec2.remark || null);
      if (sec2.proc_type) {
        setProcedureType(sec2.proc_type);
      }
    }
  }, [sec2]);

  const handleOnchangeProcedureType = (e: RadioChangeEvent) => {
    setProcedureType(e.target.value);
  };

  const handleOnchangeProcedureRemark = (e: any) => {
    setProcedureRemark(e.target.value);
  };

  const handleSaveProcedureDetails = () => {
    const payload: { section_key: string; fields: any } = {
      section_key: 'sec_2',
      fields: {}
    };
    if (!isEmpty(procedureType)) {
      payload.fields.proc_type = procedureType;
    }
    if (!isEmpty(procedureRemark)) {
      payload.fields.remark = procedureRemark;
    }
    handleCallSaveAeroReportDataApi(payload);
  };

  return (
    <div className="report-code-content sec-2" ref={scrollElementRef} id="procedure">
      <ReportSecTitleWithIcon
        title="2. PROCEDURE DETAILS"
        icon={<DownloadPdfIcon />}
        handleSaveData={handleSaveProcedureDetails}
      />
      <div className="report-content-data">
        <div className="procedure-detail-content">
          <div className="procedure-type-list">
            <Text>Procedure Type</Text>
            <Radio.Group
              className="type-list"
              value={procedureType}
              onChange={handleOnchangeProcedureType}
            >
              <div>
                <Radio value="NEW" />
                <Text className="list-title">NEW</Text>
              </div>
              <div>
                <Radio value="REVISED" />
                <Text className="list-title">REVISED</Text>
              </div>
            </Radio.Group>
          </div>
          <Row className="type-data-list">
            <ListDataCard title="Procedure Method" listData={['ICAO PANS - OPS']} />
            <ListDataCard title="State Criteria" listData={['DGCA']} />
            <ListDataCard title="Procedure ID" listData={[sec2?.proc_id || '']} />
            <ListDataCard
              title="Type of Procedure Design"
              listData={[sec2?.proc_design_type || '']}
            />
            <ListDataCard title="Type of Navigation System" listData={[sec2?.nav_sys_type || '']} />
            <ListDataCard
              title="Navigation Specification"
              listData={[sec2?.nav_spec || '']}
              info={`${sec2?.nav_spec} is required for operating on these IFPs`}
            />
            <ListDataCard title="Categories of Aircraft" listData={[sec3?.acft_cat || '']} />
            <ListDataCard title="Total Number of Procedure" listData={[sec2?.num_of_proc || '0']} />
          </Row>
          <Row className="type-data-list">
            <div className="list-data-card" style={{ width: '552px' }}>
              <Text>Remark</Text>
              <Input
                autoComplete="off"
                placeholder="Type here"
                value={procedureRemark || undefined}
                onChange={handleOnchangeProcedureRemark}
              />
            </div>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Section2;

import { FocusEvent, useEffect, useState } from 'react';
import { debounce, isEmpty } from 'lodash';
import { Button, Col, InputNumber, Row, Select, Typography } from 'antd';
import DownArrow from 'assets/svg/DownArrow';
import InfoCircleIcon from 'assets/svg/InfoCircleIcon';
import FlybyWaypointIcon from 'assets/svg/FlybyWaypointIcon';
import FlyOverWaypointIcon from 'assets/svg/FlyOverWaypointIcon';
import { typocolor, primary, red } from 'constants/color.constant';
import CustomDivider from 'components/core/CustomDivider';
import {
  InfobtwpointsResponseType,
  InitStartInfoType,
  WaypointMarkerResponseType,
  AreasemiwidthDepartureType,
  OAlegdata,
  CourseRangeResponseType
} from 'store/design/types';
import { useLazyGetDesignInfobtwPointsdataQuery } from 'services/apis/designsApi';
import { PathTerminatorOptionsType } from 'types/common.types';
import { latToDms, lngToDms } from 'utils/utils';
import AltGradiantLeg from './AltGradiantLeg';
import EndPointLeg from './EndPointLeg';
import PAOALeg from './PAOALeg';
import TurnLeg from './TurnLeg';
import SpeedLeg from './SpeedLeg';
import LegStartPointsDetail from './LegStartPointsDetail';

const { Text } = Typography;
const { Option } = Select;

interface LegDataProps {
  isDesigner: boolean;
  activeLegDesignDataDeparture: any;
  activePrevLegDesignDataDeparture: any;
  endWaypointLatLngDeparture: any;
  activeLegKey: string;
  initStartInfoDeparture: InitStartInfoType | null;
  selectedWayPointMarker: WaypointMarkerResponseType | null;
  handleShowObstacleTableDeparture: (isShow: boolean) => void;
  handleChangeWaypoint: (waypoint: string) => void;
  handleViewSelectWaypointOnMap: (waypoint: string) => void;
  activeOALegDesignDataDeparture: OAlegdata[] | [];
  handleActiveFlyBtn: (activeBtn: string) => void;
  activeDeptBtnDeparture: string;
  handleActiveLegModuleTabBtn: (activeDepBtn: string) => void;
  handleEndWaypointLat: (waypointlat: number | undefined) => void;
  handleEndWaypointLng: (waypointlng: number | undefined) => void;
  handleEnterLatLngViewOnMap: (isView: boolean) => void;
  handleChangeTerminatingWaypoint: (isTerminate: boolean) => void;
  handleChangeRemarksDepOmni: (isTerminate: string | undefined) => void;
  endSelectNavaidWaypoint: string | null;
  endAltitudeDeparture: number | null;
  pdgDeparture: number | null;
  radiusOfTurn: number | null;
  courseDeparture: number | null;
  iasSpeedDeparture: number | null;
  gradLengthDeparture: number | null;
  gradHeightDeparture: number | null;
  tasSpeedDeparture: number | null;
  magneticDeclination: number | null;
  selectedPathTerminatorDeparture: string | undefined;
  endWaypointLat: number | null;
  endWaypointLng: number | null;
  cwyDisDeparture: number | null;
  changeCWYPointInfoDataDeparture: InfobtwpointsResponseType | null;
  activeFlyBtn: string;
  activeObscleTerrainDataKey: string;
  isGeneratedOA: boolean;
  isTerminatingWaypoint: boolean;
  isLoadingAddRemarkData: boolean;
  isShowHideRemarkDepOmniBtn: boolean;
  remarksDepOmni: string | undefined;
  handleAddUpdateRemarkDepOmni: Function;
  isShowHideTerrainLayerDeparture: boolean;
  isShowHideOADataDeparture: boolean;
  handleGeneratePADataDeparture: Function;
  handleGenerateOADataDeparture: Function;
  handleChangePathTerminatorDeparture: Function;
  handleChangeCWYSetInitInfoDataDeparture: Function;
  handleChangeCWYdisInputDeparture: Function;
  handleOnChangeDataReviewIAS: Function;
  handleChangeAltitudeDeparture: Function;
  handleChangePDGDeparture: Function;
  handleChangeCFCourseDept: Function;
  handleClickOnSpeedTabDeparture: Function;
  handleShowTerrainLayerDeparture: Function;
  handleRefreshGeneratedOAdata: Function;
  turnDirectionDeparture: string | null;
  turnAngleDeparture: number | null;
  CFCourseDept: number | undefined;
  areasemiwidthDeparture: AreasemiwidthDepartureType | null;
  courseRangeDataDept: CourseRangeResponseType[] | [];
  pathTerminatorOptions: PathTerminatorOptionsType[] | [];
}

const LegData = ({
  isDesigner,
  activeLegKey,
  radiusOfTurn,
  activeLegDesignDataDeparture,
  gradHeightDeparture,
  isShowHideTerrainLayerDeparture,
  activePrevLegDesignDataDeparture,
  activeOALegDesignDataDeparture,
  endWaypointLatLngDeparture,
  initStartInfoDeparture,
  selectedWayPointMarker,
  endAltitudeDeparture,
  pdgDeparture,
  pathTerminatorOptions,
  endSelectNavaidWaypoint,
  selectedPathTerminatorDeparture,
  endWaypointLat,
  endWaypointLng,
  activeFlyBtn,
  isShowHideOADataDeparture,
  cwyDisDeparture,
  iasSpeedDeparture,
  tasSpeedDeparture,
  gradLengthDeparture,
  turnDirectionDeparture,
  turnAngleDeparture,
  isGeneratedOA,
  magneticDeclination,
  courseDeparture,
  areasemiwidthDeparture,
  changeCWYPointInfoDataDeparture,
  activeObscleTerrainDataKey,
  activeDeptBtnDeparture,
  // eslint-disable-next-line
  CFCourseDept,
  courseRangeDataDept,
  isLoadingAddRemarkData,
  isTerminatingWaypoint,
  isShowHideRemarkDepOmniBtn,
  remarksDepOmni,
  handleActiveLegModuleTabBtn,
  handleShowObstacleTableDeparture,
  handleChangeWaypoint,
  handleViewSelectWaypointOnMap,
  handleActiveFlyBtn,
  handleEndWaypointLat,
  handleEndWaypointLng,
  handleEnterLatLngViewOnMap,
  handleChangeTerminatingWaypoint,
  handleChangeRemarksDepOmni,
  handleAddUpdateRemarkDepOmni,
  handleShowTerrainLayerDeparture,
  handleChangePathTerminatorDeparture,
  handleGeneratePADataDeparture,
  handleGenerateOADataDeparture,
  handleChangeCWYSetInitInfoDataDeparture,
  handleChangeCWYdisInputDeparture,
  handleOnChangeDataReviewIAS,
  handleChangeAltitudeDeparture,
  handleChangePDGDeparture,
  handleClickOnSpeedTabDeparture,
  handleChangeCFCourseDept,
  handleRefreshGeneratedOAdata
}: LegDataProps) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [activeCourseSuffix, setActiveCourseSuffix] = useState<string>('t');
  const [endHeightDeparture, setEndHeightDeparture] = useState<number | null>(null);

  const [getDesignInfobtwPointsdata, { data: infobtwPointsDataChangeCWY }] =
    useLazyGetDesignInfobtwPointsdataQuery();
  const flyModeIcon =
    activePrevLegDesignDataDeparture?.end_waypoint?.waypoint_mode === 'by' ? (
      <FlybyWaypointIcon color={typocolor} />
    ) : activePrevLegDesignDataDeparture?.end_waypoint?.waypoint_mode === 'over' ? (
      <FlyOverWaypointIcon color={typocolor} />
    ) : (
      ''
    );

  useEffect(() => {
    if (infobtwPointsDataChangeCWY) {
      const infobtwPointsDataChangeCWYRes: any = infobtwPointsDataChangeCWY;
      if (infobtwPointsDataChangeCWYRes.status) {
        if (!isEmpty(infobtwPointsDataChangeCWYRes.data)) {
          handleChangeCWYSetInitInfoDataDeparture(infobtwPointsDataChangeCWYRes.data || null);
        }
      }
    }
  }, [infobtwPointsDataChangeCWY]);

  useEffect(() => {
    if (activeLegKey === '1') {
      setIsCollapsed(true);
    } else {
      setIsCollapsed(false);
    }
  }, [activeLegKey]);

  useEffect(() => {
    if (endAltitudeDeparture) {
      const endHeight =
        Number(endAltitudeDeparture) - Number(initStartInfoDeparture?.der_elev || 0);
      setEndHeightDeparture(endHeight);
    }
  }, [endAltitudeDeparture]);

  const handleCollapseStartPoint = () => {
    if (activeLegKey !== '1') {
      setIsCollapsed(!isCollapsed);
    }
  };

  // eslint-disable-next-line
  const handleChangeClearwayDisDeparture = async (e: FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (!value) return;
    handleChangeCWYdisInputDeparture(value);
    if (initStartInfoDeparture) {
      const latA = initStartInfoDeparture.thr_point[0];
      const lngB = initStartInfoDeparture.thr_point[1];
      const distance = (initStartInfoDeparture.cwy / 1852).toFixed(10);
      const bearingdata = initStartInfoDeparture.bearing;
      const urlString = `/infobtwpoints?lat_a=${latA}&lng_a=${lngB}&distance=${distance}&bearing=${bearingdata}`;

      await getDesignInfobtwPointsdata({ urlString });
    }
  };

  const handleOnchangeAltitudeDeparture = debounce((value: number | null) => {
    handleChangeAltitudeDeparture(value);
  }, 1000);

  const handleOnchangePDGDeparture = debounce((value: number | null) => {
    handleChangePDGDeparture(value);
  }, 1000);

  const handleChangeCourseDept = debounce((value: number | null) => {
    if (Number(CFCourseDept) === Number(value)) return;
    if (Number(CFCourseDept) === Number(courseDeparture)) return;

    handleChangeCFCourseDept(value);
  }, 1000);

  const course = !isEmpty(activeLegDesignDataDeparture)
    ? activeCourseSuffix === 't'
      ? Number(activeLegDesignDataDeparture.parameters.bearing.toFixed(3))
      : Number(
          (
            Number(activeLegDesignDataDeparture.parameters.bearing) - Number(magneticDeclination)
          ).toFixed(3)
        )
    : courseDeparture
    ? activeCourseSuffix === 't'
      ? courseDeparture?.toFixed(3)
      : Number((Number(courseDeparture) - Number(magneticDeclination)).toFixed(3))
    : undefined;

  const cfCourse = !isEmpty(activeLegDesignDataDeparture)
    ? activeCourseSuffix === 't'
      ? Number(activeLegDesignDataDeparture.parameters.bearing.toFixed(3))
      : Number(
          (
            Number(activeLegDesignDataDeparture.parameters.bearing) - Number(magneticDeclination)
          ).toFixed(3)
        )
    : CFCourseDept
    ? activeCourseSuffix === 't'
      ? CFCourseDept
      : Number((Number(CFCourseDept) - Number(magneticDeclination)).toFixed(3))
    : undefined;

  const isValidCFCourseDept = isEmpty(courseRangeDataDept)
    ? true
    : courseRangeDataDept.some(
        (range) => Number(CFCourseDept) >= range.min && Number(CFCourseDept) <= range.max
      );

  const minalt: number = Number(initStartInfoDeparture?.der_elev) || 0;

  return (
    <div className="leg-main-content dept-leg-main-content">
      <div className="point-details">
        <div className="start-point-main">
          <div>
            <div onClick={handleCollapseStartPoint}>
              <Text>
                {activeLegKey === '1'
                  ? 'Start Point'
                  : `Start Point ${
                      !isEmpty(activePrevLegDesignDataDeparture)
                        ? `(${activePrevLegDesignDataDeparture.end_waypoint.waypoint_name})`
                        : '(XXXXX)'
                    }`}
              </Text>
              {activeLegKey !== '1' && (
                <div
                  className={
                    isCollapsed ? 'collapsed-content expanded-content' : 'expanded-content'
                  }
                >
                  <DownArrow color={primary} />
                </div>
              )}
            </div>
          </div>
          {activeLegKey === '1' && (
            <div className="start-point-content">
              <div className="der-content">
                <Text>DER Details</Text>
                <Text>
                  <InfoCircleIcon color={red} />
                  <Text>DER coordinates are derived based on clear way distance.</Text>
                </Text>
              </div>
              <Row justify="space-between" style={{ marginTop: 16, paddingBottom: 12 }}>
                <Col span={11} className="general-content">
                  {/* <div className="clearway-dist">
                    <div>Clearway Dis.</div>
                    <InputNumber
                      placeholder="Enter"
                      type="number"
                      suffix={<Text>m</Text>}
                      min={150}
                      max={500}
                      readOnly
                      disabled={!isEmpty(activeLegDesignDataDeparture) || !isDesigner}
                      value={
                        activeLegDesignDataDeparture
                          ? activeLegDesignDataDeparture.parameters.cwy
                          : cwyDisDeparture
                      }
                      onBlur={handleChangeClearwayDisDeparture}
                      style={{ width: 100, color: primary, border: `0.5px solid ${primary}` }}
                    />
                  </div> */}
                  <div>
                    <div>Clearway Dis.</div>
                    <div>
                      {activeLegDesignDataDeparture
                        ? activeLegDesignDataDeparture.parameters.cwy
                        : cwyDisDeparture}{' '}
                      m
                    </div>
                  </div>
                  <div>
                    <div>Start Altitude</div>
                    <div>
                      {!isEmpty(activeLegDesignDataDeparture)
                        ? Number(activeLegDesignDataDeparture.start_waypoint.alt).toFixed(3)
                        : initStartInfoDeparture?.der_elev}{' '}
                      ft MSL
                    </div>
                  </div>
                  <div className="max-der-text">
                    <InfoCircleIcon color={red} />
                    <Text className="max-der-text">MAX(DER,AD ELEV) +5 m</Text>
                  </div>
                </Col>
                <Col>
                  <CustomDivider type="vertical" className="general-divider" />
                </Col>
                <Col span={11} className="general-content">
                  <div style={{ marginTop: 8 }}>
                    <div>LAT</div>
                    <div style={{ color: typocolor }}>
                      {!isEmpty(activeLegDesignDataDeparture)
                        ? latToDms(activeLegDesignDataDeparture.start_waypoint.lat)
                        : isEmpty(activeLegDesignDataDeparture) &&
                          !isEmpty(changeCWYPointInfoDataDeparture)
                        ? latToDms(changeCWYPointInfoDataDeparture.B[0])
                        : initStartInfoDeparture
                        ? latToDms(initStartInfoDeparture.der[0])
                        : '-'}
                    </div>
                  </div>
                  <div>
                    <div>LONG</div>
                    <div style={{ color: typocolor }}>
                      {!isEmpty(activeLegDesignDataDeparture)
                        ? lngToDms(activeLegDesignDataDeparture.start_waypoint.lng)
                        : isEmpty(activeLegDesignDataDeparture) &&
                          !isEmpty(changeCWYPointInfoDataDeparture)
                        ? lngToDms(changeCWYPointInfoDataDeparture.B[1])
                        : initStartInfoDeparture
                        ? lngToDms(initStartInfoDeparture.der[1])
                        : '-'}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          )}
          {activeLegKey !== '1' && isCollapsed && (
            <LegStartPointsDetail
              activePrevLegDesignDataDeparture={activePrevLegDesignDataDeparture}
              areasemiwidthDeparture={areasemiwidthDeparture}
              flyModeIcon={flyModeIcon}
            />
          )}
        </div>
        <div className="turn-type-content">
          <div>
            <div>Path Terminator</div>
            <div>
              <Select
                disabled={!isDesigner || !isEmpty(activeLegDesignDataDeparture)}
                className="path-terminator-select"
                style={{ width: 216, color: `${primary}` }}
                suffixIcon={<DownArrow />}
                value={selectedPathTerminatorDeparture}
                onChange={(value) => {
                  handleChangePathTerminatorDeparture(value);
                }}
                placeholder="Type of Fix"
              >
                {pathTerminatorOptions &&
                  pathTerminatorOptions.map((type: PathTerminatorOptionsType, ind: number) => {
                    return (
                      <Option
                        disabled={!type.is_active}
                        key={ind}
                        value={type.path_terminators_code}
                      >
                        {/* {type.path_terminator_name} */}
                        {`${type.path_terminator_name} (${type.path_terminators_code})`}
                      </Option>
                    );
                  })}
              </Select>
            </div>
          </div>
          {(selectedPathTerminatorDeparture === 'CA' || selectedPathTerminatorDeparture === 'CF') &&
            (Number(activeLegKey) === 1 ||
              (turnAngleDeparture === 0 && Number(activeLegKey) > 1)) && (
              <div>
                <div style={{ paddingLeft: 9 }}>Course</div>
                <div className="course-div">
                  <InputNumber
                    style={{
                      width: 98,
                      border: `0.5px solid ${primary}`
                    }}
                    readOnly
                    value={course}
                    placeholder="32° 45′ 34″"
                  />
                  <Button.Group className="course-grp-btn">
                    <Button
                      className={
                        activeCourseSuffix === 't' ? 'active-view-btn-t course-btn' : 'course-btn'
                      }
                      onClick={() => setActiveCourseSuffix('t')}
                    >
                      °T
                    </Button>
                    <Button
                      className={
                        activeCourseSuffix === 'm' ? 'active-view-btn-m course-btn' : 'course-btn'
                      }
                      onClick={() => setActiveCourseSuffix('m')}
                    >
                      °M
                    </Button>
                  </Button.Group>
                </div>
              </div>
            )}
          {selectedPathTerminatorDeparture === 'CF' &&
            turnAngleDeparture !== 0 &&
            Number(activeLegKey) > 1 && (
              <>
                <div>
                  <div style={{ paddingLeft: 9 }}>Course</div>
                  <div
                    // className={
                    //   isValidCFCourseDept || !isEmpty(activeLegDesignDataDeparture)
                    //     ? 'course-div'
                    //     : 'course-div error-course-div'
                    // }
                    className="course-div"
                  >
                    <InputNumber
                      style={{
                        width: 98,
                        border: `0.5px solid ${isValidCFCourseDept ? primary : red}`
                      }}
                      readOnly={!isEmpty(activeLegDesignDataDeparture)}
                      // className="change-course-input"
                      disabled={isEmpty(endWaypointLatLngDeparture)}
                      value={cfCourse}
                      onChange={
                        !isEmpty(activeLegDesignDataDeparture) ? () => {} : handleChangeCourseDept
                      }
                      placeholder="Enter here"
                    />
                    <Button.Group className="course-grp-btn">
                      <Button
                        className={
                          activeCourseSuffix === 't' ? 'active-view-btn-t course-btn' : 'course-btn'
                        }
                        onClick={() =>
                          isEmpty(activeLegDesignDataDeparture)
                            ? () => {}
                            : setActiveCourseSuffix('t')
                        }
                      >
                        °T
                      </Button>
                      <Button
                        disabled={isEmpty(activeLegDesignDataDeparture)}
                        className={
                          activeCourseSuffix === 'm' ? 'active-view-btn-m course-btn' : 'course-btn'
                        }
                        onClick={() =>
                          isEmpty(activeLegDesignDataDeparture)
                            ? () => {}
                            : setActiveCourseSuffix('m')
                        }
                      >
                        °M
                      </Button>
                    </Button.Group>
                  </div>
                </div>
                {!isEmpty(courseRangeDataDept) && (
                  <Text className="course-info">
                    <InfoCircleIcon color={red} />
                    <Text className="course-info-text">
                      Course range:{' '}
                      {courseRangeDataDept.map(
                        (data: CourseRangeResponseType, i: number) =>
                          `(Min.: ${data.min}, Max.: ${data.max}) ${
                            courseRangeDataDept.length > 1 &&
                            !(courseRangeDataDept.length - 1 === i)
                              ? ' OR '
                              : ''
                          }`
                      )}
                    </Text>
                  </Text>
                )}
              </>
            )}
          {selectedPathTerminatorDeparture === 'CA' && (
            <>
              <div>
                <div style={{ paddingLeft: 9 }}>Altitude</div>
                <div
                  className={
                    Number(activeLegKey) === 1 && !(Number(endAltitudeDeparture) >= 400 + minalt)
                      ? 'alt-range-error'
                      : ''
                  }
                >
                  <InputNumber
                    readOnly={!isEmpty(activeLegDesignDataDeparture)}
                    // min={
                    //   Number(activeLegKey) === 1 && gradHeightDeparture
                    //     ? Number(gradHeightDeparture) + Number(initStartInfoDeparture?.der_elev) - 1
                    //     : 399
                    // }
                    style={{
                      width: 184,
                      border: `0.5px solid ${
                        !(Number(endAltitudeDeparture) >= 400 + minalt) ? red : primary
                      }`
                    }}
                    suffix="ft"
                    value={
                      activeLegDesignDataDeparture
                        ? activeLegDesignDataDeparture.end_waypoint.alt
                        : endAltitudeDeparture || undefined
                    }
                    onChange={handleOnchangeAltitudeDeparture}
                    placeholder="Enter Altitude"
                  />
                  {Number(activeLegKey) === 1 &&
                    endHeightDeparture &&
                    Number(endHeightDeparture) <= 399 && (
                      <div className="alt-range-nfo">
                        <InfoCircleIcon color={red} height={16} width={16} />
                        {Number(activeLegKey) === 1 &&
                          `Minimum Altitude :  ${400 + Number(initStartInfoDeparture?.der_elev)}.`}
                      </div>
                    )}
                </div>
              </div>
              <div>
                <div style={{ paddingLeft: 9 }}>PDG</div>
                <div>
                  <InputNumber
                    readOnly={!isEmpty(activeLegDesignDataDeparture)}
                    min={3.3}
                    max={10}
                    step={0.1}
                    value={pdgDeparture || undefined}
                    onChange={handleOnchangePDGDeparture}
                    style={{ width: 184, border: `0.5px solid ${primary}` }}
                    suffix="%"
                    placeholder="Enter PDG"
                  />
                </div>
              </div>
            </>
          )}
        </div>
        <div className="tab-extra-content">
          {/* <CustomDivider /> */}
          <div className="tab-group">
            <Button.Group className="point-btn-grp">
              <Button
                className={activeDeptBtnDeparture === 'endpoint' ? 'point-active-btn' : ''}
                onClick={() => handleActiveLegModuleTabBtn('endpoint')}
              >
                End Point
              </Button>
              <Button
                className={activeDeptBtnDeparture === 'alt-grad' ? 'point-active-btn' : ''}
                onClick={() => handleActiveLegModuleTabBtn('alt-grad')}
              >
                Altitude/Gradient
              </Button>
              <Button
                className={activeDeptBtnDeparture === 'turn' ? 'point-active-btn' : ''}
                onClick={() => handleActiveLegModuleTabBtn('turn')}
              >
                Turn
              </Button>
              <Button
                className={activeDeptBtnDeparture === 'speed' ? 'point-active-btn' : ''}
                onClick={() => {
                  handleActiveLegModuleTabBtn('speed');
                  handleClickOnSpeedTabDeparture();
                }}
              >
                Speed
              </Button>
              <Button
                className={
                  activeDeptBtnDeparture === 'pa-oa'
                    ? 'paoa-btn point-active-btn paoa-btn-active-btn'
                    : 'paoa-btn'
                }
                onClick={() => handleActiveLegModuleTabBtn('pa-oa')}
              >
                PA/OA
              </Button>
            </Button.Group>
          </div>
          {activeDeptBtnDeparture === 'endpoint' && (
            <EndPointLeg
              isDesigner={isDesigner}
              initStartInfoDeparture={initStartInfoDeparture}
              activePrevLegDesignDataDeparture={activePrevLegDesignDataDeparture}
              endWaypointLatLngDeparture={endWaypointLatLngDeparture}
              areasemiwidthDeparture={areasemiwidthDeparture}
              activeLegDesignDataDeparture={activeLegDesignDataDeparture}
              activeLegKey={activeLegKey}
              selectedWayPointMarker={selectedWayPointMarker}
              selectedPathTerminatorDeparture={selectedPathTerminatorDeparture}
              handleChangeWaypoint={handleChangeWaypoint}
              handleViewSelectWaypointOnMap={handleViewSelectWaypointOnMap}
              handleActiveFlyBtn={handleActiveFlyBtn}
              endSelectNavaidWaypoint={endSelectNavaidWaypoint}
              activeFlyBtn={activeFlyBtn}
              handleEndWaypointLat={handleEndWaypointLat}
              handleEndWaypointLng={handleEndWaypointLng}
              endWaypointLat={endWaypointLat}
              endWaypointLng={endWaypointLng}
              endAltitudeDeparture={endAltitudeDeparture}
              isTerminatingWaypoint={isTerminatingWaypoint}
              handleEnterLatLngViewOnMap={handleEnterLatLngViewOnMap}
              handleChangeTerminatingWaypoint={handleChangeTerminatingWaypoint}
            />
          )}
          {activeDeptBtnDeparture === 'alt-grad' && (
            <AltGradiantLeg
              endAltitudeDeparture={endAltitudeDeparture}
              pdgDeparture={pdgDeparture}
              selectedPathTerminatorDeparture={selectedPathTerminatorDeparture}
              gradHeightDeparture={gradHeightDeparture}
              activeLegDesignDataDeparture={activeLegDesignDataDeparture}
              initStartInfoDeparture={initStartInfoDeparture}
              gradLengthDeparture={gradLengthDeparture}
              activePrevLegDesignDataDeparture={activePrevLegDesignDataDeparture}
              handleChangeAltitudeDeparture={handleChangeAltitudeDeparture}
              handleChangePDGDeparture={handleChangePDGDeparture}
            />
          )}
          {activeDeptBtnDeparture === 'turn' && (
            <TurnLeg
              turnDirectionDeparture={turnDirectionDeparture}
              activeLegDesignDataDeparture={activeLegDesignDataDeparture}
              turnAngleDeparture={turnAngleDeparture}
              courseDeparture={courseDeparture}
              activeCourseSuffix={activeCourseSuffix}
              setActiveCourseSuffix={setActiveCourseSuffix}
              magneticDeclination={magneticDeclination}
              selectedPathTerminatorDeparture={selectedPathTerminatorDeparture}
              activePrevLegDesignDataDeparture={activePrevLegDesignDataDeparture}
              initStartInfoDeparture={initStartInfoDeparture}
              radiusOfTurn={radiusOfTurn}
              activeLegKey={activeLegKey}
            />
          )}
          {activeDeptBtnDeparture === 'speed' && (
            <SpeedLeg
              isDesigner={isDesigner}
              activeLegDesignDataDeparture={activeLegDesignDataDeparture}
              iasSpeedDeparture={iasSpeedDeparture}
              tasSpeedDeparture={tasSpeedDeparture}
              endAltitudeDeparture={endAltitudeDeparture}
              handleOnChangeDataReviewIAS={handleOnChangeDataReviewIAS}
            />
          )}
          {activeDeptBtnDeparture === 'pa-oa' && (
            <PAOALeg
              isDesigner={isDesigner}
              isGeneratedOA={isGeneratedOA}
              handleShowObstacleTableDeparture={handleShowObstacleTableDeparture}
              isShowHideOADataDeparture={isShowHideOADataDeparture}
              handleGeneratePADataDeparture={handleGeneratePADataDeparture}
              handleGenerateOADataDeparture={handleGenerateOADataDeparture}
              activeLegDesignDataDeparture={activeLegDesignDataDeparture}
              activeOALegDesignDataDeparture={activeOALegDesignDataDeparture}
              isShowHideTerrainLayerDeparture={isShowHideTerrainLayerDeparture}
              isLoadingAddRemarkData={isLoadingAddRemarkData}
              isShowHideRemarkDepOmniBtn={isShowHideRemarkDepOmniBtn}
              handleShowTerrainLayerDeparture={handleShowTerrainLayerDeparture}
              remarksDepOmni={remarksDepOmni}
              handleChangeRemarksDepOmni={handleChangeRemarksDepOmni}
              handleAddUpdateRemarkDepOmni={handleAddUpdateRemarkDepOmni}
              handleRefreshGeneratedOAdata={handleRefreshGeneratedOAdata}
              activeObscleTerrainDataKey={activeObscleTerrainDataKey}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default LegData;

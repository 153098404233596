export const designationAdmin = [
  { id: 1, label: 'General Manager', value: 'General Manager' },
  { id: 2, label: 'Joint General Manager', value: 'Joint General Manager' },
  { id: 3, label: 'Deputy General Manager', value: 'Deputy General Manager' },
  { id: 4, label: 'Assistant General Manager', value: 'Assistant General Manager' },
  { id: 5, label: 'Senior Manager', value: 'Senior Manager' },
  { id: 6, label: 'Manager', value: 'Manager' },
  { id: 7, label: 'Junior Manager', value: 'Junior Manager' }
];

export const memberRole: any = [
  { label: 'Designer', value: 'designer' },
  { label: 'Reviewer', value: 'reviewer' }
  // { label: 'Designer / Reviewer', value: 'both' },
  // { label: 'Trainee Designer', value: 'trainee' }
];

export const adminMemberRole: any = [
  { label: 'Manager', value: 'manager' },
  { label: 'Designer', value: 'designer' },
  { label: 'Reviewer', value: 'reviewer' }
  // { label: 'Designer / Reviewer', value: 'both' }
];

export const statusbuttonText: { [key: string]: string } = {
  Assigned: 'Get Started',
  Designing: 'Continue',
  'In Review': 'Continue',
  Reviewed: 'Continue'
};

import { keys } from 'lodash';
import AircraftIconV1 from 'assets/svg/AircraftIconV1';
import ReportContentCard from 'components/core/ReportContentCard';
import { CodingTableDataType } from 'store/design/types';
import ReportSecTitleWithIcon from './ReportSecTitleWithIcon';
import CodingTable from './CodingTable';

interface Section12Props {
  scrollElementRef: any;
  sec12: { [key: string]: CodingTableDataType[] | [] } | null;
}

const Section12 = ({ scrollElementRef, sec12 }: Section12Props) => {
  return (
    <div className="report-code-content page-break" ref={scrollElementRef} id="codingtable">
      <ReportSecTitleWithIcon title="12. CODING TABLE" icon={null} handleSaveData={() => {}} />
      <div className="report-content-data">
        {sec12 &&
          keys(sec12).map((ct: string) => (
            <div className="coding-table-content" key={ct}>
              <ReportContentCard icon={<AircraftIconV1 height={16} />} title={ct} />
              <div className="obstacle-table">
                <CodingTable codingData={sec12[`${ct}`] || []} />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Section12;

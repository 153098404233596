import { useEffect } from 'react';
import L from 'leaflet';
import { useMap } from 'react-leaflet';
import { defaultCenter } from 'constants/common.constant';
import { isEmpty } from 'lodash';

interface LegBoundBoxProps {
  line: any;
  center?: any | null | undefined;
}

const LegBoundBox = ({ center = defaultCenter, line }: LegBoundBoxProps) => {
  const map = useMap();

  // @ts-ignore
  useEffect(() => {
    if (!center) return;
    if (!map) return;
    if (isEmpty(line)) return;
    const jsonData = L.geoJSON(line);

    map.fitBounds(jsonData.getBounds());
    // eslint-disable-next-line
    return () => map.removeLayer(jsonData);
  }, [center, line]); // eslint-disable-line

  return null;
};

LegBoundBox.defaultProps = {
  center: defaultCenter
};

export default LegBoundBox;

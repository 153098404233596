import { isEmpty } from 'lodash';
import { GET_GEOSERVER_LEGEND_URL } from 'utils/apiUrls';

interface LayerLegendImageProps {
  terrainPath: string | null;
  classname?: string;
}

const LayerLegendImage = ({ terrainPath, classname }: LayerLegendImageProps) => {
  if (isEmpty(terrainPath)) return null;

  return (
    <div className={`small-layer-legend ${classname}`}>
      <img
        height="250px"
        src={`${GET_GEOSERVER_LEGEND_URL}/${terrainPath}`}
        alt="layer-legend"
        loading="lazy"
      />
    </div>
  );
};

LayerLegendImage.defaultProps = {
  classname: ''
};

export default LayerLegendImage;

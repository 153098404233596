import { useMemo, useState } from 'react';
import { MapContainer } from 'react-leaflet';
import { isEmpty } from 'lodash';
import { Button, Drawer, Space, Typography } from 'antd';
import ArrowleftIcon from 'assets/svg/ArrowleftIcon';
import CollapseIconBtn from 'assets/svg/CollapseIconBtn';
import { ADDataLabel, airspaceDataLabel } from 'constants/collectData.constant';
import { blue } from 'constants/color.constant';
import { LightTriangleIcon } from 'constants/map.constants';
import ControlMapCenter from 'components/Map/ControlMapCenter';
import LiveCoordinates from 'components/Map/LiveCoordinates';
import MapActionControlV1 from 'components/Map/MapActionControlV1';
import MapLayers from 'components/Map/MapLayers';
import AixmObstaclesAdData from 'components/Map/AixmObstaclesAdData';
import LineStringComponent from 'components/Map/LineStringComponent';
import RenderControlledAirspace from 'components/Map/RenderControlledAirspace';
import RenderNavaidsAirspace from 'components/Map/RenderNavaidsAirspace';
import RenderFIRAirspace from 'components/Map/RenderFIRAirspace';
import RenderRestrictiveAirspace from 'components/Map/RenderRestrictiveAirspace';
import RenderRNAVRouteAirspace from 'components/Map/RenderRNAVRouteAirspace';
import RenderCNAVRouteAirspace from 'components/Map/RenderCNAVRouteAirspace';
import RenderMilitryAirspace from 'components/Map/RenderMilitryAirspace';
import RenderSurveyObstaclePoint from 'components/Map/RenderSurveyObstaclePoint';
import RenderADIZAirspace from 'components/Map/RenderADIZAirspace';
import ARPPolyline from 'components/Map/ARPPolyline';
import RenderARPStartEndPoint from 'components/Map/RenderARPStartEndPoint';
import RenderWayPointsAirspace from 'components/Map/RenderWayPointsAirspace';
import TerrainWMTSTileLayer from 'components/Map/TerrainWMTSTileLayer';
import {
  DesignResponse,
  ObstacleType,
  PreviewSurveyObstacleType,
  SurveyObstacleType
} from 'store/design/types';
import { Maptype } from 'types/common.types';
import { GET_GEOSERVER_LEGEND_URL } from 'utils/apiUrls';

const { Text } = Typography;

interface MapFullScreenViewProps {
  mapExpandView: boolean;
  handleOnMapClose: any;
  obstacles: ObstacleType[] | [];
  airspaceData: any;
  mapcenter: any;
  zoom: number;
  availableDataActiveCodeId: string | null;
  initInfoCollectData: any;
  isTerrainDataViewonmap: boolean;
  activeTab: string;
  surveyObstacleData: SurveyObstacleType[] | [];
  surveyFileObsData: PreviewSurveyObstacleType[] | [];
  obstacleActiveBtn: string;
  obstacleActiveTabData: string;
  selectedDesign: DesignResponse | null;
  nominalCircles: any;
  terrainLayerName: string | null;
}

const MapFullScreenView = ({
  mapExpandView,
  handleOnMapClose,
  obstacles,
  activeTab,
  airspaceData,
  mapcenter,
  surveyFileObsData,
  surveyObstacleData,
  zoom,
  availableDataActiveCodeId,
  initInfoCollectData,
  isTerrainDataViewonmap,
  obstacleActiveBtn,
  obstacleActiveTabData,
  selectedDesign,
  nominalCircles,
  terrainLayerName
}: MapFullScreenViewProps) => {
  const [mapType, setMapType] = useState<string>(Maptype.Roadmap);

  const handleMapType = (type: string) => {
    setMapType(type);
  };

  const FullMapContainer = useMemo(() => {
    return (
      <MapContainer
        center={mapcenter}
        maxZoom={15}
        minZoom={9}
        zoomControl={false}
        className="map-container collect-map-container"
      >
        {!isEmpty(initInfoCollectData) && (
          <>
            <RenderARPStartEndPoint
              initInfoCollectData={initInfoCollectData}
              ringColor="#27A4FF"
              mapType={mapType}
            />
            <ARPPolyline
              arpCoordinates={
                !isEmpty(initInfoCollectData)
                  ? [
                      [
                        initInfoCollectData.startpoint.coordinates[0],
                        initInfoCollectData.startpoint.coordinates[1]
                      ],
                      [
                        initInfoCollectData.endpoint.coordinates[0],
                        initInfoCollectData.endpoint.coordinates[1]
                      ]
                    ]
                  : []
              }
              decoratorIcon={LightTriangleIcon}
              weight={3}
            />
          </>
        )}
        {availableDataActiveCodeId === ADDataLabel.aixmobstacles && (
          <AixmObstaclesAdData obstacleData={obstacles} />
        )}
        {availableDataActiveCodeId === airspaceDataLabel.ctrl && (
          <RenderControlledAirspace ctrlAirspaceData={airspaceData} />
        )}
        {availableDataActiveCodeId === airspaceDataLabel.geoNavaid && (
          <RenderNavaidsAirspace navaidAirspaceData={airspaceData} />
        )}
        {availableDataActiveCodeId === airspaceDataLabel.waypoint && (
          <RenderWayPointsAirspace waypointAirspaceData={airspaceData} />
        )}
        {availableDataActiveCodeId === airspaceDataLabel.fir && (
          <RenderFIRAirspace firAirspaceData={airspaceData} />
        )}
        {availableDataActiveCodeId === airspaceDataLabel.restrictive && (
          <RenderRestrictiveAirspace restrictiveAirspaceData={airspaceData} />
        )}
        {availableDataActiveCodeId === airspaceDataLabel.routeRNAV && (
          <RenderRNAVRouteAirspace routeRNAVAirspaceData={airspaceData} />
        )}
        {availableDataActiveCodeId === airspaceDataLabel.routeCNAV && (
          <RenderCNAVRouteAirspace routeCNAVAirspaceData={airspaceData} />
        )}
        {availableDataActiveCodeId === airspaceDataLabel.military && (
          <RenderMilitryAirspace militryAirspaceData={airspaceData} />
        )}
        {availableDataActiveCodeId === airspaceDataLabel.adiz && (
          <RenderADIZAirspace adizAirspaceData={airspaceData} />
        )}
        {activeTab === 'obstacle' &&
          obstacleActiveBtn === 'survey' &&
          isEmpty(surveyFileObsData) &&
          !isEmpty(surveyObstacleData) && (
            <RenderSurveyObstaclePoint obstacleData={surveyObstacleData} />
          )}
        {activeTab === 'obstacle' &&
          obstacleActiveBtn === 'survey' &&
          !isEmpty(surveyFileObsData) && (
            <RenderSurveyObstaclePoint obstacleData={surveyFileObsData} />
          )}
        {isTerrainDataViewonmap &&
          obstacleActiveBtn === 'terrain' &&
          obstacleActiveTabData === 'srtm' && (
            <TerrainWMTSTileLayer
              WMTSStyle={
                !isEmpty(terrainLayerName) ? terrainLayerName : `cfnpd:${selectedDesign?.icao}`
              }
              isTerrainDataViewonmap={isTerrainDataViewonmap}
              WMTSPane="shadowPane"
            />
          )}
        {!isEmpty(nominalCircles) && !isEmpty(nominalCircles?.circle_15_nm) && (
          <LineStringComponent
            coordinates={nominalCircles.circle_15_nm}
            color={blue}
            dashArray={[5, 5]}
            weight={2}
          />
        )}
        {!isEmpty(nominalCircles) && !isEmpty(nominalCircles?.circle_30_nm) && (
          <LineStringComponent
            coordinates={nominalCircles.circle_30_nm}
            color={blue}
            dashArray={[5, 5]}
            weight={2}
          />
        )}
        <MapLayers maptype={mapType} />
        <LiveCoordinates />
        <MapActionControlV1 mapType={mapType} handleMapType={handleMapType} />
        <ControlMapCenter zoomLevel={zoom} center={mapcenter} />
      </MapContainer>
    );
  }, [
    mapType,
    airspaceData,
    obstacles,
    mapExpandView,
    mapcenter,
    zoom,
    availableDataActiveCodeId,
    isTerrainDataViewonmap,
    obstacleActiveBtn,
    obstacleActiveTabData,
    selectedDesign,
    nominalCircles
  ]);

  return (
    <Drawer
      title={
        <>
          <Text>Map View</Text>
          <Button
            key="back-icon"
            type="text"
            icon={<CollapseIconBtn />}
            onClick={handleOnMapClose}
            className="back-icon-btn"
          />
        </>
      }
      placement="bottom"
      height="calc(100vh - 56px)"
      width="100vw"
      open={mapExpandView}
      contentWrapperStyle={{ boxShadow: 'none' }}
      maskStyle={{ backgroundColor: 'transparent' }}
      onClose={handleOnMapClose}
      className="full-map-view"
      closeIcon={
        <Button
          key="back-icon"
          type="text"
          shape="circle"
          icon={<ArrowleftIcon />}
          onClick={handleOnMapClose}
          className="back-icon-btn"
        />
      }
    >
      {FullMapContainer}
      {isTerrainDataViewonmap &&
        obstacleActiveBtn === 'terrain' &&
        obstacleActiveTabData === 'srtm' && (
          <Space className="layer-legend">
            <img src={`${GET_GEOSERVER_LEGEND_URL}/${selectedDesign?.icao}`} alt="layer-legend" />
          </Space>
        )}
    </Drawer>
  );
};

export default MapFullScreenView;

import { useEffect, useState } from 'react';
import { filter, isEmpty } from 'lodash';
import { Button, Drawer, Typography } from 'antd';
import ArrowleftIcon from 'assets/svg/ArrowleftIcon';
import CollapseIconBtn from 'assets/svg/CollapseIconBtn';
import CollectADDataTable from 'components/CollectADDataTable/CollectADDataTable';
import { useAppSelector } from 'hooks/useReduxHook';
import CollectAIXMDataTable from './CollectObstactleDataTable';
import { ObstacleType, PreviewSurveyObstacleType, SurveyObstacleType } from 'store/design/types';
import SurveyObstacleDatataTable from './SurveyObstacleDatataTable';

const { Text } = Typography;

interface TableFullScreenViewProps {
  tableExpandView: boolean;
  handleOnTableClose: any;
  availableDataActiveCodeId: string | null;
  activeTab: string | null;
  metadataLoadObstacleData: ObstacleType[] | [];
  surveyFileObsData: PreviewSurveyObstacleType[] | [];
  obstacleActiveBtn: string;
  surveyFile: File | undefined;
  surveyObstacleData: SurveyObstacleType[] | [];
}

const TableFullScreenView = ({
  tableExpandView,
  handleOnTableClose,
  availableDataActiveCodeId,
  activeTab,
  metadataLoadObstacleData,
  obstacleActiveBtn,
  surveyObstacleData,
  surveyFileObsData,
  surveyFile
}: TableFullScreenViewProps) => {
  const { availableADData } = useAppSelector((state: { design: any }) => state.design);
  const [activeObsSurveyBtn, setActiveObsSurveyBtn] = useState<string>('all');
  const [fliterObsSurveyData, setFliterObsSurveyData] = useState<any>(null);

  useEffect(() => {
    setFliterObsSurveyData(surveyObstacleData);
  }, []);

  const handleSelectActiveObsSurveyBtn = (activeBtn: string) => {
    setActiveObsSurveyBtn(activeBtn);
    if (activeBtn === 'eaip') {
      const showEaip = filter([...surveyObstacleData], ['is_eaip', true]);
      setFliterObsSurveyData(showEaip);
    } else if (activeBtn === 'survey') {
      const showSurvey = filter([...surveyObstacleData], ['is_eaip', false]);
      setFliterObsSurveyData(showSurvey);
    } else if (activeBtn === 'all') {
      setFliterObsSurveyData([...surveyObstacleData]);
    }
  };

  return (
    <Drawer
      title={
        <div>
          <div>
            <Text>Table View</Text>
            <Button
              key="back-icon"
              type="text"
              icon={<CollapseIconBtn />}
              onClick={handleOnTableClose}
              className="back-icon-btn"
            />
          </div>
          {activeTab === 'obstacle' && obstacleActiveBtn === 'survey' && (
            <Button.Group className="table-grp-btn">
              <Button
                disabled={Boolean(surveyFile)}
                className={`${
                  activeObsSurveyBtn === 'all' ? 'table-data-btn all-active-btn' : 'table-data-btn'
                }`}
                onClick={() => handleSelectActiveObsSurveyBtn('all')}
              >
                All
              </Button>
              <Button
                disabled={Boolean(surveyFile)}
                className={`${
                  activeObsSurveyBtn === 'eaip'
                    ? 'table-data-btn eaip-active-btn'
                    : 'table-data-btn'
                }`}
                onClick={() => handleSelectActiveObsSurveyBtn('eaip')}
              >
                eAIP
              </Button>
              <Button
                disabled={Boolean(surveyFile)}
                className={`${
                  activeObsSurveyBtn === 'survey'
                    ? 'table-data-btn survey-active-btn'
                    : 'table-data-btn'
                }`}
                onClick={() => handleSelectActiveObsSurveyBtn('survey')}
              >
                Survey
              </Button>
            </Button.Group>
          )}
        </div>
      }
      placement="bottom"
      height="calc(100vh - 56px)"
      width="100vw"
      open={tableExpandView}
      contentWrapperStyle={{ boxShadow: 'none' }}
      maskStyle={{ backgroundColor: 'transparent' }}
      onClose={handleOnTableClose}
      className="full-table-view"
      closeIcon={
        <Button
          key="back-icon"
          type="text"
          shape="circle"
          icon={<ArrowleftIcon />}
          onClick={handleOnTableClose}
          className="back-icon-btn"
        />
      }
    >
      {!isEmpty(availableDataActiveCodeId) ? (
        availableDataActiveCodeId === 'aixmobstacles' ? (
          <CollectAIXMDataTable
            tableExpandView={tableExpandView}
            obstacleData={
              !isEmpty(metadataLoadObstacleData)
                ? metadataLoadObstacleData
                : availableADData &&
                  availableDataActiveCodeId === 'aixmobstacles' &&
                  !isEmpty(availableADData[availableDataActiveCodeId])
                ? availableADData[availableDataActiveCodeId]
                : []
            }
          />
        ) : !isEmpty(availableADData) &&
          availableDataActiveCodeId &&
          availableDataActiveCodeId !== 'aixmobstacles' ? (
          <CollectADDataTable
            availableDataActiveCodeId={availableDataActiveCodeId}
            activeTab={activeTab}
          />
        ) : (
          <Text className="flexJustifyAlignCenter ad-no-data">No Data </Text>
        )
      ) : activeTab === 'obstacle' ? (
        obstacleActiveBtn === 'survey' &&
        isEmpty(surveyFileObsData) &&
        !isEmpty(surveyObstacleData) ? (
          <SurveyObstacleDatataTable
            tableExpandView={tableExpandView}
            obstacleData={fliterObsSurveyData}
          />
        ) : !isEmpty(surveyFileObsData) && obstacleActiveBtn === 'survey' ? (
          <SurveyObstacleDatataTable
            tableExpandView={tableExpandView}
            obstacleData={surveyFileObsData}
          />
        ) : (
          <Text className="flexJustifyAlignCenter ad-no-data">No Data Selected</Text>
        )
      ) : (
        <Text className="flexJustifyAlignCenter ad-no-data">No Data Selected</Text>
      )}
    </Drawer>
  );
};

export default TableFullScreenView;

import { useEffect, useState } from 'react';
import { Button, Switch, Table, Typography } from 'antd';
import { capitalize, filter, isEmpty } from 'lodash';
import { ColumnsType } from 'antd/es/table';
import CloseIcon from 'assets/svg/CloseIcon';
import ExpandIcon from 'assets/svg/ExpandIcon';
import { primary } from 'constants/color.constant';
import { OAlegdata } from 'store/design/types';
import './styles.less';

const { Text } = Typography;

interface ObstacleAnalysisTableOmniProps {
  obstacleData: OAlegdata[] | [];
  terrainData: OAlegdata[] | [];
  isShowClosingObstacle: boolean;
  isLoadingOAData: boolean;
  activeObscleTerrainDataKey: string;
  handleShowHideObstacleTableOmni: (isShow: boolean) => void;
  handleChangeShowClosingObstacle: (isShow: boolean) => void;
  handleShowFullScreenTable: (isShow: boolean) => void;
}

const ObstacleAnalysisTableOmni = ({
  obstacleData,
  terrainData,
  isShowClosingObstacle,
  isLoadingOAData,
  activeObscleTerrainDataKey,
  handleShowHideObstacleTableOmni,
  handleChangeShowClosingObstacle,
  handleShowFullScreenTable
}: ObstacleAnalysisTableOmniProps) => {
  const [obstacleTableData, setObstacleTableData] = useState<OAlegdata[] | []>([]);

  useEffect(() => {
    let obsData =
      activeObscleTerrainDataKey === 'obstacle'
        ? [...obstacleData]
        : activeObscleTerrainDataKey === 'terrain'
        ? [...terrainData]
        : [];
    if (isShowClosingObstacle) {
      let data = filter(obsData, ['is_close_in', isShowClosingObstacle]);
      data = data.sort((a: any, b: any) => a.is_close_in - b.is_close_in);
      data = [...data].sort((a: any, b: any) => a.penetration - b.penetration);

      setObstacleTableData([...data]);
    } else {
      obsData = obsData.sort((a: any, b: any) => a.is_close_in - b.is_close_in);
      obsData = [...obsData].sort((a: any, b: any) => a.penetration - b.penetration);
      setObstacleTableData([...obsData]);
    }
  }, [isShowClosingObstacle, obstacleData, activeObscleTerrainDataKey, terrainData]);

  const columns: ColumnsType<OAlegdata> = [
    {
      title: 'UID',
      dataIndex: 'id',
      align: 'center',
      key: 'id',
      render: (value) => value || '-'
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (value) => value || '-'
    },
    {
      title: 'Coordinates',
      key: 'coordinates',
      render: (record: OAlegdata) => {
        return (
          <>
            <Text style={{ color: '#696b72', display: 'block', fontSize: 12 }}>
              {record.latitude || '-'}{' '}
            </Text>
            <Text style={{ color: '#696b72', display: 'block', fontSize: 12 }}>
              {record.longitude || '-'}
            </Text>
          </>
        );
      }
    },
    {
      title: 'Elevation (m)',
      dataIndex: 'elevation',
      align: 'center',
      key: 'elevation',
      render: (value) => {
        return value ? Number(value).toFixed(3) : '-';
      }
    },
    {
      title: 'Description',
      dataIndex: 'description',
      align: 'center',
      key: 'description',
      render: (value) => value || '-'
    },
    {
      title: 'MOCA (m)',
      dataIndex: 'moc',
      key: 'moc',
      render: (value) => {
        return value ? Number(value).toFixed(3) : '-';
      }
    },
    // {
    //   title: 'Tolerance',
    //   dataIndex: 'tolerance',
    //   align: 'center',
    //   key: 'tolerance',
    //   render: (value) => value || '-'
    // },
    {
      title: 'Penetration (m)',
      dataIndex: 'penetration',
      key: 'penetration',
      render: (value) => {
        return (
          <Text style={{ color: Number(value) <= 0 ? '#27AE60' : '#EB5757', fontSize: 12 }}>
            {Number(value) <= 0 ? 'clear' : `${Number(value).toFixed(3)}`}
          </Text>
        );
      }
    }
  ];

  return (
    <div className="obstacle-details">
      <div className="obstacle-header">
        <div>
          <Text>{capitalize(activeObscleTerrainDataKey)} Analysis Results</Text>
          <Button
            type="text"
            icon={<ExpandIcon />}
            onClick={() => handleShowFullScreenTable(true)}
            className="back-icon-btn"
          />
        </div>

        <div>
          <Text>Show Closing Obstacles</Text>
          <Switch
            size="small"
            className="oa-result-switch"
            disabled={isEmpty(obstacleData)}
            onChange={() => handleChangeShowClosingObstacle(!isShowClosingObstacle)}
          />
          <Button
            type="text"
            shape="circle"
            icon={<CloseIcon color={primary} />}
            className="back-icon-btn"
            onClick={() => handleShowHideObstacleTableOmni(false)}
          />
        </div>
      </div>
      <div>
        <Table
          className="obstacle-analysis-table design-table-main"
          columns={columns}
          dataSource={obstacleTableData || []}
          pagination={false}
          scroll={{ y: 250 }}
          rowKey={(record) => record.id}
          loading={isLoadingOAData}
        />
      </div>
    </div>
  );
};

export default ObstacleAnalysisTableOmni;

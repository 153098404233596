import { Avatar, Typography } from 'antd';
import { capitalize, isEmpty } from 'lodash';
import { decryptValue } from 'utils/decodeEncodeData';
import { shortLabel } from 'utils/utils';
import './styles.less';

interface UserOptionItemComponentProps {
  userDetail: any;
}

const { Text } = Typography;

const UserOptionItemComponent = ({ userDetail }: UserOptionItemComponentProps) => {
  return (
    <div className="user-detail-main">
      {userDetail && !isEmpty(userDetail.firstname) && !isEmpty(userDetail.lastname) ? (
        <>
          <Avatar size={22} className="user-detail-avatar">
            {shortLabel(
              `${decryptValue(userDetail.firstname)} ${decryptValue(userDetail.lastname)}`
            )}
          </Avatar>
          <Text className="user-label">
            {decryptValue(userDetail.firstname)} {decryptValue(userDetail.lastname)}
          </Text>
        </>
      ) : (
        <>
          <Avatar size={22} className="user-detail-avatar">
            {userDetail ? capitalize(decryptValue(userDetail.email)).charAt(0) : '-'}
          </Avatar>
          <Text className="user-label">
            {userDetail ? capitalize(decryptValue(userDetail.email)) : '-'}
          </Text>
        </>
      )}
    </div>
  );
};

export default UserOptionItemComponent;

import { isEmpty } from 'lodash';
import { useAppSelector } from 'hooks/useReduxHook';
import CollectADDataItem from './CollectADDataItem';
import NavaidItem from './NavaidItem';
import Approachrunwaylighting from './Approachrunwaylighting';

interface CollectADDataTableProps {
  activeTab: string | null;
  availableDataActiveCodeId: string | null;
}

const CollectADDataTable = ({ activeTab, availableDataActiveCodeId }: CollectADDataTableProps) => {
  const { availableADData } = useAppSelector((state) => state.design);

  if (isEmpty(availableADData) && activeTab !== 'addata') return null;

  return (
    <>
      {availableDataActiveCodeId === 'geoadministrative' && (
        <CollectADDataItem selectedADData={availableADData.geoadministrative} />
      )}
      {availableDataActiveCodeId === 'airspaceservice' && (
        <CollectADDataItem selectedADData={availableADData.airspaceservice} />
      )}
      {availableDataActiveCodeId === 'airtrafficservices' && (
        <CollectADDataItem selectedADData={availableADData.airtrafficservices} />
      )}
      {availableDataActiveCodeId === 'declareddistaces' && (
        <CollectADDataItem selectedADData={availableADData.declareddistaces} />
      )}
      {availableDataActiveCodeId === 'runwayphysicals' && (
        <CollectADDataItem selectedADData={availableADData.runwayphysicals} />
      )}
      {availableDataActiveCodeId === 'rwylights' && (
        <Approachrunwaylighting rwylights={availableADData.rwylights} />
      )}
      {availableDataActiveCodeId === 'navaids' && <NavaidItem navaids={availableADData.navaids} />}
    </>
  );
};

export default CollectADDataTable;

import { useEffect, useMemo, useState } from 'react';
import { Col, Divider, Row, Typography } from 'antd';
import { isEmpty } from 'lodash';
import DocFileIcon from 'assets/svg/DocFileIcon';
import DownloadPdfIcon from 'assets/svg/DownloadPdfIcon';
import ObstacleDataIcon from 'assets/svg/ObstacleDataIcon';
import SoftwareIcon from 'assets/svg/SoftwareIcon';
import { lightGrey1 } from 'constants/color.constant';
import ReportContentCard from 'components/core/ReportContentCard';
import { ObstacleDataType } from 'pages/AeroReport/AeroReport';
import TerrainSurveyObsTable from './TerrainSurveyObsTable';
import ReportSecTitleWithIcon from './ReportSecTitleWithIcon';

const { Text } = Typography;

interface Section1Props {
  sec1: any;
  scrollElementRef: any;
  handleCallSaveAeroReportDataApi: (data: { section_key: string; fields: any }) => void;
}

const Section1 = ({ sec1, scrollElementRef, handleCallSaveAeroReportDataApi }: Section1Props) => {
  const [topographyData, setTopographyData] = useState<any>([]);
  const [surveyData, setSurveyData] = useState<any>([]);

  useEffect(() => {
    let surveyObsData: ObstacleDataType[] = [];
    let terrainObsData: ObstacleDataType[] = [];
    if (!isEmpty(sec1)) {
      surveyObsData = [
        {
          key: 1,
          obstacle: 'Area-1 eTOD - Obstacle Data',
          source: 'AIP',
          vertical: sec1.etod_obs_vt,
          horizontal: sec1.etod_obs_ht,
          remarks: ''
        },
        {
          key: 2,
          obstacle: 'Uploaded Survey Data',
          source: 'Airspace Standards',
          vertical: sec1.survey_obs_vt,
          horizontal: sec1.survey_obs_ht,
          remarks: ''
        }
      ];
      terrainObsData = [
        {
          key: 1,
          obstacle: 'Area-1 eTOD - Terrain Data',
          source: 'SRTM',
          vertical: sec1.etod_terr_vt,
          horizontal: sec1.etod_terr_ht,
          remarks: ''
        },
        {
          key: 2,
          obstacle: 'Terrain spot elevation within 40 NM',
          source: 'Airspace Standards',
          vertical: sec1.terr_spot_elv_vt,
          horizontal: sec1.terr_spot_elv_ht,
          remarks: ''
        }
      ];
    }
    setTopographyData(terrainObsData);
    setSurveyData(surveyObsData);
  }, [sec1]);

  const handleSurveySaveData = (data: ObstacleDataType[] | []) => {
    setSurveyData(data);
  };

  const handleTerrainSaveData = (data: ObstacleDataType[] | []) => {
    setTopographyData([...data]);
  };

  const surveyTableMemo = useMemo(
    () => <TerrainSurveyObsTable obstacleData={surveyData} handleSaveData={handleSurveySaveData} />,
    [surveyData]
  );

  const terrainTableMemo = useMemo(
    () => (
      <TerrainSurveyObsTable obstacleData={topographyData} handleSaveData={handleTerrainSaveData} />
    ),
    [topographyData]
  );

  const handleSaveDatasource = async () => {
    const payload: { section_key: string; fields: any } = {
      section_key: 'sec_1',
      fields: {}
    };
    if (surveyData[0].key === 1 && surveyData[0].horizontal) {
      payload.fields.etod_obs_ht = surveyData[0].horizontal;
    }
    if (surveyData[0].key === 1 && surveyData[0].vertical) {
      payload.fields.etod_obs_vt = surveyData[0].vertical;
    }
    if (surveyData[1].key === 2 && surveyData[1].horizontal) {
      payload.fields.survey_obs_ht = surveyData[1].horizontal;
    }
    if (surveyData[1].key === 2 && surveyData[1].vertical) {
      payload.fields.survey_obs_vt = surveyData[1].vertical;
    }
    if (topographyData[0].key === 1 && topographyData[0].horizontal) {
      payload.fields.etod_terr_ht = topographyData[0].horizontal;
    }
    if (topographyData[0].key === 1 && topographyData[0].vertical) {
      payload.fields.etod_terr_vt = topographyData[0].vertical;
    }
    if (topographyData[1].key === 2 && topographyData[1].horizontal) {
      payload.fields.terr_spot_elv_ht = topographyData[1].horizontal;
    }
    if (topographyData[1].key === 2 && topographyData[1].vertical) {
      payload.fields.terr_spot_elv_vt = topographyData[1].vertical;
    }
    handleCallSaveAeroReportDataApi(payload);
  };

  return (
    <div className="report-code-content sec-1" ref={scrollElementRef} id="datasource">
      <ReportSecTitleWithIcon
        title="1. DATA SOURCES, REFERENCE AND SOFTWARE TOOLS"
        icon={<DownloadPdfIcon />}
        handleSaveData={handleSaveDatasource}
      />
      <div className="report-content-data">
        <div className="data-sources-tool">
          <Row className="reference-content">
            <Col span={11}>
              <ReportContentCard icon={<DocFileIcon />} title="REFERENCE DOCUMENTS :" />
              <ul>
                <li>a. ICAO PANS OPS DOC. 8168 Vliume II AMDT 8</li>
                <li>b. ICAO DOC 9368</li>
                <li>c. AIRAC Cycle {sec1?.airac_ed || '12'}</li>
              </ul>
            </Col>
            <Col>
              <Divider dashed type="vertical" />
            </Col>
            <Col span={11}>
              <ReportContentCard icon={<SoftwareIcon />} title="SOFTWARE :" />
              <ul>
                <li>a. SatSure {'<>'} CFNPD v1.0</li>
              </ul>
            </Col>
          </Row>
          <Divider dashed style={{ borderColor: lightGrey1 }} />
          <div className="obs-terrain-content">
            <ReportContentCard icon={<ObstacleDataIcon />} title="OBSTACLES AND TOLERANCE :" />
            <div className="obstacle-table">
              <ReportContentCard
                classname="table-title"
                icon={null}
                title="Topographical Obstacles"
              />
              {terrainTableMemo}
            </div>
            <Text className="table-note">
              *Note : Vegetation Allowance of +15m (50ft) is applied over significant terrain (hill
              & mountain)
            </Text>
            <div className="obstacle-table terrain-table">
              <ReportContentCard classname="table-title" icon={null} title="Survey Obstacles" />
              {surveyTableMemo}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section1;

import { createSlice } from '@reduxjs/toolkit';
import { AlertState } from './types';

const initialState: AlertState = {
  errorQueue: [],
  successAlert: null,
  warningAlert: null,
  infoAlert: null
};

export const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    enqueueError: (state, action) => {
      state.errorQueue.push(action.payload);
    },
    dequeueError: (state) => {
      state.errorQueue.shift();
    },
    setSuccessAlert: (state, action) => {
      state.successAlert = action.payload;
    },
    setWarningAlert: (state, action) => {
      state.warningAlert = action.payload;
    },
    setInfoAlert: (state, action) => {
      state.infoAlert = action.payload;
    },
    clearAlerts: () => {
      return initialState;
    }
  }
});

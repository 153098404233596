import { useState } from 'react';
import { Polygon, Popup } from 'react-leaflet';
import { isEmpty, upperCase } from 'lodash';
import { Space, Typography } from 'antd';
import { mapDrawType } from 'constants/collectData.constant';
import { lightGreen, primary, red1 } from 'constants/color.constant';
import { FeatureGeometryType } from 'types/common.types';

const { Text } = Typography;

interface RenderADIZAirspaceProps {
  adizAirspaceData: any;
}

const RenderADIZAirspace = ({ adizAirspaceData }: RenderADIZAirspaceProps) => {
  const [selectedOverE, setSelectedOverE] = useState<FeatureGeometryType | null>(null);

  if (adizAirspaceData && isEmpty(adizAirspaceData?.features)) return null;

  const handlePolygonOver = (e: any, element: any) => {
    e.target.bringToFront();
    setSelectedOverE(element);
  };

  const handlePolygonOut = () => {
    setSelectedOverE(null);
  };

  return (
    // eslint-disable-next-line
    <>
      {
        // eslint-disable-next-line
        adizAirspaceData?.features.map((adiz: any, index: number) => {
          if (adiz.geometry.type === mapDrawType.polygon) {
            if (!isEmpty(adiz.geometry.coordinates)) {
              const coordinateData = adiz.geometry.coordinates.map((points: any) => {
                return points.map((point: any) => {
                  return [Number(point[1]), Number(point[0])];
                });
              });
              return (
                <Polygon
                  key={index}
                  positions={coordinateData}
                  pathOptions={{
                    color: selectedOverE?.properties?.id === adiz.properties.id ? red1 : primary,
                    fillColor: lightGreen,
                    fillOpacity: 0.3,
                    weight: 1
                  }}
                  eventHandlers={{
                    mouseover: (e) => handlePolygonOver(e, adiz),
                    mouseout: () => handlePolygonOut()
                  }}
                  pane="shadowPane"
                >
                  {!isEmpty(adiz.properties) && (
                    <Popup className="polygon-popup">
                      <Space direction="vertical">
                        {Object.keys(adiz.properties).map((key: any) => (
                          <Space direction="vertical" key={key}>
                            <Text className="tooltip-title" style={{ fontFamily: 'Manrope' }}>
                              {upperCase(key).replace(/[^a-zA-Z0-9 ]/g, ' ')}
                            </Text>
                            <Text className="tooltip-content" style={{ fontFamily: 'Manrope' }}>
                              {adiz.properties[key] || '-'}
                            </Text>
                          </Space>
                        ))}
                      </Space>
                    </Popup>
                  )}
                </Polygon>
              );
            }
          }
        })
      }
    </>
  );
};

export default RenderADIZAirspace;

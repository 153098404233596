import { Typography } from 'antd';
import InfoCircleIcon from 'assets/svg/InfoCircleIcon';
import { red } from 'constants/color.constant';

const { Text } = Typography;

interface ListDataCardProps {
  title: string;
  listData: string[];
  classname?: string;
  info?: string | null;
  titleInfo?: string | null;
}

const ListDataCard = ({ title, classname, listData, info, titleInfo }: ListDataCardProps) => {
  return (
    <div className={`list-data-card ${classname}`}>
      <Text>
        {title}
        {titleInfo && (
          <Text className="tital-info">
            <InfoCircleIcon height={14} width={14} color={red} />
            {titleInfo}
          </Text>
        )}
      </Text>
      <div>
        {listData.map((t: string, i: number) => (
          <Text key={i}>{t}</Text>
        ))}
      </div>
      {info && (
        <Text className="list-card-info">
          <InfoCircleIcon height={14} width={14} color={red} />
          {info}
        </Text>
      )}
    </div>
  );
};
ListDataCard.defaultProps = {
  classname: '',
  info: null,
  titleInfo: null
};

export default ListDataCard;

import { Checkbox, Typography } from 'antd';
import { CollectDataType } from 'types/common.types';

const { Text } = Typography;

interface CheckboxCardProps {
  data: CollectDataType;
  handleClickMetadataLoadBtn: Function;
  activeCodeId: string | null;
  isChecked: boolean;
  handleCheckboxClick: Function;
}
const CheckboxCard = ({
  data,
  handleClickMetadataLoadBtn,
  activeCodeId,
  isChecked,
  handleCheckboxClick
}: CheckboxCardProps) => {
  return (
    <div
      className={
        activeCodeId === data.value
          ? 'click-btn-main chk-button-main'
          : isChecked
          ? 'box-checked chk-button-main'
          : 'chk-button-main'
      }
    >
      <Checkbox
        checked={isChecked}
        className={isChecked ? 'is-checked' : 'chk-card'}
        onChange={() => handleCheckboxClick(data)}
      />
      <Text onClick={() => handleClickMetadataLoadBtn(data.value)}>{data.label}</Text>
    </div>
  );
};

export default CheckboxCard;

import { keys } from 'lodash';
import AircraftIconV1 from 'assets/svg/AircraftIconV1';
import ReportContentCard from 'components/core/ReportContentCard';
import { useAppSelector } from 'hooks/useReduxHook';
import { DesignState } from 'store/design/types';
import ReportSecTitleWithIcon from './ReportSecTitleWithIcon';
import ObstacleAnalysisTable from './ObstacleAnalysisTable';
import Sec11MapContainer from './Sec11MapContainer';

interface PreviewSection11Props {
  scrollElementRef: any;
  sec11: any;
  count?: number;
}

const PreviewSection11 = ({ scrollElementRef, count, sec11 }: PreviewSection11Props) => {
  const { selectedDesign }: DesignState = useAppSelector((state) => state.design);
  if (!selectedDesign) return null;

  return (
    <div
      className="report-code-content  sec-11"
      key={count}
      ref={scrollElementRef}
      id="obstacleanalysis"
    >
      <div className="report-content-data">
        {keys(sec11).map((ct: string, i: number) => {
          return (
            <div
              className="coding-table-content page-break"
              key={`${ct} ( DER - ${sec11[`${ct}`].end_point} ) - ${i + 1}`}
            >
              {i === 0 && (
                <ReportSecTitleWithIcon
                  classname="sec11-title-header"
                  title="11. OBSTACLE ANALYSIS"
                  icon={null}
                />
              )}
              <ReportContentCard
                icon={<AircraftIconV1 height={16} />}
                title={`${ct} ( DER - ${sec11[`${ct}`].end_point} )`}
              />
              <div className="obstacle-table">
                <div className="obstacle-map" style={{ height: 700 }}>
                  <Sec11MapContainer ct={ct} selectedDesign={selectedDesign} sec11={sec11} />
                </div>
                <ObstacleAnalysisTable obstacledata={sec11[`${ct}`]?.obstacles || []} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

PreviewSection11.defaultProps = {
  count: 0
};

export default PreviewSection11;

import { useEffect, useState } from 'react';
import { isEmpty, keys } from 'lodash';
import { Table, TableProps } from 'antd';
import { Sec7TableDataType } from 'store/design/types';
import ReportSecTitleWithIcon from './ReportSecTitleWithIcon';

interface PreviewSection7Props {
  sec7: any;
}

const PreviewSection7 = ({ sec7 }: PreviewSection7Props) => {
  const [sec7V1, setSec7V1] = useState<Sec7TableDataType[] | []>([]);

  useEffect(() => {
    if (!isEmpty(sec7)) {
      const sidKeys = keys(sec7);
      const fData = sidKeys.map((sid: string, index: number) => {
        return {
          key: index + 1,
          sid,
          last_wpt: sec7[`${sid}`].last_wpt,
          description: sec7[`${sid}`].text || ''
        };
      });
      setSec7V1(fData);
    }
  }, [sec7]);

  const columns: TableProps<any>['columns'] = [
    {
      title: 'SID ID',
      width: 150,
      key: 'sidid',
      className: 'table-col-data',
      render: (record) => (
        <>
          <div className="sid-title">{record.sid}</div>
          <div className="sid-title">(DER - {record.last_wpt})</div>
        </>
      )
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      key: 'description',
      className: 'table-col-data',
      align: 'center',
      render: (value) => value || '-'
    }
  ];

  return (
    <div className="report-code-content page-break">
      <ReportSecTitleWithIcon title="7. SID ROUTE DESCRIPTION" icon={null} />
      <div className="report-content-data">
        <div className="sid-route-content">
          <Table
            className="sid-route-table"
            rowClassName={() => 'table-sid-row-data'}
            dataSource={sec7V1}
            pagination={false}
            columns={columns}
          />
        </div>
      </div>
    </div>
  );
};

export default PreviewSection7;

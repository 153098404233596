import { primary } from 'constants/color.constant';
import { IconProps } from 'types/common.types';

const LockIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.0625 14C2.70156 14 2.39247 13.8693 2.13522 13.608C1.87797 13.3467 1.74956 13.0329 1.75 12.6667V6C1.75 5.63333 1.87863 5.31933 2.13588 5.058C2.39313 4.79667 2.702 4.66622 3.0625 4.66667H3.71875V3.33333C3.71875 2.41111 4.03878 1.62489 4.67884 0.974667C5.31891 0.324445 6.09263 -0.000443989 7 4.55373e-07C7.90781 4.55373e-07 8.68175 0.325112 9.32181 0.975334C9.96187 1.62556 10.2817 2.41156 10.2812 3.33333V4.66667H10.9375C11.2984 4.66667 11.6075 4.79733 11.8648 5.05867C12.122 5.32 12.2504 5.63378 12.25 6V12.6667C12.25 13.0333 12.1214 13.3473 11.8641 13.6087C11.6069 13.87 11.298 14.0004 10.9375 14H3.0625ZM3.0625 12.6667H10.9375V6H3.0625V12.6667ZM7 10.6667C7.36094 10.6667 7.67003 10.536 7.92728 10.2747C8.18453 10.0133 8.31294 9.69956 8.3125 9.33333C8.3125 8.96667 8.18387 8.65267 7.92662 8.39133C7.66937 8.13 7.3605 7.99956 7 8C6.63906 8 6.32997 8.13067 6.07272 8.392C5.81547 8.65333 5.68706 8.96711 5.6875 9.33333C5.6875 9.7 5.81613 10.014 6.07338 10.2753C6.33063 10.5367 6.6395 10.6671 7 10.6667ZM5.03125 4.66667H8.96875V3.33333C8.96875 2.77778 8.77734 2.30556 8.39453 1.91667C8.01172 1.52778 7.54687 1.33333 7 1.33333C6.45312 1.33333 5.98828 1.52778 5.60547 1.91667C5.22266 2.30556 5.03125 2.77778 5.03125 3.33333V4.66667Z"
        fill={color}
      />
    </svg>
  );
};
LockIcon.defaultProps = {
  color: primary,
  width: 14,
  height: 14,
  onClick: () => {}
};

export default LockIcon;

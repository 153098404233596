import { isEmpty } from 'lodash';
import { Input, InputNumber, Typography } from 'antd';
import InfoCircleIcon from 'assets/svg/InfoCircleIcon';
import { primary, red } from 'constants/color.constant';
import { acftCategory } from 'constants/designData.constants';
import { useAppSelector } from 'hooks/useReduxHook';
import { DesignState, TemperatureType } from 'store/design/types';
import { tasCalculation } from 'utils/utils';

const { Text } = Typography;

interface OmniSpeedLegProps {
  isDesigner: boolean;
  activeLegDesignDataOMNI: any;
  iasSpeedOMNI: number | null;
  tasSpeedOMNI: number | null;
  handleOnChangeDataReviewIASOMNI: Function;
  temperature: TemperatureType | null;
  endAltGradLengthOMNI: number | null;
}

const OmniSpeedLeg = ({
  isDesigner,
  activeLegDesignDataOMNI,
  handleOnChangeDataReviewIASOMNI,
  iasSpeedOMNI,
  tasSpeedOMNI,
  temperature,
  endAltGradLengthOMNI
}: OmniSpeedLegProps) => {
  const { selectedDesign }: DesignState = useAppSelector((state) => state.design);

  const tasData: number | null = temperature
    ? tasCalculation(
        !isEmpty(activeLegDesignDataOMNI)
          ? activeLegDesignDataOMNI.parameters.ias || 0
          : iasSpeedOMNI,
        temperature ? temperature.tmin : 0,
        !isEmpty(activeLegDesignDataOMNI)
          ? Number(Number(activeLegDesignDataOMNI.end_waypoint.alt).toFixed(3)) || 0
          : endAltGradLengthOMNI
          ? Number((3.3 * ((Number(endAltGradLengthOMNI) || 0) * 6076.12)) / 100)
          : 0
      )
    : null;

  return (
    <div className="data-review-leg leg-tab">
      <div className="data-review-content">
        <div className="data-content-info">
          <Text>IAS</Text>
          <div className="ias-speed-info">
            <InfoCircleIcon color={red} />
            <Text>IAS + 10%</Text>
          </div>
        </div>
        <InputNumber
          className="data-review-ias"
          type="number"
          disabled={!isEmpty(activeLegDesignDataOMNI) || !isDesigner}
          value={
            !isEmpty(activeLegDesignDataOMNI)
              ? activeLegDesignDataOMNI.parameters.ias || undefined
              : iasSpeedOMNI
          }
          min={selectedDesign ? acftCategory[selectedDesign?.acft_cat!].min : 110}
          max={selectedDesign ? acftCategory[selectedDesign?.acft_cat!].max : 303}
          onChange={(value) => handleOnChangeDataReviewIASOMNI(value)}
          suffix={<Text style={{ color: primary, fontSize: 12, fontWeight: 400 }}>kts</Text>}
        />
      </div>
      <div className="ias-speed-info">
        <InfoCircleIcon color={red} />
        <Text>
          The Aircraft Category
          {selectedDesign
            ? `- ${selectedDesign?.acft_cat} (
        ${acftCategory[selectedDesign?.acft_cat!].min} -
          ${acftCategory[selectedDesign?.acft_cat!].max} kt)`
            : ''}
        </Text>
      </div>
      <div className="data-review-content">
        <Text>TAS</Text>
        <Input
          type="text"
          style={{ borderRadius: 4 }}
          readOnly
          value={Number(tasData)?.toFixed(2) || Number(tasSpeedOMNI)?.toFixed(2) || undefined}
          suffix="kts"
        />
      </div>
      <div className="data-review-content">
        <Text>TWC</Text>
        <InputNumber
          className="data-review-ias"
          type="number"
          placeholder="Enter TWC"
          readOnly
          value={30}
          min={100}
          max={350}
          suffix={<Text style={{ color: primary, fontSize: 12, fontWeight: 400 }}>kts</Text>}
        />
      </div>
    </div>
  );
};

export default OmniSpeedLeg;

import { Modal, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import AvatarUserComponent from 'components/core/AvatarUserComponent';

interface NewdesignAssignModalProps {
  isOpen: boolean;
  handleOpenCloseModal: (data: boolean) => void;
  handleNewdesignAssign: () => void;
}

const { Text } = Typography;

const NewdesignAssignModal = ({
  isOpen,
  handleOpenCloseModal,
  handleNewdesignAssign
}: NewdesignAssignModalProps) => {
  const columns: ColumnsType<any> = [
    {
      title: 'AIRPORT',
      dataIndex: 'airport',
      render: (value) => {
        return <Text className="arp-title">{value}</Text>;
      }
    },
    {
      title: 'TYPE OF PROCEDURE',
      dataIndex: 'type_procedure',
      render(value) {
        return <Text className="type-procedure">{value}</Text>;
      }
    },
    {
      title: 'NAME OF PROCEDURE',
      dataIndex: 'name_procedure',
      render(value) {
        return <Text className="name-procedure">{value}</Text>;
      }
    },
    {
      title: 'MANAGER',
      dataIndex: 'manager',
      render: (value) => {
        return <AvatarUserComponent data={value} userRole="manager" />;
      }
    },
    {
      title: 'DESIGNER',
      dataIndex: 'designer',
      render: (value) => {
        return <AvatarUserComponent data={value} userRole="designer" />;
      }
    },
    {
      title: 'REVIEWER',
      dataIndex: 'reviewer',
      render: (value) => {
        return <AvatarUserComponent data={value} userRole="reviewer" />;
      }
    }
  ];

  return (
    <Modal
      title="New Design Assigned to You"
      centered
      open={isOpen}
      okText="View"
      width={785}
      className="new-assign-modal"
      onOk={handleNewdesignAssign}
      onCancel={() => handleOpenCloseModal(false)}
    >
      <div className="assign-modal-body">
        <Table
          className="design-table-main"
          columns={columns}
          dataSource={[]}
          size="small"
          pagination={false}
          rowKey={(record) => record.id}
        />
      </div>
    </Modal>
  );
};

export default NewdesignAssignModal;

import React, { ReactNode } from 'react';
import { includes } from 'lodash';
import { Tooltip } from 'antd';
import { secondary } from 'constants/color.constant';

interface DesignMapElementProps {
  icon: ReactNode;
  element: string;
  activeElement: any;
  title: string;
  tooltipPlacement?: any;
  handleOnClickMapElement: (data: string) => void;
}

const DesignMapElement = ({
  icon,
  element,
  activeElement,
  title,
  tooltipPlacement,
  handleOnClickMapElement
}: DesignMapElementProps) => {
  return (
    <Tooltip title={title} placement={tooltipPlacement} color={secondary}>
      <div
        onClick={() => handleOnClickMapElement(element)}
        className={includes(activeElement, element) ? 'active-element' : ''}
      >
        {icon}
      </div>
    </Tooltip>
  );
};

DesignMapElement.defaultProps = {
  tooltipPlacement: 'bottom'
};

export default DesignMapElement;

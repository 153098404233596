import { useEffect, useState } from 'react';
import { filter } from 'lodash';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import ListDesignTableV1 from 'components/ListReviewDesignsTable/ListDesignTableV1';
import SubHeader from 'components/SubHeader';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { useGetDesignsQuery } from 'services/apis/designsApi';
import { getDesignData, setDesigns } from 'store/design';
import { DesignResponse } from 'store/design/types';
import './styles.less';

const DesignList = () => {
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState<string>('all');
  const [filterText, setFilterText] = useState<string>('');
  const currentUser: any = useAppSelector((state) => state.user.userSession);
  const designData: DesignResponse[] | [] = useAppSelector((state) =>
    getDesignData(state, activeTab)
  );
  const { data: designsData } = useGetDesignsQuery(
    currentUser && currentUser.organisationid ? currentUser.organisationid : skipToken,
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    if (designsData) {
      const designsDataResponse: any = designsData;
      if (designsDataResponse.status) {
        dispatch(setDesigns(designsDataResponse.data));
      }
    }
  }, [designsData]);

  const handleSearchAirport = (searchText: string) => {
    setFilterText(searchText);
  };

  const selectedActiveTab = (active: string) => {
    setActiveTab(active);
  };

  const DesignDataFilter: any = filter(designData, (item: DesignResponse) => {
    if (filterText && filterText.length >= 1) {
      return item.airport_name.toLowerCase().includes(filterText.toLowerCase());
    }
    return designData;
  });

  return (
    <div className="main-design-list">
      <div className="review-design-list">
        <SubHeader
          isListPage
          title="Designs"
          classname="design-subheader"
          handleSearchAirport={handleSearchAirport}
          filterText={filterText}
        />
        <ListDesignTableV1
          selectedActiveTab={selectedActiveTab}
          activeTab={activeTab}
          DesignDataFilter={DesignDataFilter}
        />
      </div>
    </div>
  );
};

export default DesignList;

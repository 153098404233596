import { typocolor } from 'constants/color.constant';
import { IconProps } from 'types/common.types';

const EyeOpenIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.99987 7.87493C6.51745 7.87493 6.12487 7.48235 6.12487 6.99993C6.12487 6.51751 6.51745 6.12493 6.99987 6.12493C7.48228 6.12493 7.87487 6.51751 7.87487 6.99993C7.87487 7.48235 7.48228 7.87493 6.99987 7.87493ZM6.99987 4.95826C5.87403 4.95826 4.9582 5.8741 4.9582 6.99993C4.9582 8.12576 5.87403 9.0416 6.99987 9.0416C8.1257 9.0416 9.04153 8.12576 9.04153 6.99993C9.04153 5.8741 8.1257 4.95826 6.99987 4.95826ZM7.12802 9.9152C4.61619 9.97353 2.97761 7.82511 2.43394 6.99736C3.03244 6.06111 4.53977 4.14428 6.87194 4.08478C9.37386 4.0212 11.0218 6.17486 11.5654 7.00261C10.9675 7.93886 9.45961 9.8557 7.12802 9.9152ZM12.756 6.70978C12.3839 6.06111 10.3282 2.80961 6.84219 2.9187C3.61752 3.00036 1.74211 5.8412 1.24394 6.70978C1.14069 6.88945 1.14069 7.11053 1.24394 7.2902C1.61086 7.93011 3.59419 11.083 7.01427 11.083C7.06211 11.083 7.10994 11.0824 7.15777 11.0813C10.3819 10.999 12.2579 8.15878 12.756 7.2902C12.8587 7.11053 12.8587 6.88945 12.756 6.70978Z"
        fill={color}
      />
    </svg>
  );
};
EyeOpenIcon.defaultProps = {
  color: typocolor,
  height: 14,
  width: 14,
  onClick: () => {}
};

export default EyeOpenIcon;

import { primary } from 'constants/color.constant';
import { IconProps } from 'types/common.types';

const RunwayIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      onClick={onClick}
      viewBox="0 0 26 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="26" height="8" fill={color} />
      <rect x="1.02637" y="1" width="23.9474" height="6" fill="#F7F3FF" />
    </svg>
  );
};
RunwayIcon.defaultProps = {
  color: primary,
  width: 26,
  height: 8,
  onClick: () => {}
};

export default RunwayIcon;

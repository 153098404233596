import { isEmpty } from 'lodash';
import { TriangleIcon } from 'constants/map.constants';
import ARPPolyline from './ARPPolyline';

interface RFArcLineProps {
  finalArcCords: any;
}

const RFArcLine = ({ finalArcCords }: RFArcLineProps) => {
  if (isEmpty(finalArcCords)) return null;
  const coordinateData = finalArcCords.map((point: any) => {
    return [Number(point[1]), Number(point[0])];
  });

  return (
    <ARPPolyline arpCoordinates={coordinateData} lineColor="#696B72" decoratorIcon={TriangleIcon} />
  );
};

export default RFArcLine;

import { Tooltip, Typography } from 'antd';
import RulerIcon from 'assets/svg/RulerIcon';
import { secondary, white } from 'constants/color.constant';

const { Text } = Typography;

interface DesignHeaderMarkToolsProps {
  activeMapTools: string | null;
  handleOnClickMapTools: (tool: string) => void;
}

const DesignHeaderMarkTools = ({
  activeMapTools,
  handleOnClickMapTools
}: DesignHeaderMarkToolsProps) => {
  return (
    <div>
      <Text>Mark Tools</Text>
      <div className="mark-tool">
        <Tooltip
          title="Measurement Tool"
          placement="bottom"
          color={secondary}
          className="tooltip-title"
        >
          <div
            onClick={() => handleOnClickMapTools('ruler')}
            className={activeMapTools === 'ruler' ? 'active-element' : ''}
          >
            <RulerIcon
              height={20}
              width={20}
              color={activeMapTools === 'ruler' ? secondary : white}
            />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default DesignHeaderMarkTools;

import { Table, TableProps } from 'antd';
import { generateString, latToDms, lngToDms } from 'utils/utils';

interface ObstacleAnalysisTableProps {
  obstacledata: any;
}

const ObstacleAnalysisTable = ({ obstacledata }: ObstacleAnalysisTableProps) => {
  const defaultColumns: TableProps<any>['columns'] = [
    {
      title: 'LEGS',
      key: 'legs',
      align: 'center',
      className: 'table-col-data',
      render: (record) =>
        `${record?.leg_name} (${record?.leg_start_point} - ${record?.leg_end_point})`
    },
    {
      title: 'UID',
      dataIndex: 'uid',
      key: 'uid',
      align: 'center',
      className: 'table-col-data',
      render: (t) => t || '-'
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      className: 'table-col-data',
      render: (t) => t || '-'
    },
    {
      title: 'Coordinates',
      key: 'Coordinates',
      align: 'center',
      className: 'table-col-data',
      render: (record) => {
        return (
          <>
            <div>
              {record?.coordinates && record?.coordinates[1]
                ? latToDms(record?.coordinates[0])
                : ''}
            </div>
            <div>
              {record?.coordinates && record?.coordinates[0]
                ? lngToDms(record?.coordinates[1])
                : ''}
            </div>
          </>
        );
      }
    },
    {
      title: 'Elevation',
      dataIndex: 'elv',
      key: 'ele',
      align: 'center',
      className: 'table-col-data',
      render: (t) => (t ? t.toFixed(2) : '-')
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      align: 'center',
      className: 'table-col-data',
      render: (t) => t || '-'
    },
    {
      title: 'MOCA',
      dataIndex: 'moc',
      key: 'moca',
      align: 'center',
      className: 'table-col-data',
      render: (t) => (t ? t.toFixed(2) : '-')
    },
    {
      title: 'Penetration',
      dataIndex: 'pen',
      key: 'penetration',
      align: 'center',
      className: 'table-col-data',
      render: (t) => (t ? t.toFixed(2) : '-')
    }
  ];

  return (
    <Table
      // className="obstacle-table"
      columns={defaultColumns}
      dataSource={obstacledata}
      pagination={false}
      rowKey={(record) => `${generateString(6)}-${record.pen}`}
      rowClassName={() => 'table-row-data'}
    />
  );
};

export default ObstacleAnalysisTable;

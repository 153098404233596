import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { orderBy } from 'lodash';
import { SurveyObstacleType } from 'store/design/types';

interface SurveyObstacleDatataTableProps {
  obstacleData: SurveyObstacleType[] | [];
  tableExpandView?: boolean;
}

const SurveyObstacleDatataTable = ({
  tableExpandView,
  obstacleData
}: SurveyObstacleDatataTableProps) => {
  const columns: ColumnsType<SurveyObstacleType> = [
    {
      title: 'UID',
      align: 'center',
      dataIndex: 'uid',
      key: 'uid',
      render: (text) => text || '-'
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (text) => text || '-'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (text) => text || '-'
    },
    {
      title: 'Latitude',
      dataIndex: 'latitude',
      key: 'latitude',
      render: (text) => text || '-'
    },
    {
      title: 'Longitude',
      dataIndex: 'longitude',
      key: 'longitude',
      render: (text) => text || '-'
    },
    {
      title: 'Top Elevation (m)',
      dataIndex: 'elevation',
      align: 'center',
      key: 'elevation',
      render: (text) => text.toFixed(3) || '-'
    }
  ];
  return (
    <div className="collect-table-content">
      <Table
        className={tableExpandView ? 'full-collect-table collect-table-main' : 'collect-table-main'}
        scroll={{ y: tableExpandView ? 'calc(100vh - 204px)' : 'calc(100% - 45px)' }}
        dataSource={orderBy(obstacleData, ['is_eaip'], ['asc']) || []}
        pagination={false}
        columns={columns}
        rowKey={(record) =>
          `cl-${record.elevation}-${record.type}-${record.latitude}-${record.longitude}-${record?.id}`
        }
      />
    </div>
  );
};

SurveyObstacleDatataTable.defaultProps = { tableExpandView: false };

export default SurveyObstacleDatataTable;

import { primary } from 'constants/color.constant';
import { IconProps } from 'types/common.types';

const DocFileIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 20"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00019 12H5.00019C4.44819 12 4.00019 11.552 4.00019 11C4.00019 10.448 4.44819 10 5.00019 10H8.00019C8.55219 10 9.00019 10.448 9.00019 11C9.00019 11.552 8.55219 12 8.00019 12ZM4.00019 15C4.00019 14.448 4.44819 14 5.00019 14H11.0002C11.5532 14 12.0002 14.448 12.0002 15C12.0002 15.552 11.5532 16 11.0002 16H5.00019C4.44819 16 4.00019 15.552 4.00019 15ZM13.4445 18H2.55549C2.24949 18 2.00049 17.776 2.00049 17.5V2.5C2.00049 2.224 2.24949 2 2.55549 2H8.00049V5.15C8.00049 6.722 9.21749 8 10.7145 8H14.0005V17.5C14.0005 17.776 13.7515 18 13.4445 18ZM10.0005 2.978L12.7425 6H10.7145C10.3205 6 10.0005 5.619 10.0005 5.15V2.978ZM15.7405 6.328L10.2965 0.328C10.1065 0.119 9.83849 0 9.55549 0H2.55549C1.14649 0 0.000488281 1.122 0.000488281 2.5V17.5C0.000488281 18.878 1.14649 20 2.55549 20H13.4445C14.8535 20 16.0005 18.878 16.0005 17.5V7C16.0005 6.751 15.9075 6.512 15.7405 6.328Z"
        fill={color}
      />
    </svg>
  );
};
DocFileIcon.defaultProps = {
  color: primary,
  width: 16,
  height: 20,
  onClick: () => {}
};

export default DocFileIcon;

import { combineReducers } from '@reduxjs/toolkit';
import { designApiService, userApiService } from 'services/apiService';
import user from './users';
import design from './design';
import alert from './alert';

const rootReducer = combineReducers({
  user,
  design,
  alert,
  [userApiService.reducerPath]: userApiService.reducer,
  [designApiService.reducerPath]: designApiService.reducer
});

export default rootReducer;

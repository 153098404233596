/* eslint-disable  */
import { useEffect, useState } from 'react';
import { isEmpty, some } from 'lodash';
import { Button, Radio, RadioChangeEvent, Space, Tabs, TabsProps, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import PrimaryPlusIcon from 'assets/svg/PrimaryPlusIcon';
import ProcedureCard from 'components/DesignData/ProcedureCard';
import { procedureStatusBtnTexts } from 'constants/designData.constants';
import ProcessTabComponent from 'components/DesignData/ProcessTabComponent';
import { useAppSelector } from 'hooks/useReduxHook';
import { DesignState, ProcedureDataType } from 'store/design/types';
import { useAddDesignProcedureMutation } from 'services/apis/designsApi';
import './styles.less';

interface DesignSidebarProps {
  selectedMetaId: number | null;
  selectedProcedureData: ProcedureDataType | null;
  orgid: number | null;
  selectedProcedureId: number;
  designid: any;
  designProcedurelist: ProcedureDataType[] | [];
  handleOnStartDesign: (startDesign: boolean) => void;
  handleLoading: (load: boolean) => void;
  handleSelectedProcedureId: (id: number) => void;
}

const { Text } = Typography;

const DesignSidebar = ({
  orgid,
  selectedMetaId,
  selectedProcedureData,
  designid,
  designProcedurelist,
  selectedProcedureId,
  handleOnStartDesign,
  handleSelectedProcedureId,
  handleLoading
}: DesignSidebarProps) => {
  const navigate = useNavigate();
  const { selectedDesign }: DesignState = useAppSelector((state) => state.design);
  const [activeTab, setActiveTab] = useState<string>('design');
  const [addDesignProcedure, { isLoading: isLoadingAddNewProcedure }] =
    useAddDesignProcedureMutation();

  useEffect(() => {
    handleLoading(isLoadingAddNewProcedure);
  }, [isLoadingAddNewProcedure]);

  const handleChangeTab = (key: string) => {
    setActiveTab(key);
  };

  const handleSelectSID = (e: RadioChangeEvent) => {
    handleSelectedProcedureId(e.target.value);
  };

  const handleAddnewSID = async () => {
    await addDesignProcedure({ orgId: orgid, designid, metaId: selectedMetaId });
  };

  const handleGoToAeroReport = async () => {
    navigate(`/report/${designid}`, { state: null, replace: true });
  };

  const items: TabsProps['items'] = [
    {
      key: 'design',
      label: 'Procedures'
    },
    {
      key: 'process',
      label: 'Process'
    }
  ];

  return (
    <div className="design-main-sidebar">
      <div className="design-sid">
        {selectedDesign ? selectedDesign.name : '-'}{' '}
        {selectedDesign ? `[${selectedDesign.procedure_type}]` : ''}
      </div>
      <div
        className={activeTab === 'process' ? 'data-design' : 'data-design procedure-data-design'}
      >
        <Tabs
          className={
            activeTab === 'design' ? 'design-process-tab design-active' : 'design-process-tab'
          }
          activeKey={activeTab}
          items={items}
          hideAdd
          onChange={handleChangeTab}
        />
        {activeTab === 'design' && (
          <div className="design-tab-cntent">
            <div className="inprogress-sid-list">
              <div className="procedure-data-list">
                {!isEmpty(designProcedurelist) && (
                  <Radio.Group onChange={handleSelectSID} value={selectedProcedureId}>
                    <Space direction="vertical" className="sid-main-list">
                      {designProcedurelist
                        .filter(
                          (d: ProcedureDataType) =>
                            d.status === 'in-progress' || d.status === 'not-started'
                        )
                        .map((procedure: ProcedureDataType) => (
                          <ProcedureCard
                            key={procedure.id}
                            selectedProcedureId={selectedProcedureId}
                            value={procedure.id}
                            procedure={procedure}
                          />
                        ))}
                    </Space>
                  </Radio.Group>
                )}
                <div className="add-sid-div">
                  <Button
                    type="link"
                    className="add-new-sid-btn"
                    onClick={handleAddnewSID}
                    loading={isLoadingAddNewProcedure}
                    icon={<PrimaryPlusIcon />}
                  >
                    Add New Design
                  </Button>
                </div>
              </div>
              <div className="proc-design-btn">
                <Button
                  type="primary"
                  disabled={isEmpty(designProcedurelist)}
                  onClick={() => handleOnStartDesign(true)}
                >
                  {selectedProcedureData && selectedProcedureData.status
                    ? procedureStatusBtnTexts[selectedProcedureData.status]
                    : 'Start Procedure'}
                </Button>
              </div>
            </div>
            <div className="completed-sid-list">
              <Text className="complete-sid-title">Completed Procedures</Text>
              <Radio.Group
                className="complete-sid-radio-grp"
                onChange={handleSelectSID}
                value={selectedProcedureId}
              >
                {!isEmpty(designProcedurelist) && (
                  <Space direction="vertical" className="sid-main-list">
                    {!isEmpty(
                      designProcedurelist.filter((d: ProcedureDataType) => d.status === 'completed')
                    ) ? (
                      designProcedurelist
                        .filter((d: ProcedureDataType) => d.status === 'completed')
                        .map((procedure: ProcedureDataType) => (
                          <ProcedureCard
                            key={procedure.id}
                            selectedProcedureId={selectedProcedureId}
                            value={procedure.id}
                            procedure={procedure}
                          />
                        ))
                    ) : (
                      <Text className="complete-sid-title nocomplete-sid-title">
                        No Completed Procedures
                      </Text>
                    )}
                  </Space>
                )}
              </Radio.Group>
              <div className="goto-report-btn-div">
                <Button
                  type="primary"
                  className="goto-aero-report-btn"
                  onClick={handleGoToAeroReport}
                  disabled={
                    some(
                      designProcedurelist,
                      (d: ProcedureDataType) =>
                        d.status === 'in-progress' || d.status === 'not-started'
                    ) || isEmpty(designProcedurelist)
                  }
                >
                  Go to Aero Report
                </Button>
              </div>
            </div>
          </div>
        )}
        {activeTab === 'process' && <ProcessTabComponent />}
      </div>
      {activeTab === 'process' && <div className="start-design-div" />}
    </div>
  );
};

export default DesignSidebar;

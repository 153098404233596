import { FocusEvent, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { Button, Col, Row, Typography } from 'antd';
import InfoCircleIcon from 'assets/svg/InfoCircleIcon';
import { red } from 'constants/color.constant';
import CustomDivider from 'components/core/CustomDivider';
import {
  InfobtwpointsResponseType,
  InitStartInfoType,
  OAlegdata,
  TemperatureType
} from 'store/design/types';
import { useLazyGetDesignInfobtwPointsdataQuery } from 'services/apis/designsApi';
import { latToDms, lngToDms } from 'utils/utils';
import OmniSpeedLeg from './OmniSpeedLeg';
import OmniPAOALeg from './OmniPAOALeg';
import OmniEndPointAltGradLeg from './OmniEndPointAltGradLeg';
import OmniTurnLeg from './OmniTurnLeg';

const { Text } = Typography;

interface OmniLegDataProps {
  isDesigner: boolean;
  activeLegDesignDataOMNI: any;
  activeLegKey: string;
  activeObscleTerrainDataKey: string;
  initStartInfoOMNI: InitStartInfoType | null;
  handleShowObstacleTableOMNI: (isShow: boolean) => void;
  cwyDisOMNI: number | null;
  endAltGradLengthOMNI: number | null;
  changeCWYPointInfoDataOMNI: InfobtwpointsResponseType | null;
  changeLengthPointInfoDataOMNI: InfobtwpointsResponseType | null;
  handleChangeCWYSetInitInfoDataOMNI: Function;
  handleOMNIChangeCWYdisInput: Function;
  handleChangeLengthOMNI: Function;
  handleClickEndLatLngViewOnMapOMNI: Function;
  temperature: TemperatureType | null;
  handleOnChangeDataReviewIASOMNI: Function;
  iasSpeedOMNI: number | null;
  tasSpeedOMNI: number | null;
  handleGeneratePADataOMNI: Function;
  handleGenerateOADataOMNI: Function;
  activeOALegDesignDataOMNI: OAlegdata[] | [];
  isShowHideOADataOMNI: boolean;
  handleChangeOmniEndPDG: Function;
  isShowHideTerrainLayerOMNI: boolean;
  handleShowTerrainLayerOMNI: Function;
  handleChangeOmniEndAltGrad: Function;
  handleChangeOmniEndLength: Function;
  disableEndPointFieldOMNI: string;
  handleEndDisableFieldOMNI: Function;
  handleClickOnSpeedTabOmni: Function;
  pdgOMNI: number;
  endAltitudeOMNI: number | null;
  isLengthAltPDGRangeOMNI: boolean;
  handleShowLengthAltPDGRangeOMNI: (isShow: boolean) => void;
  handleChangeRemarksDepOmni: (isTerminate: string | undefined) => void;
  remarksDepOmni: string | undefined;
  isShowHideRemarkDepOmniBtn: boolean;
  isLoadingAddRemarkData: boolean;
  handleAddUpdateRemarkDepOmni: Function;
  handleRefreshGeneratedOAdata: Function;
}

const OmniLegData = ({
  isDesigner,
  activeLegDesignDataOMNI,
  activeLegKey,
  activeObscleTerrainDataKey,
  initStartInfoOMNI,
  endAltGradLengthOMNI,
  changeCWYPointInfoDataOMNI,
  cwyDisOMNI,
  temperature,
  changeLengthPointInfoDataOMNI,
  iasSpeedOMNI,
  tasSpeedOMNI,
  activeOALegDesignDataOMNI,
  isShowHideOADataOMNI,
  disableEndPointFieldOMNI,
  pdgOMNI,
  endAltitudeOMNI,
  handleShowObstacleTableOMNI,
  handleShowLengthAltPDGRangeOMNI,
  isLengthAltPDGRangeOMNI,
  handleChangeCWYSetInitInfoDataOMNI,
  handleOMNIChangeCWYdisInput,
  handleChangeLengthOMNI,
  handleClickEndLatLngViewOnMapOMNI,
  handleOnChangeDataReviewIASOMNI,
  handleGenerateOADataOMNI,
  handleGeneratePADataOMNI,
  handleChangeOmniEndPDG,
  handleChangeOmniEndAltGrad,
  handleChangeOmniEndLength,
  handleEndDisableFieldOMNI,
  handleClickOnSpeedTabOmni,
  isShowHideTerrainLayerOMNI,
  handleShowTerrainLayerOMNI,
  remarksDepOmni,
  isShowHideRemarkDepOmniBtn,
  isLoadingAddRemarkData,
  handleAddUpdateRemarkDepOmni,
  handleChangeRemarksDepOmni,
  handleRefreshGeneratedOAdata
}: OmniLegDataProps) => {
  const [activeDeptBtn, setActiveDeptBtn] = useState<string>('endpoint');
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  const [getDesignInfobtwPointsdata, { data: infobtwPointsDataChangeCWY }] =
    useLazyGetDesignInfobtwPointsdataQuery();

  useEffect(() => {
    if (infobtwPointsDataChangeCWY) {
      const infobtwPointsDataChangeCWYRes: any = infobtwPointsDataChangeCWY;
      if (infobtwPointsDataChangeCWYRes.status) {
        if (!isEmpty(infobtwPointsDataChangeCWYRes.data)) {
          handleChangeOmniEndPDG(3.3);
          handleChangeOmniEndAltGrad(null);
          handleChangeOmniEndLength(null);
          handleChangeCWYSetInitInfoDataOMNI(infobtwPointsDataChangeCWYRes.data || null);
        }
      }
    }
  }, [infobtwPointsDataChangeCWY]);

  useEffect(() => {
    if (activeLegKey === '1') {
      setIsCollapsed(true);
    } else {
      setIsCollapsed(false);
    }
  }, [activeLegKey]);

  const handleCollapseStartPoint = () => {
    if (activeLegKey !== '1') {
      setIsCollapsed(!isCollapsed);
    }
  };

  // eslint-disable-next-line
  const handleChangeClearwayDistanceOMNI = async (e: FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (!value) return;
    handleOMNIChangeCWYdisInput(value);

    if (initStartInfoOMNI) {
      const latA = initStartInfoOMNI.thr_point[0];
      const lngB = initStartInfoOMNI.thr_point[1];
      const distance = (initStartInfoOMNI.cwy / 1852).toFixed(10);
      const bearingdata = initStartInfoOMNI.bearing;
      const urlString = `/infobtwpoints?lat_a=${latA}&lng_a=${lngB}&distance=${distance}&bearing=${bearingdata}`;

      await getDesignInfobtwPointsdata({ urlString });
    }
  };

  return (
    <div className="leg-main-content omni-leg">
      <div className="point-details">
        <div className="start-point-main">
          <div>
            <div onClick={handleCollapseStartPoint}>
              <Text>Start Point</Text>
            </div>
          </div>
          {isCollapsed && (
            <div className="start-point-content">
              <div className="der-content">
                <Text>DER Details</Text>
                <Text>
                  <InfoCircleIcon color={red} />
                  <Text>DER coordinates are derived based on Clearway Dis.</Text>
                </Text>
              </div>
              <Row justify="space-between" style={{ marginTop: 16, paddingBottom: 12 }}>
                <Col span={11} className="general-content">
                  {/* <div className="clearway-dist">
                    <div>Clearway Dis.</div>
                    <InputNumber
                      placeholder="Enter"
                      type="number"
                      suffix="m"
                      min={150}
                      readOnly
                      disabled={!isEmpty(activeLegDesignDataOMNI) || !isDesigner}
                      max={500}
                      value={
                        !isEmpty(activeLegDesignDataOMNI)
                          ? activeLegDesignDataOMNI?.parameters?.cwy
                          : cwyDisOMNI
                      }
                      onBlur={handleChangeClearwayDistanceOMNI}
                      style={{ width: 80, color: primary }}
                    />
                  </div> */}
                  <div>
                    <div>Clearway Dis.</div>
                    <div>
                      {!isEmpty(activeLegDesignDataOMNI)
                        ? activeLegDesignDataOMNI?.parameters?.cwy
                        : cwyDisOMNI}{' '}
                      m
                    </div>
                  </div>
                  <div>
                    <div>Start Altitude</div>
                    <div>
                      {!isEmpty(activeLegDesignDataOMNI)
                        ? Number(activeLegDesignDataOMNI.start_waypoint.alt).toFixed(3)
                        : initStartInfoOMNI?.der_elev}{' '}
                      ft MSL
                    </div>
                  </div>
                  <div className="max-der-text">
                    <InfoCircleIcon color={red} />
                    <Text className="max-der-text">MAX(DER , AD ELEV) +5 m</Text>
                  </div>
                </Col>
                <Col>
                  <CustomDivider type="vertical" className="general-divider" />
                </Col>
                <Col span={11} className="general-content">
                  <div>
                    <div>LAT</div>
                    <div>
                      {!isEmpty(activeLegDesignDataOMNI)
                        ? latToDms(activeLegDesignDataOMNI.start_waypoint.lat)
                        : isEmpty(activeLegDesignDataOMNI) && !isEmpty(changeCWYPointInfoDataOMNI)
                        ? latToDms(changeCWYPointInfoDataOMNI.B[0])
                        : initStartInfoOMNI
                        ? latToDms(initStartInfoOMNI.der[0])
                        : '-'}
                    </div>
                  </div>
                  <div>
                    <div>LONG</div>
                    <div>
                      {!isEmpty(activeLegDesignDataOMNI)
                        ? lngToDms(activeLegDesignDataOMNI.start_waypoint.lng)
                        : isEmpty(activeLegDesignDataOMNI) && !isEmpty(changeCWYPointInfoDataOMNI)
                        ? lngToDms(changeCWYPointInfoDataOMNI.B[1])
                        : initStartInfoOMNI
                        ? lngToDms(initStartInfoOMNI.der[1])
                        : '-'}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </div>
        <div className="tab-extra-content omni-tab-extra-content">
          <div className="tab-group">
            <Button.Group className="point-btn-grp">
              <Button
                className={activeDeptBtn === 'endpoint' ? 'point-active-btn' : ''}
                onClick={() => setActiveDeptBtn('endpoint')}
              >
                End Point / Altitude / Gradient
              </Button>
              <Button
                className={activeDeptBtn === 'turn' ? 'point-active-btn' : ''}
                onClick={() => setActiveDeptBtn('turn')}
              >
                Turn
              </Button>
              <Button
                className={activeDeptBtn === 'speed' ? 'point-active-btn' : ''}
                onClick={() => {
                  setActiveDeptBtn('speed');
                  handleClickOnSpeedTabOmni();
                }}
              >
                Speed
              </Button>
              <Button
                className={
                  activeDeptBtn === 'pa-oa'
                    ? 'paoa-btn point-active-btn paoa-btn-active-btn'
                    : 'paoa-btn'
                }
                onClick={() => setActiveDeptBtn('pa-oa')}
              >
                PA/OA
              </Button>
            </Button.Group>
          </div>
          {activeDeptBtn === 'endpoint' && (
            <OmniEndPointAltGradLeg
              isDesigner={isDesigner}
              activeLegDesignDataOMNI={activeLegDesignDataOMNI}
              endAltGradLengthOMNI={endAltGradLengthOMNI}
              handleChangeLengthOMNI={handleChangeLengthOMNI}
              changeLengthPointInfoDataOMNI={changeLengthPointInfoDataOMNI}
              handleClickEndLatLngViewOnMapOMNI={handleClickEndLatLngViewOnMapOMNI}
              handleChangeOmniEndPDG={handleChangeOmniEndPDG}
              handleChangeOmniEndAltGrad={handleChangeOmniEndAltGrad}
              handleChangeOmniEndLength={handleChangeOmniEndLength}
              disableEndPointFieldOMNI={disableEndPointFieldOMNI}
              handleEndDisableFieldOMNI={handleEndDisableFieldOMNI}
              pdgOMNI={pdgOMNI}
              endAltitudeOMNI={endAltitudeOMNI}
              initStartInfoOMNI={initStartInfoOMNI}
              handleShowLengthAltPDGRangeOMNI={handleShowLengthAltPDGRangeOMNI}
              isLengthAltPDGRangeOMNI={isLengthAltPDGRangeOMNI}
            />
          )}
          {activeDeptBtn === 'turn' && (
            <OmniTurnLeg
              activeLegDesignDataOMNI={activeLegDesignDataOMNI}
              activeLegKey={activeLegKey}
            />
          )}
          {activeDeptBtn === 'speed' && (
            <OmniSpeedLeg
              isDesigner={isDesigner}
              activeLegDesignDataOMNI={activeLegDesignDataOMNI}
              iasSpeedOMNI={iasSpeedOMNI}
              tasSpeedOMNI={tasSpeedOMNI}
              temperature={temperature}
              handleOnChangeDataReviewIASOMNI={handleOnChangeDataReviewIASOMNI}
              endAltGradLengthOMNI={endAltGradLengthOMNI}
            />
          )}
          {activeDeptBtn === 'pa-oa' && (
            <OmniPAOALeg
              isDesigner={isDesigner}
              activeLegKey={activeLegKey}
              handleShowObstacleTableOMNI={handleShowObstacleTableOMNI}
              handleGeneratePADataOMNI={handleGeneratePADataOMNI}
              handleGenerateOADataOMNI={handleGenerateOADataOMNI}
              activeLegDesignDataOMNI={activeLegDesignDataOMNI}
              activeOALegDesignDataOMNI={activeOALegDesignDataOMNI}
              isShowHideOADataOMNI={isShowHideOADataOMNI}
              isShowHideTerrainLayerOMNI={isShowHideTerrainLayerOMNI}
              handleShowTerrainLayerOMNI={handleShowTerrainLayerOMNI}
              activeObscleTerrainDataKey={activeObscleTerrainDataKey}
              handleChangeRemarksDepOmni={handleChangeRemarksDepOmni}
              remarksDepOmni={remarksDepOmni}
              isShowHideRemarkDepOmniBtn={isShowHideRemarkDepOmniBtn}
              isLoadingAddRemarkData={isLoadingAddRemarkData}
              handleAddUpdateRemarkDepOmni={handleAddUpdateRemarkDepOmni}
              handleRefreshGeneratedOAdata={handleRefreshGeneratedOAdata}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default OmniLegData;

import { white } from 'constants/color.constant';
import { IconProps } from 'types/common.types';

const ConventionalRoutesIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5 19L8 9M8 9L16 4.5M8 9L17 15" stroke={color} />
      <path d="M3.26795 20L5 17L6.73205 20H3.26795Z" fill={color} stroke={color} />
      <path d="M6.26795 10L8 7L9.73205 10H6.26795Z" fill={color} stroke={color} />
      <path d="M15.2679 5L17 2L18.7321 5H15.2679Z" fill={color} stroke={color} />
      <path d="M15.2679 16L17 13L18.7321 16H15.2679Z" fill={color} stroke={color} />
    </svg>
  );
};
ConventionalRoutesIcon.defaultProps = {
  color: white,
  width: 24,
  height: 24,
  onClick: () => {}
};

export default ConventionalRoutesIcon;

/* eslint-disable */
import React, { useRef, useState } from 'react';
import { Marker, useMapEvents } from 'react-leaflet';
import { FlybyWaypointIcon, FlyoverWaypointIcon } from 'constants/map.constants';

const DraggableMarker = ({
  activeFlyBtn,
  arcEndCoord,
  handleCreateWaypointLatLng,
  handleCallAreaSemiwidth
}: any) => {
  const [position, setPosition] = useState<any>(arcEndCoord);
  const markerRef = useRef(null);

  useMapEvents({
    click(event) {
      const marker = markerRef.current;
      if (marker != null) {
        const latlng = event.latlng;
        // @ts-ignore
        marker.setLatLng(latlng);
        handleCreateWaypointLatLng([latlng.lat, latlng.lng]);
        handleCallAreaSemiwidth([latlng.lat, latlng.lng]);
      }
    }
  });

  const handleDragEnd = () => {
    const marker = markerRef.current;
    if (marker != null) {
      // @ts-ignore
      const latlng = marker.getLatLng();
      setPosition(latlng);
      handleCreateWaypointLatLng([latlng.lat, latlng.lng]);
      handleCallAreaSemiwidth([latlng.lat, latlng.lng]);
    }
  };

  const handleMarkerDrag = () => {
    const marker = markerRef.current;
    if (marker != null) {
      // @ts-ignore
      const latlng = marker.getLatLng();
      setPosition(latlng);
      handleCreateWaypointLatLng([latlng.lat, latlng.lng]);
    }
  };

  return (
    <Marker
      icon={activeFlyBtn === 'by' ? FlybyWaypointIcon : FlyoverWaypointIcon}
      draggable
      eventHandlers={{
        drag: handleMarkerDrag,
        dragend: handleDragEnd
      }}
      position={position}
      ref={markerRef}
    />
  );
};

export default DraggableMarker;

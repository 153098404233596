import { useMemo } from 'react';
import { isEmpty } from 'lodash';
import { Polyline } from 'react-leaflet';
import { LightTriangleIcon } from 'constants/map.constants';
import PolylineArrowDecorator from './PolylineArrowDecorator';

interface ARPPolylineProps {
  arpCoordinates: any;
  weight?: number;
  decoratorIcon?: any;
  lineColor?: string | undefined;
}

const ARPPolyline = ({ arpCoordinates, lineColor, decoratorIcon, weight }: ARPPolylineProps) => {
  const lineShape = useMemo(() => {
    if (isEmpty(arpCoordinates)) return null;
    return (
      <Polyline
        pane="shadowPane"
        pathOptions={{ color: lineColor, weight }}
        positions={arpCoordinates}
      />
    );
  }, [arpCoordinates]);

  // eslint-disable-next-line
  return (
    <>
      {lineShape}
      <PolylineArrowDecorator coordinates={arpCoordinates} decoratorIcon={decoratorIcon} />
    </>
  );
};

ARPPolyline.defaultProps = {
  lineColor: '#27A4FF',
  decoratorIcon: LightTriangleIcon,
  weight: 1
};

export default ARPPolyline;

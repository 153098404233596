import { Button, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { orderBy } from 'lodash';
import CloseIcon from 'assets/svg/CloseIcon';
import ExpandIcon from 'assets/svg/ExpandIcon';
import { primary } from 'constants/color.constant';
import { SurveyObstacleType } from 'store/design/types';
import './styles.less';

const { Text } = Typography;

interface DesignSurveyObsaclesTableProps {
  obstacleData: SurveyObstacleType[] | [];
  isObsTerrainFullScreenTable: boolean;
  handleShowAeroTable: (isHide: boolean) => void;
  handleShowFullScreenTable: (isShow: boolean) => void;
}

const DesignSurveyObsaclesTable = ({
  obstacleData,
  isObsTerrainFullScreenTable,
  handleShowAeroTable,
  handleShowFullScreenTable
}: DesignSurveyObsaclesTableProps) => {
  const columns: ColumnsType<SurveyObstacleType> = [
    {
      title: 'UID',
      align: 'center',
      dataIndex: 'uid',
      key: 'uid',
      render: (text) => text || '-'
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (text) => text || '-'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (text) => text || '-'
    },
    {
      title: 'Latitude',
      dataIndex: 'latitude',
      key: 'latitude',
      render: (text) => text || '-'
    },
    {
      title: 'Longitude',
      dataIndex: 'longitude',
      key: 'longitude',
      render: (text) => text || '-'
    },
    {
      title: 'Top Elevation(m)',
      dataIndex: 'elevation',
      align: 'center',
      key: 'elevation',
      render: (text) => text.toFixed(3) || '-'
    }
  ];

  return (
    <div
      className={
        isObsTerrainFullScreenTable ? 'full-coding-details aerodrome-details' : 'aerodrome-details'
      }
    >
      {!isObsTerrainFullScreenTable && (
        <div className="aerodrome-header">
          <div>
            <Text>Survey Obstacles</Text>
            <Button
              type="text"
              icon={<ExpandIcon />}
              onClick={() => handleShowFullScreenTable(true)}
              className="back-icon-btn"
            />
          </div>
          <Button
            type="text"
            shape="circle"
            icon={<CloseIcon color={primary} />}
            className="back-icon-btn"
            onClick={() => handleShowAeroTable(false)}
          />
        </div>
      )}
      <div>
        <Table
          // className="aero-obstacle-table design-table-main"
          className={
            isObsTerrainFullScreenTable
              ? 'full-collect-table collect-table-main '
              : 'aero-obstacle-table design-table-main'
          }
          scroll={{
            y: isObsTerrainFullScreenTable ? 'calc(100vh - 204px)' : 250
          }}
          columns={columns}
          dataSource={orderBy(obstacleData, ['is_eaip'], ['asc']) || []}
          pagination={false}
          // scroll={{ y: 250 }}
          rowKey={(record) =>
            `cl-${record.elevation}-${record.type}-${record.latitude}-${record.longitude}-${record?.id}`
          }
        />
      </div>
    </div>
  );
};

export default DesignSurveyObsaclesTable;

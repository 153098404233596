import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AiOutlineSearch } from 'react-icons/ai';
import { Button, Input, Typography } from 'antd';
import MapIcon from 'assets/svg/MapIcon';
import ListIcon from 'assets/svg/ListIcon';
import { black, secondary, white } from 'constants/color.constant';
import { routes } from 'constants/pageRoutes.constants';
import { useAppSelector } from 'hooks/useReduxHook';
import { UserResponse, UserRole } from 'store/users/types';
import './styles.less';

const { Text } = Typography;

interface SubHeaderProps {
  title: string | null;
  classname?: string;
  isMapPage?: boolean;
  isListPage?: boolean;
  handleSearchAirport?: Function;
  filterText?: string;
}

const SubHeader = ({
  title,
  classname,
  isMapPage,
  isListPage,
  handleSearchAirport,
  filterText
}: SubHeaderProps) => {
  const navigate = useNavigate();
  const currentUser: UserResponse = useAppSelector((state) => state.user.userSession);

  const handleCreatenewDesign = () => {
    navigate(routes.CreateDesign);
  };

  const handleNavigateUserManagement = () => {
    navigate(routes.UserManagement);
  };

  const handleNavigateHomepage = () => {
    navigate(routes.Home);
  };

  const handleListView = () => {
    navigate(routes.DesignList);
  };

  return (
    <div className={classname}>
      <div className="search-input-div">
        {title && <Text className="subheader-title">{title}</Text>}
        <Input
          placeholder="Search Airports"
          className="search-input"
          value={filterText}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            handleSearchAirport && handleSearchAirport((e.target as HTMLInputElement).value)
          }
          prefix={<AiOutlineSearch />}
        />
      </div>
      <div className="create-list-div">
        {currentUser?.role === UserRole.Admin && (
          <Button type="primary" onClick={handleNavigateUserManagement}>
            User Management
          </Button>
        )}
        {currentUser?.role === UserRole.Manager && (
          <Button type="primary" onClick={() => handleCreatenewDesign()}>
            Create New Design
          </Button>
        )}
        <Button.Group className="list-map-grp-btn">
          <Button
            className={isMapPage ? 'active-view-btn' : ''}
            icon={<MapIcon color={isMapPage ? white : black} />}
            onClick={handleNavigateHomepage}
          />
          <Button
            className={isListPage ? 'active-view-btn' : ''}
            icon={<ListIcon color={isListPage ? white : secondary} />}
            onClick={handleListView}
          />
        </Button.Group>
      </div>
    </div>
  );
};

SubHeader.defaultProps = {
  classname: '',
  isMapPage: false,
  isListPage: false,
  handleSearchAirport: () => {},
  filterText: ''
};

export default SubHeader;

import { white } from 'constants/color.constant';
import { IconProps } from 'types/common.types';

const TerrainIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.89 16.2292L14.0118 8.1927C13.9079 8.07136 13.7451 8 13.572 8C13.3989 8 13.236 8.07136 13.1322 8.1927L10.2805 11.5247L9.14819 10.2017C9.04439 10.0804 8.88144 10.009 8.70838 10.009C8.53532 10.009 8.37244 10.0804 8.26857 10.2017L3.10992 16.2292C2.98502 16.3751 2.96493 16.5705 3.05802 16.7337C3.15112 16.8969 3.34148 17 3.54973 17H20.4502C20.6585 17 20.8488 16.8969 20.9419 16.7337C21.0351 16.5705 21.015 16.3751 20.89 16.2292ZM4.64925 16.0365L8.70838 11.2937L9.84059 12.6167C9.94439 12.738 10.1073 12.8094 10.2804 12.8094C10.4535 12.8094 10.6163 12.738 10.7202 12.6167L13.5719 9.28468L19.3507 16.0365H4.64925Z"
        fill={color}
      />
    </svg>
  );
};
TerrainIcon.defaultProps = {
  color: white,
  width: 24,
  height: 24,
  onClick: () => {}
};

export default TerrainIcon;

import { isEmpty } from 'lodash';
import {
  Button,
  Col,
  Divider,
  InputNumber,
  Radio,
  RadioChangeEvent,
  Row,
  Switch,
  Typography
} from 'antd';
import RoundCircleIcon from 'assets/svg/RoundCircleIcon';
import SquareCircleIcon from 'assets/svg/SquareCircleIcon';
import { typocolor, primary } from 'constants/color.constant';
import CustomDivider from 'components/core/CustomDivider';
import { useAppSelector } from 'hooks/useReduxHook';
import {
  AreasemiwidthDepartureType,
  DesignState,
  InitStartInfoType,
  WaypointMarkerResponseType
} from 'store/design/types';
import { latToDms, lngToDms } from 'utils/utils';

const { Text } = Typography;

interface EndPointLegProps {
  isDesigner: boolean;
  activeLegKey: string;
  activeLegDesignDataDeparture: any;
  activePrevLegDesignDataDeparture: any;
  endWaypointLatLngDeparture: any;
  handleChangeWaypoint: (waypoint: string) => void;
  handleViewSelectWaypointOnMap: (waypoint: string) => void;
  areasemiwidthDeparture: AreasemiwidthDepartureType | null;
  handleActiveFlyBtn: (isActiveBtn: string) => void;
  handleEndWaypointLat: (waypointlat: number | undefined) => void;
  handleEndWaypointLng: (waypointlng: number | undefined) => void;
  handleEnterLatLngViewOnMap: (isView: boolean) => void;
  handleChangeTerminatingWaypoint: (isTerminate: boolean) => void;
  selectedWayPointMarker: WaypointMarkerResponseType | null;
  endSelectNavaidWaypoint: string | null;
  endWaypointLat: number | null;
  endWaypointLng: number | null;
  endAltitudeDeparture: number | null;
  initStartInfoDeparture: InitStartInfoType | null;
  activeFlyBtn: string;
  isTerminatingWaypoint: boolean;
  selectedPathTerminatorDeparture: string | undefined;
}

const EndPointLeg = ({
  isDesigner,
  activeLegKey,
  initStartInfoDeparture,
  areasemiwidthDeparture,
  endWaypointLatLngDeparture,
  activeLegDesignDataDeparture,
  selectedPathTerminatorDeparture,
  handleChangeWaypoint,
  handleActiveFlyBtn,
  handleEndWaypointLat,
  handleEndWaypointLng,
  endSelectNavaidWaypoint,
  selectedWayPointMarker,
  endWaypointLat,
  endWaypointLng,
  activeFlyBtn,
  endAltitudeDeparture,
  activePrevLegDesignDataDeparture,
  isTerminatingWaypoint,
  handleEnterLatLngViewOnMap,
  handleViewSelectWaypointOnMap,
  handleChangeTerminatingWaypoint
}: EndPointLegProps) => {
  const { designProcedureVersionsData }: DesignState = useAppSelector((state) => state.design);

  const handleonChangeWaypoint = (e: RadioChangeEvent) => {
    handleChangeWaypoint(e.target.value);
  };

  const handleClickViewOnMap = () => {
    handleEnterLatLngViewOnMap(true);
  };

  const minalt: number = Number(initStartInfoDeparture?.der_elev) || 0;

  return (
    <div className="leg-tab end-point">
      <div>
        <Text style={{ paddingLeft: 8 }}>Select Navaid / Waypoint </Text>
        <Text>
          Waypoint Name -{' '}
          {!isEmpty(activeLegDesignDataDeparture)
            ? activeLegDesignDataDeparture.end_waypoint.waypoint_name
            : selectedWayPointMarker
            ? selectedWayPointMarker.name
            : 'XXXXX'}
        </Text>
      </div>
      <div style={{ marginTop: 16 }}>
        <Radio.Group
          onChange={handleonChangeWaypoint}
          value={endSelectNavaidWaypoint}
          className="way-point"
        >
          {((Number(activeLegKey) === 1 &&
            endAltitudeDeparture &&
            !(Number(endAltitudeDeparture) >= 400 + minalt)) ||
            (!isEmpty(activePrevLegDesignDataDeparture) &&
              Number(activePrevLegDesignDataDeparture.end_waypoint.alt) > endAltitudeDeparture!)) &&
          selectedPathTerminatorDeparture === 'CA' ? (
            <div
              className={
                endSelectNavaidWaypoint === 'selectWaypoint'
                  ? 'checked-waypoint select-waypoint alt-range-error-info'
                  : 'select-waypoint'
              }
            >
              <Radio
                disabled={
                  !isDesigner ||
                  !isEmpty(activeLegDesignDataDeparture) ||
                  isEmpty(selectedPathTerminatorDeparture)
                }
                value="selectWaypoint"
              >
                Waypoint on Map
              </Radio>
              {endSelectNavaidWaypoint === 'selectWaypoint' &&
                selectedPathTerminatorDeparture !== 'CA' && (
                  <Button
                    type="text"
                    disabled={!isDesigner || !isEmpty(activeLegDesignDataDeparture)}
                    onClick={() => handleViewSelectWaypointOnMap('selectWaypoint')}
                  >
                    view on map
                  </Button>
                )}
            </div>
          ) : (
            <div
              className={
                endSelectNavaidWaypoint === 'selectWaypoint'
                  ? 'checked-waypoint select-waypoint'
                  : 'select-waypoint'
              }
            >
              <Radio
                disabled={
                  !isDesigner ||
                  !isEmpty(activeLegDesignDataDeparture) ||
                  isEmpty(selectedPathTerminatorDeparture) ||
                  (Number(activeLegKey) === 1 && selectedPathTerminatorDeparture === 'CF')
                }
                value="selectWaypoint"
              >
                Waypoint on Map
              </Radio>
              {endSelectNavaidWaypoint === 'selectWaypoint' &&
                selectedPathTerminatorDeparture !== 'CA' && (
                  <Button
                    type="text"
                    disabled={!isDesigner || !isEmpty(activeLegDesignDataDeparture)}
                    onClick={() => handleViewSelectWaypointOnMap('selectWaypoint')}
                  >
                    view on map
                  </Button>
                )}
            </div>
          )}
          <div
            className={
              endSelectNavaidWaypoint === 'createWaypoint'
                ? 'checked-waypoint checked-createpoint create-waypoint'
                : 'create-waypoint'
            }
          >
            <Radio
              disabled={
                !isDesigner ||
                !isEmpty(activeLegDesignDataDeparture) ||
                isEmpty(selectedPathTerminatorDeparture) ||
                selectedPathTerminatorDeparture === 'CA'
              }
              value="createWaypoint"
            >
              Create new Waypoint on Map
            </Radio>
          </div>
          <div
            className={
              endSelectNavaidWaypoint === 'latlngWaypoint'
                ? 'checked-waypoint checked-latlngpoint lat-lng-main'
                : 'lat-lng-main'
            }
          >
            <div className="lat-long">
              <Radio
                disabled={
                  !isDesigner ||
                  !isEmpty(activeLegDesignDataDeparture) ||
                  isEmpty(selectedPathTerminatorDeparture) ||
                  (selectedPathTerminatorDeparture === 'CF' && Number(activeLegKey) === 1) ||
                  selectedPathTerminatorDeparture === 'CA'
                }
                value="latlngWaypoint"
              >
                Enter Lat/Long
              </Radio>
              <InputNumber
                placeholder="Enter Lat"
                type="number"
                className="lat-lng-input"
                disabled={
                  !isDesigner ||
                  !isEmpty(activeLegDesignDataDeparture) ||
                  endSelectNavaidWaypoint !== 'latlngWaypoint'
                }
                value={endWaypointLat || undefined}
                onChange={(value: any) => handleEndWaypointLat(value)}
                style={{ width: 140, color: primary }}
              />
              <InputNumber
                placeholder="Enter Long"
                type="number"
                value={endWaypointLng || undefined}
                className="lat-lng-input"
                disabled={
                  !isDesigner ||
                  !isEmpty(activeLegDesignDataDeparture) ||
                  endSelectNavaidWaypoint !== 'latlngWaypoint'
                }
                onChange={(value: any) => handleEndWaypointLng(value)}
                style={{ width: 140, color: primary }}
              />
            </div>
            <div className="waypoint-label">
              <Button
                type="primary"
                disabled={
                  endSelectNavaidWaypoint !== 'latlngWaypoint' &&
                  isEmpty(endWaypointLat) &&
                  isEmpty(endWaypointLng)
                }
                ghost
                onClick={handleClickViewOnMap}
              >
                View on Map
              </Button>
            </div>
          </div>
        </Radio.Group>
      </div>
      <Divider className="waypoint-divider" />
      <div className="start-point-content general-point">
        <Row justify="space-between" style={{ marginBottom: 16 }}>
          <Col span={11} className="general-content">
            <div>
              <div style={{ paddingLeft: 8 }}>XTT</div>
              <div>
                {activeLegDesignDataDeparture
                  ? `${activeLegDesignDataDeparture.end_waypoint.aswdetails?.xtt} NM`
                  : areasemiwidthDeparture && areasemiwidthDeparture.xtt
                  ? `${areasemiwidthDeparture.xtt} NM`
                  : '-'}
              </div>
            </div>
            <div>
              <div style={{ paddingLeft: 8 }}>ATT</div>
              <div>
                {activeLegDesignDataDeparture
                  ? `${activeLegDesignDataDeparture.end_waypoint.aswdetails?.att} NM`
                  : areasemiwidthDeparture && areasemiwidthDeparture.att
                  ? `${areasemiwidthDeparture.att} NM`
                  : '-'}
              </div>
            </div>
            <div>
              <div style={{ paddingLeft: 8 }}>1/2 AW</div>
              <div>
                {activeLegDesignDataDeparture
                  ? `${activeLegDesignDataDeparture.end_waypoint.aswdetails?.aw} NM`
                  : areasemiwidthDeparture && areasemiwidthDeparture.aw
                  ? `${areasemiwidthDeparture.aw} NM`
                  : '-'}
              </div>
            </div>
          </Col>
          <Col>
            <CustomDivider type="vertical" className="general-divider" />
          </Col>
          <Col span={11} className="general-content">
            <div>
              <div>Endpoint Coordinates</div>
              <div />
            </div>
            <div>
              <div>LAT</div>
              <div>
                {!isEmpty(activeLegDesignDataDeparture)
                  ? latToDms(activeLegDesignDataDeparture.end_waypoint.lat)
                  : !isEmpty(endWaypointLatLngDeparture)
                  ? latToDms(endWaypointLatLngDeparture[0])
                  : '-'}
              </div>
            </div>
            <div>
              <div>LONG</div>
              <div>
                {!isEmpty(activeLegDesignDataDeparture)
                  ? lngToDms(activeLegDesignDataDeparture.end_waypoint.lng)
                  : !isEmpty(endWaypointLatLngDeparture)
                  ? lngToDms(endWaypointLatLngDeparture[1])
                  : '-'}
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Divider className="waypoint-divider" />
      <div className="fly-mode-content">
        <div style={{ paddingLeft: 6 }}>Fly Mode</div>
        <Button.Group className="fly-btn-grp">
          <Button
            className={activeFlyBtn === 'over' ? 'fly-btn over-active-btn' : 'fly-btn'}
            icon={<RoundCircleIcon color={activeFlyBtn === 'over' ? primary : typocolor} />}
            disabled={!isEmpty(activeLegDesignDataDeparture) && activeFlyBtn !== 'over'}
            onClick={() => handleActiveFlyBtn('over')}
          >
            Fly Over
          </Button>
          <Button
            className={activeFlyBtn === 'by' ? 'fly-btn by-active-btn' : 'fly-btn'}
            disabled={
              (!isEmpty(activeLegDesignDataDeparture) && activeFlyBtn !== 'by') ||
              selectedPathTerminatorDeparture === 'CA'
            }
            icon={<SquareCircleIcon color={activeFlyBtn === 'by' ? primary : typocolor} />}
            onClick={() => handleActiveFlyBtn('by')}
          >
            Fly By
          </Button>
        </Button.Group>
      </div>
      {activeLegKey !== '1' &&
        designProcedureVersionsData &&
        !designProcedureVersionsData[0].is_terminal && (
          <div className="obstacle-switch-content">
            <Text className="oa-data-label">Terminating Waypoint</Text>
            <Switch
              className={
                isEmpty(activeLegDesignDataDeparture)
                  ? 'oa-switch-data obstacle-switch'
                  : 'obstacle-switch'
              }
              disabled={!isDesigner || !isEmpty(activeLegDesignDataDeparture)}
              checked={isTerminatingWaypoint}
              onChange={() => handleChangeTerminatingWaypoint(!isTerminatingWaypoint)}
            />
          </div>
        )}
      {/* {activeLegKey !== '1' && (
        <div className="min-distance">
          <Text>Minimum Stabilisation Distance</Text>
          <Input
            type="text"
            placeholder="Enter"
            defaultValue="Automatically"
            className="alt-input"
            suffix="m"
            style={{ width: 184 }}
          />
        </div>
      )} */}
    </div>
  );
};

export default EndPointLeg;

import { Button, Input, InputNumber, Typography } from 'antd';
import { primary } from 'constants/color.constant';

const { Text } = Typography;

interface OmniTurnLegProps {
  activeLegKey: string;
  activeLegDesignDataOMNI: any;
}

const OmniTurnLeg = ({ activeLegKey, activeLegDesignDataOMNI }: OmniTurnLegProps) => {
  return (
    <div className="leg-tab turn-leg">
      <div className="turn-angle-content turn-dir-content" style={{ paddingBottom: 0 }}>
        <Text>Turn Direction</Text>
        <Button.Group className="turn-dir-btn">
          <Button disabled>Left</Button>
          <Button className="active-dir-btn">On-Set</Button>
          <Button disabled>Right</Button>
        </Button.Group>
      </div>
      <div className="turn-angle-content turn-dir-content">
        <Text>Angle of Turn</Text>
        <Input
          type="text"
          value={activeLegDesignDataOMNI ? activeLegDesignDataOMNI?.parameters.turn_angle : 0}
          readOnly
        />
      </div>
      <div className="bank-angle turn-dir-content">
        <Text>Bank Angle</Text>
        <InputNumber
          type="number"
          placeholder="Enter Angle"
          defaultValue={
            activeLegDesignDataOMNI ? activeLegDesignDataOMNI?.parameters.bank_angle : 15
          }
          min={15}
          max={25}
          readOnly
          suffix={<Text style={{ color: primary, fontSize: 12, fontWeight: 400 }}>&deg;deg</Text>}
        />
      </div>
      {activeLegKey !== '1' && (
        <div className="bank-angle reaction-time turn-dir-content">
          <Text>Pilot Reaction Time</Text>
          <InputNumber type="number" placeholder="Enter" suffix="sec" defaultValue={6} />
        </div>
      )}
    </div>
  );
};

export default OmniTurnLeg;

import { primary } from 'constants/color.constant';
import { IconProps } from 'types/common.types';

const InfoCircleIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Outline/Status/Info-circle">
        <g id="Icon">
          <path
            d="M9.99992 8.9585C10.3451 8.9585 10.6249 9.23832 10.6249 9.5835V13.7502C10.6249 14.0953 10.3451 14.3752 9.99992 14.3752C9.65474 14.3752 9.37492 14.0953 9.37492 13.7502V9.5835C9.37492 9.23832 9.65474 8.9585 9.99992 8.9585Z"
            fill={color}
          />
          <path
            d="M9.99992 7.50016C10.4602 7.50016 10.8333 7.12707 10.8333 6.66683C10.8333 6.20659 10.4602 5.8335 9.99992 5.8335C9.53968 5.8335 9.16658 6.20659 9.16658 6.66683C9.16658 7.12707 9.53968 7.50016 9.99992 7.50016Z"
            fill={color}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.70825 10.0002C2.70825 5.97309 5.97284 2.7085 9.99992 2.7085C14.027 2.7085 17.2916 5.97309 17.2916 10.0002C17.2916 14.0272 14.027 17.2918 9.99992 17.2918C5.97284 17.2918 2.70825 14.0272 2.70825 10.0002ZM9.99992 3.9585C6.6632 3.9585 3.95825 6.66344 3.95825 10.0002C3.95825 13.3369 6.6632 16.0418 9.99992 16.0418C13.3366 16.0418 16.0416 13.3369 16.0416 10.0002C16.0416 6.66344 13.3366 3.9585 9.99992 3.9585Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};
InfoCircleIcon.defaultProps = {
  color: primary,
  width: 20,
  height: 20,
  onClick: () => {}
};

export default InfoCircleIcon;

import { ReactNode } from 'react';
import { Button, Typography } from 'antd';

const { Text } = Typography;

interface ReportSecTitleWithIconProps {
  title: string;
  icon: ReactNode | null;
  handleSaveData?: Function;
  classname?: string;
}

const ReportSecTitleWithIcon = ({
  title,
  icon,
  handleSaveData,
  classname
}: ReportSecTitleWithIconProps) => {
  return (
    <div className={`report-content-title ${classname}`}>
      <Text>{title}</Text>
      {icon && (
        <Button type="text" icon={icon} onClick={() => handleSaveData && handleSaveData()} />
      )}
    </div>
  );
};

ReportSecTitleWithIcon.defaultProps = {
  handleSaveData: () => {},
  classname: ''
};

export default ReportSecTitleWithIcon;

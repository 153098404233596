import { Table } from 'antd';
import type { TableProps } from 'antd';
import { capitalize, isEmpty } from 'lodash';
import { AiOutlineCheck } from 'react-icons/ai';
import { CodingTableDataType } from 'store/design/types';

interface CodingTableProps {
  codingData: CodingTableDataType[] | [];
}

const CodingTable = ({ codingData }: CodingTableProps) => {
  const defaultColumns: TableProps<any>['columns'] = [
    {
      title: 'Waypoint Identifier',
      dataIndex: 'waypoint',
      key: 'waypoint',
      align: 'center',
      className: 'table-col-data coding-col',
      render: (_, record) => {
        return !isEmpty(record?.waypoint) ? record?.waypoint : '-';
      }
    },
    {
      title: 'Path Termionator',
      dataIndex: 'path_terminator',
      key: 'path_terminator',
      align: 'center',
      className: 'table-col-data coding-col',
      render: (_, record) => {
        return !isEmpty(record?.path_terminator) ? record?.path_terminator : '-';
      }
    },
    {
      title: 'Flyover',
      dataIndex: 'fly_over',
      key: 'fly_over',
      align: 'center',
      className: 'table-col-data coding-col',
      render: (_, record) => {
        return record?.fly_over ? <AiOutlineCheck /> : '';
      }
    },
    {
      title: 'Course M (T)',
      dataIndex: 'course',
      key: 'course',
      align: 'center',
      className: 'table-col-data coding-col',
      render: (_, record) => {
        return record?.course ? record?.course : '-';
      }
    },
    {
      title: 'Turn Direction',
      dataIndex: 'turn_direction',
      key: 'turn_direction',
      align: 'center',
      className: 'table-col-data coding-col',
      render: (_, record) => {
        return record?.turn_direction
          ? record?.turn_direction === 'on-set'
            ? ''
            : capitalize(record?.turn_direction)
          : '';
      }
    },
    {
      title: 'Upper/Lower Limit Altitude (Ft)',
      // dataIndex: 'upper_limit',
      key: 'upper_limit',
      align: 'center',
      className: 'table-col-data',
      render: (_, record) => {
        const uplwLimit = `${
          record?.upper_limit ? Number(record?.upper_limit).toFixed(2) : '-'
        } / ${record?.lower_limit ? Number(record?.lower_limit).toFixed(2) : '-'}`;
        return uplwLimit;
      }
    },
    // {
    //   title: 'Lower Limit Altitude (Ft)',
    //   dataIndex: 'lower_limit',
    //   key: 'lower_limit',
    //   align: 'center',
    //   className: 'table-col-data',
    //   render: (_, record) => {
    //     return record?.lower_limit ? record?.lower_limit : '-';
    //   }
    // },
    {
      title: 'Speed Limit (Kt)',
      dataIndex: 'speed',
      key: 'speed',
      align: 'center',
      className: 'table-col-data coding-col',
      render: (_, record) => {
        return record?.speed ? record?.speed : '-';
      }
    },
    {
      title: 'TMT Distance (NM)',
      dataIndex: 'distance',
      key: 'distance',
      align: 'center',
      className: 'table-col-data coding-col',
      render: (_, record) => {
        return record?.distance ? record?.distance : '-';
      }
    },
    {
      title: 'Navigation Specifications',
      dataIndex: 'navigation',
      key: 'navigation',
      align: 'center',
      className: 'table-col-data',
      render: (_, record) => {
        return record?.navigation ? record?.navigation : '-';
      }
    }
  ];

  return (
    <Table
      columns={defaultColumns}
      dataSource={codingData}
      pagination={false}
      rowKey={(record) =>
        `${record.navigation}-${Math.floor(Math.random() * 100)}-${record?.upper_limit}-${
          record?.distance
        }`
      }
      rowClassName={() => 'table-row-data'}
    />
  );
};

export default CodingTable;
